import React, { useState, useEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import _  from "lodash";
import {BootstrapInput} from '../Page/input';
import { createUser, resetUser } from '../../db/user';
import { UserPermissions } from '../../constants/defines';

const DEFAULT_STATE = {
    email: '',
    uid: "" ,
    password: "",
    edit: [],
    view: []
}

function UserModal(props) {
  const {handleClose, open, db} = props;
  const [user, setUser] = useState({...DEFAULT_STATE});  

  useEffect(() => {
    if (open) {
        setUser({...DEFAULT_STATE});        
    }
  }, [open]);

  const handleSave = async () => {
    console.log('Save:', user);
    const permissionItem = _.find(UserPermissions, {val:user.permission});
    if (!permissionItem) {
      console.log('No Permission defined!');
      return;
    }
    user.view = [...permissionItem.view];
    user.edit = [...permissionItem.edit];
    
    const userId = await createUser(db, user);
    //await resetUser(userId, password);
    handleClose();
  }

  const handleCloseDialog = (event) => {
    handleClose(); 
  }

  const handleChange = (event) => {
    let { name, value} = event.target;
    
    setUser({...user, [name]:value});
  }

  const scroll = 'paper';
  return (
    <Dialog onClose={handleCloseDialog} 
        aria-labelledby="user-dialog-title" 
        open={open}         
        fullWidth={true} 
        maxWidth={"sm"} 
        scroll={scroll}
    >
        <DialogTitle onClose={handleCloseDialog} sx={{padding: '20px 40px 10px', position: 'relative'}}>
          <Typography variant="h2">New User</Typography>   
          <IconButton aria-label="close" variant={'close'} onClick={handleCloseDialog}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
            <Box sx={{padding:'10px 15px'}}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <InputLabel>Email</InputLabel>
                        <BootstrapInput name="email" onChange={handleChange} />                
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel>Permission</InputLabel>
                        <Select
                            name="permission"
                            sx={{width: '100%'}}
                            value={user.permission}
                            input={<BootstrapInput />}
                            onChange={handleChange}
                            >
                            {
                                UserPermissions.map(item=> (
                                <MenuItem value={item.val}>{item.name}</MenuItem>
                                ))
                            }
                        </Select>
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel>Password</InputLabel>
                        <BootstrapInput type="password" name="password" onChange={handleChange} value={user.password} />                
                    </Grid>
                </Grid>   
            </Box>
        </DialogContent>

        <DialogActions  sx={{padding: '20px 40px', justifyContent: 'flex-start'}}>
          <Button variant="blue" sx={{width:'150px'}} disabled={user.email==="" || user.password===""} onClick={handleSave}>Create</Button>
        </DialogActions>
    </Dialog>
  );
}

export default UserModal;
