import * as React from "react"
const GreenArrowSVG = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={21}
    fill="none"
    {...props}
  >
    <path
      fill="#1F7553"
      d="M4.558 15.058a.625.625 0 1 0 .884.884l5.808-5.808 3.308 3.308A.625.625 0 0 0 15.625 13V5.5A.625.625 0 0 0 15 4.875H7.5a.625.625 0 0 0-.442 1.067l3.308 3.308-5.808 5.808Z"
    />
  </svg>
)
export default GreenArrowSVG
