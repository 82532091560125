import React from "react";
import { Box, Tooltip, Typography } from "@mui/material";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import { COMMON_COLORS, palette } from "../../theme/colors";
import BoxShippingSVG from "./Svgs/ShippingBox";
import MakeUpSVG from "./Svgs/MakeUp";

const TopHalfCards = ({ keyValue, title, data, icon, isMobile }) => {
  const isOldestOrder = icon.toLowerCase() === "box";

  const commonProps = {
    width: isMobile ? 18 : 35,
    height: isMobile ? 18 : 35,
    viewBox: isMobile ? "0 0 40 40" : "0 0 44 44",
  };

  const renderThisIconBasedOfKey = () => {
    switch (icon.toLowerCase()) {
      case "box":
        return <BoxShippingSVG {...commonProps} />;
      case "makeup":
        return <MakeUpSVG {...commonProps} />;
      default:
        return (
          <IndeterminateCheckBoxIcon sx={{ color: "#fff", fontSize: 28 }} />
        );
    }
  };

  return (
    <Tooltip
      title={
        isOldestOrder ? (
          <div>
            <div>Buyer's email: {data.extraData.email}</div>
          </div>
        ) : (
          ""
        )
      }
      arrow
      disableHoverListener={!isOldestOrder}
    >
      <Box
        sx={{ ...styles.card, borderRadius: isMobile ? 4 : 2}}
        key={keyValue}
      >
        <Box sx={styles.topRow}>
          <Box sx={styles.iconContainer}>{renderThisIconBasedOfKey()}</Box>
          <Box sx={styles.titleAndSub}>
            {isMobile ? (
              <>
                <Box
                  sx={{
                    ...styles.titleAndDate,
                    padding: 1,
                    alignItems: !isMobile ? "center" : "flex-start",
                    flexDirection: isMobile ? "column" : "row",
                  }}
                >
                  <Typography
                    sx={{
                      ...styles.text,
                      fontSize: isMobile ? 11 : 14,
                    }}
                  >
                    {title}
                  </Typography>
                  <>
                    {data.orderNumber && (
                      <Typography
                        component="span"
                        sx={{
                          ...styles.mainOrderNumberText,
                          fontSize: 17,
                        }}
                      >
                        #{data.orderNumber.toUpperCase()}
                      </Typography>
                    )}
                    {data.count && (
                      <Typography sx={styles.text}>
                        <Typography
                          component="span"
                          sx={styles.mainCountOfMakeups}
                        >
                          {data.count}
                        </Typography>
                      </Typography>
                    )}
                    <Typography
                      sx={{
                        ...styles.dateText,
                        fontSize: 12,
                      }}
                    >
                      {data.date}
                    </Typography>
                  </>
                </Box>
              </>
            ) : (
              <>
                <Box sx={styles.titleAndDate}>
                  <Typography sx={styles.text}>{title}</Typography>
                  <Typography sx={styles.dateText}>{data.date}</Typography>
                </Box>
                <>
                  {data.orderNumber && (
                    <Typography
                      component="span"
                      sx={styles.mainOrderNumberText}
                    >
                      #{data.orderNumber.toUpperCase()}
                    </Typography>
                  )}
                </>
                {data.count && (
                  <Typography sx={styles.text}>
                    <Typography component="span" sx={styles.mainCountOfMakeups}>
                      {data.count}
                    </Typography>
                  </Typography>
                )}
              </>
            )}
          </Box>
        </Box>
      </Box>
    </Tooltip>
  );
};

const styles = {
  card: {
    display: "flex",
    flexDirection: "column",
    padding: 1,
    borderRadius: 2,
    boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
    backgroundColor: "#fff",
    boxSizing: "border-box",
    width: "100%",
    height: "100%",
  },
  titleAndDate: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  text: {
    fontWeight: 500,
    fontSize: 12,
  },
  dateText: {
    color: "#898989",
    fontSize: 10,
    paddingRight: 1,
  },
  mainOrderNumberText: {
    fontSize: 24,
    fontWeight: 500,
  },
  topRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
  },
  iconContainer: {
    display: "flex",
    alignItems: "center",
    padding: 1,
    borderRadius: 2,
    backgroundColor: COMMON_COLORS.babyBlue,
    borderRadius: 10,
    justifyContent: "center",
  },
  titleAndSub: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginLeft: 2,
    width: "100%",
  },
};

export default TopHalfCards;
