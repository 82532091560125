import {collections} from '../constants/defines';
import _  from "lodash";
import { 
  collection, 
  query, 
  where, 
  getDocs, 
  orderBy,
  doc,
  updateDoc,
  addDoc,
  setDoc,
  getDoc,
  deleteDoc,
  onSnapshot
} from "firebase/firestore";

const getFormattedCropSetting = (db, name, callback) => {
  const q = query(collection(db, collections.CROPSETTINGS), where("baseName", "==", name));

  onSnapshot(q, (querySnapshot) => {
    let setting = null;

    querySnapshot.forEach((doc) => {
      let data = doc.data();
        const name = data.baseName;
        const artType = data.artType;
        
        if (!!setting) {
          setting.items[artType] = {
            id: doc.id,
            name: data.name,
            type: data.type,
            style: data.style, 
            frontCanvas: {...data.frontCanvas},
            backCanvas: {...data.backCanvas},
            frontArea: {...data.frontArea},
            backArea: {...data.backArea},
            resizeArea: {...data.resizeArea},
            placeArea: {...data.placeArea},     
            toeArea: {...data.toeArea},
            format: data.format,
            size: data.size,
            isSolo: data.isSolo
          };
        } else {
          setting = {};
          setting.name = name;
          setting.type = data.type;
          setting.style = data.style;
          setting.format = data.format;
          setting.size = data.size;
          setting.items = {};
          setting.items[artType] = {
            id: doc.id,
            name: data.name,
            type: data.type,
            style: data.style, 
            frontCanvas: {...data.frontCanvas},
            backCanvas: {...data.backCanvas},
            frontArea: {...data.frontArea},
            backArea: {...data.backArea},
            resizeArea: {...data.resizeArea},
            placeArea: {...data.placeArea},
            toeArea: {...data.toeArea},
            format: data.format,
            size: data.size,
            isSolo: data.isSolo
          };             
        }
    });
    callback(setting);
  });
}

const getFormattedCropSettings = (db, limit, callback) => {
  const q = query(collection(db, collections.CROPSETTINGS), orderBy('name'));

  onSnapshot(q, (querySnapshot) => {
    let settings = [];
    querySnapshot.forEach((doc) => {
      let data = doc.data();
      const name = data.baseName;
      const artType = data.artType;
      let setting = _.find(settings, {name});
      if (!!setting) {
        setting.items[artType] = {
          id: doc.id,
          name: data.name,
          type: data.type,
          style: data.style, 
          frontCanvas: data.frontCanvas,
          backCanvas: data.backCanvas,
          frontArea: data.frontArea,
          backArea: data.backArea,
          resizeArea: data.resizeArea,
          placeArea: data.placeArea,
          toeArea: data.toeArea,
          format: data.format,
          size: data.size,
          isSolo: data.isSolo
        };
      } else {
        setting = {};
        setting.name = name;
        setting.type = data.type;
        setting.style = data.style;
        setting.format = data.format;
        setting.size = data.size;
        setting.items = {};
        setting.items[artType] = {
          id: doc.id,
          name: data.name,
          type: data.type,
          style: data.style, 
          frontCanvas: data.frontCanvas,
          backCanvas: data.backCanvas,
          frontArea: data.frontArea,
          backArea: data.backArea,
          resizeArea: data.resizeArea,
          placeArea: data.placeArea,
          toeArea: data.toeArea,
          format: data.format,
          size: data.size,
          isSolo: data.isSolo
        };
        settings.push(setting);          
      }
    });
    callback(settings);
  });
}

const getCropSettings = (db, limit, callback) => {
  const q = query(collection(db, collections.CROPSETTINGS), orderBy('name'));

  onSnapshot(q, (querySnapshot) => {
    let items = [];
    querySnapshot.forEach((doc) => {
      let data = doc.data();
      const item = {
        id: doc.id,
        name: data.name,
        artType: data.artType,
        type: data.type,
        style: data.style, 
        frontCanvas: data.frontCanvas,
        backCanvas: data.backCanvas,
        hyperOpticCanvas: data.hyperOpticCanvas,
        frontArea: data.frontArea,
        backArea: data.backArea,
        resizeArea: data.resizeArea,
        placeArea: data.placeArea,
        toeArea: data.toeArea,
        format: data.format,
        size: data.size,
        isSolo: data.isSolo
      };

      items.push(item);
    });
    callback(items);
  });
}

const createCropSetting = (db, setting) => {
  const requests = _.map(setting.items, (item, key) => {
    const _item = {
      artType: key,
      baseName: setting.name,
      name: `${setting.name} - ${key.toUpperCase()}`,
      type: setting.type,
      style: setting.style,
      format: setting.format,
      size: setting.size,
      isSolo: key === "solo",
      frontArea: {
        ...item.frontArea,
      },
      backArea: {
        ...item.backArea,
      },
      frontCanvas: {
        ...item.frontCanvas,
      },
      backCanvas: {
        ...item.backCanvas,
      },
      resizeArea: {
        ...item.resizeArea
      },
      placeArea: {
        ...item.placeArea
      },
      toeArea: {
        ...item.toeArea
      }
    };

    return addDoc(collection(db, collections.CROPSETTINGS), _item);
  });

  console.log('createCropSetting');
  return  Promise.all(requests);  
}

const updateCropSetting = (db, setting) => {
  const requests = _.map(setting.items, (item, key) => {
    const _item = {
      artType: key,
      baseName: setting.name,
      name: `${setting.name} - ${key.toUpperCase()}`,
      type: setting.type,
      style: setting.style,
      format: setting.format,
      size: setting.size,
      isSolo: key === "solo",
      frontArea: {
        ...item.frontArea,
      },
      backArea: {
        ...item.backArea,
      },
      frontCanvas: {
        ...item.frontCanvas,
      },
      backCanvas: {
        ...item.backCanvas,
      },
      resizeArea: {
        ...item.resizeArea
      },
      placeArea: {
        ...item.placeArea
      },
      toeArea:{
        ...item.toeArea
      }
    };

    const docRef = doc(db, collections.CROPSETTINGS, item.id);
    return updateDoc(docRef, _item);
  });

  console.log('updateCropSetting');
  return  Promise.all(requests);  
}

const deleteCropSetting = (db, setting) => {
  const requests = _.map(setting.items, (item, key) => {
    return deleteDoc(doc(db, collections.CROPSETTINGS, item.id));
  });
  console.log('deleteCropSetting');
  return  Promise.all(requests); 
}

const getMatchedCropSettingByFront = (cropsettingsList, width, height) => {
  let setting = _.find(cropsettingsList, item => {
    return item.frontCanvas && typeof item.frontCanvas === 'object' && item.frontCanvas.width == width && item.frontCanvas.height == height;
  });
  return setting;
}

const getMatchedCropSettingByBack = (cropsettingsList, width, height) => {
  let setting = _.find(cropsettingsList, item => {
    return item.backCanvas && typeof item.backCanvas === 'object' && item.backCanvas.width == width && item.backCanvas.height == height;
  });
  return setting;
};

const getMatchedCropSettingsByHyperOptic = (cropsettingsList, width, height) => {

  const filteredBySocks = _.filter(cropsettingsList, item => {
    return item.type === 'Socks';
  });

  // Use _.find to return the first matching item instead of an array
  let setting = _.find(filteredBySocks, item => {
    return item.hyperOpticCanvas && item.hyperOpticCanvas.width == width && item.hyperOpticCanvas.height == height;
  });

  return setting || false
}

const getMatchedCropSettings = (cropsettingsList, type, style, size, format) => {
  let settings = _.filter(cropsettingsList, item => {
    return item.type===type && item.style===style && item.size===size && item.format===format;
  });
  return settings;
}


export { 
  getFormattedCropSetting,
  getFormattedCropSettings, 
  getCropSettings, 
  createCropSetting, 
  updateCropSetting, 
  deleteCropSetting,
  getMatchedCropSettingByFront,
  getMatchedCropSettingByBack,
  getMatchedCropSettings,
  getMatchedCropSettingsByHyperOptic
 };