import { query, where, getDocs, collection } from "firebase/firestore";
import { collections } from "../constants/defines";

const getBySkuBundleOrProduct = async (db, sku) => {
  try {
    // Step 1: Look in PRODUCTS where `sku == sku`
    const productsRef = collection(db, collections.PRODUCTS);
    let result = await getDocs(query(productsRef, where("sku", "==", sku)));
    if (!result.empty) {
      return result.docs[0].data(); // Return the first matching document
    }

    // Step 2: Look in PRODUCTS where `variants` array contains `sku`
    result = await getDocs(query(productsRef, where("variants", "array-contains", sku)));
    if (!result.empty) {
      return result.docs[0].data(); // Return the first matching document
    }

    // Step 3: Look in BOXSETS where `sku == sku`
    const boxsetsRef = collection(db, collections.BOXSETS);
    result = await getDocs(query(boxsetsRef, where("sku", "==", sku)));
    if (!result.empty) {
      const items = await getBundleItems(db, result.docs[0].data()); // Return the first matching document
      const bundle = result.docs[0].data();
      bundle.items = items;
      return bundle;
    }

    // Step 4: Look in BOXSETS where `variants` array contains `sku`
    result = await getDocs(query(boxsetsRef, where("variants", "array-contains", sku)));
    if (!result.empty) {
      const items = await getBundleItems(db, result.docs[0].data()); // Return the first matching document
      const bundle = result.docs[0].data();
      bundle.items = items;
      return bundle;
    }

    // If nothing is found in any of the steps
    return { found: false };
  } catch (error) {
    console.error("Error searching for SKU:", error);
    throw error;
  }
};

const getBundleItems = async (db, bundle) => {
  const items = [];
  for (let i = 0; i < bundle.items.length; i++) {
    console.log('bundle.items[i]: ', bundle.items[i]);
    const item = await getBySkuBundleOrProduct(db, bundle.items[i]);
    items.push(item);
  }
  return items;
}


export default getBySkuBundleOrProduct;
