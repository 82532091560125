import React, { useEffect } from "react";


const useToolTip = ({
  setFunction,
}) => {

  useEffect(() => {
    // Automatically show the tooltip when the component mounts for the first time
    setFunction(true);

    // Hide the tooltip after a delay
    const timer = setTimeout(() => setFunction(false), 12000);  // 12 seconds 

    return () => clearTimeout(timer); // Cleanup the timer
  }, []);


}


export default useToolTip;