import React, { useState, useEffect } from "react"
import Navigation from '../Navigation';

export default function Layout(props) {

  return (
    <section>
      <Navigation page={props.page} user={props.user} />
      {/* ui puposes, hide padding for analytics. */}
      <div className={props.page !== 'analytics' ? "Content" : 'AnalyticsContainer'}>
        <div className="Container">{props.children}</div>
      </div>
    </section>
  )
}