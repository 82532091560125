

const searchDeep = (data, searchValue) => {
  
  if (Array.isArray(data)) {
      // If it's an array, recursively search each item
      return data.some(item => searchDeep(item, searchValue));
  } else if (typeof data === 'object' && data !== null) {
      // If it's an object, search each property
      return Object.values(data).some(value => searchDeep(value, searchValue));
  } else {
      // Coerce data to string and check if it includes the searchValue
      return String(data).toLowerCase().includes(searchValue);
  }
};


export const useSearchKeyWords = (searchValue, initialData) => {

  initialData = JSON.parse(JSON.stringify(initialData));

  if (searchValue === '') {
    return initialData;  // Return all data if searchValue is empty
  }

  // Convert searchValue to string to ensure consistent comparison
  const searchValueStr = String(searchValue).toLowerCase();

  const returnData = initialData.filter(item => searchDeep(item, searchValueStr));

  return returnData;
}