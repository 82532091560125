import React from 'react';
import { makeStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Brightness1Icon from '@mui/icons-material/Brightness1';
import { ProductArtBundle, SoloArtBundle } from '../Products/components';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';


const useHeaderStyles = makeStyles((theme) => ({
    boxControl: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    boxMockup: {
        width: 'fit-content',
        borderRadius: '6px',
        border: '3px solid #EEEEEE',
        padding: '10px',
        boxSizing: 'border-box',
    },
    boxDots: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    imageBox: {
        position: 'relative',
    },
    coverImage: {

    },
    hImage: {
        height: 'auto',
        width: '100%'
    },
    vImage: {
        height: 'auto',
        width: '100%'
    },
}));

const MockupItem = props => {
    const { product, cropSettings, index, toggle, enableToggle, onToggle, adjustForYouthL, isMixedBoxSet, isAdjustedForOtherTypes, isUnderWearOnly, isLowAnd5Pck } = props;
    const classes = useHeaderStyles();
    console.log('[MockupItem] product', product);

    const handleToggle = (val) => {
        onToggle(index, val)
    }

    const isUnderwear = product?.type === 'Underwear'

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            paddingTop: adjustForYouthL || isMixedBoxSet ? '40%' : '10%'
        }}>
            {
                !adjustForYouthL && !isMixedBoxSet && (
                    <Box style={styles.draggerBox}>
                        <DragIndicatorIcon
                            sx={{
                                fontSize: '40px',
                                color: '#808080'
                            }}
                        />
                    </Box>
                )
            }
            <Box className={classes.boxMockup}>
                {
                    !product ? (
                        <>
                            <ProductArtBundle
                                name="fullImage"
                                key="fullImage"
                                firebase={props.firebase.getFirebase()}
                                classes={classes}
                                progress={100}
                                path={''}
                                isUnderwearBundleComponent={isUnderwear}
                                isAdjustedForOtherTypes={isAdjustedForOtherTypes}
                                isUnderWearOnly={isUnderWearOnly}
                                isNotFound={true}
                                isLowAnd5Pck={isLowAnd5Pck}
                            />
                        </>
                    ) : (
                        <>
                            {product.identical ? (
                                <SoloArtBundle
                                    name="SoloImage"
                                    key="SoloImage"
                                    cropSettings={cropSettings}
                                    isFront={true}
                                    firebase={props.firebase.getFirebase()}
                                    classes={classes}
                                    file={null}
                                    path={product.pathFront}
                                    isMixedBoxSet={isMixedBoxSet}
                                    isAdjustedForOtherTypes={isAdjustedForOtherTypes}
                                    isUnderWearOnly={isUnderWearOnly}
                                    isLowAnd5Pck={isLowAnd5Pck}
                                />
                            ) : (
                                <ProductArtBundle
                                    name="fullImage"
                                    key="fullImage"
                                    firebase={props.firebase.getFirebase()}
                                    classes={classes}
                                    progress={100}
                                    path={product.pathFront}
                                    boxedPath={product?.isBoxedImagePath ?? ""}
                                    isUnderwearBundleComponent={isUnderwear}
                                    isAdjustedForOtherTypes={isAdjustedForOtherTypes}
                                    isUnderWearOnly={isUnderWearOnly}
                                    isLowAnd5Pck={isLowAnd5Pck}
                                />
                            )}
                        </>
                    )
                }
            </Box>
            {!isMixedBoxSet && enableToggle && !isUnderWearOnly && (
                <Box className={classes.boxDots}>
                    <IconButton
                        aria-label="close"
                        onClick={e => handleToggle(false)}
                        sx={!toggle ? styles.activeButton : styles.inactiveButton}
                    >
                        <Brightness1Icon fontSize='medium' />
                    </IconButton>
                    <IconButton
                        aria-label="close"
                        sx={toggle ? styles.activeButton : styles.inactiveButton}
                        onClick={e => handleToggle(true)}
                    >
                        <Brightness1Icon fontSize='medium' />
                    </IconButton>
                </Box>
            )}
        </Box>
    );
}

const styles = {
    draggerBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingBottom: '20px'
    },
    activeButton: {
        padding: '5px',
        color: '#1F7CF2'
    },
    inactiveButton: {
        padding: '5px',
        color: '#ccc'
    }
}

export default MockupItem;
