/**
 * Contents of dropdowns for list filter UI
 * 
 * These are designed to give labels and key definitions
 * for commonly used drop-down filter interfaces
 */

const filterShows = [
    {id: 1, name: '25'},
    {id: 2, name: '50'},
    {id: 3, name: '100'},
    {id: 4, name: '200'},  
];
  
const filterTypes = [
    {id: 1, name: 'All'},
    {id: 2, name: 'Socks'},
    {id: 3, name: 'Underwear'},
    {id: 4, name: 'Underwear only'},
    {id: 5, name: 'Socks only'},
    {id: 6, name: 'Mixed'},
];

const filterStyles = [
    {id: 1, name: 'All'},
    {id: 2, name: 'None'},
    {id: 3, name: 'Graphic'},  
    {id: 4, name: 'HyperOptic'},  
    {id: 5, name: 'Hex Full'},  
    {id: 6, name: 'Hex Window'},  
    {id: 7, name: 'Knitted'}, 
];

const filterFormats = [
    {id: 1, name: 'All', val: ''},
    {id: 2, name: 'None', val: ''},
    {id: 3, name: 'Mask', val: '5WybLrAFexzZyRz3Yx0A'},  
    {id: 4, name: 'Crew', val: 'sXll8cHRYFbYR9quepsh'},  
    {id: 5, name: 'Quarter', val: 'VFmXYpcFPEjUgubb7bNR'},  
    {id: 6, name: 'Low', val: 'NECfTbkHZzJkCVawN5jv'},  
    {id: 7, name: 'Heather', val: 'J8KxWmjm2rWtylOX9BQo'}, 
];

const filterSizes = [
    {id: 1, name: 'All', val: ''},
    {id: 2, name: 'None', val: ''},
    {id: 3, name: 'L/XL', val: '9mIfvRdbpw78UdYA1VZ3'},  
    {id: 4, name: 'S/M', val: 'AuXXAwCybPRItw3WRxCN'},  
    {id: 5, name: 'YTH', val: 'pvbHBKuJ8EBJZGJSjkqH'},  
    {id: 6, name: 'YM', val: 'Z3XFUStaMILVl22hUH4N'},  
    {id: 7, name: 'TDL', val: '1a3uvlwU0548yLVBGLcO'},  
    {id: 8, name: 'ADLT', val: 'TXnyH7yzOjq5l64fOAzB'}, 
];

const filterProducts = [
    {id: 1, name: 'All', val: 0},
    {id: 2, name: 'One Product', val: 1},
    {id: 3, name: 'Two Products', val: 2},
    {id: 4, name: '3+ Products', val: 3},
    {id: 5, name: 'Reprint', val: 4},
];

const filterProductionTypes = [
    {id: 1, name: 'All', val: ''},
    {id: 2, name: 'Graphic', val: 'graphic'},
    {id: 3, name: 'Finished', val: 'finished'},
];

export {
    filterShows,
    filterTypes,
    filterStyles,
    filterFormats,
    filterSizes,
    filterProducts,
    filterProductionTypes
}