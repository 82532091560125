export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const ACCOUNT = '/account';
export const PASSWORD_FORGET = '/pw-forget';
export const DASHBOARD = '/dash';
export const CUSTOM_ORDER = '/custom';
export const INLINE_ORDER = '/s';
export const MANUAL_ENTRY = '/manual';
export const RUNNING = '/running';
export const HISTORY = '/history';
export const PRODUCTS = '/products';
export const BUNDLES = '/bundles';
export const TEMPLATES = '/templates';
export const TAGS = '/tags';
export const FORMATS = '/formats';
export const ORDERS = '/orders';
export const FULLILLMENT = '/fulfillment';
export const INVENTORY = '/inventory';
export const TIMESTAMP = '/timestamp';
export const REPORT = '/report';
export const QB_CONNECT = '/qbconnect';
export const WHOLESALE = '/wholesale';
export const UPLOADS = '/uploads';
export const BARCODES = '/barcodes';
export const USERS = '/users';
export const ANALYTICS = '/analytics';
export const UPDATE_PASSWORD = '/update-password';
export const MIX_MATCH_COLLECTIONS = '/mixandmatch';
export const DS_INLINE = '/dsinline';
export const DS_CUSTOM = '/dscustom';
export const DS_QUEUES = '/dsqueues';
export const WEB_QUEUES = '/';
export const BATCH = '/batch';
