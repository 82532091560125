import { collection, getDocs, query, where } from "firebase/firestore";
import { collections } from "../constants/defines";

export const checkIfPrinted = async (orders, db, isCustoms) => {
  const orderIds = orders.map((order) => order.orderNumber);

  // Only get unique order numbers
  const uniqueOrderIds = [...new Set(orderIds)];

  // Determine the correct collection based on isCustoms flag
  const queryThisCollection = isCustoms ? collections.CUSTOM_PRODUCTS_QUEUE : collections.INLINE_PRODUCTS_QUEUE;

  // Function to create chunks of size 30
  const chunkArray = (array, size) => {
    const result = [];
    for (let i = 0; i < array.length; i += size) {
      result.push(array.slice(i, i + size));
    }
    return result;
  };

  // Split unique order IDs into batches of 30
  const chunks = chunkArray(uniqueOrderIds, 30);

  // Execute each batch in parallel
  const batchPromises = chunks.map(async (chunk) => {
    const batchQuery = query(
      collection(db, queryThisCollection),
      where('orderNumber', 'in', chunk)
    );
    const snapshot = await getDocs(batchQuery);
    return snapshot.docs.map((doc) => doc.data());
  });

  // Wait for all batches to complete and combine results
  const batchResults = await Promise.all(batchPromises);
  const ordersFetched = batchResults.flat(); // Flatten the array of arrays

  // return any that are printed: isPrinting
  const ordersPrinted = ordersFetched.filter((order) => order.isPrinting);
  console.log("ordersPrinted", ordersPrinted);
  return ordersPrinted;
};
