import * as React from "react";
import Box from "@mui/material/Box";
import { Button, CircularProgress, Menu, MenuItem } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import SimpleCalendar from "./SimpleCalendar";
import { COMMON_COLORS } from "../../theme/colors";

const rangeNameMapping = {
  Today: "today",
  Yesterday: "yesterday",
  "Last 7 Days": "last7days",
  "Last 30 Days": "last30days",
  "Last 90 Days": "last90days",
  "Last 365 Days": "last365days",
  "Last Month": "lastmonth",
  "Last 12 Months": "last12months",
  "Last Year": "lastyear",
  "Week to Date": "weektodate",
  "Month to Date": "monthtodate",
  "Quarter to Date": "quartertodate",
  "Year to Date": "yeartodate",
  Hourly: "hourly",
};

const predefinedRanges = {
  Today: [new Date(), new Date()],
  Yesterday: (() => {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    return [yesterday, yesterday];
  })(),
  "Last 7 Days": (() => {
    const end = new Date();
    end.setDate(end.getDate() - 1);
    const start = new Date(end);
    start.setDate(start.getDate() - 6);
    return [start, end];
  })(),
  "Last 30 Days": (() => {
    const end = new Date();
    end.setDate(end.getDate() - 1);
    const start = new Date(end);
    start.setDate(start.getDate() - 29);
    return [start, end];
  })(),
  "Last 90 Days": (() => {
    const end = new Date();
    end.setDate(end.getDate() - 1);
    const start = new Date(end);
    start.setDate(start.getDate() - 89);
    return [start, end];
  })(),
  "Last 365 Days": (() => {
    const end = new Date();
    end.setDate(end.getDate() - 1);
    const start = new Date(end);
    start.setDate(start.getDate() - 364);
    return [start, end];
  })(),
  "Last Month": (() => {
    const end = new Date();
    end.setDate(1);
    end.setHours(-1); // Go back one hour to get the last moment of the previous month
    const start = new Date(end.getFullYear(), end.getMonth(), 1);
    return [start, end];
  })(),
  "Last 12 Months": (() => {
    const today = new Date();
    const end = new Date(today.getFullYear(), today.getMonth(), 1); // First day of the current month
    end.setDate(end.getDate() - 1); // Last day of the previous month

    const start = new Date(end.getFullYear() - 1, end.getMonth() + 1, 1); // First day of the month, one year ago
    return [start, end];
  })(),
  "Last Year": (() => {
    const end = new Date();
    end.setFullYear(end.getFullYear() - 1);
    end.setMonth(11);
    end.setDate(31);
    const start = new Date(end.getFullYear(), 0, 1);
    return [start, end];
  })(),
  "Week to Date": (() => {
    const end = new Date();
    const start = new Date(end);
    start.setDate(start.getDate() - start.getDay());
    return [start, end];
  })(),
  "Month to Date": (() => {
    const end = new Date();
    const start = new Date(end);
    start.setDate(1);
    return [start, end];
  })(),
  "Quarter to Date": (() => {
    const end = new Date();
    const start = new Date(end);
    start.setMonth(Math.floor(start.getMonth() / 3) * 3);
    start.setDate(1);
    return [start, end];
  })(),
  "Year to Date": (() => {
    const end = new Date();
    const start = new Date(end);
    start.setMonth(0);
    start.setDate(1);
    return [start, end];
  })(),
};

export const CustomDateRangePicker = ({
  setOpenModal,
  isMobile = false,
  selectedDates,
  setSelectedDates,
  functionToRun,
  setRangeIdentifier,
  loadFetchedData,
  setDefaultRangeIdentifier,
}) => {
  const [currentDate, setCurrentDate] = React.useState(new Date());
  const [selectedRange, setSelectedRange] = React.useState(null);

  const onSelectDay = (selectedDate) => {
    if (selectedDates.length === 2) {
      setSelectedDates([selectedDate]); // Start new selection if 2 dates are already selected
    } else if (
      selectedDates.length === 1 &&
      selectedDates[0] &&
      selectedDate > selectedDates[0]
    ) {
      setSelectedDates([...selectedDates, selectedDate]); // Add second date for range
    } else {
      setSelectedDates([selectedDate]); // Single date selection or start of a new range
    }
  };

  const handleChangeMonth = (side, step) => {
    const newDate = new Date(currentDate);
    newDate.setMonth(newDate.getMonth() + (side === "left" ? -step : step));
    setCurrentDate(newDate);
  };

  const previousMonthDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() - 1,
    1
  );

  const formatDate = (date) => {
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };

  // run
  const updateDateSelection = async () => {
    await functionToRun();
  };

  const handleArrowForwardIconClick = (rangeKey) => {
    setSelectedRange(rangeKey);
    const rangeFunc = predefinedRanges[rangeKey];
    if (rangeFunc) {
      const rangeResult = rangeFunc;
      const start = rangeResult[0];
      const end = rangeResult[1];
      setSelectedDates([start, end]);
      setRangeIdentifier(rangeNameMapping[rangeKey]);
      setDefaultRangeIdentifier(rangeKey);
    }
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    // unmount and reset state
    return () => {
      setSelectedDates([]);
    };
  }, []);

  return (
    <>
      <Box sx={isMobile ? styles.isMobileContainer : styles.container}>
        {loadFetchedData ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              width: "100%",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box
            sx={{
              display: !isMobile ? "flex" : "",
            }}
          >
            {isMobile ? (
              <>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingTop: "10px",
                  }}
                >
                  <Button
                    id="demo-positioned-button"
                    variant="outlined"
                    aria-controls={open ? "demo-positioned-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                  >
                    Select Date Range
                  </Button>
                  <Menu
                    id="demo-positioned-menu"
                    aria-labelledby="demo-positioned-button"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >
                    {Object.keys(predefinedRanges).map((range) => (
                      <MenuItem
                        key={range}
                        onClick={() => {
                          handleArrowForwardIconClick(range);
                          handleClose();
                        }}
                      >
                        {range}
                      </MenuItem>
                    ))}
                  </Menu>
                </Box>
                <Box sx={styles.mobileDateRangeBox}>
                  {isMobile && selectedDates[0] && !selectedDates[1] && (
                    <Box sx={styles.mobileFakeDateBox}> {"  "}</Box>
                  )}
                  {isMobile && !selectedDates[0] && !selectedDates[1] && (
                    <Box sx={styles.mobileFakeDateBox}> {"  "}</Box>
                  )}
                  {isMobile && selectedDates[0] && selectedDates[1] && (
                    <>
                      <Box sx={styles.mobileDateBox}>
                        {formatDate(selectedDates[0])}
                      </Box>
                      <ArrowForwardIcon
                        fontSize="small"
                        style={{
                          margin: "10px 15px",
                        }}
                      />
                      <Box sx={styles.mobileDateBox}>
                        {formatDate(selectedDates[1])}
                      </Box>
                    </>
                  )}
                </Box>
              </>
            ) : (
              <Box sx={styles.tree}>
                {Object.keys(predefinedRanges).map((range) => (
                  <Button
                    key={range}
                    sx={styles.optionsBox}
                    onClick={() => handleArrowForwardIconClick(range)}
                    variant="text"
                  >
                    {range} {selectedRange === range && "✔️"}
                  </Button>
                ))}
              </Box>
            )}

            <Box
              sx={
                isMobile
                  ? styles.isMobileCalendarContainer
                  : styles.calendarContainer
              }
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  paddingTop: "10px",
                  height: "100%",
                }}
              >
                <Box
                  sx={
                    isMobile
                      ? styles.isMobileDualCalendarContainer
                      : styles.dualCalendarContainer
                  }
                >
                  <SimpleCalendar
                    selectedDates={selectedDates}
                    date={previousMonthDate}
                    side="left"
                    onSelectDay={onSelectDay}
                    onChangeMonth={() => handleChangeMonth("left", 1)}
                  />

                  <SimpleCalendar
                    selectedDates={selectedDates}
                    date={currentDate}
                    side="right"
                    onSelectDay={onSelectDay}
                    onChangeMonth={() => handleChangeMonth("right", 1)}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
      {!loadFetchedData && (
        <Box
          sx={isMobile ? styles.mobileButtonContainer : styles.buttonContainer}
        >
          <Button
            variant="outlined"
            style={{
              ...styles.button,
              borderColor: "white",
              backgroundColor: COMMON_COLORS.cancelGrey,
              width: isMobile ? "125px" : "8%",
              fontWeight: "bold",
            }}
            onClick={() => {
              setSelectedDates([]);
              setOpenModal(false);
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              updateDateSelection();
            }}
            variant="contained"
            style={{
              ...styles.button,
              backgroundColor: COMMON_COLORS.buttonBlue,
              color: "white",
              fontWeight: "bold",
              width: isMobile ? "125px" : "8%",
            }}
          >
            Apply
          </Button>
        </Box>
      )}
    </>
  );
};

const styles = {
  dualCalendarContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-evenly",
  },
  isMobileDualCalendarContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    height: "95%",
    justifyContent: "flex-start",
  },
  mobileDateRangeBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  dateRangeBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "10px",
  },
  dateBox: {
    padding: "10px",
    borderRadius: "5px",
    margin: "5px",
    width: "350px",
  },
  mobileDateBox: {
    padding: "10px",
    width: "115px",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  fakeDateBox: {
    padding: "10px",
    borderRadius: "5px",
    width: "350px",
    height: "29px",
  },
  mobileFakeDateBox: {
    padding: "10px",
    borderRadius: "5px",
    height: "29px",
  },
  container: {
    display: "flex",
    width: "70vw",
    height: "45vh",
  },
  isMobileContainer: {
    display: "flex",
    width: "100%",
    height: "100%",
  },
  tree: {
    display: "flex",
    flexDirection: "column",
    width: "22%",
    height: "100%",
    // borderRight: "0.5px solid gray",
    overflowY: "auto", // Enable vertical scrolling
    // Custom scrollbar styles
    "&::-webkit-scrollbar": {
      width: "8px",
    },
    "&::-webkit-scrollbar-track": {
      background: "none",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "rgba(0, 0, 0, 0.2)",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "rgba(0, 0, 0, 0.3)",
    },
    "&::-webkit-scrollbar-thumb:active": {
      background: "rgba(0, 0, 0, 0.4)",
    },
  },
  optionsBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "10px",
    width: "100%",
    textAlign: "left",
    fontSize: "16px",
    textTransform: "none",
  },
  calendarContainer: {
    height: "85%",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
  isMobileCalendarContainer: {
    height: "81%",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
  calendarAndTreeContainer: {
    display: "flex",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    width: "100%",
  },
  mobileButtonContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    marginBottom: "10px",
  },
  button: {
    marginRight: "1%",
    width: "10%",
    padding: "10px",
  },
};
