import {collections} from '../constants/defines';

import { doc, onSnapshot } from "firebase/firestore";

// This function now sets up a real-time listener
export const listenToShipstationBatchDownloadStatus = (db, batchId, setStatus) => {
  if (!batchId) {
    return;
  }

  const docRef = doc(db, collections.DOWNLOADS_SHIPSTATION, batchId);

  const unsubscribe = onSnapshot(docRef, (docSnap) => {

    if (docSnap.exists()) {
      const data = docSnap.data();
      setStatus(data);
    } else {
      setStatus(false); // Or any default state
    }
  }, 
  error => {
    console.log('Error in listenToShipstationBatchDownloadStatus:', error);
  });

  return unsubscribe;
}
