import React, { useEffect } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Box from '@mui/material/Box';
import { styled, keyframes } from '@mui/system';

const countdownAnimation = keyframes`
  from {
    width: 100%;
  }
  to {
    width: 0%;
  }
`;

const CountdownBar = styled('div')({
  height: '5px',
  width: '100%',
  animation: `${countdownAnimation} linear`
});

export function Toast({ show, data, onClose, duration = 6000 }) {
  const animationStyle = { animationDuration: `${duration}ms` };
  
  useEffect(() => {
    let timer;
    if (show) {
      // Close the Snackbar after the specified duration
      timer = setTimeout(onClose, duration);
    }
    return () => clearTimeout(timer); // Cleanup on unmount or if show changes
  }, [show, onClose, duration]);

  return (
    <Snackbar
      open={show}
      onClose={onClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      message={
        <Box sx={{ width: '100%' }}>
          {data.message}
          <CountdownBar style={animationStyle} 
            sx={{
              backgroundColor: data.isError ? 'red' : 'green',
            }}
          />
        </Box>
      }
      ContentProps={{
        sx: {
          width: 'fit-content',
          minWidth: '300px',
          backgroundColor: 'rgba(0,0,0,0.7)',
        },
      }}
    />
  );
}
