import React, { useEffect, useState } from "react";
import { Typography, Box, Button } from "@mui/material";
import { doc, getDoc, onSnapshot, setDoc } from "firebase/firestore";
import { collections } from "../../constants/defines";
import Firebase from "../Firebase";
const firebaseInstance = new Firebase();

const styles = {
  alertContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px",
    borderRadius: "8px",
    backgroundColor: "rgba(25, 118, 210, 0.5)",
    boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
    animation: "pingEffect 1.5s infinite",
    "@keyframes pingEffect": {
      "0%": { transform: "scale(1)", opacity: 1 },
      "50%": { transform: "scale(1.1)", opacity: 0.7 },
      "100%": { transform: "scale(1)", opacity: 1 },
    },
  },
  alertText: {
    fontSize: 16,
    fontWeight: 500,
    marginRight: "8px",
    color: "white",
  },
};

const UpdateAlert = ({ userId }) => {
  const [showAlert, setShowAlert] = useState(false);
  const hardcodedReleaseTimestamp = "2024-12-13-5pm";
  console.log("Hardcoded Release Timestamp: ", hardcodedReleaseTimestamp);
  useEffect(() => {
    const latestUpdateRef = doc(
      firebaseInstance.db,
      collections.UPDATES,
      "latestRelease"
    );
    const userUpdateRef = doc(firebaseInstance.db, collections.USERS, userId);

    const unsubscribe = onSnapshot(latestUpdateRef, async (snapshot) => {
      if (snapshot.exists()) {
        const latestReleaseTimestamp = snapshot.data().timestamp;

        try {
          const userDoc = await getDoc(userUpdateRef);
          const userLastUpdateReceived =
            userDoc?.data()?.lastUpdateReceived || "";

          // Show alert if the user's last update timestamp is behind the latest
          if (userLastUpdateReceived !== latestReleaseTimestamp) {
            setShowAlert(true);
          }
        } catch (error) {
          console.error("Error checking user's last update:", error);
        }
      }
    });

    return () => unsubscribe(); // Clean up the listener on component unmount
  }, [userId]);

  const handleAcknowledgeUpdate = async () => {
    const userUpdateRef = doc(firebaseInstance.db, collections.USERS, userId);
    try {
      console.log("Updating with: ", hardcodedReleaseTimestamp);
      await setDoc(
        userUpdateRef,
        { lastUpdateReceived: hardcodedReleaseTimestamp },
        { merge: true }
      );
      setShowAlert(false);
      // refresh the page
      window.location.reload();
    } catch (error) {
      console.error("Error acknowledging update:", error);
    }
  };

  return (
    showAlert && (
      <Box sx={styles.alertContainer}>
        <Typography sx={styles.alertText}>
          A new update is available!
        </Typography>
        <Button variant="outlined" onClick={handleAcknowledgeUpdate}>
          <Typography sx={styles.alertText}>Refresh Now</Typography>
        </Button>
      </Box>
    )
  );
};

export default UpdateAlert;
