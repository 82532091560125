import { useCallback, useState } from 'react';

export const useShiftSelected = (rows, setSelectedRows, callBack, useOtherOrderNumber = false) => {

  const [lastSelectedIndex, setLastSelectedIndex] = useState(null);

  const handleRowClick = useCallback(
    (event, clickedRow) => {
      // Determine the property to use based on useOtherOrderNumber
      const orderProp = useOtherOrderNumber ? 'order' : 'orderNumber';

      let currentIndex = rows.findIndex(row => row[orderProp] === clickedRow[orderProp]);

      if (event.shiftKey && lastSelectedIndex !== null) {

        const startIndex = Math.min(lastSelectedIndex, currentIndex);

        const endIndex = Math.max(lastSelectedIndex, currentIndex);

        const range = rows.slice(startIndex, endIndex + 1);

        // Update to use dynamic property
        const rangeOrderNumbers = range.map(row => row[orderProp]);
        setSelectedRows(prevSelectedRows => {

          const newSelection = [...prevSelectedRows];

          rangeOrderNumbers.forEach(orderNumber => {
            if (!newSelection.includes(orderNumber)) {
              newSelection.push(orderNumber);
            }
          });

          callBack(null, newSelection);

          return newSelection;
        });
      } else {
        setLastSelectedIndex(currentIndex);
        // Update to use dynamic property for single selection
        setSelectedRows([clickedRow[orderProp]]);
        callBack(null, [clickedRow[orderProp]]);
      }
    },
    [rows, setSelectedRows, lastSelectedIndex, useOtherOrderNumber] // Adding useOtherOrderNumber to dependencies
  );

  return handleRowClick;
};

