import React, { useState, useEffect } from "react";
import { withFirebase } from "../Firebase";
import * as ROUTES from "../../constants/routes";
import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import logoIcon from "../../images/logoandicon.svg";
import InputField from "../AuthComponents/TextField";
import LoadingButton from "../AuthComponents/Button";
import { COMMON_COLORS } from "../../theme/colors";
import MailboxSVG from "../AuthComponents/MailBox";
import useIsMobile from "../../hooks/useIsMobileHook";

const INITIAL_STATE = {
  email: "",
  error: null,
  loading: false,
  sent: false,
};

const PasswordForgetFormBase = ({ firebase }) => {
  const isMobile = useIsMobile();

  const [state, setState] = useState(INITIAL_STATE);

  const { email, error, loading, sent } = state;

  const enterValidEmail = () => {
    // regex for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return !emailRegex.test(email);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!email) {
      setState({ ...state, error: "Please fill in all fields" });
      return;
    }

    if (enterValidEmail()) {
      setState({ ...state, error: "Please enter a valid email" });
      return;
    }

    setState({ ...state, loading: true });

    try {
      await firebase.doPasswordReset(email);
      setState({ ...state, sent: true });
    } catch (err) {
      // best practice to not expose error messages to users
      setState({ ...state, sent: true, loading: false });
    }
  };

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  return (
    <Box sx={styles.container}>
      <style>
        {`
        input:focus {
          border-color: black;
          color: black;
          outline: none;
        }

        input::placeholder {
          color: #9C9C9C;
        }
      `}
      </style>
      <Box
        sx={{
          ...styles.imageAndBox,
          paddingBottom: isMobile ? "40%" : "0",
        }}
      >
        <Box sx={styles.loginHeader}>
          <img src={logoIcon} alt="logo" />
        </Box>
        <Box
          sx={{ ...styles.loginBox, width: sent && isMobile ? "80%" : "80%" }}
        >
          {!sent && (
            <>
              <Box
                sx={{
                  ...styles.titlesLogin,
                  width: isMobile ? "90%" : "70%",
                }}
              >
                <Typography
                  component="h1"
                  variant="h2"
                  fontWeight={500}
                  paddingBottom={0.5}
                >
                  Reset Passcode
                </Typography>
                <Typography
                  component="h1"
                  variant="body2"
                  color={"#9C9C9C"}
                  textAlign={"center"}
                >
                  Enter your email and we will send you a passcode reset link.
                </Typography>
              </Box>
              <Box component="form" onSubmit={handleSubmit} sx={styles.formBox}>
                <InputField
                  type="text"
                  name="email"
                  value={email}
                  onChange={handleChange}
                  placeholder="Enter your email"
                />
                {error && (
                  <Typography sx={styles.loginError}>{error}</Typography>
                )}
                <Box sx={{ paddingTop: 1, width: "100%" }}>
                  <LoadingButton loading={loading} text="Send Reset Email" />
                </Box>
              </Box>
            </>
          )}
          {sent && (
            <>
              <MailboxSVG />
              <Box
                sx={{
                  ...styles.resetedPassBox,
                  padding: isMobile ? "5px" : "20px",
                  width: isMobile ? "90%" : "70%",
                  textAlign: "center",
                }}
              >
                <Typography
                  component="h1"
                  variant={isMobile ? "h3" : "h2"}
                  fontWeight={500}
                  paddingBottom={1}
                >
                  Passcode Reset {isMobile ? <br /> : <></>}Email Sent
                </Typography>
                <Typography sx={styles.resetedPassText}>
                  An email has been sent to <br />
                  <span style={{ fontWeight: 600, color: "black" }}>
                    {" "}
                    {email}{" "}
                  </span>
                  with the instructions for resetting your passcode.
                </Typography>
              </Box>
            </>
          )}
        </Box>
        <Typography sx={styles.greyLink}>
          <Link
            style={{
              textDecoration: "none",
              color: "#7D7D7D",
              cursor: "pointer",
            }}
            to={ROUTES.SIGN_IN}
          >
            Return to Login
          </Link>
        </Typography>
      </Box>
    </Box>
  );
};

const PasswordForgetLink = ({ error }) => {
  const message = error
    ? "Forgot your passcode?"
    : "Forgot your login credentials?";

  return (
    <Typography sx={styles.greyLink}>
      <Link
        style={{ textDecoration: "none", color: "#7D7D7D", cursor: "pointer" }}
        to={ROUTES.PASSWORD_FORGET}
      >
        {message}
      </Link>
    </Typography>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: COMMON_COLORS.blackBackground,
    height: "100vh",
    width: "100vw",
    justifyContent: "center",
  },
  resetedPassBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    padding: "20px",
  },
  resetedPassText: {
    fontSize: "18px",
    color: "#7D7D7D",
    textAlign: "center",
  },
  greyLink: {
    paddingTop: "20px",
    fontSize: "18px",
  },
  loginError: {
    color: "#FF3838",
    fontSize: "12px",
    textAlign: "left",
    width: "100%",
    paddingBottom: "5px",
  },
  forgotPassword: {
    paddingTop: "20px",
    fontSize: "18px",
    color: "#7D7D7D",
  },
  titlesLogin: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "70%",
  },
  formBox: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
  },
  loginBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "white",
    padding: "40px 20px",
    borderRadius: "10px",
    maxWidth: "500px", // Max width for larger screens
  },
  inputContainer: {
    margin: "10px 0",
    width: "100%",
  },
  passwordContainer: {
    position: "relative",
    width: "100%",
  },
  input: {
    width: "100%",
    padding: "13px",
    borderRadius: "12px",
    border: "1px solid #ccc",
    boxSizing: "border-box",
    backgroundColor: COMMON_COLORS.greyTextFields,
    color: "#9C9C9C",
  },
  passwordToggle: {
    position: "absolute",
    right: "10px",
    top: "50%",
    transform: "translateY(-50%)",
    background: "none",
    border: "none",
    cursor: "pointer",
    color: "#9C9C9C",
    fontSize: "12px",
  },
  buttonText: {
    color: "white",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "32px",
  },
  imageAndBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    paddingBottom: "5%",
  },
  loginHeader: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: "10px",
  },
};

const PasswordForgetForm = withFirebase(PasswordForgetFormBase);

export { PasswordForgetForm, PasswordForgetLink };
