import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import AsyncSelect from 'react-select/async';
import Select, { components } from 'react-select';
import _, { debounce } from "lodash";
import { filterNonCustomProducts } from '../../module/typesense';
import useHotkeyToggle from '../../hooks/useHotkeyToggle';
import { lookForAnyOrder } from '../../module/lookForAnyOrder';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    marginTop: '0px'
  },
  searchInput: {
    width: '100%',

    'label + &': {
      marginTop: '15px',
      width: '100%'
    },
    'label': {
      fontSize: '16px',
      fontWeight: '500',
      color: '#000000'
    },
    '& input': {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: 'rgba(0,0,0,0.04)',
      border: '1px solid #ced4da',
      fontSize: 16,
      padding: '11px 10px 11px 12px !important',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      '&:focus': {
        borderRadius: 4,
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
  },
}));

let data = [];

// Custom Input component that triggers onPaste event
const Input = (props) => {
  const { onPaste } = props.selectProps;
  return <components.Input {...props} onPaste={onPaste} />;
};

export default function ProductsDropdown({ onSelect, onChange, setShowTooltip, searchOrderMode, setSearchOrderMode, db, formats, sizes, singlesInventoryBypass }) {
  const classes = useStyles();

  // custom hook to toggle SKU search mode
  useHotkeyToggle({
    setFunction: setSearchOrderMode,
    variable: searchOrderMode,
    extraFunction: setShowTooltip,
    updateStorageKey: 'searchOrderMode',
    allowHook: !singlesInventoryBypass
  });

  const [debouncedLookForAnyOrder] = useState(() =>
    debounce((inputValue, resolve) => {
      console.log("Final inputValue after debounce:", inputValue);

      let cleanedInput = inputValue.trim();

      lookForAnyOrder(cleanedInput, db).then((result) => {
        let _items = _.map(result, (item) => ({
          value: item,
          label: `#${item.orderNumber}`,
        }));

        // Remove labels that are #undefined
        _items = _items.filter((item) => item.label !== '#undefined');

        // Automatically select the first option if available
        if (_items.length > 0 && onSelect) {
          onSelect(_items[0]);
        }

        resolve(_items);
      });
    }, 2000) // Adjust debounce delay as needed
  );

  const promiseOptions = (inputValue) => {
    inputValue = inputValue.trim();
    if (onChange) {
      onChange(inputValue);
    }

    if (searchOrderMode) {
      // If we are in searchOrderMode but relying on paste, we won't trigger here on each char.
      // We could still debounce if user types manually.
      return new Promise((resolve) => {
        debouncedLookForAnyOrder(inputValue, resolve);
      });
    }

    // Normal product search mode
    return new Promise(resolve => {
      setTimeout(() => {
        filterNonCustomProducts(inputValue, null, 100, searchOrderMode, db, formats, sizes, singlesInventoryBypass)
          .then((results) => {
            data = [...results];
            const _items = _.map(results, item => {
              return { value: item.sku, label: `${item.sku} - ${item.name}` };
            });
            resolve(_items);
          });
      }, 500);
    });
  };

  const handleChange = (newValue, actionMeta) => {
    console.log("handleChange", newValue);

    if (searchOrderMode) {
      if (newValue) {
        onSelect(newValue);
      }
    }

    if (newValue) {
      let item = _.find(data, item => item.sku === newValue.value);
      if (item) {
        onSelect(item);
      }
    }
  };

  // onPaste handler for barcode scanning:
  const onPaste = (e) => {
    e.preventDefault();
    const pastedData = (e.clipboardData || window.clipboardData).getData('text');

    if (searchOrderMode) {
      // Directly process the full pasted barcode without waiting
      let cleanedInput = pastedData.trim();
      if (cleanedInput.length > 14) {
        cleanedInput = cleanedInput.slice(8);
      }

      lookForAnyOrder(cleanedInput, db).then((result) => {
        let _items = _.map(result, (item) => ({
          value: item,
          label: `#${item.orderNumber}`,
        }));

        // Remove labels that are #undefined
        _items = _items.filter((item) => item.label !== '#undefined');

        // Automatically select the first option if available
        if (_items.length > 0 && onSelect) {
          onSelect(_items[0]);
        }
      });
    } else {
      // If not in searchOrderMode, optionally handle pasted input differently, or do nothing.
    }
  };

  return (
    <div className={classes.root}>
      <AsyncSelect
        closeMenuOnSelect={true}
        isClearable
        loadOptions={promiseOptions}
        onChange={handleChange}
        noOptionsMessage={() => 'No results found'}
        loadingMessage={() => 'searching...'}
        components={{ Input }}
        onPaste={onPaste}
      />
    </div>
  );
}
