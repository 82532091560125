import {collections} from '../constants/defines';

import { doc, onSnapshot } from "firebase/firestore";
import { updateChangedOrders } from '../redux/wholeSaleOrders/actions';

// This function now sets up a real-time listener
export const listenToWholesaleChange = (db, orderId, dispatch, isProduction) => {
  if (!orderId) {
    return;
  }

  const docRef = doc(db, collections.QUICKBOOKS, orderId);

  // Flag to check if it's the first snapshot
  let isFirstSnapshot = true;

  const unsubscribe = onSnapshot(docRef, (docSnap) => {
    if (docSnap.exists()) {
      // Skip dispatching on the first snapshot to avoid the initial trigger
      if (!isFirstSnapshot) {
        const data = docSnap.data();

        const dataUpdated = {
          ...data,
          production: isProduction,
        }

        dispatch(updateChangedOrders(dataUpdated, isProduction));
      } else {
        // After the first snapshot, set the flag to false to allow dispatching on updates
        isFirstSnapshot = false;
      }
    } else {
      console.log('No data');
    }
  }, 
  error => {
    console.log('Error in listenToWholesaleChange:', error);
  });

  return unsubscribe;
};
