import { collections, orderStatus, orderTypes } from '../constants/defines';
import { formatDate, formatTime } from '../module/functions';
import _ from "lodash";
import moment from "moment";
import {
  collection,
  query,
  where,
  getDocs,
  orderBy,
  doc,
  limit,
  updateDoc,
  addDoc,
  setDoc,
  getDoc,
  deleteDoc,
  onSnapshot
} from "firebase/firestore";
import { itemsChecked } from '../utils/updateItemIfSaysMissingArt';

const getOrders = (db, fetchData, setRemovedItems) => {
  const q = query(collection(db, collections.ORDERS), orderBy("date", "desc"));

  let previousItems = []; // To store the previous state of items

  onSnapshot(q, (querySnapshot) => {
    let items = [];

    querySnapshot.forEach((doc) => {
      const data = doc.data();
      let _items = data.lineItems.map((_item, index) => {
        return {
          id: `${doc.id}_${index}`,
          sku: _item.sku,
          qty: _item.quantity,
          error: _item.error,
          boxset: !!_item.isBoxSet,
          order: _item.orderNumber ? _item.orderNumber : "",
        };
      });
      const total = !!data.total ? data.total : 0;
      let current = 0;
      if (total > 0) {
        if (data.hasOwnProperty("remaining")) current = total - data.remaining;
        else current = 0;
      }
      items.push({
        date: formatDate(data.date),
        _date: data.date,
        batch: data.batch,
        id: doc.id,
        name: data.name,
        error: !!data.isError,
        source: data.source,
        type: data.source === "CustomOrder" ? "Custom" : "Non-Custom",
        current: current,
        total: total,
        items: _items,
        batchInfo: data.secondary,
      });
    });

    // Compare current items with previous items to detect removed items
    const removedItems = previousItems.filter(
      (prevItem) => !items.some((item) => item.id === prevItem.id)
    );

    if (removedItems.length > 0) {
      setRemovedItems(removedItems); // Capture all removed items
    }

    // Update previousItems with the current state
    previousItems = items;

    fetchData(items);
  });
};



const getHistoryDocuments = (db, fetchData) => {
  const q = query(collection(db, collections.HISTORY), orderBy('createdAt', 'desc'), limit(200));

  onSnapshot(q, (querySnapshot) => {
    console.log('History Changed...');
    let documents = [];
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      let isError = false, isWarning = false;
      let items = data.items.map((item, index) => {
        if (!!item.isError)
          isError = true;
        if (!!item.message)
          isWarning = true;
        return { ...item, id: index.toString() };
      });
      const historyDate = data.createdAt.toDate();
      const createdAt = moment(historyDate).format('MM/DD/YY hh:mm A');

      documents.push({ ...data, items: [...items], createdAt, isError, isWarning });
    });
    fetchData(documents);
  });
}



const deleteOrder = (db, docId) => {
  return new Promise((resolve, reject) => {
    deleteDoc(doc(db, collections.ORDERS, docId))
      .then(() => {
        console.log("Job successfully deleted!");
        resolve();
      })
      .catch((error) => {
        // The document probably doesn't exist.
        console.error("Error deleting Job: ", error);
        reject();
      });
  });
}

export { getOrders, getHistoryDocuments, deleteOrder };