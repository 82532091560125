import React from 'react';
import { withStyles } from '@mui/styles';
import InputBase from '@mui/material/InputBase';

const BootstrapInput = withStyles((theme) => ({
    root: {
      width: '100%',
      'label + &': {
        marginTop: '10px',
        width: '100%'
      },
      'label': {
        fontSize: '16px',
        fontWeight: '500',
        color: '#000000'
      }
    },
    input: {      
      borderRadius: 4,
      position: 'relative',
      backgroundColor: 'rgba(0,0,0,0.04)',
      border: '1px solid #ced4da!important',
      fontSize: 16,
      padding: '10px 10px 10px 12px!important',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      '&:focus': {
        borderRadius: 4,
        borderColor: '#80bdff!important',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)!important',
      },
    },
  
  }))(InputBase);

export {BootstrapInput};  