import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import SignInPage from '../SignIn';
import { ThemeProvider } from '@mui/material/styles';
import CustomOrdersPage from '../CustomOrders';
import InlineOrdersPage from '../InlineOrders';
import HistoryPage from '../History';
import ProductsPage from '../Products';
import RunningPage from '../Running';
import ManualEntryPage from '../ManualEntry';
import OrdersPage from '../Orders';
import FulfillmentPage from '../Fulfillment';
import BundlesPage from '../Bundles';
import TemplatesPage from '../Templates';
import FormatsAndSizesPage from '../FormatsAndSizes';
import TagsPage from '../Tags';
import TimestampPage from '../Timestamp';
import ReportPage from '../Report';
import InventoryPage from '../Inventory';
import UploadsPage from '../Uploads';
import BarcodesPage from '../Barcodes';
import UsersPage from '../Users';
import UserPermissionPage from '../Users/permission';
import WholesalePage from '../Wholesale'
import * as ROUTES from '../../constants/routes';
import { withAuthentication } from '../Session';
import theme from './app.styles'
import store from "../../redux/store";
import { Provider, useDispatch } from "react-redux";
import Firebase from '../Firebase';
import useFetchHistory from '../../hooks/useFetchHistoryData';
import Analytics from '../Analytics';
import MixAndMatchCMS from '../MixAndMatch'

const firebaseInstance = new Firebase();

import useFetchAnalytics from '../../hooks/useFetchAnalytics';
import usePullAllOrdersFromInlineAndCustoms from '../../hooks/usePullAllOrder';
import { PasswordForgetForm } from '../PasswordForget';
import PasswordChangeForm from '../PasswordChange';
import useGetAllBundles from '../../hooks/useGetBundles';
import DsInlines from '../DsQueue/DsInlines';
import DsCustoms from '../DsQueue/DsCustoms';
import useFetchInlineOrders from '../../hooks/useFetchInlineOrders';
import WebQueues from '../WebQueues';
import DsQueues from '../DsQueue/DsQueues';
import BatchesAndRunning from '../BatchAndRunning';

const App = () => {

  const dispatch = useDispatch();

  /**
   * custom hooks to fetch data from firebase
  */

  usePullAllOrdersFromInlineAndCustoms(dispatch, firebaseInstance.db);

  useGetAllBundles(dispatch);

  useFetchAnalytics(dispatch, firebaseInstance.db);

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <Route exact path={ROUTES.SIGN_IN} component={SignInPage} />
        <Route exact path='/' component={WebQueues} />
        <Route exact path={ROUTES.ANALYTICS} component={Analytics} />
        <Route exact path={ROUTES.CUSTOM_ORDER} component={CustomOrdersPage} />
        <Route exact path={ROUTES.MANUAL_ENTRY} component={ManualEntryPage} />
        <Route exact path={ROUTES.RUNNING} component={RunningPage} />
        <Route exact path={ROUTES.BATCH} component={BatchesAndRunning} />
        <Route exact path={ROUTES.PRODUCTS} component={ProductsPage} />
        <Route exact path={ROUTES.ORDERS} component={OrdersPage} />
        <Route exact path={ROUTES.FULLILLMENT} component={FulfillmentPage} />
        <Route exact path={ROUTES.INVENTORY} component={InventoryPage} />
        <Route exact path={ROUTES.BUNDLES} component={BundlesPage} />
        <Route exact path={ROUTES.TEMPLATES} component={TemplatesPage} />
        <Route exact path={ROUTES.FORMATS} component={FormatsAndSizesPage} />
        <Route exact path={ROUTES.TAGS} component={TagsPage} />
        <Route exact path={ROUTES.TIMESTAMP} component={TimestampPage} />
        <Route exact path={ROUTES.REPORT} component={ReportPage} />
        <Route exact path={ROUTES.UPLOADS} component={UploadsPage} />
        <Route exact path={ROUTES.BARCODES} component={BarcodesPage} />
        <Route exact path={ROUTES.USERS} component={UsersPage} />
        <Route exact path={ROUTES.WHOLESALE} component={WholesalePage} />
        <Route exact path={`${ROUTES.USERS}/:userId`} component={UserPermissionPage} />
        <Route path={ROUTES.PASSWORD_FORGET} component={PasswordForgetForm} />
        <Route path={ROUTES.UPDATE_PASSWORD} component={PasswordChangeForm} firebase={firebaseInstance} />
        <Route path={ROUTES.MIX_MATCH_COLLECTIONS} component={MixAndMatchCMS} />
        <Route path={ROUTES.DS_INLINE} component={DsInlines} />
        <Route path={ROUTES.DS_CUSTOM} component={DsCustoms} />
        <Route path={ROUTES.DS_QUEUES} component={DsQueues} />
      </ThemeProvider >
    </Router >
  );
};

// Enhance the App component with withAuthentication
const AuthApp = withAuthentication(App);

// Create a new component that wraps AuthApp with Provider
const AppWithStore = () => (
  <Provider store={store}>
    <AuthApp />
  </Provider>
);

export default AppWithStore;