
import { ADDITIONAL_STYLES, HYPEROPTIC_PREFIX } from "../constants/defines";

export const extractProductDetailsForHyperOptic = (/** @type {string} */ productName, /** @type {any[]} */ sizesInDb, /** @type {any[]} */ formatIds) => {

  // Initialize variables to hold extracted values
  let format = '', rawSize = '', style = 'HyperOptic', type = 'Socks';

  // Check for the presence of '---' which indicates the original format
  if (productName.includes('---')) {
    // Original format processing logic
    const parts = productName.split('---');
    const formatSizePart = parts.length > 2 ? parts[2] : '';
    const formatSizeSplit = formatSizePart.split('-');

    format = formatSizeSplit.length > 0 ? formatSizeSplit[0].trim() : '';
    rawSize = formatSizeSplit.length > 1 ? formatSizeSplit[1] : '';
  } else {

    // Adapted logic for the provided filename format
    const parts = productName.split(' - '); // Split by ' - ' to get the main components

    if (parts.length > 2) {

      const formatSizePart = parts[2].split(' ')[0];

      format = formatSizePart;

      const sizePart = parts[2].match(/[A-Za-z]+/g);

      rawSize = sizePart ? sizePart[1] : '';

    }

  }

  // Find the size ID in the sizesInDb array for Socks
  const socksSizes = sizesInDb.filter(size => size.type === 'Socks');
  const sockFormats = formatIds.filter(f => f.type === 'Socks');
  const formatId = sockFormats.find(f => f.name === format)?.id || '';
  const sizeId = socksSizes.find(size => size.sku.toUpperCase().replace(/[^A-Z0-9]/g, '') === rawSize.toUpperCase())?.id || '';

  // Return an object containing the extracted details
  return {
    format: formatId,
    size: sizeId, // Returning the ID of the size
    style,
    type
  };
}

export const destructureName = (/** @type {string} */ productName, /** @type {string} */ givenSide) => {
  // get the (Left) or (Right) part of the product name
  const side = productName.match(/\(([^)]+)\)/)?.[1] || '';
  // lowercase the side
  const sideLower = side.toLowerCase();

  // Extract the keyword 'left' or 'right' from sideLower
  let extractedSide = '';
  if (sideLower.includes('left')) {
    extractedSide = 'left';
  } else if (sideLower.includes('right')) {
    extractedSide = 'right';
  }

  // Check if the extracted side matches the given side
  if (extractedSide !== givenSide) {
    alert(`Invalid file! Please upload a ${givenSide} fraternal hyperoptic image.`);
    return false;
  }

  return true;
}

export const checkIfItsFrontOrBack = (/** @type {string} */ productName, /** @type {string} */ side) => {
  // Check if the product name contains the side
  return productName.toLowerCase().includes(side.toLowerCase());
}

const FILE_NAME_FORMAT = {
  BOXER: 'BOXER',
  KNITTED: 'CREW',
  TSHIRT: 'CREW'
}


export const extractProductDetailsForBoxedImages = (productName, sizesInDb, formatIds) => {
  // Define a mapping for style types, allowing for different variations dynamically
  const styleMappings = {
    tshirt: ['Tshirt', 'T-Shirt', 'T Shirt', 'tshirt', 't-shirt', 't shirt'],
    knit: ['Knit', 'knit', 'knitted', 'Knitted'],
    cutsew: ['Cutsew', 'cutsew', 'Cut-and-Sew', 'cut-and-sew'],
  };

  // Create a function to build a regex pattern dynamically from the style mappings
  const buildRegex = () => {
    const stylePatterns = Object.values(styleMappings)
      .flat()
      .map(style => style.replace(/[- ]/g, '[\\s\\-_]*'))  // Allow for spaces, hyphens, and underscores
      .join('|');
    return new RegExp(`(${stylePatterns})[\\s\\-_]*(Crew|Boxer|)?[\\s\\-_]*(\\b[A-Z0-9]+\\b)?`, 'i');
  };

  // Generate the dynamic regex pattern
  const pattern = buildRegex();
  const match = productName.match(pattern);

  if (!match) {
    console.log("Invalid product name format:", productName);
    return null;
  }

  // Extract the matched style, type, and size from the regex
  let [, styleKey, typeKey, size] = match;

  let formatName, styleName;
  let isTeeShirt = false;

  // Determine the style based on the match from styleMappings
  const findStyleKey = (style) => {
    return Object.keys(styleMappings).find(key => styleMappings[key].includes(style));
  };

  const detectedStyleKey = findStyleKey(styleKey);

  if (!detectedStyleKey) {
    console.error("Unknown style:", styleKey);
    return null;
  }
  // Map style to format and styleName
  switch (detectedStyleKey) {
    case 'tshirt':
      console.log("T-Shirt detected");
      formatName = 'T-Shirt';
      styleName = ADDITIONAL_STYLES.TSHIRT;
      isTeeShirt = true;
      break;
    case 'knit':
      formatName = 'Socks';
      styleName = ADDITIONAL_STYLES.KNITTED;
      break;
    case 'cutsew':
      formatName = 'Underwear';
      styleName = ADDITIONAL_STYLES.CUT_AND_SEW;
      break;
    default:
      console.error("Unknown style:", styleKey);
      return null;
  }

  // Handle size and format
  const sizeObject = findProductSizeById(sizesInDb, size, formatName, isTeeShirt);
  const format = productsFormatIds(formatIds, formatName, isTeeShirt)[0]?.id || null;
  return {
    style: styleName,
    type: formatName,
    size: sizeObject?.id || null,
    format
  };
};



function findProductSizeById(sizesInDb, sizeName, type, isTeeShirt = false) {

  console.log("SizeName:", sizeName);
  console.log("Type:", type);

  if (isTeeShirt) {
    const sizeObject = sizesInDb.find(size => size.type === "T-Shirt" && size.sku === 'L');
    return sizeObject;
  }

  const sizeObject = sizesInDb.find(size => size.type === type && size.sku === sizeName);
  return sizeObject;
}

function productsFormatIds(formatIds, type, isTeeShirt = false) {
  if (isTeeShirt) {
    return formatIds.filter(f => f.type === 'T-Shirt');
  }
  return formatIds.filter(f => f.type === type);
}
