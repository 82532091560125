/**
 * 
 * @param {string} name 
 * @param {string} nameToChange 
 * @param {string} changeNameToThis 
 * @returns returns new name if name matches nameToChange
 */

export const changeFormatName = (name, nameToChange, changeNameToThis) => {
  if ( name === nameToChange ) {
    return changeNameToThis;
  }
  return name;
};

/**
 * 
 * @param {string} formats 
 * @param {string} formatToRemove 
 * @returns array with excluded format
 */
export const removeMaskFormat = (formats, formatToRemove) => {
  // Use filter to return a new array without the 'Mask' format
  return formats.filter(format => format.name !== formatToRemove);
};

