export const calculateProducts = (orders) => {
  // Initialize the base structure for counters
  const baseCounters = () => ({
    total: 0,
    boxes: 0,
    products: 0,
    socks: 0,
    underwear: 0,
    finished: 0,
    "add-on": 0,
    notFound: new Set(),
    missingArt: new Set(),
  });

  let overallCounter = baseCounters();
  let shopifyWebCounter = baseCounters();
  let dropShipCounter = baseCounters();

  let missingType = []
  let missingStyle = []

  // Process each item in an order
  const processItem = (item, quantity, counter) => {
    const error = item.error !== "" ? item?.error?.toLowerCase() : "";

    const sku = item.sku || "";

    if (error === "product not found" && !item.isMixAndMatch) {
      counter.notFound.add(sku);
      overallCounter.notFound.add(sku); // Add to overall counter
    } else if (error === "missing art" && !item.isMixAndMatch) {
      counter.missingArt.add(sku);
      overallCounter.missingArt.add(sku); // Add to overall counter
    } else {
      counter.products += quantity;
    }

    if (item.type === "" && !item.isMixAndMatch) {
      missingType.push(item.sku)
      return
    }

    if (item.style === "" && !item.isMixAndMatch) {
      missingStyle.push(item.sku)
      return
    }

    if (item?.type?.toLowerCase().includes('sock')) {
      item.type = 'Socks';
    }

    const itemType = item.type ? item.type.toLowerCase() : "";
    const itemStyle = item.style ? item.style.toLowerCase() : "";

    if (itemType) {
      counter[itemType] += quantity;
      overallCounter[itemType] += quantity;
    }

    if (["knitted", "hyperoptic"].includes(itemStyle) && !item.isMixAndMatch) {
      counter.socks -= quantity;
      counter.finished += quantity;
      overallCounter.finished += quantity;
    } else if (itemStyle === "cut & sew") {
      counter.underwear -= quantity;
      counter.finished += quantity;
      overallCounter.finished += quantity;
    } else if (["knitted", "hyperoptic"].includes(itemStyle) && item.isMixAndMatch) {
      // mix match have 2 socks
      counter.finished += quantity + 1
      overallCounter.finished += quantity;
    } else if (['graphic tee'].includes(itemStyle)) {
      counter.finished += quantity;
    } else if (['hat'].includes(itemStyle)) {
      counter.finished += quantity;
    } else if (['hoodie'].includes(itemStyle)) {
      counter.finished += quantity;
    }

    if (itemType === "add-on") {
      counter["add-on"] += quantity;
      counter.finished += quantity;
      overallCounter.finished += quantity;
    }
  };

  // Choose the appropriate counter based on order source
  const getCounters = (order) => {
    if (typeof order.order === 'string' && String(order.order).startsWith('DS')) {
      dropShipCounter.total += 1;
      return dropShipCounter;
    } else {
      shopifyWebCounter.total += 1;
      return shopifyWebCounter;
    }
  };



  orders.forEach((order) => {
    const counterToUse = getCounters(order);
    order.items.forEach((item) => {
      const quantity = parseInt(item.quantity || item.qty, 10) || 0;
      if ((item.isBoxSet || item.isBundle || item.boxset || item.bundle) && item.items && Array.isArray(item.items) && item.items.length > 0) {
        if (!Array.isArray(order.items)) {
          return; // Continue to next iteration or handle error appropriately
        }

        counterToUse.boxes += 1;

        counterToUse[item.type.toLowerCase()] += item.items.length

      } else {
        processItem(item, quantity, counterToUse);
      }
    });
  });

  // Convert notFound and missingArt Sets to their sizes for all counters
  const finalizeCounter = (counter) => ({
    ...counter,
    notFound: counter.notFound.size,
    missingArt: counter.missingArt.size,
  });

  shopifyWebCounter = finalizeCounter(shopifyWebCounter);
  dropShipCounter = finalizeCounter(dropShipCounter);
  overallCounter = finalizeCounter(overallCounter);

  // Adjust overall totals to include both Shopify and Dropship counts
  overallCounter.total = shopifyWebCounter.total + dropShipCounter.total;
  overallCounter.boxes = shopifyWebCounter.boxes + dropShipCounter.boxes;
  overallCounter.products = shopifyWebCounter.products + dropShipCounter.products;
  overallCounter.socks = shopifyWebCounter.socks + dropShipCounter.socks;
  overallCounter.underwear = shopifyWebCounter.underwear + dropShipCounter.underwear;
  overallCounter.finished = shopifyWebCounter.finished + dropShipCounter.finished;
  overallCounter["add-on"] = shopifyWebCounter["add-on"] + dropShipCounter["add-on"];


  const allCOunters = {
    overallCounter,
    shopifyWebCounter,
    dropShipCounter,
  };

  return allCOunters;

};

const TYPE_OF_BUNDLE = {
  SOCKS: 'Socks',
  UNDERWEAR: 'Underwear',
  MIXED: 'Mixed',
}

export const getWhatTypeOfBundle = (item) => {
  let isSocks = item?.items?.every(i => i.type === 'Socks')
  let isUnderwear = item?.items?.every(i => i.type === 'Underwear');

  if (isSocks) {
    item.type = TYPE_OF_BUNDLE.SOCKS;
  } else if (isUnderwear) {
    item.type = TYPE_OF_BUNDLE.UNDERWEAR;
  } else {
    item.type = TYPE_OF_BUNDLE.MIXED;
  }
};


export const calculateWholesale = (products, isArrayOfOrders) => {


  let overallCounter = {
    total: 0,
    boxes: 0,
    products: 0,
    socks: 0,
    underwear: 0,
    finished: 0,
    "add-on": 0,
    notFound: new Set(),
    missingArt: new Set(),
    notFoundInBoxes: new Set(),
    missingArtInBoxes: new Set(),
  };

  // check if the parameter is an array of orders with items in there
  if (isArrayOfOrders) {
    products = products.flatMap(order => order?.items ?? [])
  }

  if (!products.length) {
    return overallCounter
  }

  products.forEach((product) => {
    const quantity = parseInt(product.quantity || product.qty, 10) || 1;

    if (product.boxset || product.isBoxSet) {
      if (!Array.isArray(product.items)) {
        return; // Continue to next iteration or handle error appropriately
      }

      overallCounter.boxes += 1;
      // console.log('Processing Boxset', product.sku, product.items.length);
      product.items.forEach((boxItem) => processProductWholesale(boxItem, quantity, overallCounter, true));

    } else {
      processProductWholesale(product, quantity, overallCounter);
    }
  });


  const finalizeCounter = (counter) => ({
    ...counter,
    notFound: counter.notFound.size + counter.notFoundInBoxes.size,
    missingArt: counter.missingArt.size + counter.missingArtInBoxes.size,
  });

  overallCounter = finalizeCounter(overallCounter);

  return overallCounter;
}


const processProductWholesale = (mutableProduct, quantity, counter, isBoxsetItem = false) => {

  const product = { ...mutableProduct }

  const error = product.error ? product.error.toLowerCase() : "";

  const sku = product.sku || "";

  if (error === "product not found") {
    if (isBoxsetItem) {
      counter.notFoundInBoxes.add(sku);
    } else {
      counter.notFound.add(sku);
    }
  } else if (error === "missing art") {
    if (isBoxsetItem) {
      counter.missingArtInBoxes.add(sku);
    } else {
      counter.missingArt.add(sku);
    }
  } else {
    counter.products += quantity;
  }

  if (product?.type?.toLowerCase().includes('sock')) {
    product.type = 'Socks';
  }

  const itemType = product.type ? product.type.toLowerCase() : "";

  const itemStyle = product.style ? product.style.toLowerCase() : "";

  if (itemType) {
    counter[itemType] += quantity;
  }

  if (["knitted", "hyperoptic"].includes(itemStyle)) {
    counter.socks -= quantity;
    counter.finished += quantity;
  } else if (itemStyle === "cut & sew") {
    counter.underwear -= quantity;
    counter.finished += quantity;
  }

  if (itemType === "add-on") {
    counter["add-on"] += quantity;
    counter.finished += quantity;
  }

}



export const calculateManualEntryBatch = (products) => {

  let overallCounter = {
    total: 0,
    boxes: 0,
    products: 0,
    socks: 0,
    underwear: 0,
    finished: 0,
    "add-on": 0,
    notFound: new Set(),
    missingArt: new Set(),
    notFoundInBoxes: new Set(),
    missingArtInBoxes: new Set(),
  };


  products.forEach((product) => {
    const quantity = parseInt(product.quantity || product.qty, 10) || 1;
    if (product.boxset) {
      if (!Array.isArray(product.items)) {
        return; // Continue to next iteration or handle error appropriately
      }
      overallCounter.boxes += 1;
      product.items.forEach((boxItem) => processProduct(boxItem, quantity, overallCounter, true));
    } else {
      processProduct(product, quantity, overallCounter);
    }
  });


  const finalizeCounter = (counter) => ({
    ...counter,
    notFound: counter.notFound.size,
    missingArt: counter.missingArt.size,
    notFoundInBoxes: counter.notFoundInBoxes.size,
    missingArtInBoxes: counter.missingArtInBoxes.size,
    missingArtInBoxesSkus: Array.from(counter.missingArtInBoxes),
    notFoundInBoxesSkus: Array.from(counter.notFoundInBoxes),
  });

  overallCounter = finalizeCounter(overallCounter);

  return overallCounter;
}

const processProduct = (product, quantity, counter, isBoxsetItem = false) => {
  if (!product) {
    return;
  }

  const error = product?.error ? product.error.toLowerCase() : "";

  const sku = product.sku || "";

  if (error === "product not found") {
    if (isBoxsetItem) {
      counter.notFoundInBoxes.add(sku);
    } else {
      counter.notFound.add(sku);
    }
  } else if (error === "missing art") {
    if (isBoxsetItem) {
      counter.missingArtInBoxes.add(sku);
    } else {
      counter.missingArt.add(sku);
    }
  } else if (!product.exists) {
    if (isBoxsetItem) {
      counter.notFoundInBoxes.add(sku);
    } else {
      counter.notFound.add(sku);
    }
  } else {
    counter.products += quantity;
  }

  if (product?.type?.toLowerCase().includes('sock')) {
    product.type = 'Socks';
  }

  const itemType = product.type ? product.type.toLowerCase() : "";

  const itemStyle = product.style ? product.style.toLowerCase() : "";

  if (itemType) {
    counter[itemType] += quantity;
  }

  if (["knitted", "hyperoptic"].includes(itemStyle)) {
    counter.socks -= quantity;
    counter.finished += quantity;
  } else if (itemStyle === "cut & sew") {
    counter.underwear -= quantity;
    counter.finished += quantity;
  }

  if (itemType === "add-on") {
    counter["add-on"] += quantity;
    counter.finished += quantity;
  }

}

// ---- Ds Queue ----

export const countProductsAndBundles = (selectedOrders) => {
  let products = 0;
  let bundles = 0;
  let socks = 0;
  let underwear = 0;
  let finished = 0;
  let notFound = 0;

  // Updated finished quantity function
  function updateFinishedQuantity(itemStyle, quantity) {
    const stylesToAdd = ["knitted", "hyperoptic", "cut & sew", "graphic tee", "hat", "hoodie"];

    if (stylesToAdd.includes(itemStyle)) {
      finished += quantity;  // Update the finished count directly
      return true;  // Indicate that this item is finished
    }

    return false;  // This item is not considered finished
  }
  // Create unique set of order ids
  const orderIds = new Set();

  selectedOrders.forEach((order) => {
    orderIds.add(order.orderNumber);
  });

  const orders = orderIds.size;

  selectedOrders.forEach((order) => {
    order.items.forEach((item) => {
      const quantity = item.quantity || 1;

      if (item?.error?.toLowerCase() === "product not found") {
        notFound += 1;
      }

      if (item.isBoxSet) {
        bundles += quantity;

        // Handle box sets with specific types
        if (item.type === "Socks") {
          // It's an all-socks box set
          const itemsInBox = item.data?.items?.length || 0;
          socks += itemsInBox * quantity; // Add socks from the box set
          products += itemsInBox * quantity;

        } else if (item.type.toLowerCase().includes("socks & underwear")) {
          // It's a combo of socks and underwear
          socks += 1 * quantity;
          underwear += 1 * quantity;
          products += 2 * quantity; // 1 sock + 1 underwear in each combo
        }

        // Check for PCK keyword when no items are defined in the box set
        if (item.data?.items?.length === 0) {
          const pckMatch = item.sku.match(/(\d+)PCK/);
          if (pckMatch) {
            const itemsInPCK = parseInt(pckMatch[1], 10);
            socks += itemsInPCK * quantity; // Assume these are socks
            products += itemsInPCK * quantity; // Add to total products count
          }
        }
      } else {

        const itemStyle = item.style ? item.style.toLowerCase() : "";

        // Check if the item is a finished product
        const isFinished = updateFinishedQuantity(itemStyle, quantity);

        // If a finished item was found, increment the finished count and skip socks/underwear count
        if (!isFinished) {
          // For non-box set individual items
          if (item.type === "Socks") {
            socks += quantity;  // Add to socks
            products += quantity;  // Add to total products count
          } else if (item.type === "Underwear") {
            underwear += quantity;  // Add to underwear
            products += quantity;  // Add to total products count
          }
        }
      }

    });
  });
  return { products, bundles, socks, underwear, orders, finished, notFound };
};
