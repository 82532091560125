// db saved baby 0-12M with a space at the end????
const sizeOrder = [
  "2XL", "XL", "L/XL", "L", "M", "S/M", "Small", "ADLT", "YTH L", "Youth M", "YM", "YTH", "Toddler 2-4Y", "Toddler 1-2Y", "Baby 0-12M ", "O/S", "All", "None"
];



export const sortSizes = (items) => {
  return items.sort((a, b) => {
    let indexA = sizeOrder.indexOf(a.name);
    let indexB = sizeOrder.indexOf(b.name);

    if (indexA === -1) indexA = sizeOrder.length;
    if (indexB === -1) indexB = sizeOrder.length;

    return indexA - indexB;
  });
};