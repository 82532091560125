import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import Typography from "@mui/material/Typography";
import { palette } from "../../theme/colors";
import { OrderSummary } from "../CustomOrders/pageHeaderHelperOrders";

const useHeaderStyles = makeStyles((theme) => ({
  search: {
    width: "470px",
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "rgba(0, 0, 0, 0.04)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    right: "0px",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: "10px 16px!important",
    paddingRight: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
  },
}));

const PageHeader = (props) => {
  const classes = useHeaderStyles();

  // Destructure props for easier access
  const {
    initial,
    selected,
    filteredData,
    showFilteredData,
    type,
    totalsLive,
  } = props;

  let initialClone = { ...initial };

  initialClone.total = initialClone.orders;
  selected.total = selected.orders;

  let initialToUse = !totalsLive ? initialClone : false;

  if (!initialToUse) {
    initialToUse = {
      products: totalsLive.ordersCount,
      socks: totalsLive.socksCount,
      underwear: totalsLive.underwearCount,
      orders: totalsLive.ordersCount,
      finished: totalsLive.finishedGoodsCount,
      notFound: totalsLive.missingProductsCount,
      total: totalsLive.ordersCount,
    };
  }

  return (
    <div className="pageHeader">
      <div className="pageHeading">
        <Typography variant="h1">Web Queue</Typography>

        <OrderSummary
          title="Total"
          counter={initialToUse}
          palette={palette}
          totalsLive={totalsLive}
        />
        {selected.orders > 0 && (
          <OrderSummary title="Selected" counter={selected} palette={palette} />
        )}

        {filteredData && showFilteredData && (
          <OrderSummary
            title="Filtered"
            counter={filteredData}
            palette={palette}
          />
        )}
      </div>
    </div>
  );
};

PageHeader.propTypes = {
  initial: PropTypes.number.isRequired,
};

export default PageHeader;
