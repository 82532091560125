import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { withAuthorization } from '../Session';
import Layout from '../Page';
import PageHeader from './pageHeader';
import PageToolbar from './pageToolbar';
import CartDrawer from './cartDrawer';
import { createManualBatch } from '../../db/inlineOrder';
import { GeneralDialog } from '../Page/generalModal';
import { validateProduct } from '../../db/products';
import { EnhancedTableHead } from '../Page/table';
import { getBarcodes } from '../../db/barcodes';
import RouteLeavingGuard from '../Page/routeLeavingGuard';
import PrintModal from '../Page/printModal';
import useStyles from './manualEntry.styles';
import { calculateManualEntryBatch, calculateProducts } from '../../utils/calculateOrderItems';
import { Box, Card, CardContent, Modal } from '@mui/material';
import getBySkuBundleOrProduct from '../../db/lookInAllPlaces';
import { MockupImage, MockupImageManualEntry } from '../Products/components';
import { ToastCopyCLip } from '../Toast/copyToClip';

const headCells = [
  { id: 'qty', numeric: true, align: 'left', disablePadding: false, label: 'QTY', width: '80px' },
  { id: 'sku', numeric: false, align: 'left', disablePadding: false, label: 'SKU', width: 'auto' },
  { id: 'name', numeric: false, align: 'left', disablePadding: false, label: 'NAME', width: '500px' },
  { id: 'error', numeric: false, align: 'left', disablePadding: false, label: 'ERROR', width: '150px' },
  { id: 'format', numeric: true, align: 'left', disablePadding: false, label: 'FORMAT', width: '70px' },
  { id: 'size', numeric: false, align: 'left', disablePadding: false, label: 'SIZE', width: '70px' },
  { id: 'delete', numeric: false, align: 'right', disablePadding: false, label: '', width: '20px' },
];

function Manual(props) {
  const { history, formats, sizes, cropSettings } = props;

  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [batchModal, setBatchModal] = useState(false);
  const [batchName, setBatchName] = useState('');
  const [printModal, setPrintModal] = useState(false);
  const [press, setPress] = useState(0);
  const [barcodes, setBarcodes] = useState([]);
  const [name, setName] = useState("");
  const [notes, setNotes] = useState("");
  const [searchOrderMode, setSearchOrderMode] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [toastOpen, setToastOpen] = useState(false);
  const [savedBatches, setSavedBatches] = useState([]);
  const [viewSavedBatchesModal, setViewSavedBatchesModal] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [selectedTimestamp, setSelectedTimestamp] = useState(null);
  const [openNameRequestModal, setOpenNameRequestModal] = useState(false);
  const [batchInfo, setBatchInfo] = useState({
    batchName: '',
    inputtedBy: '',
  });

  const db = props.firebase.getdb();
  const storage = props.firebase.getstorage();

  // use effect to look in storage for saved progress
  useEffect(() => {
    // get all items in local storage that start with manualEntryProgress_
    const savedProgresses = Object.keys(localStorage).filter(key => key.startsWith('manualEntryProgress_'));
    const savedProgressesArray = savedProgresses.map(key => JSON.parse(localStorage.getItem(key)));
    savedProgressesArray.sort((a, b) => new Date(b?.timestamp) - new Date(a?.timestamp));
    setSavedBatches(savedProgressesArray);
  }, []);

  useEffect(() => {
    const downHandler = (e) => {
      setPress(e.keyCode);
    };

    getBarcodes(db, 10, fetchBarcodes);
    window.addEventListener("keydown", downHandler);
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener("keydown", downHandler);
    };

  }, []);

  const fetchBarcodes = (items) => {
    setBarcodes([...items]);
  }

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {

      const newSelecteds = products.map((n) => n.sku);
      const _newSelecteds = _.uniq(newSelecteds);
      setSelected(_newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleProductsSelect = () => {
    console.log('handleProductsSelect');
    const _selected = [...selected];
    const _cartItems = [...cartItems];
    const _selItems = _.map(_selected, sku => {
      let _item = _.find(products, product => product.sku === sku);
      return {
        exists: _item.exists,
        name: _item.name,
        qty: _item.qty,
        sku: _item.sku
      };
    });
    const newItems = _cartItems.concat(_selItems);
    setCartItems(newItems);
  }

  const handleBatch = () => {
    console.log('handleBatch');
    setOpenDrawer(cartItems.length > 0);
  }

  const handleCartPrintBatch = async (name, items) => {
    createManualBatch(db, name, "", "#000000", "", items, handleBatchResult);
    setOpenDrawer(false);
    setCartItems([]);
    setProducts([]);
  }

  const handlePrintBatch = async (name, secondaryName, color, sheetType, underwearFormat, barcode, sockFormat) => {

    const _products = _.filter(products, row => isSelected(row.sku));

    const ret = await createManualBatch(db, name, secondaryName, color, sheetType, underwearFormat, barcode, _products, handleBatchResult, formats, sizes, notes, sockFormat);

    if (!ret) {
      return;
    }

    // remove local storage item
    console.log('removing: ', `manualEntryProgress_${selectedTimestamp}`);
    localStorage.removeItem(`manualEntryProgress_${selectedTimestamp}`);

    setSavedBatches(prev => prev.filter(item => item.timestamp !== selectedTimestamp));
    setSelectedTimestamp(null);
    setPrintModal(false);
    setProducts([]);
    setSelected([]);
    setBatchName('');
    setPress(0);
  }

  const handleCSV = (items) => {
    setProducts(items);

    let newSelecteds = items.map((n) => n.sku);
    setSelected(_.uniq(newSelecteds));
  }

  const handleSelectProduct = async (item, isFromSearch = false) => {

    try {
      setSelectedOrder(null);
      if (searchOrderMode && !isFromSearch) {
        setLoading(true);
        const order = item.value;
        const _items = [];
        console.log('handleSelectProduct: ', order);
        for (let i = 0; i < order.items.length; i++) {
          const _item = await getBySkuBundleOrProduct(db, order.items[i].sku);
          _items.push(_item);
        }
        order.items = _items;
        setSelectedOrder(order);

        return;
      }

      const _products = [...products];

      let _item = _.find(_products, { sku: item.sku });
      if (_item) {
        _item.qty += 1;
      } else {
        _products.unshift(item);
      }

      setProducts(_products);

      const selectedIndex = selected.indexOf(item.sku);
      let newSelected = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, item.sku);
        setSelected(newSelected);
      }
    } catch (error) {
      console.log('error: ', error);
    } finally {
      setLoading(false);
    }
  }

  const handleChangeQty = (event, index) => {
    const qty = parseInt(event.target.value);
    const _products = [...products];
    if (qty == 0) {
      //ignore 0
      //_products.splice(index, 1);
    } else {
      _products[index].qty = qty;
    }
    setProducts(_products);
  }


  const handleRemoveItem = (event, index) => {
    const _products = [...products];
    _products.splice(index, 1);

    setProducts(_products);
  }


  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setOpenDrawer(open);
  };

  const handlePrint = () => {
    console.log('handlePrint');
    if (selected.length == 0) {
      console.log('No Selected Items');
      setPress(0);
      return;
    }
    setPrintModal(true);
    setPress(0);
  }

  const handleBatchResult = (result, batchId, batchName) => {
    setBatchName(batchName);
    setBatchModal(true);
    return true;
  }

  const handleCloseBatchModal = () => {
    setBatchModal(false);
  }

  const renderBatchDialog = () => {
    return (
      <GeneralDialog
        label="batch"
        title="Batch"
        open={batchModal}
        onClose={handleCloseBatchModal}
        onConfirm={handleCloseBatchModal}
      >
        <Typography variant='body2' gutterBottom>
          Inline BATCH name is  <strong>{batchName}</strong>
        </Typography>
      </GeneralDialog>
    );
  }

  const handleClosePrintModal = () => {
    setPrintModal(false);
  }

  const renderPrintDialog = (productsCnt, bundlesCnt) => (
    <PrintModal
      {...props}
      open={printModal}
      source="Manual"
      onPrint={handlePrintBatch}
      onClose={handleClosePrintModal}
      products={productsCnt}
      bundles={bundlesCnt}
      barcodes={barcodes}
      name={name}
      setName={setName}
      titleName={"Manual"}
      isCustom={false}
      notes={notes}
      setNotes={setNotes}
    />
  )

  const classes = useStyles();
  const selectedProducts = _.filter(products, row => isSelected(row.sku));
  const selectedBoxsets = _.filter(selectedProducts, row => row.boxset);
  const isSelectedAll = products.length === selectedProducts.length;

  const initialInfo = calculateManualEntryBatch(products);

  const selectedInfo = calculateManualEntryBatch(selectedProducts);

  // Function to validate all products
  const validateAllProducts = async () => {
    const validationResults = await Promise.all(
      products.map(async (product) => {
        // Your existing validation logic...
        let error = '';
        if (!product.exists) {
          error = 'Product Not Found';
        } else if (!product.boxset) {
          const ret = await validateProduct(product, cropSettings, storage);
          if (!ret.valid) {
            error = ret.msg;
          }
        }
        return { ...product, error };
      })
    );
    setProducts(validationResults);
  };

  useEffect(() => {
    if (products.length > 0 && !searchOrderMode) {
      validateAllProducts();
    }
  }, [products.length]);

  const handleWheel = (event) => {
    // Prevent the default scroll behavior when focused on the input
    event.target.blur();
    setTimeout(() => {
      event.target.focus();
    }, 0);
  };


  const handleAddClick = (item) => {
    if (item.isCustomProduct) {
      setToastMessage('*Custom products are not supported in manual entry*');
      setToastOpen(true);
      return;
    }
    const _item = _.cloneDeep(item);
    console.log('handleAddClick: ', _item);
    _item.qty = 1;
    _item.exists = true;
    _item.error = '';
    handleSelectProduct(_item, true);
  }

  const handleSaveProgress = () => {
    setOpenNameRequestModal(true);
  };

  const handleSaveProgressAfterNameSubmit = () => {
    // check if batchName is empty and/or inputtedBy is empty
    if (batchInfo.batchName === '' || batchInfo.inputtedBy === '') {
      setToastMessage('Please fill out all fields!');
      setToastOpen(true);
      return;
    }

    const timestamp = new Date().toLocaleString('en-US', { timeZone: 'America/New_York' });

    const savedProgress = {
      products,
      timestamp,
      batchName: batchInfo.batchName.trim(),
      inputtedBy: batchInfo.inputtedBy.trim(),
    };

    // Generate a unique key using the current timestamp
    const uniqueKey = `manualEntryProgress_${timestamp}`;

    setToastMessage('Saved progress!');
    // Save the progress entry as a separate item in localStorage
    localStorage.setItem(uniqueKey, JSON.stringify(savedProgress));

    // Optionally update the state with the new entry
    setSavedBatches((prev) => [...prev, savedProgress]);
    setToastOpen(true);
    setOpenNameRequestModal(false);
  }

  const handleRemoveSavedBatch = (event, timestamp) => {
    event.stopPropagation();
    // use the timestamp to find in local storage and remove
    localStorage.removeItem(`manualEntryProgress_${timestamp}`);
    // now remove from savedBatches
    const _savedBatches = [...savedBatches];
    const _savedBatchesFiltered = _savedBatches.filter(item => item.timestamp !== timestamp);
    setSavedBatches(_savedBatchesFiltered);
  }

  const handleViewSavedBatches = () => {
    setViewSavedBatchesModal(true);
  }

  const handleLoadSavedBatch = (products, timestamp) => {
    setSelectedTimestamp(timestamp);
    setProducts(products);
    setViewSavedBatchesModal(false);
  }

  useEffect(() => {
    const stayActive = setInterval(() => {
      console.log("Staying active...");
    }, 5 * 60 * 1000); // Every 5 minutes

    return () => clearInterval(stayActive);
  }, []);


  return (
    <Layout page={props.page} user={props.user}>

      {
        savedBatches.length > 0 && (
          <Box
            sx={{
              width: '10%',
              backgroundColor: 'white',
              textAlign: 'center',
              marginBottom: '20px',
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              fullWidth
              onClick={handleViewSavedBatches}
            >
              View Saved Batches
            </Button>
          </Box>
        )
      }
      {
        formats.length > 0 && sizes.length > 0 && (
          <PageHeader
            viewOnly={props.viewOnly}
            editOnly={props.editOnly}
            initial={initialInfo}
            selected={selectedInfo}
            handleCSV={handleCSV}
            handleSelect={handleSelectProduct}
            formats={formats}
            sizes={sizes}
            searchOrderMode={searchOrderMode}
            setSearchOrderMode={setSearchOrderMode}
          />
        )
      }

      {
        selectedOrder && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '50px',
              flexDirection: 'column',
              width: '100%',
            }}
          >
            {/* Placeholder Section */}
            <Typography
              variant="h2"
              sx={{
                textAlign: 'center',
                fontWeight: '600',
              }}
            >
              Order #{selectedOrder.orderNumber}
            </Typography>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start', // Align items at the top
                gap: 3, // Space between cards
                marginTop: '50px',
                flexDirection: 'row',
                flexWrap: 'wrap', // Allow wrapping if needed
              }}
            >
              {selectedOrder.items.map((item, index) => {
                const isBoxset = item?.items?.length > 1;
                if (!isBoxset) {
                  const isBoxedFg = item.isBoxedImagePath !== "" ? item.isBoxedImagePath : false;

                  // Render single item
                  return (
                    <Card
                      key={item.sku}
                      sx={{
                        boxShadow: 3,
                        borderRadius: 2,
                        alignSelf: 'flex-start', // Prevent height alignment issues
                        width: '300px', // Set a fixed width for single item cards
                      }}
                    >
                      <CardContent>
                        <MockupImageManualEntry
                          name="product"
                          firebase={props.firebase.getFirebase()}
                          classes={classes}
                          path={isBoxedFg ? item.isBoxedImagePath : item.pathFront}
                          isBoxed={isBoxedFg}
                          reversePath={`r_${isBoxedFg ? item.isBoxedImagePath : item.pathFront}`}
                          item={item}
                        />
                        <Typography
                          variant="h6"
                          component="div"
                          sx={{
                            textAlign: 'center',
                            marginBottom: '10px',
                          }}
                        >
                          SKU: {item.sku}
                        </Typography>
                        <Button
                          variant="contained"
                          onClick={() => handleAddClick(item)}
                          fullWidth
                          sx={{
                            color: '#FFFFFF',
                            marginTop: '10px',
                            fontSize: '16px',
                          }}
                        >
                          Add
                        </Button>
                      </CardContent>
                    </Card>
                  );
                }

                return (
                  <Card
                    key={item.sku}
                    sx={{
                      boxShadow: 3,
                      borderRadius: 2,
                      alignSelf: 'flex-start', // Prevent height alignment issues
                      width: '100%', // Let the bundle card span full width
                      maxWidth: '600px', // Optional: limit max width for bundles
                    }}
                  >
                    <CardContent>
                      <Typography
                        variant="h5"
                        sx={{
                          fontWeight: 'bold',
                          textAlign: 'center',
                          marginBottom: '10px',
                        }}
                      >
                        Bundle: {item.sku}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          textAlign: 'center',
                          marginBottom: '20px',
                        }}
                      >
                        Items in this bundle:
                      </Typography>

                      {/* Render items in the boxset */}
                      <Box
                        sx={{
                          display: 'flex', // Makes items arrange in a row
                          flexWrap: 'wrap', // Allows wrapping if items exceed container width
                          alignItems: 'center', // Vertically align items
                          justifyContent: 'center', // Centers items horizontally
                          gap: 2, // Adds space between items
                        }}
                      >
                        {item.items.map((boxItem) => (
                          <Card
                            key={boxItem.sku}
                            sx={{
                              boxShadow: 1,
                              borderRadius: 2,
                              padding: 2,
                              flex: '1 1 calc(25% - 16px)', // Flex item width (responsive grid)
                              minWidth: '150px',
                            }}
                          >
                            <CardContent>
                              <MockupImageManualEntry
                                name="product"
                                firebase={props.firebase.getFirebase()}
                                classes={classes}
                                path={boxItem.pathFront}
                                reversePath={`r_${boxItem.pathFront}`}
                                item={boxItem}
                              />
                              <Typography
                                variant="h6"
                                component="div"
                                sx={{
                                  textAlign: 'center',
                                  marginBottom: '10px',
                                }}
                              >
                                SKU: {boxItem.sku}
                              </Typography>
                              <Button
                                variant="contained"
                                onClick={() => handleAddClick(boxItem)}
                                fullWidth
                                sx={{
                                  color: '#FFFFFF',
                                  marginTop: '10px',
                                  fontSize: '16px',
                                }}
                              >
                                Add
                              </Button>
                            </CardContent>
                          </Card>
                        ))}
                      </Box>
                    </CardContent>
                  </Card>
                );
              })}
            </Box>
          </Box>
        )
      }
      {
        products.length > 0 ? (
          <React.Fragment>
            <PageToolbar
              numSelected={selected.length}
              viewOnly={props.viewOnly}
              editOnly={props.editOnly}
              allSelected={isSelectedAll}
              count={cartItems.length}
              onSelectAllClick={handleSelectAllClick}
              handleSelect={handleProductsSelect}
              handleBatch={handleBatch}
              handlePrint={handlePrint}
            />

            <TableContainer sx={{ marginTop: '30px', marginBottom: '50px' }}>
              <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size={'medium'}
                aria-label="enhanced table"
              >
                <EnhancedTableHead classes={classes} useCheckbox={true} cells={headCells}
                />
                <TableBody>

                  {products
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.sku);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      if (row.format == 'WkHkjmiRLmK7FEeZ2QPx') {
                        // change to a different format
                        row.format = "UKepmKta3zRE6C9rSZzS";
                      }

                      if (row.size === 'awBM1ULgZpzjk92OKP3a') {
                        // change to a different size
                        row.size = "pOk2rp5Rmz6DCZZMpa8u"
                      }

                      const format = _.find(formats, { id: row.format }) ? _.find(formats, { id: row.format }).name : '';

                      const size = _.find(sizes, { id: row.size }) ? _.find(sizes, { id: row.size }).
                        name : '';

                      const keyId = `key-${row.sku}-${index}`;

                      const odd = index % 2 === 1;
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={keyId}
                          classes={{
                            root: odd ? classes.tableRow : classes.tableRow2,
                            selected: classes.selectedTableRow,
                          }}
                          selected={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{ 'aria-labelledby': labelId }}
                              onChange={(event) => handleClick(event, row.sku)}
                            />
                          </TableCell>
                          <TableCell align="left" className={row.exists ? classes.tableCell : classes.tableCellError}>
                            <TextField
                              type="number"
                              value={row.qty}
                              className={classes.inputQty}
                              onChange={(event) => handleChangeQty(event, index)}
                              onWheel={handleWheel}
                            />
                          </TableCell>
                          <TableCell align="left">
                            <Typography variant={row.error === '' ? 'tableText' : 'tableErrorText'}>{row.sku}</Typography>
                          </TableCell>
                          <TableCell align="left"><Typography variant='tableText'>{row.name}</Typography></TableCell>
                          <TableCell align="left"><Typography variant='tableErrorText'>{row.error}</Typography></TableCell>
                          <TableCell align="left"><Typography variant='tableText'>{format}</Typography></TableCell>
                          <TableCell align="left"><Typography variant='tableText'>{size}</Typography></TableCell>
                          <TableCell align="right">
                            <Button onClick={(event) => handleRemoveItem(event, index)}>
                              <DeleteOutlineIcon />
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}


                </TableBody>
              </Table>
            </TableContainer>
          </React.Fragment>
        ) : (
          <div sx={{ marginTop: '50px' }}>

          </div>
        )
      }


      <Drawer anchor="right" open={openDrawer} onClose={toggleDrawer(false)}>
        <CartDrawer {...props} classes={classes} products={cartItems} onClose={toggleDrawer(false)} onPrint={handleCartPrintBatch} />
      </Drawer>

      {renderBatchDialog()}
      {renderPrintDialog(selectedProducts.length, selectedBoxsets.length)}

      <RouteLeavingGuard
        when={products.length}
        navigate={path => {
          history.push(path);
        }}
        shouldBlockNavigation={location => {
          return products.length > 0;
        }}
      />


      {
        products.length > 0 && (
          <Box
            sx={styles.saveProgressButton}
          >
            <Button
              variant="outlined"
              sx={{ height: '100%' }}
              color="primary"
              fullWidth
              onClick={handleSaveProgress}
            >
              Save Progress
            </Button>
          </Box>
        )
      }

      <Modal
        open={viewSavedBatchesModal}
        onClose={() => setViewSavedBatchesModal(false)}
      >
        <Box
          sx={styles.modal}
        >
          <Typography variant="h6" sx={{ marginBottom: '16px', fontWeight: 'bold' }}>
            Saved Batches
          </Typography>

          {/* Check if there are products */}
          {savedBatches.length > 0 ? (
            <Box sx={{ marginTop: '24px' }}>
              {savedBatches.map((product, index) => (
                <Box
                  key={product.timestamp}
                  sx={styles.savedBatchItem}
                  onClick={() => handleLoadSavedBatch(product.products, product.timestamp)}
                  style={{ cursor: 'pointer' }}
                >
                  <Box>
                    <Typography color="textSecondary" sx={styles.savedBatchItemText}>
                      <strong>Batch Name:</strong> {product.batchName || 'Unnamed'}
                    </Typography>
                    <Typography color="textSecondary" sx={styles.savedBatchItemText}>
                      <strong>Inputted By:</strong> {product.inputtedBy || 'Unknown'}
                    </Typography>
                    <Typography color="textSecondary" sx={styles.savedBatchItemText}>
                      <strong>Saved On:</strong> {product.timestamp}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" sx={styles.savedBatchItemText}>
                      <strong># of Line Items:</strong> {product.products.length}
                    </Typography>
                  </Box>

                  <Button
                    variant="outlined"
                    color="error"
                    onClick={(event) => handleRemoveSavedBatch(event, product.timestamp)}
                    sx={{ marginLeft: '16px' }}
                  >
                    Remove
                  </Button>
                </Box>
              ))}
            </Box>
          ) : (
            <Typography variant="body2" color="textSecondary">
              No saved batches available.
            </Typography>
          )}
        </Box>
      </Modal>

      {loading && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50vh',
          }}
        >
          <CircularProgress />
        </Box>
      )}

      <Modal
        open={openNameRequestModal}
        onClose={() => setOpenNameRequestModal(false)}
      >
        <Box sx={styles.nameRequestModal}>
          <Typography>
            Please enter a name for this batch
          </Typography>
          <TextField
            sx={styles.nameRequestModalTextField}
            label="Batch Name"
            value={batchInfo.batchName}
            onChange={(event) => setBatchInfo({ ...batchInfo, batchName: event.target.value })}
          />
          <TextField
            sx={styles.nameRequestModalTextField}
            label="Inputted By"
            value={batchInfo.inputtedBy}
            onChange={(event) => setBatchInfo({ ...batchInfo, inputtedBy: event.target.value })}
          />
          <Button
            variant="outlined"
            onClick={handleSaveProgressAfterNameSubmit}
            sx={styles.nameRequestModalButton}
          >
            Save
          </Button>
        </Box>
      </Modal>

      <ToastCopyCLip
        message={toastMessage}
        show={toastOpen}
      />
    </Layout >
  );
}

const styles = {
  modal: {
    width: '500px',
    maxHeight: '80vh',
    overflowY: 'auto',
    backgroundColor: 'white',
    padding: '16px',
    borderRadius: '8px',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
    // place in center of screen
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  nameRequestModal: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '16px',
    padding: '16px',
    backgroundColor: 'white',
    borderRadius: '8px',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  nameRequestModalTextField: {
    width: '100%',
  },
  nameRequestModalButton: {
    marginTop: '16px',
  },
  addBatchSection: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    padding: '16px',
    backgroundColor: 'white',
    borderRadius: '8px',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
  },
  inputField: {
    width: '100%',
  },
  addButton: {
    alignSelf: 'flex-end',
  },
  savedBatchItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid #ddd',
    padding: '8px 0',
  },
  savedBatchItemText: {
    fontSize: '16px',
  },
}

const condition = authUser => !!authUser;
export default withAuthorization(condition)(Manual);

