import React, { useState } from 'react';
import { TableCell, IconButton, Menu, MenuItem, Box } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { updateItemStatusInOrder } from '../../redux/wholeSaleOrders/actions';
import { updateStatusNumberInItem } from '../../db/quickbooks';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';

const ItemActionsCell = ({ item, classes, color, dispatch, isProduction, order, db }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // function that updates redux to set back the item to not be greyed out
  // and update db
  const handleMenuItemClick = () => {
    dispatch(updateItemStatusInOrder(order, item.id, isProduction, true));
    updateStatusNumberInItem(db, order, 0, item.id);
  };

  return (
    <TableCell align="left" className={classes.itemTableCell} sx={{ color: color }}>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreHorizIcon
          fontSize="large"
        />
      </IconButton>

      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top', // Aligns the top of the menu with the anchor element
          horizontal: 'left', // Aligns the menu to the left of the anchor element
        }}
        transformOrigin={{
          vertical: 'top', // Keeps the top of the menu aligned with the anchor
          horizontal: 'right', // Moves the menu to the left of the anchor element
        }}
      >
        <MenuItem onClick={() => handleMenuItemClick()}>
          <LocalPrintshopIcon />
          <Box
            sx={{
              marginLeft: 1,
              fontSize: 14,
            }}
          >Enable Re-Print</Box>
        </MenuItem>
      </Menu>


    </TableCell>
  );
};

export default ItemActionsCell;
