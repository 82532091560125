import { collections } from "../../constants/defines";
import { useSearchKeyWords } from "../../utils/searchByKeyWord";
import { debounce } from 'lodash';
import { collection, query, where, getDocs } from "firebase/firestore";
import { adjustHistoryDocs } from "../../db/history";

export const debouncedDbSearch = debounce(async (db, keyWords, setHistoryDocs) => {
  console.log('debouncedDbSearch', keyWords);
  const collectionRef = collection(db, collections.HISTORY);
  const keywordQuery = query(
    collectionRef,
    // where name is almost or exactly like the keyword
    where('name', '==', keyWords)
  );

  const snapshot = await getDocs(keywordQuery);
  const fallbackDocs = snapshot.docs.map(doc => doc.data());
  const adjustedFallbackDocs = adjustHistoryDocs(fallbackDocs);
  console.log('adjustedFallbackDocs', adjustedFallbackDocs);
  // Update history docs with fallback if found
  setHistoryDocs(adjustedFallbackDocs);
}, 300); // 300 ms debounce delay

export const filterHistoryDocs = (historyDocs, batchType, productType, batchStyle, batchStatus, sort, selectedFilters, keyWords, lookedInHistory) => {

  let filteredHistoryDocs = historyDocs;
  if (keyWords !== "" && lookedInHistory) {
    filteredHistoryDocs = useSearchKeyWords(keyWords, filteredHistoryDocs, 'history');
  } else {
    filteredHistoryDocs = filteredHistoryDocs;
  }

  // Filter by batchType
  if (batchType !== "All") {
    filteredHistoryDocs = filteredHistoryDocs.filter(doc => doc?.source?.toLowerCase() === batchType.toLowerCase());
  }

  // Filter by productType
  if (productType !== "All") {
    filteredHistoryDocs = filteredHistoryDocs.filter(doc => doc.productType === productType);
  }

  // Filter by batchStyle
  if (batchStyle !== "All") {
    filteredHistoryDocs = filteredHistoryDocs.filter(doc => doc.typeOfPress?.toLowerCase() === batchStyle.toLowerCase());
  }

  // Filter by batchStatus
  if (batchStatus !== "All") {
    if (batchStatus.toLowerCase() === 'fulfilled') {
      filteredHistoryDocs = filteredHistoryDocs.filter(doc => doc.wasPicked);
    } else {
      filteredHistoryDocs = filteredHistoryDocs.filter(doc => !doc.wasPicked);
    }
  }

  filteredHistoryDocs = handleProductsFilter(selectedFilters, filteredHistoryDocs);

  // Sorting logic
  if (sort === "Oldest") {
    // Reverse the array to show the oldest first
    filteredHistoryDocs = filteredHistoryDocs.slice().reverse(); // Use slice to avoid mutating the original array
  } else if (sort === "Newest") {
    // Sort by newest (ensure it's in the default order)
    filteredHistoryDocs = filteredHistoryDocs.slice(); // Reset to original order (assuming it's fetched in newest-first order)
  }

  return filteredHistoryDocs;
};


const handleProductsFilter = (selectedFilters, filteredHistoryDocs) => {
  return filteredHistoryDocs.filter(doc => {
    const { productTypeCheckers } = doc;

    // Check if "All" filter is selected
    if (selectedFilters.all) {
      return true; // Return all documents if "All" is selected
    }

    // Check filters individually
    const matchesSocks = selectedFilters.socks && productTypeCheckers?.hasSocks;
    const matchesCustoms = selectedFilters.socksCustom && productTypeCheckers?.hasCustoms;
    const matchesInline = selectedFilters.socksInline && productTypeCheckers?.hasSocks; // Assuming inline is part of socks
    const matchesUnderwear = selectedFilters.underwear && productTypeCheckers?.hasBoxers; // Assuming hasBoxers is for underwear

    // Return true if any of the selected filters match
    return matchesSocks || matchesCustoms || matchesInline || matchesUnderwear;
  });
};
