import React from "react";
import { withAuthorization } from "../Session";
import Layout from "../Page";
import { BatchesRunningAndHIstory } from "./BatchesRunningAndHIstory";

const BatchesAndRunning = (props) => {
  return (
    <Layout page="batch" user={props.user}>
      <BatchesRunningAndHIstory {...props} />
    </Layout>
  );
};

const condition = (authUser) => !!authUser;
export default withAuthorization(condition)(BatchesAndRunning);
