

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { unitsToProduceToday } from '../utils/getTodaysOrderData';
import { getFormats } from '../db/formats';
import { saveDailyAnalyticUnitsProduced } from '../db/inlineOrder';
import { doc, setDoc } from 'firebase/firestore';

const saveDailyOrdersData = async (db, ordersArranged, arrayOfData) => {
  const docRef = doc(db, 'DailyOverview', 'latest');
  try {
    await setDoc(docRef, {
      arrayOfData: arrayOfData,
      timestamp: new Date(),
    });
  } catch (error) {
    console.error('Error saving daily orders data:', error);
  }
};

const usePullAllOrdersFromInlineAndCustoms = (dispatch, db) => {
  const { initialAllInlineOrders } = useSelector(state => state.inlineOrders);
  const { initialCustomOrders } = useSelector(state => state.customOrders);

  const [hasSaved, setHasSaved] = useState(false);

  const allOrdersObject = [
    ...initialAllInlineOrders,
    ...initialCustomOrders,
  ];

  useEffect(() => {
    const fetchData = async () => {
      if (initialCustomOrders.length === 0 || initialAllInlineOrders.length === 0) {
        return;
      }

      // Ensure this block runs only once
      if (hasSaved) {
        return;
      }

      const formats = await getFormatsData(db);

      const ordersArranged = unitsToProduceToday(allOrdersObject, dispatch, formats, db);

      const objectForHalfCardsProduction = {
        id: 2,
        title: "Units To Be Produced",
        value: 'toBeProduced',
        data: [ordersArranged.valid]
      };

      const alreadyProducedObject = {
        id: 1,
        title: "Units Produced",
        value: "produced",
        data: []
      };

      const arrayOfData = [
        alreadyProducedObject,
        objectForHalfCardsProduction,
      ];

      // dispatch(setItemsWithErrors(ordersArranged.arrayOfErrorSkus));
      // dispatch(injectAnalytics(arrayOfData, TYPE_OF_ANALYTICS.UNITS));
      await saveDailyOrdersData(db, ordersArranged, arrayOfData);
      // send request to save the data
      saveDailyAnalyticUnitsProduced(db, arrayOfData, ordersArranged.arrayOfErrorSkus);

      // Set the flag to true after saving
      setHasSaved(true);
    };

    fetchData();
  }, [initialAllInlineOrders, initialCustomOrders, hasSaved]);
};

const getFormatsData = (db) => {
  return new Promise((resolve, reject) => {
    getFormats(db, 100, resolve);
  });
};


export default usePullAllOrdersFromInlineAndCustoms;