import { HYPEROPTIC_PREFIX } from "../../constants/defines";

const parseFileName = (filename) => {
    let productName = '', size = '', type = 'Design', style = '', side = '';
    if (filename.includes('---')) {
      var _filename = filename.split('.').slice(0, -1).join('.').trim();
      let fileWords = _filename.split('---');
      const fileWordsLen = fileWords.length;

      // Check and handle -(Right) or -(Left) specially before removing
      fileWords = fileWords.map(word => {
        const rightLeftMatch = word.match(/-\(Right\)|-\(Left\)/i);

        if (rightLeftMatch) {
          return word.replace(/-\(Right\)|-\(Left\)/i, '').trim()
        }
        
        return word;
      });

      let validPrefixs = fileWordsLen-1;
      let lastPrefex = fileWords[validPrefixs];
      if (lastPrefex.toLowerCase()==="front" || lastPrefex.toLowerCase()==="back") {
        side = lastPrefex;
        validPrefixs = validPrefixs - 1;
        let productSpecs = fileWords[validPrefixs].split('-');
        style = productSpecs[0];
        size = productSpecs[1];
        type = productSpecs[2]?productSpecs[2]:'';
      } else {
        let productSpecs = lastPrefex.split('-');      
        style = productSpecs[0];
        size = productSpecs[1];
        type = productSpecs[2];
        side = productSpecs[3];
      }

      for(let j=0; j<validPrefixs; j++) {
        let word = fileWords[j];
        word = word.replaceAll("-", " ");
        if (j != 0) {
          productName += " - ";
        }
        productName += word;
      }

    } else {
      let productSpecs = filename.split('-');  
      for(let i=0;i<productSpecs.length;i++) {
        let prefix = productSpecs[i].toLowerCase();
        if (prefix.includes('back') || prefix.includes('front') || prefix.includes('.')) {

        } else if (prefix === 'lxl' || prefix === 'sm' || prefix === 'yth') {
          size = prefix;
        } else {
          productName += productSpecs[i] + " ";
        }
      }
  
      productName = productName.substring(0, productName.length - 1);
    }

    // Normalize spaces in productName: Replace multiple spaces with a single space
    productName = productName.replace(/\s+/g, ' ').trim();

    return {name: productName, key: `${productName}-${style}-${size}-${type}`};
}

const FileParser = class { 
	constructor(filename) { 
      this._filename = filename; 
      const fileSpec = parseFileName(filename);

      this._name = fileSpec.name;
      this._key = fileSpec.key;
	} 

  filename() {
      return this._filename;
  }
	
	name() {
    return this._name;
	}
  
	key() {
    return this._key;
	}

  isFront() {
    const filename = this._filename.toLowerCase();
    // Regular expression to match '-design-front' or '-front'
    const frontViewPattern = /-design-front|-front/;
  
    return frontViewPattern.test(filename);
  }

  isBack() {
    const filename = this._filename.toLowerCase();
    // Regular expression to match '-Design-Back' in the filename
    const backViewPattern = /-design-back|-back/;

    return backViewPattern.test(filename);
  }


  isLeftHyperOptic() {
      const filename = this._filename.toLowerCase();
      const leftHyperOpticPattern = new RegExp(`(?:^|[-_ \(])(${HYPEROPTIC_PREFIX.left})(?:$|[-_ \).])`);
      return leftHyperOpticPattern.test(filename);
  }

  isRightHyperOptic() {
      const filename = this._filename.toLowerCase();
      const rightHyperOpticPattern = new RegExp(`(?:^|[-_ \(])(${HYPEROPTIC_PREFIX.right})(?:$|[-_ \).])`);

      return rightHyperOpticPattern.test(filename);
  }

}; 

const ProductArt = (props) => {
    let index = props.index;
    let key = props.key;
    let incorrectSKU = props.incorrectSKU;
    let incorrectDimension = props.incorrectDimension;
    let error = props.error;
    let front = props.front;
    let back = props.back;
    let isMask = props.isMask;
    let loading = props.loading;
    let selected = props.selected;
    let product = props.product;
    let soloArts = [...props.soloArts];
}

export { FileParser, ProductArt };