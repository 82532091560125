import React from 'react';
import IconButton from '@mui/material/IconButton';
import { withFirebase } from '../Firebase';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

const SignOutButton = ({ firebase }) => (
  <IconButton
      aria-label="account of current user"
      aria-controls="menu-appbar"
      aria-haspopup="true"
      color="inherit"
      onClick={firebase.doSignOut}
    >
    <ExitToAppIcon />
  </IconButton>
);

export default withFirebase(SignOutButton);
