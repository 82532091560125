import { Box, Modal, Typography, List, ListItem, Paper } from '@mui/material';
import React from 'react';

export default function MissingProductModal({ missingArtSkus, pnfSkus, onClose, open }) {


  
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >      
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { xs: '80%', sm: 500 }, // Responsive width
          maxHeight: '100vh', // Prevent the modal from being too tall
          overflowY: 'auto', // Enable scrolling within the modal
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography id="modal-title" variant="h6" component="h2">
          Missing Product(s)
        </Typography>
        {missingArtSkus.length > 0 && (
          <>
            <Typography variant="subtitle1" gutterBottom component="div">
              Artwork Not Found
            </Typography>
            <Paper variant="outlined" sx={{ maxHeight: 200, overflow: 'auto', mb: 2 }}>
              <List dense>
                {missingArtSkus.map((sku) => (
                  <ListItem key={sku}>{sku}</ListItem>
                ))}
              </List>
            </Paper>
          </>
        )}
        {pnfSkus.length > 0 && (
          <>
            <Typography variant="subtitle1" gutterBottom component="div">
              Product Not Found
            </Typography>
            <Paper variant="outlined" sx={{ maxHeight: 200, overflow: 'auto' }}>
              <List dense>
                {pnfSkus.map((sku) => (
                  <ListItem key={sku}>{sku}</ListItem>
                ))}
              </List>
            </Paper>
          </>
        )}
      </Box>
    </Modal>
  );
}
