import { useEffect } from "react";
import { API } from "../constants/defines";
import { injectBundles, injectMetadata } from "../redux/bundles/actions";

const useGetAllBundles = (dispatch) => {


  useEffect(() => {
    console.log("Running useGetAllBundles Hook");
    const fetchData = async () => {
      try {

        const response = await fetch(API.getBundles);

        if (!response.ok || response.status !== 200) {
          throw new Error("Network response was not ok");
        }

        const { bundles, metaData, allBundles } = await response.json();

        dispatch(injectBundles(bundles, allBundles));
        dispatch(injectMetadata(metaData));

      } catch (error) {
        console.log("Error in useGetAllBundles:", `${error.message}`)
      }
    };

    fetchData();
  }, []); // empty dependencies array

}


export default useGetAllBundles;