import Fuse from 'fuse.js';

/**
 * Performs a fuzzy search on complex nested data using Fuse.js, with adaptable options based on search type.
 * @param {string} searchValue - The string to search for.
 * @param {Array} initialData - The initial data array containing complex objects.
 * @param {string} searchType - The type of search ("inline", "bundle", etc.).
 * @returns {Array} - The filtered data array based on the search criteria.
 */
export const useFuzzySearch = (searchValue, initialData, searchType) => {
    if (searchValue.trim() === '') {
        return initialData;  // Return all data if searchValue is empty
    }

    // Default Fuse.js options
    let options = {
        includeScore: true,  // Optional, to see how closely each result matches the search term
        threshold: 0.3,      // Adjust this value to be more or less strict
        minMatchCharLength: 1,
        useExtendedSearch: true  // Enables more powerful search expressions
    };

    // Switch case to adjust search options based on the search type
    switch (searchType) {
        case 'inline':
            options.keys = [
                "name",
                "sku",
                "items.name",
                "items.sku",
                "variants",
                "orderNumber"
            ];
            break;
        case 'bundle':
            options.keys = [
                "name",
                "sku",
                "originalSku",
                "items",
                "subDocs.name",
                "subDocs.sku",
                "subDocs.originalSku",
                "subDocs.items",
                "mockups.type",
                "mockups.path",
                "variants",
            ];
            break;
        default:
            options.keys = [
                "name",
                "sku",
                "items",
                "variants",
            ];
            break;
    }

    // Create a Fuse instance with the specified options
    const fuse = new Fuse(initialData, options);

    // Perform the search
    const results = fuse.search(searchValue);

    // Map results back to the original data objects
    return results.map(result => result.item);
};
