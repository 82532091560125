import React, { useCallback, useEffect, useRef, useState } from "react";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import Typography from "@mui/material/Typography";
import { EnhancedTableHead } from "../Page/table";
import { getHistoryOnSnapshot } from "../../db/history";
import Row from "./HistoryRowAndTable";
import { debouncedDbSearch, filterHistoryDocs } from "./helpers";
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  Menu,
  MenuItem,
  Pagination,
  Select,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import { searchHistory } from "../../module/typesense";
import { debounce } from "lodash";
import { useBatchContext } from "./batchContext";

const noResultsImage = require("../../images/noResults.png");

const headCells = [
  {
    id: "dateRan",
    numeric: false,
    align: "left",
    disablePadding: true,
    label: "DATE RAN",
    minWidth: "180px", // Set a minimum width instead of a fixed width
  },
  {
    id: "error",
    numeric: false,
    align: "left",
    disablePadding: true,
    label: "NOTES",
    minWidth: "120px", // Minimum width for flexibility
  },
  {
    id: "batch",
    numeric: false,
    align: "left",
    disablePadding: false,
    label: "BATCH NAME",
    minWidth: "100px",
  },
  {
    id: "type",
    numeric: false,
    align: "left",
    disablePadding: false,
    label: "BATCH TYPE",
    minWidth: "120px",
  },
  {
    id: "products",
    numeric: false,
    align: "left",
    disablePadding: false,
    label: "PRODUCTS",
    minWidth: "220px",
  },
  {
    id: "typeOfPress",
    numeric: false,
    align: "left",
    disablePadding: false,
    label: "STYLE",
    minWidth: "120px",
  },
  {
    id: "files",
    numeric: false,
    align: "center",
    disablePadding: false,
    label: "SHEETS",
    minWidth: "120px",
  },
  {
    id: "oldestOrderDate",
    numeric: false,
    align: "center",
    disablePadding: false,
    label: "ORDER DATES",
    minWidth: "120px",
  },
  {
    id: "fulfilled",
    numeric: false,
    align: "center",
    disablePadding: false,
    label: "FULFILLED",
    minWidth: "120px",
  },
  {
    id: "download",
    numeric: false,
    align: "center",
    disablePadding: false,
    label: "FILES",
    minWidth: "150px",
  },
  {
    id: "action",
    numeric: false,
    align: "center",
    disablePadding: false,
    label: "",
    minWidth: "80px",
  },
];

const useStyles = makeStyles((theme) => ({
  search: {
    width: "470px",
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "rgba(0, 0, 0, 0.04)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    right: "0px",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: "10px 16px!important",
    paddingRight: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
  },
  root: {
    width: "100%",
  },
  filterBox: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
  table: {
    minWidth: 750,
  },
  itemTable: {
    width: "1200px",
  },
  itemTableSub: {
    width: "100%",
  },
  tableHead: {
    background: "rgba(217, 223, 233, 0.4)",
  },
  tableHeadCell: {
    lineHeight: "20.5px",
  },
  tableHeadLabel: {
    fontSize: "14px",
    fontWeight: "500",
    color: "#000000",
    letterSpacing: "1.5px",
  },
  tableCell: {
    fontSize: "14px",
    fontWeight: "500!important",
    letterSpacing: "1.5px",
    color: "#000000",
  },
  itemTableCell: {
    fontSize: "14px",
    fontWeight: "500!important",
    letterSpacing: "1.5px",
    color: "#000000",
    borderBottom: "none!important",
  },
  itemTableCell2: {
    fontSize: "14px",
    fontWeight: "500!important",
    letterSpacing: "1.5px",
    color: "#E0491B!important",
    borderBottom: "none!important",
  },
  txtLocation: {
    fontSize: "12px",
    fontWeight: "500!important",
    letterSpacing: "1.5px",
    color: "#14B779!important",
  },
  itemTableCell3: {
    fontSize: "14px",
    fontWeight: "500!important",
    letterSpacing: "1.5px",
    color: "#14B779!important",
    borderBottom: "none!important",
  },
  dateCell: {
    fontSize: "16px", // Larger font for the date
    fontWeight: "600",
    color: "#000000",
    "& h6": {
      fontSize: "16px", // Control date size here
    },
    "& body2": {
      fontSize: "12px", // Control time size here
      fontWeight: "400",
      color: "#777777", // Slightly lighter color for the time
    },
  },
  tableRow: {
    height: "90px",
    backgroundColor: "rgba(242, 244, 246, 0.4)",
    "&:hover": {
      backgroundColor: "#F1F7FE!important",
    },
  },
  tableRow2: {
    height: "70px",
    backgroundColor: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#F1F7FE!important",
    },
  },
  selectedTableRow: {
    backgroundColor: "#F1F7FE!important",
  },
  progressPrimaryError: {
    backgroundColor: "#14B779",
  },
  progressPrimaryNormal: {
    backgroundColor: "rgba(31, 124, 242, 0.16)",
  },
  progressBarError: {
    backgroundColor: "#14B779",
  },
  progressBarNormal: {
    backgroundColor: "#1F7CF2",
  },
  jobActionButton: {
    width: "100%",
    color: "#FFFFFF",
    textTransform: "initial",
    fontSize: "16px",
    fontWeight: "500!important",
    borderRadius: "6px",
    "&.Mui-disabled": {
      background: "rgba(0, 0, 0, 0.12)!important",
      color: "rgba(0, 0, 0, 0.26)",
    },
  },
  jobActionButton1: {
    color: "#FFFFFF",
    borderRadius: "6px",
    padding: "8px 16px",
    "&:hover": {
      background: "#1F7CF2",
    },
    "&.Mui-disabled": {
      background: "rgba(0, 0, 0, 0.12)!important",
      color: "rgba(0, 0, 0, 0.26)",
    },
  },
  jobActionButton2: {
    color: "#FFFFFF",
    borderRadius: "6px",
    background: "#1F7CF2",
    marginLeft: "15px",
    padding: "8px 16px",
    "&:hover": {
      background: "#1F7CF2",
    },
    "&.Mui-disabled": {
      background: "rgba(0, 0, 0, 0.12)!important",
      color: "rgba(0, 0, 0, 0.26)",
    },
  },
  jobActionButton3: {
    color: "#FFFFFF",
    borderRadius: "6px",
    background: "#1F7CF2",
    marginRight: "15px!important",
    padding: "8px 16px",
    "&:hover": {
      background: "#1F7CF2",
    },
    "&.Mui-disabled": {
      background: "rgba(0, 0, 0, 0.12)!important",
      color: "rgba(0, 0, 0, 0.26)",
    },
  },
  jobActionButton4: {
    background: "#3d3d3d",
    minWidth: "55px",
    "&.Mui-disabled": {
      background: "rgba(0, 0, 0, 0.12)!important",
      color: "rgba(0, 0, 0, 0.26)",
    },
  },
  buttonCSV: {
    background: "#FFFFFF!important",
    color: "#1F7CF2!important",
    fontSize: "16px!important",
    fontWeight: "600!important",
    width: "100%!important",
    borderRadius: "6px!important",
  },
  buttonQueue: {
    background: "#e0491b!important",
    color: "#FFFFFF!important",
    fontSize: "16px!important",
    fontWeight: "600!important",
    width: "100%!important",
    borderRadius: "6px!important",
    marginTop: "20px!important",
    "&:hover": {
      background: "#e0491b!important",
    },
  },
  buttonInventory: {
    color: "#14B779!important",
    fontSize: "16px!important",
    fontWeight: "600!important",
    "&:hover": {
      background: "transparent!important",
    },
  },
  itemNavButton: {
    fontSize: "14px",
    fontWeight: "600",
    letterSpacing: "1.5px",
    color: "#000000",
    borderRadius: "0",
    width: "100%",
  },
  pagination: {
    display: "flex",
    justifyContent: "center",
    marginTop: "20px",
  },
}));

const History = (props) => {
  const { historyDocs: savedHistoryDocs } = useBatchContext();
  const [historyDocs, setHistoryDocs] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [batchStyle, setBatchStyle] = useState("All");
  const [batchStatus, setBatchStatus] = useState("All");
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [sort, setSort] = useState("Newest");
  const [anchorEl, setAnchorEl] = useState(null);
  const [keyWords, setKeyWords] = useState("");
  const [batchSource, setBatchSource] = useState("All");
  const [noResults, setNoResults] = useState(false);

  const [selectedFilters, setSelectedFilters] = useState({
    all: true,
    socks: false,
    socksCustom: false,
    socksInline: false,
    underwear: false,
  });

  const db = props.firebase.getdb();

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setSelectedFilters((prevFilters) => {
      let newFilters = { ...prevFilters, [name]: checked };

      // if nothing was s
      // If "socks" is checked, check both "socksCustom" and "socksInline"
      if (name === "socks") {
        newFilters = {
          ...newFilters,
          socks: checked,
          socksCustom: checked,
          socksInline: checked,
        };
      }

      // If either "socksCustom" or "socksInline" is unchecked, uncheck "socks"
      if ((name === "socksCustom" || name === "socksInline") && !checked) {
        newFilters.socks = false; // Uncheck "socks"
      }

      // If both "socksCustom" and "socksInline" are checked, check "socks"
      if (newFilters.socksCustom && newFilters.socksInline) {
        newFilters.socks = true; // Re-check "socks" if both sub-options are checked
      }

      // If "all" is checked, uncheck all other options
      if (name === "all" && checked) {
        newFilters = {
          all: true,
          socks: false,
          socksCustom: false,
          socksInline: false,
          underwear: false,
        };
      }

      // If any option other than "all" is checked, uncheck "all"
      if (checked && name !== "all") {
        newFilters.all = false;
      }

      // Check if all options are unchecked, then check "all"
      const allUnchecked =
        !newFilters.socks &&
        !newFilters.socksCustom &&
        !newFilters.socksInline &&
        !newFilters.underwear;

      if (allUnchecked) {
        newFilters.all = true; // Automatically check "all" if everything else is unchecked
      }

      return newFilters;
    });
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const fetchHistory = (unsubscribe) => {
    if (keyWords === "") {
      // Automatically fetch history if the search box is empty
      unsubscribe = searchHistory(
        {
          textSearch: keyWords,
          rowsPerPage: itemsPerPage,
          pageNumber: currentPage,
          filters: "",
          batchSource,
          batchStatus,
          batchStyle,
        },
        (historyDocs, { productCount, pages }) => {
          setHistoryDocs(historyDocs);
          setTotalPages(pages);
        },
        db
      );
    }
  };

  useEffect(() => {
    let unsubscribe = null;
    fetchHistory(unsubscribe);
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [
    batchSource,
    batchStatus,
    batchStyle,
    itemsPerPage,
    currentPage,
    savedHistoryDocs,
    keyWords,
  ]);

  const classes = useStyles();

  const storage = props.firebase.getstorage();

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(event.target.value);
    setCurrentPage(1);
  };

  const resetFilters = () => {
    setBatchStyle("All");
    setBatchStatus("All");
    setSort("Newest");
    setKeyWords("");
    setCurrentPage(1);
    setItemsPerPage(20);
    setBatchSource("All");
    setNoResults(false);
  };

  const handleSearch = (event) => {
    if (event.key === "Enter") {
      setCurrentPage(1); // Optionally reset to the first page
      searchHistory(
        {
          textSearch: keyWords,
          rowsPerPage: itemsPerPage,
          pageNumber: currentPage,
          filters: "",
          batchSource,
          batchStatus,
          batchStyle,
        },
        (historyDocs, { productCount, pages }) => {
          setNoResults(productCount === 0);
          setHistoryDocs(historyDocs);
          setTotalPages(pages);
        },
        db
      );
    }
  };

  const onDelete = (batchId) => {
    let unsubscribe = null;
    fetchHistory(unsubscribe);
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  };

  return (
    <>
      <Box sx={{ marginTop: "30px" }}>
        <div className="pageHeader">
          <div className="pageHeading">
            <Typography variant="h1">Print Recently</Typography>
          </div>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search History"
              sx={{ width: "100%" }}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              value={keyWords}
              inputProps={{ "aria-label": "search" }}
              onChange={(event) => setKeyWords(event.target.value)} // Update state on typing
              onKeyUp={handleSearch} // Trigger search on Enter key
            />
          </div>
        </div>
        <div className="pageToolbar">
          <div className={classes.filterBox}>
            <>
              <FormControl
                variant="outlined"
                size="small"
                style={{ minWidth: 100, marginRight: 16 }}
              >
                <InputLabel id="items-per-page-label">
                  Items per page
                </InputLabel>
                <Select
                  labelId="items-per-page-label"
                  id="items-per-page"
                  value={itemsPerPage}
                  onChange={handleItemsPerPageChange}
                  label="Items per page"
                >
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                </Select>
              </FormControl>

              <FormControl
                variant="outlined"
                size="small"
                style={{ minWidth: 100, marginRight: 16 }}
              >
                <InputLabel id="page-label">Batch Type</InputLabel>
                <Select
                  labelId="page-label"
                  id="page"
                  value={batchSource}
                  onChange={(event) => setBatchSource(event.target.value)}
                  label="Batch Type"
                >
                  <MenuItem value="All">All</MenuItem>
                  <MenuItem value="Web">Web</MenuItem>
                  <MenuItem value="DS">DS</MenuItem>
                  <MenuItem value="Manual">Manual</MenuItem>
                  <MenuItem value="Wholesale">Wholesale</MenuItem>
                </Select>
              </FormControl>

              <FormControl
                variant="outlined"
                size="small"
                style={{ minWidth: 100, marginRight: 16 }}
              >
                <InputLabel id="page-label">Batch Style</InputLabel>
                <Select
                  labelId="page-label"
                  id="page"
                  value={batchStyle}
                  onChange={(event) => setBatchStyle(event.target.value)}
                  label="Batch Style"
                >
                  <MenuItem value="All">All</MenuItem>
                  <MenuItem value="Shuttle">Shuttle</MenuItem>
                  <MenuItem value="Belt">Belt</MenuItem>
                </Select>
              </FormControl>

              <FormControl
                variant="outlined"
                size="small"
                style={{ minWidth: 100, marginRight: 16 }}
                label="Batch Status"
              >
                <InputLabel id="page-label">Batch Status</InputLabel>
                <Select
                  labelId="page-label"
                  id="page"
                  value={batchStatus}
                  onChange={(event) => setBatchStatus(event.target.value)}
                  label="Batch Status"
                >
                  <MenuItem value="All">All</MenuItem>
                  <MenuItem value="Fulfilled">Fulfilled</MenuItem>
                  <MenuItem value="Not Fulfilled">Not Fulfilled</MenuItem>
                </Select>
              </FormControl>

              <FormControl
                variant="outlined"
                size="small"
                style={{ minWidth: 100, marginRight: 16 }}
              >
                <InputLabel id="page-label">Sort</InputLabel>
                <Select
                  labelId="page-label"
                  id="page"
                  value={sort}
                  onChange={(event) => setSort(event.target.value)}
                  label="Sort"
                >
                  <MenuItem value="Newest">Newest to oldest</MenuItem>
                  <MenuItem value="Oldest">Oldest to newest</MenuItem>
                </Select>
              </FormControl>

              <FormControl variant="outlined" size="small" style={{}}>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                >
                  <FormGroup style={{ padding: "0 16px" }}>
                    {/* All Option */}
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedFilters.all}
                          onChange={handleCheckboxChange}
                          name="all"
                        />
                      }
                      label="All"
                    />

                    {/* Socks Option */}
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedFilters.socks}
                          onChange={handleCheckboxChange}
                          name="socks"
                        />
                      }
                      label="Socks"
                    />

                    {/* Sub-options for Socks */}
                    <div style={{ paddingLeft: "20px" }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedFilters.socksCustom}
                            onChange={handleCheckboxChange}
                            name="socksCustom"
                          />
                        }
                        label="Custom"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectedFilters.socksInline}
                            onChange={handleCheckboxChange}
                            name="socksInline"
                          />
                        }
                        label="Inline"
                      />
                    </div>

                    {/* Underwear Option */}
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedFilters.underwear}
                          onChange={handleCheckboxChange}
                          name="underwear"
                        />
                      }
                      label="Underwear"
                    />
                  </FormGroup>
                </Menu>
              </FormControl>
              <Button
                variant="outlined"
                onClick={resetFilters}
                sx={{
                  marginRight: 2,
                  marginBottom: 2,
                }}
              >
                Reset
              </Button>
            </>
          </div>
        </div>

        {historyDocs?.length > 0 ? (
          <TableContainer sx={{ marginTop: "30px", marginBottom: "50px" }}>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size={"medium"}
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                classes={classes}
                useCheckbox={true}
                cells={headCells}
              />

              <TableBody>
                {historyDocs.map((row, index) => {
                  const odd = index % 2 === 0;
                  const labelId = `history-table-checkbox-${index}`;
                  const keyId = `key-${row.batchId}-${index}`;
                  row.odd = odd;
                  return (
                    <Row
                      key={keyId}
                      label={labelId}
                      viewOnly={props.viewOnly}
                      editOnly={props.editOnly}
                      row={row}
                      classes={classes}
                      db={db}
                      query=""
                      storage={storage}
                      rowDataLocalState={false}
                      formats={props.formats}
                      onDelete={onDelete}
                      sizes={props.sizes}
                    />
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        ) : !noResults ? (
          <Box
            sx={{
              marginTop: "30px",
              marginBottom: "50px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Typography variant="h6">Loading...</Typography>
            <CircularProgress />
          </Box>
        ) : (
          <Box
            sx={{
              marginTop: "30px",
              marginBottom: "50px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              padding: "20px",
              borderRadius: "8px",
              backgroundColor: "#f9f9f9",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            }}
          >
            <img
              src={noResultsImage}
              alt="No history"
              style={{ width: "120px", marginBottom: "20px" }}
            />
            <Typography
              variant="h6"
              sx={{ color: "#555", fontWeight: "bold", marginBottom: "10px" }}
            >
              No History Found
            </Typography>
            <Typography
              variant="body2"
              sx={{ color: "#777", maxWidth: "400px" }}
            >
              It seems like there's no data available for search keyword:{" "}
              <strong>{keyWords}</strong>
            </Typography>
          </Box>
        )}
      </Box>
      <div className={classes.pagination}>
        <Pagination
          count={totalPages}
          page={currentPage} // Current page
          onChange={handleChangePage} // Handle page change
          sx={{ marginBottom: 2 }}
          showFirstButton
          showLastButton
        />
      </div>
    </>
  );
};

export default History;
