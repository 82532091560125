import React from "react";
import { API } from "../constants/defines";


const useGetBundleCsv = ( callBack ) => {
  
  React.useEffect(() => {
    const getBundleCsv = async () => {

      try {
        
        const response = await fetch(API.getBundlesCsv, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          }
        });
        
        const data = await response.json();
        
        callBack(data);

      } catch (error) {
        console.error('Error in getBundleCsv: ', error);
      }

    };

    getBundleCsv();
  }, []);



}

export default useGetBundleCsv;