import { collections } from '../constants/defines';
import { formatDate1 } from '../module/functions';

import _ from "lodash";
import {
  collection,
  query,
  where,
  limit,
  getDocs,
  orderBy,
  doc,
  updateDoc,
  addDoc,
  setDoc,
  getDoc,
  deleteDoc,
  onSnapshot,
  writeBatch
} from "firebase/firestore";
import { deleteMultipleOrders, deleteWholeSaleOrder, switchProductionOrderToWholeSaleReg, switchWholeSaleOrderToProduction, updateItemsInOrder, updateTableOrderState, updateWholeSaleOrder } from '../redux/wholeSaleOrders/actions';

const getQBOrderQueue = (db, callback) => {
  const q = query(collection(db, collections.QUICKBOOKS),
    where("production", "==", false),
    where("orderStatus", "==", "normal"),
    orderBy("tableOrder"),
    orderBy("createdAt")
  );
  onSnapshot(q, (querySnapshot) => {
    console.log('QB Orders Changed...');
    const apiQueue = [];

    querySnapshot.forEach((doc) => {
      let data = doc.data();
      const docId = doc.id;

      const { docNumber, poNumber, createdAt, cancelDate, txnDate, shipDate, billAddress, shipAddress, customer, email, note, totalAmount, priority } = data;
      let units = 0, isError = false;

      const items = _.map(data.items, item => {
        units += parseInt(item.qty);
        if (item.error !== "")
          isError = true;
        const status = !!item.status ? item.status : 0;
        return { ...item, status };
      })

      apiQueue.push({
        id: docId,
        order: data.orderNumber,
        docNumber,
        poNumber,
        createdAt: formatDate1(createdAt),
        cancelDate: cancelDate ? formatDate1(cancelDate) : "",
        txnDate: formatDate1(txnDate),
        shipDate: shipDate ? formatDate1(shipDate) : "",
        billAddress,
        shipAddress,
        customer,
        email,
        note,
        totalAmount,
        source: "QuickBooks",
        units,
        items,
        companyId: !!data.companyId ? data.companyId : "",
        attachments: !!data.attachments ? data.attachments : [],
        priority,
        isError
      });
    });
    callback(apiQueue);
  });
}

const getQBProductionOrderQueue = (db, callback) => {
  const q = query(collection(db, collections.QUICKBOOKS),
    where("production", "==", true),
    where("orderStatus", "==", "normal"),
    where("printed", "==", false),
    orderBy("tableOrder")
  );

  onSnapshot(q, (querySnapshot) => {
    console.log('QB Orders Changed...');
    const apiQueue = [];
    querySnapshot.forEach((doc) => {
      let data = doc.data();
      const docId = doc.id;

      const { docNumber, poNumber, createdAt, cancelDate, txnDate, shipDate, billAddress, shipAddress, customer, email, note, totalAmount, priority } = data;
      let units = 0, isError = false;

      const items = _.map(data.items, item => {
        units += parseInt(item.qty);
        if (item.error !== "")
          isError = true;
        const status = !!item.status ? item.status : 0;
        return { ...item, status };
      })

      apiQueue.push({
        id: docId,
        order: data.orderNumber,
        docNumber,
        poNumber,
        createdAt: formatDate1(createdAt),
        cancelDate: cancelDate ? formatDate1(cancelDate) : "",
        txnDate: formatDate1(txnDate),
        shipDate: shipDate ? formatDate1(shipDate) : "",
        billAddress,
        shipAddress,
        customer,
        email,
        note,
        totalAmount,
        source: "QuickBooks",
        units,
        items,
        companyId: !!data.companyId ? data.companyId : "",
        attachments: !!data.attachments ? data.attachments : [],
        priority,
        isError
      });
    });
    callback(apiQueue);
  });
}

const addToProduction = (db, queue, dispatch) => {
  const batch = writeBatch(db);

  _.each(queue, orderId => {
    const docRef = doc(db, collections.QUICKBOOKS, orderId.toString());

    // Manually construct the object to be set for logging
    const docData = {
      production: true,
      printed: false,
      tableOrder: 100,
      productionDate: new Date()
    };

    dispatch(switchWholeSaleOrderToProduction(orderId, docData));

    // Use the constructed object in the batch operation
    batch.set(docRef, docData, { merge: true });
  });

  // Commit batch
  return batch.commit().then(function () {
    console.log(`Added ${queue.length} orders to production!`);
  }).catch(function (error) {
    console.error(`Error committing batch: ${error.message}`);
  });

}

const updatePriority = (db, orderId, priority, dispatch, isProduction) => {
  return new Promise((resolve, reject) => {
    const docRef = doc(db, collections.QUICKBOOKS, orderId);
    updateDoc(docRef, { priority })
      .then(() => {
        console.log("Order Priority successfully updated!", orderId, priority);
        dispatch(updateWholeSaleOrder(orderId, { priority }, isProduction));
        resolve();
      })
      .catch((error) => {
        // The document probably doesn't exist.
        console.error("Error updating Irder Priority: ", error);
        reject();
      });
  });
}

const updateTableOrder = (db, orders, dispatch) => {
  const batch = writeBatch(db);

  _.each(orders, (order, index) => {
    const tableOrder = index + 1;
    const docRef = doc(db, collections.QUICKBOOKS, order.order.toString());

    // Here you should include whether the order is a production order in your payload
    dispatch(updateTableOrderState({
      orderId: order.id,
      tableOrder,
    }))

    // Use batch.set to update Firestore document
    batch.set(docRef, { tableOrder }, { merge: true });
  });

  // Commit batch
  return batch.commit().then(() => {
    console.log('Commit Success!');
  }).catch((error) => {
    console.error(`Error committing batch: ${error.message}`);
  });
};

const markOrdersAsPrinting = (db, orders, dispatch) => {
  const batch = writeBatch(db);
  _.each(orders, (order, index) => {
    const newItems = _.map(order.items, item => {
      const status = item.status == 0 ? 1 : item.status;
      return { ...item, status };
    });


    const docRef = doc(db, collections.QUICKBOOKS, order.order);

    const dataUpdate = { items: newItems, isPrinting: true, printed: false }

    batch.set(docRef, dataUpdate, { merge: true });
  });

  // Commit batch
  return batch.commit().then(function () {
    console.log('Commit Success!');
  }).catch(function (error) {
    console.error(`Error comitting batch: ${error.message}`);
  });
}

const markOrderItemsAsPrinting = (db, order, newItems, dispatch, deleteWholeOrder, selectedAdjusted) => {
  console.log('markOrderItemsAsPrinting');

  const batch = writeBatch(db);

  console.log('selectedAdjusted: ', selectedAdjusted)

  const _newItems = _.map(order.items, item => {

    // if item is in selectedAdjusted, then mark status as 1, else keep the same status
    const status = _.find(selectedAdjusted, { id: item.id }) ? 1 : item.status;

    return { ...item, status };


  })

  const docRef = doc(db, collections.QUICKBOOKS, order.order);

  if (deleteWholeOrder) {
    dispatch(deleteWholeSaleOrder(order.id, true));
  }

  const dataObject = {
    items: _newItems,
    isPrinting: true,
    printed: false
  }

  console.log('dataObject', dataObject);

  batch.set(docRef, dataObject, { merge: true });

  // Commit batch
  return batch.commit().then(function () {
    console.log('Commit Success! Mark Order Items As Printing');
  }).catch(function (error) {
    console.error(`Error comitting batch: ${error.message}`);
  });

}

const createBatch = (db, batchName, secondaryBatchName, color, underwearFormat, barcode, orders, callback, deleteWholeOrder, selectedAdjusted) => {
  const currentUser = localStorage.getItem("currentuser");
  const batchId = Date.now().toString();

  const requests = _.map(orders, (order, index) => {
    const inlinePrintBatch = {
      batch: batchId + index,
      date: new Date(),
      source: "Wholesale",
      sheetType: "shuttle",
      name: `${order.docNumber} - ${batchName}`,
      baseName: batchName,
      secondary: secondaryBatchName,
      underwearFormat,
      color,
      email: currentUser,
      lineItems: [],
      printed: false,
      bucket: "",
      priority: 2,
      barcode,
      reference: '1234567890JG'
    };


    order.items.forEach(item => {

      // now loop through selectedAdjusted and check if item.id is in selectedAdjusted.id
      selectedAdjusted.forEach(selected => {
        if (selected.id === String(item.id)) {
          const { sku, qty, error, isBoxSet } = item;
          inlinePrintBatch.lineItems.push({
            orderId: order.poNumber,
            orderNumber: order.poNumber,
            customer: order.customer,
            sku,
            quantity: qty,
            error: error || "", // Using short-circuit evaluation to handle falsey values
            itemId: `${order.id}-${item.id}`, // Assuming order.id is the correct reference
            isBoxSet
          });
        }
      });

    });

    return addDoc(collection(db, collections.ORDERS), inlinePrintBatch);

  });

  Promise.all(requests).then(() => {
    callback(true, orders, batchName, deleteWholeOrder, selectedAdjusted);
  });

}

const updateOrder = (db, orderId, items, production, dispatch) => {
  return new Promise((resolve, reject) => {
    const docRef = doc(db, collections.QUICKBOOKS, orderId);

    const updatedObject = {
      production,
      items,
      printed: false
    }

    dispatch(switchProductionOrderToWholeSaleReg(orderId, updatedObject));

    updateDoc(docRef, updatedObject)
      .then(() => {
        console.log("QB Order successfully updated!");
        resolve();
      })
      .catch((error) => {
        // The document probably doesn't exist.
        console.error("Error updating QB Order: ", error);
        reject();
      });
  });
}

const updateOrderItems = (db, orderId, items, dispatch, isProduction, updateDataObjectForRedux) => {
  return new Promise((resolve, reject) => {
    resolve();

    dispatch(updateWholeSaleOrder(orderId, updateDataObjectForRedux, isProduction));

    const docRef = doc(db, collections.QUICKBOOKS, orderId);

    updateDoc(docRef, {
      items,
    })
      .then(() => {
        console.log("QB Order successfully updated!");
        resolve();
      })
      .catch((error) => {
        // The document probably doesn't exist.
        console.error("Error updating QB Order: ", error);
        reject();
      });

  });
}

const updateStatusNumberInItem = (db, orderId, status, itemId) => {
  return new Promise((resolve, reject) => {
    const docRef = doc(db, collections.QUICKBOOKS, orderId);

    getDoc(docRef).then((doc) => {
      if (doc.exists()) {
        const data = doc.data();
        const items = _.map(data.items, item => {
          if (item.id === itemId) {
            return { ...item, status };
          }
          return item;
        });

        updateDoc(docRef, { items })
          .then(() => {
            console.log("QB Order successfully updated!");
            resolve();
          })
          .catch((error) => {
            // The document probably doesn't exist.
            console.error("Error updating QB Order: ", error);
            reject();
          });
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
        reject();
      }
    }).catch((error) => {
      console.log("Error getting document:", error);
      reject();
    });
  });

}

const markOrderAsDelete = (db, orderId, dispatch, isProduction = false) => {
  return new Promise((resolve, reject) => {
    deleteDoc(doc(db, collections.QUICKBOOKS, orderId))
      .then(() => {
        console.log("QB Order successfully deleted!");
        dispatch(deleteWholeSaleOrder(orderId, isProduction));
        resolve();
      })
      .catch((error) => {
        // The document probably doesn't exist.
        console.error("Error deleting QB Order: ", error);
        reject();
      });
  });
}

// ! For multiple orders/and or FOR DELETE BUTTON AT TOP
const markOrdersAsDelete = (db, orders, dispatch, isProduction) => {
  const batch = writeBatch(db);


  _.each(orders, (order) => {
    const docRef = doc(db, collections.QUICKBOOKS, order);
    batch.delete(docRef);
  });

  dispatch(deleteMultipleOrders(orders, isProduction));

  // Commit batch
  return batch.commit().then(function () {
    console.log('Commit Success!');
  }).catch(function (error) {
    console.error(`Error comitting batch: ${error.message}`);
  });
}

export {
  getQBOrderQueue,
  getQBProductionOrderQueue,
  addToProduction,
  updatePriority,
  updateOrder,
  updateOrderItems,
  updateTableOrder,
  createBatch,
  markOrderAsDelete,
  markOrdersAsDelete,
  markOrdersAsPrinting,
  markOrderItemsAsPrinting,
  updateStatusNumberInItem
};
