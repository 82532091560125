import { doc, getDoc, writeBatch } from 'firebase/firestore';
import { collections } from '../constants/defines';

// Helper function to fetch document from custom or inline queue
const fetchOrderDoc = async (db, order, isCustomRow) => {
  let ref = doc(db, isCustomRow ? collections.DS_CUSTOM_QUEUE : collections.DS_INLINE_QUEUE, order.order.toString());
  let docSnap = await getDoc(ref);

  // If the document doesn't exist in the primary collection, check the other collection
  if (!docSnap.exists() && isCustomRow) {
    ref = doc(db, collections.DS_INLINE_QUEUE, order.order.toString());
    docSnap = await getDoc(ref);
  } else if (!docSnap.exists() && !isCustomRow) {
    ref = doc(db, collections.DS_CUSTOM_QUEUE, order.order.toString());
    docSnap = await getDoc(ref);
  }

  // Return both the reference and the document snapshot
  return { ref, docSnap };
};

// Helper function to filter SKUs
const filterSkus = (existingSkus, orderSkus) => {
  // Filter the items where the sku matches any in the orderSkus array
  return existingSkus.filter(item => orderSkus.includes(item.sku));
};

// Main function for restoring orders
const restoreOrdersBatchForDs = async (db, orders, isCustomRow) => {
  try {
    const batch = writeBatch(db);

    for (const order of orders) {
      // Fetch the document based on the queue type
      const { ref, docSnap } = await fetchOrderDoc(db, order, isCustomRow);

      // If the document doesn't exist in both queues, log a warning and skip
      if (!docSnap.exists()) {
        console.warn(`Order ${order.order} not found in both custom and inline queues.`);
        continue;
      }

      // Get the document's data and existing SKUs
      const docData = docSnap.data();
      const existingSkus = docData.items || [];

      // Filter the SKUs based on the order SKUs
      const updatedSkus = filterSkus(existingSkus, order.skus);

      batch.set(ref, {
        isPrinting: false,
        fulfill: 'unfulfilled',
        hasBeenRestored: true,
        items: updatedSkus
      }, { merge: true });
    }

    await batch.commit();

    return true;

  } catch (error) {
    console.error(`Error processing batch: ${error.message}`, error);
    return false;
  }
};

export default restoreOrdersBatchForDs;
