import React from 'react';
import { Tooltip, Typography, Box } from '@mui/material';
import _ from 'lodash';

function CustomTooltip({ row, sizes, formats }) {
  const tooltipStyle = {
    padding: '10px',
    maxWidth: 250,
    border: '1px solid #ddd'
  };

  // Function to aggregate item data with error handling for missing information
  const getAggregatedItems = () => {
    const itemMap = new Map();

    row.items.forEach(item => {
      const size = _.find(sizes, { id: item.size });
      const format = _.find(formats, { id: item.format });
      let key;

      if (!size || !format) {
        // Handle missing size or format by creating an error type key
        const errorType = [];
        if (!size) errorType.push('Missing Size');
        if (!format) errorType.push('Missing Format');
        key = `Error: ${errorType.join(' & ')}`;
      } else {
        // Normal key with format and size
        const sizeName = size.name;
        const formatName = format.name;
        key = `${formatName.toUpperCase()} ${item.style.toUpperCase()} ${sizeName.toUpperCase()}`;
      }

      if (itemMap.has(key)) {
        itemMap.set(key, itemMap.get(key) + item.qty);
      } else {
        itemMap.set(key, item.qty);
      }
    });

    return itemMap;
  };

  // Render tooltip content with aggregated items, including error types
  const renderTooltipContent = () => {
    const itemMap = getAggregatedItems();

    return (
      <Box sx={tooltipStyle}>
        {Array.from(itemMap.entries()).map(([key, qty]) => (
          <Typography key={key} variant="body2">
            {key} - {qty}
          </Typography>
        ))}
      </Box>
    );
  };

  return (
    <Tooltip title={renderTooltipContent()} placement="top-end" arrow>
      <Typography variant="body2">{row.units}</Typography>
    </Tooltip>
  );
}

export default CustomTooltip;
