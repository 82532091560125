import React, { useContext, useState, useEffect, useRef } from 'react';
import { withStyles, makeStyles } from '@mui/styles';

// Material UI dependencies
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ReplayIcon from '@mui/icons-material/Replay';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import SyncIcon from '@mui/icons-material/Sync';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';

import moment from "moment";
import { useDrag, useDrop } from 'react-dnd';
import { HtmlTooltip } from '../Page/tooltip';
import { lookupItems } from '../../db/products';
import { updatePriority, updateOrder, updateOrderItems, markOrderAsDelete } from '../../db/quickbooks';
import axios from 'axios';
import { API } from '../../constants/defines';
import TableContext from '../Page/tableContext';
import { ConfirmDialog } from '../Page/generalModal';
import { palette } from '../../theme/colors';

import { useDispatch, useSelector } from 'react-redux';
import { keywords, validateImageInStorage } from '../../utils/validateImageInStorage';
import { CircularProgress } from '@mui/material';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ItemActionsCell from './reprint';
import { Toast } from '../Toast';
import { listenToWholesaleChange } from '../../utils/getWholesaleInfoLive';
import { closeRow, openRow } from '../../redux/wholeSaleOrders/actions';
import CustomTooltip from './itemCountModal';
import ItemsRow from './itemsRow';
import { useShiftSelectedWQ } from '../../hooks/useSelecteManyWQ';


const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getcontentanchorel={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
));

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  table: {
    minWidth: 750,
  },
  tableHead: {
    background: 'rgba(217, 223, 233, 0.4)'
  },
  tableHeadCell: {
    lineHeight: '27.5px'
  },
  tableHeadLabel: {
    fontSize: '14px',
    fontWeight: '500',
    textTransform: 'uppercase',
    color: '#000000',
    letterSpacing: '1.5px'
  },
  tableCheckbox: {
  },
  overdue: {
    color: 'red',
    fontSize: '14px',
    fontWeight: '500',
    textTransform: 'uppercase'
  },
  tableCell: {
    fontSize: '14px',
    fontWeight: '500',
    textTransform: 'initial',
    letterSpacing: '1.2px',
    color: '#000000'
  },
  tableCell1: {
    fontSize: '14px',
    fontWeight: '500',
    textTransform: 'capitalize',
    letterSpacing: '1.2px',
    color: '#000000'
  },
  tableCell2: {
    fontSize: '14px',
    fontWeight: '500',
    textTransform: 'uppercase',
    letterSpacing: '1.2px',
    color: '#FFFFFF',
    display: 'inline-block',
    background: '#05aa1d',
    padding: '5px 12px',
    borderRadius: '5px'
  },
  tableCellError: {
    fontSize: '14px',
    fontWeight: '500',
    textTransform: 'uppercase',
    letterSpacing: '1.2px',
    color: 'red'
  },
  tableError: {
    fontSize: '14px',
    fontWeight: '500',
    letterSpacing: '1.2px',
    color: 'red'
  },
  tableError1: {
    paddingLeft: '10px'
  },
  tableRow: {
    height: '70px',
    backgroundColor: 'rgba(242, 244, 246, 0.4)',
  },
  tableRow2: {
    height: '70px',
    backgroundColor: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#F1F7FE'
    }
  },
  tableRowError: {
    height: '70px',
    backgroundColor: '#ffdbdb',
  },
  selectedTableRow: {
    backgroundColor: '#F1F7FE'
  },
  pagination: {
    marginTop: '60px',
    '& ul': {
      justifyContent: 'center'
    },
  },
  button: {
    padding: '9px 32px',
    fontSize: '16px',
    fontWeight: '500',
    color: '#ffffff',
    textTransform: 'initial',
    marginRight: '16px',
    background: '#1f7cf2'
  },
  formLabel: {
    color: '#000000',
    fontSize: '14px',
    fontWeight: '500',
    letterSpacing: '1.5px'
  },
  toolbar: {

  },
  orderTitle: {
    fontSize: '28px',
    textTransform: 'uppercase',
    fontWeight: '400',
    letterSpacing: '2px'
  },
  orderSmallTitle: {
    fontSize: '18px',
    textTransform: 'uppercase',
    fontWeight: '400',
    letterSpacing: '1.5px'
  },
  detailTitle: {
    textTransform: 'uppercase',
    fontWeight: 'bold'
  },
  detailText: {

  },
  itemTableCell: {
    fontSize: '14px',
    fontWeight: '500!important',
    letterSpacing: '1.5px',
    color: '#000000',
    textTransform: 'uppercase',
    borderBottom: 'none'
  },
  itemTableCell2: {
    fontSize: '14px',
    fontWeight: '500!important',
    letterSpacing: '1.5px',
    color: 'red!important',
    borderBottom: 'none!important'
  },
  indicator: {
    fontSize: '0'
  },
  downloadTitle: {
    fontSize: '17px',
    fontWeight: '500!important',
  },
  downloadText: {
    fontSize: '15px',
    color: 'gray',
    letterSpacing: '1.5px',
    textTransform: 'uppercase',
  },
  downloadBox: {
    borderRadius: '7px',
    padding: '10px 10px',
    border: '2px solid rgba(224, 224, 224, 1)'
  },
  buttonDownload: {
    color: 'gray'
  },
  detailHeader: {
    display: 'flex',
    justifyContent: 'space-between'
  }
}));

function Attachment(props) {
  const { companyId, itemId, name } = props;
  const classes = useStyles();

  const getAttachableLink = (callback) => {
    const url = `${API.downloadAttachable}`;
    let params = { companyId, itemId };

    fetch(url, {
      method: 'post',
      body: JSON.stringify(params)
    }).then(function (response) {
      return response.json();
    }).then(function (data) {
      console.log('Response:', data);
      if (data.success) {
        callback(data.url);
      }
    });
  }
  const handlePreview = () => {
    getAttachableLink(url => {
      const link = global.document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", name);
      link.setAttribute("target", "_blank");

      global.document.body.appendChild(link); // Required for FF
      link.click();
      global.document.body.removeChild(link);
    });
  }

  const handleDownload = () => {
    getAttachableLink(url => {
      var req = new XMLHttpRequest();
      req.open("GET", url, true);
      req.responseType = "blob";

      req.onload = function (event) {
        var blob = req.response;
        console.log(blob.size);
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = name;
        link.click();
      };

      req.send();
    });
  }

  return (
    <Box className={classes.downloadBox}>
      <Typography className={classes.downloadTitle}>{name}</Typography>
      <Box sx={{ marginTop: '0px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Button className={classes.downloadText} onClick={() => handlePreview()}>Preview Document</Button>
        <IconButton className={classes.buttonDownload} onClick={() => handleDownload()}>
          <CloudDownloadIcon />
        </IconButton>
      </Box>
    </Box>
  );
}

function OrderItemsTable(props) {

  const { items, classes, dispatch, isProduction, order, db, selection, selectedRowIds } = props;

  const data = useContext(TableContext);

  const handleClick = React.useCallback((event, id, joinIds) => {

    event.stopPropagation();

    // join by string selection 

    const selected = selection.split(',');

    const arrayMade = [...selected]

    // remove empty string
    const arrayOfSelections = arrayMade.filter(item => item !== '');
    let newSelected

    if (joinIds) {

      // ids come here as a string joined like : 1,2,3,4
      const ids = id.split(',');
      const selectedIds = ids.map(id => id.toString());

      // check if all selected ids are already in the array
      const allSelected = selectedIds.every(id => arrayOfSelections.includes(id.toString()));

      if (allSelected) {
        newSelected = arrayOfSelections.filter(item => !selectedIds.includes(item));
      }

      // if not all selected ids are in the array, add them
      else {
        newSelected = [...arrayOfSelections, ...selectedIds];
      }



    } else {
      newSelected = arrayOfSelections.includes(id)
        ? arrayOfSelections.filter(item => item !== id)
        : [...arrayOfSelections, id];
    }
    data.setSelect(props.order, newSelected); // Update context or global state if needed
  }, [selection, data, props.order]);


  const isSelected = (id) => {
    if (selection === "")
      return false;
    const strIds = selection.split(",");
    const checked = strIds.indexOf(id) !== -1;
    return checked;
  }

  const _items = _.filter(items, item => item.status < 3);


  const handleRowClick = useShiftSelectedWQ(_items, data.setSelect, handleClick, selectedRowIds, dispatch, props.order, isProduction);

  return (
    <Table size="small" aria-label="items" className={classes.itemTable}>
      <TableHead>
        <TableRow>
          <TableCell className={classes.itemTableCell} sx={{ width: '20px' }}></TableCell>
          <TableCell className={classes.itemTableCell} align="center" sx={{ width: '50px' }}>QTY</TableCell>
          <TableCell className={classes.itemTableCell} align="center" sx={{ width: '50px' }}>Type</TableCell>
          <TableCell className={classes.itemTableCell} align="center" sx={{ width: '50px' }}>Size</TableCell>
          <TableCell className={classes.itemTableCell} sx={{ width: '400px' }}>Product Name</TableCell>
          <TableCell className={classes.itemTableCell} align="left" sx={{ width: '220px' }}>SKU</TableCell>
          <TableCell className={classes.itemTableCell} align="left" sx={{ width: '50px' }}></TableCell>
          <TableCell className={classes.itemTableCell} align="left" sx={{ width: '5px' }}></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {_items.map((item, index) => {

          const targetStyles = ['hyperoptic', 'knitted', 'cut & sew', 'packaging'];

          const isError = item.error !== "";

          let stSize = _.find(props.sizes, { id: item.size }) ? _.find(props.sizes, { id: item.size }).name : "";

          const color = item.status > 0 ? "gray" : isError ? "#FF0000" : "#000000";

          const changeTextColorIfFinishedGood = (style) => {

            // Ensure style is lowercase for comparison
            const styleLowerCase = style?.toLowerCase();

            // Check if style matches any in the targetStyles array
            const isTargetStyle = targetStyles.some(targetStyle => styleLowerCase?.includes(targetStyle));

            return isTargetStyle ? palette.productPurple : color;
          };

          const changeTypeNameBasedOffStyle = (style) => {

            if (!style) return ('')

            switch (style.toLowerCase()) {
              case 'hyperoptic':
                return 'HyperOPtic';
              case 'knitted':
                return 'Knitted';
              case 'cut & sew':
                return 'Cut & Sew';
              case 'packaging':
                return 'Add-On';
              case 'graphic':
                return item?.type
              default:
                return style;
            }

          }

          if (stSize === 'YTHL') {
            stSize = 'Youth L'
          }

          return (
            <ItemsRow
              handleRowClick={handleRowClick}
              color={color}
              changeTextColorIfFinishedGood={changeTextColorIfFinishedGood}
              changeTypeNameBasedOffStyle={changeTypeNameBasedOffStyle}
              item={item}
              _items={_items}
              index={index}
              key={index}
              isSelected={isSelected}
              classes={classes}
              dispatch={dispatch}
              isProduction={isProduction}
              order={order}
              db={db}
              handleClick={handleClick}
              stSize={stSize}
            />
          );

        })}
      </TableBody>
    </Table>
  );
}

const DND_ITEM_TYPE = 'row';

// primary row component
function Row(props) {

  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');

  const { row, label, index, production, cropSettings, templates, dragDrop, sizes, onSelect, onMove, onDrop, onPrint, isProduction, storage, selection, selectedRowIds } = props;

  const dispatch = useDispatch();

  const isOpen = useSelector(state => state.wholeSaleOrders.openRows.has(row.id));

  const toggleRow = () => {
    if (isOpen) {
      dispatch(closeRow(row.id));
      setOpen(false);
    } else {
      setOpen(true);
      dispatch(openRow(row.id));
    }
  }


  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(isOpen);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [detailAnchor, setDetailAnchor] = useState(null);
  const [modalDelete, setModalDelete] = useState(false);

  const db = props.firebase.getdb();
  const dropRef = useRef(null);
  const dragRef = useRef(null);
  const classes = useStyles();

  const [, drop] = useDrop({
    accept: DND_ITEM_TYPE,
    drop(item, monitor) {
      if (!dropRef.current) {
        return
      }
      const dragIndex = item.index
      if (onDrop)
        onDrop(dragIndex);
    },
    hover(item, monitor) {
      if (!dropRef.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      // Determine rectangle on screen
      const hoverBoundingRect = dropRef.current.getBoundingClientRect()
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      // Time to actually perform the action
      if (onMove)
        onMove(dragIndex, hoverIndex)
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
  })

  useEffect(() => {
    if (!row.id) return;
    // Set up the real-time listener
    const unsubscribe = listenToWholesaleChange(db, row.id, dispatch, isProduction);

    // Cleanup function to unsubscribe when component unmounts or batchId changes
    return () => {
      unsubscribe();
    };

  }, [db, row.id]);

  const [{ isDragging }, drag, preview] = useDrag({
    type: DND_ITEM_TYPE,
    item: { index },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const handleChange = (event) => {
    if (onSelect)
      onSelect(row.order);
  }

  const renderDetailHeader = () => {
    return (
      <Box className={classes.detailHeader}>
        <Box>
          <Typography className={classes.orderTitle}><strong>Order</strong> {row.poNumber}</Typography>
          <Typography className={classes.orderSmallTitle}><strong>Estimate #</strong> {row.docNumber}&nbsp;&nbsp;&nbsp;Placed {row.createdAt}</Typography>
        </Box>

        <Button
          aria-controls="customized-menu-detail"
          aria-haspopup="true"
          variant="black"
          color="primary"
          onClick={handleToggleDetailMenu}
        >
          <MoreHorizIcon />
        </Button>

        <StyledMenu
          id="customized-menu-detail"
          anchorEl={detailAnchor}
          keepMounted
          open={Boolean(detailAnchor)}
          onClose={handleToggleCloseDetailMenu}
        >
          {production && <MenuItem onClick={e => handleBack()}><ReplayIcon />&nbsp;&nbsp;Send to Order Queue</MenuItem>}
          <MenuItem onClick={e => handleRefresh()}><SyncIcon />&nbsp;&nbsp;Sync Products</MenuItem>
          <MenuItem onClick={e => handleDelete()}><DeleteOutlineIcon />&nbsp;&nbsp;Remove</MenuItem>
        </StyledMenu>

      </Box>
    );
  }

  const renderDetailInfo = () => {
    return (
      <Box sx={{ marginBottom: '40px' }}>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <Typography className={classes.detailTitle}>Customer</Typography>
            <Typography className={classes.detailText}>{row.customer}</Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography className={classes.detailTitle}>Email</Typography>
            <Typography className={classes.detailText}>{row.email}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography className={classes.detailTitle}>Ship Date</Typography>
            <Typography className={classes.detailText}>{row.shipDate}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography className={classes.detailTitle}>Cancel Date</Typography>
            <Typography className={classes.detailText}>{row.cancelDate}</Typography>
          </Grid>

        </Grid>
      </Box>
    );
  }

  const renderDetailAddress = () => {
    return (
      <Box sx={{ maxWidth: '80%', margin: '0 auto 30px' }}>
        <Grid container spacing={3}>
          <Grid item xs={5}>
            <Typography className={classes.detailTitle}>Billing Address</Typography>
            <Typography className={classes.detailText}>{row.billAddress}</Typography>
          </Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={5}>
            <Typography className={classes.detailTitle}>Shipping Address</Typography>
            <Typography className={classes.detailText}>{row.shipAddress}</Typography>
          </Grid>
        </Grid>
      </Box>
    );
  }

  const renderDetailItems = () => {
    return (
      <Box sx={{ borderTop: '1px solid rgba(224, 224, 224, 1)', padding: '30px 10px' }}>
        <OrderItemsTable
          {...props}
          selectedRowIds={selectedRowIds}
          order={row.order}
          items={row.items}
          classes={classes}
          selection={selection}
          dispatch={dispatch}
          isProduction={isProduction}
          db={db}
        />
      </Box>
    );
  }
  const renderAttachments = () => {
    return (
      <Box>
        {
          _.map(row.attachments, item => {
            return (
              <Attachment
                key={`Attachable_${item.Id}`}
                companyId={row.companyId}
                itemId={item.Id}
                name={item.FileName}
              />
            )
          })
        }
      </Box>
    )
  }

  const renderDetailFooter = () => {
    return (
      <Box sx={{ marginTop: '40px' }}>
        <Grid container spacing={3}>
          <Grid item xs={5}>
            <Typography className={classes.detailTitle}>Notes</Typography>
            <Typography className={classes.detailText}>{row.note}</Typography>
          </Grid>

          <Grid item xs={3}></Grid>
          <Grid item xs={4}>
            <Typography className={classes.detailTitle}>Attachments</Typography>
            {renderAttachments()}
          </Grid>
        </Grid>
      </Box>
    );
  }

  const handlePrint = () => {
    const _items = _.filter(row.items, item => isSelected(item.id));
    if (onPrint) {
      onPrint(row.order, _items);
    }
  }

  const renderPrintButton = () => {
    return (
      <Box sx={{ marginTop: '40px', textAlign: 'right' }}>
        <Button
          variant="black"
          disabled={selection === ""}
          onClick={handlePrint}
        >
          Batch Order
        </Button>
      </Box>
    )
  }

  const handleToggle = (event) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleToggleClose = () => {
    setMenuAnchor(null);
  };

  const handleToggleDetailMenu = (event) => {
    setDetailAnchor(event.currentTarget);
  };

  const handleToggleCloseDetailMenu = () => {
    setDetailAnchor(null);
  };

  const handleClickPriority = (priority) => {
    if (row.priority !== priority)
      updatePriority(db, row.order, priority, dispatch, isProduction);
    handleToggleClose();
  }

  const handleBack = () => {
    // const items = _.map(row.items, item => {
    //   return { ...item, status: 0 };
    // });

    updateOrder(db, row.order, row.items, false, dispatch);
  }

  const handleDelete = () => {
    setModalDelete(true);
  }

  const handleRefresh = async () => {
    setIsLoading(true);
    try {

      const items = await lookupItems(db, row.items, cropSettings, templates, storage);

      // Validate all items concurrently and wait for all validations to complete
      const validatedItems = await Promise.all(items.map(item => validateImageInStorage(item, storage)));

      let isError = validatedItems.some(item => item.error !== "");

      const updateDataObjectForRedux = {
        isError: isError,
        items: validatedItems,
      }

      // Now all items have been validated and potentially updated with error properties
      updateOrderItems(db, row.order, validatedItems, dispatch, isProduction, updateDataObjectForRedux);

      setDetailAnchor(null);
    } catch (error) {
      console.error('Error during handleRefresh:', error);
      // Handle any errors that occurred during validation or updating
    }
    setIsLoading(false);
  };

  const isSelected = (id) => {
    if (selection === "")
      return false;
    const strIds = selection.split(",");
    return strIds.indexOf(id) !== -1;
  }

  const handleCloseDeleteModal = () => {
    setModalDelete(false);
  }

  const handleConfirmDelete = () => {
    markOrderAsDelete(db, row.order, dispatch, isProduction);
  }

  const renderDeleteModal = () => {
    return (
      <ConfirmDialog
        label="delete"
        title="Delete"
        open={modalDelete}
        onClose={handleCloseDeleteModal}
        onConfirm={handleConfirmDelete}
      >
        <Typography gutterBottom>Are you sure you want to remove this order?</Typography>
      </ConfirmDialog>
    );
  }

  let cancelDate = moment(row.cancelDate);
  let today = moment();
  let daysLeft = row.cancelDate ? cancelDate.diff(today, 'days') : 0;

  let opacity = 1;
  if (dragDrop) {
    preview(drop(dropRef));
    drag(dragRef);
    opacity = isDragging ? 0 : 1;
  }

  let priorityColor = '#06ab13';
  if (row.priority === 'High')
    priorityColor = '#ff010d';
  else if (row.priority === 'Reg')
    priorityColor = '#00b3ff';


  const hasMatchingStyle = row.items.some(item => keywords.includes(item.style.toLowerCase()));

  return (
    <React.Fragment>
      <TableRow
        ref={dropRef}
        role="checkbox"
        tabIndex={-1}
        key={label}
        aria-checked={selection !== ""}
        selected={isOpen || selection !== ""}
        sx={{
          opacity: opacity,
          backgroundColor: (theme) => daysLeft <= 0 ? '#ffdbdb' : isOpen ? theme.palette.action.selected : "inherit",
          '&:hover, &.Mui-selected, &.Mui-selected:hover': {
            backgroundColor: daysLeft <= 0 ? '#ffdbdb' : isOpen ? '#desiredOpenColor' : 'inherit', // Adjust as necessary
          },
        }}
      >

        <TableCell component="th" scope="row" padding="checkbox" className={classes.tableCheckbox}>
          <Box display="flex" alignItems="center">
            {dragDrop && (
              <Box ref={dragRef} className={classes.indicator}>
                <DragIndicatorIcon />
              </Box>
            )}

            <Checkbox
              color="primary"
              checked={selection !== ""}
              onChange={handleChange}
              inputProps={{ 'aria-labelledby': label }}
            />

            <IconButton aria-label="expand row" size="small" onClick={() => toggleRow()}>
              {isOpen ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
            </IconButton>
          </Box>
        </TableCell>
        <TableCell align="center" onClick={() => toggleRow()}>
          <Box display="flex">
            {
              hasMatchingStyle && (
                <AssignmentIcon sx={{ color: '#14B779', verticalAlign: 'middle' }} />
              )
            }
            {row.isError && (
              <ErrorOutlineIcon sx={{ color: '#E64A19', verticalAlign: 'middle' }} />
            )}
          </Box>
        </TableCell>

        <TableCell align="center">
          <Typography variant='tableText'>{row.txnDate}</Typography>
        </TableCell>
        <TableCell align="center"><Typography variant='tableText'>{row.customer}</Typography></TableCell>
        <TableCell align="center"><Typography variant='tableText'>{row.poNumber}</Typography></TableCell>
        <TableCell align="center"><Typography variant='tableText'>{row.docNumber}</Typography></TableCell>
        <TableCell align="center"><Typography variant='tableText'>{row.shipDate}</Typography></TableCell>
        <TableCell align="center">
          <Typography variant='tableText'>{row.cancelDate}</Typography>
          {daysLeft <= 0 && <Typography className={classes.overdue}>Overdue</Typography>}
        </TableCell>
        <TableCell align="center" className={(daysLeft > 0 && daysLeft < 15) ? classes.tableCellError : classes.tableCell}>{daysLeft < 0 ? 0 : daysLeft}</TableCell>
        <TableCell align="center" className={classes.tableCell}>
          <CustomTooltip row={row} sizes={sizes} formats={props.formats} />

        </TableCell>
        <TableCell align="center">
          <Button
            aria-controls="customized-menu"
            aria-haspopup="true"
            variant="blue"
            color="primary"
            sx={{ background: priorityColor, width: '70px', textTransform: 'uppercase' }}
            onClick={handleToggle}
          >
            {row.priority}
          </Button>

          <StyledMenu
            id="customized-menu"
            anchorEl={menuAnchor}
            keepMounted
            open={Boolean(menuAnchor)}
            onClose={handleToggleClose}
          >
            <MenuItem onClick={e => handleClickPriority("High")}>High</MenuItem>
            <MenuItem onClick={e => handleClickPriority("Med")}>Med</MenuItem>
            <MenuItem onClick={e => handleClickPriority("Reg")}>Reg</MenuItem>
          </StyledMenu>
        </TableCell>
      </TableRow>

      <TableRow sx={{ background: '#F1F7FE' }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan="100%">
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ padding: '40px 0 40px 40px' }}>
              {renderDetailHeader()}
              {renderDetailInfo()}
              {renderDetailAddress()}
              {
                isLoading ? (
                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
                    <CircularProgress />
                  </Box>
                ) : renderDetailItems()
              }
              {renderDetailFooter()}
              {production && renderPrintButton()}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>

      {renderDeleteModal()}
      <Toast
        show={showToast}
        data={toastMessage}
        onClose={() => setShowToast(false)}
        duration={6000}
      />
    </React.Fragment>
  );
}

export default Row;