import React from "react";
import DsOrdersPage from "./DsOrdersPage";
import { withAuthorization } from "../Session";
import { collections } from "../../constants/defines";

const DsCustoms = (props) => {
  return (
    <DsOrdersPage
      {...props}
      collectionName={collections.DS_CUSTOM_QUEUE}
      type="customs"
      includeItemsPerPageSelector={true}
      convertToRowsOptions={true}
      pageName="dscustom"
    />
  );
};

const condition = (authUser) => !!authUser;
export default withAuthorization(condition)(DsCustoms);
