import * as React from "react"
const PrinterSVG = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={35}
    height={35}
    viewBox="0 0 44 44"  // Add viewBox attribute
    fill="none"
    {...props}
  >
    <path
      fill="#3477F5"
      d="M31.389 5.278C29.776 3.667 27.184 3.667 22 3.667c-5.186 0-7.779 0-9.39 1.611-.902.902-1.298 2.114-1.474 3.916 1.026-.027 2.16-.027 3.402-.027h14.927c1.241 0 2.375 0 3.4.027-.175-1.802-.575-3.014-1.477-3.916ZM33 28.417c0 5.184 0 8.695-1.611 10.305-1.61 1.611-4.204 1.611-9.389 1.611s-7.779 0-9.389-1.611C11 37.112 11 33.602 11 28.417h22Z"
    />
    <path
      fill="#3477F5"
      d="M29.333 11H14.666c-5.185 0-7.779 0-9.388 1.611-1.612 1.61-1.612 4.204-1.612 9.389s0 7.779 1.612 9.389c.905.907 2.123 1.303 3.94 1.477-.052-1.325-.052-2.887-.052-4.45a.917.917 0 0 1 0-1.833h25.667a.917.917 0 0 1 0 1.834c0 1.562 0 3.124-.052 4.45 1.815-.175 3.035-.571 3.942-1.478 1.61-1.61 1.61-4.204 1.61-9.389s0-7.779-1.612-9.389C37.111 11 34.517 11 29.333 11Z"
      opacity={0.5}
    />
    <path
      fill="#3477F5"
      d="M16.5 19.708a1.375 1.375 0 1 0 0-2.75H11a1.375 1.375 0 0 0 0 2.75h5.5ZM33 18.333a1.833 1.833 0 1 1-3.667 0 1.833 1.833 0 0 1 3.667 0Z"
    />
  </svg>
)
export default PrinterSVG
