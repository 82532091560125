import React, { useState, useRef } from "react";
import Papa from "papaparse";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Paper,
} from "@mui/material";
import { API } from "../../constants/defines";
import { isLikelyHeader } from "../DsQueue/helpers";
import { Toast } from "../Toast";

const FileUpload = () => {
  const [loading, setLoading] = useState(false);
  const [fileData, setFileData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [displayCount, setDisplayCount] = useState(50);
  const [showToast, setShowToast] = useState(false);
  const [toastData, setToastData] = useState({});
  const fileInputRef = useRef(null);
  const isSubmittingRef = useRef(false); // Ref to track submission status

  const handleFileUpload = (event) => {
    setFileData(null);
    const file = event.target.files?.[0];

    if (!file) {
      return;
    }

    const removeFirstRowAndAssignHeaders = (data) => {
      let headerRow = data[0];
      let contentRows = data.slice(1);

      // Check if the first row is a valid header, if not find the next one
      if (!isLikelyHeader(headerRow)) {
        headerRow = contentRows[0]; // Use the next row as the header
        contentRows = contentRows.slice(1); // Remove the new header row from content rows
      }

      const formattedData = contentRows.map((row) => {
        const rowData = {};
        headerRow.forEach((header, index) => {
          rowData[header] = row[index];
        });
        return rowData;
      });

      const filteredData = formattedData.filter((row) => {
        return Object.values(row).join("").length > 0;
      });

      return { headers: headerRow, data: filteredData };
    };

    Papa.parse(file, {
      header: false,
      skipEmptyLines: true,
      complete: (result) => {
        const parsedData = removeFirstRowAndAssignHeaders(result.data);

        // Extract 'PO Date' values from the data
        const poDates = parsedData.data.map((row) => row["PO Date"]);

        // Get unique dates
        const uniqueDates = [...new Set(poDates.map((date) => date.trim()))];
        if (uniqueDates.length > 1) {
          // Alert the user if multiple dates are found
          alert(
            `Error: Multiple 'PO Date' values found in the file: ${uniqueDates.join(
              ", "
            )}. Please ensure all rows have the same 'PO Date'.`
          );
          // Optionally, you can stop further processing here
          return;
        }

        // Proceed if only one date is present
        setFileData(parsedData);
        setShowModal(true);
      },
      error: (error) => {
        console.log("Error parsing the file: ", error);
        alert("Error parsing the file. Please try again.");
      },
    });

    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setFileData(null);
    setDisplayCount(50);
  };

  const loadMoreRows = () => {
    setDisplayCount((prevCount) => prevCount + 50);
  };

  const onSubmit = async () => {
    if (isSubmittingRef.current) {
      console.log("Already submitting...");
      return; // Prevent double submission
    }
    isSubmittingRef.current = true; // Set submission in progress
    try {
      setLoading(true);
      const response = await fetch(API.createDropshipOrders, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: fileData.data }),
      });

      if (response.ok) {
        setShowModal(false);
        return;
      }

      const data = await response.json();

      setShowToast(true);
      setToastData({
        isError: true,
        message: data.message,
      });
    } catch (error) {
      console.log("Error in onSubmit: ", error);
      alert("Error submitting the CSV data. Please try again.");
    } finally {
      setLoading(false);
      isSubmittingRef.current = false; // Reset submission status
    }
  };

  return (
    <div>
      <input
        type="file"
        accept=".csv"
        onChange={handleFileUpload}
        ref={fileInputRef}
        style={{ marginBottom: "20px" }}
      />

      {/* Modal */}
      <Dialog
        open={showModal}
        onClose={handleCloseModal}
        maxWidth="xxl"
        fullWidth
      >
        <DialogTitle>Total Rows: {fileData?.data?.length || 0}</DialogTitle>
        {loading ? (
          <Box
            p={2}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress size={24} />
            <Typography variant="body1">Loading...</Typography>
          </Box>
        ) : (
          <>
            <DialogContent>
              <TableContainer component={Paper} sx={{ maxHeight: 900 }}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      {fileData?.headers?.map((header, index) => (
                        <TableCell
                          key={index}
                          sx={{
                            fontWeight: "bold",
                            backgroundColor: "#f5f5f5",
                            borderBottom: "2px solid #ddd",
                          }}
                        >
                          {header}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {fileData?.data
                      ?.slice(0, displayCount)
                      .map((row, rowIndex) => (
                        <TableRow key={rowIndex}>
                          {fileData.headers.map((header, cellIndex) => (
                            <TableCell key={cellIndex}>{row[header]}</TableCell>
                          ))}
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>

              {/* "Load More" button */}
              {fileData && displayCount < fileData.data.length && (
                <Button
                  variant="outlined"
                  sx={{
                    marginTop: "20px",
                    marginBottom: "20px",
                  }}
                  onClick={loadMoreRows}
                  color="primary"
                >
                  Load 50 More
                </Button>
              )}
            </DialogContent>

            {/* Sticky Buttons */}
            <DialogActions
              sx={{
                position: "sticky",
                bottom: 0,
                background: "white",
                zIndex: 1,
              }}
            >
              <Button
                variant="outlined"
                onClick={onSubmit}
                color="primary"
                disabled={loading}
              >
                Submit
              </Button>
              <Button
                variant="outlined"
                onClick={handleCloseModal}
                color="primary"
                disabled={loading}
              >
                Cancel
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
      <Toast
        show={showToast}
        data={toastData}
        onClose={() => setShowToast(false)}
      />
    </div>
  );
};

export default FileUpload;
