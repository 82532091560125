export const START_FETCH_HISTORY = 'START_FETCH_HISTORY';
export const FETCHED_HISTORY = 'FETCHED_HISTORY';
export const FETCH_HISTORY_ERROR = 'FETCH_HISTORY_ERROR';
export const PROCESS_ROW_DATA = 'PROCESS_ROW_DATA';
export const UPDATE_PAGE_NUMBER = 'UPDATE_PAGE_NUMBER';
export const DELETE_HISTORY_DOC = 'DELETE_HISTORY_DOC';
export const INJECT_ONE_ITEM_HISTORY = 'INJECT_ONE_ITEM_HISTORY';
export const INJECT_ONE_ROW_DATA_HISTORY = 'INJECT_ONE_ROW_DATA_HISTORY';
export const UPDATE_STATE_FOR_FETCH_RAN_DOC = 'UPDATE_STATE_FOR_FETCH_RAN_DOC';
export const RESET_STATE_FOR_FETCH_RAN_DOC = 'RESET_STATE_FOR_FETCH_RAN_DOC';
export const REPLACE_HISTORY_DOC = 'REPLACE_HISTORY_DOC';
export const REPLACE_ONE_ROW_DATA_HISTORY = 'REPLACE_ONE_ROW_DATA_HISTORY';
export const UPDATE_SINGLE_ITEM_BY_ID = 'UPDATE_SINGLE_ITEM_BY_ID';
export const UPDATE_LOADING = 'UPDATE_LOADING';
export const SET_DOWNLOAD_STATUS = 'SET_DOWNLOAD_STATUS';
export const CHANGED_PAGE = 'CHANGED_PAGE';
export const UPDATE_DOCS_WHEN_CHANGED_SHOW = 'UPDATE_DOCS_WHEN_CHANGED_SHOW';
export const USE_SEARCH = 'USE_SEARCH';
export const FILTER_HISTORY = 'FILTER_HISTORY';
export const RESET_STATE_HISTORY = 'RESET_STATE_HISTORY';