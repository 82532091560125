import Papa from 'papaparse';

export const handleCsvDownloadBundles = ({ data }) => {
  if (!data || data.length === 0) {
    console.log("No data to download.");
    return;
  }

  const { completeSizes, missingSizes } = data;

  // Alternative: Use a different separator if new lines are not suitable
  const formattedMissingSizes = missingSizes.map(item => ({
    ...item,
    missing: item.missing ? item.missing.join('; ') : '' // Use semicolon-space as separator
  }));

  const date = new Date().toISOString().slice(0, 10);
  // time stamp 
  const time = new Date().toISOString().slice(11, 19);

  // Convert datasets to CSV format
  const csvComplete = Papa.unparse(completeSizes, {
    header: true,
    columns: ['sku', 'complete'] // Define the columns if necessary
  });
  const csvMissing = Papa.unparse(formattedMissingSizes, {
    header: true,
    columns: ['sku', 'missing'], // Define the columns if necessary
    quotes: true // Force quoting to ensure special characters are handled correctly
  });

  // Creating downloadable CSV blobs
  const blobComplete = new Blob([csvComplete], { type: "text/csv;charset=utf-8;" });
  const blobMissing = new Blob([csvMissing], { type: "text/csv;charset=utf-8;" });

  // Create links and trigger download for Complete Sizes
  const urlComplete = URL.createObjectURL(blobComplete);
  const aComplete = document.createElement("a");
  aComplete.href = urlComplete;
  aComplete.download = `Complete-sizes-${date}-${time}.csv`;
  document.body.appendChild(aComplete);
  aComplete.click();
  document.body.removeChild(aComplete);
  URL.revokeObjectURL(urlComplete);

  // Create links and trigger download for Missing Sizes
  const urlMissing = URL.createObjectURL(blobMissing);
  const aMissing = document.createElement("a");
  aMissing.href = urlMissing;
  aMissing.download = `Missing-sizes-${date}-${time}.csv`;
  document.body.appendChild(aMissing);
  aMissing.click();
  document.body.removeChild(aMissing);
  URL.revokeObjectURL(urlMissing);
};
