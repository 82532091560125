import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Drawer from '@mui/material/Drawer';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import { withAuthorization } from '../Session';
import Layout from '../Page';
import PageHeader from './pageHeader';
import PageToolbar from './pageToolbar';
import { FilterProvider } from './filterContext';
import {deleteFormat} from '../../db/formats';
import FormatModal from './formatModal';
import {ConfirmDialog} from '../Page/generalModal';
import {EnhancedTableHead} from '../Page/table';

const filterShows = [
  {id: 150, name: '150'},
  {id: 100, name: '100'},
  {id: 200, name: '200'},  
];

const headCells = [
  { id: 'name', numeric: false, align: 'left', disablePadding: false, label: 'NAME', width: 'auto' },
  { id: 'edit', numeric: true, align: 'left', disablePadding: false, label: '', width: '120px'  },
  { id: 'delete', numeric: true, align: 'left', disablePadding: false, label: '', width: '100px' },  
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  tableRow: {
    backgroundColor: 'rgba(242, 244, 246, 0.4)'
  },  
  tableRow2: {
    backgroundColor: '#FFFFFF'
  },
  tableRowError: {
    backgroundColor: 'rgba(255, 0, 0, 0.1)'
  },
  selectedTableRow: {
    backgroundColor: '#F1F7FE!important',
  },
  pagination: {
    marginTop: '60px',
    '& ul': {
      justifyContent: 'center'
    },
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  pageText: {
    color: '#000000',
    letterSpacing: '1.5px',
    fontSize: '14px',
    fontWeight: '500'
  },
  disabledButton: {
    backgroundColor: '#ccc!important',
    color: 'gray !important'
  },
  emptyText: {
    fontSize: '16px',
    fontWeight: '500',
    color: '#000000'
  },
  inputQty: {
    '& input': {
      borderRadius: '6px',
      border: '1px solid rgba(0,0,0,0.08)',
      padding: '10px 12px'
    }
  },
  drawerHeader: {
    position: 'relative',
    padding: '50px 50px 15px'
  },
  drawerBottom: {
    padding: '0px 50px 30px'
  },
  pageHeading: {
    borderBottom: '1px solid rgba(217, 223, 233, 0.4)'
  },
  sectionHeading: {
    fontSize: '16px',
    fontWeight: '500',
    color: '#000000',
    marginBottom: '15px'
  },
  inputBatchName: {
    color: 'inherit',
    background: 'white',
    '& input': {
      background: 'rgba(0,0,0,0.04)',
      padding: '11px 16px',
      width: '100%',
      borderRadius: '4px',
      border: 'none'
    }
  },
  batchButton: {
    fontSize: '16px',
    fontWeight: '500',
    textTransform: 'initial',
    color: '#FFFFFF',
    background: '#1F7CF2',
    borderRadius: '6px',
    width: '170px',    
  },
  disabledBatchButton: {
    opacity: '0.48',
    backgroundColor: '#1F7CF2 !important',
    color: '#FFFFFF!important',
  },
  updateButton: {
    color: '#FFFFFF',
    textTransform: 'initial',
    width: '110px'
  },
  removeButton: {

  }
}));

function Formats(props) {  
  const [selected, setSelected] = useState("");
  const [loading, setLoading] = useState(true);
  const [formats, setFormats] = useState([]);  
  const [filterShow, setFilterShow] = useState(150);
  const [openModal, setOpenModal] = useState(false);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const db = props.firebase.getdb();
  const permission = props.user.permission;

  useEffect(() => {
    setFormats([...props.formats]);
  }, [props.formats]);

  const handleCloseDialog = () => {
    setOpenModal(false);
  }

  const handleCloseCreateDialog = () => {
    setOpenCreateModal(false);
  }

  const handleClick = (event, name) => {    
    setSelected(name);
  };

  const handelEditFormat = (event, name) => {
    setSelected(name);
    setOpenModal(true);
  }

  const isSelected = (name) => selected === name;

  const handleAddFormat = () => {
    setOpenCreateModal(true);
  }

  const handleCloseRemoveDialog = () => {
    setOpenDeleteModal(false);
  }

  const handleDeleteFormat = (event) => {
    const _selected = _.find(formats, row => isSelected(row.name));  
    setOpenDeleteModal(false);
    setSelected("");
    deleteFormat(db, _selected);    
  }

  const renderRemoveDialog = () => {
    return (
      <ConfirmDialog 
        label="format"
        title="Remove Format"
        open={openDeleteModal}
        onClose={handleCloseRemoveDialog}
        onConfirm={handleDeleteFormat}
      >
        <Typography variant='body2' gutterBottom>
          Do you want to remove <strong>{selected}</strong> ?
        </Typography>  
      </ConfirmDialog>
    );
  }

  const classes = useStyles();

  const selectedFormat = _.find(formats, row => isSelected(row.name));  
  
  return (
    <div>
      <PageHeader total={formats.length} setSearchText={setSearchText} searchText={searchText}/>

      <FilterProvider value={{
                            shows: filterShows,
                            filterShow,
                            setFilterShow
                            }}>
        <PageToolbar viewOnly={props.viewOnly} editOnly={props.editOnly} numSelected={0} handleCreate={handleAddFormat}/>
      </FilterProvider>

      <TableContainer sx={{marginTop: '30px', marginBottom: '50px'}}>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size={'medium'}
          aria-label="enhanced table"            
        >
          <EnhancedTableHead classes={classes} cells={headCells} useCheckbox={false} />
          <TableBody>
            {formats
              .map((row, index) => {
                if(!row.name.toLowerCase().includes(searchText)){
                  return
                }
                const isItemSelected = isSelected(row.name);
                const labelId = `enhanced-table-checkbox-${index}`;
                const keyId = `key-${row.sku}-${index}`;
                const odd = index%2 === 0;
                return (
                  <TableRow
                    hover                                        
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={keyId}                      
                    classes={{
                      root: odd?classes.tableRow:classes.tableRow2,
                      selected: classes.selectedTableRow, 
                    }}
                    selected={isItemSelected} 
                    onClick={(event) => handleClick(event, row.name)}                       
                  >
                    <TableCell align="left"><Typography variant='tableText'>{row.name}</Typography></TableCell>
                    <TableCell align="right">
                      {props.editOnly && (
                      <Button
                        variant="blue"                        
                        sx={{width: '120px'}} 
                        onClick={() => handelEditFormat(row.name)}         
                      >
                        Edit
                      </Button>
                      )}

                    </TableCell>
                    <TableCell align="center">
                      {props.editOnly && (
                      <Button onClick={() => setOpenDeleteModal(true)} disabled={!props.editOnly}>
                        <DeleteOutlineIcon />
                      </Button>
                      )}
                    </TableCell>
                  </TableRow>                  
                );
              })}
            
          </TableBody>
        </Table>
      </TableContainer> 
      
      {selected && (<FormatModal open={openModal} handleClose={handleCloseDialog} modal_name="Edit Format" item={selectedFormat} />)}    

      <FormatModal isCreate open={openCreateModal} modal_name="New Format" handleClose={handleCloseCreateDialog} item={null} />  

      {renderRemoveDialog()}
    </div>
  );
}

const condition = authUser => !!authUser;
export default withAuthorization(condition)(Formats);

