import { collections, excludedStyles } from '../constants/defines';
import moment from "moment";
import _ from "lodash";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  updateDoc,
  getDoc,
  deleteDoc,
  onSnapshot,
  writeBatch,
  limit,
  orderBy
} from "firebase/firestore";
import { itemsChecked } from '../utils/updateItemIfSaysMissingArt';

function getValidDate(date) {
  if (date && typeof date.toDate === 'function') {
    // It's a Firestore Timestamp
    return date.toDate();
  } else if (date instanceof Date) {
    // It's already a JavaScript Date object
    return date;
  } else if (typeof date === 'string') {
    // It's a string, parse it to Date
    return new Date(date);
  } else {
    // Unsupported type or undefined, handle accordingly, maybe return 'Invalid date'
    console.error("Invalid date format:", date);
    return new Date(); // or return a fallback date or throw an error
  }
}

const getHistoryDocument = async (db, batchId, callback, storage) => {

  if (!batchId) {
    return;
  }

  const docRef = doc(db, collections.HISTORY, batchId.toString());

  try {
    const docSnap = await getDoc(docRef);
    let item = { exists: false, printed: false, downloaded: false, downloads: 0 };

    if (docSnap.exists()) {
      const data = docSnap.data();
      item = { ...data, exists: true };

      const historyDate = getValidDate(data.createdAt);
      const createdAt = moment(historyDate).format('MM/DD/YY hh:mm A');
      item.createdAt = createdAt;

      // Process items asynchronously using itemsChecked and wait for the result
      const processedHits = await itemsChecked({ hits: [{ document: data }] }, db, storage);
      const processedItems = processedHits[0].items;
      // Compute isError and isWarning flags based on the processed items
      let isError = processedItems.some(item => !!item.isError);
      let isWarning = processedItems.some(item => !!item.isWarning);

      if (processedItems.some(item => item?.quantity === "INV")
        || processedItems.some(item => item?.message?.includes("in Existing Inventory"))
      ) {
        isWarning = true;
      }

      // Map over processedItems to add an 'id' to each item
      const itemsWithId = processedItems.map((item, index) => ({
        ...item,
        id: index.toString()
      }));

      // Update the item object with the new values
      item.isError = isError;
      item.isWarning = isWarning;
      item.items = itemsWithId;
    }
    callback(item);
  } catch (error) {
    console.error("Error getting document:", error);
    callback({ exists: false, printed: false, downloaded: false, downloads: 0 });
  }
};


const setHistoryDownloads = (db, batchId, downloads, callback) => {
  const docRef = doc(db, collections.HISTORY, batchId);
  updateDoc(docRef, { downloads: downloads })
    .then(() => {
      callback(downloads);
    })
    .catch((error) => {
      // The document probably doesn't exist.
      console.error("Error updating Format: ", error);
    });
}

const setPrintStatus = async (db, batchId, printed, callback, data, formats, sizes, dispatch, printedIcon) => {
  if (!db) {
    console.error("Firestore db is not defined.");
    return;
  }

  const currentUser = localStorage.getItem("currentuser");

  if (!currentUser) {
    console.error("No currentuser found in localStorage.");
    // return;
  }

  const docRef = doc(db, collections.HISTORY, batchId);

  if (!docRef) {
    console.error("Document reference could not be created.");
    return;
  }


  try {
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const docData = docSnap.data();
      // Toggle the status based on the current value or default to false if no value is found
      const didPrintSocks =
        printedIcon === "socks" ? !docData.didPrintSocks : (docData.didPrintSocks || false);

      const didPrintUnderwear =
        printedIcon === "underwear"
          ? !docData.didPrintUnderwear
          : (docData.didPrintUnderwear || false);

      const printedBeforeUpdate = printedIcon === 'printedFlag'

      await updateDoc(docRef, {
        printedBy: currentUser,
        didPrintSocks,
        didPrintUnderwear,
        printed: printedBeforeUpdate
      });

      // // this blocks data for being re-entered in the database if it has already been entered
      // if (!docData.analyticsWritten) {

      //   const itemsWithDetails = await getBatchesDataForItems(db, data, formats, sizes);

      //   const aggregatedData = aggregateItems(itemsWithDetails);

      //   await writeProducedAnalytic(db, aggregatedData, dispatch);

      //   // Set the analyticsWritten flag to true
      //   await updateDoc(docRef, { analyticsWritten: true });

      // } else {
      //   console.log("Analytics have already been written for this batch.");
      // }

      callback(printed); // Ensure callback is defined and can handle this argument
    } else {
      console.error("No such document!");
    }
  } catch (error) {
    console.error("Error updating document: ", error);
  }
};


const getItemDetailsBySKU = async (db, item, formats, source, sizes) => {
  try {
    const q = query(collection(db, collections.PRODUCTS), where('sku', '==', item.sku));

    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      console.error(`No item found for SKU: ${item.sku}`);
      return null;
    }

    // Assuming item.sku is unique and only one document will be returned
    const doc = querySnapshot.docs[0];

    // Extract necessary details
    const itemData = doc.data();

    const format = _.find(formats, { id: itemData.format })?.name || 'Unknown';

    const size = _.find(sizes, { id: itemData.size })?.sku || 'Unknown';

    const count = item.quantity;

    return { type: itemData.type, format, size, count, source, sku: item.sku, style: itemData.style };

  } catch (error) {
    console.error("Error fetching item details: ", error);
    throw error;
  }
};


const getBatchesDataForItems = async (db, data, formats, sizes) => {
  if (!db) {
    console.error("Firestore db is not defined.");
    return;
  }

  try {
    console.log('DATA: ', data);
    const itemDetailsPromises = data.items.map(async (item) => {
      const itemDetails = await getItemDetailsBySKU(db, item, formats, data.source, sizes);
      return itemDetails;
    });

    const itemsWithDetails = await Promise.all(itemDetailsPromises);

    return itemsWithDetails.filter(item => item !== null);

  } catch (error) {
    console.error("Error fetching item details: ", error);
  }
};

const aggregateItems = (items) => {
  const aggregatedData = [];

  items = items.filter(item => !excludedStyles.includes(item.style));

  items.forEach(item => {
    // Find if the item already exists in the aggregated data
    const existingItem = aggregatedData.find(
      i => i.type === item.type &&
        i.format === item.format &&
        i.size === item.size &&
        i.source === item.source
    );

    // Aggregate item counts
    if (existingItem) {
      existingItem.count += item.count;
    } else {
      aggregatedData.push(item);
    }
  });

  return aggregatedData;
};

const deleteHistory = (db, batchId, dispatch) => {
  return new Promise((resolve, reject) => {
    deleteDoc(doc(db, collections.HISTORY, batchId))
      .then(() => {
        console.log("History successfully deleted!");
        // dispatch(deleteHistoryDoc(batchId));
        resolve();
      })
      .catch((error) => {
        // The document probably doesn't exist.
        console.error("Error deleting History: ", error);
        reject();
      });
  });
}

const addOrderError = async (db, batchId, orderId, errorMessage) => {

  const docRef = doc(db, collections.HISTORY, batchId);
  const historyDoc = await getDoc(docRef);

  if (!historyDoc.exists) {
    console.error('ERROR: addOrderError on invalid batch ' + batchId);
    return 0;
  }
  else {

    // extract the existing items array from the batch object
    const batchObject = historyDoc.data();
    const items = batchObject.items;

    // transform items array with new error status
    const newItems = items.map(item => {

      if (item.orderId == orderId) {

        // flag this item as having an error
        item.isError = true;
        // add the error message
        item.errorType = errorMessage;

      }

      return item;
    });

    // update this record with the new items array
    const res = await updateDoc(docRef, { items: newItems });
    return res;
  }

};

const checkExistingHistory = (db, name) => {
  let m1 = moment();
  let m2 = moment();
  //m1.add(-1, 'days');
  //m2.add(-1, 'days');
  m1.startOf('day');
  m2.endOf('day');
  let start_date = m1.toDate();
  let end_date = m2.toDate();

  return new Promise((resolve, reject) => {
    const q = query(collection(db, collections.HISTORY),
      where("baseName", "==", name),
      where("createdAt", ">", start_date),
      where("createdAt", "<=", end_date)
    );

    getDocs(q)
      .then((querySnapshot) => {
        resolve(querySnapshot.docs.length > 0);
      })
      .catch(error => {
        resolve(false);
      });
  });
}

const handleResetTags = async (db, data) => {
  const historyRef = doc(db, collections.HISTORY, data.batchId);
  const downloadsRef = doc(db, collections.DOWNLOADS_SHIPSTATION, data.batchId);
  const batch = writeBatch(db);  // Corrected: Using writeBatch for batch operations

  try {
    const [historySnap, downloadsSnap] = await Promise.all([
      getDoc(historyRef),
      getDoc(downloadsRef)
    ]);

    if (!historySnap.exists()) {
      console.error("History document not found");
      throw new Error("History document not found");
    }

    if (downloadsSnap.exists()) {
      // update document with notifyToShipstation: false
      const downloadsData = downloadsSnap.data();
      const newDownloadsData = {
        ...downloadsData,
        didFinish: false,
        downloads: 0,
        isDownloading: false
      };
      console.log('Updating downloads document:', newDownloadsData);
      batch.update(downloadsRef, newDownloadsData);
    }

    // Prepare the updated batch data with tags reset
    const newBatchData = {
      ...historySnap.data(),
      notifyToShipstation: false,
      // downloads: 0
    };

    batch.update(historyRef, newBatchData);  // Schedule update for the history document

    await batch.commit();  // Execute the batched operations
    console.log('Batch operations completed successfully.');
  } catch (error) {
    console.error('Failed to reset tags:', error);
    throw error;  // Rethrow or handle the error appropriately
  }
};

const getHistoryOnSnapshot = (db, callback) => {
  // where created_at is showing newest first
  const q = query(collection(db, collections.HISTORY), orderBy("completedAt", "desc"), limit(1000));
  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    const historyDocs = querySnapshot.docs.map((doc) => doc.data());

    // Sort by `completedAt` field from newest to oldest
    historyDocs.sort((a, b) => {
      // Sort using the `completedAt` field, converting seconds to milliseconds
      return new Date(b.createdAt.seconds * 1000) - new Date(a.createdAt.seconds * 1000);
    });

    // run the adjustHistoryDocs function on the historyDocs
    const adjustedHistoryDocs = adjustHistoryDocs(historyDocs);
    callback(adjustedHistoryDocs);
  });

  return unsubscribe;
};


const getHistoryMatchById = (db, batchIds, callback) => {
  try {
    const chunks = [];
    while (batchIds.length) {
      chunks.push(batchIds.splice(0, 10)); // Split into chunks of 10
    }

    const allResults = [];
    const unsubscribeFunctions = [];

    chunks.forEach((chunk) => {
      const q = query(
        collection(db, collections.HISTORY),
        where("__name__", "in", chunk)
      );

      // Use Firestore's onSnapshot for real-time updates
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const results = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Add to allResults array and trigger callback with combined results
        allResults.push(...results);

        // Deduplicate results by ID (optional, depending on your use case)
        const uniqueResults = Array.from(
          new Map(allResults.map((item) => [item.id, item])).values()
        );

        callback(uniqueResults);
      });

      unsubscribeFunctions.push(unsubscribe);
    });

    // Return a function to unsubscribe all listeners
    return () => {
      unsubscribeFunctions.forEach((unsubscribe) => unsubscribe());
    };
  } catch (error) {
    console.error("Error querying documents by batches:", error);
    throw error;
  }
};



// adjust data to be as needed.
export const adjustHistoryDocs = (historyDocs) => {
  return historyDocs.map((doc) => {

    const finishedGoods = ["knitted", "hyperoptic", "cut & sew", "graphic tee", "hat", "hoodie"];

    const isWarning = doc.items.some(item =>
      finishedGoods.includes(item.style?.toLowerCase()) &&
      item.style !== "" &&
      item?.errorType?.toLowerCase() !== "product not found"
    );

    const isError = doc.items.some(item => item.isError);

    // if name ends with: - In-Line
    const isInlineBatchFromCustom = doc.name.endsWith("- In-Line");

    if (isInlineBatchFromCustom) {
      doc.typeOfPress = "SHUTTLE";
    }

    const hasInMessage = doc.items.some(item => item.inMessage !== "" && item.message !== "" && item.location !== "" && item.location !== null);

    if (hasInMessage) {
      doc.isWarning = true;
    }

    if (isWarning) {
      doc.isWarning = true;
    }

    if (isError) {
      doc.isError = true;
    }
    if (doc.source?.toLowerCase() === 'order') {
      doc.source = 'Web'
    }
    // const if some items have an order id that starts with DS, set source to DS
    if (doc.items.some(item => String(item.orderId).startsWith('DS'))) {
      doc.source = 'DS';
    }

    if (doc.source.toLowerCase() === "customorder") {
      doc.source = "Web"
    }


    // Assign unique IDs to each item
    doc.items = doc.items.map((item, index) => ({
      ...item,
      id: index + 1, // Assign an ID starting from 1
    }));

    return doc;
  });
}

export { getHistoryDocument, setHistoryDownloads, setPrintStatus, deleteHistory, checkExistingHistory, addOrderError, handleResetTags, getHistoryOnSnapshot, getHistoryMatchById };
