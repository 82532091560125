import React, { createContext, useContext, useState } from "react";

const BatchContext = createContext();

export const BatchProvider = ({ children }) => {
  const [historyDocs, setHistoryDocs] = useState([]);

  const addToHistory = (job) => {
    // add a delay of 1000ms
    setTimeout(() => {
      console.log("SETTING NEW HISTORY DOC");
      setHistoryDocs((prevHistory) => [job, ...prevHistory]); // Add the new item to the top of the list
    }, 15000);
  };

  return (
    <BatchContext.Provider value={{ historyDocs, addToHistory }}>
      {children}
    </BatchContext.Provider>
  );
};

export const useBatchContext = () => useContext(BatchContext);
