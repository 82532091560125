import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ReplyIcon from "@mui/icons-material/Reply";
import LinearProgress from "@mui/material/LinearProgress";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { CSVLink } from "react-csv";
import _ from "lodash";
import { restoreCustomOrders } from "../../db/customOrder";
import { addRestoreInlineOrders } from "../../db/inlineOrder";
import { getOrders, deleteOrder } from "../../db/running";
import { ConfirmDialog } from "../Page/generalModal";
import { EnhancedTableHead } from "../Page/table";

// component styles
import useStyles from "./Running.styles";

import { useDispatch } from "react-redux";
import {
  injectOneItemHistory,
  injectOneRowDataHistory,
  resetStateForFetchRanDoc,
  updateStateForFetchRanDoc,
} from "../../redux/history/actions";
import { fetchUntilFoundFinishedOrder } from "../../utils/fetchUntilFound";
import { useBatchContext } from "./batchContext";

const headers = [
  { label: "sku", key: "sku" },
  { label: "quantity", key: "qty" },
];

const calculateProducts = (orders) => {
  let total = orders.length;
  let products = 0,
    boxes = 0;

  orders.forEach((order) => {
    order.items.forEach((item) => {
      // Ensure item.quantity is defined and is a number
      const quantity = Number(item.quantity);

      // Check if quantity is a valid number
      if (!isNaN(quantity)) {
        if (item.isBoxSet) {
          boxes += quantity;
        } else {
          products += quantity;
        }
      } else {
        // console.error("Invalid quantity detected", item);
      }
    });
  });

  return { total, boxes, products };
};

function Row(props) {
  const { row, db, classes, label, onDelete, onRestore, storage } = props;

  const [open, setOpen] = useState(false);

  let progress = parseInt((row.current / row.total) * 100);

  let status = "";

  if (row.total == 0) {
    progress = 100;
    status = "Ready";
  } else if (row.total > 0 && row.total == row.current) {
    status = "Completed";
  } else {
    status = `${row.current} of ${row.total}`;
  }

  const isOrder = row.source === "CustomOrder" || row.source === "Order";

  return (
    <React.Fragment>
      <TableRow
        hover
        role="checkbox"
        tabIndex={-1}
        key={label}
        classes={{
          root: row.odd ? classes.tableRow : classes.tableRow2, // class name, e.g. `root-x`
          selected: classes.selectedTableRow, // class name, e.g. `disabled-x`
        }}
        selected={open}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" padding="none">
          <Typography variant="tableText">{row.date}</Typography>
        </TableCell>
        <TableCell align="left">
          <Typography variant="tableText">{row.name}</Typography>
        </TableCell>
        <TableCell align="left">
          <Typography variant="tableText">{row.batchInfo ?? ""}</Typography>
        </TableCell>
        <TableCell align="left">
          {status === "Ready" ? (
            <LinearProgress
              classes={{
                colorPrimary: classes.progressReady,
                barColorPrimary: classes.progressBarReady,
              }}
            />
          ) : (
            <LinearProgress
              variant="determinate"
              value={progress}
              color="primary"
              classes={{
                colorPrimary: classes.progressPrimaryNormal,
                barColorPrimary: classes.progressBarNormal,
              }}
            />
          )}
        </TableCell>
        <TableCell align="center">
          <Typography variant="tableText">{status}</Typography>
        </TableCell>
        <TableCell align="right">
          {isOrder && (
            <Button
              variant="blue"
              sx={{ marginRight: "10px" }}
              disabled={!props.editOnly}
              onClick={() => {
                onRestore(row.batch);
              }}
            >
              <ReplyIcon />
            </Button>
          )}
          <Button
            variant="red"
            disabled={!props.editOnly}
            onClick={() => {
              onDelete(row.batch);
            }}
          >
            <DeleteOutlineIcon />
          </Button>
        </TableCell>
      </TableRow>
      <TableRow sx={{ background: "#F1F7FE" }}>
        <TableCell sx={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box display="flex" sx={{ padding: "40px 0 40px 40px" }}>
              <Box flexGrow={1}>
                <Table
                  size="small"
                  aria-label="items"
                  className={classes.itemTable}
                >
                  <TableHead>
                    <TableRow>
                      {isOrder && (
                        <TableCell
                          sx={{ borderBottom: "none", width: "100px" }}
                        >
                          <Typography variant="tableText">ORDER</Typography>
                        </TableCell>
                      )}
                      <TableCell sx={{ borderBottom: "none", width: "80px" }}>
                        <Typography variant="tableText">QTY</Typography>
                      </TableCell>
                      <TableCell sx={{ borderBottom: "none" }}>
                        <Typography variant="tableText">SKU</Typography>
                      </TableCell>
                      <TableCell sx={{ borderBottom: "none" }}></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.items.map((item, index) => (
                      <TableRow key={`running_sub_${index}`}>
                        {isOrder && (
                          <TableCell align="left" sx={{ borderBottom: "none" }}>
                            <Typography variant="tableText">
                              {item.order}
                            </Typography>
                          </TableCell>
                        )}
                        <TableCell align="left" sx={{ borderBottom: "none" }}>
                          <Typography variant="tableText">
                            {item.qty}
                          </Typography>
                        </TableCell>
                        <TableCell align="left" sx={{ borderBottom: "none" }}>
                          <Typography
                            variant={
                              !item.error ? "tableText" : "tableErrorText"
                            }
                          >
                            {item.sku}
                          </Typography>
                        </TableCell>
                        <TableCell align="left" sx={{ borderBottom: "none" }}>
                          <Typography variant="tableErrorText">
                            {item.error}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
              <Box sx={{ width: "200px" }}>
                <CSVLink
                  data={row.items}
                  headers={headers}
                  filename={`${row.name}.csv`}
                  className={classes.buttonCSV}
                  target="_blank"
                >
                  <CloudDownloadIcon />
                  &nbsp;&nbsp;Item CSV
                </CSVLink>
              </Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const headCells = [
  {
    id: "identifier",
    numeric: false,
    align: "left",
    disablePadding: false,
    label: "",
    width: "30px",
  },
  {
    id: "name",
    numeric: false,
    align: "left",
    disablePadding: true,
    label: "DATE",
    width: "120px",
  },
  {
    id: "batch",
    numeric: true,
    align: "left",
    disablePadding: false,
    label: "BATCH NAME",
    width: "300px",
  },
  {
    id: "batchInfo",
    numeric: false,
    align: "left",
    disablePadding: false,
    label: "BATCH Info",
    width: "180px",
  },
  {
    id: "progress",
    numeric: false,
    align: "left",
    disablePadding: false,
    label: "PROGRESS",
    width: "auto",
  },
  {
    id: "status",
    numeric: true,
    align: "center",
    disablePadding: false,
    label: "STATUS",
    width: "120px",
  },
  {
    id: "action",
    numeric: false,
    align: "center",
    disablePadding: false,
    label: "",
    width: "180px",
  },
];

function Running(props) {
  const { addToHistory } = useBatchContext();

  const dispatch = useDispatch();

  const storage = props.firebase.getstorage();

  const classes = useStyles();

  const [runningBatches, setRunningBatches] = useState(new Set());

  const [loading, setLoading] = useState(true);
  const [runningJobs, setRunningJobs] = useState([]);
  const [fullShow, setFullShow] = useState(true);
  const [deleteModal, setDeleteModal] = useState(false);
  const [restoreModal, setRestoreModal] = useState(false);
  const [deleteBatch, setDeleteBatch] = useState("");

  const db = props.firebase.getdb();

  const [removedItems, setRemovedItems] = useState([]);

  useEffect(() => {
    getOrders(db, fetchRunningJobs, setRemovedItems);
  }, []);

  useEffect(() => {
    if (removedItems.length > 0) {
      removedItems.forEach((item) => {
        console.log(`Removed item: ${item.id}, Batch: ${item.batch}`);
        addToHistory(item);
      });
    }
  }, [removedItems]);

  useEffect(() => {
    const newRunningBatches = new Set(runningJobs.map((job) => job.batch));
    setRunningBatches(newRunningBatches);
  }, [runningJobs]);

  const handleDelete = () => {
    console.log("Delete Batch: ", deleteBatch);
    const activeBatch = _.find(runningJobs, { batch: deleteBatch });
    if (activeBatch) {
      deleteOrder(db, activeBatch.id);
    }

    setDeleteModal(false);
  };

  const handleRestore = () => {
    console.log("Restore Batch: ", deleteBatch);

    const activeBatch = _.find(runningJobs, { batch: deleteBatch });
    if (activeBatch) {
      const orders = _.map(activeBatch.items, (order) => order.order);
      if (orders.length > 0) {
        let uniqueOrders = _.uniq(orders);
        if (activeBatch.source === "CustomOrder")
          restoreCustomOrders(db, uniqueOrders);
        else
          addRestoreInlineOrders(
            db,
            activeBatch.batch,
            "running",
            uniqueOrders
          );

        //restoreInlineOrders(db, uniqueOrders);
      }
    }

    setRestoreModal(false);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModal(false);
  };

  const handleCloseRestoreModal = () => {
    setRestoreModal(false);
  };

  const handleDeleteRow = (batchId) => {
    setDeleteBatch(batchId);
    setDeleteModal(true);
  };

  const handleRestoreRow = (batchId) => {
    setDeleteBatch(batchId);
    setRestoreModal(true);
  };

  const renderDeleteModal = () => {
    return (
      <ConfirmDialog
        label="delete"
        title="Delete"
        open={deleteModal}
        onClose={handleCloseDeleteModal}
        onConfirm={handleDelete}
      >
        <Typography variant="body2" gutterBottom>
          Do you want to remove current batch?
        </Typography>
      </ConfirmDialog>
    );
  };

  const renderRestoreModal = () => {
    return (
      <ConfirmDialog
        label="restore"
        title="Restore"
        open={restoreModal}
        onClose={handleCloseRestoreModal}
        onConfirm={handleRestore}
      >
        <Typography variant="body2" gutterBottom>
          Do you want to send back current batch?
        </Typography>
      </ConfirmDialog>
    );
  };

  const fetchRunningJobs = (orders) => {
    setRunningJobs([...orders]);
    setLoading(false);
  };

  let jobs = [...runningJobs];

  const jobsCount = jobs.length;

  const runninginfo = calculateProducts(jobs);

  if (fullShow == false) jobs = jobs.slice(0, 5);

  return (
    <>
      <div className="pageHeader">
        <div className="pageHeading">
          <Typography variant="h1">Running & Recent</Typography>
          <Typography variant="subtitle1">
            Running &nbsp;<strong>{runninginfo.total}</strong> orders,{" "}
            <strong>{runninginfo.products}</strong> products,{" "}
            <strong>{runninginfo.boxes}</strong> bundles
          </Typography>
        </div>
      </div>

      {loading === false && jobs.length === 0 ? (
        <Box sx={{ marginTop: "40px", minHeight: "50px" }}>
          <Typography variant="subtitle1">
            No printsheets currently running
          </Typography>
        </Box>
      ) : (
        <TableContainer sx={{ marginTop: "30px", marginBottom: "50px" }}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={"medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              useCheckbox={false}
              cells={headCells}
            />
            <TableBody>
              {jobs.map((row, index) => {
                const odd = index % 2 === 0;
                const labelId = `running-table-${index}`;
                const keyId = `batch-${row.batch}`;
                row.odd = odd;
                return (
                  <Row
                    key={keyId}
                    db={db}
                    viewOnly={props.viewOnly}
                    editOnly={props.editOnly}
                    label={labelId}
                    row={row}
                    classes={classes}
                    onDelete={handleDeleteRow}
                    onRestore={handleRestoreRow}
                    storage={storage}
                  />
                );
              })}

              {loading && (
                <TableRow>
                  <TableCell align="center" colSpan={7}>
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {jobsCount >= 5 && (
        <div className={classes.itemNav}>
          {fullShow ? (
            <IconButton
              className={classes.itemNavButton}
              onClick={(event) => setFullShow(false)}
            >
              SHOW LESS&nbsp;&nbsp;
              <KeyboardArrowUpIcon />
            </IconButton>
          ) : (
            <IconButton
              className={classes.itemNavButton}
              onClick={(event) => setFullShow(true)}
            >
              LOAD MORE&nbsp;&nbsp;
              <KeyboardArrowDownIcon />
            </IconButton>
          )}
        </div>
      )}

      {renderDeleteModal()}
      {renderRestoreModal()}
    </>
  );
}

export default Running;
