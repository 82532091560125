import React from "react";
import DsOrdersPage from "./DsOrdersPage";
import { withAuthorization } from "../Session";

const DsQueues = (props) => {
  return (
    <DsOrdersPage
      {...props}
      type=""
      includeItemsPerPageSelector={true}
      convertToRowsOptions={true}
      pageName="dsqueues"
    />
  );
};

const condition = (authUser) => !!authUser;
export default withAuthorization(condition)(DsQueues);
