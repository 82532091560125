import React, { useState, useEffect } from 'react';
import { withStyles } from '@mui/styles';
import Menu from '@mui/material/Menu';

const StyledMenu = withStyles({
    paper: {
      border: '1px solid #d3d4d5',
    },
  })((props) => (
    <Menu
      elevation={0}
      getcontentanchorel={null}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
));

export { StyledMenu };  