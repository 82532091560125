import * as React from "react"
const BoxShippingSVG = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={35}
    height={35}
    viewBox="0 0 44 44"  // Add viewBox attribute
    fill="none"
    {...props}
  >
    <path
      fill="#3477F5"
      d="M16.04 38.3c3.219 1.69 4.828 2.534 6.559 2.534V22.5L5.435 13.467c-.025.04-.05.082-.073.123-1.096 1.86-1.096 4.175-1.096 8.804v.214c0 4.628 0 6.943 1.094 8.802 1.096 1.861 3.07 2.897 7.013 4.967l3.666 1.923Z"
    />
    <path
      fill="#3477F5"
      d="M32.824 8.625 29.157 6.7c-3.218-1.688-4.827-2.534-6.558-2.534-1.732 0-3.34.844-6.56 2.534l-3.666 1.925c-3.857 2.024-5.83 3.058-6.937 4.84L22.599 22.5l17.164-9.033c-1.111-1.784-3.08-2.818-6.94-4.842Z"
      opacity={0.7}
    />
    <path
      fill="#3477F5"
      d="M39.838 13.59a5.923 5.923 0 0 0-.075-.123L22.6 22.5v18.334c1.73 0 3.34-.844 6.56-2.534l3.666-1.925c3.943-2.07 5.916-3.104 7.012-4.965 1.095-1.859 1.095-4.174 1.095-8.8v-.214c0-4.63 0-6.947-1.095-8.806Z"
      opacity={0.5}
    />
    <path
      fill="#3477F5"
      d="m12.192 8.72.183-.095 2.738-1.437 16.683 9.176 7.379-3.687c.251.284.471.587.664.913.275.466.48.96.634 1.512l-7.331 3.665v5.566a1.375 1.375 0 1 1-2.75 0v-4.19l-6.417 3.208v17.306a5.61 5.61 0 0 1-1.375.176c-.455 0-.904-.058-1.375-.176V23.351L4.729 15.1c.154-.55.359-1.045.634-1.511.19-.325.413-.631.664-.913L22.6 20.964l6.21-3.104-16.618-9.14Z"
    />
  </svg>
)
export default BoxShippingSVG
