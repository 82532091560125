import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import * as ROUTES from '../../constants/routes';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SettingsIcon from '@mui/icons-material/Settings';

import { withAuthorization } from '../Session';
import Layout from '../Page';
import PageHeader from './pageHeader';
import { EnhancedTableHead } from '../Page/table';
import { getUsers, updateUserPermission, deleteUser, resetUser } from '../../db/user';
import { formatDate } from '../../module/functions';
import { StyledMenu } from '../Page/menu';
import _ from "lodash";
import { ConfirmDialog } from '../Page/generalModal';
import UserModal from './userModal';
import ResetModal from './resetModal';
import { UserPermissions } from '../../constants/defines';

const headCells = [
  { id: 'email', numeric: false, align: 'left', disablePadding: false, label: 'Email', width: '420px' },
  { id: 'created', numeric: false, align: 'left', disablePadding: false, label: 'Created', width: '180px' },
  { id: 'signed', numeric: false, align: 'left', disablePadding: false, label: 'Signed In', width: '180px' },
  { id: 'action', numeric: true, align: 'center', disablePadding: false, label: '', width: '100px' },
];

const permissionItems = [
  { id: 1, name: 'Admin', val: 'Admin' },
  { id: 2, name: 'Customer Service', val: 'Customer Service' },
  { id: 3, name: 'Product Manager', val: 'Product Manager' },
  { id: 4, name: 'Print Admin', val: 'Print Admin' },
  { id: 5, name: 'Print Manager', val: 'Print Manager' },
  { id: 6, name: 'Product Admin', val: 'Product Admin' }
];


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  tableHead: {
    background: 'rgba(217, 223, 233, 0.4)'
  },
  tableHeadCell: {
    lineHeight: '27.5px'
  },
  tableHeadLabel: {
    fontSize: '14px',
    fontWeight: '500',
    color: '#000000',
    letterSpacing: '1.5px'
  },
  tableCell: {
    fontSize: '14px',
    fontWeight: '500',
    letterSpacing: '1.2px',
    color: '#000000'
  },
  tableCellError: {
    fontSize: '14px',
    fontWeight: '500',
    textTransform: 'uppercase',
    letterSpacing: '1.2px',
    color: 'red'
  },
  tableRow: {
    height: '70px',
    backgroundColor: 'rgba(242, 244, 246, 0.4)',
    '&:hover': {
      backgroundColor: '#F1F7FE!important',
      '& button': {
        opacity: '1.0'
      },
    }
  },
  tableRow2: {
    height: '70px',
    backgroundColor: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#F1F7FE!important',
      '& button': {
        opacity: '1.0'
      },
    }
  },
  tableRowError: {
    backgroundColor: 'rgba(255, 0, 0, 0.1)'
  },
  selectedTableRow: {
    backgroundColor: '#F1F7FE!important',
  },
  pagination: {
    marginTop: '60px',
    '& ul': {
      justifyContent: 'center'
    },
  },
  buttonMenu: {
    background: 'transparent',
    color: '#000000',
    opacity: '0',
    transition: '0.2s'
  }
}));


function Users(props) {
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [selected, setSelected] = useState(null);
  const [anchorMenu, setAnchorMenu] = useState(null);
  const [anchorPermission, setAnchorPermission] = useState(null);
  const [permissionModal, setPermissionModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [resetModal, setResetModal] = useState(false);
  const [currentPermission, setCurrentPermission] = useState(null);

  const db = props.firebase.getdb();

  useEffect(() => {
    getUsers(db, fetchData);
  }, []);


  const fetchData = (items) => {

    const _items = _.filter(items, item => { return item.email !== 'rob@rockemsocks.com' });
    setUsers([..._items]);
    setLoading(false);
  };


  const isSelected = (name) => selected === name;

  const handleAdd = () => {
    setAddModal(true);
  }

  const handleToggleMenu = (event, userId) => {
    setSelected(userId);
    setAnchorMenu(event.currentTarget);
  };

  const handleToggleMenuClose = () => {
    setSelected(null);
    setAnchorMenu(null);
  }

  const handleTogglePermission = (event, userId) => {
    setSelected(userId);
    setAnchorPermission(event.currentTarget);
  };

  const handleTogglePermissionClose = () => {
    setSelected(null);
    setAnchorPermission(null);
  }

  const handleChangePermission = (permission) => {
    setCurrentPermission(permission);
    setPermissionModal(true);
    setAnchorPermission(null);
  }

  const handleUpdatePermission = () => {
    updateUserPermission(db, selected, currentPermission);
    setPermissionModal(false);
  }

  const handleClosePermissionDialog = (event) => {
    setPermissionModal(false);
  }

  const handleCloseAddDialog = () => {
    setAddModal(false);
  }

  const handleDeleteUser = () => {
    setDeleteModal(true);
    setAnchorMenu(null);
  }

  const handleEditPermission = () => {
    props.history.push(`${ROUTES.USERS}/${selected}`);
  }

  const handleDoDeleteUser = () => {
    deleteUser(db, selected);
    setDeleteModal(false);
  }

  const handleCloseDeleteDialog = (event) => {
    setDeleteModal(false);
  }

  const handleResetUser = () => {
    setResetModal(true);
    setAnchorMenu(null);
  }

  const handleCloseResetDialog = (event) => {
    setResetModal(false);
  }

  const handleClick = (id) => {
    if (props.editOnly) {
      props.history.push(`${ROUTES.USERS}/${id}`);
    }
  };

  const classes = useStyles();

  return (
    <Layout page={props.page} user={props.user}>
      <PageHeader users={users.length} handleAdd={handleAdd} viewOnly={props.viewOnly} editOnly={props.editOnly} />

      <TableContainer sx={{ marginTop: '30px', marginBottom: '50px' }}>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size={'medium'}
          aria-label="enhanced table"
        >
          <EnhancedTableHead classes={classes} useCheckbox={false} cells={headCells} />
          <TableBody>
            {users
              .map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;
                const keyId = `key-${row.sku}-${index}`;
                const odd = index % 2 === 0;

                return (
                  <TableRow
                    hover
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={keyId}
                    classes={{
                      root: odd ? classes.tableRow : classes.tableRow2,
                      selected: classes.selectedTableRow,
                    }}
                    selected={isItemSelected}
                  >
                    <TableCell align="left" onClick={(event) => handleClick(row.id)}><Typography variant='tableText'>{row.email}</Typography></TableCell>
                    <TableCell align="left" onClick={(event) => handleClick(row.id)}><Typography variant='tableText'>{formatDate(row.createdAt)}</Typography></TableCell>
                    <TableCell align="left" onClick={(event) => handleClick(row.id)}><Typography variant='tableText'>{formatDate(row.signedIn)}</Typography></TableCell>
                    <TableCell align="right">
                      {props.editOnly && (
                        <Button
                          aria-controls="customized-menu"
                          aria-haspopup="true"
                          variant="text"
                          color="primary"
                          className={classes.buttonMenu}
                          onClick={e => handleToggleMenu(e, row.id)}
                        >
                          <MoreVertIcon />
                        </Button>
                      )}

                      {props.editOnly && (
                        <StyledMenu
                          id="customized-menu"
                          anchorEl={anchorMenu}
                          keepMounted
                          open={Boolean(anchorMenu)}
                          onClose={handleToggleMenuClose}
                        >
                          <MenuItem onClick={e => handleEditPermission(e)}><SettingsIcon />&nbsp;&nbsp;Edit Permission</MenuItem>
                          <MenuItem onClick={e => handleDeleteUser(e)}><DeleteOutlineIcon />&nbsp;&nbsp;Delete</MenuItem>
                          <MenuItem onClick={e => handleResetUser(e)}><RestartAltIcon />&nbsp;&nbsp;Reset Password</MenuItem>
                        </StyledMenu>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}

            {loading && (
              <TableRow>
                <TableCell align="center" colSpan={5}>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            )}

          </TableBody>
        </Table>
      </TableContainer>

      <ConfirmDialog
        label="permission-dialog-title"
        title="User Permission"
        open={permissionModal}
        onConfirm={handleUpdatePermission}
        onClose={handleClosePermissionDialog}
      >
        <Typography gutterBottom>
          Do you want to update current permission?
        </Typography>
      </ConfirmDialog>

      <ConfirmDialog
        label="delete-dialog-title"
        title="Delete User"
        open={deleteModal}
        onConfirm={handleDoDeleteUser}
        onClose={handleCloseDeleteDialog}
      >
        <Typography gutterBottom>
          Do you want to delete current User?
        </Typography>
      </ConfirmDialog>

      <UserModal open={addModal} db={db} handleClose={handleCloseAddDialog} />
      <ResetModal open={resetModal} userId={selected} handleClose={handleCloseResetDialog} />
    </Layout>
  );
}

const condition = authUser => !!authUser;
export default withAuthorization(condition)(Users);

