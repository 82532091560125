/**
 * Updates the variant list by merging current and new variants, explicitly handling deletions.
 * @param {Array<string>} currentVariants - The current list of variants.
 * @param {Array<string>} newVariants - The new variants to be merged.
 * @returns {Array<string>} The updated list of variants.
 */
export function mergeAndUpdateVariants(existingVariants, newVariant) {

  if(!existingVariants || existingVariants.length === 0) {
    console.log('No existing variants, returning new variants:', newVariant);
    return newVariant;
  }

  // Example of merging without duplicates
  const updatedVariants = Array.from(new Set([...existingVariants, ...newVariant]));
  
  console.log('Merged:', updatedVariants);
  return updatedVariants;
}
