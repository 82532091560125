import {collections} from '../constants/defines';
import _  from "lodash";
import { 
  collection, 
  query, 
  where, 
  getDocs, 
  orderBy,
  doc,
  updateDoc,
  addDoc,
  setDoc,
  getDoc,
  deleteDoc,
  onSnapshot,
  writeBatch
} from "firebase/firestore";

const getShippings = (db, limit, callback) => {
  const q = query(collection(db, collections.SHIPPINGS));

  onSnapshot(q, (querySnapshot) => {
    let items = [];
    querySnapshot.forEach((doc) => {
      let data = doc.data();
      items.push({
        id: doc.id,
        name: data.name
      });
    });
    callback(items);
  });
}

export { getShippings };