import { useEffect } from "react";


const useStorageKey = ({
  setFunction,
  storageKey,
  initialValue,
  storageType = "localStorage"
}) => {

  useEffect(() => {
    const storedValue = window[storageType].getItem(storageKey);
    // Only attempt to parse and set if `storedValue` is not null
    if (storedValue !== null) {
      try {
        setFunction(JSON.parse(storedValue));
        console.log('Setting storage key => ', storedValue);
      } catch (e) {
        console.error("Error parsing stored value", e);
        setFunction(initialValue);
      }
    }
  }, [setFunction, storageKey, storageType]);

}


export default useStorageKey;