import React, { useState, useEffect, createRef } from 'react';
import { makeStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CheckIcon from '@mui/icons-material/Check';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Dropzone from 'react-dropzone';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import { SIZES, collections, PRODUCT_ART_PREFIX } from '../../constants/defines';
import { PRODUCT_DEFAULT, getProductVariants } from '../../db/products';
import { createUploads } from '../../db/uploads';
import { PrintDialog } from '../Page/generalModal';
import { withAuthorization } from '../Session';
import { FileParser } from './productArt';
import { uploadToStorage } from '../../module/storage';
import { ProductUploadItem } from './productUploadItem';
import { UploadProvider } from '../../context/uploadContext';
import { checkImageDimensions, getDimenssionsAndSize } from '../../utils/findIfIdentical';
import { CircularProgress } from '@mui/material';
import { generateHyperOpticThumbnail } from '../../utils/generateThumbnail';

const sizeYthL = _.find(SIZES, { name: 'YTHL' }).id;
const sizeYth = _.find(SIZES, { name: 'YTHL' }).id;

const useHeaderStyles = makeStyles((theme) => ({
  modalTitle: {
    fontSize: '32px',
    lineHeight: '45px',
    fontWeight: '500',
    color: '#000000'
  },
  modalText: {
    fontSize: '16px',
    lineHeight: '22px',
    fontWeight: '400',
    color: '#000000'
  },
  closeButton: {
    position: 'absolute',
    right: '0px',
    top: '10px',
    color: '#000000',
  },
  iconButton: {
    position: 'absolute',
    left: '-30px',
    padding: '0'
  },
  inputRoot: {
    width: '100%',
    fontSize: '14px',
    fontWeight: '500',
    letterSpacing: '1.5px',
    color: '#000000',
    background: 'rgba(0,0,0,0.04)',
    padding: '11px 15px',
    borderRadius: '4px'
  },
  inputLabel: {
    fontSize: '16px',
    fontWeight: '500',
    color: '#000000'
  },
  inputInlineLabel: {
    fontSize: '16px',
    fontWeight: '500',
    color: '#000000',
    marginTop: '15px',
    marginRight: '25px'
  },
  addButton: {
    textTransform: 'uppercase!important',
    textDecoration: 'underline!important',
    '&:hover': {
      textDecoration: 'underline!important',
    }
  },
  saveButton: {
    background: '#1F7CF2',
    padding: '8px 56px',
    borderRadius: '6px',
    fontSize: '16px',
    fontWeight: '500',
    color: '#FFFFFF',
    textTransform: 'initial'
  },
  duplicateButton: {
    background: 'rgb(61, 61, 61)',
    padding: '8px 56px',
    borderRadius: '6px',
    fontSize: '16px',
    fontWeight: '500',
    color: '#FFFFFF',
    textTransform: 'initial',
    marginRight: '20px'
  },
  link: {
    display: 'block',
    marginTop: '10px',
    fontSize: '16px',
    fontWeight: '500',
    color: '#000000',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none'
    }
  },
  tagList: {
    display: 'flex',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    marginTop: '16px',
    '& li': {
      marginBottom: '10px'
    }
  },
  chip: {
    marginRight: '16px',
    borderRadius: '6px',
    background: '#3d3d3d',
    height: '40px',
    padding: '0 15px 0 25px'
  },
  chipLabel: {
    fontSize: '16px',
    fontWeight: '500',
    color: '#FFFFFF'
  },
  chipButton: {
    color: '#ffffff',
    opacity: '0.4',
    marginLeft: '15px',
    padding: '0',
    '&:hover': {
      opacity: '1',
    }
  },
  image: {
    width: '100%',
    height: 'auto'
  },
  dropBox: {
    textAlign: 'center',
    padding: '88px 0px',
    borderRadius: '6px',
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    '& svg': {
      opacity: '0.56'
    }
  },
  artDropBox: {
    position: 'absolute',
    left: '0',
    top: '0',
    width: '100%',
    height: 'calc(100% - 140px)',
    padding: '35px 0',
    zIndex: '100'
  },

  dropLabel: {
    fontSize: '16px',
    fontWeight: '500',
    color: '#000000',
    opacity: '0.56',
    margin: '0'
  },
  dropButton: {
    fontSize: '16px',
    fontWeight: '500',
    color: '#FFFFFF',
    width: '160px',
    background: '#3D3D3D',
    borderRadius: '6px',
    textTransform: 'Capitalize',
    height: '40px',
    padding: '0',
    marginTop: '20px'
  },
  subTitle: {
    fontSize: '24px',
    fontWeight: '500',
    color: '#000000',
    marginBottom: '10px'
  },
  boldText: {
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: '0.94',
    color: '#000000',
    marginBottom: '10px'
  },
  text: {
    fontSize: '16px',
    lineHeight: '0.94',
    color: '#000000',
    marginBottom: '10px'
  },
  errorText: {
    fontSize: '16px',
    lineHeight: '0.94',
    color: '#FF0000',
    marginBottom: '10px'
  },
  errorList: {
    paddingLeft: '0px',
    listStyle: 'none'
  },
  errorRow: {
    marginBottom: '10px'
  },
  errorItem: {

  },
  progress: {
    height: '6px',
    borderRadius: '3px',
    backgroundColor: 'rgba(20, 183, 121, 0.16 )'
  },
  fullImage: {
    width: '100%',
    height: 'auto'
  },
  itemBoxOverlay: {
    position: 'absolute',
    left: '0',
    top: '0',
    width: '100%',
    height: '100%',
    zIndex: '100',
    background: 'rgba(255,255,255, 0.4)'
  },
  ImageBox1: {
    width: '60px'
  },
  ImageBox2: {
    width: 'calc(50% - 50px)',
  },
  ImageBox3: {
    width: '40px',
    opacity: '0.5',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  ImageBox4: {
    width: 'calc(50% - 50px)'
  },
  ImageBox5: {
    width: 'calc(50% - 10px)'
  },
  ImageBox6: {
    width: 'calc(50% - 10px)'
  },
  ImageBoxHeader: {
    fontSize: '16px',
    lineHeight: '24px',
    color: '#000000',
    textAlign: 'center'
  },
  ImageBoxFooter: {
    fontSize: '16px',
    lineHeight: '24px',
    color: '#000000',
    textAlign: 'center'
  },
  ImageWrapper: {
    position: 'relative',
    border: '1px solid gray',
    borderRadius: '5px',
    fontSize: '0',
    padding: '3px',
    minHeight: '140px',
    textAlign: 'center'
  },
  IdenticalImageWrapper: {
    position: 'relative',
    border: '1px solid gray',
    borderRadius: '5px',
    fontSize: '0',
    padding: '3px',
    minHeight: '220px',
    textAlign: 'center'
  },
  BoxedImageWrapper: {
    position: 'relative',
    border: '1px solid gray',
    borderRadius: '5px',
    fontSize: '0',
    padding: '3px',
    textAlign: 'center'
  },
  SoloImageWrapper: {
    position: 'relative',
    border: '1px solid gray',
    borderRadius: '5px',
    fontSize: '0',
    padding: '3px',
    textAlign: 'center'
  },
  ImageWrapperError: {
    position: 'relative',
    border: '1px solid red',
    borderRadius: '5px',
    fontSize: '0',
    padding: '3px',
    minHeight: '220px',
    textAlign: 'center'
  },
  selectButton: {
    background: '#12B275',
    color: '#FFFFFF',
    fontSize: '16px',
    fontWeight: '500',
    borderRadius: '3px',
    width: '100%',
    marginBottom: '20px',
    border: '1px solid rgb(0 0 0 / 12%)',
    '&:hover': {
      background: '#000000',
    }
  },
  approveButton: {
    background: '#12B275!important',
    color: '#FFFFFF!important',
    fontSize: '16px!important',
    fontWeight: '500!important',
    borderRadius: '3px!important',
    width: '100%',
    border: '1px solid rgb(0 0 0 / 12%)!important',
    '&:hover': {
      background: '#000000!important',
    }
  },
  approveSoloButton: {
    background: '#12B275!important',
    color: '#FFFFFF!important',
    fontSize: '16px!important',
    fontWeight: '500!important',
    borderRadius: '3px!important',
    width: '100%',
    padding: '7px 0!important',
    marginTop: '20px!important',
    border: '1px solid rgb(0 0 0 / 12%)!important',
    '&:hover': {
      background: '#000000!important',
    }
  },
  deleteButton: {
    background: '#E24716!important',
    color: '#FFFFFF!important',
    fontSize: '16px!important',
    fontWeight: '500!important',
    borderRadius: '3px!important',
    width: '100%',
    border: '1px solid rgb(0 0 0 / 12%)!important',
    '&:hover': {
      background: '#000000!important',
    }
  },
  deleteSoloButton: {
    background: '#E24716!important',
    color: '#FFFFFF!important',
    fontSize: '16px!important',
    fontWeight: '500!important',
    borderRadius: '3px!important',
    width: '100%',
    padding: '7px 0!important',
    marginTop: '20px!important',
    border: '1px solid rgb(0 0 0 / 12%)!important',
    '&:hover': {
      background: '#000000!important',
    }
  },
  itemBox1: {
    width: '35%'
  },
  itemBox2: {
    width: '50%'
  },
  itemBox3: {
    width: '15%',
    padding: '20px'
  },
  itemBox4: {
    width: '25%'
  },
  itemBox5: {
    width: '30%'
  },
  itemBox6: {
    width: '45%',
    padding: '20px 0'
  },
  grayRow: {
    backgroundColor: 'rgba(224, 224, 224, 0.35)',
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
  },
  whiteRow: {

  },
  autoRow: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    backgroundColor: '#F1F7FE'
  },

  sizeText: {
    fontSize: '32px!important',
    fontWeight: 'bold!important',
  },
  sizeIcon: {
    fontSize: '40px!important',
    marginTop: '5px!important',
    color: 'rgba(0, 0, 0, 0.26)!important'
  },
  buttonBox: {
    textAlign: 'right',
    padding: '0 0px'
  },
  centeredSpinnerBox: {
    position: 'relative',
    padding: '3px',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    display: 'flex'
  },

}));

const dropzoneUploadRef = createRef();

function UploadProductModal(props) {
  const { onClose, open, templates, tags, sizes, formats, cropSettings } = props;
  const [arts, setArts] = useState([]);
  const [approveIndex, setApproveIndex] = useState(-1);
  const [printDialog, setPrintDialog] = useState(false);
  const classes = useHeaderStyles();
  const db = props.firebase.getdb();
  const [loading, setLoading] = useState(false);

  const handleCloseDialog = (event, reason) => {
    if (reason !== 'backdropClick') {
      setArts([]);
      onClose();
    }
  }

  const handleDrop = async (files) => {
    setLoading(true);
    let images = [];
    let index = 0;
    let promises = [];
    _.map(files, file => {
      let product = { ...PRODUCT_DEFAULT };

      const fileSpec = new FileParser(file.name);
      product.name = fileSpec.name();
      let filePath = fileSpec.filename();

      const _filename = filePath.split('.').slice(0, -1).join('.');
      const ext = filePath.substr(filePath.lastIndexOf('.') + 1);
      filePath = `${_filename}-${Date.now()}.${ext}`;

      if (fileSpec.isFront()) {
        const _image = _.find(images, image => image.key === fileSpec.key());
        if (!_image) {
          images.push({
            index,
            key: fileSpec.key(),
            incorrectSKU: false,
            incorrectDimension: false,
            error: false,
            front: file,
            isMask: false,
            loading: false,
            selected: false,
            product: {
              ...product,
              pathFront: filePath
            }
          });
          index++;
        } else {
          _image.front = file;
          _image.product.pathFront = filePath;
        }
      } else if (fileSpec.isBack()) {
        const _image = _.find(images, image => image.key === fileSpec.key());
        if (!_image) {
          images.push({
            index,
            key: fileSpec.key(),
            incorrectSKU: false,
            incorrectDimension: false,
            error: false,
            back: file,
            isMask: false,
            loading: false,
            selected: false,
            product: {
              ...product,
              pathBack: filePath
            }
          });
          index++;
        } else {
          _image.back = file;
          _image.product.pathBack = filePath;
        }
      } else if (fileSpec.isLeftHyperOptic() || fileSpec.isRightHyperOptic()) {
        // Generate a common key by removing ' (Left)' or ' (Right)' from the filename
        const commonKey = fileSpec.key().replace(' (Left)', '').replace(' (Right)', '');

        // Find an existing image group by the common key
        let imageGroup = images.find(img => img.key === commonKey);

        if (!imageGroup) {
          // If no group exists, create a new one with placeholders for both sides
          imageGroup = {
            index,
            key: commonKey,
            incorrectSKU: false,
            incorrectDimension: false,
            error: false,
            isMask: false,
            loading: false,
            selected: false,
            leftFraternalHyperOptic: null,
            rightFraternalHyperOptic: null,
            product: {
              ...product,
              hyperOpticFraternal: filePath,
              pathLeft: null,
              pathRight: null,
              leftArt: {}, // Placeholder for dimensions, to be filled later
              rightArt: {}, // Placeholder for dimensions, to be filled later
            },
          };
          images.push(imageGroup);
          index++;
        }

        // Update the image group with the current file
        if (fileSpec.isLeftHyperOptic()) {
          imageGroup.leftFraternalHyperOptic = file;
          imageGroup.product.pathLeft = filePath;
          const promise = getDimenssionsAndSize(file).then(dimensions => {
            imageGroup.product.leftArt = { ...dimensions };
          }).catch(error => {
            console.error("🚀 ~ (LEFT)getDimenssionsAndSize ~ error", error);
          });
          promises.push(promise);
        } else if (fileSpec.isRightHyperOptic()) {
          imageGroup.rightFraternalHyperOptic = file;
          imageGroup.product.pathRight = filePath;
          const promise = getDimenssionsAndSize(file).then(dimensions => {
            imageGroup.product.rightArt = { ...dimensions };
          }).catch(error => {
            console.error("🚀 ~ (RIGHT)getDimenssionsAndSize ~ error", error);
          });
          promises.push(promise);
        }
      } else {

        // boxedImageProduct is a square image, that is for Cut n Sew underwear, knitted socks, and add-ons
        const processImage = new Promise((resolve, reject) => {
          getDimenssionsAndSize(file).then((dimensions) => {
            const { width, height } = dimensions;
            let isBoxedImage = width === height;
            // 2766 x 2767
            if (width === 2766 && height === 2767) {
              isBoxedImage = true;
            }

            if (!isBoxedImage) {
              return checkImageDimensions(file, cropSettings);
            }

            console.log('Boxed image');
            // Push to images array as before
            images.push({
              index,
              key: fileSpec.key(),
              incorrectSKU: false,
              incorrectDimension: false,
              error: false,
              front: false,
              back: false,
              isMask: false,
              loading: false,
              selected: false,
              isBoxedImageFile: file,
              product: {
                ...product,
                isBoxedImage: filePath,
                isBoxedImagePath: filePath,
                isBoxedImageArt: {
                  ...dimensions,
                  path: filePath
                }
              }
            });

            // Increment index if necessary
            index++;
            // Resolve the outer promise here to prevent further execution
            resolve('Boxed image processed');

          }).then((dimensionCheckResult) => {
            if (dimensionCheckResult) {
              images.push({
                index,
                key: fileSpec.key(),
                incorrectSKU: false,
                incorrectDimension: false,
                error: false,
                front: false,
                back: false,
                isMask: false,
                loading: false,
                selected: false,
                isIdenticalHyperOptic: file,
                product: {
                  ...product,
                  hyperOpticIdentical: filePath,
                  identicalHyperOpticPath: filePath,
                  hyperOpticIdenticalCropsettings: dimensionCheckResult,
                  hyperOpticIdenticalArt: {
                    ...dimensionCheckResult.hyperOpticCanvas,
                    path: filePath
                  }
                }
              });

              index++;

              resolve('Dimension check passed');
            }
          }).catch((error) => {
            console.log('Error processing image:', error);
            reject(error);
          });
        });

        // Add the processImage promise to the promises array
        promises.push(processImage);

      }

    });

    // Wait for all image dimension checks to complete
    Promise.all(promises).then(() => {
      console.log('All dimension checks complete', images);
      setLoading(false);
      setArts(images); // Update state only after all promises have resolved
    });

  }

  const renderUploadControl = () => {
    return (
      <Box sx={{ padding: '20px 0px 0' }}>
        {
          loading ?
            <Box sx={{ textAlign: 'center' }}>
              <Typography variant="h5" className={classes.subTitle}>Processing Artwork</Typography>
              <Typography variant="h6" className={classes.text}>Please wait while we process your artwork</Typography>
              <CircularProgress />
            </Box> : (
              <Dropzone
                ref={dropzoneUploadRef}
                onDrop={(files) => handleDrop(files)}
                multiple={true}>
                {({ getRootProps, getInputProps }) => (
                  <Box {...getRootProps()} className={classes.dropBox}>
                    <input {...getInputProps()} accept="image/*" />
                    <CloudUploadIcon />
                    <p className={classes.dropLabel}>Drag & Drop<br /> Artwork File</p>
                  </Box>
                )}
              </Dropzone>
            )
        }
      </Box>
    );
  }

  const handleSelectArt = (index, selected) => {
    console.log(`[Upload:Parent:onSelect] Product Index:${index}, Checked:${selected}`);
    const newArts = _.map(arts, item => {
      if (item.index === index)
        return { ...item, selected };
      return { ...item };
    })
    setArts(newArts);
  }

  const handleChangeProduct = (index, product) => {
    const newArts = _.map(arts, item => {
      if (item.index === index) {
        const newProduct = {
          ...product,
          templates: [...product.templates],
          variants: [...product.variants],
          tags: [...product.tags]
        }
        return { ...item, product: newProduct };
      }
      return { ...item, product: { ...item.product } };
    });

    setArts(newArts);
  }

  const handleChangeArtFront = (index, fileInfo) => {
    console.log(`[Upload:Parent:onChangeArtFront] Product Index:${index}`);

    const fileSpec = new FileParser(fileInfo.name);
    const newArts = _.map(arts, item => {
      if (item.index === index) {
        const product = { ...item.product, name: fileSpec.name(), pathFront: fileSpec.filename() };
        return { ...item, front: fileInfo, product };
      }
      return { ...item, product: { ...item.product } };
    });
    setArts(newArts);
  }

  const handleChangeArtLeft = async (index, fileInfo) => {
    const fileSpec = new FileParser(fileInfo.name);

    // Map over arts and create a promise for each item's asynchronous operation
    const promises = arts.map(async (item) => {
      if (item.index === index) {
        const product = { ...item.product, name: fileSpec.name(), pathLeft: fileSpec.filename() };

        // Await here to resolve the promise and get the dimensions
        const dimensionsForLeft = await getDimenssionsAndSize(fileInfo);

        product.leftArt = {
          ...dimensionsForLeft
        };

        return { ...item, leftFraternalHyperOptic: fileInfo, product };
      }
      return item; // Return item directly if index does not match
    });

    // Use Promise.all to wait for all promises to resolve
    Promise.all(promises).then((newArts) => {
      console.log('(LEFT) : newArts:', newArts);
      setArts(newArts); // Update state once all promises have resolved
    }).catch((error) => {
      console.error('Error updating arts:', error);
      setArts(arts); // Reset state to original value if any promise fails
    });
  }

  const handleChangeArtRight = async (index, fileInfo) => {
    const fileSpec = new FileParser(fileInfo.name);

    const promises = arts.map(async (item) => {
      if (item.index === index) {
        const product = { ...item.product, name: fileSpec.name(), pathRight: fileSpec.filename() };

        const dimensionsForRight = await getDimenssionsAndSize(fileInfo);

        product.rightArt = {
          ...dimensionsForRight
        };

        return { ...item, rightFraternalHyperOptic: fileInfo, product };
      }
      return item;
    }
    );

    Promise.all(promises).then((newArts) => {
      console.log('(RIGHT): newArts:', newArts);
      setArts(newArts);
    }
    ).catch((error) => {
      console.error('Error updating arts:', error);
      setArts(arts);
    }
    );

  }

  const handleChangeArtIdentical = (index, fileInfo) => {
    const fileSpec = new FileParser(fileInfo.name);

    const newArts = _.map(arts, item => {
      if (item.index === index) {
        const product = { ...item.product, name: fileSpec.name(), pathIdentical: fileSpec.filename() };
        return { ...item, isIdenticalHyperOptic: fileInfo, product };
      }
      return { ...item, product: { ...item.product } };
    });

    setArts(newArts);

  }

  const handleChangeArtBack = (index, fileInfo) => {
    console.log(`[Upload:Parent:onChangeArtBack] Product Index:${index}`);

    const fileSpec = new FileParser(fileInfo.name);
    const newArts = _.map(arts, item => {
      if (item.index === index) {
        const product = { ...item.product, name: fileSpec.name(), pathBack: fileSpec.filename() };
        return { ...item, back: fileInfo, product };
      }
      return { ...item, product: { ...item.product } };
    });

    setArts(newArts);
  }

  const handleSwitchArt = (index) => {
    console.log(`[Upload:Parent:onSiwtchArt] Product Index:${index}`);
    const newArts = _.map(arts, item => {
      if (item.index === index) {
        const product = { ...item.product };
        const frontFile = item.front;
        const backFile = item.back;

        return { ...item, front: backFile, back: frontFile, product };
      }
      return { ...item, product: { ...item.product } };
    });

    setArts(newArts);
  }

  const handleChangeDimension = (index, dimension) => {
    const newArts = _.map(arts, item => {
      if (item.index === index) {
        return { ...item, incorrectDimension: dimension, product: { ...item.product } };
      }
      return { ...item, product: { ...item.product } };
    });
    setArts(newArts);
  }

  const handleDeleteArt = (index) => {
    console.log(`[Upload:Parent:onDelete] Product Index:${index}`);

    const newArts = [];
    _.each(arts, item => {
      if (item.index !== index) {
        newArts.push({
          ...item,
          product: { ...item.product }
        });
      }
    })

    setArts(newArts);
  }

  const handleDeleteAll = () => {
    console.log('Delete All');
    let _arts = [...arts];
    let __arts = _.filter(_arts, { selected: false });
    setArts(__arts);
  }

  const checkProductSKU = (product) => {
    if (product.sku === "")
      return true;

    if (product.size === "")
      return false;
    let productSKU = product.sku.toLowerCase();
    if (productSKU.includes('/'))
      return false;

    const sizeInfo = _.find(sizes, { id: product.size });
    if (!sizeInfo)
      return;

    let sizeSku = sizeInfo.sku.toLowerCase();
    const productSKUs = productSKU.split("-");
    const productSKU_size = productSKUs.pop();
    return productSKU_size === sizeSku;
  }

  const handleApproveArt = (index) => {
    const art = _.find(arts, { index: index });
    if (checkValidArt(art)) {
      setApproveIndex(index);
      setPrintDialog(true);
    }
  }

  const onUploadArtItem = (name, art) => {
    const promises = [];
    let uploaders = [];
    const product = art.product;


    if (art.front) {
      uploaders.push({
        file: art.front,
        path: product.pathFront
      });
    }

    if (art.back) {
      uploaders.push({
        file: art.back,
        path: product.pathBack
      });
    }

    if (art.leftFraternalHyperOptic) {
      uploaders.push({
        file: art.leftFraternalHyperOptic,
        path: art.leftFraternalHyperOptic.path,
      });
    }

    if (art.rightFraternalHyperOptic) {
      uploaders.push({
        file: art.rightFraternalHyperOptic,
        path: art.rightFraternalHyperOptic.path
      });
    }

    if (art.isIdenticalHyperOptic) {
      uploaders.push({ file: art.isIdenticalHyperOptic, path: product.hyperOpticIdentical });
    }

    if (art.isBoxedImageFile) {
      uploaders.push({ file: art.isBoxedImageFile, path: product.isBoxedImagePath });
    }

    uploaders.forEach(uploader => {
      console.log('uploader:', uploader)
      const file = uploader.file;
      const path = uploader.path;

      const storage = props.firebase.getstorage();
      promises.push(uploadToStorage(storage, `${PRODUCT_ART_PREFIX}${path}`, file));
    });

    Promise.all(promises)
      .then(() => {
        addProductsToUploads(name, [art]);
        console.log('All Files Uploaded!(Single)');
      })
      .catch(err => {
        console.log('Error in uploading files:', err)
      });
  }

  const handleApproveAll = () => {
    console.log('Approve All');
    setApproveIndex(-1);
    setPrintDialog(true);
  }

  // upload MANY arts
  const onUploadArtItems = (name, _arts) => {
    const promises = [];
    let uploaders = [];

    _.each(_arts, art => {
      const product = art.product;
      if (art.front)
        uploaders.push({ file: art.front, path: product.pathFront });

      if (art.back)
        uploaders.push({ file: art.back, path: product.pathBack });

      if (art.leftFraternalHyperOptic) {
        uploaders.push({
          file: art.leftFraternalHyperOptic,
          path: art.leftFraternalHyperOptic.path,
        });
      }

      if (art.rightFraternalHyperOptic) {
        uploaders.push({
          file: art.rightFraternalHyperOptic,
          path: art.rightFraternalHyperOptic.path
        });
      }

      if (art.isIdenticalHyperOptic)
        uploaders.push({ file: art.isIdenticalHyperOptic, path: product.hyperOpticIdentical });

      if (art.isBoxedImageFile) {
        uploaders.push({ file: art.isBoxedImageFile, path: product.isBoxedImagePath });
      }

    });

    uploaders.forEach(uploader => {
      const file = uploader.file;
      const path = uploader.path;
      const storage = props.firebase.getstorage();
      promises.push(uploadToStorage(storage, `${PRODUCT_ART_PREFIX}${path}`, file));
    });

    Promise.all(promises)
      .then(async () => {
        console.log('All Files Uploaded!');
        await addProductsToUploads(name, _arts);
      })
      .catch(err => {
        console.log(err)
      });
  }

  //!  UPLOADER
  const addProductsToUploads = async (name, _arts) => {

    try {
      let items = _.map(_arts, art => {
        art.selected = true;
        const product = art.product;
        const sku = _.trim(product.sku.toUpperCase());

        // Adjusting leftArt and rightArt based on conditions
        if (product.hyperOpticFraternal) {
          console.log('product.hyperOpticFraternal:', product.hyperOpticFraternal);

          const hasLeftArt = product.leftArt && Object.keys(product.leftArt).length > 0;
          const hasRightArt = product.rightArt && Object.keys(product.rightArt).length > 0;

          if (!hasRightArt && hasLeftArt) {
            product.rightArt = { ...product.leftArt, path: product.leftArt.path.replace('(Left)', '(Right)') };
          } else if (!hasLeftArt && hasRightArt) {
            product.leftArt = { ...product.rightArt, path: product.rightArt.path.replace('(Right)', '(Left)') };
          } else {
            console.log('Skipped if/else due to no art adjustments needed');
          }

          product.pathRight = product.rightArt.path;
          product.pathLeft = product.leftArt.path;

        } else {
          console.log('product.hyperOpticFraternal is falsy, skipping art adjustments');
        }

        // save as an YTH if the product is a Youth L
        if (product.size === sizeYthL) {
          product.size = sizeYth;
        }

        if (product.style === "HyperOptic") {
          // only generate thumbnails for hyperoptic products indentical for now. 
          if (product.hyperOpticIdentical && product.hyperOpticIdentical !== "") {
            generateHyperOpticThumbnail(product);
          } else {
            generateHyperOpticThumbnail(product, false);
          }
        }

        return ({
          sku: sku,
          name: _.trim(product.name),
          variants: product.variants,
          type: _.trim(product.type),
          style: _.trim(product.style),
          size: product.size,
          format: product.format,
          pathFront: !!product.pathFront ? product.pathFront : "",
          pathBack: !!product.pathBack ? product.pathBack : "",
          templates: product.templates,
          isCustomProduct: product.isCustomProduct,
          hyperOpticIdenticalArt: product?.hyperOpticIdenticalArt || {},
          leftArt: product?.leftArt || {},
          rightArt: product?.rightArt || {},
          pathLeft: !!product.pathLeft ? product.pathLeft : "",
          pathRight: !!product.pathRight ? product.pathRight : "",
          tags: [...product.tags],
          color: _.trim(product.color),
          identical: product.identical,
          pathHyperOpticIdentical: product?.hyperOpticIdentical || "",
          isBoxedImageArt: product?.isBoxedImageArt || {},
          isBoxedImagePath: product.isBoxedImagePath ? product.isBoxedImagePath : "",
        })
      });

      const strFormat = _.find(formats, { id: items[0].format }).name;

      createUploads(db, name, `${items[0].style} - ${strFormat}`, new Date(), items);

      let currentArts = [...arts];
      let __arts = _.filter(currentArts, { selected: false });
      setArts(__arts);
    } catch (error) {
      console.log('Error:', error);
    }

  }

  const renderArts = () => {
    return (
      <UploadProvider value={{
        templates,
        tags,
        sizes,
        formats,
        cropSettings,
        arts,
        onSelect: handleSelectArt,
        onApprove: handleApproveArt,
        onDelete: handleDeleteArt,
        onChangeProduct: handleChangeProduct,
        onChangeArtFront: handleChangeArtFront,
        onChangeArtLeft: handleChangeArtLeft,
        onChangeArtIdentical: handleChangeArtIdentical,
        onChangeArtRight: handleChangeArtRight,
        onChangeArtBack: handleChangeArtBack,
        onSwitchArt: handleSwitchArt,
        onChangeDimension: handleChangeDimension
      }}
        key={`UploadProvider_${arts.length}`}
      >
        <Box>
          {arts.map((art, index) => {
            const odd = index % 2 === 0;
            return (
              <>
                <ProductUploadItem
                  key={`Atomic_${art.index}`}
                  storage={props.firebase.getstorage()}
                  index={art.index}
                  loading={art.loading}
                  classes={classes}
                  data={art}
                  gray={odd}
                />
              </>
            )
          })}
        </Box>
      </UploadProvider>
    )
  }

  const handleAddProduct = () => {
    let _arts = [...arts];
    let product = { ...PRODUCT_DEFAULT };

    _arts.push({
      index: _arts.length,
      key: `NewProduct_${_arts.length}`,
      incorrectSKU: false,
      incorrectDimension: false,
      error: false,
      loading: false,
      selected: false,
      back: null,
      front: null,
      isMask: false,
      product
    });
    setArts(_arts);
  }

  const renderToolbar = () => {
    const _arts = _.filter(arts, { selected: true });
    return (
      <Box display="flex" justifyContent="space-between" sx={{ width: '100%' }}>
        <Box>
          <IconButton
            variant='green'
            disabled={_arts.length == 0}
            onClick={handleApproveAll}
            sx={{ width: '200px', marginRight: '30px', marginBottom: '0px' }}>
            <Box display="flex" alignItems="center" justifyContent="space-around" sx={{ width: '80%' }}>
              <Typography sx={{ marginRight: '30px' }}>Approve</Typography>
              <CheckIcon />
            </Box>
          </IconButton>

          <IconButton
            variant='red'
            disabled={_arts.length == 0}
            onClick={handleDeleteAll}
            sx={{ width: '200px' }}>
            <Box display="flex" alignItems="center" justifyContent="space-around" sx={{ width: '80%' }}>
              <Typography sx={{ marginRight: '30px' }}>Delete</Typography>
              <DeleteOutlineIcon />
            </Box>
          </IconButton>
        </Box>
        <Box>
          <Button className={classes.addButton} onClick={handleAddProduct} >Add Product</Button>
        </Box>
      </Box>
    );
  }

  const handleClickUploadArt = () => {
    console.log('handleClickUploadArt');
    dropzoneUploadRef.current.open();
  }


  const handleClosePrintDialog = () => {
    setPrintDialog(false);
  }

  const checkValidArt = (art) => {

    const product = art.product;

    // Hotfix for missing paths
    if (product.hyperOpticFraternal) {
      // When pathRight exists, add pathLeft by replacing 'Right' with 'Left'
      if (product.pathRight && !product.pathLeft) {
        product.pathLeft = product.pathRight.replace(' (Right)', ' (Left)');
      }
      // When pathLeft exists, add pathRight by replacing 'Left' with 'Right'
      if (product.pathLeft && !product.pathRight) {
        product.pathRight = product.pathLeft.replace(' (Left)', ' (Right)');
      }
    }

    let errors = []; // Array to store error messages

    // Check each condition and add error messages as needed
    if (product.name === "") errors.push("Product name is missing.");
    if (product.sku === "") errors.push("Product SKU is missing.");
    if (product.type === "") errors.push("Product type is missing.");
    if (product.format === "") errors.push("Product format is missing.");
    if (product.size === "") errors.push("Product size is missing.");
    if (product.style === "") errors.push("Product style is missing.");
    if (art.incorrectDimension) errors.push("Art has incorrect dimensions.");
    if (product.pathFront === "" && !product?.hyperOpticIdentical && product.hyperOpticFraternal === "" && product.isBoxedImage === "") errors.push("Front path is missing.");
    if (product.pathBack === "" && !product?.hyperOpticIdentical && product.hyperOpticFraternal === "" && product.isBoxedImage === "") errors.push("Back path is missing.");
    if (product.pathLeft === "" && product?.hyperOpticFraternal && product.isBoxedImage === "") errors.push("Left path is missing.");
    if (product.pathRight === "" && product?.hyperOpticFraternal && product.isBoxedImage === "") errors.push("Right path is missing.");
    if (product.pathIdentical === "" && product?.hyperOpticIdentical && product.isBoxedImage === "") errors.push("Identical path is missing.");

    // Check for duplicated SKUs
    const duplicated = _.filter(arts, (item) => item.product.sku === product.sku).length > 1;
    if (duplicated) errors.push("Duplicated SKU found.");

    // Check SKU validity
    if (product.isBoxedImage === "") {
      art.incorrectSKU = !checkProductSKU(product);
      if (art.incorrectSKU) errors.push("Product SKU is incorrect.");
    }

    // Log errors if any
    if (errors.length > 0) {
      console.log("Validation Errors:", errors.join(" "));
      art.error = true; // Mark the art object with an error
      return false;
    } else {
      art.error = false; // Mark the art object as valid
      return true;
    }
  };


  const handlePrintAll = async (name) => {
    let _arts = [...arts];
    let reqs = [], orders = [];
    let totalErrors = 0; // To keep track of total number of errors

    _.each(_arts, art => {
      if (art.selected === true) {
        const product = art.product;

        // Hotfix for missing paths
        if (product.hyperOpticFraternal) {
          // When pathRight exists, add pathLeft by replacing 'Right' with 'Left'
          if (product.pathRight && !product.pathLeft) {
            product.pathLeft = product.pathRight.replace(' (Right)', ' (Left)');
          }
          // When pathLeft exists, add pathRight by replacing 'Left' with 'Right'
          if (product.pathLeft && !product.pathRight) {
            product.pathRight = product.pathLeft.replace(' (Left)', ' (Right)');
          }
        }
        let errors = []; // Array to store error messages for each art

        // Check each condition and add error messages as needed
        if (product.name === "") errors.push("Product name is missing.");
        if (product.sku === "") errors.push("Product SKU is missing.");
        if (product.type === "") errors.push("Product type is missing.");
        if (product.format === "") errors.push("Product format is missing.");
        if (product.size === "") errors.push("Product size is missing.");
        if (product.style === "") errors.push("Product style is missing.");
        if (art.incorrectDimension) errors.push("Art has incorrect dimensions.");
        if (product.pathFront === "" && !product?.hyperOpticIdentical && !product?.hyperOpticFraternal && product.isBoxedImage === "") errors.push("Front path is missing.");
        if (product.pathBack === "" && !product?.hyperOpticIdentical && !product?.hyperOpticFraternal && product.isBoxedImage === "") errors.push("Back path is missing.");

        // Check for duplicated SKUs within _arts
        const duplicated = _.filter(_arts, item => item.product.sku === product.sku).length > 1;
        if (duplicated) errors.push("Duplicated SKU found.");

        if (product.isBoxedImage === "") {
          art.incorrectSKU = !checkProductSKU(product);
          if (art.incorrectSKU) errors.push("Product SKU is incorrect.");
        }
        art.error = errors.length > 0;
        if (art.error) {
          totalErrors += errors.length;
          console.log(`[Error] Product. Index: ${art.index}, Name: '${product.name}', Errors: ${errors.join(", ")}`);
        } else {
          _.each(product.variants, variant => {
            reqs.push(getProductVariants(db, variant));
            orders.push(art.index);
          });
        }
      }
    });

    const responses = await Promise.all(reqs);
    for (let i = 0; i < responses.length; i++) {
      let rsp = responses[i];
      if (rsp.skus.length > 0) {
        let _art = _.find(_arts, { index: orders[i] });
        _art.error = true;
        totalErrors++;
        _art.loading = false;
      }
    }

    if (totalErrors === 0) {
      _.each(_arts, art_2 => {
        if (art_2.selected === true) {
          art_2.loading = true;
        }
      });
    }

    setArts(_arts);

    if (totalErrors > 0) {
      console.log('Error Products Noticed', totalErrors);
      return;
    }

    let __arts = _.filter(_arts, { selected: true });
    onUploadArtItems(name, __arts);
    setPrintDialog(false);
  };


  const handlePrintOne = (name) => {
    let _arts = [...arts];
    let error = false;
    let reqs = [];

    _.each(_arts, art => {
      if (art.index === approveIndex) {
        const product = art.product;
        let errors = []; // Array to store error messages

        // Check each condition and add error messages as needed
        if (product.name === "") errors.push("Product name is missing.");
        if (product.sku === "") errors.push("Product SKU is missing.");
        if (product.type === "") errors.push("Product type is missing.");
        if (product.format === "") errors.push("Product format is missing.");
        if (product.size === "") errors.push("Product size is missing.");
        if (product.style === "") errors.push("Product style is missing.");
        if (art.incorrectDimension) errors.push("Art has incorrect dimensions.");
        if (product.pathFront === "" && !product?.hyperOpticIdentical && product.hyperOpticFraternal === "") errors.push("Front path is missing.");
        if (product.pathBack === "" && !product?.hyperOpticIdentical && product.hyperOpticFraternal === "") errors.push("Back path is missing.");

        // Check for duplicated SKUs within _arts
        const duplicated = _.filter(_arts, item => item.product.sku === product.sku).length > 1;
        if (duplicated) errors.push("Duplicated SKU found.");

        // Check SKU validity
        if (product.isBoxedImage === "") {
          art.incorrectSKU = !checkProductSKU(product);
          if (art.incorrectSKU) errors.push("Product SKU is incorrect.");
        }


        // Determine if there are any errors
        art.error = errors.length > 0;
        art.loading = !art.error; // Set loading to false if there are errors

        // Log errors if any
        if (art.error) {
          console.log(`Validation Errors for art index ${art.index}:`, errors.join(" "));
        }

        // Mapping product variants to requests
        reqs = _.map(product.variants, variant => getProductVariants(db, variant));
      }
    });

    return Promise.all(reqs)
      .then(function (responses) {
        let art = _.find(_arts, art => art.index === approveIndex);
        let duplicated = 0;
        for (let i = 0; i < responses.length; i++) {
          let rsp = responses[i];
          duplicated += rsp.skus.length;
        }

        if (duplicated) {
          art.error = true;
          error = true;
          art.loading = false;
        }
        setArts(_arts);
        if (error) {
          console.log('Approve Error');
          return;
        }

        onUploadArtItem(name, art);

        setPrintDialog(false);
      });
  }

  const handlePrint = (name) => {
    // !EMPTY BEOFRE -DBUG MESSAGE ONLY
    if (approveIndex == -1) {
      handlePrintAll(name);
    } else {
      handlePrintOne(name);
    }
  }

  const renderDropDialog = () => {
    return (
      <Dialog
        onClose={handleCloseDialog}
        aria-labelledby="upload-drop-dialog"
        open={open}
        fullWidth={true}
        maxWidth="sm">
        <DialogTitle id="print-dialog-title" onClose={handleCloseDialog} sx={{ padding: '10px 40px', marginTop: '20px' }}>
          <IconButton aria-label="close" variant='close' onClick={handleCloseDialog}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {renderUploadControl()}
        </DialogContent>

        <DialogActions sx={{ padding: '20px 40px', justifyContent: 'center' }}>
          <Button
            onClick={handleClickUploadArt}
            color="primary"
            variant="blue"
            sx={{ width: '200px' }}
          >Upload Art</Button>
        </DialogActions>
      </Dialog>
    );
  }

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      let _arts = _.map(arts, item => {
        return { ...item, selected: true };
      })
      setArts([..._arts]);
      return;
    }

    let _arts = _.map(arts, item => {
      return { ...item, selected: false };
    })
    setArts([..._arts]);
  }

  const renderHeaderBox = () => {
    const numSelected = _.filter(arts, { selected: true }).length;
    const allSelected = arts.length === numSelected;

    return (
      <Box className={classes.buttonBox}>
        <FormControl component="fieldset" sx={{ marginRight: '40px', height: '46px', width: '165px' }}>
          <FormGroup aria-label="position" row>
            <FormControlLabel
              className={classes.formLabel}
              control={
                <Checkbox color="primary"
                  indeterminate={numSelected > 0 && !allSelected}
                  checked={numSelected > 0 && allSelected}
                  onChange={handleSelectAll}
                />}
              label={numSelected > 0 && allSelected ? "SELECT NONE" : "SELECT ALL"}
              labelPlacement="end"
            />
          </FormGroup>
        </FormControl>

      </Box>
    )
  }

  const renderImagesDialog = () => {
    return (
      <Dialog
        onClose={handleCloseDialog}
        aria-labelledby="upload-images-dialog"
        open={open}
        fullWidth={true}
        maxWidth="lg">
        <DialogTitle id="print-dialog-title" onClose={handleCloseDialog} sx={{ padding: '10px 40px', marginTop: '20px' }}>
          <Typography variant='h2'>Add New Products</Typography>
          <IconButton aria-label="close" variant='close' onClick={handleCloseDialog}>
            <CloseIcon />
          </IconButton>
          <Typography className={classes.modalText}>{arts.length} New Items</Typography>
          {renderHeaderBox()}
        </DialogTitle>
        <DialogContent sx={{ padding: '20px 0' }}>
          {renderArts()}

          <PrintDialog
            open={printDialog}
            title="Upload"
            label="Upload"
            showInfo={false}
            onClose={handleClosePrintDialog}
            onPrint={handlePrint}
            arts={arts}
          />

        </DialogContent>
        <DialogActions sx={{ padding: '20px 40px', justifyContent: 'space-between' }}>
          {renderToolbar()}
        </DialogActions>
      </Dialog>
    );

  }

  return arts.length > 0 ? renderImagesDialog() : renderDropDialog();
}


const condition = authUser => !!authUser;
export default withAuthorization(condition)(UploadProductModal);
