import {collections, orderStatus, orderTypes} from '../constants/defines';
import _  from "lodash";
import { 
  collection, 
  query, 
  where, 
  getDocs, 
  orderBy,
  doc,
  updateDoc,
  addDoc,
  setDoc,
  getDoc,
  deleteDoc,
  onSnapshot,
  writeBatch
} from "firebase/firestore";

const getOrderActivities = (db, orderNumber, callback) => {
  const q = query(collection(db, collections.ACTIVITIES), 
    where("orderNumber", "==", orderNumber),
    orderBy("createdAt")
  );

  getDocs(q)
    .then((querySnapshot) => {
      let activities = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        activities.push(data);
      });
      callback(activities); 
    })
    .catch( error => {
      console.log("Error getting items", error.message);
      callback([]);
    });
}

export {
    getOrderActivities
};
