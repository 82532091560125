import * as React from "react"
const MailboxSVG = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={76}
    height={76}
    fill="none"
    {...props}
  >
    <path
      fill="#555"
      d="M6.333 35.625c0-9.182 6.38-16.625 14.25-16.625s14.25 7.443 14.25 16.625v27.708h-21.43c-3.905 0-7.07-3.692-7.07-8.248v-19.46Z"
      opacity={0.5}
    />
    <path
      fill="#383838"
      d="M34.833 35.625v27.708h27.845c3.86 0 6.988-3.65 6.988-8.153V35.625c0-9.182-6.38-16.625-14.25-16.625H20.583c7.87 0 14.25 7.443 14.25 16.625Z"
      opacity={0.8}
    />
    <path
      fill="#000"
      d="M30.083 63.333v6.333a2.375 2.375 0 1 0 4.75 0v-6.333h-4.75ZM47.5 63.333h-4.75v6.333a2.375 2.375 0 1 0 4.75 0v-6.333Z"
    />
    <path
      fill="#000"
      fillRule="evenodd"
      d="M13.458 50.667a2.375 2.375 0 0 1 2.375-2.375h9.5a2.375 2.375 0 1 1 0 4.75h-9.5a2.375 2.375 0 0 1-2.375-2.375ZM55.052 20.851l.81-.162a6.908 6.908 0 0 1 3.93.363 11.136 11.136 0 0 0 6.853.469l.195-.049c1.658-.415 2.827-1.956 2.827-3.728v-6.747c0-2.336-2.103-4.058-4.29-3.51a6.138 6.138 0 0 1-3.778-.26l-.231-.092a11.138 11.138 0 0 0-6.335-.585l-1.42.284c-1.71.342-2.946 1.897-2.946 3.706v22.015c0 1.258.981 2.278 2.192 2.278 1.211 0 2.193-1.02 2.193-2.278V20.851Z"
      clipRule="evenodd"
    />
  </svg>
)
export default MailboxSVG
