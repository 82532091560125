import React, { useState, useEffect } from 'react';
import { withStyles } from '@mui/styles';
import axios from 'axios';

// Material UI dependencies
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import DoneIcon from '@mui/icons-material/Done';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AssignmentIcon from '@mui/icons-material/Assignment';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ScheduleIcon from '@mui/icons-material/Schedule';
import InventoryIcon from '@mui/icons-material/Inventory';
import PostAddIcon from '@mui/icons-material/PostAdd';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import Menu from '@mui/material/Menu';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
// Utility functions
import { API, collections } from '../../constants/defines';
import { createCSV } from '../../module/functions';
import { ConfirmDialog } from '../Page/generalModal';
import { getHistoryDocument, setPrintStatus, setHistoryDownloads, deleteHistory, addOrderError, handleResetTags } from '../../db/history';
import { restoreCustomOrders, restoreCustomOrderItems } from '../../db/customOrder';
import { restoreInlineOrders, restoreInlineOrderItems, addRestoreInlineOrders } from '../../db/inlineOrder';
import { getBundleBySku, getBySku } from '../../db/products';
import { palette } from '../../theme/colors';
import { useSelector, useDispatch } from 'react-redux';
import Loading from '../Loading';
import { injectHistory, replaceHistoryDoc, setDownloadStatus, updateKeyValueInHistory } from '../../redux/history/actions';
import { usePrevious } from '../../hooks/usePrevious';
import { listenToShipstationBatchDownloadStatus } from '../../db/getShipstationDownload';
import { handleCopyToBoard } from '../../utils/copyToClip';
import { ToastCopyCLip } from '../Toast/copyToClip';
import { listenToPrintStatus } from '../../db/listenToPrintStatus';
import { listenToDownloadStatus } from '../../db/listenToDownloadButton';
import SubItemRow from './SubMixAndMatchItems';
import _ from 'lodash';
import restoreOrdersBatchForDs from '../../db/restoreDsOrders';
import { Toast } from '../Toast';


const formatFirestoreTimestamp = (timestamp) => {

  if (typeof timestamp === 'string') {
    return timestamp;
  }

  if (!timestamp || typeof timestamp !== 'object' || !timestamp._seconds) {
    // console.log('Invalid timestamp:', timestamp);
    return '';
  }

  const date = new Date(timestamp._seconds * 1000);

  return date.toLocaleString(); // This will format the date and time according to the locale settings
};


// Inline styles for ellipses button pop-up menu
const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getcontentanchorel={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
));

const HistoryInventoryItems = ({ inventory, classes }) => {
  const html = [];
  _.each(inventory, item => {
    const offset = item._quantity - item.quantity;
    if (offset > 0)
      //html.push(<Typography>{item.sku} ({item.location}) - {offset} {offset>1?'Units':'Unit'}</Typography>);
      html.push(<Typography className={classes.txtLocation}>{item.sku}, {item.location}</Typography>);
  });

  return (
    <Box sx={{ padding: '5px 0' }}>
      {html}
    </Box>
  )
}

// Sub-component for expanded items listing
function HistoryItemsTable(props) {
  const [showToast, setShowToast] = useState(false);
  const { items, classes, source, setOrders } = props;
  const [selected, setSelected] = useState([]);
  const [open, setOpen] = useState(false);

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
    setOrders(newSelected);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Function to decide the new style based on original style
  const getModifiedStyle = (originalStyle) => {
    if (originalStyle?.includes('Graphic')) return null;
    if (originalStyle?.includes('Hyper')) return 'HYPEROPTIC';
    if (originalStyle?.includes('Knit')) return 'KNITTED';
    if (originalStyle?.includes('Cut & Sew')) return 'CUT & SEW';
    if (originalStyle?.includes('Packaging')) return 'ADD-ON';
    return originalStyle; // Return unchanged if no conditions match
  };

  // Transform items here before rendering
  const transformedItems = items.map(item => ({
    ...item,
    style: getModifiedStyle(item.style),
  }));

  const [toastMessage, setToastMessage] = useState('');

  return (
    <Table size="small" aria-label="items" className={classes.itemTableSub}>
      <TableHead>
        <TableRow>
          <TableCell className={classes.itemTableCell} sx={{ width: '20px' }}></TableCell>
          <TableCell className={classes.itemTableCell} sx={{ width: '70px' }}>QTY</TableCell>
          {source !== "Manual" && (
            <TableCell className={classes.itemTableCell} sx={{ width: '200px' }}>ORDER</TableCell>
          )}
          <TableCell className={classes.itemTableCell} sx={{ width: '400px' }}>SKU</TableCell>
          <TableCell className={classes.itemTableCell}></TableCell>
          <TableCell className={classes.itemTableCell} sx={{ width: '250px' }}></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {transformedItems.map((item, index) => {

          const isError = item.isError;

          const isItemSelected = isSelected(item.id);

          let quantity = item.quantity;

          const mixAndMatchItem = item.isMixAndMatch;

          if (item.inventory && !item.isBoxSet) {
            quantity = item.inventory.quantity;
          }

          if (!quantity && mixAndMatchItem) {
            quantity = item.quantity
          }

          if (mixAndMatchItem) {
            item.style = `HYPEROPTIC MIX N' MATCH`;
            item.sku = `MIX N' MATCH - ${item.collection}`;
          }

          if (item.type === 'T-Shirt') {
            item.style = 'T-Shirt';
          }

          const rowTextStyle = item.style ? palette.productPurple : '';

          return (
            <>
              <TableRow key={`history_sub_${index}`} >
                <TableCell padding="checkbox" sx={{ borderBottom: 'none' }} onClick={(event) => handleClick(event, item.id)}>
                  <Checkbox
                    color="primary"
                    checked={isItemSelected}
                  />
                </TableCell>
                <TableCell align="left" sx={{ borderBottom: 'none' }}>
                  <Typography variant={isError ? 'tableErrorText' : 'tableText'}
                    sx={{ color: rowTextStyle }}
                  >{quantity}</Typography>
                </TableCell>
                {source !== "Manual" && (
                  <TableCell
                    onClick={() => handleCopyToBoard(`Copied Order ID: ${item.orderId} `, item.orderId, setToastMessage, setShowToast)}
                    align="left"
                    sx={{ borderBottom: 'none', cursor: 'pointer' }}
                  >
                    <Typography variant={isError ? 'tableErrorText' : 'tableText'}
                      sx={{ color: rowTextStyle }}
                    >{item.orderId}</Typography>
                  </TableCell>
                )}
                <TableCell
                  onClick={() => handleCopyToBoard(`Copied SKU: ${item.sku} `, item.sku, setToastMessage, setShowToast)}
                  align="left"
                  sx={{ borderBottom: 'none', cursor: 'pointer' }}
                >
                  <Typography variant={isError ? 'tableErrorText' : 'tableText'}
                    sx={{ color: rowTextStyle }}
                  >
                    {item.sku}</Typography>
                </TableCell>
                {item?.style && (
                  <TableCell align="left" sx={{ borderBottom: 'none', width: "20%" }}>
                    <Typography variant={isError ? 'tableErrorText' : 'tableText'}
                      sx={{ color: rowTextStyle }}
                    >{item?.style}</Typography>
                  </TableCell>
                )}
                {
                  isError ? (
                    <TableCell align="left" sx={{ borderBottom: 'none' }}><Typography variant='tableErrorText'>{item.errorType}</Typography></TableCell>
                  ) : (
                    <TableCell align="left" sx={{ borderBottom: 'none' }}><Typography variant='tableGreenText'>{item.message}</Typography></TableCell>
                  )
                }
                <TableCell align="left" sx={{ borderBottom: 'none' }}>
                  {item.isBoxSet && item.message !== '' ? (
                    <IconButton aria-label="expand row" size="small" className={classes.buttonInventory} onClick={() => setOpen(!open)}>
                      {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}<Typography variant='tableGreenText'>{item.location}</Typography>
                    </IconButton>
                  ) : (
                    <><Typography variant='tableGreenText'>{item.location}</Typography></>
                  )}
                  {open && (
                    <HistoryInventoryItems inventory={item.inventory} classes={classes} />
                  )}
                </TableCell>
              </TableRow>
              {mixAndMatchItem && (
                <>
                  <SubItemRow
                    subItem={item.inventory.firstSock}
                    orderId={item.orderId}
                    index={`${index}_1`}
                    label="First"
                    color={palette.productPurple}
                  />
                  <SubItemRow
                    subItem={item.inventory.secondSock}
                    orderId={item.orderId}
                    index={`${index}_2`}
                    label="Second"
                    color={palette.productPurple}
                  />
                </>
              )}
            </>
          );
        })}
      </TableBody>
      <ToastCopyCLip show={showToast} message={toastMessage} />
    </Table>
  );
}


export function RowWrapper(props) {
  const { rowData } = useSelector(state => state.history);

  const useThisRowData = props?.rowDataLocalState || rowData;

  const dataRetrieved = useThisRowData.find(item => item?.data?.batchId === props.row.batchId);

  const clonedDataRetrieved = _.cloneDeep(dataRetrieved);

  if (clonedDataRetrieved?.data?.items) {
    // Check if isWarning is already true; if not, update it based on the items
    if (!clonedDataRetrieved.data.isWarning) {
      clonedDataRetrieved.data.isWarning = clonedDataRetrieved.data.items.some(item => item.isMixAndMatch);
    }
  }

  // Check if dataRetrieved is undefined or if dataRetrieved.data is an empty object
  if (!dataRetrieved || Object.keys(dataRetrieved?.data || {}).length === 0) {
    return null;
  }

  return <Row {...props} dataRetrieved={clonedDataRetrieved} />;
}


// primary row component
const Row = React.memo(({ dataRetrieved, ...props }) => {

  const [showToast, setShowToast] = useState(false);
  const [toastData, setToastData] = useState({});

  const { isLoading } = useSelector(state => state.history);

  const dispatch = useDispatch();

  const data = dataRetrieved?.data;
  const { classes, label, db, query, onDelete, storage, } = props;

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selected, setSelected] = useState([]);
  const [mode, setMode] = useState(0);
  const [modal, setModal] = useState(false);
  const [modalMsg, setModalMsg] = useState("");
  const [modalTitle, setModalTitle] = useState("");

  // attributes for print button
  const [loading, setLoading] = useState(false);
  const [printed, setPrinted] = useState(false);
  const [downloaded, setDownloaded] = useState(false);
  const [notifyToShipstation, setNotifyToShipstation] = useState(false);
  const [shipLoading, setShipLoading] = useState(false);
  const [intervalId, setIntervalId] = useState(0);
  const [seconds, setSeconds] = useState(0);


  const prevOptions = usePrevious(seconds);

  useEffect(() => {
    // Function to perform deep comparison between two objects
    const isDifferent = (obj1, obj2) => {
      return JSON.stringify(obj1) !== JSON.stringify(obj2);
    };

    // Skip the effect on initial mount or if options have not changed
    if (!prevOptions || !isDifferent(prevOptions, seconds)) {
      return;
    }

    console.log('Fetching Inline Orders...')

    setLoading(true);

    getHistoryDocument(db, data.batchId.toString(), fetchHistoryDocument, storage);

  }, [prevOptions]);



  useEffect(() => {
    if (nonOrderRow) {
      if (intervalId === 0 && downloaded == true && !notifyToShipstation) {
        startTimerHandler();
      } else if (intervalId !== 0 && notifyToShipstation) {
        // stopTimerHandler();
      }
    }
  }, [downloaded, notifyToShipstation, intervalId]);

  // update the print button color based on the current print status
  const fetchHistoryDocument = (item) => {
    if (item.exists) {

      const updateBatchDataForRedux = {
        ...item,
        downloads: item.downloads,
        printed: item.printed,
        notifyToShipstation: item.notifyToShipstation
      }

      dispatch(replaceHistoryDoc(updateBatchDataForRedux, db, storage));

    }
    setLoading(false);
  };

  const startTimerHandler = () => {
    console.log(`Start Timer...Row Id:'${data.batchId}', Name:'${data.name}'`);
    let newIntervalId = setInterval(() => {
      setSeconds(seconds => seconds + 1);
    }, 5000);

    setIntervalId(newIntervalId);
  };

  // toggle printed status
  const handlePrintButton = () => {
    // prompt user for 'unprint'
    if (printed === true) {
      setMode(4);
      setModalTitle('Undo');
      setModalMsg('Are you sure you want to undo this print?');
      setModal(true);
    }
    else {
      //handleNotifyToShipstation();
      setPrintStatus(db, data.batchId, !printed, setPrinted, data, props.formats, props.sizes, dispatch);
    }
  };

  const restoreOrders = async (orders) => {
    console.log('Restore Orders: ', orders);
    if (isCustomRow)
      await restoreCustomOrders(db, orders);
    else {
      //await restoreInlineOrders(db, orders);
      await addRestoreInlineOrders(db, data.batchId, "all", orders);
    }

    setSelected([]);
  }

  const restoreOrderItems = async (items) => {
    let orders = [];
    _.each(items, item => {
      let _sel = _.find(orders, { order: item.order });
      if (!_sel) {
        let newItem = { order: item.order, skus: [item.sku] };
        orders.push(newItem);
      } else {
        _sel.skus.push(item.sku);
      }
    });

    const hasDsOrder = orders.some(order => order.order.startsWith('DS'));

    if (hasDsOrder) {
      const result = await restoreOrdersBatchForDs(db, orders, isCustomRow);
      if (!result) {
        console.log('Error restoring DS orders');
        setShowToast(true);
        setToastData({
          isError: true,
          message:
            "Error restoring DS orders",
        });
        return;
      }
      setShowToast(true);
      setToastData({
        isError: false,
        message:
          "DS orders restored successfully",
      });
      // If we get here, the orders were restored successfully
      return;
    }

    if (isCustomRow)
      await restoreCustomOrderItems(db, orders);
    else
      await addRestoreInlineOrders(db, data.batchId, "partial", orders);
  }

  // restore/restart batch
  const handleModalAction = async () => {
    let _orders = [];
    let orders = [];

    switch (mode) {

      // restore batch
      case 1:
        orders = _.map(data.items, item => item.orderId);
        _orders = _.uniq(orders);
        if (_orders.length > 0)
          restoreOrders(_orders);
        break;

      // restore selected orders
      case 2:
        orders = [];
        _.map(data.items, item => {
          if (selected.includes(item.id)) {
            orders.push({ order: item.orderId, sku: item.sku });
          }
        });
        if (orders.length > 0) {
          restoreOrderItems(orders);
        } else {
          setShowToast(true);
          setToastData({
            isError: true,
            message:
              "No orders selected!",
          });
        }
        break;

      // delete batch
      case 3:
        deleteHistory(db, data.batchId, dispatch);
        if (onDelete)
          onDelete(data.batchId);
        break;

      // confirm 'unprint'
      case 4:
        setPrintStatus(db, data.batchId, !printed, setPrinted);
        break;
    }

    setModal(false);
  }

  const handleCloseModal = () => {
    setModal(false);
  }

  const handleOpenBackToQueue = () => {
    setMode(1);
    setModalTitle('Restore');
    setModalMsg('Do you want to restore this Batch?');
    setModal(true);
    handleToggleClose();
  }

  const handleOpenSelectedOrdersBackToQueue = () => {
    setMode(2);
    setModalTitle('Restore');
    setModalMsg(`Do you want to restore the selected orders?`);
    setModal(true);
  }

  const handleOpenDelete = () => {
    setMode(3);
    setModalTitle('Delete');
    setModalMsg('Do you want to remove this Batch?');
    setModal(true);
    handleToggleClose();
  }

  const handleDownloadCSV = () => {
    console.log('Manual Items Download');
    let items = [];
    for (let i = 0; i < data.items.length; i++) {
      const item = data.items[i];
      if (data.customBatch) {
        items.push({
          sku: item.sku,
          quantity: item.qty
        });
        continue;
      }

      if (!item.inventory) {
        items.push({
          sku: item.sku,
          quantity: item.quantity
        });
        continue;
      }

      if (item.isBoxSet) {
        for (let j = 0; j < item.inventory.length; j++) {
          const inventoryItem = item.inventory[j];
          if (inventoryItem.quantity > 0) {
            items.push({
              sku: inventoryItem.sku,
              quantity: inventoryItem.quantity
            });
          }
        }
      } else {
        if (item.inventory.quantity > 0) {
          items.push({
            sku: item.sku,
            quantity: item.inventory.quantity
          });
        }
      }
    }

    let orderSKUs = _.map(items, item => item.sku);
    let _orderSKUs = _.uniq(orderSKUs);
    let orderIds = _.map(_orderSKUs, sku => {
      const matchItems = _.filter(items, _item => sku === _item.sku);
      let quantity = 0;
      _.each(matchItems, _item => {
        quantity += _item.quantity;
      });

      return { sku, quantity };
    });


    if (orderIds.length > 0) {
      createCSV(`${data.name}-${data.batchId}`, orderIds);
    }
  }

  const handleDownloadInventory = async () => {
    let items = [];

    setLoading(true);

    const promises = data.items.filter(item => item.inventory).map(async (item) => {
      if (item.isBoxSet) {
        await processBoxSetItem(db, item, items);
      } else {
        await processSingleItem(db, item, items);
      }
    });

    // Wait for all promises to resolve
    await Promise.all(promises);

    if (items.length > 0) {
      createCSV(`Pick List - ${data?.name || data.batchId}`, items);
    } else {
      console.log('No inventory items found');
      setLoading(false);
    }

    setLoading(false);
  };

  const processBoxSetItem = async (db, item, items) => {
    const bundleInfo = await getBundleBySku(db, item.sku);

    const allItemsInInventory = bundleInfo?.items?.every(bundleItem => {
      const inventoryItem = item.inventory.find(invItem => invItem.sku === bundleItem);
      return inventoryItem && inventoryItem.inventory > 0;
    });

    console.log('All Items In Inventory: ', allItemsInInventory)

    if (!allItemsInInventory) {
      // If the bundle is not complete, push individual items that are available
      return await processIndividualItemsInBundle(db, item.inventory, items);
    }

    // Push a special row for the bundle itself if all items are in inventory
    items.push({
      "Product Name": bundleInfo.name || 'N/A',
      "Product SKU": bundleInfo.sku,
      "Quantity Needed": "BUNDLE",
      "Pick Location": "BUNDLE",
    });

    // Push each item within the bundle
    return await processIndividualItemsInBundle(db, item.inventory, items, bundleInfo.name);
  };

  const processIndividualItemsInBundle = async (db, inventory, items, bundleName = null) => {
    for (const inventoryItem of inventory) {
      const itemData = await getBySku(db, inventoryItem.sku);
      if (inventoryItem.inventory > 0) {
        items.push({
          "Product Name": itemData.name || 'N/A',
          "Product SKU": inventoryItem.sku,
          "Quantity Needed": inventoryItem?._quantity || inventoryItem.quantity,
          "Pick Location": inventoryItem.location,
        });
      }
    }
  };

  const processSingleItem = async (db, item, items) => {
    if (item.isMixAndMatch) {
      return await handleMixAndMatchInventoryDownload(db, item, items);
    }

    const itemData = await getBySku(db, item.sku);

    if (item.inventory.inventory > 0) {
      items.push({
        "Product Name": itemData.name || 'N/A',
        "Product SKU": item.sku,
        "Quantity Needed": item?._quantity || item.quantity,
        "Pick Location": item.inventory.location,
      });
    }
  };

  const handleMixAndMatchInventoryDownload = async (db, item, items) => {
    try {
      const socks = [
        {
          sku: item.firstSock,
          inventory: item.inventory.firstSock
        },
        {
          sku: item.secondSock,
          inventory: item.inventory.secondSock
        }
      ];

      const sockPromises = socks.map(async (sock) => {
        const sockData = await getBySku(db, sock.sku, false, true);
        return {
          "Product Name": sockData.name || 'N/A',
          "Product SKU": sock.sku,
          "Quantity Needed": sock.inventory._quantity,
          "Pick Location": sock.inventory.location !== "" ? sock.inventory.location : "Location not found."
        };
      });

      const sockItems = await Promise.all(sockPromises);

      const spacerItem = {
        "Product Name": item.collection ? `Mix N' Match - ${item.collection}` : 'Mix N Match',
        "Product SKU": "--------------------------------------",
        "Quantity Needed": "-------------------",
        "Pick Location": "-------------------"
      };

      items.push(spacerItem, ...sockItems, spacerItem);

    } catch (error) {
      console.log('Error in handleMixAndMatchInventoryDownload: ', error);
    }
  }

  // Check if there's a nested 'items' array where orderId might reside
  const isDsOrder = data?.items?.some(item => {
    // if orderId is not a string, make it a string
    if (typeof item?.orderId !== 'string') {
      item.orderId = item.orderId.toString();
    }

    if (item?.orderId?.includes('DS')) {
      return true;
    }
    if (item.items) {
      return item.items.some(nestedItem => nestedItem.orderId && nestedItem.orderId.includes('DS'));
    }
    return false;
  });


  const renderModal = () => {
    return (
      <ConfirmDialog
        label="back"
        title={modalTitle}
        open={modal}
        onClose={handleCloseModal}
        onConfirm={handleModalAction}
      >
        <Typography variant='body2' gutterBottom>
          {modalMsg}
        </Typography>
      </ConfirmDialog>
    );
  }

  const handleToggle = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleToggleClose = () => {
    setAnchorEl(null);
  };

  const setOrders = (orders) => {
    setSelected(orders);
  }

  const callBackLog = (msg) => {
    console.log("msg => ", msg);
  }

  const handleDownload = (first) => {

    // return if there are no print sheets, this blocks a useless api call.
    if (data.numPrintSheetsTotal === 0) {
      console.log('No Print Sheets');
      // update the flag that controls the button layout
      setDownloaded(true);
      // dispatch update for item in redux
      dispatch(setDownloadStatus(data.batchId, true));
      // first time download
      handleNotifyToShipstation(true)

      // update download status 
      setHistoryDownloads(db, data.batchId, 1, callBackLog);

      return;

    }

    const currentUser = localStorage.getItem("currentuser");

    const params = { batchId: data.batchId, email: currentUser };
    fetch(API.downloadHistory, {
      method: 'post',
      body: JSON.stringify(params)
    }).then(function (response) {
      return response.json();
    }).then(function (data) {
      if (data.success) {

        // update the flag that controls the button layout
        setDownloaded(true);
        // dispatch update for item in redux
        dispatch(setDownloadStatus(data.batchId, true));

        if (!isDsOrder) {
          // first time download
          if (first) {
            handleNotifyToShipstation(true)
          }
        }

        const link = global.document.createElement("a");
        link.setAttribute("href", data.url);
        link.setAttribute("download", "Zip");
        global.document.body.appendChild(link); // Required for FF
        link.click();
        global.document.body.removeChild(link);

      } else {
        console.log("Error");
      }
    })
      .catch(function (error) {
        console.log('Request failed', error);
      });

  }

  const [status, setStatus] = useState('');

  useEffect(() => {
    if (!data.batchId) return;

    // Log the data.batchId to verify it is a string

    let newIdToString = data.batchId.toString();

    // Set up the real-time listener
    const unsubscribe = listenToShipstationBatchDownloadStatus(db, newIdToString, setStatus);
    const unsubscribePrintStatus = listenToPrintStatus(db, newIdToString, setPrinted);
    const unsubscribeDownloadStatus = listenToDownloadStatus(db, newIdToString, setDownloaded);

    // Cleanup function to unsubscribe when component unmounts or batchId changes
    return () => {
      unsubscribe();
      unsubscribePrintStatus();
      unsubscribeDownloadStatus();
    };
  }, [data.batchId]);


  const nonOrderRow = data.source === 'Order' || data.source === 'CustomOrder';

  const handleNotifyToShipstation = (firstTimeDownload = false) => {
    // prevent user from triggering multiple API calls

    if (!nonOrderRow) {
      console.log('Non Order Row');
      return
    }

    console.log('Tagging ShipStation: ', data.batchId);

    if (shipLoading === false) {
      console.log('Call Ship Sync API: ', data.batchId);
      // toggle loading status
      setShipLoading(true);

      // call internal API to update ShipStation based on this batch
      const url = `${API.shipOrder}/${data.batchId}/${firstTimeDownload}`;

      axios
        .get(url)
        .then(
          (result) => {
            console.log('API Request Success: ', result)
            const data = result.data;

            if (data.success === true) {

              let batchSuccess = true;

              data.responseArray.forEach(order => {

                // anything other than a statusCode of 200 is an error from ShipStation API
                if (order.statusCode != 200) {
                  // add flag for this order
                  addOrderError(db, data.batchId, order.orderNumber, "ShipStation Error");
                  // mark batch as unsuccessful so that it can be re-run
                  batchSuccess = false;

                }
              });

              if (batchSuccess) {
                console.log('Ship Sync Success. ', data.batchId);
                // if everything was successful, mark the ship button green
                setNotifyToShipstation(batchSuccess);
              }

              //setPrintStatus(db, row.batchId, !printed, setPrinted);
            }

            // disable loading animation
            setShipLoading(false);
          },

          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            console.log('API Request Error: ', error);
            setShipLoading(false);
          }
        )
        .catch((error) => {
          console.log('API Request Error: [ handleNotifyToShipstation ] ', error);
        }
        );

    }
  }

  const isCustomRow = data.customBatch || (!!data.customBatchId && data.customBatchId !== '');

  let historyItems = [];

  historyItems = data.items ? [...data.items] : [];

  // set actions for batches data
  useEffect(() => {
    setDownloaded(data.downloads > 0);
    setPrinted(data?.printed);
    setNotifyToShipstation(data?.notifyToShipstation);
  }, []);

  const calculateNumberOfOrders = () => {
    // calculate by orderId, do not count duplicates
    let orders = [];

    historyItems.forEach(item => {
      if (!orders.includes(item.orderId)) {
        orders.push(item.orderId);
      }
    });

    return orders.length;
  }

  const resetBatchTagging = async () => {
    await handleResetTags(db, data);
  }


  return (
    <React.Fragment>
      <>
        <TableRow
          hover
          role="checkbox"
          tabIndex={-1}
          key={label}
          classes={{
            root: data?.odd ? classes.tableRow : classes.tableRow2, // class name, e.g. `root-x`
            selected: classes.selectedTableRow, // class name, e.g. `disabled-x`
          }}
          selected={open}
        >
          <TableCell >
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
            </IconButton>
          </TableCell>
          <TableCell
            component="th"
            scope="row"
            padding="none"
            className={classes.tableCell}
            onClick={() => setOpen(!open)}>
            <Typography variant='tableText'>
              {formatFirestoreTimestamp(data.createdAt)}
            </Typography>
          </TableCell>

          <TableCell align="center" className={classes.tableCell} onClick={() => setOpen(!open)}>
            <Box display="flex">
              {data.isError && <ErrorOutlineIcon sx={{ color: '#E64A19', verticalAlign: 'middle' }} />}
              {data.isWarning && <AssignmentIcon sx={{ color: '#14B779', verticalAlign: 'middle' }} />}
            </Box>
          </TableCell>
          <TableCell onClick={() => setOpen(!open)}>
            <Typography variant='tableText'>{data.name}</Typography>
          </TableCell>
          <TableCell align="left" onClick={() => setOpen(!open)}>
            <Typography variant='tableText'>{data.customBatch ? 'Custom' : data.secondaryName ? data.secondaryName : ''}</Typography>
          </TableCell>
          <TableCell align="center" onClick={() => setOpen(!open)}>
            <Typography variant='tableText'>{data.numPrintSheetsTotal}</Typography>
          </TableCell>
          <TableCell align="center">
            <DoneIcon sx={{ color: status.downloads > 0 || data.downloads > 0 ? '#1F7CF2' : 'rgba(40,40,40,0.16)' }} />
          </TableCell>
          <TableCell align="center">
            {status.downloads > 0 || data.downloads > 0 || downloaded ? (
              <Box display="flex" justifyContent="flex-end">
                {nonOrderRow && !isDsOrder && (
                  <Button
                    variant={status.didFinish || data.notifyToShipstation || notifyToShipstation ? 'green' : 'blue'}
                    color="primary"
                    disabled={!props.editOnly}
                    onClick={() => notifyToShipstation ? null : handleNotifyToShipstation()}
                    sx={{ marginRight: '20px' }}
                  >
                    {status.isDownloading || shipLoading
                      ? <CircularProgress size={22} className={classes.buttonProgress} color="inherit" />
                      : <LocalShippingIcon />
                    }
                  </Button>
                )}

                {!isLoading
                  ? <Button
                    variant={printed ? 'green' : 'blue'}
                    color="primary"
                    disabled={!props.editOnly}
                    onClick={handlePrintButton}
                  >
                    <LocalPrintshopIcon />
                  </Button>
                  : <Button
                    variant={'grey'}
                    color="primary"
                    disabled={!props.editOnly}
                  >
                    <LocalPrintshopIcon />
                  </Button>}
              </Box>
            ) : (
              <Button
                variant="blue"
                disabled={!props.editOnly}
                // dont auto update the download status
                onClick={e => handleDownload(false)}
                sx={{ width: '150px' }}
              >
                Download
              </Button>
            )}
          </TableCell>
          <TableCell align="center" className={classes.tableCell}>
            <Button
              aria-controls="customized-menu"
              aria-haspopup="true"
              variant="black"
              disabled={!props.editOnly}
              onClick={handleToggle}
            >
              <MoreHorizIcon />
            </Button>

            <StyledMenu
              id="customized-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleToggleClose}
            >
              {data.source === 'Order' && (<MenuItem onClick={handleOpenBackToQueue}><ScheduleIcon />&nbsp;&nbsp;Back to Queue</MenuItem>)}
              <MenuItem onClick={e => handleDownload(false)}><CloudDownloadIcon />&nbsp;&nbsp;Download</MenuItem>
              <MenuItem onClick={handleDownloadCSV}><PostAddIcon />&nbsp;&nbsp;CSV</MenuItem>
              <MenuItem onClick={handleDownloadInventory}>
                {
                  loading
                    ? <CircularProgress size={22} className={classes.buttonProgress} color="inherit" />
                    : <InventoryIcon />
                }
                &nbsp;&nbsp;Inventory</MenuItem>
              <MenuItem onClick={handleOpenDelete}><DeleteOutlineIcon />&nbsp;&nbsp;Delete</MenuItem>
              {
                nonOrderRow && !isDsOrder && (
                  <MenuItem onClick={e => handleNotifyToShipstation(false)}><LocalShippingIcon />&nbsp;&nbsp;Tag in ShipStation</MenuItem>
                )
              }
            </StyledMenu>
            {renderModal()}
          </TableCell>
        </TableRow>
        <TableRow sx={{ background: '#F1F7FE' }}>
          <TableCell sx={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box display="flex" sx={{ padding: '40px 0 40px 40px' }}>
                <Box flexGrow={1}
                >
                  {
                    data.source !== 'Manual' && (
                      <Typography variant='tableText' sx={{ color: '#1F7CF9' }}>
                        ORDERS: {calculateNumberOfOrders()}
                      </Typography>
                    )
                  }

                  <HistoryItemsTable items={historyItems} classes={classes} source={data.source} setOrders={setOrders} />

                </Box>
                <Box sx={{ width: '180px' }}>
                  {data.source !== 'Manual' && (
                    <IconButton variant='red' onClick={handleOpenSelectedOrdersBackToQueue} disabled={!props.editOnly}>
                      &nbsp;&nbsp;Back to Queue
                    </IconButton>
                  )}
                </Box>
              </Box>
            </Collapse>
          </TableCell>

        </TableRow>
      </>
      <Toast
        show={showToast}
        data={toastData}
        onClose={() => setShowToast(false)}
      />
    </React.Fragment>
  );
})

export default Row;