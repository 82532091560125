import {
  collection,
  query,
  onSnapshot,
  orderBy,
  or,
  where,
  getDocs,
  getDoc,
  doc,
  updateDoc,
  limit
} from "firebase/firestore";
import { collections } from "../constants/defines";

const _LIMIT = 5000;

export const getDsOrders = (
  db,
  callback,
) => {
  let q1 = query(
    collection(db, collections.DS_INLINE_QUEUE),
    orderBy('created_at', 'asc'),
    where('isPrinting', '==', false),
    limit(4000)
  );

  let q2 = query(
    collection(db, collections.DS_CUSTOM_QUEUE),
    orderBy('created_at', 'asc'),
    where('isPrinting', '==', false),
    limit(1500)
  );

  let items = [];

  const processSnapshot = (querySnapshot, collectionName, isCustomQueue = false) => {
    let snapshotItems = [];
    querySnapshot.forEach(async (doc) => {
      let data = doc.data();
      let cntSocks = 0, cntUnderwear = 0;
      let numCustomItems = 0, numItems = 0;

      data.items.map(async (_item) => {
        if (_item.error !== "") {
          await updatePnfAndDocument(db, _item, collectionName);
        }

        if (_item.type === 'Underwear') cntUnderwear++;
        else cntSocks++;
        if (_item.isCustomProduct) {
          numCustomItems += parseInt(_item.quantity);
        }
        numItems += parseInt(_item.quantity);
      });

      let type = 'multiple';
      if (cntUnderwear === data.items.length) type = 'underwear';
      else if (cntSocks === data.items.length) type = 'socks';

      let orderType = 'OTHER';
      if (numItems === 1 && numCustomItems === 1) orderType = 'SINGLE';
      else if (numItems === numCustomItems) orderType = 'MULTIPLE';

      const updatedData = {
        ...data,
        orderType,
        numItems,
        numCustomItems,
        type,
        // Add the isFromCustomsQueue flag if this is from the custom queue
        ...(isCustomQueue && { isFromCustomsQueue: true })
      };

      snapshotItems.push(updatedData);
    });
    return snapshotItems;
  };

  // Set up real-time listeners for both collections
  const handleSnapshots = () => {
    let inlineItems = [];
    let customItems = [];

    // Listener for the inline queue
    onSnapshot(q1, (querySnapshot1) => {
      inlineItems = processSnapshot(querySnapshot1, collections.DS_INLINE_QUEUE);

      // Combine results from both queries
      items = [...inlineItems, ...customItems];
      // Sort in descending order by 'created_at'
      items = items.sort((a, b) => b.created_at - a.created_at);
      // resort to place ones that have restoreDate first
      items = items.sort((a, b) => a.hasBeenRestored ? -1 : b.hasBeenRestored ? 1 : 0);

      callback(items, [...querySnapshot1.docs]);
      console.log('items', items.length);
    });

    // Listener for the custom queue
    onSnapshot(q2, (querySnapshot2) => {
      customItems = processSnapshot(querySnapshot2, collections.DS_CUSTOM_QUEUE, true); // Pass true to indicate this is from the custom queue

      // Combine results from both queries
      items = [...inlineItems, ...customItems];
      // Sort in descending order by 'created_at'
      items = items.sort((a, b) => b.created_at - a.created_at);
      // resort to place ones that have restoreDate first
      items = items.sort((a, b) => a.hasBeenRestored ? -1 : b.hasBeenRestored ? 1 : 0);

      callback(items, [...querySnapshot2.docs]);
      console.log('items', items.length);
    });
  };

  handleSnapshots();
};


export const returnErrorsInDsOrder = (db, callback) => {
  const q = query(
    collection(db, collections.DS_ERRORS),
  );

  onSnapshot(q, (querySnapshot) => {
    let items = [];
    querySnapshot.forEach((doc) => {
      let data = doc.data();
      items.push(data);
    });

    callback(items);
  });
}
const findInCollection = async (db, collectionName, item) => {
  const q = query(
    collection(db, collectionName),
    or(
      where('variants', 'array-contains', item.sku),
      where('sku', '==', item.sku)
    )
  );

  const querySnapshot = await getDocs(q);

  // Return the first item found
  if (!querySnapshot.empty) {
    const doc = querySnapshot.docs[0]; // Get the first document
    return { id: doc.id, ...doc.data() };
  }

  return null; // Return null if nothing is found
};

const updateItemAndDocument = async (db, item, foundItem, dsQueue) => {
  item.error = '';
  item.style = foundItem?.style || '';
  item.type = foundItem?.type || '';
  item.size = foundItem?.size || '';
  item.format = foundItem?.format || '';
  item.exists = true;
  await updateItemInDsOrders(db, item, dsQueue);
};

const updatePnfAndDocument = async (db, item, dsQueue) => {
  // First, check in bundles collection
  const bundle = await findInCollection(db, collections.BOXSETS, item);
  if (bundle) {
    await updateItemAndDocument(db, item, bundle, dsQueue);
    return; // Return early if bundle is found
  }

  // If not found in bundles, check in products collection
  const product = await findInCollection(db, collections.PRODUCTS, item);
  if (product) {
    await updateItemAndDocument(db, item, product, dsQueue);
  }

  return null; // Return null if nothing is found in either collection
};


const updateItemInDsOrders = async (db, item, dsQueue) => {
  const docRef = doc(collection(db, dsQueue), item.reference);

  // Fetch the document
  const docSnapshot = await getDoc(docRef);
  if (docSnapshot.exists()) {
    const data = docSnapshot.data();

    // Find the specific item in the 'items' array
    const items = data.items || [];
    const itemIndex = items.findIndex((i) => i.id === item.id);

    if (itemIndex !== -1) {
      // Update the specific item in the array
      items[itemIndex] = item
      // updating item sku : 
      console.log('updating item sku : ', item.sku);
      // Update the document with the modified 'items' array
      await updateDoc(docRef, { items });
    } else {
      console.error(`Item with id ${item.id} not found`);
    }
  } else {
    console.error('Document not found');
  }
};


export const getRunningLables = (db, callback) => {
  const q = query(
    collection(db, collections.DS_JOBS),
    orderBy('batchDate', 'desc'),
  );

  onSnapshot(q, (querySnapshot) => {
    let items = [];
    querySnapshot.forEach((doc) => {
      let data = doc.data();
      items.push(data);
    });

    callback(items);
  });
}