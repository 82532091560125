import { collections } from '../constants/defines';
import _ from "lodash";
import {
  collection,
  query,
  onSnapshot,
  getDoc,
  deleteDoc,
} from "firebase/firestore";

const getTotals = (db, callback) => {
  const q = query(collection(db, collections.COUNTS));

  onSnapshot(q, (querySnapshot) => {
    querySnapshot.forEach((doc) => {
      let data = doc.data();
      callback(data);
    });
  });
}

export { getTotals };