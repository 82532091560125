import _ from "lodash";
import { excludedStyles } from "../constants/defines";

export const unitsToProduceToday = (ordersIn, dispatch, formats, db) => {
  const orders = _.cloneDeep(ordersIn);

  const result = { products: [] };
  const invalidResult = { products: [] };
  const errorResult = new Set();

  orders.forEach(order => {
    order.items.forEach(item => {
      processItem(item, formats, result, invalidResult, errorResult, db);
    });
  });

  const convertedErrorResult = Array.from(errorResult);

  // console.log('VALID: ', JSON.stringify(result, null, 2));

  return { valid: result, error: errorResult, arrayOfErrorSkus: convertedErrorResult};
};

// Helper functions
const findOrCreateProductType = (type, targetResult) => {
  let product = targetResult.products.find(p => p.type === type);
  if (!product) {
    product = { type, types: { shopify: { styles: [] } } };
    targetResult.products.push(product);
  }
  return product;
};

const findOrCreateStyle = (styles, styleName) => {
  let style = styles.find(s => s.style === styleName);
  if (!style) {
    style = { style: styleName, sizes: {}, totalUnitProduced: 0 };
    styles.push(style);
  }
  return style;
};

const addQuantityToStyle = (style, size, quantity) => {
  if (!style.sizes[size]) {
    style.sizes[size] = 0;
  }
  style.sizes[size] += quantity;
  style.totalUnitProduced += quantity;
};

const processItem = async (item, formats, result, invalidResult, errorResult, db) => {
  try {
    if (item.error !== "") {
      if (errorResult.has(item.sku)) return;

      errorResult.add(item.sku); // Add only SKU to the set
      return;
    }

    // Skip items with excluded styles
    if (excludedStyles.includes(item.style)) {
      return;
    }

    // Extract product details
    let productType = item.type ? item.type.toLowerCase() : 'unknown';

    if (productType === 'underwear') productType = 'boxer';

    let productFormat = _.find(formats, { id: item.format })?.name || 'Unknown';

    const productSizeMatch = item.sku.match(/-([A-Z0-9]+)$/);

    const productSize = productSizeMatch ? productSizeMatch[1] : 'unknown';

    const itemSource = item.source ? item.source.toLowerCase() : 'unknown';

    const isValid = productType !== 'unknown' 
      && productFormat !== 'Unknown' 
      && productSize !== 'unknown' 
      && itemSource !== 'unknown';

    const targetResult = isValid ? result : invalidResult;

    // Find or create product type and style
    const product = findOrCreateProductType(productType, targetResult);

    if (!product.types[itemSource]) {
      product.types[itemSource] = { styles: [] };
    }

    const styleList = product.types[itemSource].styles;

    const style = findOrCreateStyle(styleList, productFormat);

    let quantityOfItems = item.quantity ? item.quantity : item.qty ? item.qty : 0;

    if (item.isBoxSet) {
      quantityOfItems = item?.items?.length ?? 0;

      if (quantityOfItems === 0) {
        // find the number in the -XPCK pattern, where X is the number
        const match = item.sku.match(/-(\d+)PCK/);

        if (match) {
          const quantity = parseInt(match[1]);
          if (quantity > 0) {
            quantityOfItems = quantity;
          }
        }
      }
    }

    // Add quantity to the appropriate size
    addQuantityToStyle(style, productSize, quantityOfItems);

    return targetResult;

  } catch (error) {
    console.log('Error processing item:', item, 'Error:', error.message);
  }
};
