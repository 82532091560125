import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Grid';
import { withAuthorization } from '../Session';
import { updateInventory } from '../../db/inventory';
import { getProductBySku } from '../../db/products';
import { BootstrapInput } from '../Page/input';
import { StorageImage } from '../Page/art';

import { PRODUCT_ART_PREFIX, PRODUCT_THUMB_PREFIX } from '../../constants/defines';

const useHeaderStyles = makeStyles((theme) => ({
    imageBox: {
        width: '100%',
        border: '1px solid #ccc',
        background: '#eee'
    }
}));


function InventoryModal(props) {
    const { open, item, onClose, onRemove } = props;
    const [data, setData] = useState(null);
    const [frontPath, setFrontPath] = useState("");
    const [backPath, setBackPath] = useState("");
    const classes = useHeaderStyles();
    const db = props.firebase.getdb();

    useEffect(() => {
        if (open) {
            const sku = item.sku;
            setData({ ...item });

            getProductBySku(db, sku, getProduct);
        } else {
            setData(null);
            setFrontPath("");
            setBackPath("");
        }

    }, [item, open]);

    const getProduct = (product) => {
        console.log("Get Product: ", product);
        if (product.sku === "") {
            // dont waste a query
            return;
        }

        if (product.style === "HyperOptic") {
            //`${prefix}thumb_${path}`;
            const prefix = `${PRODUCT_THUMB_PREFIX}/`
            const path = `thumb_${product.pathHyperOpticIdentical}`;
            setFrontPath(`${prefix}${path}`);
            setBackPath(`${prefix}${path}`);
            return
        }

        setFrontPath(`${PRODUCT_THUMB_PREFIX}/${product.pathFront}`);
        setBackPath(`${PRODUCT_THUMB_PREFIX}/${product.pathBack}`);
    }

    const handleChange = (event) => {
        let { name, value } = event.target;
        setData({ ...data, [name]: value });
    }

    const handleSubmit = () => {
        if (data.location === '')
            return;
        updateInventory(db, data);
        if (onRemove && data.quantity == 0)
            onRemove();
        onClose();
    }

    return (
        <Dialog
            onClose={onClose}
            aria-labelledby={item.name}
            open={open}
            fullWidth={true}
            maxWidth="md">
            <DialogTitle id="print-dialog-title" onClose={onClose} sx={{ padding: '30px 40px 10px' }}>
                <Typography variant='h2'>{item.sku}</Typography>
                <IconButton aria-label="close" variant='close' onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <Box sx={{ padding: '20px 14px' }}>
                    <InputLabel>Inventory QTY</InputLabel>
                    <BootstrapInput type="number" name="quantity" value={data ? data.quantity : 0} onChange={handleChange} />
                </Box>

                <Box sx={{ padding: '20px 14px' }}>
                    <InputLabel>Location</InputLabel>
                    <BootstrapInput type="location" name="location" value={data ? data.location : ""} onChange={handleChange} />
                </Box>

                <Box sx={{ padding: '20px 14px' }}>
                    <Grid container spacing={3}>
                        <Grid item xs={4}>
                            <Box className={classes.imageBox}>
                                <StorageImage key={`${item.sku}-Front`} firebase={props.firebase.getFirebase()} path={frontPath} />
                            </Box>
                        </Grid>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={4}>
                            <Box className={classes.imageBox}>
                                <StorageImage key={`${item.sku}-Back`} firebase={props.firebase.getFirebase()} path={backPath} />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>

            </DialogContent>
            <DialogActions sx={{ justifyContent: 'flex-start', padding: '20px 40px' }}>
                <Button
                    onClick={handleSubmit}
                    variant="blue"
                    sx={{ width: '120px' }}
                >Submit</Button>
            </DialogActions>
        </Dialog>
    );
}

const condition = authUser => !!authUser;
export default withAuthorization(condition)(InventoryModal);
