import React from "react";
import { Box, Tooltip, Typography } from "@mui/material";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import { COMMON_COLORS, palette } from "../../theme/colors";
import PrinterSVG from "./Svgs/Printer";
import ExMarkSVG from "./Svgs/Mark";
import ShipDollySVG from "./Svgs/Dolly";

const TopSingleCards = ({ keyValue, title, data, icon, isMobile }) => {
  const isOldestOrder = icon.toLowerCase() === "box";

  const commonProps = {
    width: isMobile ? 18 : 35,
    height: isMobile ? 18 : 35,
    viewBox: isMobile ? "0 0 40 40" : "0 0 44 44",
  };

  const renderThisIconBasedOfKey = () => {
    switch (icon.toLowerCase()) {
      case "printer":
        return <PrinterSVG {...commonProps} />;
      case "truck":
      case "boat":
      case "mailbox":
        return <ShipDollySVG {...commonProps} />;
      default:
        return (
          <IndeterminateCheckBoxIcon sx={{ color: "#fff", fontSize: 28 }} />
        );
    }
  };

  const hasExceeded = () => {
    if(title ==="Average Order to Print" && data.time.days > 2){
      return true;
    }

    if(title ==="Average Print to Ship" && data.time.days > 1 ){
      return true;
    }

    if(title ==="Average Order to Ship" && data.time.days > 7 ){
      return true;
    }
  }

  return (
    <Tooltip
      title={
        isOldestOrder ? (
          <div>
            <div>Buyers email: {data.extraData.email}</div>
          </div>
        ) : (
          ""
        )
      }
      arrow
      disableHoverListener={!isOldestOrder}
    >
      <Box
        sx={{
          ...styles.card,
          borderRadius: isMobile ? 4 : 2,
          padding: isMobile ? 1.25 : 1,
        }}
        key={keyValue}
      >
        <Box
          sx={{
            ...styles.iconAndTopRightDateContainer,
            padding: isMobile ? 0 : 1,
          }}
        >
          <Typography
            sx={{
              ...styles.text,
              fontSize: { xs: 10, sm: 16, md: 16 },
            }}
          >
            {title}
          </Typography>
          <Box sx={styles.iconBox}>{renderThisIconBasedOfKey(data.icon)}</Box>
        </Box>

        <hr
          style={{
            width: isMobile ? "100%" : "90%",
            border: "1px solid #EAEAEA",
          }}
        />

        <Box sx={styles.containerForTimes}>
          <Box
            sx={{
              ...styles.timeBox,
              padding: isMobile ? 1 : 2,
              width: isMobile ? "100%" : "67.5%",
              backgroundColor: hasExceeded()
                ? COMMON_COLORS.babyPink
                : COMMON_COLORS.greyTextFields,
            }}
          >
            {hasExceeded() && (
              <Box sx={styles.markContainer}>
                <ExMarkSVG />
              </Box>
            )}

            {data.time && (
              <Typography sx={styles.text}>
                <Typography component="span" sx={styles.mainDateText}>
                  {data.time.days}D
                </Typography>
                <Typography component="span" sx={styles.mainDateText}>
                  {` ${data.time.hours}hr`}
                </Typography>
                <Typography component="span" sx={styles.mainDateText}>
                  {` ${data.time.minutes}min`}
                </Typography>
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    </Tooltip>
  );
};

const styles = {
  card: {
    display: "flex",
    flexDirection: "column",
    boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
    backgroundColor: "#fff",
  },
  markContainer: {
    paddingRight: 1,
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
  },
  containerForTimes: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  timeBox: {
    backgroundColor: COMMON_COLORS.greyTextFields,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 2,
  },
  daysLeftText: {
    fontSize: { xs: 12, sm: 14, lg: 12 }, // Example responsive font size
    fontWeight: 300,
  },
  iconAndTopRightDateContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
  },
  topRightDate: {
    fontSize: { xs: 10, sm: 12, md: 14 }, // Responsive font size
    fontWeight: 400,
    paddingBottom: 4,
  },
  text: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textAlign: "center",
    fontWeight: 500,
  },
  mainDateText: {
    // color: palette.analyticsColors.warningRed,
    fontSize: { xs: 16, sm: 16, md: 16 }, // Responsive font size
    fontWeight: 500,
  },
  mainOrderNumberText: {
    fontSize: { xs: 14, sm: 14, md: 16, lg: 16 }, // Responsive font size
    fontWeight: 500,
  },
  subMainDateText: {
    // color: palette.analyticsColors.informativeRedLight,
    fontSize: { xs: 10, sm: 12, md: 11 }, // Responsive font size
    fontWeight: 400,
  },
  separator: {
    // color: palette.analyticsColors.warningRed,
    fontSize: { xs: 16, sm: 16, md: 16 }, // Responsive font size
    fontWeight: 500,
  },
  iconBox: {
    display: "flex",
    padding: 1,
    borderRadius: 2,
    backgroundColor: COMMON_COLORS.babyBlue,
    borderRadius: 10,
    justifyContent: "center",
    alignItems: "center",
  },
  mainCountOfMakeups: {
    fontSize: { xs: 16, sm: 16, md: 16 }, // Responsive font size
    fontWeight: 550,
  },
};

export default TopSingleCards;
