import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';


const useHeaderStyles = makeStyles((theme) => ({
  search: {
    width: '400px',
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)',
    },
    marginRight: 0,
    marginLeft: 0,
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    right: '0px'
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: '10px 16px!important',
    paddingRight: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
  },
  headerAction: {
    width: '820px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  csvButton: {
    marginLeft: '15px!important',
    background: '#3D3D3D!important',
    borderRadius: '6px',
    width: '160px!important',
    height: '40px!important',
    fontSize: '16px!important',
    fontWeight: '500!important',
    color: '#FFFFFF',
    textTransform: 'initial',
    '&:hover': {
      background: 'rgba(61,61,61, 0.8)',
    },
  },
}));


const PageHeader = (props) => {
  const classes = useHeaderStyles();
  const { initial, onSearch, onUpload, onUploadArt, onExport, exporting } = props;
  const viewOnly = props.viewOnly;
  const editOnly = props.editOnly;

  const handleUpload = () => {
    onUpload();
  }

  const handleUploadArt = () => {
    onUploadArt();
  }

  const handleExport = () => {
    onExport();
  }

  return (
    <div className="pageHeader">
      <div className="pageHeading">
        <Typography variant="h1">Products</Typography>
        <Typography variant='subtitle1'>Total Products: <strong>{initial}</strong></Typography>
      </div>

      <div className={classes.headerAction}>
        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            placeholder="Search Products"
            sx={{ width: '100%' }}
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            inputProps={{ 'aria-label': 'search' }}

            onKeyUp={onSearch}
          />
        </div>

        <Button variant="black" component="span" sx={{ marginRight: '10px', marginLeft: '10px', width: '170px' }} disabled={!editOnly} onClick={handleUploadArt} >
          Upload Art
        </Button>

        <Button variant="black" component="span" sx={{ marginRight: '10px', width: '175px' }} disabled={!editOnly} onClick={handleUpload} >
          Upload CSV
        </Button>

        <Button variant="black" component="span" sx={{ width: '170px' }} disabled={exporting || !editOnly} onClick={handleExport} >
          {exporting ? (<CircularProgress size="25px" />) : "Export CSV"}
        </Button>
      </div>

    </div>
  );
}

PageHeader.propTypes = {
  initial: PropTypes.number.isRequired
};

export default PageHeader;