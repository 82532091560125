import React, { useState, useEffect, createRef } from 'react';
import { makeStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Dropzone from 'react-dropzone';
import Papa from 'papaparse';
import size from 'lodash.size';
import { collections, PRODUCT_ART_PREFIX, storageLink} from '../../constants/defines';
import { withAuthorization } from '../Session';
import { getProductVariants } from '../../db/products';


const BATCH_LIMIT = 500;

const useHeaderStyles = makeStyles((theme) => ({
    modalTitle: {
        fontSize: '32px',
        lineHeight: '45px',
        fontWeight: '500',
        color: '#000000'
    },
  closeButton: {
    position: 'absolute',
    right: '0px',
    top: '10px',
    color: '#000000',
  },
  iconButton: {
    position: 'absolute',
    left: '-30px',
    padding: '0'
  },
  inputRoot: {
    width: '100%',
    fontSize: '14px',
    fontWeight: '500',
    letterSpacing: '1.5px',
    color: '#000000',
    background: 'rgba(0,0,0,0.04)',
    padding: '11px 15px',
    borderRadius: '4px'
  },
  inputLabel: {
    fontSize: '16px',
    fontWeight: '500',
    color: '#000000'
  },
  inputInlineLabel: {
    fontSize: '16px',
    fontWeight: '500',
    color: '#000000',
    marginTop: '15px',
    marginRight: '25px'
  },
  addButton: {
    background: '#3D3D3D',
    padding: '8px 32px',
    marginRight: '15px',
    borderRadius: '6px',
    fontSize: '16px',
    fontWeight: '500',
    color: '#FFFFFF',
    textTransform: 'initial'
  },
  saveButton: {
    background: '#1F7CF2',
    padding: '8px 56px',
    borderRadius: '6px',
    fontSize: '16px',
    fontWeight: '500',
    color: '#FFFFFF',
    textTransform: 'initial'
  },
  duplicateButton: {
    background: 'rgb(61, 61, 61)',
    padding: '8px 56px',
    borderRadius: '6px',
    fontSize: '16px',
    fontWeight: '500',
    color: '#FFFFFF',
    textTransform: 'initial',
    marginRight: '20px'
  },
  deleteButton: {
    background: 'rgb(230, 74, 25)',
    padding: '8px 56px',
    borderRadius: '6px',
    fontSize: '16px',
    fontWeight: '500',
    color: '#FFFFFF',
    textTransform: 'initial'
  },
  link: {
    fontSize: '16px',
    fontWeight: '500',
    color: '#000000',        
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none'
    }    
  },
  tagList: {
    display: 'flex',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    marginTop: '16px',
    '& li': {
      marginBottom: '10px'
    }
  },
  chip: {
    marginRight: '16px',
    borderRadius: '6px',
    background: '#3d3d3d',
    height: '40px',
    padding: '0 15px 0 25px'
  },
  chipLabel: {
    fontSize: '16px',
    fontWeight: '500',
    color: '#FFFFFF'
  },
  chipButton: {
    color: '#ffffff',
    opacity: '0.4',
    marginLeft: '15px',
    padding: '0',
    '&:hover': {
      opacity: '1',
    }
  },
  image: {
    width: '100%',
    height: 'auto'
  },
  dropBox: {    
    textAlign: 'center',
    padding: '88px 0px',
    borderRadius: '6px',
    backgroundColor: 'rgba(0, 0, 0, 0.04)',    
    '& svg' : {
      opacity: '0.56'
    }
  },
  dropLabel: {
    fontSize: '16px',
    fontWeight: '500',
    color: '#000000',
    opacity: '0.56',
    margin: '0'
  },
  dropButton: {
    fontSize: '16px',
    fontWeight: '500',
    color: '#FFFFFF',
    width: '160px',
    background: '#3D3D3D',
    borderRadius: '6px',
    textTransform: 'Capitalize',
    height: '40px',
    padding: '0',
    marginTop: '20px'
  },
  subTitle: {
    fontSize: '24px',
    fontWeight: '500',
    color: '#000000',
    marginBottom: '10px'
  },
  boldText: {
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: '0.94',
    color: '#000000',
    marginBottom: '10px'
  },
  text: {
    fontSize: '16px',
    lineHeight: '0.94',
    color: '#000000',
    marginBottom: '10px'
  },
  errorText: {
    fontSize: '16px',
    lineHeight: '0.94',
    color: '#FF0000',
    marginBottom: '10px'
  },
  errorList: {
    paddingLeft: '0px',
    listStyle: 'none'
  },
  errorRow: {
    marginBottom: '10px'
  },
  errorItem: {

  },
  progress: {
    height: '6px',
    borderRadius: '3px',
    backgroundColor: 'rgba(20, 183, 121, 0.16 )'
  }
}));

const dropzoneUploadRef = createRef();
let products = [], frontImageKey, backImageKey, startingIndex = 0;
let errors = {};

function UploadModal(props) {
  const {onClose, open, formats, templates, sizes} = props;

  const [csvFile, setCsvFile] = useState(null);
  const [csvError, setCsvError] = useState('');  
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState('');
  const [uploadComplete, setUploadComplete] = useState(false);

  const classes = useHeaderStyles();
  const db = props.firebase.getdb();    

  useEffect(() => {

  }, []);

  useEffect(() => {
    if (loading) {
        console.log('Loading data...');
        addProductsToDatabase();
    }
  }, [loading]);


  const addProductsToDatabase = () => {
    setMsg('Checking Images...');
    const storageRef = props.firebase.getFirebase().storage().ref();
    let images = [];
    products.forEach(function (product) {
      if (!!product[frontImageKey])
        images.push(storageRef.child(`${PRODUCT_ART_PREFIX}${product[frontImageKey]}`).getDownloadURL());
      if (!!product[backImageKey])
        images.push(storageRef.child(`${PRODUCT_ART_PREFIX}${product[backImageKey]}`).getDownloadURL());
    });

    // Override catch so it doesn't stop the Promise.all
    images = images.map(function (p) {
      return p.catch(function (error) {
        return undefined;
      });
    });

    Promise.all(images)
      .then(function (responses) {
        // Scrape resonses for errors
        responses.forEach(function (response, index) {
          if (response === undefined) {
            const productIndex = Math.floor(index / 2);
            const sideKey = index / 2 === productIndex ? frontImageKey : backImageKey;
            const sideLabel = sideKey === frontImageKey ? 'front' : 'back';
            if (!errors.hasOwnProperty(products[productIndex].sku)) {
              errors[products[productIndex].sku] = {};
            }
            errors[products[productIndex].sku][sideLabel] = products[productIndex][sideKey];
          }
        });

        /**
         * Filter out products with image errors
         */
        products = products.filter(function (product) {
          return !errors.hasOwnProperty(product.sku);
        });

         checkVariants();
      });
  }

  const checkVariants = () => {
    if (products.length === 0) {
      return writeProductsToDatabase();
    }

    setMsg('Checking Variants...');
    let reqs = [], orders = [];
    _.each(products, (product, index) => {
      const _variants = _.trim(product.variants);
      if (_variants !== "") {
        _.each(_variants.split(","), (variant) => {
          const _v = _.trim(variant);
          reqs.push(getProductVariants(db, _v));        
          orders.push(index);
        });
      }  
    });

    if (reqs.length === 0) {
      return writeProductsToDatabase();
    }

    Promise.all(reqs)
      .then(function (responses) {
        // Scrape resonses for errors
        responses.forEach(function (rsp, index) {
          if (rsp.skus.length>0) {
            const productIndex = orders[index];
            if (!errors.hasOwnProperty(products[productIndex].sku)) {
              errors[products[productIndex].sku] = {};
              errors[products[productIndex].sku]["variants"] = [];
            }
            errors[products[productIndex].sku]["variants"].push(rsp.variant);
          }
        });

        /**
         * Filter out products with image errors
         */
        products = products.filter(function (product) {
          return !errors.hasOwnProperty(product.sku);
        });

        /**
         * Write products to database
         */
        writeProductsToDatabase();
      });
  }

  const writeProductsToDatabase = () => {    
    setMsg('Adding products...');  
    const endingIndex = Math.min(products.length, (startingIndex + BATCH_LIMIT));
    const items = products.slice(startingIndex, endingIndex);
    writeBatchToDatabase(items);
  }

  const writeBatchToDatabase = (items) => {
    let batch = db.batch();
    
    items.forEach(function (item) {
      let _templates = [], variants = [];
      var hasTemplateError = false;
      let isCustomProduct = false;  
      if (!!item.templates && !!_.trim(item.templates)) {  
        let __templates = _.trim(item.templates);      
        _templates = _.map(__templates.split(","), (template) => {
          let templateId = "";     
          let templateName = _.trim(template);

          let tItem = _.find(templates, {name: templateName});
          if (!tItem) {
            hasTemplateError = true;
          } else {
            templateId = tItem.id;
            isCustomProduct = tItem.isCustom;
          }            
          return {template: templateId};
        });        
      }      

      if (!!item.variants && !!_.trim(item.variants)) {   
        let _variants = _.trim(item.variants);    
        variants = _.map(_variants.split(","), (variant) => {
          return _.trim(variant);
        });        
      }  

      const itemSize = _.find(sizes, {name: item.size});
      const itemFormat = _.find(formats, {name: item.format});
      const isValidType = item.type;
      const isValidStyle = item.style;

      // Make sure size and format are valid before setting
      if (!!itemSize 
        && !!itemFormat 
        && !hasTemplateError 
        && isValidType
        && isValidStyle) {
        const ref = db.collection(collections.PRODUCTS).doc(item.sku);
        batch.set(ref, {
          createdBy: localStorage.getItem("currentuser"),
          identical: true,
          dimension: false,
          sku: _.trim(item.sku),
          name: _.trim(item.name),
          variants: variants,
          type: _.trim(item.type),
          style: _.trim(item.style),
          size: itemSize.id,
          format: itemFormat.id,
          pathFront: !!item[frontImageKey]?item[frontImageKey]:"",
          pathBack: !!item[backImageKey]?item[backImageKey]:"",
          templates: _templates,
          isCustomProduct,
          color: _.trim(item.color),
          pdfs: [],
          isBundle: false,
          tags: ""
        });
      } else {
        // Record error
        if (!errors.hasOwnProperty(item.sku)) {
          errors[item.sku] = {};
        }

        if (!itemSize) {
            errors[item.sku].size = item.size;
        }

        if (!itemFormat) {
            errors[item.sku].format = item.format;
        }

        if (!isValidType) {
          errors[item.sku].type = item.type;
        }

        if (!isValidStyle) {
          errors[item.sku].style = item.style;
        }

        if (hasTemplateError) {
          errors[item.sku].templates = item.templates;
        }
      }
    });

    // Commit batch
    batch.commit().then(function () {
      if (startingIndex + items.length >= products.length) {
        onBulkUploadComplete();
      } else {
        startingIndex += BATCH_LIMIT;
        writeProductsToDatabase();
      }
    }).catch(function (error) {
      console.error(`Error comitting batch: ${error.message}`);
    });
  }

  const onBulkUploadComplete = () => {
    setLoading(false);
    setUploadComplete(true);
  }

  const handleSubmit = () => {
    Papa.parse(csvFile, {
        header: true,
        dynamicTyping: true,
        complete: onParseCSV,
    });       
  }

  const handleCloseDialog = (event, reason) => {
    if (reason !== 'backdropClick') {
      setUploadComplete(false);
      setCsvFile(null);    
      setLoading(false);
      products = [];
      startingIndex = 0;
      errors = {};
      onClose();
    } 
  }

  const handleDrop = async (file) => {
    Papa.parse(file, {
        header: true,
        dynamicTyping: true,
        complete: onCheckValidCSV,
    });
  }

  const onCheckValidCSV = (results, csvFile) => {
    console.log('onCheckValidCSV');
    const fields = results.meta.fields;    
    if (CSVhasCorrectFields(fields)) {
        setCsvFile(csvFile);
        setCsvError('');
    } else {
        setCsvError('Your CSV file did not have the correct Fields. Please correct the file and try again.');
        setCsvFile(null);
    }
  }
  
  const onParseCSV = (results, csvFile) => {
    // Check to make sure CSV if formatted correctly
    const fields = results.meta.fields;
    // Remove empty lines
    products = results.data.filter(function (row) {
        let validImage = !!row[frontImageKey] && !!row[backImageKey];

        if (row.format === "Mask")
            validImage = !!row[frontImageKey] || !!row[backImageKey];
        return row.sku !== null && row.sku !== ""
            && row.name !== null && row.name !== ""
            && row.type !== null && row.type !== ""
            && row.style !== null && row.style !== ""
            && row.format !== null && row.format !== ""
            && row.size !== null && row.size !== "";          
    });

    setLoading(true);   
  }

  const handleClickUploadCSV = () => {
    console.log('handleClickUploadCSV');
    dropzoneUploadRef.current.open();
  }

  
  const CSVhasCorrectFields = (fields) =>  {
    // Check and record front image key (he uses front_image sometimes)
    if (fields.includes('pathFront')) {
      frontImageKey = 'pathFront';
    } else if (fields.includes('front_image')) {
      frontImageKey = 'front_image';
    } else {
      return false;
    }

    // Check and record back image key (he uses back_image sometimes)
    if (fields.includes('pathFront')) {
      backImageKey = 'pathBack';
    } else if (fields.includes('front_image')) {
      backImageKey = 'back_image';
    } else {
      return false;
    }

    // Check for other keys
    return fields.includes('sku') 
      && fields.includes('variants')
      && fields.includes('name')
      && fields.includes('type')
      && fields.includes('style')
      && fields.includes('format')
      && fields.includes('size') 
      && fields.includes('templates');
  }
  
  const renderUploadControl = () => {
      return (
        <Grid container spacing={3}>
            <Grid item xs={4}>
                <Dropzone 
                    ref={dropzoneUploadRef} 
                    onDrop={(files) => handleDrop(files[0])} 
                    multiple={false}>
                    {({getRootProps, getInputProps}) => (
                    <Box {...getRootProps()} className={classes.dropBox}>
                        <input {...getInputProps()} accept=".csv"/>
                        <CloudUploadIcon />
                        <p className={classes.dropLabel}>Drag & Drop<br/> CSV File</p>
                    </Box>
                    )}
                </Dropzone>
            </Grid>
            <Grid item xs={8}>
                <Box sx={{padding: '65px 0 0 100px'}}>
                    <Typography className={classes.subTitle}>Before You Being</Typography>
                    <Typography className={classes.text}>1. Upload all images to <a href={storageLink} target="_blank">Firebase Storage</a></Typography>
                    <Typography className={classes.text}>2. Make sure your csv file <a href="https://rock-em-test.web.app/sample.csv" target="_blank">looks like this</a></Typography>
                    <Button 
                        variant="blue" 
                        onClick={handleClickUploadCSV} 
                        sx={{width:'250px', marginTop:'20px'}}>Upload CSV</Button>
                </Box>
            </Grid>
        </Grid>
      );
  }

  let formBody;
  if (size(errors)) {
    const errorRows = [];
    let count = 0;
    for (let sku in errors) {
      let errorItems = [];
      for (let errorType in errors[sku]) {
        switch (errorType) {
          case "front":
            errorItems.push(<li key={count} className={classes.errorItem}>Front image "{errors[sku][errorType]}" was not found in storage.</li>);
            break;
          case "back":
            errorItems.push(<li key={count} className={classes.errorItem}>Back image "{errors[sku][errorType]}" was not found in storage.</li>);
            break;
          case "size":
            errorItems.push(<li key={count} className={classes.errorItem}>Size "{errors[sku][errorType]}" is invalid.</li>);
            break;
          case "format":
            errorItems.push(<li key={count} className={classes.errorItem}>Format "{errors[sku][errorType]}" is invalid.</li>);
            break;
          case "templates":
            errorItems.push(<li key={count} className={classes.errorItem}>Templates "{errors[sku][errorType]}" is invalid.</li>);
            break;
          case "type":
            errorItems.push(<li key={count} className={classes.errorItem}>Type "{errors[sku][errorType]}" is invalid.</li>);
            break;
          case "style":
            errorItems.push(<li key={count} className={classes.errorItem}>Style "{errors[sku][errorType]}" is invalid.</li>);
            break;
          case "variants":
            errorItems.push(<li key={count} className={classes.errorItem}>Varaints: "{errors[sku][errorType].join(',')}" is invalid.</li>);
          default:
        }
        count++;
      }
      errorRows.push(<li key={count} className={classes.errorRow}>
        <strong className={classes.errorText}>{sku}</strong>
        <ul className={classes.errorList}>{errorItems}</ul>
      </li>);
      count++;
    }
    formBody = (
        <div>            
            <div className={classes.text}>The following products had issues uploading:</div>          
            <ul className={classes.errorList}>{errorRows}</ul>
        </div>
      );
  }
  return (
    <Dialog 
        onClose={handleCloseDialog} 
        aria-labelledby="upload-dialog"
        open={open}
        fullWidth={true}
        maxWidth="md">
        <DialogTitle id="print-dialog-title" onClose={handleCloseDialog} sx={{padding: '10px 40px', marginTop:'20px'}}>
            <Typography variant='h2'>Bulk Upload Products</Typography>   
            <IconButton aria-label="close" variant='close' onClick={handleCloseDialog}>
                <CloseIcon />
            </IconButton>
        </DialogTitle>
        <DialogContent dividers>
            <Box sx={{padding: '20px 14px', minHeight: '270px'}}>
                {uploadComplete == false && csvFile !==  null && (
                    <Box sx={{background: 'rgba(0, 0, 0, 0.04)', padding: '10px 10px 1px'}}>
                        <Typography className={classes.text}>{csvFile.name}</Typography>
                    </Box>
                )}
                {csvError !== '' && (<Typography className={classes.errorText}>{csvError}</Typography>)}

                {loading && (
                    <Box sx={{marginTop: '20px'}}>
                        <Typography className={classes.text}>{msg}</Typography>
                        <Box sx={{marginTop: '30px', textAlign: 'center'}}>
                            <LinearProgress className={classes.progress}/>
                        </Box>                        
                    </Box>
                )}

                {csvFile === null && renderUploadControl()}

                {uploadComplete && (
                    <div>
                        <div className={classes.boldText}>Upload Complete</div>
                        {size(errors) > 0? formBody: <div className={classes.text}>Successful</div>}                        
                    </div>
                )}

            </Box>
        </DialogContent>

        <DialogActions  sx={{padding: '20px 40px', justifyContent: 'space-between'}}>
          {uploadComplete? 
          (<Button variant="black" 
                color="primary" 
                sx={{width:'200px'}} 
                onClick={handleCloseDialog}>Close</Button>):
          (<Button 
                variant="blue" 
                disabled={csvFile===null || csvError!==''}
                color="primary" 
                sx={{width:'200px'}} 
                onClick={handleSubmit}>Submit</Button>)}
        </DialogActions>
    </Dialog>
  );
}


const condition = authUser => !!authUser;
export default withAuthorization(condition)(UploadModal);
