import { API } from "../constants/defines";

export const generateHyperOpticThumbnail = async (product, isIdentical = true) => {
  try {
    if (!product) {
      console.log("Error in generateThumbnail: product is missing");
      return;
    }

    if (!isIdentical) {
      const paths = [
        {
          sku: product.sku,
          artPath: product.pathLeft
        },
        {
          sku: product.sku,
          artPath: product.pathRight
        },
      ];

      for (let i = 0; i < paths.length; i++) {
        console.log("Uploading number: ", i + 1, " out of ", paths.length);
        await uploader(paths[i]);
      }

      return;
    }

    // For identical path
    await uploader({
      sku: product.sku,
      artPath: product.hyperOpticIdentical,
    });

  } catch (error) {
    console.log("Error in generateThumbnail: ", error);
  }
};

const uploader = async ({ sku, artPath }) => {
  try {
    const response = await fetch(API.createSingleThumbnail, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        sku,
        artPath,
      }),
    });

    const data = await response.json();

    if (data.error) {
      console.log("Error in generateThumbnail: ", data.error);
      return;
    }

    return data;
  } catch (error) {
    console.log("Error in generateThumbnail: ", error);
    throw error;
  }
};
