import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import Typography from '@mui/material/Typography';

const useHeaderStyles = makeStyles((theme) => ({
    search: {
        width: '470px',
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.08)',
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
      },
      searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        right: '0px'
      },
      inputRoot: {
        color: 'inherit',
      },
      inputInput: {
        padding: '10px 16px!important',
        paddingRight: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
      },
  }));

const PageHeader = (props) => {
    const classes = useHeaderStyles();
    const {onSearch} = props;

    return (
      <div className="pageHeader">
        <div className="pageHeading">
          <Typography variant="h1">Print History</Typography>             
        </div>
        
        <div className={classes.search}>
          
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>

          <InputBase
            placeholder="Search Print History"
            sx={{ width: '100%' }}
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}    
            value={props.textSearch}
            inputProps={{ 'aria-label': 'search' }}  
            onKeyUp={onSearch}
          />
        
        </div>
      </div>
    );
}

export default PageHeader;