import {
  WHOLESALE_ORDERS,
  START_FETCHING_WHOLESALE_ORDERS,
  FETCH_WHOLESALE_ORDERS_SUCCESS,
  FETCH_WHOLESALE_ORDERS_FAILURE,
  UPDATE_WHOLESALE_ORDER,
  UPDATE_WHOLESALE_ORDER_SUCCESS,
  UPDATE_WHOLESALE_ORDER_FAILURE,
  DELETE_WHOLESALE_ORDER,
  WHOLESALE_ORDERS_PRODUCTION_QUEUE,
  SWITCH_WHOLESALE_ORDER_TO_PRODUCTION,
  UPDATE_TABLE_ORDER,
  SWITCH_PRODUCTION_ORDER_TO_WHOLESALE_REG,
  UPDATE_ITEM_STATUS_IN_ORDER,
  DELETE_MULTIPLE_ORDERS,
  UPDATE_CHANGED_ORDERS,
  OPEN_ROW,
  CLOSE_ROW,
  SELECTING_ID_IN_ORDER
} from "./types"



export const openRow = (rowId) => ({
  type: OPEN_ROW,
  payload: rowId,
});

export const closeRow = (rowId) => ({
  type: CLOSE_ROW,
  payload: rowId,
});


export const injectWholeSaleOrdersQueue = (wholeSaleOrders) => {
  return {
    type: WHOLESALE_ORDERS,
    payload: wholeSaleOrders
  }
}

export const injectWholeSaleOrdersProductionQueue = (wholeSaleOrders) => {
  return {
    type: WHOLESALE_ORDERS_PRODUCTION_QUEUE,
    payload: wholeSaleOrders
  }

}

export const startFetchingWholeSaleOrders = () => {
  return {
    type: START_FETCHING_WHOLESALE_ORDERS,
    payload: true
  }
}

export const fetchWholeSaleOrdersSuccess = () => {
  return {
    type: FETCH_WHOLESALE_ORDERS_SUCCESS,
    payload: true
  }
}

export const fetchWholeSaleOrdersFailure = (error) => {
  return {
    type: FETCH_WHOLESALE_ORDERS_FAILURE,
    payload: error
  }
}

export const updateWholeSaleOrder = (orderId, updates, isProduction = false ) => {
  return {
    type: UPDATE_WHOLESALE_ORDER,
    payload: { orderId, updates, isProduction },
  };
};


export const updateWholeSaleOrderSuccess = () => {
  return {
    type: UPDATE_WHOLESALE_ORDER_SUCCESS,
    payload: true
  }
}

export const updateWholeSaleOrderFailure = (error) => {
  return {
    type: UPDATE_WHOLESALE_ORDER_FAILURE,
    payload: error
  }
}

// removes from array in state
export const deleteWholeSaleOrder = (orderId, isProduction) => {
  return {
    type: DELETE_WHOLESALE_ORDER,
    payload: { orderId, isProduction }
  }
}

export const switchWholeSaleOrderToProduction = (orderId, updates) => {
  return {
    type: SWITCH_WHOLESALE_ORDER_TO_PRODUCTION,
    payload: { orderId, updates } 
  }
}

export const switchProductionOrderToWholeSaleReg = (orderId, updates) => {
  console.log('orderId: ', orderId, 'updates: ', updates)
  return {
    type: SWITCH_PRODUCTION_ORDER_TO_WHOLESALE_REG,
    payload: { orderId, updates } 
  }

}

export const updateTableOrderState = (order) => {
  return {
    type: UPDATE_TABLE_ORDER,
    payload: order
  }
}

export const updateItemsInOrder = (orderId, updates, isProduction = false) => {

  return (dispatch) => {
    dispatch(updateWholeSaleOrder(orderId, updates, isProduction));
  }
  
}

export const updateItemStatusInOrder = (orderId, itemId, isProduction, reprint = false) => ({
  type: UPDATE_ITEM_STATUS_IN_ORDER,
  payload: { orderId, itemId , isProduction, reprint }
});

export const deleteMultipleOrders = (orderIds, isProduction) => {
  return {
    type: DELETE_MULTIPLE_ORDERS,
    payload: { orderIds, isProduction }
  }
}

export const updateChangedOrders = (order, isProduction) => {
  return {
    type: UPDATE_CHANGED_ORDERS,
    payload: { order, isProduction }
  }
}

export const selectingIdInOrder = (orderId, rowId) => {
  return {
    type: SELECTING_ID_IN_ORDER,
    payload: {
      orderId,
      rowId
    }
  }
}