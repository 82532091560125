import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import Pagination from '@mui/material/Pagination';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import _ from "lodash";
import { debounce } from 'lodash';
import PageHeader from './pageHeader';
import Row, { RowWrapper } from './row';
import FilterDropdown from '../Page/filterDropdown';
import Layout from '../Page';
import { withAuthorization } from '../Session';
import { EnhancedTableHead } from '../Page/table';

import { useSelector, useDispatch } from 'react-redux';
import { filterHistoryByDropdowns, resetHistory, setNewPageData, updateDocsWhenChangedShow, updatePageNumber, useSearch } from '../../redux/history/actions';
import { Button } from '@mui/material';


const filterShows = [
  { id: 0, name: '25', value: 25 },
  { id: 1, name: '50', value: 50 },
  { id: 2, name: '100', value: 100 },
  { id: 3, name: '150', value: 150 },
];

const headCells = [
  { id: 'name', numeric: false, align: 'left', disablePadding: true, label: 'DATE', width: '170px' },
  { id: 'error', numeric: false, align: 'center', disablePadding: true, label: '', width: '30px' },
  { id: 'batch', numeric: false, align: 'left', disablePadding: false, label: 'BATCH NAME', width: '400px' },
  { id: 'type', numeric: false, align: 'left', disablePadding: false, label: 'BATCH INFO', width: '220px' },
  { id: 'files', numeric: false, align: 'center', disablePadding: false, label: 'FILES', width: '120px' },
  { id: 'downloaded', numeric: false, align: 'center', disablePadding: false, label: 'DOWNLOADED', width: 'auto' },
  { id: 'download', numeric: false, align: 'center', disablePadding: false, label: '', width: '150px' },
  { id: 'action', numeric: false, align: 'center', disablePadding: false, label: '', width: '80px' },
];

const filterSources = [
  { id: 1, name: 'All', value: '' },
  { id: 2, name: 'Shopify', value: 'Order' },
  { id: 3, name: 'Shopify Custom', value: 'CustomOrder' },
  { id: 4, name: 'WS Queue', value: 'Wholesale' },
  { id: 5, name: 'Manual Entry', value: 'Manual' },

];


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  filterBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  table: {
    minWidth: 750,
  },
  itemTable: {
    width: '1200px'
  },
  tableHead: {
    background: 'rgba(217, 223, 233, 0.4)'
  },
  tableHeadCell: {
    lineHeight: '27.5px'
  },
  tableHeadLabel: {
    fontSize: '14px',
    fontWeight: '500',
    color: '#000000',
    letterSpacing: '1.5px'
  },
  tableCell: {
    fontSize: '14px',
    fontWeight: '500!important',
    letterSpacing: '1.5px',
    color: '#000000'
  },
  itemTableCell: {
    fontSize: '14px',
    fontWeight: '500!important',
    letterSpacing: '1.5px',
    color: '#000000',
    borderBottom: 'none!important'
  },
  itemTableCell2: {
    fontSize: '14px',
    fontWeight: '500!important',
    letterSpacing: '1.5px',
    color: '#E0491B!important',
    borderBottom: 'none!important'
  },
  txtLocation: {
    fontSize: '12px',
    fontWeight: '500!important',
    letterSpacing: '1.5px',
    color: '#14B779!important',
  },
  itemTableCell3: {
    fontSize: '14px',
    fontWeight: '500!important',
    letterSpacing: '1.5px',
    color: '#14B779!important',
    borderBottom: 'none!important'
  },
  tableRow: {
    height: '70px',
    backgroundColor: 'rgba(242, 244, 246, 0.4)',
    '&:hover': {
      backgroundColor: '#F1F7FE!important',
    }
  },
  tableRow2: {
    height: '70px',
    backgroundColor: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#F1F7FE!important',
    }
  },
  selectedTableRow: {
    backgroundColor: '#F1F7FE!important',
  },
  progressPrimaryError: {
    backgroundColor: '#14B779'
  },
  progressPrimaryNormal: {
    backgroundColor: 'rgba(31, 124, 242, 0.16)'
  },
  progressBarError: {
    backgroundColor: '#14B779'
  },
  progressBarNormal: {
    backgroundColor: '#1F7CF2'
  },
  buttonProgress: {
    color: '#FFF'
  },
  jobActionButton: {
    width: '100%',
    color: '#FFFFFF',
    textTransform: 'initial',
    fontSize: '16px',
    fontWeight: '500!important',
    borderRadius: '6px',
    "&.Mui-disabled": {
      background: "rgba(0, 0, 0, 0.12)!important",
      color: "rgba(0, 0, 0, 0.26)"
    }
  },
  jobActionButton1: {
    color: '#FFFFFF',
    borderRadius: '6px',
    padding: '8px 16px',
    '&:hover': {
      background: '#1F7CF2',
    },
    "&.Mui-disabled": {
      background: "rgba(0, 0, 0, 0.12)!important",
      color: "rgba(0, 0, 0, 0.26)"
    }
  },
  jobActionButton2: {
    color: '#FFFFFF',
    borderRadius: '6px',
    background: '#1F7CF2',
    marginLeft: '15px',
    padding: '8px 16px',
    '&:hover': {
      background: '#1F7CF2',
    },
    "&.Mui-disabled": {
      background: "rgba(0, 0, 0, 0.12)!important",
      color: "rgba(0, 0, 0, 0.26)"
    }
  },
  jobActionButton3: {
    color: '#FFFFFF',
    borderRadius: '6px',
    background: '#1F7CF2',
    marginRight: '15px!important',
    padding: '8px 16px',
    '&:hover': {
      background: '#1F7CF2',
    },
    "&.Mui-disabled": {
      background: "rgba(0, 0, 0, 0.12)!important",
      color: "rgba(0, 0, 0, 0.26)"
    }
  },
  jobActionButton4: {
    background: '#3d3d3d',
    minWidth: '55px',
    "&.Mui-disabled": {
      background: "rgba(0, 0, 0, 0.12)!important",
      color: "rgba(0, 0, 0, 0.26)"
    }
  },
  buttonCSV: {
    background: '#FFFFFF!important',
    color: '#1F7CF2!important',
    fontSize: '16px!important',
    fontWeight: '600!important',
    width: '100%!important',
    borderRadius: '6px!important'
  },
  buttonQueue: {
    background: '#e0491b!important',
    color: '#FFFFFF!important',
    fontSize: '16px!important',
    fontWeight: '600!important',
    width: '100%!important',
    borderRadius: '6px!important',
    marginTop: '20px!important',
    '&:hover': {
      background: '#e0491b!important',
    }
  },
  buttonInventory: {
    color: '#14B779!important',
    fontSize: '16px!important',
    fontWeight: '600!important',
    '&:hover': {
      background: 'transparent!important',
    }
  },
  itemNavButton: {
    fontSize: '14px',
    fontWeight: '600',
    letterSpacing: '1.5px',
    color: '#000000',
    borderRadius: '0',
    width: '100%'
  },
  pagination: {
    marginTop: '60px',
    '& ul': {
      justifyContent: 'center'
    },
  },
}));



function History(props) {

  const dispatch = useDispatch();

  const { historyDocs, isLoading, pageData } = useSelector(state => state.history);

  const classes = useStyles();
  const [filterShow, setFilterShow] = useState(0);
  const [pageNumber, setPageNumber] = useState(pageData?.pageNumber || 1);
  const [textSearch, setTextSearch] = useState("");
  const [query, setQuery] = useState("");
  const [filterSource, setFilterSource] = useState(1);

  const handlResetData = () => {
    dispatch(resetHistory());
  }

  const db = props.firebase.getdb();

  const storage = props.firebase.getstorage();

  const handleChangeSource = (id) => {
    const selectedObject = _.find(filterSources, item => item.id === id);
    dispatch(filterHistoryByDropdowns(selectedObject));
  }

  const handleChangeShow = (id) => {
    // use this to bind the drop-down
    setFilterShow(id);

    // need to get the value to update Algolia
    const selectedObject = _.find(filterShows, item => item.id == id);

    dispatch(updateDocsWhenChangedShow(selectedObject));

  };

  const handleSearch = (event) => {
    // Check if Enter key is pressed or input is cleared
    // ! ADD reset for page to be 1
    if (event.key === 'Enter') {
      console.log('Enter key pressed')
      // Process the search instantly
      setTextSearch(event.target.value);
      dispatch(useSearch(event.target.value))
    }
  };

  const handleChangePage = (event, newPage) => {
    setPageNumber(newPage);

    dispatch(updatePageNumber({
      ...pageData,
      pageNumber: newPage
    }));

    // load new set of data: 
    dispatch(setNewPageData())

    // scroll to top with space for the header
    window.scrollTo(0, 200)
  };


  const handleDelete = (batchId) => {
    // dispatch(deleteHistoryDoc(batchId));
    console.log('Delete: ' + batchId);
  };


  const jobsToShow = historyDocs.slice(0, pageData.rowsPerPage).map(job => ({ ...job }));

  return (
    <Layout page={props.page} user={props.user}>


      <><PageHeader onSearch={handleSearch} /><div className='pageToolbar'>
        <div className={classes.filterBox}>
          <div sx={{ marginRight: '30px' }}><FilterDropdown title="Source" items={filterSources} active={filterSource} onSelect={
            (id) => {
              setFilterSource(id);
              handleChangeSource(id);
            }
          } /></div>
          <div><FilterDropdown title="Show" items={filterShows} active={filterShow} onSelect={handleChangeShow} /></div>
        </div>
        <div className='pageToolbarRight'>
          <Button
            variant="outlined"
            color="primary"
            onClick={handlResetData}
            sx={{ marginRight: '20px', marginLeft: '20px' }}
          >Reset</Button>
        </div>
      </div><TableContainer sx={{ marginTop: '30px', marginBottom: '50px' }}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              useCheckbox={true}
              cells={headCells} />
            <TableBody>
              {jobsToShow
                .map((row, index) => {
                  const odd = index % 2 === 0;
                  const labelId = `history-table-checkbox-${index}`;
                  const keyId = `key-${row.id}-${index}`;
                  row.odd = odd;
                  row.id = row.batchId;
                  return (
                    <RowWrapper
                      key={keyId}
                      label={labelId}
                      viewOnly={props.viewOnly}
                      editOnly={props.editOnly}
                      row={row}
                      classes={classes}
                      db={db}
                      onDelete={handleDelete}
                      query={query}
                      storage={storage}
                      showCount={pageData?.rowsPerPage ?? 25}
                      formats={props.formats}
                      sizes={props.sizes}
                    />
                  );
                })}

              {!isLoading && historyDocs.length == 0 && (
                <TableRow>
                  <TableCell align="center" colSpan={9}>
                    <Typography>There are no results.</Typography>
                  </TableCell>
                </TableRow>
              )}

              {isLoading && (
                <TableRow>
                  <TableCell align="center" colSpan={9}>
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              )}

            </TableBody>
          </Table>
        </TableContainer><div className={classes.pagination}>
          <Pagination count={pageData?.pages ?? 1} page={pageNumber} onChange={handleChangePage} showFirstButton showLastButton />
        </div></>

    </Layout>
  );

}

const condition = authUser => !!authUser;

export default withAuthorization(condition)(History);