

export const handleCopyToBoard = (message, thingToCopy, setToastMessage, setShowToast) => {
  navigator.clipboard.writeText(thingToCopy)
    .then(() => {
      setToastMessage(message);
      setShowToast(true);
      
      // Hide the toast after 3 seconds
      setTimeout(() => setShowToast(false), 3500);
    })
    .catch(err => console.error('Failed to copy: ', err));
};