import React from 'react';

// Key renaming map for better clarity
const keyDisplayNames = {
  products: 'Total Products',
  bundles: 'Bundles',
  socks: 'Socks',
  underwear: 'Underwear',
  orders: 'Total Orders',
  finished: 'Finished Goods',
  notFound: 'Product(s) Not Found',
};

const StickyInfo = ({ selectedInfo }) => {
  if (!selectedInfo) {
    return null; // Don't render if there's no data
  }

  const filteredInfo = { ...selectedInfo };

  delete filteredInfo.total; // Remove total if it shouldn't be displayed
  delete filteredInfo.bundles

  return (
    <div style={stickyStyle}>
      <h3 style={titleStyle}>Overview</h3>
      <div style={infoGridStyle}>
        {Object.entries(filteredInfo).map(([key, value]) => (
          <div key={key} style={infoItemStyle}>
            <span style={keyStyle}>{keyDisplayNames[key] || key}:</span>
            <span style={valueStyle}>{value}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

// Styles for the sticky component
const stickyStyle = {
  position: 'sticky',
  bottom: '0',
  zIndex: 1000,
  backgroundColor: '#ffffff',
  borderTop: '2px solid #ccc',
  padding: '5px 20px',
  width: '98.25%',
  boxShadow: '0px -2px 6px rgba(0, 0, 0, 0.1)',
  fontFamily: 'Arial, sans-serif',
  marginTop: '25px',
};

const titleStyle = {
  marginBottom: '10px',
  fontSize: '18px',
  fontWeight: 'bold',
  color: '#333',
};

const infoGridStyle = {
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(150px, 1fr))',
  gap: '15px',
};

const infoItemStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '10px',
  backgroundColor: '#f5f5f5',
  borderRadius: '8px',
  boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
  transition: 'transform 0.2s ease-in-out',
};

const keyStyle = {
  fontSize: '18px',
  color: '#555',
};

const valueStyle = {
  fontSize: '16px',
  fontWeight: 'bold',
  color: '#222',
  marginTop: '5px',
};

export default StickyInfo;
