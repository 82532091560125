import { searchForFinishedOrder } from "../module/typesense";
import { resetStateForFetchRanDoc, updateStateForFetchRanDoc } from "../redux/history/actions";

export function fetchUntilFoundFinishedOrder(batchId, callback, attempt = 0, delay = 100, dispatch) {
  const maxRetries = 20;

  // Function to actually fetch the order
  const fetchOrder = () => {
    return new Promise((resolve, reject) => {
      searchForFinishedOrder(batchId, (order) => {
        if (order) {
          resolve(order);
        } else {
          reject(new Error('Order not found'));
        }
      });
    });
  };

  const attemptFetch = () => {
    fetchOrder().then(order => {
      console.log('Order fetched successfully: ', order?.name ?? 'No name found');
      callback(order);
    }).catch(error => {
      if (attempt < maxRetries - 1) {
        console.log(`Retrying... (Attempt ${attempt + 2} of ${maxRetries})`);

        setTimeout(() => fetchUntilFoundFinishedOrder(batchId, callback, attempt + 1, 3000, dispatch), delay);

      } else {
        console.error('Max retries reached. Giving up.');

        setTimeout(() => {
          dispatch(resetStateForFetchRanDoc())
        }, 3000);


      }
    });
  };

  attemptFetch();
}
