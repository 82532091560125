import * as React from "react"
const CalendarSVG = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={25}
    fill="none"
    {...props}
  >
    <path
      fill="#113B8F"
      d="M6.94 2.5c.416 0 .753.324.753.724v1.46c.668-.012 1.417-.012 2.26-.012h4.015c.842 0 1.591 0 2.259.013v-1.46c0-.4.337-.725.753-.725s.753.324.753.724V4.75c1.445.111 2.394.384 3.09 1.055.698.67.982 1.582 1.097 2.972L22 9.5H2v-.724c.116-1.39.4-2.302 1.097-2.972.697-.67 1.645-.944 3.09-1.055V3.224c0-.4.337-.724.753-.724Z"
    />
    <path
      fill="#113B8F"
      d="M22 14.5v-2c0-.839-.004-2.335-.017-3H2.01c-.013.665-.01 2.161-.01 3v2c0 3.771 0 5.657 1.172 6.828C4.343 22.5 6.228 22.5 10 22.5h4c3.77 0 5.656 0 6.828-1.172C22 20.158 22 18.272 22 14.5Z"
      opacity={0.5}
    />
    <path
      fill="#113B8F"
      d="M18 17.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm0-4a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm-5 4a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm0-4a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm-5 4a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm0-4a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z"
    />
  </svg>
)
export default CalendarSVG