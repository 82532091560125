import * as React from "react"
const ShipDollySVG = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={35}
    height={35}
    viewBox="0 0 44 44"  // Add viewBox attribute
    fill="none"
    {...props}
  >
    <path
      fill="#3477F5"
      d="M4.375 9.62a1.375 1.375 0 0 1 1.694-.958l3.122.866a4.963 4.963 0 0 1 3.476 3.443l3.944 14.274.29 1.003a5.426 5.426 0 0 1 2.79 2.323l.568-.176 16.262-4.226a1.374 1.374 0 1 1 .693 2.662l-16.201 4.21-.606.186c-.01 2.328-1.618 4.459-4.051 5.09-2.915.758-5.913-.92-6.694-3.748-.78-2.829.95-5.736 3.865-6.494a6.15 6.15 0 0 1 .434-.095l-3.945-14.278a2.213 2.213 0 0 0-1.558-1.523l-3.124-.868a1.374 1.374 0 0 1-.959-1.692Z"
    />
    <path
      fill="#3477F5"
      d="m17.735 16.005.944 3.415c.89 3.218 1.333 4.828 2.64 5.56 1.307.732 2.966.3 6.285-.562l3.52-.917c3.318-.861 4.977-1.292 5.732-2.559.756-1.269.312-2.878-.579-6.096l-.942-3.413c-.89-3.22-1.335-4.83-2.64-5.56-1.31-.734-2.968-.302-6.287.562l-3.52.913c-3.318.862-4.977 1.294-5.73 2.563-.756 1.267-.312 2.876.577 6.094Z"
      opacity={0.5}
    />
  </svg>
)
export default ShipDollySVG
