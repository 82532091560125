import { PRODUCTS_FETCHED } from "./types";




const initialState = {
  products: [],
  isLoading: true,
  pageData: {},
};



const defectReducer = (state = initialState, action) => {

  switch (action.type) {
    case PRODUCTS_FETCHED:
      return {
        ...state,
        products: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }

}

export default defectReducer;