import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import Layout from '../Page';
import { withAuthorization } from '../Session';
import * as ROUTES from '../../constants/routes';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import { Divider } from '@mui/material';
import {getUser, updateUser} from '../../db/user';
import _  from "lodash";

const userStyles = makeStyles((theme) => ({
    pageHeader: {
        marginBottom: '30px'
    },
    pageMain: {
        marginTop: '40px',
        background: 'rgba(242, 244, 246, 0.3)',
        padding: '20px 40px'
    },
    pageSubMain: {
        marginTop: '40px',
        marginBottom: '40px'
    },
    paneLabel: {
        fontFamily: 'Roboto',
        fontSize: '16px',
        fontWeight: 'bold',
        marginBottom: '30px'
    },
    paneBox: {
        padding: '20px 20px',
        background: '#ffffff'
    },
    boxLabel: {
        fontSize: '18px',
        fontWeight: '500',
        marginBottom: '10px'
    },
    boxText: {

    },
    saveButton: {
        fontSize: '16px',
        fontWeight: '500',
        color: '#FFFFFF',
        width: '100%',
        background: '#1F7CF2',
        borderRadius: '6px',
        textTransform: 'Capitalize',
        height: '50px',
        padding: '0',
        marginTop: '40px'
    },
    buttonBox: {
        width: '200px',
        marginLeft: 'auto',
        marginTop: '10px',
        textAlign: 'center'
    },
    updateText: {
        marginTop: '10px',
        lineHeight: 1.5,
        letterSpacing: '0.5px',
        color: '#30936d'
    },
    pageSelect: {
        padding: '20px 0',
        textAlign: 'right'
    }
}));

const PermissionBox = (props) => {
    const {page, title, descriptioin, classes, onChange, hideEdit} = props;
    const [view, setView] = useState(props.view);
    const [edit, setEdit] = useState(props.edit);

    useEffect(() => {
        setView(props.view);
    }, [props.view]);

    useEffect(() => {
        setEdit(props.edit);
    }, [props.edit]);

    const handleChange = (event) => {
        let { name, value, checked, type} = event.target;
        value = type === "checkbox" ? checked : value;
        if (onChange)
            onChange(page, name, value);
    }

    return (
        <Box className={classes.paneBox}>
            <Grid container spacing={3}>
                <Grid item xs={8}>
                    <Typography variant='subtitle1'>{title}</Typography>
                    <Typography variant='body1'>{descriptioin}</Typography>
                </Grid>
                <Grid item xs={2}>
                    <FormControlLabel
                        control={
                        <Checkbox
                            color="primary"
                            name="view"
                            checked={view}
                            onChange={handleChange}
                        />
                        }
                        label="View"
                    />
                </Grid>
                <Grid item xs={2}>
                    {!hideEdit && (
                    <FormControlLabel
                        control={
                        <Checkbox
                            color="primary"
                            name="edit"
                            checked={edit}
                            onChange={handleChange}
                        />
                        }
                        label="Edit"
                    />
                    )}
                </Grid>
            </Grid>
        </Box>
    )
}

const allViews = ['custom', 'inline', 'wholesale', 'manual', 'running', 'history', 'products', 'bundles', 'product', 'bundle', 'formats', 'templates', 'uploads', 'tags', 'timestamp', 'inventory', 'barcodes', 'users' ];
const allEdits  = ['custom', 'inline', 'wholesale', 'manual', 'running', 'history', 'products', 'bundles', 'product', 'bundle', 'formats', 'templates', 'tags', 'inventory', 'barcodes', 'users' ];

const Permission = (props) =>  {
    const {match} = props;
    const [user, setUser] = useState(null);
    const [data, setData] = useState({view:[], edit:[]});
    const [isUpdated, setIsUpdated] = useState(false);
    const [selectAll, setSelectAll] = useState(false);

    const userId = match.params.userId;
    const classes = userStyles();
    const db = props.firebase.getdb();

    useEffect(() => {
        getUser(db, userId)
            .then(data => {
                setUser(data);
                setData({view:[...data.view], edit:[...data.edit]});
            })
    }, []);

    useEffect(() => {
        if (data.view.length === allViews.length && data.edit.length === allEdits.length)
            setSelectAll(true);
        else 
            setSelectAll(false);
    }, [data]);

    const handleClickBack = () => {
        props.history.push(ROUTES.USERS);
    }

    const handleChangePermission = (name, type, value) => {
        console.log(`Name: ${name}, Type: ${type}, Value:${value}`);
        let items = [];
        if (type === 'view') {
            items = [...data.view];
        } else {
            items = [...data.edit];
        }

        let newItems = [];
        if (items.includes(name)) {
            _.each(items, item => {
                if (item !== name)
                    newItems.push(item);
            })
        } else {
            newItems = [...items];
            newItems.push(name);
        }        

        if (type === 'view') {
            setData({...data, view:[...newItems]});
        } else {
            setData({...data, edit:[...newItems]});
        }
    }

    const handleSave = async () => {
        await updateUser(db, userId, {...data, view:[...data.view], edit:[...data.edit]});
        setIsUpdated(true);
        setTimeout(() => {
            setIsUpdated(false);
        }, 5000);
    }

    const handleSelectAll = (event) => {
        if (event.target.checked) {
            setData({...data, view:[...allViews], edit:[...allEdits]});
        } else {
            setData({...data, view:[], edit:[]});
        }        
    }

    return (
        <Layout page={props.page} user={props.user}>
            <Box className={classes.pageHeader}>
                <IconButton aria-label="expand row" size="small" onClick={handleClickBack}>
                    <KeyboardArrowLeftIcon /> BACK
                </IconButton>

                {user && (
                    <Typography>{user.email}</Typography>
                )}

                <Box className={classes.buttonBox}>
                    <Button 
                        variant="blue" 
                        onClick={handleSave} 
                        sx={{width:'160px'}}>Update</Button>
                    {isUpdated && (
                        <Typography className={classes.updateText}>Permission Updated</Typography>
                    )}
                </Box>
            </Box>

            <Divider />

            <Box className={classes.pageSelect}>
                <FormControlLabel
                    control={
                    <Checkbox
                        color="primary"
                        checked={selectAll}
                        onChange={handleSelectAll}
                    />
                    }
                    label="Select All Permissions"
                />                
                
            </Box>

            <Box className={classes.pageMain}>
                <Box className={classes.pageSubMain}>
                    <Typography variant='h3'>Print</Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <PermissionBox
                                page={'custom'}
                                title={'Custom Order Queue'}
                                descriptioin={'Access to the custom order queue and ability to print custom orders.'}
                                classes={classes}
                                view={data.view.includes('custom')}
                                edit={data.edit.includes('custom')}
                                onChange={handleChangePermission}
                            ></PermissionBox>
                        </Grid>
                        <Grid item xs={6}>
                            <PermissionBox
                                page={'inline'}
                                title={'Inline Order Queue'}
                                descriptioin={'Access to the inline order queue and ability to print custom orders.'}
                                classes={classes}
                                view={data.view.includes('inline')}
                                edit={data.edit.includes('inline')}
                                onChange={handleChangePermission}
                            ></PermissionBox>
                        </Grid>
                        <Grid item xs={6}>
                            <PermissionBox
                                page={'wholesale'}
                                title={'Wholesale'}
                                descriptioin={'Access to the wholesale orders queue and ability to print.'}
                                classes={classes}
                                view={data.view.includes('wholesale')}
                                edit={data.edit.includes('wholesale')}
                                onChange={handleChangePermission}
                            ></PermissionBox>
                        </Grid>
                        <Grid item xs={6}>
                            <PermissionBox
                                page={'manual'}
                                title={'Manual'}
                                descriptioin={'Access to the manual entry section and ability to print orders manually.'}
                                classes={classes}
                                view={data.view.includes('manual')}
                                edit={data.edit.includes('manual')}
                                onChange={handleChangePermission}
                            ></PermissionBox>
                        </Grid>                        
                        <Grid item xs={6}>
                            <PermissionBox
                                page={'running'}
                                title={'Running'}
                                descriptioin={'Ability to view orders currently running and download recently printed batches. '}
                                classes={classes}
                                view={data.view.includes('running')}
                                edit={data.edit.includes('running')}
                                onChange={handleChangePermission}
                            ></PermissionBox>
                        </Grid>
                        <Grid item xs={6}>
                            <PermissionBox
                                page={'history'}
                                title={'History'}
                                descriptioin={'Access to the print history page.'}
                                classes={classes}
                                view={data.view.includes('history')}
                                edit={data.edit.includes('history')}
                                onChange={handleChangePermission}
                            ></PermissionBox>
                        </Grid>
                    </Grid>
                </Box>
                <Box className={classes.pageSubMain}>
                    <Typography variant='h3'>Products</Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <PermissionBox
                                page={'products'}
                                title={'Products Landing'}
                                descriptioin={'Access to view and filter products. Edit includes ability to add, delete, edit products.'}
                                classes={classes}
                                view={data.view.includes('products')}
                                edit={data.edit.includes('products')}
                                onChange={handleChangePermission}
                            ></PermissionBox>
                        </Grid>
                        <Grid item xs={6}>
                            <PermissionBox
                                page={'bundles'}
                                title={'Bundles Landing'}
                                descriptioin={'Access to the product  bundles landing page. Edit includes the ability to add/remove. '}
                                classes={classes}
                                view={data.view.includes('bundles')}
                                edit={data.edit.includes('bundles')}
                                onChange={handleChangePermission}
                            ></PermissionBox>
                        </Grid>
                        <Grid item xs={6}>
                            <PermissionBox
                                page={'product'}
                                title={'Product Profile'}
                                descriptioin={'Access to specific products'}
                                classes={classes}
                                view={data.view.includes('product')}
                                edit={data.edit.includes('product')}
                                onChange={handleChangePermission}
                            ></PermissionBox>
                        </Grid>
                        <Grid item xs={6}>
                            <PermissionBox
                                page={'bundle'}
                                title={'Bundle Profile'}
                                descriptioin={'Access to specific bundle products'}
                                classes={classes}
                                view={data.view.includes('bundle')}
                                edit={data.edit.includes('bundle')}
                                onChange={handleChangePermission}
                            ></PermissionBox>
                        </Grid>
                        <Grid item xs={6}>
                            <PermissionBox
                                page={'formats'}
                                title={'Formats and Sizes'}
                                descriptioin={'View product crop settings, formats, and sizes. Edit allows ability to add, delete, and edit. '}
                                classes={classes}
                                view={data.view.includes('formats')}
                                edit={data.edit.includes('formats')}
                                onChange={handleChangePermission}
                            ></PermissionBox>
                        </Grid>
                        <Grid item xs={6}>
                            <PermissionBox
                                page={'templates'}
                                title={'Custom Templates'}
                                descriptioin={'Access to custom templates.'}
                                classes={classes}
                                view={data.view.includes('templates')}
                                edit={data.edit.includes('templates')}
                                onChange={handleChangePermission}
                            ></PermissionBox>
                        </Grid>
                        <Grid item xs={6}>
                            <PermissionBox
                                page={'uploads'}
                                title={'Uploads'}
                                descriptioin={'Access to product upload queue. '}
                                classes={classes}
                                view={data.view.includes('uploads')}
                                edit={data.edit.includes('uploads')}
                                onChange={handleChangePermission}
                                hideEdit={true}
                            ></PermissionBox>
                        </Grid>
                        <Grid item xs={6}>
                            <PermissionBox
                                page={'tags'}
                                title={'Tags'}
                                descriptioin={'Access to the tags landing page.'}
                                classes={classes}
                                view={data.view.includes('tags')}
                                edit={data.edit.includes('tags')}
                                onChange={handleChangePermission}
                            ></PermissionBox>
                        </Grid>
                    </Grid>
                </Box>

                <Box className={classes.pageSubMain}>
                    <Typography variant='h3'>Other</Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <PermissionBox
                                page={'timestamp'}
                                title={'Order Time Stamps'}
                                descriptioin={'Ability to view order history/timestamps.'}
                                classes={classes}
                                view={data.view.includes('timestamp')}
                                edit={data.edit.includes('timestamp')}
                                hideEdit={true}
                                onChange={handleChangePermission}
                            ></PermissionBox>
                        </Grid>
                        <Grid item xs={6}>
                            <PermissionBox
                                page={'inventory'}
                                title={'Inventory'}
                                descriptioin={'Ability to view and edit existing inventory.'}
                                classes={classes}
                                view={data.view.includes('inventory')}
                                edit={data.edit.includes('inventory')}
                                onChange={handleChangePermission}
                            ></PermissionBox>
                        </Grid>                        
                        <Grid item xs={6}>
                            <PermissionBox
                                page={'barcodes'}
                                title={'Barcodes'}
                                descriptioin={'Ability to view and edit barcodes.'}
                                classes={classes}
                                view={data.view.includes('barcodes')}
                                edit={data.edit.includes('barcodes')}
                                onChange={handleChangePermission}
                            ></PermissionBox>
                        </Grid>
                        <Grid item xs={6}>
                            <PermissionBox
                                page={'users'}
                                title={'Users'}
                                descriptioin={'Ability to view and edit permissions.'}
                                classes={classes}
                                view={data.view.includes('users')}
                                edit={data.edit.includes('users')}
                                onChange={handleChangePermission}
                            ></PermissionBox>
                        </Grid>
                    </Grid>
                </Box>

            </Box>  
        </Layout>
    );
}

const condition = authUser => !!authUser;

export default withAuthorization(condition)(Permission);

