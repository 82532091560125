import * as React from "react";
const ExMarkSVG = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={25}
    fill="none"
    {...props}
  >
    <path
      fill="#DA2525"
      d="M20.703 4.297c2.281 2.25 3.422 4.984 3.422 8.203 0 3.219-1.14 5.969-3.422 8.25-2.25 2.25-4.984 3.375-8.203 3.375-3.219 0-5.969-1.125-8.25-3.375C2 18.469.875 15.719.875 12.5.875 9.281 2 6.547 4.25 4.297 6.531 2.016 9.281.875 12.5.875c3.219 0 5.953 1.14 8.203 3.422ZM14 15.5a1.966 1.966 0 0 0-1.5-.656c-.594 0-1.11.219-1.547.656-.406.406-.61.906-.61 1.5s.204 1.11.61 1.547c.438.406.953.61 1.547.61s1.094-.204 1.5-.61c.438-.438.656-.953.656-1.547s-.219-1.094-.656-1.5Zm-3.563-8.39.376 6.374c0 .344.187.516.562.516h2.25c.375 0 .563-.172.563-.516l.374-6.375c0-.187-.062-.328-.187-.421A.446.446 0 0 0 14 6.5h-3a.583.583 0 0 0-.422.188c-.094.093-.14.234-.14.421Z"
    />
  </svg>
);
export default ExMarkSVG;
