import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import {Dialog, DialogTitle, DialogContent, DialogActions, Divider, Box, Button, } from '@mui/material';
import {BootstrapInput} from './input';
import {validateBatchName} from '../../module/functions';

const useHeaderStyles = makeStyles((theme) => ({
    closeButton: {
        position: 'absolute',
        right: '10px',
        top: '10px',
        color: '#000000',
    },
    modalTitle: {
        fontSize: '32px',
        lineHeight: '45px',
        fontWeight: '500',
        color: '#000000'
    },
    modalText: {
        color: '#000000',
        letterSpacing: '1.5px',
        fontSize: '14px',
        fontWeight: '500',
        textTransform: 'uppercase'
    },
    modalError: {
        fontSize: '14px',
        fontWeight: '400',
        color: '#f44336',
        marginTop: '5px'
    },
    modalLabel: {
        fontSize: '16px',
        fontWeight: '500',
        color: '#000000'
    },
    disabledButton: {
        backgroundColor: '#ccc!important',
        color: 'gray !important'
    },
}));

function ConfirmDialog(props) {
    const {open, label, title, onClose, onConfirm} = props;
    const classes = useHeaderStyles();
    return (
        <Dialog 
            onClose={onClose} 
            aria-labelledby={label} 
            open={open}
            fullWidth={true}
            maxWidth="sm">
            <DialogTitle id="print-dialog-title" onClose={onClose} sx={{padding: '10px 40px', marginTop:'20px'}}>
                <Typography variant='h2'>{title}</Typography>   
                <IconButton aria-label="close" variant='close' onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <Box sx={{padding: '20px 14px', minHeight: '100px'}}>
                {props.children}
                </Box>
            </DialogContent>
            <DialogActions sx={{padding: '10px 40px'}}>
                <Button 
                    onClick={onClose} 
                    color="secondary" 
                    variant="black" 
                    sx={{width: '120px'}}  
                >No</Button> 

                <Button onClick={onConfirm} 
                    color="primary" 
                    variant="blue" 
                    sx={{width: '120px'}}  
                >Yes</Button>         
            </DialogActions>
        </Dialog>
    );
}

function GeneralDialog(props) {
    const {open, label, title, onClose, OnHandle} = props;
    const classes = useHeaderStyles();
    return (
        <Dialog 
            onClose={onClose} 
            aria-labelledby={label} 
            open={open}
            fullWidth={true}
            maxWidth="sm">
            <DialogTitle id="print-dialog-title" onClose={onClose} sx={{padding: '10px 40px', marginTop:'20px'}}>
                <Typography variant='h2'>{title}</Typography>   
                <IconButton aria-label="close" variant='close' onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <Box sx={{padding: '20px 14px', minHeight: '100px'}}>
                {props.children}
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} 
                    color="primary" 
                    variant="blue" 
                    sx={{width: '120px'}}  
                >OK</Button>         
            </DialogActions>
        </Dialog>
    );
}

function PrintDialog(props) {
    const {open, total, products, title, label, showInfo, boxsets, onPrint, onClose} = props;

    const [name, setName] = useState('');
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    useEffect(() => {
        if (open) {
            setName("");
        }
    }, [open]);

    const onChangeName = event => {
        const value = event.target.value;
        let result = validateBatchName(value);
        if (result !== "Success") {
            setError(true);
            setErrorMsg(result);
        } else {
            setError(false);
            setErrorMsg("");
        }
        setName(value);
    };

    const handlePrint = () => {
        if (name === '')
            return;
        if (onPrint)
            onPrint(name);
    }

    const handleClose = () => {
        if (onClose)
            onClose();
    }

    const classes = useHeaderStyles();
    return (
    <Dialog onClose={handleClose} aria-labelledby="print-dialog-title" open={open} fullWidth >
        <DialogTitle id="print-dialog-title" onClose={handleClose} sx={{padding: '20px 40px'}}>
            {title !== "" && <Typography variant="h2">{title}</Typography>}
          
          <IconButton aria-label="close" variant='close' onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{padding: '20px 40px'}} dividers>
            {showInfo && (
            <Typography className={classes.modalText}>
            Selected <strong>{total}</strong> Orders, <strong>{products}</strong> Products, <strong>{boxsets}</strong> Bundles
            </Typography>
            )}

            {showInfo && (
                <Divider component="div" sx={{marginTop:'30px', marginBottom: '40px'}} />
            )}           

            <Box >
                <Typography className={classes.modalLabel}>Batch Name</Typography>
                <BootstrapInput                 
                    name="name"
                    defaultValue={name}
                    autoFocus
                    onChange={onChangeName}  
                />
                {error && (<Typography className={classes.modalError}>{errorMsg}</Typography>)}
            </Box> 

        </DialogContent>
        <DialogActions sx={{padding: '20px 40px'}}>
          <Button 
              onClick={handlePrint}
              disabled={error}
              color="primary" 
              variant="blue" 
              classes={{ disabled: classes.disabledButton }}
              sx={{width: '145px'}}  
          >{label}</Button>
        </DialogActions>
    </Dialog>
    );
}

export { GeneralDialog, ConfirmDialog, PrintDialog };
