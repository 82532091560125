import moment from 'moment-timezone';

export const convertToRows = (_orders) => {
  const rows = [];
  _orders.forEach((order, index) => {
    const odd = index % 2 === 1;
    const createDate = moment(order.createDate, 'YYYY-MM-DD HH:mm:ss')
      .tz('America/New_York')
      .format('MM/DD/YY');

    order.items.forEach((item, itemIndex) => {

      const rowData = {
        createDate,
        odd,
        orderNumber: order.orderNumber,
        exists: item.exists,
        available: item.available,
        type: item.type,
        shipping: order.shipping,
        sku: item.sku,
        quantity: item.quantity,
        tags: item.tags,
        source: order.source,
        reprint: !!order.reprint,
        error: item.error,
        style: item.style,
        items: item.items,
        index: itemIndex,
      };

      // Conditionally add isMixAndMatch and properties if isMixAndMatch is truthy
      if (item.isMixAndMatch) {
        rowData.isMixAndMatch = item.isMixAndMatch;
        rowData.properties = item.properties;
      }
      rows.push(rowData);
    });
  });
  return rows;
};

export const headCells = [
  { id: 'date', numeric: false, align: 'left', enableSort: false, disablePadding: true, label: 'DATE', width: '140px' },
  { id: 'order', numeric: true, align: 'left', enableSort: false, disablePadding: false, label: 'ORDER', width: '140px' },
  { id: 'type', numeric: false, align: 'center', enableSort: false, disablePadding: false, label: 'TYPE', width: '110px' },
  { id: 'sku', numeric: false, align: 'left', enableSort: false, disablePadding: false, label: 'SKU', width: 'auto' },
  { id: 'shipping', numeric: false, align: 'left', enableSort: false, disablePadding: false, label: 'Shipping', width: 'auto' },
  { id: 'qty', numeric: true, align: 'left', enableSort: false, disablePadding: false, label: 'QTY', width: '60px' },
  { id: 'tags', numeric: false, align: 'left', enableSort: false, disablePadding: false, label: 'TAGS', width: '60px' },
  { id: 'source', numeric: false, align: 'center', enableSort: false, disablePadding: false, label: 'SOURCE', width: '100px' },
];