import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import { formatBytes } from '../../module/functions';
import { palette } from '../../theme/colors';
import { OrderSummary } from './pageHeaderHelperOrders';


const PageHeader = (props) => {

  // Destructure props for easier access
  const { shopifyWebCounter, dropShipCounter, selected, initial, showFilterData, filteredData} = props;

  const displayData = selected.total > 0 ? selected : initial;

  const selectedSize = formatBytes(selected.size);

  return (

    <div className="pageHeader">

      <div className="pageHeading">

        <Typography variant="h1">Custom Order Queue</Typography>

        <OrderSummary title="Web" counter={shopifyWebCounter} palette={palette} />
        
        <OrderSummary title="DS" counter={dropShipCounter} palette={palette} />
        
        <OrderSummary title="Total" counter={initial} palette={palette} />

        {selected.total > 0 && <OrderSummary title="Selected" counter={displayData} palette={palette} />}

        {showFilterData && <OrderSummary title="Filtered" counter={filteredData} palette={palette} />}

      </div>

    </div>
  );
};

PageHeader.propTypes = {
  initial: PropTypes.object.isRequired,
  selected: PropTypes.object.isRequired,
};

export default PageHeader;