import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import Module from './module';
import { BarcodeCanvas } from './canvas';
import { withAuthorization } from '../Session';
import {BootstrapInput} from '../Page/input';
import { createBarcode, updateBarcode } from '../../db/barcodes';

const useHeaderStyles = makeStyles((theme) => ({
  inputInlineLabel: {
    fontSize: '16px',
    fontWeight: '500',
    color: '#000000',
    marginTop: '15px',
    marginRight: '25px'
  },
  normalAccordion: {
    background: 'rgba(242, 244, 246, 0.4)'
  },
  expandedAccordion: {
    background: '#F1F7FE'
  },
  deleteBox: {
    position: 'absolute',
    top: '50%',
    right: '0px',
    transform: 'translateY(-50%)'
  },
  accordionControlBar: {
    position: 'relative',
    display: "flex",
    alignItems:"center",
    justifyContent: "space-between",
    width:'300px', 
    paddingRight:'40px'
  },
  content: {
    display: "flex",    
    justifyContent: "space-between",
    padding: '20px 20px 40px'
  },
  modules: {
    width: '420px',
    overflowY: "scroll",
    height: '300px'
  },
  preview: {
    width: '600px'
  }
}));

const DEFAULT_MODULE1 = {
  name: "Product Name", 
  type: "text",
  image: "",
  text: "Product Name",
  fontSize: 30,
  fontWeight: 600,
  pos: {
    x:15,
    y:30,
    width:10,
    height:10,
    _width:10,
    _height:10
  },
  visible: true
}

const DEFAULT_MODULE2 = {
  name: "Product Sku", 
  type: "text",
  image: "",
  text: "XXX-CUSTOM-LXL",
  fontSize: 20,
  fontWeight: 500,
  pos: {
    x:200,
    y:50,
    width:10,
    height:10,
    _width:10,
    _height:10
  },
  visible: true
}

const DEFAULT_MODULE3 = {
  name: "Barcode number", 
  type: "text",
  image: "",
  text: "1234567890",
  fontSize: 40,
  fontWeight: 500,
  pos: {
    x:10,
    y:65,
    width:440,
    height:220,
    _width:440,
    _height:220
  },
  visible: true
}

const DEFAULT_MODULE4 = {
  name: "RN Number", 
  type: "text",
  image: "",
  text: "124771",
  fontSize: 14,
  fontWeight: 500,
  pos: {
    x:550,
    y:160,
    width:10,
    height:10,
    _width:10,
    _height:10
  },
  visible: true
}

const DEFAULT_MODULE5 = {
  name: "Price", 
  type: "text",
  image: "",
  text: "$100",
  fontSize: 24,
  fontWeight: 600,
  pos: {
    x:520,
    y:270,
    width:10,
    height:10,
    _width:10,
    _height:10
  },
  visible: true
}

const DEFAULT_MODULE6 = {
  name: "Wash Instructions", 
  type: "image",
  image: "",
  text: "",
  fontSize: 0,
  pos: {
    x:470,
    y:70,
    width:150,
    height:70,
    _width:150,
    _height:70
  },
  visible: true
}

const DEFAULT_MODULE7 = {
  name: "Content", 
  type: "image",
  image: "",
  text: "",
  fontSize: 0,
  pos: {
    x:470,
    y:70,
    width:150,
    height:70,
    _width:150,
    _height:70
  },
  visible: true
}

const DEFAULT_MODULE8 = {
  name: "Vendor Information", 
  type: "image",
  image: "",
  text: "",
  fontSize: 0,
  pos: {
    x:470,
    y:10,
    width:140,
    height:60,
    _width:140,
    _height:60
  },
  visible: true
}

const DEFAULT_MODULE9 = {
  name: "Vendor Logo", 
  type: "image",
  image: "",
  text: "",
  fontSize: 0,
  pos: {
    x:490,
    y:170,
    width:110,
    height:70,
    _width:110,
    _height:70
  },
  visible: true
}

const DEFAULT_STATE = {
  name: "",
  type: "codabar",
  rnNumber: "",
  modules: [
    {...DEFAULT_MODULE1}, 
    {...DEFAULT_MODULE2}, 
    {...DEFAULT_MODULE3}, 
    {...DEFAULT_MODULE4}, 
    {...DEFAULT_MODULE5}, 
    {...DEFAULT_MODULE6}, 
    {...DEFAULT_MODULE7}, 
    {...DEFAULT_MODULE8},
    {...DEFAULT_MODULE9}
  ]
};

function BarcodeModal(props) {
  const {handleClose, open, isCreate} = props;
  const [template, setTemplate] = useState({...DEFAULT_STATE});
  const db = props.firebase.getdb();

  useEffect(() => {
    if (open) {
      if (isCreate) {
        setTemplate({...DEFAULT_STATE});
      } else {
        const modules = _.map(props.item.modules, item => {
          return {...item, expanded:false};
        })
        setTemplate({...props.item, modules}); 
      }         
        
    }
  }, [open]);

  const handleSave = () => {
    if (isCreate) {
      createBarcode(db, template);      
    } else {
      updateBarcode(db, template);      
    }
      
    handleClose();
  }

  const handleCloseDialog = (event) => {

    handleClose(); 
  }

  const handleChange = (event) => {
    let { name, value, checked, type} = event.target;
    value = type === "checkbox" ? checked : value;

    let _template = {...template};
    _template[name] = value;

    setTemplate(_template);
  }

  const renderHeader = () => {
    return (
      <Box sx={{padding: '10px 0 40px'}}>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <InputLabel className={classes.inputInlineLabel}>Template Name</InputLabel>
            <BootstrapInput type="text" name="name" value={template.name} onChange={handleChange} />
          </Grid>
          <Grid item xs={3}>
            <InputLabel className={classes.inputInlineLabel}>Barcode Type</InputLabel>
            <Select
              name="type"
              value={template.type}
              input={<BootstrapInput />}
              onChange={handleChange}
            >
              <MenuItem value="codabar">Codabar</MenuItem>
              <MenuItem value="CODE128">CODE128</MenuItem>
              <MenuItem value="CODE39">CODE39</MenuItem>
              <MenuItem value="EAN13">EAN-13</MenuItem>
              <MenuItem value="EAN8">EAN-8</MenuItem>
              <MenuItem value="EAN5">EAN-5</MenuItem>
              <MenuItem value="EAN2">EAN-2</MenuItem>
              <MenuItem value="UPC">UPC</MenuItem>
              <MenuItem value="ITF14">ITF14</MenuItem>
              <MenuItem value="MSI">MSI</MenuItem>
              <MenuItem value="pharmacode">Pharmacode</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={3}>            
            <InputLabel className={classes.inputInlineLabel}>RN #</InputLabel>
            <BootstrapInput type="text" name="rnNumber" value={template.rnNumber} onChange={handleChange} />            
          </Grid>
        </Grid>
      </Box>
    );
  }

  const handleUpdateModule = (layer) => {
    const modules = _.map(template.modules, item => {
      if (layer.name === item.name) {
        return {...layer, pos:{...layer.pos}};
      }
      return {...item};
    });
    setTemplate({...template, modules});
  }

  const renderContent = () => {
    return (
      <Box className={classes.content}>
        <Box className={classes.preview}>
          <BarcodeCanvas 
            firebase={props.firebase.getFirebase()}
            template={template}            
          />
        </Box>
        <Box className={classes.modules}>
          {
            template.modules.map((module, index) => {
              const labelId = `Module_${module.name}`;
              return (
                <Module 
                  key={labelId}
                  layer={module}
                  onUpdate={handleUpdateModule}

                />
              )
            })
          }
        </Box>
      </Box>
    )
  }

  const classes = useHeaderStyles();
  const scroll = 'paper';
  return (
    <Dialog onClose={handleCloseDialog} 
        aria-labelledby="barcode-dialog-title" 
        open={open}         
        fullWidth={true} 
        maxWidth={"lg"} 
        scroll={scroll}
    >
        <DialogTitle onClose={handleCloseDialog} sx={{padding: '30px 40px 10px', position: 'relative'}}>
          <Typography variant="h2">{template.name}</Typography>   
          <IconButton aria-label="close" variant='close' onClick={handleCloseDialog}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{padding: '0 40px'}} dividers>
          {renderHeader()}
          {renderContent()}
        </DialogContent>
        <DialogActions sx={{justifyContent: 'flex-start', padding: '20px 40px'}}>
          <Button variant="blue" color="primary" sx={{width:'150px'}} onClick={handleSave}>Save</Button>        
        </DialogActions>
    </Dialog>
  );
}


const condition = authUser => !!authUser;
export default withAuthorization(condition)(BarcodeModal);
