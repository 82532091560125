import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { ConfirmDialog } from '../Page/generalModal';
import Papa from 'papaparse';

import { formatBytes } from '../../module/functions';
import { getProductBySku, getProductsBySku, getProductsByVariant } from '../../db/products';
import { getBoxsetsBySku, getBoxsetsByVariant } from '../../db/boxsets';

import { withAuthorization } from '../Session';
import ProductsSearch from '../Page/productsSearch';
import { OrderSummary } from '../CustomOrders/pageHeaderHelperOrders';
import { palette } from '../../theme/colors';
import MissingProductModal from './missingProductModal';
import { Box, FormControlLabel, Switch, Tooltip } from '@mui/material';
import useToolTip from '../../hooks/useToolTip';
import useStorageKey from '../../hooks/useGetStorageKey';

const useHeaderStyles = makeStyles((theme) => ({
  search: {
    width: '800px',
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: 'rgba(255, 255, 255, 0.04)',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.08)',
    },
    marginLeft: 0,
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    right: '0px'
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: '10px 16px',
    paddingRight: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
  },
  headerAction: {
    width: '1000px',
    display: 'flex',
    flexDirection: 'column', // Changed to stack switch and row vertically
    gap: theme.spacing(1), // Adjust gap as needed
  },
  actionRow: {
    display: 'flex',
    flexDirection: 'row', // Ensure row elements line up in a row
    alignItems: 'center', // Center-align items within the row
    justifyContent: 'space-between',
    width: '100%', // Match width to parent to space out items
  },
  headerButton: {
    width: '160px'
  },
  headerText: {
    fontSize: '14px',
    fontWeight: '500',
    letterSpacing: '1.5px',
    color: '#000000',
    opacity: '0.4'
  },
  csvButton: {
    background: '#3D3D3D',
    borderRadius: '6px',
    width: '100%',
    fontSize: '16px',
    fontWeight: '500',
    color: '#FFFFFF',
    textTransform: 'initial',
    '&:hover': {
      background: 'rgba(61,61,61, 0.8)',
    },
  },
  searchText: {
    fontSize: '22px',
    fontWeight: '500',
    color: 'rgba(0,0,0,0.56)',
    marginTop: '30px'
  },
  pageDescription: {
    width: '100%'
  }
}));

let boxsetLastItem = { name: '', sku: '' };

function PageHeader(props) {

  const { initial, selected, handleCSV, handleSelect, formats, sizes, searchOrderMode, setSearchOrderMode } = props;

  useStorageKey({
    storageKey: 'skuSearchMode',
    setFunction: setSearchOrderMode,
    initialValue: false,
  });

  const [open, setOpen] = useState(false);

  const [key, setKey] = useState("");

  const [csvFile, setCSVFile] = useState(null);

  const db = props.firebase.getdb();

  const [showModal, setShowModal] = useState(false);

  const handleFileSelect = (event) => {
    const _csvFile = event.target.files[0];
    setCSVFile(_csvFile);
    setOpen(true);
    console.log('CSV File name: ', _csvFile.name);
  };

  const handleUploadCSV = () => {

    Papa.parse(csvFile, {
      header: true,
      dynamicTyping: true,
      skipEmptyLines: true,
      complete: async (results) => {
        console.log('-- results: ', results);

        setOpen(false);
        await processCsvItems(results.data);

      }
    });
  }

  const processCsvItems = async (skus) => {
    const items = await getProductsBySku(db, skus);

    const existItems = _.filter(items, { exists: true });
    let nonExistItems = _.filter(items, { exists: false });

    const productItems = _.map(existItems, item => {
      let frontSize = 0, backSize = 0;
      if (!!item.frontArt && !!item.frontArt.size) {
        frontSize = item.frontArt.size;
      }

      if (!!item.backArt && !!item.backArt.size) {
        backSize = item.backArt.size;
      }

      return { ...item, error: "", boxset: false, frontSize, backSize };
    });


    const boxsetProductItems = await getBoxsetsBySku(db, nonExistItems);
    nonExistItems = _.filter(boxsetProductItems, { exists: false });
    const boxsetVariantItems = await getBoxsetsByVariant(db, nonExistItems);

    nonExistItems = _.filter(boxsetVariantItems, { exists: false });
    const boxsetItems = [..._.filter(boxsetProductItems, { exists: true }), ..._.filter(boxsetVariantItems, { exists: true })];

    //get sub products sku for boxset
    const boxsetSKUs = [];
    _.map(boxsetItems, item => {
      _.map(item.items, itemId => {
        const existingItems = _.filter(boxsetSKUs, { sku: itemId });
        if (existingItems.length === 0) {
          boxsetSKUs.push({ qty: 1, sku: itemId });
        }
      })
    });

    const boxProducts = await getProductsBySku(db, boxsetSKUs);

    const boxsetFinalItems = _.map(boxsetItems, item => {
      let frontSize = 0, backSize = 0;
      // Transform each SKU in item.items into a detailed product object
      const detailedItems = _.map(item.items, sku => {
        const _product = _.find(boxProducts, { exists: true, sku });

        if (!!_product && !!_product.frontArt) {
          frontSize += _product.frontArt.size;
        }

        if (!!_product && !!_product.backArt) {
          backSize += _product.backArt.size;
        }

        // Return the product object here, which includes the detailed information
        return _product;
      });

      // Include the detailedItems array with product objects instead of just SKUs
      return { ...item, error: "", errors: [], boxset: true, frontSize, backSize, items: detailedItems };
    });



    const variantItems = await getProductsByVariant(db, nonExistItems);
    const variantFinalItems = _.map(variantItems, (item, index) => {
      if (item.exists) {
        let frontSize = 0, backSize = 0;
        if (!!item.frontArt && !!item.frontArt.size) {
          frontSize = item.frontArt.size;
        }

        if (!!item.backArt && !!item.backArt.size) {
          backSize = item.backArt.size;
        }

        return { ...item, error: "", sku: nonExistItems[index].sku, boxset: false, frontSize, backSize, qty: item.qty };
      }

      return { ...item, error: "Product Not Found", qty: item.qty };
    });

    const allItems = [...productItems, ...boxsetFinalItems, ...variantFinalItems];

    handleCSV(allItems);
  }

  const handleCloseDialog = () => {
    setOpen(false);
  }

  const renderUploadDialog = () => {
    return (
      <ConfirmDialog
        label="csv-dialog-title"
        title="CSV Upload"
        open={open}
        onConfirm={handleUploadCSV}
        onClose={handleCloseDialog}
      >
        <Typography variant='body2' gutterBottom>
          Do you want to upload <strong>{csvFile ? csvFile.name : 'this file'}</strong> ?
        </Typography>
      </ConfirmDialog>
    )
  }

  const handleChangeKey = (value) => {
    setKey(value);
  }

  const handleSelectProduct = (newValue) => {
    if (searchOrderMode) {
      handleSelect(newValue);
      return;
    }

    if (newValue.isBoxset) {
      const boxsetSKUs = [];
      boxsetLastItem.name = newValue.name;
      boxsetLastItem.sku = newValue.sku;
      _.map(newValue.items, item => {
        boxsetSKUs.push({ qty: 1, sku: item });
      });

      getProductsBySku(db, boxsetSKUs)
        .then(_items => {
          fetchBoxsetProducts(_items);
        });

    } else {
      getProductBySku(db, newValue.sku, fetchProduct);
    }
  }

  const fetchBoxsetProducts = (items) => {
    let frontSize = 0, backSize = 0;
    _.map(items, _product => {
      if (!!_product && !!_product.frontArt && !!_product.frontArt.size) {
        frontSize += _product.frontArt.size;
      }

      if (!!_product && !!_product.backArt && !!_product.backArt.size) {
        backSize += _product.backArt.size;
      }
    });


    let item = {
      exists: true,
      qty: 1,
      format: '',
      size: '',
      name: boxsetLastItem.name,
      sku: boxsetLastItem.sku,
      frontSize,
      backSize,
      boxset: true,
      items
    };

    handleSelect(item);

  }

  const fetchProduct = (_product) => {
    console.log('Fetch Product');

    let item = {
      exists: true,
      qty: 1,
      type: _product.type,
      style: _product.style,
      format: _product.format,
      size: _product.size,
      name: _product.name,
      sku: _product.sku,
      isMask: _product.isMask,
      backArt: !!_product.backArt ? _product.backArt : {},
      frontArt: !!_product.frontArt ? _product.frontArt : {},
      frontSize: !!_product.frontArt ? _product.frontArt.size : 0,
      backSize: !!_product.backArt ? _product.backArt.size : 0,
      boxset: _product.isBoxset === true ? true : false,
      leftArt: !!_product.leftArt ? _product.leftArt : {},
      rightArt: !!_product.rightArt ? _product.rightArt : {},
      pathLeft: !!_product.leftArt ? _product.leftArt : '',
      pathRight: !!_product.pathRight ? _product.pathRight.path : '',
      pathHyperOpticIdentical: !!_product.pathHyperOpticIdentical ? _product.pathHyperOpticIdentical : '',
      isBoxedImageArt: !!_product.isBoxedImageArt ? _product.isBoxedImageArt : {},
      hyperOpticIdenticalArt: !!_product.hyperOpticIdenticalArt ? _product.hyperOpticIdenticalArt : {},
      isBoxedImagePath: !!_product.isBoxedImagePath ? _product.isBoxedImagePath.path : '',
    };
    handleSelect(item);

  };

  const classes = useHeaderStyles();

  const filter = 'isCustomProduct: "false"';

  const totalSize = formatBytes(initial.frontSize + initial.backSize);

  const selectedSize = formatBytes(selected.frontSize + selected.backSize);

  const useThisCounter = (counter) => {
    // Determine the type of counter and map it to the corresponding key
    const counterKey = counter === 'missingArt' ? 'missingArtInBoxesSkus' : counter === 'pnfs' ? 'notFoundInBoxesSkus' : null;

    // First, check if 'selected' has relevant data
    if ((selected.missingArtInBoxes > 0 || selected.notFoundInBoxes > 0) && counterKey) {
      return selected[counterKey];
    }

    // If not, fall back to 'initial'
    if ((initial.missingArtInBoxes > 0 || initial.notFoundInBoxes > 0) && counterKey) {
      return initial[counterKey];
    }

    // Default return if no conditions are met
    return [];
  }

  // State to manage whether the tooltip is shown
  const [showTooltip, setShowTooltip] = useState(false);

  useToolTip({
    setFunction: setShowTooltip,
  });

  return (
    <div className="pageHeader">
      <div className={classes.pageHeading}>
        <Typography variant="h1">Manual Entry</Typography>
      </div>

      <div className={classes.headerAction}>
        <Tooltip
          title={<span style={{ fontSize: '16px' }}>Toggle Order Search by typing `/` anywhere </span>} // Increase font size directly in title
          open={showTooltip}
          enterDelay={3000}
          leaveDelay={100}
          placement="top-start"
          PopperProps={{
            sx: {
              "& .MuiTooltip-tooltip": {
                padding: "8px", // Increase padding
              }
            }
          }}
          // invert arrow
          arrow
        >
          <Typography
            sx={{
              fontSize: '1rem',
              fontWeight: 'bold',
            }}
          >
            Search By Order: {searchOrderMode ? 'ON' : 'OFF'}
          </Typography>
        </Tooltip>

        <div className={classes.actionRow}> {/* Encapsulate row elements */}
          <div className={classes.search}>
            <ProductsSearch
              db={db}
              onSelect={handleSelectProduct}
              onChange={handleChangeKey}
              setSearchOrderMode={setSearchOrderMode}
              searchOrderMode={searchOrderMode}
              setShowTooltip={setShowTooltip}
              formats={formats}
              sizes={sizes}
            />
          </div>

          <div className={classes.headerText}>OR</div>

          <div className={classes.headerButton}>
            <label htmlFor="contained-button-file">
              <input
                hidden
                type="file"
                accept=".csv"
                id="contained-button-file"
                onChange={handleFileSelect}
              />
              <Button variant="blue" component="span" sx={{ width: '180px' }} disabled={!props.editOnly} >
                Upload CSV
              </Button>
            </label>
          </div>
        </div> {/* Close actionRow */}
      </div>

      {
        selected.products > 0 ? (
          <OrderSummary title="Selected" counter={selected} palette={palette} hideOrdersString={true} bundles={selected.boxes} />
        ) : (
          <OrderSummary title="Total" counter={initial} palette={palette} hideOrdersString={true} bundles={initial.boxes} />
        )
      }

      {
        ((selected.notFoundInBoxes > 0 || selected.missingArtInBoxes > 0) ||
          (initial.notFoundInBoxes > 0 || initial.missingArtInBoxes > 0)) && (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', paddingTop: '1%' }}>
            <Button
              onClick={() => setShowModal(true)}
              variant="outlined"
              sx={{
                color: '#FF0000',
                borderColor: '#FF0000',
                textTransform: 'initial'
              }}
            >
              See Missing Products In Bundles
            </Button>
          </Box>
        )
      }

      {
        showModal && (
          <MissingProductModal
            missingArtSkus={useThisCounter('missingArt')}
            pnfSkus={useThisCounter('pnfs')}
            onClose={() => setShowModal(false)}
            open={showModal}
          />
        )
      }

      {renderUploadDialog()}
    </div>
  );
}


const condition = authUser => !!authUser;
export default withAuthorization(condition)(PageHeader);
