import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import FilterDropdown from '../Page/filterDropdown';
import { EnhancedTableHead } from '../Page/table';
import { withAuthorization } from '../Session';
import { RowWrapper } from '../History/row';

import { useSelector } from 'react-redux';

const filterShows = [
  { id: 0, name: '25', value: 25 },
  { id: 1, name: '50', value: 50 },
  { id: 2, name: '100', value: 100 },
];


const headCells = [
  { id: 'name', numeric: false, align: 'left', disablePadding: true, label: 'DATE', width: '170px' },
  { id: 'error', numeric: false, align: 'center', disablePadding: true, label: '', width: '30px' },
  { id: 'batch', numeric: false, align: 'left', disablePadding: false, label: 'BATCH NAME', width: '400px' },
  { id: 'type', numeric: false, align: 'left', disablePadding: false, label: 'BATCH INFO', width: '220px' },
  { id: 'files', numeric: false, align: 'center', disablePadding: false, label: 'FILES', width: '120px' },
  { id: 'downloaded', numeric: false, align: 'center', disablePadding: false, label: 'DOWNLOADED', width: 'auto' },
  { id: 'download', numeric: false, align: 'center', disablePadding: false, label: '', width: '150px' },
  { id: 'action', numeric: false, align: 'center', disablePadding: false, label: '', width: '80px' },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  filterBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  table: {
    minWidth: 750,
  },
  itemTable: {
    width: '1200px'
  },
  itemTableSub: {
    width: '100%'
  },
  tableHead: {
    background: 'rgba(217, 223, 233, 0.4)'
  },
  tableHeadCell: {
    lineHeight: '27.5px'
  },
  tableHeadLabel: {
    fontSize: '14px',
    fontWeight: '500',
    color: '#000000',
    letterSpacing: '1.5px'
  },
  tableCell: {
    fontSize: '14px',
    fontWeight: '500!important',
    letterSpacing: '1.5px',
    color: '#000000'
  },
  itemTableCell: {
    fontSize: '14px',
    fontWeight: '500!important',
    letterSpacing: '1.5px',
    color: '#000000',
    borderBottom: 'none!important'
  },
  itemTableCell2: {
    fontSize: '14px',
    fontWeight: '500!important',
    letterSpacing: '1.5px',
    color: '#E0491B!important',
    borderBottom: 'none!important'
  },
  txtLocation: {
    fontSize: '12px',
    fontWeight: '500!important',
    letterSpacing: '1.5px',
    color: '#14B779!important',
  },
  itemTableCell3: {
    fontSize: '14px',
    fontWeight: '500!important',
    letterSpacing: '1.5px',
    color: '#14B779!important',
    borderBottom: 'none!important'
  },
  tableRow: {
    height: '70px',
    backgroundColor: 'rgba(242, 244, 246, 0.4)',
    '&:hover': {
      backgroundColor: '#F1F7FE!important',
    }
  },
  tableRow2: {
    height: '70px',
    backgroundColor: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#F1F7FE!important',
    }
  },
  selectedTableRow: {
    backgroundColor: '#F1F7FE!important',
  },
  progressPrimaryError: {
    backgroundColor: '#14B779'
  },
  progressPrimaryNormal: {
    backgroundColor: 'rgba(31, 124, 242, 0.16)'
  },
  progressBarError: {
    backgroundColor: '#14B779'
  },
  progressBarNormal: {
    backgroundColor: '#1F7CF2'
  },
  jobActionButton: {
    width: '100%',
    color: '#FFFFFF',
    textTransform: 'initial',
    fontSize: '16px',
    fontWeight: '500!important',
    borderRadius: '6px',
    "&.Mui-disabled": {
      background: "rgba(0, 0, 0, 0.12)!important",
      color: "rgba(0, 0, 0, 0.26)"
    }
  },
  jobActionButton1: {
    color: '#FFFFFF',
    borderRadius: '6px',
    padding: '8px 16px',
    '&:hover': {
      background: '#1F7CF2',
    },
    "&.Mui-disabled": {
      background: "rgba(0, 0, 0, 0.12)!important",
      color: "rgba(0, 0, 0, 0.26)"
    }
  },
  jobActionButton2: {
    color: '#FFFFFF',
    borderRadius: '6px',
    background: '#1F7CF2',
    marginLeft: '15px',
    padding: '8px 16px',
    '&:hover': {
      background: '#1F7CF2',
    },
    "&.Mui-disabled": {
      background: "rgba(0, 0, 0, 0.12)!important",
      color: "rgba(0, 0, 0, 0.26)"
    }
  },
  jobActionButton3: {
    color: '#FFFFFF',
    borderRadius: '6px',
    background: '#1F7CF2',
    marginRight: '15px!important',
    padding: '8px 16px',
    '&:hover': {
      background: '#1F7CF2',
    },
    "&.Mui-disabled": {
      background: "rgba(0, 0, 0, 0.12)!important",
      color: "rgba(0, 0, 0, 0.26)"
    }
  },
  jobActionButton4: {
    background: '#3d3d3d',
    minWidth: '55px',
    "&.Mui-disabled": {
      background: "rgba(0, 0, 0, 0.12)!important",
      color: "rgba(0, 0, 0, 0.26)"
    }
  },
  buttonCSV: {
    background: '#FFFFFF!important',
    color: '#1F7CF2!important',
    fontSize: '16px!important',
    fontWeight: '600!important',
    width: '100%!important',
    borderRadius: '6px!important'
  },
  buttonQueue: {
    background: '#e0491b!important',
    color: '#FFFFFF!important',
    fontSize: '16px!important',
    fontWeight: '600!important',
    width: '100%!important',
    borderRadius: '6px!important',
    marginTop: '20px!important',
    '&:hover': {
      background: '#e0491b!important',
    }
  },
  buttonInventory: {
    color: '#14B779!important',
    fontSize: '16px!important',
    fontWeight: '600!important',
    '&:hover': {
      background: 'transparent!important',
    }
  },
  itemNavButton: {
    fontSize: '14px',
    fontWeight: '600',
    letterSpacing: '1.5px',
    color: '#000000',
    borderRadius: '0',
    width: '100%'
  }
}));

function Runnning(props) {

  const { isLoading, responseFetchedRanDoc, historyDocs } = useSelector(state => state.history);

  const classes = useStyles();

  const [filterShow, setFilterShow] = useState(0);

  const db = props.firebase.getdb();

  const storage = props.firebase.getstorage();


  let _jobs = [...historyDocs];

  const showCount = _.find(filterShows, item => item.id == filterShow).value;

  const jobs = _jobs.slice(0, showCount + 3).map(job => ({ ...job }));

  return (
    <>
      <Box sx={{ marginTop: '90px' }}>
        <div className="pageHeader">
          <div className="pageHeading">
            <Typography variant="h1">Print Recently</Typography>
            {
              responseFetchedRanDoc && (
                <Typography variant="subtitle1">
                  {responseFetchedRanDoc}
                </Typography>
              )
            }
          </div>
        </div>
        <div className='pageToolbar'>
          <div className={classes.filterBox}>
            <FilterDropdown title="Show" items={filterShows} active={filterShow} onSelect={setFilterShow} />
          </div>
        </div>

        <TableContainer sx={{ marginTop: '30px', marginBottom: '50px' }}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              useCheckbox={true}
              cells={headCells}
            />

            {
              isLoading ? (
                <TableRow>
                  <TableCell align="center" colSpan={9}>
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              ) : (
                <TableBody>
                  {jobs
                    .map((row, index) => {
                      const odd = index % 2 === 0;
                      const labelId = `history-table-checkbox-${index}`;
                      const keyId = `key-${row.batchId}-${index}`;
                      row.odd = odd;
                      return (
                        <RowWrapper
                          key={keyId}
                          label={labelId}
                          viewOnly={props.viewOnly}
                          editOnly={props.editOnly}
                          row={row}
                          classes={classes}
                          db={db}
                          query=''
                          storage={storage}
                          rowDataLocalState={false}
                          formats={props.formats}
                          sizes={props.sizes}
                        />
                      );
                    })}
                </TableBody>
              )
            }
          </Table>
        </TableContainer>

      </Box>
    </>

  );

}

const condition = authUser => !!authUser;

export default withAuthorization(condition)(Runnning);

