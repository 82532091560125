import {
  collection,
  query,
  getDocs,
  orderBy,
  where,
  getDoc,
  or,
  doc,
  updateDoc,
  limit,
} from "firebase/firestore";
import { collections } from "../constants/defines";

export const getWebOrders = async (db) => {
  const date = new Date('2024-10-01T00:00:00Z');
  const orderInAsc = "asc";


  let queryConstraintsCustomNonStandard = [
    orderBy("created_at", orderInAsc),
    where("isPrinting", "==", false),
    where('created_at', '>=', date),
    where('isCanceled', '==', false),
    where('fulfill', '==', 'unfulfilled'),
    where('isApproved', '==', true),
  ];

  try {
    const queryCustomNonStandard = query(
      collection(db, collections.CUSTOM_PRODUCTS_QUEUE),
      ...queryConstraintsCustomNonStandard
    );

    // Fetch non-standard shipping orders in parallel
    const [snapshotCustomNonStandard] = await Promise.all([
      getDocs(queryCustomNonStandard),
    ]);

    // Collect non-standard orders
    const customNonStandardOrders = snapshotCustomNonStandard.docs.map(doc => doc.data());

    // Combine prioritized non-standard shipping orders and additional standard orders
    const customOrders = [...customNonStandardOrders];

    // Process each order
    const processedOrders = await Promise.all(customOrders.map(async (doc) => {
      let data = doc;
      let cntSocks = 0, cntUnderwear = 0;
      let numCustomItems = 0, numItems = 0;

      await Promise.all(data.items.map(async (_item) => {
        if (_item.error !== "") {
          await updatePnfAndDocument(db, _item, collections.CUSTOM_PRODUCTS_QUEUE);
        }

        if (_item.type === 'Underwear') cntUnderwear++;
        else cntSocks++;

        if (_item.isCustomProduct) {
          numCustomItems += parseInt(_item.quantity);
        }
        numItems += parseInt(_item.quantity);

        if (_item.sku.toLowerCase().includes('how many faces?')) {
          data.items = data.items.filter(item => item.sku !== _item.sku);
        }
      }));

      let type = 'multiple';
      if (cntUnderwear === data.items.length) type = 'underwear';
      else if (cntSocks === data.items.length) type = 'socks';

      let orderType = 'OTHER';
      if (numItems === 1 && numCustomItems === 1) orderType = 'SINGLE';
      else if (numItems === numCustomItems) orderType = 'MULTIPLE';

      return {
        ...data,
        orderType,
        numItems,
        numCustomItems,
        type,
      };
    }));
    const combinedOrders = [...processedOrders];

    combinedOrders.sort((a, b) => {
      if (a.restoreDate && !b.restoreDate) return -1;
      if (!a.restoreDate && b.restoreDate) return 1;
      return a.created_at - b.created_at;
    });

    return {
      orders: combinedOrders,
    };

  } catch (error) {
    console.error("Error fetching orders:", error);
  }
};

const updateItemAndDocument = async (db, item, foundItem, collectionRef) => {
  item.error = '';
  item.style = foundItem?.style || '';
  item.type = foundItem?.type || '';
  item.size = foundItem?.size || '';
  item.format = foundItem?.format || '';
  item.exists = true;
  await updateItemInDsOrders(db, item, collectionRef);
};

const updateItemInDsOrders = async (db, item, collectionRef) => {
  // iff this is not allowed: item?.reference.toString(), just return
  if (!item?.reference) {
    console.error('Item reference not found: ', item);
    return
  }

  const docRef = doc(collection(db, collectionRef), item?.reference.toString());

  // Fetch the document
  const docSnapshot = await getDoc(docRef);
  if (docSnapshot.exists()) {
    const data = docSnapshot.data();

    // Find the specific item in the 'items' array
    const items = data.items || [];
    const itemIndex = items.findIndex((i) => i.id === item.id);

    if (itemIndex !== -1) {
      // Update the specific item in the array
      items[itemIndex] = item
      // updating item sku : 
      console.log('updating item sku : ', item.sku);
      // Update the document with the modified 'items' array
      await updateDoc(docRef, { items });
    } else {
      console.error(`Item with id ${item.id} not found`);
    }
  } else {
    console.error('Document not found');
  }
};


const updatePnfAndDocument = async (db, item, collectionRef) => {
  // First, check in bundles collection
  const bundle = await findInCollection(db, collections.BOXSETS, item);
  if (bundle) {
    await updateItemAndDocument(db, item, bundle, collectionRef);
    return; // Return early if bundle is found
  }

  // If not found in bundles, check in products collection
  const product = await findInCollection(db, collections.PRODUCTS, item);
  if (product) {
    await updateItemAndDocument(db, item, product, collectionRef);
  }

  return null; // Return null if nothing is found in either collection
};

const findInCollection = async (db, collectionName, item) => {
  const q = query(
    collection(db, collectionName),
    or(
      where('variants', 'array-contains', item.sku),
      where('sku', '==', item.sku)
    )
  );

  const querySnapshot = await getDocs(q);

  // Return the first item found
  if (!querySnapshot.empty) {
    const doc = querySnapshot.docs[0]; // Get the first document
    return { id: doc.id, ...doc.data() };
  }

  return null; // Return null if nothing is found
};

