import { doc, getDoc } from "firebase/firestore";
import { API, collections } from "../constants/defines";
import { lookForAnyOrderByTrackDb } from "./lookForAnyOrderByTrack";

export const lookForAnyOrder = async (number, db) => {
  let orderId = number.trim();

  let isTrackingNumber = orderId.length > 14;

  if (isTrackingNumber) {
    // if everything has numbers, remove the first 8 characters
    if (/^\d+$/.test(orderId)) {
      console.log("Removing first 8 characters: ", orderId);
      orderId = orderId.slice(8);
    }

    const order = await lookForAnyOrderByTrackDb(orderId, db);

    if (!order) {
      try {
        const response = await fetch(API.getOrderInfoByTrack, {
          method: 'POST',
          body: JSON.stringify({ track: orderId })
        });
        const data = await response.json();
        orderId = data?.order ?? orderId;
      } catch (error) {
        console.error("Error searching for order in ShipStation:", error);
      }
    } else {
      return {
        found: true,
        data: order[0]
      }
    }
  }

  try {
    // Array of collections to search
    const collectionRefs = [
      collections.INLINE_PRODUCTS_QUEUE,
      collections.CUSTOM_PRODUCTS_QUEUE,
      collections.DS_INLINE_QUEUE,
      collections.DS_CUSTOM_QUEUE,
    ];

    // Map over the collection references to create queries
    const queries = collectionRefs.map(async (collectionRef) => {
      const docRef = doc(db, collectionRef, orderId);
      const result = await getDoc(docRef);

      if (result.exists()) {
        return {
          found: true,
          data: result.data(),
        };
      }
      return null; // Return null if not found
    });

    // Wait for all queries to complete
    const results = await Promise.all(queries);

    // Return the first found result or indicate not found
    const foundResult = results.find((result) => result !== null);
    return foundResult || { found: false };
  } catch (error) {
    console.error("Error searching for order:", error);
    throw error;
  }
};
