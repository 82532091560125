/**
 * Product Index
 * 
 * Admin page that shows a full list of all available products which
 * can be filtered by various attributes
 * 
 * @author Ray Dollete <ray@raydollete.com>
 */

// React Dependencies
import React, { useState, useEffect, useMemo } from 'react';
import { useTable } from 'react-table';
import { withAuthorization } from '../Session';
import _ from "lodash";

// Material UI dependencies
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Pagination from '@mui/material/Pagination';
import Typography from '@mui/material/Typography';

// Component Styling
import useStyles from './Bundles.styles';

// Component Layouts
import Layout from '../Page';
import PageHeader from './pageHeader';
import PageToolbar from './pageToolbar';
import { FilterProvider } from './filterContext';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

// Constants used for Filter Drop-downs
import {
  filterShows,
  filterTypes,
  filterStyles
} from '../Products/Products.constants'
import BundleModal from './bundleModal';
import { searchProducts } from '../../module/typesense';
import useGetBundleCsv from '../../hooks/useGetBundleCsv';

import { useDispatch, useSelector } from 'react-redux';
import { handleDataChangeRequest, handleOriginalDropdownData, handleOriginalSubDocs, hanldeChangeBundle, resetState } from '../../redux/bundles/actions';
import { listenToMockJobChange } from '../../db/listenToMockJobChange';
import { CircularProgress } from '@mui/material';
import { FILTERING_TYPES } from '../../redux/bundles/types';
import useGetAllBundles from '../../hooks/useGetBundles';
// component module
const Bundles = (props) => {

  const dispatch = useDispatch();

  const { bundles, currentPage, totalPages, rowsPerPage, totalBundles, allBundles } = useSelector(state => state.productBundles);

  const data = bundles;

  const classes = useStyles();

  const db = props.firebase.getdb();

  const [filterType, setFilterType] = useState(1);
  const [filterShow, setFilterShow] = useState(5);
  const [filterStyle, setFilterStyle] = useState(1);
  const [filterFormat, setFilterFormat] = useState(1);
  const [filterSize, setFilterSize] = useState(1);
  const [formats, setFormats] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [filterFormats, setFilterFormats] = useState([]);
  const [filterSizes, setFilterSizes] = useState([]);
  const [viewMode, setViewMode] = useState("list");
  const [openModal, setOpenModal] = useState(false);
  const [openCreateModal, setOpenCreateModal] = useState(false);

  // define table columns
  const columns = useMemo(
    () => [
      {
        Header: "SKU",
        accessor: "sku"
      },
      {
        Header: "Name",
        accessor: "name"
      },
      {
        Header: "Product",
        accessor: "type"
      },
      {
        Header: "Style",
        accessor: "style"
      },
      {
        Header: "Format",
        accessor: "stringFormat"
      },
      {
        Header: "Size",
        accessor: "stringSize"
      }
    ], []
  );

  // Use the useTable Hook to send the columns and data to build the table
  const {
    getTableProps, // table props from react-table
    getTableBodyProps, // table body props from react-table
    headerGroups, // headerGroups, if your table has groupings
    rows, // rows for the table based on the data passed
    prepareRow // Prepare the row (this function needs to be called for each row before getting the row props)
  } = useTable({
    columns,
    data
  });

  useEffect(() => {
    console.log('Product Page Formats: ', props.formats.length);
    fetchFormatData(props.formats);
  }, [props.formats]);

  useEffect(() => {
    //setSizes([...props.sizes]);
    fetchSizeData(props.sizes);
  }, [props.sizes]);

  const fetchFormatData = (items) => {
    const _filterFormats = [
      { id: 1, name: 'All', val: '' },
      { id: 2, name: 'None', val: '' },
    ];

    const formats = _.map(items, (item, index) => {
      return { id: index + 3, name: item.name, val: item.id };
    });
    const _formats = _filterFormats.concat(formats);
    setFilterFormats([..._formats]);
    setFormats([...items]);
  }

  const fetchSizeData = (items) => {

    const _filterFormats = [
      { id: 1, name: 'All', val: '' },
      { id: 2, name: 'None', val: '' },
    ];

    const formats = _.map(items, (item, index) => {
      return { id: index + 3, name: item.name, val: item.id };
    });
    const _formats = _filterFormats.concat(formats);

    setFilterSizes([..._formats]);

    setSizes([...items]);

  }

  const handleSearch = (event) => {

    // if user presses enter or clears the box, process the search
    if (event.keyCode === 13 || event.target.value.length === 0) {

      dispatch(handleDataChangeRequest(FILTERING_TYPES.SEARCH_KEY, event.target.value));

    }

  };

  const handleReset = () => {
    // reset state
    dispatch(resetState())
  }

  const handleChangePage = (event, newPage) => {
    dispatch(handleDataChangeRequest(FILTERING_TYPES.PAGE_NUMBER, newPage))
  };

  const handleChangeShow = (id) => {
    // use this to bind the drop-down
    setFilterShow(id);

    // need to get the value to update Algolia
    const selectedObject = _.find(filterShows, item => item.id == id);
    // dispatch to update the rows per page
    dispatch(handleDataChangeRequest(FILTERING_TYPES.ROWS_PER_PAGE, parseInt(selectedObject.name)))

  };

  const handleClick = (event, bundle) => {
    if (props.editOnly) {
      // no more local props to set.
      // sets the main bundle to look at.
      dispatch(hanldeChangeBundle(bundle))
      // handle when we want to show all of the avilable bundles.
      dispatch(handleOriginalDropdownData(bundle.dropDownData));
      //handle the original sub docs
      dispatch(handleOriginalSubDocs(bundle.subDocs));
      setOpenModal(true);
    }
  };

  const handleCloseDialog = () => {
    // reset state
    dispatch(hanldeChangeBundle({}));
    dispatch(handleOriginalDropdownData([]));
    dispatch(handleOriginalSubDocs([]));
    setOpenModal(false);
  }

  const handleCloseCreateDialog = () => {
    setOpenCreateModal(false);
  }

  const handleAddBundle = () => {
    setOpenCreateModal(true);
  }

  const onChangeView = (mode) => {
    console.log('Change View: ', mode);
    setViewMode(mode);
  }

  const renderListView = () => {
    return (
      <TableContainer sx={{ marginTop: '30px', marginBottom: '50px' }}>

        {
          allBundles.length === 0 ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '400px' }}>
              <CircularProgress />
            </Box>
          ) : (
            <Table
              className={classes.table}
              size={'medium'}
              {...getTableProps()}
            >
              <TableHead className={classes.tableHead}>
                {headerGroups.map(headerGroup => (
                  <TableRow {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                      <TableCell {...column.getHeaderProps()} className={classes.tableHeadCell}><Typography variant='tableText'>{column.render("Header")}</Typography></TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableHead>

              <TableBody {...getTableBodyProps()}>
                {rows.map((row, i) => {

                  prepareRow(row);

                  return (
                    <TableRow hover {...row.getRowProps()}
                      onClick={(event) => handleClick(event, row.original)}
                      classes={{
                        root: i % 2 === 1 ? classes.tableRow : classes.tableRow2, // class name, e.g. `root-x`
                        selected: classes.selectedTableRow, // class name, e.g. `disabled-x`
                      }}>
                      {row.cells.map(cell => {
                        return <TableCell {...cell.getCellProps()}><Typography variant='tableText'>{cell.render("Cell")}</Typography></TableCell>;
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>

          )
        }

      </TableContainer>
    );
  }

  return (
    <Layout page={props.page} user={props.user}>
      <PageHeader initial={totalBundles} onSearch={handleSearch} />

      {filterFormats.length > 0 && (
        <FilterProvider value={{
          types: filterTypes,
          styles: filterStyles,
          formats: filterFormats,
          sizes: filterSizes,
          shows: filterShows,
          filterType,
          setFilterType,
          filterShow,
          setFilterShow: handleChangeShow,
          filterStyle,
          setFilterStyle,
          filterFormat,
          setFilterFormat,
          filterSize,
          setFilterSize
        }}>
          <PageToolbar
            rowsPerPage={rowsPerPage}
            handleReset={handleReset}
            viewOnly={props.viewOnly}
            editOnly={props.editOnly}
            numSelected={0}
            handleNew={handleAddBundle}
            view={viewMode}
            onChangeView={onChangeView}
            sizes={props.sizes}
            formats={props.formats}
            dispatch={dispatch}
          />
        </FilterProvider>
      )}

      {renderListView()}

      <div className={classes.pagination}>
        <Pagination count={totalPages} page={currentPage} onChange={handleChangePage} showFirstButton showLastButton />
      </div>

      <BundleModal
        {...props}
        dispatch={dispatch}
        open={openModal}
        handleClose={handleCloseDialog}
      />

      <BundleModal
        {...props}
        dispatch={dispatch}
        isCreate
        open={openCreateModal}
        handleClose={handleCloseCreateDialog}
        item={null}
      />
    </Layout>
  );
};

const condition = authUser => !!authUser;

export default withAuthorization(condition)(Bundles);
