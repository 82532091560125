import { useFuzzySearch } from "../../utils/useFuzzySearchForBundles";
import { FILTERING_TYPES } from "./types"




export const handleFilters = (allBundles, requestType, data, previousFilteringData) => {
  // Initialize with the previous filtering data
  let currentlyFilteredData = allBundles;

  let previousFilteringUsed = { ...previousFilteringData };


  // Check if the request is to update search, reset the previously filtered data
  // Inside handleFilters function
  if (requestType === FILTERING_TYPES.SEARCH_KEY && data !== previousFilteringUsed.lastUsedSearchKey) {
    currentlyFilteredData = useFuzzySearch(data, [...allBundles], "bundle"); // Pass a copy of allBundles
    previousFilteringUsed.lastUsedSearchKey = data;
    previousFilteringUsed.currentPage = 1;
  } else if (previousFilteringUsed.lastUsedSearchKey !== "") {
    currentlyFilteredData = useFuzzySearch(previousFilteringUsed.lastUsedSearchKey, [...allBundles], 'bundle');
    previousFilteringUsed.currentPage = 1;
  }


  // Handle other filter types
  switch (requestType) {
    case FILTERING_TYPES.ROWS_PER_PAGE:
      previousFilteringUsed.rowsPerPage = data;
      break;
    case FILTERING_TYPES.PAGE_NUMBER:
      previousFilteringUsed.currentPage = data;
      break;
  }

  // Apply pagination after all other filters
  const startIndex = (previousFilteringUsed.currentPage - 1) * previousFilteringUsed.rowsPerPage;
  const endIndex = startIndex + previousFilteringUsed.rowsPerPage;
  let paginatedData = currentlyFilteredData.slice(startIndex, endIndex);
  previousFilteringUsed.totalPages = Math.ceil(currentlyFilteredData.length / previousFilteringUsed.rowsPerPage);
  const totalPageCount = previousFilteringUsed.totalPages;


  return {
    filteredData: paginatedData,
    filterState: previousFilteringUsed,
    totalPageCount,
  };
}
