import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import { withAuthorization } from '../Session';
import Layout from '../Page';
import PageHeader from './pageHeader';
import PageToolbar from './pageToolbar';
import {EnhancedTableHead} from '../Page/table';
import {getFormattedCropSettings, deleteCropSetting} from '../../db/cropSettings';
import { FilterProvider } from './filterContext';
import SettingModal from './settingModal';
import {ConfirmDialog} from '../Page/generalModal';

const filterShows = [
  {id: 150, name: '150'},
  {id: 100, name: '100'},
  {id: 200, name: '200'},  
];

const headCells = [
  { id: 'name', numeric: false, align: 'left', disablePadding: false, label: 'NAME', width: 'auto' },
  { id: 'edit', numeric: true, align: 'left', disablePadding: false, label: '', width: '120px'  },
  { id: 'delete', numeric: true, align: 'left', disablePadding: false, label: '', width: '100px' },  
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableHeadLabel: {
    fontSize: '14px', 
    fontWeight: '600', 
    color: '#000000',
    letterSpacing: '1.5px'
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  tableCell: {
    fontSize: '14px',
    fontWeight: '500',
    textTransform: 'uppercase',
    letterSpacing: '1.2px',
    color: '#000000'
  },
  tableCellError: {
    fontSize: '14px',
    fontWeight: '500',
    textTransform: 'uppercase',
    letterSpacing: '1.2px',
    color: 'red'
  },
  tableRow: {
    backgroundColor: 'rgba(242, 244, 246, 0.4)'
  },  
  tableRow2: {
    backgroundColor: '#FFFFFF'
  },
  tableRowError: {
    backgroundColor: 'rgba(255, 0, 0, 0.1)'
  },
  selectedTableRow: {
    backgroundColor: '#F1F7FE!important',
  },
  pagination: {
    marginTop: '60px',
    '& ul': {
      justifyContent: 'center'
    },
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  pageText: {
    color: '#000000',
    letterSpacing: '1.5px',
    fontSize: '14px',
    fontWeight: '500'
  },
  disabledButton: {
    backgroundColor: '#ccc!important',
    color: 'gray !important'
  },
  emptyText: {
    fontSize: '16px',
    fontWeight: '500',
    color: '#000000'
  },
  inputQty: {
    '& input': {
      borderRadius: '6px',
      border: '1px solid rgba(0,0,0,0.08)',
      padding: '10px 12px'
    }
  },
  drawerHeader: {
    position: 'relative',
    padding: '50px 50px 15px'
  },
  drawerBottom: {
    padding: '0px 50px 30px'
  },
  pageHeading: {
    borderBottom: '1px solid rgba(217, 223, 233, 0.4)'
  },
  sectionHeading: {
    fontSize: '16px',
    fontWeight: '500',
    color: '#000000',
    marginBottom: '15px'
  },
  inputBatchName: {
    color: 'inherit',
    background: 'white',
    '& input': {
      background: 'rgba(0,0,0,0.04)',
      padding: '11px 16px',
      width: '100%',
      borderRadius: '4px',
      border: 'none'
    }
  },
  batchButton: {
    fontSize: '16px',
    fontWeight: '500',
    textTransform: 'initial',
    color: '#FFFFFF',
    background: '#1F7CF2',
    borderRadius: '6px',
    width: '170px',    
  },
  disabledBatchButton: {
    opacity: '0.48',
    backgroundColor: '#1F7CF2 !important',
    color: '#FFFFFF!important',
  },
  updateButton: {
    color: '#FFFFFF',
    textTransform: 'initial',
    width: '110px'
  },
  removeButton: {

  }
}));

function CropSetting(props) {  
  const [selected, setSelected] = useState("");
  const [loading, setLoading] = useState(true);
  const [settings, setSettings] = useState([]);  
  const [formats, setFormats] = useState([]);  
  const [sizes, setSizes] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [filterShow, setFilterShow] = useState(150);
  const [openModal, setOpenModal] = useState(false);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const db = props.firebase.getdb();
  const permission = props.user.permission;
  
  useEffect(() => {    
    getFormattedCropSettings(db, filterShow, fetchData);
  }, []);

  useEffect(() => {
    console.log('CropSetting Page Formats: ', props.formats.length);
    fetchFormats(props.formats);
  },[props.formats]);

  useEffect(() => {
    setSizes([...props.sizes]);
  },[props.sizes]);
  
  const fetchData = (items) => {
    console.log('Fetch CropSettings Data');
    setSettings([...items]);
    setLoading(false);
  };

  const fetchFormats = (items) => {
    console.log('Fetch Formats Data');
    setFormats([...items]);
  }

  const handleCloseDialog = () => {
    setOpenModal(false);
  }

  const handleCloseCreateDialog = () => {
    setOpenCreateModal(false);
  }

  const handleClick = (event, name) => {    
    setSelected(name);
  };

  const handelEdit = (event, name) => {
    setSelected(name);
    setOpenModal(true);
  }

  const isSelected = (name) => selected === name;

  const handleAdd = () => {
    setOpenCreateModal(true);
  }

  const handleCloseRemoveDialog = () => {
    setOpenDeleteModal(false);
  }

  const handleDelete = (event) => {
    const _selected = _.find(settings, row => isSelected(row.name));  
    setOpenDeleteModal(false);
    setSelected("");
    deleteCropSetting(db, _selected);    
  }

  const renderRemoveDialog = () => {
    const _selected = _.find(settings, row => isSelected(row.name));  
    return (
      <ConfirmDialog 
        label="size"
        title="Remove CropSetting"
        open={openDeleteModal}
        onClose={handleCloseRemoveDialog}
        onConfirm={handleDelete}
      >
        <Typography variant='body2' gutterBottom>
          Do you want to remove <strong>{_selected.name}</strong> ?
        </Typography>  
      </ConfirmDialog>
    );
  }

  const classes = useStyles();
  const _settings = _.filter(settings, item => {
    if (searchText === "")
      return true;
    if (item.name.toLowerCase().includes(searchText))
      return true;
    return false;
  });

  const selectedSetting = _.find(_settings, row => isSelected(row.name));  

  return (
    <div>
      <PageHeader total={settings.length} setSearchText={setSearchText} searchText={searchText}/>

        <FilterProvider value={{
                            shows: filterShows,
                            filterShow,
                            setFilterShow
                            }}>
        <PageToolbar numSelected={0} viewOnly={props.viewOnly} editOnly={props.editOnly} handleCreate={handleAdd}/>
      </FilterProvider>

      <TableContainer sx={{marginTop: '30px', marginBottom: '50px'}}>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size={'medium'}
          aria-label="enhanced table"            
        >
          <EnhancedTableHead classes={classes} useCheckbox={false} cells={headCells} />
          <TableBody>
            {_settings
              .map((row, index) => {

                const isItemSelected = isSelected(row.name);                
                const keyId = `key-${row.name}`;
                const odd = index%2 === 0;
                return (
                  <TableRow
                    hover                                        
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={keyId}                      
                    classes={{
                      root: odd?classes.tableRow:classes.tableRow2,
                      selected: classes.selectedTableRow, 
                    }}
                    selected={isItemSelected} 
                    onClick={(event) => handleClick(event, row.name)}                       
                  >
                    <TableCell align="left"><Typography variant='tableText'>{row.name}</Typography></TableCell>
                    <TableCell align="right" className={classes.tableCell}>
                      {props.editOnly && (
                      <Button
                        variant="blue"
                        sx={{width:'110px'}}
                        onClick={() => handelEdit(row.name)}         
                      >
                        Edit
                      </Button>
                      )}
                    </TableCell>
                    <TableCell align="center" className={classes.tableCell}>
                      {props.editOnly && (
                      <Button variant='' onClick={() => setOpenDeleteModal(true)}>
                        <DeleteOutlineIcon />
                      </Button>
                      )}
                    </TableCell>
                  </TableRow>                  
                );
              })}

              {loading && (
                <TableRow>
                  <TableCell align="center" colSpan={3}>
                  <CircularProgress />  
                  </TableCell>
                </TableRow>
              )}

              {!loading && _settings.length === 0 && (
                <TableRow>
                  <TableCell align="center" className={classes.tableCell} colSpan={3}>
                    <Typography variant='tableText'>No Settings</Typography>
                  </TableCell>
                </TableRow>
              )}
            
          </TableBody>
        </Table>
      </TableContainer> 
      
      
      <SettingModal 
        open={openModal}         
        handleClose={handleCloseDialog} 
        modal_name="Edit Crop Setting" 
        item={selectedSetting} 
      />
      
      <SettingModal 
        isCreate 
        open={openCreateModal}         
        modal_name="New Crop Setting" 
        handleClose={handleCloseCreateDialog} 
        item={null} 
      />
      
      {selected && renderRemoveDialog()}
    </div>
  );
}

const condition = authUser => !!authUser;
export default withAuthorization(condition)(CropSetting);

