/**
 * Product Index
 * 
 * Admin page that shows a full list of all available products which
 * can be filtered by various attributes
 * 
 * @author Ray Dollete <ray@raydollete.com>
 */

// React Dependencies
import React, { useState, useEffect, useMemo } from 'react';
import { useTable } from 'react-table';
import { withAuthorization } from '../Session';
import _ from "lodash";
import { debounce } from 'lodash';

// Material UI dependencies
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Pagination from '@mui/material/Pagination';
import Typography from '@mui/material/Typography';

// Component Styling
import useStyles from './Products.styles';

// Component Layouts
import Layout from '../Page';
import PageHeader from './pageHeader';
import PageToolbar from './pageToolbar';
import { FilterProvider } from '../../context/filterContext';

// DB helper utils
import { exportProducts } from '../../db/products';
import { getTemplates } from '../../db/templates';
import { getTags } from '../../db/tags';
import ProductModal from './productModal';
import UploadModal from './uploadModal';
import UploadProductModal from './uploadProductModal';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import ProductGridItem from './productGridItem';
import { searchProducts } from '../../module/typesense';

// Constants used for Filter Drop-downs
import {
  filterShows,
  filterTypes,
  filterStyles,
  _filterTemplates
} from './Products.constants'
import { sortSizes } from '../../utils/sortSizes';

// component module
const Products = (props) => {
  const [selected, setSelected] = useState("");
  const [filterType, setFilterType] = useState(1);
  const [filterShow, setFilterShow] = useState(5);
  const [filterStyle, setFilterStyle] = useState(1);
  const [filterFormat, setFilterFormat] = useState(1);
  const [filterSize, setFilterSize] = useState(1);
  const [filterTemplate, setFilterTemplate] = useState("All");

  const classes = useStyles();
  const db = props.firebase.getdb();
  const storage = props.firebase.getstorage();
  const [data, setData] = useState([]);
  const [productCount, setProductCount] = useState(0);
  const [templates, setTemplates] = useState([]);
  const [formats, setFormats] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [tags, setTags] = useState([]);
  const [cropSettings, setCropSettings] = useState([]);
  const [filterTemplates, setFilterTemplates] = useState([]);
  const [filterFormats, setFilterFormats] = useState([]);
  const [filterSizes, setFilterSizes] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [textSearch, setTextSearch] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openBulkModal, setOpenBulkModal] = useState(false);
  const [openUploadProductModal, setOpenUploadProductModal] = useState(false);

  const [updates, setUpdates] = useState(0);
  const [exporting, setExporting] = useState(false);
  const [viewMode, setViewMode] = useState("list");

  const [options, setOptions] = useState({
    'textSearch': textSearch,
    'rowsPerPage': rowsPerPage,
    'pageNumber': pageNumber,
    'filters': ''
  });

  // define table columns
  const columns = useMemo(
    () => [
      {
        Header: "SKU",
        accessor: "sku"
      },
      {
        Header: "Name",
        accessor: "name"
      },
      {
        Header: "Product",
        accessor: "type"
      },
      {
        Header: "Style",
        accessor: "style"
      },
      {
        Header: "Format",
        accessor: "format"
      },
      {
        Header: "Size",
        accessor: "size"
      }
    ], []
  );

  // Use the useTable Hook to send the columns and data to build the table
  const {
    getTableProps, // table props from react-table
    getTableBodyProps, // table body props from react-table
    headerGroups, // headerGroups, if your table has groupings
    rows, // rows for the table based on the data passed
    prepareRow // Prepare the row (this function needs to be called for each row before getting the row props)
  } = useTable({
    columns,
    data
  });

  useEffect(() => {
    getTemplates(db, 150, fetchTemplatesData);
    getTags(db, 100, fetchTagsData);
  }, []);

  useEffect(() => {
    fetchFormatData(props.formats);
  }, [props.formats]);

  useEffect(() => {
    //setSizes([...props.sizes]);
    fetchSizeData(props.sizes);
  }, [props.sizes]);

  useEffect(() => {
    fetchCropSettings(props.cropSettings);
  }, [props.cropSettings]);

  const fetchTemplatesData = (items) => {
    var _templates = _filterTemplates.concat(items);
    setFilterTemplates([..._templates]);

    setTemplates([...items]);
  };

  const fetchTagsData = (items) => {
    setTags([...items]);
  };

  const fetchCropSettings = (items) => {
    setCropSettings([...items]);
  };

  const fetchFormatData = (items) => {
    const _filterFormats = [
      { id: 1, name: 'All', val: '' },
      { id: 2, name: 'None', val: '' },
    ];

    const formats = _.map(items, (item, index) => {
      return { id: index + 3, name: item.name, val: item.id };
    });
    const _formats = _filterFormats.concat(formats);
    setFilterFormats([..._formats]);
    setFormats([...items]);
  }

  const fetchSizeData = (items) => {

    const sortedSizes = sortSizes(items);

    const _filterFormats = [
      { id: 2, name: 'None', val: '' },
      { id: 1, name: 'All', val: '' },
    ];

    const formats = _.map(sortedSizes, (item, index) => {
      return { id: index + 3, name: item.name, val: item.id };
    });

    const _formats = _filterFormats.concat(formats);
    setFilterSizes([..._formats]);
    setSizes([...items]);
  }

  const debouncedSearch = debounce((value) => {
    searchProducts(options, fetchData, true, db, sizes, formats, true);
  }, 1000);

  // on mount/update query the products for the current page
  useEffect(() => {
    //console.log('Options: ', options);   
    debouncedSearch();
  }, [options]);

  // update options
  useEffect(() => {

    const oldFilters = options.filters;

    let filterArray = [];
    filterArray = processFilter(filterTypes, filterType, 'type', filterArray);
    filterArray = processFilter(filterStyles, filterStyle, 'style', filterArray);
    filterArray = processFilter(filterFormats, filterFormat, 'format', filterArray);
    filterArray = processFilter(filterSizes, filterSize, 'size', filterArray);
    filterArray = processTemplateFilter(filterTemplate, 'templates', filterArray);
    //filterArray = processFilter(filterTemplates, filterTemplate, 'template', filterArray);

    filterArray.push('isBoxset:=false');

    // if filters changed, reset page back to 1
    const newFilters = filterArray.join(' && ');

    setOptions({
      'textSearch': textSearch,
      'rowsPerPage': rowsPerPage,
      'pageNumber': (oldFilters === newFilters) ? pageNumber : 1,
      filters: newFilters
    });
  }, [pageNumber, textSearch, filterType, filterStyle, filterFormat, filterSize, filterTemplate, rowsPerPage, updates]);


  // process selected filter into string for Algolia query
  const processTemplateFilter = (itemId, attributeName, filterArray) => {
    // ignore if filter value is 1 (should refer to 'All')
    if (itemId !== "All") {

      let filterString = '';

      if (itemId === 'None') {
        filterString = 'hasTemplate:=false';
      }
      else {
        // TODO: handle actual tempalte filters in item.name
        filterString = `${attributeName}:=["${itemId}"]`;
      }

      filterArray.push(filterString);
    }
    return filterArray;
  }

  const processFilter = (collection, itemId, attributeName, filterArray) => {

    // ignore if filter value is 1 (should refer to 'All')
    if (itemId !== 1) {
      let filterString = '';
      const selectedFilter = _.find(collection, item => item.id == itemId);
      filterString = `${attributeName}:="${selectedFilter.name}"`;
      filterArray.push(filterString);
    }
    return filterArray;
  };


  // callback for getProducts
  const fetchData = (products, productsMetadata) => {
    setData([...products]);
    setProductCount(productsMetadata.productCount);
    setTotalPages(productsMetadata.pages);
  };

  const handleSearch = (event) => {

    // if user presses enter or clears the box, process the search
    if (event.keyCode === 13 || event.target.value.length === 0) {
      setPageNumber(1);
      setTextSearch(event.target.value);
    }

  };

  const handleChangePage = (event, newPage) => {
    setPageNumber(newPage);
  };

  const handleChangeShow = (id) => {
    // use this to bind the drop-down
    setFilterShow(id);

    // need to get the value to update Algolia
    const selectedObject = _.find(filterShows, item => item.id == id);
    setRowsPerPage(selectedObject.name);

  };

  const handleClick = (event, name) => {
    if (props.viewOnly || props.editOnly) {
      setSelected(name);
      setOpenModal(true);
    }
  };

  const handleCloseDialog = () => {
    setOpenModal(false);

    setTimeout(() => {
      setUpdates(updates + 1);
    }, 5000);
  }

  const handleCloseCreateDialog = () => {
    setOpenCreateModal(false);
    setTimeout(() => {
      setUpdates(updates + 1);
    }, 5000);

  }

  const handleCloseBulkDialog = () => {
    setOpenBulkModal(false);

    setTimeout(() => {
      setUpdates(updates + 1);
    }, 5000);

  }

  const handleCloseUploadArtDialog = () => {
    setOpenUploadProductModal(false);
  }

  const handleAddProduct = () => {
    setOpenCreateModal(true);
  }

  const handleUpload = () => {
    setOpenBulkModal(true);
  }

  const handleUploadArt = () => {
    console.log('handleUploadArt');
    setOpenUploadProductModal(true);
  }

  const handleExport = () => {

    if (templates.length > 0) {
      const type = _.find(filterTypes, { id: filterType }).name;
      const style = _.find(filterStyles, { id: filterStyle }).name;
      const format = _.find(filterFormats, { id: filterFormat }).val;
      const size = _.find(filterSizes, { id: filterSize }).val;
      setExporting(true);
      exportProducts(db, { type, style, format, size, template: filterTemplate }, sizes, formats, templates, handleCompleteExport);
    }
  }

  const handleCompleteExport = (count) => {
    setExporting(false);
  }

  const onChangeView = (mode) => {
    console.log('Change View: ', mode);
    setViewMode(mode);
  }

  // list of items to render in PRODUCTS PAGE
  const renderListView = () => {
    return (
      <TableContainer sx={{ marginTop: '30px', marginBottom: '50px' }}>
        <Table
          className={classes.table}
          size={'medium'}
          {...getTableProps()}
        >
          <TableHead className={classes.tableHead}>
            {headerGroups.map(headerGroup => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <TableCell {...column.getHeaderProps()} className={classes.tableHeadCell}><Typography variant='tableText'>{column.render("Header")}</Typography></TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>

          <TableBody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <TableRow hover {...row.getRowProps()}
                  onClick={(event) => handleClick(event, row.values.sku)}
                  classes={{
                    root: i % 2 === 1 ? classes.tableRow : classes.tableRow2, // class name, e.g. `root-x`
                    selected: classes.selectedTableRow, // class name, e.g. `disabled-x`
                  }}>
                  {row.cells.map(cell => {
                    return <TableCell {...cell.getCellProps()}><Typography variant='tableText'>{cell.render("Cell")}</Typography></TableCell>;
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  const renderGridView = () => {
    return (
      <Box>
        <Grid container spacing={3}>
          {rows.map((row, i) => {
            return (
              <Grid item xs={3}>
                <ProductGridItem
                  key={`GridItem_${row.values.sku}`}
                  db={db}
                  data={row.values}
                  settings={cropSettings}
                  templates={templates}
                  handleClick={handleClick}
                  storage={storage}
                />
              </Grid>
            );
          })}
        </Grid>

      </Box>
    );
  }

  const renderProduct = () => {
    return viewMode === "list" ? renderListView() : renderGridView();
  }

  return (
    <Layout page={props.page} user={props.user}>

      <PageHeader
        viewOnly={props.viewOnly}
        editOnly={props.editOnly}
        initial={productCount}
        onSearch={handleSearch}
        onUpload={handleUpload}
        onUploadArt={handleUploadArt}
        onExport={handleExport}
        exporting={exporting}
        db={db}
      />

      {templates.length > 0 && filterFormats.length > 0 && (
        // state management for filter context
        <FilterProvider
          value={{
            types: filterTypes,
            styles: filterStyles,
            formats: filterFormats,
            sizes: filterSizes,
            templates: filterTemplates,
            shows: filterShows,
            filterType,
            setFilterType,
            filterShow,
            setFilterShow: handleChangeShow,
            filterStyle,
            setFilterStyle,
            filterFormat,
            setFilterFormat,
            filterSize,
            setFilterSize,
            filterTemplate,
            setFilterTemplate
          }}
        >
          <PageToolbar
            numSelected={0}
            viewOnly={props.viewOnly}
            editOnly={props.editOnly}
            handleNew={handleAddProduct}
            view={viewMode}
            onChangeView={onChangeView}
            sizes={props.sizes}
            formats={props.formats}
          />
        </FilterProvider>
      )}

      {templates.length > 0 && cropSettings.length > 0 && renderProduct()}

      <div className={classes.pagination}>
        <Pagination count={totalPages} page={pageNumber} onChange={handleChangePage} showFirstButton showLastButton />
      </div>

      {selected && templates.length > 0 && tags.length > 0 && cropSettings.length > 0 && formats.length > 0 && (
        <ProductModal
          {...props}
          key="Product_Modal_Edit"
          isCreate={false}
          viewOnly={props.viewProduct}
          editOnly={(props.editProduct) && (props.editOnly || props.viewOnly)}
          open={openModal}
          addingProduct={false}
          formats={formats}
          templates={templates}
          tags={tags}
          cropSettings={cropSettings}
          handleClose={handleCloseDialog}
          sku={selected}
        />
      )}

      {templates.length > 0 && tags.length > 0 && cropSettings.length > 0 && formats.length > 0 && (
        // ! for add product
        <ProductModal
          {...props}
          key="Product_Modal_Create"
          isCreate={true}
          viewOnly={props.viewProduct}
          editOnly={props.editProduct}
          open={openCreateModal}
          addingProduct={true}
          formats={formats}
          templates={templates}
          tags={tags}
          cropSettings={cropSettings}
          handleClose={handleCloseCreateDialog}
          sku=""
        />
      )}

      {templates.length > 0 && formats.length > 0 && (<UploadModal key="Upload_Modal1" open={openBulkModal} templates={templates} formats={formats} onClose={handleCloseBulkDialog} />)}

      {templates.length > 0 && cropSettings.length > 0 && tags.length > 0 && formats.length > 0 &&
        (<UploadProductModal
          key="Upload_Modal2"
          open={openUploadProductModal}
          templates={templates}
          formats={formats}
          sizes={sizes}
          tags={tags}
          cropSettings={cropSettings}
          onClose={handleCloseUploadArtDialog}
        />)}

    </Layout>
  );
};

const condition = authUser => !!authUser;

export default withAuthorization(condition)(Products);

