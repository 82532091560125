import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import Typography from '@mui/material/Typography';
import { withAuthorization } from '../Session';

const useHeaderStyles = makeStyles((theme) => ({
      search: {
        width: '470px',
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: 'rgba(255, 255, 255, 0.04)',
        '&:hover': {
          backgroundColor: 'rgba(255, 255, 255, 0.08)',
        },    
        marginLeft: 0,
      },
      searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        right: '0px'
      },
      inputRoot: {
        color: 'inherit',
      },
      inputInput: {
        padding: '10px 16px',
        paddingRight: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
      },
      headerAction: {
        width: '680px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
      },
      headerButton: {
        width: '160px',
        marginLeft: '15px'
      },
      headerText: {
        fontSize: '14px',
        fontWeight: '500',
        letterSpacing: '1.5px',
        color: '#000000',
        opacity: '0.4'
      },
      searchText: {
        fontSize: '14px',
        fontWeight: '500',
        color: 'rgba(0,0,0, 0.56)',
        textTransform: 'uppercase'
      }
  }));

function PageHeader(props) {
  const {handleSelect, tags} = props;
  // const [key, setKey] = useState("");  
  const db = props.firebase.getdb();

  const handleChangeKey = (event) => {
    props.setSearchText(event.target.value.toLowerCase());
  }

  const handleKeyDown = (event) => {    
    if (event.keyCode === 13) {      
      // getTag(db, key, handleSelect);
      props.setSearchText("");
    }    
  }

    const classes = useHeaderStyles();
    
    return (
        <div className="pageHeader">
          <div className={classes.pageHeading}>
            <Typography variant="h1">Tags</Typography> 
            <Typography className={classes.searchText}><strong>{tags}</strong> Total Tags</Typography>   
          </div>

          <div className={classes.headerAction}>
            <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  placeholder="Search for tags"
                  sx={{ width: '100%' }}
                  classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput,
                  }}    
                  defaultValue={props.searchText}
                  onChange={handleChangeKey}
                  onKeyDown={handleKeyDown}
                  inputProps={{ 'aria-label': 'search' }}       
                />
            </div>
          </div>              
        </div>
    );
}


const condition = authUser => !!authUser;
export default withAuthorization(condition)(PageHeader);
