import React from 'react';
import Typography from '@mui/material/Typography';
import Layout from '../Page';

const Permissions = (props) => (
  <Layout page={props.page} user={props.user}>
    <Typography sx={{textAlign: 'center'}}>No permission for this page</Typography>   
  </Layout>
);


export default Permissions;

