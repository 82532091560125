import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Pagination from '@mui/material/Pagination';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { EnhancedTableHead, getComparator, stableSort } from '../Page/table';
import { getQBOrderQueue, addToProduction, markOrdersAsDelete } from '../../db/quickbooks';
import { ConfirmDialog } from '../Page/generalModal';
import { getTemplates } from '../../db/templates';
import Row from './row';
import FilterDropdown from '../Page/filterDropdown';
import _ from 'lodash';

const headCells = [
  { id: 'indentifier', numeric: false, align: 'left', enableSort: false, disablePadding: true, label: '', width: '30px' },
  { id: 'txnDate', numeric: false, align: 'center', enableSort: true, disablePadding: true, label: 'Order Date', width: '140px' },
  { id: 'customer', numeric: true, align: 'center', enableSort: true, disablePadding: false, label: 'Customer', width: '200px' },
  { id: 'poNumber', numeric: true, align: 'center', enableSort: true, disablePadding: false, label: 'PO#', width: '100px' },
  { id: 'docNumber', numeric: true, align: 'center', enableSort: true, disablePadding: false, label: 'Estimate #', width: '100px' },
  { id: 'shipDate', numeric: false, align: 'center', enableSort: true, disablePadding: false, label: 'Ship Date', width: '140px' },
  { id: 'cancelDate', numeric: false, align: 'center', enableSort: true, disablePadding: false, label: 'Cancel Date', width: '140px' },
  { id: 'daysleft', numeric: true, align: 'center', enableSort: true, disablePadding: false, label: 'Days left', width: '50px' },
  { id: 'units', numeric: true, align: 'center', enableSort: true, disablePadding: false, label: 'Units', width: '50px' },
  { id: 'priority', numeric: false, align: 'center', enableSort: true, disablePadding: false, label: 'Priority', width: '50px' },
];

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  table: {
    minWidth: 750,
  },
  tableHead: {
    background: 'rgba(217, 223, 233, 0.4)'
  },
  tableHeadCell: {
    lineHeight: '27.5px'
  },
  tableHeadLabel: {
    fontSize: '14px',
    fontWeight: '500',
    textTransform: 'uppercase',
    color: '#000000',
    letterSpacing: '1.5px'
  },
  tableCheckbox: {
  },
  tableCell: {
    fontSize: '14px',
    fontWeight: '500',
    textTransform: 'initial',
    letterSpacing: '1.2px',
    color: '#000000'
  },
  tableCell1: {
    fontSize: '14px',
    fontWeight: '500',
    textTransform: 'capitalize',
    letterSpacing: '1.2px',
    color: '#000000'
  },
  tableCell2: {
    fontSize: '14px',
    fontWeight: '500',
    textTransform: 'uppercase',
    letterSpacing: '1.2px',
    color: '#FFFFFF',
    display: 'inline-block',
    background: '#05aa1d',
    padding: '5px 12px',
    borderRadius: '5px'
  },
  tableCellError: {
    fontSize: '14px',
    fontWeight: '500',
    textTransform: 'uppercase',
    letterSpacing: '1.2px',
    color: 'red'
  },
  tableError: {
    fontSize: '14px',
    fontWeight: '500',
    letterSpacing: '1.2px',
    color: 'red'
  },
  tableError1: {
    paddingLeft: '10px'
  },
  tableRow: {
    height: '70px',
    backgroundColor: 'rgba(242, 244, 246, 0.4)',
    '&:hover': {
      backgroundColor: '#F1F7FE!important',
    }
  },
  tableRow2: {
    height: '70px',
    backgroundColor: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#F1F7FE!important',
    }
  },
  selectedTableRow: {
    backgroundColor: '#F1F7FE!important',
  },
  pagination: {
    marginTop: '60px',
    '& ul': {
      justifyContent: 'center'
    },
  },
  button: {
    padding: '9px 32px',
    fontSize: '16px',
    fontWeight: '500',
    color: '#ffffff',
    textTransform: 'initial',
    marginRight: '16px',
    background: '#1f7cf2'
  },
  formLabel: {
    color: '#000000',
    fontSize: '14px',
    fontWeight: '500',
    letterSpacing: '1.5px'
  },
  toolbar: {
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  search: {
    width: '470px',
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: 'rgba(255, 255, 255, 0.04)',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.08)',
    },
    marginLeft: 'auto',
    marginTop: '30px'
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    right: '0px'
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: '10px 16px',
    paddingRight: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
  },

}));

const filterShows = [
  { id: 1, name: '50', value: 50 },
  { id: 2, name: '100', value: 100 },
  { id: 3, name: '200', value: 200 },
  { id: 4, name: '500', value: 500 },
];

import { useSelector, useDispatch } from 'react-redux';
import Loading from '../Loading';
import { calculateManualEntryBatch, calculateWholesale } from '../../utils/calculateOrderItems';
import { OrderSummary } from '../CustomOrders/pageHeaderHelperOrders';
import { palette } from '../../theme/colors';
import { Box } from '@mui/material';

function OrderQueue(props) {

  const dispatch = useDispatch();

  const { wholeSaleOrders, isLoading } = useSelector(state => state.wholeSaleOrders);

  const orders = _.cloneDeep(wholeSaleOrders);

  const [filterShow, setFilterShow] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const [searchKey, setSearchKey] = useState("");
  const [selection, setSelection] = useState({});
  const [templates, setTemplates] = useState([]);

  const classes = useStyles();
  const db = props.firebase.getdb();
  const storage = props.firebase.getstorage();

  useEffect(() => {
    getTemplates(db, 50, fetchTemplates);
  }, []);

  useEffect(() => {
    setSearchKey(props.searchKey);
  }, [props.searchKey]);

  const fetchTemplates = (items) => {
    setTemplates([...items]);
  };

  const renderPageHeader = () => {
    return (
      <div className="pageHeader" sx={{ paddingBottom: '15px' }}>
        <div className="pageHeading">
          <Typography variant="h1">Wholesale Order Queue</Typography>
        </div>
      </div>
    );
  }

  const renderPageToolbar = () => {
    // Function to filter items within an order based on status
    const filterItemsByStatus = (items) => {
      return _.filter(items, item => item.status === 0 || item.status === undefined);
    };

    // Filter orders to only include items with a status of 0 or undefined before any further processing
    const filteredOrders = _.map(orders, order => {
      return { ...order, items: filterItemsByStatus(order.items) };
    });

    // Now work with filteredOrders for selection and calculations
    let selectedOrders = [];

    const _selected = _.filter(filteredOrders, order => isSelection(order.order));

    selectedOrders = _.map(_selected, orderItem => {
      const itemSelection = selection[orderItem.order] ? selection[orderItem.order] : "";
      const strIds = itemSelection.split(",");
      // Given the orders have been pre-filtered, only filter items by ID here
      const items = _.filter(orderItem.items, item => strIds.indexOf(item.id) !== -1);
      return { ...orderItem, items };
    });

    const numSelected = selectedOrders.length;

    // Calculate wholesale information based on the selected orders
    const info = calculateWholesale(selectedOrders, true);

    // Calculate initial totals based on all eligible orders before selection
    const initial = calculateWholesale(filteredOrders, true);

    return (
      <div className='pageToolbar'>
        <div className={classes.buttonBox}>

          <div
            style={{
              paddingBottom: '80px',
            }}
          >
            <OrderSummary title={"Total"} palette={palette} counter={initial} hideOrdersString={true} hideBundleCount={true} />
            <OrderSummary title={"Selected"} palette={palette} counter={info} hideOrdersString={true} hideBundleCount={true} />
          </div>

          <FormControl component="fieldset" sx={{ marginRight: '40px', height: '46px', width: '165px' }}>
            <FormGroup aria-label="position" row>
              <FormControlLabel
                className={classes.formLabel}
                control={
                  <Checkbox color="primary"
                    indeterminate={numSelected > 0 && !isSelectedAll}
                    checked={numSelected > 0 && isSelectedAll}
                    onChange={handleSelectAllClick}
                  />}
                label={numSelected > 0 && isSelectedAll ? "SELECT NONE" : "SELECT ALL"}
                labelPlacement="end"
              />
            </FormGroup>
          </FormControl>

          {props.editOnly && numSelected > 0 && (
            <Button
              variant="blue"
              sx={{ width: '180px', marginRight: '15px' }}
              disabled={numSelected == 0}
              onClick={onAddPrint}>Add Selected</Button>
          )}

          {props.editOnly && numSelected > 0 && (
            <Button
              variant="black"
              sx={{ width: '160px' }}
              disabled={numSelected == 0}
              onClick={onClickDelete}>Delete</Button>
          )}
        </div>

        <div className={classes.filterBox}>
          <FilterDropdown title="Show" items={filterShows} active={filterShow} onSelect={handleChangeRowsPerPage} />
        </div>
      </div>
    );

  }

  const renderTable = () => {
    return (
      <DndProvider backend={HTML5Backend}>

        <TableContainer sx={{ marginTop: '30px', marginBottom: '50px' }}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              useCheckbox={true}
              cells={headCells}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            {
              isLoading ? (
                <TableRow>
                  <TableCell align="center" colSpan={9}>
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              ) : (
                <TableBody>
                  {
                    currentOrders
                      .map((row, index) => {
                        const odd = index % 2 === 0;
                        const labelId = `wholesale-order-${row.order}-${Math.random()}`;
                        const itemSelection = !!selection[row.order] ? selection[row.order] : "";
                        row.odd = odd;

                        return (
                          <Row
                            {...props}
                            storage={storage}
                            key={labelId}
                            label={labelId}
                            index={index}
                            production={false}
                            dragDrop={false}
                            row={row}
                            templates={templates}
                            selection={itemSelection}
                            classes={classes}
                            onSelect={handleSelectRow}
                          />
                        );
                      })
                  }
                </TableBody>
              )
            }
          </Table>
        </TableContainer>

      </DndProvider>
    );
  }

  const onAddPrint = () => {
    addToProduction(db, selected, dispatch);
    setSelection({});
  }

  const onClickDelete = () => {
    console.log('Clicked Delete Button');
    setDeleteDialog(true);
  }

  const handleDelete = () => {
    markOrdersAsDelete(db, selected, dispatch, false);
    setSelection({});
    setDeleteDialog(false);
  }

  const handleCloseDeleteDialog = () => {
    setDeleteDialog(false);
  }

  const renderDeleteDialog = () => {
    return (
      <ConfirmDialog
        label="delete"
        title="Delete"
        open={deleteDialog}
        onClose={handleCloseDeleteDialog}
        onConfirm={handleDelete}
      >
        <Typography gutterBottom>
          Are you sure you want to delete selected orders?
        </Typography>
      </ConfirmDialog>
    );
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      let _selection = {};
      _.each(currentOrders, orderItem => {
        const orderItems = _.filter(orderItem.items, item => item.status == 0);
        const ids = _.map(orderItems, item => item.id);
        const strIds = ids.join();
        _selection[orderItem.order] = strIds;
      })
      setSelection({ ..._selection });
      return;
    }
    setSelection({});
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
  }

  const handleChangeRowsPerPage = (shows) => {
    setFilterShow(shows);
    setPage(0);
  };

  const handleSelectRow = (name) => {
    console.log('Select Row: ', name);

    const _selection = { ...selection };
    if (!_selection[name]) {
      console.log('No Selection');

      const orderItem = _.find(orders, { order: name });
      const orderItems = _.filter(orderItem.items, item => item.status < 2);
      const ids = _.map(orderItems, item => item.id);
      const strIds = ids.join();
      _selection[name] = strIds;
    } else {
      console.log('Selection');
      delete _selection[name];
    }
    setSelection({ ..._selection });
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const filterOrders = () => {
    const key = searchKey.toLowerCase();
    if (key === "")
      return orders;
    const items = _.filter(orders, item => {
      return item.poNumber.toLowerCase().includes(key) ||
        item.customer.toLowerCase().includes(key) ||
        item.docNumber.toLowerCase().includes(key);
    });
    return items;
  }

  const isSelection = (name) => !!selection[name];
  let selected = _.map(selection, (item, key) => key);

  let rowsPerPage = _.find(filterShows, { id: filterShow }).value;

  const _orders = orderBy !== "" ? stableSort(filterOrders(orders), getComparator(order, orderBy)) : filterOrders(orders);

  const pages = parseInt((_orders.length + rowsPerPage - 1) / rowsPerPage);
  const currentOrders = _orders.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  const pageSelectedOrders = _.filter(currentOrders, row => isSelection(row.order));
  const isSelectedAll = currentOrders.length === pageSelectedOrders.length;

  return (
    <div>
      {renderPageHeader()}

      {renderPageToolbar()}

      {renderTable()}

      <div className={classes.pagination}>
        <Pagination count={pages} page={page + 1} onChange={handleChangePage} showFirstButton showLastButton />
      </div>

      {renderDeleteDialog()}
    </div>
  );
}

export default OrderQueue;