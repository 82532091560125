import { collections } from '../constants/defines';
import _ from "lodash";
import {
  collection,
  query,
  where,
  limit,
  getDocs,
  orderBy,
  doc,
  updateDoc,
  addDoc,
  setDoc,
  getDoc,
  writeBatch,
  deleteDoc,
  onSnapshot,
  startAfter
} from "firebase/firestore";

let unsubscribeCurrentQuery = null;

const getInvetoryUnits = (db, sort, fetchData) => {
  if (unsubscribeCurrentQuery) {
    unsubscribeCurrentQuery();
  }

  const direction = sort === "name" ? "asc" : "desc";
  const q = query(collection(db, collections.INVENTORY), orderBy(sort, direction));

  unsubscribeCurrentQuery = onSnapshot(q, (querySnapshot) => {
    const apiQueue = [];

    querySnapshot.forEach((doc) => {
      let data = doc.data();
      apiQueue.push({
        id: doc.id,
        sku: data.sku,
        name: data.name,
        location: data.location,
        parent: data?.parent ? data?.parent : '',
        quantity: parseInt(data.quantity)
      });
    });
    fetchData(apiQueue);
  });
}

const addInventory = (db, item) => {
  return new Promise((resolve, reject) => {
    const docRef = doc(db, collections.INVENTORY, item.sku);
    setDoc(docRef, {
      sku: item.sku,
      name: item.name,
      quantity: parseInt(item.quantity),
      location: item.location,
      parent: item.parent,
      createdAt: new Date()
    })
      .then(() => {
        console.log("New Inventory created");
        resolve();
      })
      .catch((error) => {
        console.error("Error adding Inventory: ", error);
        reject();
      });
  });
}

const deleteInventory = (db, item) => {
  return new Promise((resolve, reject) => {
    deleteDoc(doc(db, collections.INVENTORY, item.id))
      .then(() => {
        console.log("Inventory successfully deleted!");
        resolve();
      })
      .catch((error) => {
        // The document probably doesn't exist.
        console.error("Error deleting Inventory: ", error);
        reject();
      });
  });
}

const updateInventory = (db, item) => {
  return new Promise((resolve, reject) => {
    const docRef = doc(db, collections.INVENTORY, item.id);
    updateDoc(docRef, {
      sku: item.sku,
      name: item.name,
      quantity: parseInt(item.quantity),
      location: item.location,
      parent: item.parent,
      createdAt: new Date()
    })
      .then(() => {
        console.log("Inventory successfully updated!");
        resolve();
      })
      .catch((error) => {
        // The document probably doesn't exist.
        console.error("Error updating Inventory: ", error);
        reject();
      });
  });
}

const getInventory = (db, sku) => {
  return new Promise((resolve, reject) => {
    const q = query(collection(db, collections.INVENTORY), where("sku", "==", sku));
    getDocs(q)
      .then((querySnapshot) => {
        let item = { exists: false, sku, data: null };
        querySnapshot.forEach((doc) => {
          if (doc.exists) {
            const data = doc.data();
            item.id = doc.id;
            item.exists = true;
            item.sku = sku;
            item.data = { ...data };
          }
        });
        resolve(item);
      })
      .catch(error => {
        console.log("getInventory Error. ", error.message);
        reject();
      });
  });

}

const addBulkProducts = (db, items) => {
  const batch = writeBatch(db);

  // Add deletes to batch
  items.forEach(item => {
    const docRef = doc(db, collections.INVENTORY, item.sku);

    batch.set(docRef,
      {
        createdAt: new Date(),
        sku: item.sku,
        name: item.name,
        quantity: parseInt(item.quantity),
        parent: item.parent,
        location: item.location
      },
      { merge: true }
    );
  });

  batch.commit().then(function () {
    console.log('Complete uploading bulk Inventory');
  }).catch(function (error) {
    console.error(`Error comitting batch: ${error.message}`);
  });
}

const addProducts = (db, items, callback) => {
  const requests = _.map(items, item => getInventory(db, item.sku));
  Promise.all(requests)
    .then((rsps) => {
      console.log('All Arts Uploaded!');

      const reqs = [];
      for (let i = 0; i < rsps.length; i++) {
        const _item = { ..._.find(items, { sku: rsps[i].sku }) };
        if (rsps[i].exists) {
          _item.id = rsps[i].id;
          _item.name = rsps[i].data.name;
          _item.quantity = _item.quantity + parseInt(rsps[i].data.quantity);
          reqs.push(updateInventory(db, _item));
        } else {
          reqs.push(addInventory(db, _item));
        }
      }
      Promise.all(reqs)
        .then(() => {
          if (callback)
            callback(true);
        })
        .catch(err => {
          console.log(err.code);
          if (callback)
            callback(false);
        });
    })
    .catch(err => {
      console.log(err.code);
      if (callback)
        callback(false);
    });
}

const getBoxset = (db, sku) => {
  return new Promise((resolve, reject) => {
    const q = query(collection(db, collections.BOXSETS), where("sku", "==", sku));

    onSnapshot(q, (querySnapshot) => {
      let _item = { exists: false, sku: '', name: name, items: [] };
      querySnapshot.forEach((doc) => {
        if (doc.exists) {
          const data = doc.data();

          if (!data.toggles) {
            _item = { ...data, exists: true, id: doc.id, toggles: [true, true, true, true, true] };
          } else {
            _item = { ...data, exists: true, id: doc.id, };
          }
        }
      });
      resolve(_item);
    });
  });
}

const exportProducts = async (db, sort, callback) => {
  const rows = [["sku", "quantity", "location", "parent"]];
  let csvContent = "";
  rows.forEach(function (rowArray) {
    let row = rowArray.join(",");
    csvContent += row + "\r\n";
  });

  let last = null, totalCount = 0, matchedCount = 0;
  const direction = sort === "name" ? "asc" : "desc";

  do {
    // code block to be executed

    const q = query(collection(db, collections.INVENTORY),
      orderBy("name"),
      startAfter(last),
      limit(1000)
    );

    const snapshot = await getDocs(q);
    if (snapshot.docs.length > 0) {
      totalCount += snapshot.docs.length;
      for (let i = 0; i < snapshot.docs.length; i++) {
        const doc = snapshot.docs[i];
        if (doc.exists) {
          const data = doc.data();
          console.log(`Inventory SKU:'${data.sku}', Boxset:${data.isBoxset}, Location:${data.location}`);
          if (!!data.sku) {
            csvContent += `${data.sku},${data.quantity},${data.location},${data.parent ? data.parent : ''}\r\n`;
            matchedCount++;
          }
        }
      }
    }
    // Get the last document
    last = snapshot.docs[snapshot.docs.length - 1];
    console.log('Length: ', snapshot.docs.length);
  }
  while (last);

  console.log('Total Count: ', totalCount);
  console.log('Matched Count: ', matchedCount);
  var blob = new Blob(["\ufeff", csvContent]);
  var url = URL.createObjectURL(blob);
  var downloadLink = document.createElement("a");
  downloadLink.href = url;

  const baseBatchId = Date.now();
  downloadLink.download = `Exports_${baseBatchId}.csv`;

  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);

  callback(matchedCount);

}

const getInventoryProducts = (db, items, callback) => {
  const skus = items.map(item => item.sku);
  const requests = _.map(skus, (sku) => {
    const q = query(collection(db, collections.PRODUCTS),
      where('sku', '==', sku),
      limit(1)
    );
    return getDocs(q);
  });

  // Make requests
  return Promise.all(requests)
    .then((snaps) => {
      /**
       * Filter existing/missing docs
       * Since limit was 1, and there should be only one item with this sku,
       * it's safe to just grab the first doc in each snap.
       */
      const docs = _.map(snaps, (snap) => snap.docs[0]);

      const _items = _.map(docs, (doc, index) => {
        const csvItem = items[index];
        if (!!doc) {
          const data = doc.data();
          return {
            exists: doc.exists(),
            sku: data.sku,
            name: data.name,
            isMask: data.isMask,
            qty: csvItem.quantity,
            location: !!csvItem.location ? csvItem.location : "",
            parent: !!csvItem.parent ? csvItem.parent : "",
            format: data.format,
            size: data.size,
            type: data.type,
            style: data.style
          };

        } else {
          return {
            exists: false,
            sku: csvItem.sku,
            name: '',
            qty: csvItem.quantity,
            location: !!csvItem.location ? csvItem.location : "",
            parent: !!csvItem.parent ? csvItem.parent : "",
            isMask: false,
            format: '',
            size: '',
            type: '',
            style: ''
          };
        }
      });

      return _items;
    })
    .catch((error) => {
      console.log('getProductsBySku: ', error);
    });
}

const getInventoryItems = (db, items) => {
  const requests = _.map(items, (sku) => getInventory(db, sku));

  // Make requests
  return Promise.all(requests)
    .then((results) => {

      const _items = _.map(results, item => {
        if (!item.exists) {
          return { sku: item.sku, location: '', quantity: '', notes: 'Product not found' };
        }
        return { sku: item.sku, location: item.data.location, quantity: item.data.quantity, notes: '' }
      })

      return _items;
    })
    .catch((error) => {
      console.log('getProductsBySku: ', error);
    });
}

const getInventoryBoxsets = (db, items) => {
  const skus = items.map(item => item.sku);
  const requests = _.map(skus, (sku) => {
    const q = query(collection(db, collections.BOXSETS),
      where('sku', '==', sku),
      limit(1)
    );
    return getDocs(q);
  });

  // Make requests
  return Promise.all(requests)
    .then((snaps) => {
      /**
       * Filter existing/missing docs
       * Since limit was 1, and there should be only one item with this sku,
       * it's safe to just grab the first doc in each snap.
       */
      const docs = _.map(snaps, (snap) => snap.docs[0]);
      const _items = _.map(docs, (doc, index) => {
        if (!!doc) {
          const data = doc.data();

          return {
            exists: doc.exists(),
            sku: data.sku,
            name: data.name,
            qty: items[index].qty,
            location: !!items[index].location ? items[index].location : "",
            format: '',
            size: '',
            type: '',
            style: ''
          };

        } else {
          return {
            exists: false,
            sku: items[index].sku,
            name: '',
            qty: items[index].qty,
            location: !!items[index].location ? items[index].location : "",
            format: '',
            size: '',
            type: '',
            style: ''
          };
        }
      });

      return _items;
    })
    .catch((error) => {
      console.log('getBoxsetsBySku: ', error);
    });
}

const getInventoryVariantProducts = (db, items) => {
  const skus = items.map(item => item.sku);
  const requests = _.map(skus, (sku) => {
    const q = query(collection(db, collections.PRODUCTS),
      where('variants', 'array-contains', sku),
      limit(1)
    );
    return getDocs(q);
  });

  // Make requests
  return Promise.all(requests)
    .then((snaps) => {
      /**
       * Filter existing/missing docs
       * Since limit was 1, and there should be only one item with this sku,
       * it's safe to just grab the first doc in each snap.
       */
      const docs = _.map(snaps, (snap) => snap.docs[0]);

      const _items = _.map(docs, (doc, index) => {
        const csvItem = items[index];
        if (!!doc) {
          const data = doc.data();
          return {
            exists: doc.exists(),
            sku: data.sku,
            name: data.name,
            isMask: data.isMask,
            qty: csvItem.qty,
            location: !!csvItem.location ? csvItem.location : "",
            parent: !!csvItem.parent ? csvItem.parent : "",
            format: data.format,
            size: data.size,
            type: data.type,
            style: data.style
          };

        } else {
          return {
            exists: false,
            sku: csvItem.sku,
            name: '',
            isMask: false,
            qty: csvItem.qty,
            location: !!csvItem.location ? csvItem.location : "",
            parent: !!csvItem.parent ? csvItem.parent : "",
            format: '',
            size: '',
            type: '',
            style: ''
          };
        }
      });
      return _items;
    })
    .catch((error) => {
      console.log('getProductsBySku: ', error);
    });
}

export {
  getInvetoryUnits,
  getInventoryItems,
  addProducts,
  addBulkProducts,
  updateInventory,
  deleteInventory,
  exportProducts,
  getInventoryProducts,
  getInventoryBoxsets,
  getInventoryVariantProducts
};