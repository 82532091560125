import React, {useState, useEffect} from 'react';
import { makeStyles } from '@mui/styles';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import _  from "lodash";
import {searchProducts} from '../../module/typesense';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        marginTop: '0px'
    },
    searchInput: {
      width: '100%',

      'label + &': {
        marginTop: '15px',
        width: '100%'
      },
      'label': {
        fontSize: '16px',
        fontWeight: '500',
        color: '#000000'
      },
      '& input': {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: 'rgba(0,0,0,0.04)',
        border: '1px solid #ced4da',
        
        fontSize: 16,
        padding: '11px 10px 11px 12px !important',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        '&:focus': {
          borderRadius: 4,
          borderColor: '#80bdff',
          boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
      },
    },

}));

export default function ProductsDropdown(props) {
  const classes = useStyles();
  const {onSelect, label, onChange} = props;
  const [open, setOpen] = useState(false);
  const [items, setItems] = useState([]);
  const [textSearch, setTextSearch] = useState("");
  const [value, setValue] = React.useState(null);
  const [loading, setLoading] = useState(false);
  const WAIT_INTERVAL = 300;
  let timerId;

  useEffect(() => {
    if (!open) {
      return;
    }
    const options = {
      'textSearch': textSearch,
      'rowsPerPage': 100,
      'pageNumber': 1,
      filters: props.filter
    };

    setLoading(true);
    // last param is to not allow boxset search
    searchProducts(options, fetchData, true);  
  }, [textSearch, open]);

  useEffect(() => {
    if (!open) {
        setItems([]);
    }
  }, [open]);

  // callback for getProducts
  const fetchData = (products, productsMetadata) => {
    setItems([...products]);
    setLoading(false);
  };

  const handleSelect = (event, newValue, reason) => {
    if (reason === "selectOption") {
      onSelect(newValue);
      setValue({sku: "", name: ""});
    }
  }
 
  const handleSearch = (event, value, reason) => {    
    clearTimeout(timerId);
    if (onChange)
      onChange(value);
    timerId = setTimeout( () => {      
      //setItems([]);
      setTextSearch(value);
    }, WAIT_INTERVAL);
  }

  return (
    <div className={classes.root}>
      <Autocomplete                             
        value={value}                  
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        disableClearable={true}
        onChange={handleSelect}
        onInputChange={handleSearch}
        getOptionLabel={(option) => option.sku?`${option.sku} - ${option.name}`:""}
        options={items}
        loading={loading}
        openOnFocus={true}
        noOptionsText="No Items"
        renderInput={(params) => (
          <TextField 
            {...params}
            placeholder={label}
            className={classes.searchInput}
            variant="outlined"                      
            InputProps={{
              ...params.InputProps
            }}
            
          />
        )}
      />
    </div>
  );
}
