import React, { useState, useEffect, createRef } from 'react';
import update from "immutability-helper";
import { makeStyles, withStyles  } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import InputBase from '@mui/material/InputBase';
import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import {getFormattedCropSetting, createCropSetting, updateCropSetting} from '../../db/cropSettings';
import { withAuthorization } from '../Session';
import {BootstrapInput} from '../Page/input';
import { filterTypes, filterStyles } from '../Products/Products.constants';

const useHeaderStyles = makeStyles((theme) => ({
  image: {
    width: '100%',
    height: 'auto'
  },
  imageBox: {
    position: 'relative',
    border: 'solid 1px rgba(217, 223, 233, 0.4)'
  },
  coverImage: {
    height: '320px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'top center'
  },
  dropBox: {    
    textAlign: 'center',
    padding: '88px 0px',
    borderRadius: '6px',
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    marginBottom: '25px',
    '& svg' : {
      opacity: '0.56'
    }
  },
  dropLabel: {
    fontSize: '16px',
    fontWeight: '500',
    color: '#000000',
    opacity: '0.56',
    margin: '0'
  },
  inputLabelWarning: {
    paddingLeft: '25px',
    fontSize: '15px',
    fontWeight: '400',
    color: 'red',
    fontStyle: 'italic',
    minHeight: '15px'
  },
  inputInlineLabel: {
    fontSize: '16px',
    fontWeight: '500',
    color: '#000000',
    marginTop: '15px',
    marginRight: '20px'
  },
  slotHeading: {
    fontSize: '24px',
    fontWeight: '500',
    color: '#000000',
    flexBasis: 'calc(100% - 250px)',
    flexShrink: 0,
  },
  slotLabel: {
    fontSize: '16px',
    fontWeight: '500',
    color: '#000000',  
  },
  tableLabel: {
    fontSize: '14px',
    fontWeight: '500',
    color: '#000000',  
    marginRight: '10px'
  },
  normalAccordion: {
    background: 'rgba(242, 244, 246, 0.4)'
  },
  expandedAccordion: {
    background: '#F1F7FE'
  }
}));

const DEFAULT_STATE = {
  id: "",
  artType: "",
  name: "",
  type: "",
  style: "",
  format: "",
  size: "",
  frontCanvas: {
    width: 0,
    height: 0,
  },
  backCanvas: {
    width: 0,
    height: 0,
  },
  frontArea: {
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  },
  backArea: {
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  },
  resizeArea: {
    width: 0,
    height: 0
  },
  placeArea: {
    x: 0,
    y: 0
  },
  toeArea: {
    top: 0,
    bottom: 0
  }
};

const DEFAULT_SETTING = {
  name: "",
  artType: "",
  type: "",
  style: "",
  format: "",
  size: "",
  items: {
    "legacy": DEFAULT_STATE,
    "modern": DEFAULT_STATE,
    "solo": DEFAULT_STATE
  }
};

function SettingModal(props) {
  const {handleClose, open, isCreate} = props;
  const [data, setData] = useState({...DEFAULT_SETTING});
  const [artType, setArtType] = useState("legacy");
  const [sizes, setSizes] = useState([]);
  const [formats, setFormats] = useState([]);
  const [styles, setStyles] = useState([]);

  const db = props.firebase.getdb();

  useEffect(() => {
    if (open) {
      setArtType("legacy");
      
      if (isCreate)
        setData({...DEFAULT_SETTING});
      else {
        getFormattedCropSetting(db, props.item.name, fetchData);
      } 
    }
  }, [open]);

  const loadSpecs = (value) => {
    if (value === '') {
      setFormats([...props.formats]);
      setSizes([...props.sizes]);
      const _styles = _.filter(filterStyles, item => {return item.id > 2});
      setStyles([..._styles]);

    } else {
      const _formats = _.filter(props.formats, {type: value})
      setFormats([..._formats]);

      const _sizes = _.filter(props.sizes, {type: value})
      setSizes([..._sizes]);

      const _styles = _.filter(filterStyles, item => {
        return item.id>2 && item.type.includes(value);
      })
      setStyles([..._styles]);
    }
  }

  useEffect(() => {
    loadSpecs(data.type);
  }, [data]);

  const fetchData = (setting) => {
    setData({...setting});
  }

  const handleSave = () => {
    if (isCreate) {
      createCropSetting(db, data);      
    } else {
      updateCropSetting(db, data);
    }      
    handleClose();
  }

  const handleCloseDialog = (event) => {
    handleClose();     
  }

  const handleChange = (event) => {
    let { name, value} = event.target;
    if (name === "type") {
      setData({...data, [name]:value, style:'', size:'', format:''});
    } else {
      setData({...data, [name]:value});
    }    
  }

  const handleFrontCanvasDimensionChange = (event) => {
    const { name: label, value } = event.target;
    const cleanValue = !isNaN(parseFloat(value)) ? parseFloat(value) : value;
    let property = "";

    switch (label) {
      case "canvasWidth":
        property = "width";
        break;
      case "canvasHeight":
        property = "height";
        break;
      default:
        property = "";
    }

    let _setting = {...data};
    const _item = {..._setting.items[artType]};
    _item.frontCanvas = update(_item.frontCanvas, {
      [property]: { $set: cleanValue }     
    });
    _setting.items[artType] = _item;
    setData(_setting);
  }

  const handleBackCanvasDimensionChange = (event) => {
    const { name: label, value } = event.target;
    const cleanValue = !isNaN(parseFloat(value)) ? parseFloat(value) : value;
    let property = "";

    switch (label) {
      case "canvasWidth":
        property = "width";
        break;
      case "canvasHeight":
        property = "height";
        break;
      default:
        property = "";
    }

    let _setting = {...data};
    const _item = {..._setting.items[artType]};
    _item.backCanvas = update(_item.backCanvas, {
      [property]: { $set: cleanValue }     
    });
    _setting.items[artType] = _item;
    setData(_setting);
  }

  const handleFrontAreaChange = (event) => {
    const { name: label, value } = event.target;
    const cleanValue = !isNaN(parseFloat(value)) ? parseFloat(value) : value;
    let property = "";

    switch (label) {
      case "printAreaX":
        property = "x";
        break;
      case "printAreaY":
        property = "y";
        break;
      case "printAreaWidth":
        property = "width";
        break;
      case "printAreaHeight":
        property = "height";
        break;
      default:
        property = "";
    }

    let _setting = {...data};
    const _item = {..._setting.items[artType]};
    _item.frontArea = update(_item.frontArea, {
      [property]: { $set: cleanValue }     
    });
    _setting.items[artType] = _item;

    setData(_setting);
  };
  
  const handleBackAreaChange = (event) => {
    const { name: label, value } = event.target;
    const cleanValue = !isNaN(parseFloat(value)) ? parseFloat(value) : value;
    let property = "";

    switch (label) {
      case "printAreaX":
        property = "x";
        break;
      case "printAreaY":
        property = "y";
        break;
      case "printAreaWidth":
        property = "width";
        break;
      case "printAreaHeight":
        property = "height";
        break;
      default:
        property = "";
    }

    let _setting = {...data};
    const _item = {..._setting.items[artType]};
    _item.backArea = update(_item.backArea, {
      [property]: { $set: cleanValue }     
    });
    _setting.items[artType] = _item;

    setData(_setting);
  };

  const handleResizeAreaChange = (event) => {
    const { name: label, value } = event.target;
    const cleanValue = !isNaN(parseFloat(value)) ? parseFloat(value) : value;
    let property = label;


    let _setting = {...data};
    const _item = {..._setting.items[artType]};
    _item.resizeArea = update(_item.resizeArea, {
      [property]: { $set: cleanValue }     
    });
    _setting.items[artType] = _item;

    setData(_setting);
  };

  const handleToeAreaChange = (event) => {
    const { name: label, value } = event.target;
    const cleanValue = !isNaN(parseFloat(value)) ? parseFloat(value) : value;
    let property = label;

    let _setting = {...data};
    const _item = {..._setting.items[artType]};
    _item.toeArea = update(_item.toeArea, {
      [property]: { $set: cleanValue }     
    });
    _setting.items[artType] = _item;

    setData(_setting);
  };

  const handlePlaceAreaChange = (event) => {
    const { name: label, value } = event.target;
    const cleanValue = !isNaN(parseFloat(value)) ? parseFloat(value) : value;
    let property = label;


    let _setting = {...data};
    const _item = {..._setting.items[artType]};
    _item.placeArea = update(_item.placeArea, {
      [property]: { $set: cleanValue }     
    });
    _setting.items[artType] = _item;

    setData(_setting);
  };

  const classes = useHeaderStyles();
  const scroll = 'paper';
  const types = _.filter(filterTypes, item=> {return item.name !== 'All' && item.name !== 'None'});  
  let item = {...data.items[artType]};
  const frontExceed = item.frontArea.height > item.frontCanvas.height;
  const backExceed = item.backArea.height > item.backCanvas.height;
  return (
    <Dialog onClose={handleCloseDialog} 
        aria-labelledby="setting-dialog-title" 
        open={open}         
        fullWidth={true} 
        maxWidth={"lg"} 
        scroll={scroll}
    >
      <DialogTitle sx={{padding:'30px 40px 10px'}}>
        <Typography variant="h2">{props.modal_name}</Typography>   
        <IconButton aria-label="close" variant='close' onClick={handleCloseDialog}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
        <DialogContent sx={{padding: '0 40px'}} dividers>
          <Box sx={{padding: '30px 0'}}>
            <Grid container spacing={3}>
              <Grid item xs={5}>
                <InputLabel>Name</InputLabel>
                <BootstrapInput name="name" value={data.name} onChange={handleChange} />
              </Grid>

              <Grid item xs={1}></Grid>

              <Grid item xs={3}>
                <InputLabel>&nbsp;</InputLabel> 
                <Select
                  name="artType"
                  value={artType}
                  input={<BootstrapInput />}
                  onChange={e => setArtType(e.target.value)}
                >              
                  <MenuItem value="legacy">Legacy</MenuItem>
                  <MenuItem value="modern">Modern</MenuItem>
                  <MenuItem value="solo">Solo</MenuItem>
                </Select>
              </Grid>
            </Grid>
          </Box>

          <Divider />

          <Box sx={{padding: '20px 0'}}>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <InputLabel>Type</InputLabel>            
                <Select
                  name="type"
                  value={data.type}
                  input={<BootstrapInput />}
                  onChange={handleChange}
                >
                  <MenuItem value="">-</MenuItem>
                  {
                  _.map(types, item => (
                    <MenuItem value={item.name}>{item.name}</MenuItem>
                  ))
                  }
                </Select>
              </Grid>

              <Grid item xs={3}>
                <InputLabel>Format</InputLabel>            
                <Select
                  name="format"
                  value={data.format}
                  input={<BootstrapInput />}
                  onChange={handleChange}
                >
                  <MenuItem value="">-</MenuItem>
                  {
                    _.map(formats, format => (
                      <MenuItem value={format.id}>{format.name}</MenuItem>
                    ))
                  }
                </Select>
              </Grid>

              <Grid item xs={3}>
                <InputLabel>Style</InputLabel>            
                <Select
                  name="style"
                  value={data.style}
                  input={<BootstrapInput />}
                  onChange={handleChange}
                >
                  <MenuItem value="">-</MenuItem>
                  {
                    _.map(styles, style => (
                      <MenuItem value={style.name}>{style.name}</MenuItem>
                    ))
                  }
                </Select>
              </Grid>
              
              <Grid item xs={3}>
                <InputLabel>Size</InputLabel>            
                <Select
                  name="size"
                  value={data.size}
                  input={<BootstrapInput />}
                  onChange={handleChange}
                >
                  <MenuItem value="">-</MenuItem>
                  {
                    _.map(sizes, size => (
                      <MenuItem value={size.id}>{size.name}</MenuItem>
                    ))
                  }
                </Select>
              </Grid>
            </Grid>
          </Box>

          <Divider />

          <Box sx={{padding: '20px 0'}}>
            <InputLabel>Front Canvas Dimension</InputLabel>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Box display="flex" alignItems="center">
                  <InputLabel className={classes.inputInlineLabel}>W</InputLabel>
                  <BootstrapInput type="number" name="canvasWidth" value={item.frontCanvas.width} onChange={handleFrontCanvasDimensionChange} />
                </Box>
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                <Box display="flex" alignItems="center">
                  <InputLabel className={classes.inputInlineLabel}>H</InputLabel>
                  <BootstrapInput type="number" name="canvasHeight" value={item.frontCanvas.height} onChange={handleFrontCanvasDimensionChange} />
                </Box>                
              </Grid>
            </Grid>
          </Box>

          <Divider />

          <Box sx={{padding: '20px 0'}}>
            <InputLabel>Back Canvas Dimension</InputLabel>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Box display="flex" alignItems="center">
                  <InputLabel className={classes.inputInlineLabel}>W</InputLabel>
                  <BootstrapInput type="number" name="canvasWidth" value={item.backCanvas.width} onChange={handleBackCanvasDimensionChange} />
                </Box>
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                <Box display="flex" alignItems="center">
                  <InputLabel className={classes.inputInlineLabel}>H</InputLabel>
                  <BootstrapInput type="number" name="canvasHeight" value={item.backCanvas.height} onChange={handleBackCanvasDimensionChange} />
                </Box>                
              </Grid>
            </Grid>
          </Box>

          <Divider />          

          <Box sx={{padding: '20px 0'}}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <InputLabel htmlFor="demo-customized-textbox">Front Crop Dimension</InputLabel>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Box display="flex" alignItems="center">
                      <InputLabel className={classes.inputInlineLabel}>X</InputLabel>
                      <BootstrapInput type="number" name="printAreaX" value={item.frontArea.x} onChange={handleFrontAreaChange}/>
                    </Box>
                  </Grid>
                  
                  <Grid item xs={6}>
                    <Box display="flex" alignItems="center">
                      <InputLabel htmlFor="demo-customized-textbox" className={classes.inputInlineLabel}>Y</InputLabel>
                      <BootstrapInput type="number" name="printAreaY" value={item.frontArea.y} onChange={handleFrontAreaChange}/>
                    </Box>                
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <InputLabel htmlFor="demo-customized-textbox" className={classes.inputLabelWarning}>{frontExceed?'Note: Crop Dimensions exceed Canvas Dimensions, stretching will occur':'.'}</InputLabel>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Box display="flex" alignItems="center">
                      <InputLabel className={classes.inputInlineLabel}>W</InputLabel>
                      <BootstrapInput type="number" name="printAreaWidth" value={item.frontArea.width} onChange={handleFrontAreaChange}/>
                    </Box>
                  </Grid>
                  
                  <Grid item xs={6}>
                    <Box display="flex" alignItems="center">
                      <InputLabel className={classes.inputInlineLabel}>H</InputLabel>
                      <BootstrapInput type="number" name="printAreaHeight" value={item.frontArea.height} onChange={handleFrontAreaChange}/>
                    </Box>                
                  </Grid>
                </Grid>
              </Grid>
            </Grid>                  
          </Box>

          <Divider />

          <Box sx={{padding: '20px 0'}}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <InputLabel htmlFor="demo-customized-textbox">Back Crop Dimension</InputLabel>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Box display="flex" alignItems="center">
                      <InputLabel className={classes.inputInlineLabel}>X</InputLabel>
                      <BootstrapInput type="number" name="printAreaX" value={item.backArea.x} onChange={handleBackAreaChange}/>
                    </Box>
                  </Grid>
                  
                  <Grid item xs={6}>
                    <Box display="flex" alignItems="center">
                      <InputLabel htmlFor="demo-customized-textbox" className={classes.inputInlineLabel}>Y</InputLabel>
                      <BootstrapInput type="number" name="printAreaY" value={item.backArea.y} onChange={handleBackAreaChange}/>
                    </Box>                
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <InputLabel htmlFor="demo-customized-textbox" className={classes.inputLabelWarning}>{backExceed?'Note: Crop Dimensions exceed Canvas Dimensions, stretching will occur':' '}</InputLabel>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Box display="flex" alignItems="center">
                      <InputLabel className={classes.inputInlineLabel}>W</InputLabel>
                      <BootstrapInput type="number" name="printAreaWidth" value={item.backArea.width} onChange={handleBackAreaChange}/>
                    </Box>
                  </Grid>
                  
                  <Grid item xs={6}>
                    <Box display="flex" alignItems="center">
                      <InputLabel className={classes.inputInlineLabel}>H</InputLabel>
                      <BootstrapInput type="number" name="printAreaHeight" value={item.backArea.height} onChange={handleBackAreaChange}/>
                    </Box>                
                  </Grid>
                </Grid>
              </Grid>
            </Grid>                  
          </Box>

          <Divider /> 
          {/* Resize */}   
          {item.type === "Underwear" && (
          <Box sx={{padding: '20px 0'}}>
            <InputLabel>Resize Dimension</InputLabel>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Box display="flex" alignItems="center">
                  <InputLabel className={classes.inputInlineLabel}>W</InputLabel>
                  <BootstrapInput type="number" name="width" value={item.resizeArea.width} onChange={handleResizeAreaChange}/>
                </Box>
              </Grid>
              
              <Grid item xs={3}>
                <Box display="flex" alignItems="center">
                  <InputLabel htmlFor="demo-customized-textbox" className={classes.inputInlineLabel}>H</InputLabel>
                  <BootstrapInput type="number" name="height" value={item.resizeArea.height} onChange={handleResizeAreaChange}/>
                </Box>                
              </Grid>
            </Grid>
          </Box> 
          )} 

          {item.type === 'Socks' && (
          <Box sx={{padding: '20px 0'}}>
            <InputLabel>Toe Placement</InputLabel>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Box display="flex" alignItems="center">
                  <InputLabel className={classes.inputInlineLabel}>Top</InputLabel>
                  <BootstrapInput type="number" name="top" value={item.toeArea?item.toeArea.top:0} onChange={handleToeAreaChange}/>
                </Box>
              </Grid>
              
              <Grid item xs={3}>
                <Box display="flex" alignItems="center">
                  <InputLabel htmlFor="demo-customized-textbox" className={classes.inputInlineLabel}>Bottom</InputLabel>
                  <BootstrapInput type="number" name="bottom" value={item.toeArea?item.toeArea.bottom:0} onChange={handleToeAreaChange}/>
                </Box>                
              </Grid>
            </Grid>
          </Box>            
          )}
          <Divider /> 

          {item.type === "Underwear" && (
          <Box sx={{padding: '20px 0'}}>
            <InputLabel>Placement</InputLabel>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Box display="flex" alignItems="center">
                  <InputLabel className={classes.inputInlineLabel}>X</InputLabel>
                  <BootstrapInput type="number" name="x" value={item.placeArea.x} onChange={handlePlaceAreaChange}/>
                </Box>
              </Grid>
              
              <Grid item xs={3}>
                <Box display="flex" alignItems="center">
                  <InputLabel htmlFor="demo-customized-textbox" className={classes.inputInlineLabel}>Y</InputLabel>
                  <BootstrapInput type="number" name="y" value={item.placeArea.y} onChange={handlePlaceAreaChange}/>
                </Box>                
              </Grid>
            </Grid>
          </Box> 
          )} 
          <Divider />              
        </DialogContent>

        <DialogActions  sx={{padding: '15px 40px', justifyContent: 'flex-start'}}>
          <Button variant="blue" color="primary" sx={{width: '150px'}} onClick={handleSave}>Save</Button>
        </DialogActions>
    </Dialog>
  );
}


const condition = authUser => !!authUser;
export default withAuthorization(condition)(SettingModal);
