import React, { useContext, useState } from 'react';
import { makeStyles } from '@mui/styles';
import FilterDropdown from '../Page/filterDropdown';
import FilterContext from './filterContext';
import Button from '@mui/material/Button';
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    padding: '50px 0 50px 16px',
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid',
    borderColor: 'rgba(217, 223, 233, 0.4)'
  },
  toolBox: {
    display: 'flex',
    width: 'calc(100% - 200px)',
    justifyContent: 'flex-end'
  },
  filterBox: {
    display: 'flex',
    alignItems: 'center'
  },
  button: {
    padding: '9px 32px',
    fontSize: '16px',
    fontWeight: '500',
    color: '#ffffff',
    textTransform: 'initial',
    marginRight: '16px'
  },
  formLabel: {
    color: '#000000',
    fontSize: '14px',
    fontWeight: '500',
    letterSpacing: '1.5px'
  },
  iconBox: {
    textAlign: 'right',
    paddingRight: '30px',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  viewIconBox: {
    padding: '0 5px',
    cursor: 'pointer',
  },
  viewIcon: {
    fontSize: '30px',
    color: '#e5e5e5'
  },
  viewActiveIcon: {
    fontSize: '30px',
    color: '#bebebe'
  },
  sortBox: {
    display: 'flex'
  }
}));

const PageToolbar = (props) => {
  const { handleNew, view, onChangeView } = props;
  const data = useContext(FilterContext);
  const classes = useToolbarStyles();

  const onClickViewMode = (mode) => {
    console.log('onClickViewMode');
    if (onChangeView)
      onChangeView(mode);
  }

  return (
    <div className='pageToolbar'>
      <div className={classes.buttonBox}>
        {props.editOnly && (
          <Button
            variant="blue"
            onClick={handleNew}
            sx={{ width: '180px' }}
          >
            Add Product
          </Button>
        )}
      </div>

      <div className={classes.toolBox}>
        <div className={classes.iconBox}>
          <div className={classes.viewIconBox}>
            <ViewListIcon onClick={() => onClickViewMode("list")} className={props.view === 'list' ? classes.viewActiveIcon : classes.viewIcon} />
          </div>
          <div className={classes.viewIconBox}>
            <ViewModuleIcon onClick={() => onClickViewMode("grid")} className={props.view === 'grid' ? classes.viewActiveIcon : classes.viewIcon} />
          </div>
        </div>

        <div className={classes.sortBox}>
          <FilterDropdown title="Show" items={data.shows} active={data.filterShow} onSelect={data.setFilterShow} />
          <FilterDropdown title="Sort" items={data.sorts} active={data.filterSort} onSelect={data.setFilterSort} />
        </div>
      </div>
    </div>
  );
};

PageToolbar.propTypes = {
};

export default PageToolbar;