import React from "react";
import ProductsDropdown from "../Page/productsDropdown";
import { Box, Button } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';




const Products = ({
  db,
  products,
  onSelect,
  classes,
  handleClear,
  renderItems,
  hideItems,
  adjustForYouthL,
}) => {
  return (
    <Box sx={styles.mainBox}>
      {
        !hideItems && (
          <Box sx={styles.searchComponent}>

            <ProductsDropdown
              db={db}
              products={products}
              onSelect={onSelect}
              label="Add Products"
            />

          </Box>
        )
      }

      <Box sx={{
        ...styles.itemsComponent,
        width: hideItems ? '100%' : '70%',
        marginTop: adjustForYouthL ? '3%' : '0px'
      }}>
        {renderItems()}
      </Box>

    </Box>
  );
}


const styles = {
  mainBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '10px',
    gap: '10px',
  },
  searchComponent: {
    width: '30%',
    paddingLeft: '15px',
  },
  itemsComponent: {
    width: '70%',
    height: '100%',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    overflow: 'auto',
  }
}


export default Products;