import React from 'react';
import { makeStyles } from '@mui/styles';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';

const useHeaderStyles = makeStyles((theme) => ({
    search: {
        width: '470px',
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.08)',
        },
        marginRight: 0,
        marginLeft: 0,
      },
      searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        right: '0px'
      },
      inputRoot: {
        color: 'inherit',
      },
      inputInput: {
        padding: '10px 16px!important',
        paddingRight: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
      },
      headerAction: {

      },
      csvButton: {
        marginLeft: '15px',
        background: '#3D3D3D',
        borderRadius: '6px',
        width: '160px',
        height: '40px',
        fontSize: '16px',
        fontWeight: '500',
        color: '#FFFFFF',
        textTransform: 'initial',
        '&:hover': {
          background: 'rgba(61, 61, 61, 0.8)',
        },
      },
  }));

  
const PageHeader = (props) => {
    const classes = useHeaderStyles();
    const {onSearch, onSync, isSync} = props;

    return (
      <div className="pageHeader">
        <div className={classes.headerAction}>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
                <SearchIcon />
            </div>
            <InputBase
              disabled={isSync}
              placeholder="Sync estimate"
              sx={{ width: '100%' }}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}    
              inputProps={{ 'aria-label': 'search' }}                
              onKeyUp={onSync}
            />          
          </div>
        </div>

        <div className={classes.headerAction}>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search estimate, po number or customers"
              sx={{ width: '100%' }}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}    
              inputProps={{ 'aria-label': 'search' }}  
              
              onKeyUp={onSearch}
            />
          </div>
        </div>

      </div>
    );
}


export default PageHeader;