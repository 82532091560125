import {collections, orderStatus, orderTypes} from '../constants/defines';
import {formatDate, formatTime} from '../module/functions';
import _  from "lodash";
import { 
    collection, 
    query, 
    where, 
    getDocs, 
    orderBy,
    doc,
    updateDoc,
    addDoc,
    setDoc,
    getDoc,
    deleteDoc,
    onSnapshot
} from "firebase/firestore";

const getUploads = (db, fetchData) => {
    const q = query(collection(db, collections.UPLOADS), orderBy('createdAt', 'desc'));
  
    onSnapshot(q, (querySnapshot) => {
      console.log('Uploads Changed...');
      let items = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        items.push({date: formatDate(data.createdAt), 
            id: doc.id,
            batch: data.batch,
            type: data.type,
            current: data.current,
            total: data.total,
            name: data.name,
            items: data.items}); 

      });
      fetchData(items);
    });
}

const deleteUploads = (db, docId) => {
    return new Promise((resolve, reject) => {
      deleteDoc(doc(db, collections.UPLOADS, docId))
        .then(() => {
          console.log("Uploads successfully deleted!");
          resolve();
        })
        .catch((error) => {
          // The document probably doesn't exist.
          console.error("Error deleting Uploads: ", error);
          reject();
      });
    });  
}

const createUploads = (db, name, type, createdAt, items) => {
    const newItem = {};
    const batchId = Date.now().toString();
    
    newItem.createdAt = createdAt;
    newItem.batch = batchId;
    newItem.total = items.length;
    newItem.current = 0;
    newItem.name = name;
    newItem.type = type;
    newItem.items = [...items];
    newItem.uploadedBy = localStorage.getItem("currentuser");
    console.log("New Uploads: ", newItem)
    return new Promise((resolve, reject) => {
        const docRef = doc(db, collections.UPLOADS, batchId);

        setDoc(docRef, newItem)  
            .then((response) => {
                console.log("New Uploads created");
                resolve();
            })
            .catch((error) => {
                console.error("Error adding Uploads: ", error);
                reject();
            });
    });
}

const getUploadsHistory = (db, fetchData) => {
    const q = query(collection(db, collections.UPLOADS_HISTORY), orderBy('createdAt', 'desc'));
  
    onSnapshot(q, (querySnapshot) => {
      console.log('Uploads Changed...');
      let items = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        let isError = false, isWarning = false;
        let created = '';
        if (!!data.createdAt) {
            created = formatDate(data.createdAt) + ' ' + formatTime(data.createdAt);
        }

        let _items = data.items.map((_item, index) => {  
            if (!!_item.isError)
                isError = true;
            if (!!_item.message)
                isWarning = true;
            return {   
                ..._item,
                id: index.toString()
            };
        });

        items.push({ id: doc.id,
                        batchId: data.batchId,
                        error: isError, 
                        warning: isWarning,
                        created: created, 
                        name: data.name,
                        completed: !!data.completed,
                        type: data.type,
                        files: data.files,
                        items: _items }); 

      });
      fetchData(items);
    });
}

export {getUploads, deleteUploads, getUploadsHistory, createUploads };