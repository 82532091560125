import React, { useState, useEffect, createRef } from 'react';
import update from "immutability-helper";
import { makeStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import InputBase from '@mui/material/InputBase';
import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import LinearProgress from '@mui/material/LinearProgress';
import Dropzone from 'react-dropzone';
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { customAssetBucket } from '../../constants/defines';
import {createSize, updateSize} from '../../db/sizes';
import { withAuthorization } from '../Session';
import {BootstrapInput} from '../Page/input';
import { filterTypes } from '../Products/Products.constants';

const dropzoneRef1 = createRef();

function ItemImage(props) {
  const {firebase, path, classes} = props;
  const [imagePath, setImagePath] = useState("");  

  useEffect(() => {
    const storage = firebase.getstorage();
    getDownloadURL(ref(storage, `${customAssetBucket}/${path}`))
        .then(url => {
          setImagePath(url);
        })
        .catch(error => {
            console.log(error);            
        })
  }, [props.path]);


  return (
    <Box className={classes.imageBox}>
      {props.progress < 100 && (<LinearProgress variant="determinate" value={props.progress} color="primary"  />)}

      {props.progress >= 100 && imagePath && <Box className={classes.coverImage} sx={{backgroundImage: `url(${imagePath})`}}></Box>}
    </Box>
  );
}

const useHeaderStyles = makeStyles((theme) => ({
  image: {
    width: '100%',
    height: 'auto'
  },
  imageBox: {
    position: 'relative',
    border: 'solid 1px rgba(217, 223, 233, 0.4)'
  },
  coverImage: {
    height: '320px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'top center'
  },
  dropBox: {    
    textAlign: 'center',
    padding: '88px 0px',
    borderRadius: '6px',
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    marginBottom: '25px',
    '& svg' : {
      opacity: '0.56'
    }
  },
  dropLabel: {
    fontSize: '16px',
    fontWeight: '500',
    color: '#000000',
    opacity: '0.56',
    margin: '0'
  },
  inputInlineLabel: {
    fontSize: '16px',
    fontWeight: '500',
    color: '#000000',
    marginTop: '15px',
    marginRight: '25px'
  },
  slotHeading: {
    fontSize: '24px',
    fontWeight: '500',
    color: '#000000',
    flexBasis: 'calc(100% - 250px)',
    flexShrink: 0,
  },
  slotLabel: {
    fontSize: '16px',
    fontWeight: '500',
    color: '#000000',  
  },
  tableLabel: {
    fontSize: '14px',
    fontWeight: '500',
    color: '#000000',  
    marginRight: '10px'
  },
  normalAccordion: {
    background: 'rgba(242, 244, 246, 0.4)'
  },
  expandedAccordion: {
    background: '#F1F7FE'
  }
}));

const DEFAULT_STATE = {
  name: "",
  sku: "",
  type: "",
  customPrintSheetFront: {
    width: 0,
    height: 0,
  },
  customPrintSheetBack: {
    width: 0,
    height: 0
  },
  customMockPath: "",  
  maxSlots: 5,
  maxMaskSlots: 7
};

function SizeModal(props) {
  const {handleClose, open, item, isCreate} = props;
  const [size, setSize] = useState({...DEFAULT_STATE});
  const [frontProgress, setFrontProgress] = useState(100); 
  const db = props.firebase.getdb();
  const storage = props.firebase.getstorage();

  useEffect(() => {
    if (isCreate)
      setSize({...DEFAULT_STATE});
    else
      setSize({...props.item});
  }, [props.item]);

  const handleSave = () => {
    if (isCreate) {
      createSize(db, size);
      setSize({...DEFAULT_STATE});
    } else {
      updateSize(db, size);
      setSize({...props.item}); 
    }
      
    handleClose();
  }

  const handleCloseDialog = (event) => {
    if (isCreate)
      setSize({...DEFAULT_STATE});
    else
      setSize({...props.item});  
    handleClose(); 
  }

  const handleChange = (event) => {
    let { name, value} = event.target;
    
    let _size = {...size};
    _size[name] = value;

    setSize(_size);
  }

  const handleFrontDimensionChange = (event) => {
    const { name: label, value } = event.target;
    const cleanValue = !isNaN(parseFloat(value)) ? parseFloat(value) : value;
    let property = "";

    switch (label) {
      case "frontWidth":
        property = "width";
        break;
      case "frontHeight":
        property = "height";
        break;
      default:
        property = "";
    }
    
    let _size = {...size};
    _size.customPrintSheetFront = update(_size.customPrintSheetFront, {
      [property]: { $set: cleanValue }     
    });

    setSize(_size);
  }

  const getImageDimensions = (file) => {
    return new Promise((resolve, reject) => {
      const fr = new FileReader();
      fr.onload = function () {
        const img = new Image();
        img.onload = function () {
          resolve({ width: img.width, height: img.height });
        };
        img.src = fr.result;
      };
      fr.readAsDataURL(file);
    });
  };

  const handleUploadFrontArt = async (file) => {
    console.log("handleUploadFrontArt");
    const filename = file.name;
    const storageRef = ref(storage, `${customAssetBucket}/custom-mock-overlays/${filename}`);
    const uploadTask = uploadBytesResumable(storageRef, file);
    
    setFrontProgress(0);
    uploadTask.on('state_changed', 
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload is ' + progress + '% done');
        switch (snapshot.state) {
          case 'paused':
            console.log('Upload is paused');
            break;
          case 'running':
            console.log('Upload is running');
            setFrontProgress(progress); 
            break;
        }
      }, 
      (error) => {
        // Handle unsuccessful uploads
      }, 
      () => {
        // Upload completed successfully, now we can get the download URL  
        setSize({...size, customMockPath: `custom-mock-overlays/${filename}`});
        setFrontProgress(100); 
      }
    );
  }

  const handleClickUploadFront = () => {
    console.log('handleClickUploadFront');
    dropzoneRef1.current.open();
  }

  const handleBackDimensionChange = (event) => {
    const { name: label, value } = event.target;
    const cleanValue = !isNaN(parseFloat(value)) ? parseFloat(value) : value;
    let property = "";

    switch (label) {
      case "backWidth":
        property = "width";
        break;
      case "backHeight":
        property = "height";
        break;
      default:
        property = "";
    }
    
    let _size = {...size};
    console.log(_size)
    _size.customPrintSheetBack = update(_size.customPrintSheetBack, {
      [property]: { $set: cleanValue }     
    });

    setSize(_size);
  }

  const types = _.filter(filterTypes, item=> {return item.name !== 'All' && item.name !== 'None'});
  const classes = useHeaderStyles();
  const scroll = 'paper';
  return (
    <Dialog onClose={handleCloseDialog} 
        aria-labelledby="revision-dialog-title" 
        open={open}         
        fullWidth={true} 
        maxWidth={"lg"} 
        scroll={scroll}
    >
      <DialogTitle sx={{padding:'30px 40px 10px'}}>
        <Typography variant="h2">{props.modal_name}</Typography>   
        <IconButton aria-label="close" variant='close' onClick={handleCloseDialog}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
        <DialogContent sx={{padding: '0 40px'}} dividers>
          <Grid container spacing={3} sx={{padding: '30px 0'}}>
            <Grid item xs={5}>              
                <InputLabel>Name</InputLabel>
                <BootstrapInput name="name" value={size.name} onChange={handleChange} />              
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={5}>              
                <InputLabel>SKU Suffix</InputLabel>
                <BootstrapInput name="sku" value={size.sku} onChange={handleChange} />              
            </Grid>
            <Grid item xs={5}>              
                <InputLabel>Type</InputLabel>
                <Select
                    name="type"
                    value={size.type}
                    input={<BootstrapInput />}
                    onChange={handleChange}
                >
                    <MenuItem value="">-</MenuItem>
                    {
                    _.map(types, item => (
                      <MenuItem value={item.name}>{item.name}</MenuItem>
                    ))
                    }
                </Select>              
            </Grid>
          </Grid>

          <Divider />

          <Grid item xs={12} md={5} sx={{padding: "40px 0"}}>
            <InputLabel>Mockup Overlay</InputLabel>
            <Box sx={{marginTop: '16px', width: '100%'}}>
              <Grid container spacing={3}>
                <Grid item xs={8}>      
                  {size.customMockPath && (
                    <ItemImage firebase={props.firebase.getFirebase()} classes={classes} progress={frontProgress} path={size.customMockPath} />
                  )}                  
                </Grid>
                <Grid item xs={4}>
                  <Dropzone 
                    ref={dropzoneRef1} 
                    onDrop={(files) => handleUploadFrontArt(files[0])} 
                    multiple={false}>
                    {({getRootProps, getInputProps}) => (
                      <Box {...getRootProps()} className={classes.dropBox}>
                        <input {...getInputProps()} accept="image/*"/>
                        <CloudUploadIcon />
                        <p className={classes.dropLabel}>Drag & Drop Artwork File</p>
                      </Box>
                    )}
                  </Dropzone>

                  <Button 
                    variant="blue" 
                    onClick={handleClickUploadFront} 
                  >Upload Front</Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>

          <Divider />

          <Box sx={{padding: '40px 0'}}>
            <InputLabel>Custom Product Print Sheet Dimension (Front)</InputLabel>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Box display="flex" alignItems="center">
                  <InputLabel className={classes.inputInlineLabel}>W</InputLabel>
                  <BootstrapInput type="number" name="frontWidth" value={size.customPrintSheetFront && size.customPrintSheetFront.width} onChange={handleFrontDimensionChange} />
                </Box>
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                <Box display="flex" alignItems="center">
                  <InputLabel className={classes.inputInlineLabel}>H</InputLabel>
                  <BootstrapInput type="number" name="frontHeight" value={size.customPrintSheetFront && size.customPrintSheetFront.height} onChange={handleFrontDimensionChange} />
                </Box>                
              </Grid>
            </Grid>
          </Box>

          <Divider />
          <Box sx={{padding: '40px 0'}}>
            <InputLabel>Custom Product Print Sheet Dimension (Back)</InputLabel>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Box display="flex" alignItems="center">
                  <InputLabel className={classes.inputInlineLabel}>W</InputLabel>
                  <BootstrapInput type="number" name="backWidth" value={size.customPrintSheetBack && size.customPrintSheetBack.width} onChange={handleBackDimensionChange} />
                </Box>
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={3}>
                <Box display="flex" alignItems="center">
                  <InputLabel className={classes.inputInlineLabel}>H</InputLabel>
                  <BootstrapInput type="number" name="backHeight" value={size.customPrintSheetBack && size.customPrintSheetBack.height} onChange={handleBackDimensionChange} />
                </Box>                
              </Grid>
            </Grid>
          </Box>
          
          <Divider />
        </DialogContent>

        <DialogActions  sx={{padding: '20px 40px', justifyContent: 'flex-start'}}>
          <Button variant="blue" color="primary" sx={{width:'120px'}} onClick={handleSave}>Save</Button>
        </DialogActions>
    </Dialog>
  );
}


const condition = authUser => !!authUser;
export default withAuthorization(condition)(SizeModal);
