import React from 'react';
import AuthUserContext from './context';
import { withFirebase } from '../Firebase';
import { getUser, getPermissions } from '../../db/user';
import { getFormats } from '../../db/formats';
import { getSizes } from '../../db/sizes';
import { getCropSettings } from '../../db/cropSettings';

const withAuthentication = Component => {
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        authUser: null,
        permissions: null,
        sizes: null,
        cropSettings: null,
        formats: null
      };
      this.fetchFormats = this.fetchFormats.bind(this);
      this.fetchSizes = this.fetchSizes.bind(this);
      this.fetchCropSettings = this.fetchCropSettings.bind(this); 
      //this.fetchPermissions = this.fetchPermissions.bind(this);
    }

    componentDidMount() {
      const db = this.props.firebase.getdb();
      this.listener = this.props.firebase.auth.onAuthStateChanged(
        authUser => {
          if (!authUser) {
            this.setState({authUser: null});
            return;
          }            

          getUser(db, authUser.uid)
          .then(user => {
            this.setState({authUser:{...user}});
          })
          .catch(() => {
            this.setState({authUser: null});
          })
        },
      );

      getFormats(db, 100, this.fetchFormats);

      getSizes(db, 100, this.fetchSizes);
        
      getCropSettings(db, 100, this.fetchCropSettings);

      //getPermissions(db, this.fetchPermissions);
     
    }

    componentWillUnmount() {
      this.listener();
    }

    fetchFormats(items) {
      this.setState({formats:[...items]});
    }

    fetchSizes(items) {
      this.setState({sizes:[...items]});
    }

    fetchCropSettings(items) {
      this.setState({cropSettings:[...items]});
    }

    fetchPermissions(items) {
      this.setState({permissions:[...items]});
    }

    render() {
      return (
        <AuthUserContext.Provider value={{user:this.state.authUser, cropSettings:this.state.cropSettings, formats:this.state.formats, sizes:this.state.sizes, permissions:this.state.permissions}}>
          <Component {...this.props} />
        </AuthUserContext.Provider>
      );
    }
  }

  return withFirebase(WithAuthentication);
};

export default withAuthentication;
