import React, { useState } from "react";
import { withRouter, useLocation } from "react-router-dom";
import { compose } from "recompose";
import { withFirebase } from "../Firebase";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import { updateUserSignedIn, getUserByUid } from "../../db/user";
import { COMMON_COLORS } from "../../theme/colors";
import logoIcon from "../../images/logoandicon.svg";
import { PasswordForgetLink } from "../PasswordForget";
import InputField from "../AuthComponents/TextField";
import LoadingButton from "../AuthComponents/Button";
import useIsMobile from "../../hooks/useIsMobileHook";

const SignInFormBase = ({ firebase }) => {
  const isMobile = useIsMobile();

  const [didUseRedirect, setDidUseRedirect] = useState(false);

  const [state, setState] = useState({
    email: "",
    password: "",
    showPassword: false,
    error_code: "",
    error_message: "",
    loading: false,
  });

  const { email, password, showPassword, error_code, error_message, loading } =
    state;

  const handleErrorMessageOutput = (error_code) => {
    console.log("Error code:", error_code);
    switch (error_code) {
      case "auth/user-not-found":
      case "auth/wrong-password":
      case "auth/invalid-email":
        return "The ID or Passcode you entered is incorrect.";
      default:
        return "An error occurred";
    }
  };

  const onSubmit = async (event) => {
    event.preventDefault();

    setState({ ...state, loading: true, error_code: "", error_message: "" });

    if (!email || !password) {
      setState({
        ...state,
        error_code: "Please fill in all fields",
        error_message: "Please fill in all fields",
        loading: false,
      });
      return;
    }

    try {
      const userCredential = await firebase.doSignInWithEmailAndPassword(
        email,
        password
      );
      const db = firebase.getdb();
      const user = await getUserByUid(db, userCredential.user.uid);
      if (user) {
        if (didUseRedirect) {
          // window.location.href = `http://localhost:5173/handler?uid=${user.uid}`;
          window.location.href = `https://rockem-fulfillment-live.web.app/handler?uid=${user.uid}`;
          return;
        }
        await updateUserSignedIn(db, user.uid);
        setState({ ...state, loading: false });
        localStorage.setItem("currentuser", user.email);
        window.location.href = `/`;
      } else {
        localStorage.setItem("currentuser", "");
      }
    } catch (error) {
      const errorMessage = handleErrorMessageOutput(error.code);
      setState({
        ...state,
        error_code: errorMessage,
        error_message: errorMessage,
        loading: false,
      });
    }
  };

  const onChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const toggleShowPassword = () => {
    setState({ ...state, showPassword: !showPassword });
  };

  React.useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const someParam = queryParams.get("isRedirect");
    if (someParam) {
      // Do something with the query parameter
      console.log("Query param:", someParam);
      setDidUseRedirect(true);
    }
  }, [location.search]);

  return (
    <Box sx={styles.container}>
      <style>
        {`
          input:focus {
            border-color: black;
            color: black;
            outline: none;
          }
  
          input::placeholder {
            color: #9C9C9C;
          }
        `}
      </style>

      <Box
        sx={{
          ...styles.imageAndBox,
          paddingBottom: isMobile ? "40%" : "0",
        }}
      >
        <Box sx={styles.loginHeader}>
          <img src={logoIcon} alt="logo" />
        </Box>

        <Box sx={styles.loginBox}>
          <Box sx={styles.titlesLogin}>
            <Typography
              component="h1"
              variant="h5"
              fontWeight={500}
              paddingBottom={0.5}
            >
              Login
            </Typography>
            <Typography component="h1" variant="body1" color={"#9C9C9C"}>
              Fill in your log in details below.
            </Typography>
          </Box>
          <Box component="form" onSubmit={onSubmit} sx={styles.formBox}>
            <InputField
              type="text"
              name="email"
              value={email}
              onChange={onChange}
              placeholder="Your ID"
            />
            <InputField
              type="password"
              name="password"
              value={password}
              onChange={onChange}
              placeholder="Your Passcode"
              showPassword={showPassword}
              toggleShowPassword={toggleShowPassword}
              isPassword
            />
            {error_code && (
              <Typography sx={styles.loginError}>{error_message}</Typography>
            )}
            <Box sx={{ paddingTop: 1, width: "100%" }}>
              <LoadingButton loading={loading} text="Login" />
            </Box>
          </Box>
        </Box>
        <PasswordForgetLink error={error_code === "" ? false : true} />
      </Box>
    </Box>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: COMMON_COLORS.blackBackground,
    height: "100vh",
    width: "100vw",
    justifyContent: "center",
  },
  loginError: {
    color: "#FF3838",
    fontSize: "12px",
    textAlign: "left",
    width: "100%",
    paddingBottom: "5px",
  },
  forgotPassword: {
    paddingTop: "20px",
    fontSize: "18px",
    color: "#7D7D7D",
  },
  titlesLogin: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  formBox: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
  },
  loginHeader: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: "10px",
  },
  loginBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "white",
    padding: "40px 20px",
    borderRadius: "10px",
    width: "80%", // Adjusted for mobile friendliness
    maxWidth: "400px", // Max width for larger screens
  },
  inputContainer: {
    margin: "10px 0",
    width: "100%",
  },
  passwordContainer: {
    position: "relative",
    width: "100%",
  },
  input: {
    width: "100%",
    padding: "13px",
    borderRadius: "12px",
    border: "1px solid #ccc",
    boxSizing: "border-box",
    backgroundColor: COMMON_COLORS.greyTextFields,
    color: "#9C9C9C",
  },
  passwordToggle: {
    position: "absolute",
    right: "10px",
    top: "50%",
    transform: "translateY(-50%)",
    background: "none",
    border: "none",
    cursor: "pointer",
    color: "#9C9C9C",
    fontSize: "12px",
  },
  buttonText: {
    color: "white",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "32px",
  },
  headerWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "20%", // Adjust this value to your preference
    width: "100%",
  },
  imageAndBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
};

const SignInPage = compose(withRouter, withFirebase)(SignInFormBase);

export default SignInPage;
