import React from 'react';

const useAspectRatio = (product) => {
  const [aspectRatio, setAspectRatio] = React.useState(0);

  React.useEffect(() => {
    // List of potential art sources
    const artSources = [
      product.hyperOpticIdenticalArt,
      product.frontArt,
      product.backArt,
      product.leftArt,
      product.rightArt,
      product.knittedSockArt,
      product.cutAndSewArt,
      product.packagingArt,
    ].filter(Boolean); // Filter out undefined art sources

    const validArtSources = artSources.filter(art => art.width && art.height);
    // Calculate the average aspect ratio of all valid art sources
    const totalAspectRatio = validArtSources.reduce((total, art) => {
      return total + (art.width / art.height);
    }, 0);

    const newAspectRatio = validArtSources.length > 0 ? totalAspectRatio / validArtSources.length : 0;

    setAspectRatio(newAspectRatio);

  }, [product]); // You might need to adjust this dependency array based on your needs

  return aspectRatio;
};

export default useAspectRatio;
