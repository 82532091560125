import React, { useState, useEffect } from 'react';
import update from "immutability-helper";
import { makeStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import InputBase from '@mui/material/InputBase';
import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Grid';


import {BootstrapInput} from '../Page/input';
import { resetUser } from '../../db/user';

const useHeaderStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: '10px',
    top: '10px',
    color: '#000000',
  },
  inputRoot: {
    width: '100%',
    fontSize: '14px',
    fontWeight: '500',
    letterSpacing: '1.5px',
    color: '#000000',
    background: 'rgba(0,0,0,0.04)',
    padding: '11px 15px',
    borderRadius: '4px'
  },
  inputLabel: {
    fontSize: '16px',
    fontWeight: '500',
    color: '#000000'
  },
  inputInlineLabel: {
    fontSize: '16px',
    fontWeight: '500',
    color: '#000000',
    marginTop: '15px',
    marginRight: '25px'
  },
  slotHeading: {
    fontSize: '24px',
    fontWeight: '500',
    color: '#000000',
    flexBasis: 'calc(100% - 250px)',
    flexShrink: 0,
  },
  slotLabel: {
    fontSize: '16px',
    fontWeight: '500',
    color: '#000000',  
  },
  tableLabel: {
    fontSize: '14px',
    fontWeight: '500',
    color: '#000000',  
    marginRight: '10px'
  },
  addButton: {
    background: '#3D3D3D',
    padding: '8px 32px',
    marginRight: '15px',
    borderRadius: '6px',
    fontSize: '16px',
    fontWeight: '500',
    color: '#FFFFFF',
    textTransform: 'initial'
  },
  blueButton: {
    background: '#1F7CF2',
    padding: '8px 56px',
    borderRadius: '6px',
    fontSize: '16px',
    fontWeight: '500',
    color: '#FFFFFF',
    textTransform: 'initial',
    "&.Mui-disabled": {
      background: "rgba(0, 0, 0, 0.12)!important",
      color: "rgba(0, 0, 0, 0.26)"
    }
  },
  normalAccordion: {
    background: 'rgba(242, 244, 246, 0.4)'
  },
  expandedAccordion: {
    background: '#F1F7FE'
  }
}));


function ResetModal(props) {
  const {handleClose, open, userId} = props;
  const [password, setPassword] = useState("");

  useEffect(() => {
    if (open) {
        setPassword("");
    }
  }, [open]);

  const handleReset = () => {
    resetUser(userId, password);
    handleClose();
  }

  const handleCloseDialog = (event) => {
    handleClose(); 
  }

  const handleChange = (event) => {
    let { name, value} = event.target;
    
    setPassword(value);
  }

  const classes = useHeaderStyles();
  const scroll = 'paper';
  return (
    <Dialog onClose={handleCloseDialog} 
        aria-labelledby="reset-dialog-title" 
        open={open}         
        fullWidth={true} 
        maxWidth={"sm"} 
        scroll={scroll}
    >
        <DialogTitle onClose={handleCloseDialog} sx={{padding: '40px 40px 20px', position: 'relative'}}>
          <Typography variant="h1">Reset Password</Typography>   
          <IconButton aria-label="close" className={classes.closeButton} onClick={handleCloseDialog}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent >
            <Box sx={{padding:'10px 15px'}}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <InputLabel>Password</InputLabel>
                        <BootstrapInput type="password" name="password" onChange={handleChange} value={password} />                
                    </Grid>
                </Grid>   
            </Box>
        </DialogContent>

        <DialogActions  sx={{padding: '40px', justifyContent: 'flex-start'}}>
          <Button variant="contained" color="primary" className={classes.blueButton} disabled={password===""} onClick={handleReset}>Reset</Button>
        </DialogActions>
    </Dialog>
  );
}

export default ResetModal;
