import React, { useState } from "react";
import {
  Modal,
  Box,
  Button,
  TextField,
  Typography,
  IconButton,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const EditOrderModal = ({
  order,
  isOpen,
  handleClose,
  handleSubmit,
  isLoading,
  errorText,
  ORDER_KEYS_MAP,
}) => {
  const [formData, setFormData] = useState(order);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const onSubmit = () => {
    // only send back the 5 data sets in the form...
    const formDataUpdated = {
      ...formData,
      [ORDER_KEYS_MAP.orderNumber]: formData[ORDER_KEYS_MAP.orderNumber],
      [ORDER_KEYS_MAP.customerName]: formData[ORDER_KEYS_MAP.customerName],
      [ORDER_KEYS_MAP.address1]: formData[ORDER_KEYS_MAP.address1],
      [ORDER_KEYS_MAP.extraAddress]: formData[ORDER_KEYS_MAP.extraAddress],
      [ORDER_KEYS_MAP.city]: formData[ORDER_KEYS_MAP.city],
      [ORDER_KEYS_MAP.state]: formData[ORDER_KEYS_MAP.state],
      [ORDER_KEYS_MAP.postalCode]: formData[ORDER_KEYS_MAP.postalCode],
    };
    handleSubmit(formDataUpdated);
  };

  return (
    <Modal open={isOpen} onClose={handleClose}>
      {!isLoading ? (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            p: 4,
            boxShadow: 24,
            width: "80%",
            maxWidth: "600px",
            borderRadius: "8px",
          }}
        >
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h6" gutterBottom>
              Edit Order: {formData[ORDER_KEYS_MAP.orderNumber]}
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>

          <TextField
            fullWidth
            margin="normal"
            label="Name"
            name={ORDER_KEYS_MAP.customerName} // Use the mapped key for customerName
            value={formData[ORDER_KEYS_MAP.customerName]}
            onChange={handleInputChange}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Street 1"
            name={ORDER_KEYS_MAP.address1} // Use the mapped key for address1
            value={formData[ORDER_KEYS_MAP.address1]}
            onChange={handleInputChange}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Street 2"
            name={ORDER_KEYS_MAP.extraAddress} // Use the mapped key for extraAddress
            value={formData[ORDER_KEYS_MAP.extraAddress]}
            onChange={handleInputChange}
          />
          <TextField
            fullWidth
            margin="normal"
            label="City"
            name={ORDER_KEYS_MAP.city} // Use the mapped key for city
            value={formData[ORDER_KEYS_MAP.city]}
            onChange={handleInputChange}
          />
          <TextField
            fullWidth
            margin="normal"
            label="State"
            name={ORDER_KEYS_MAP.state} // Use the mapped key for state
            value={formData[ORDER_KEYS_MAP.state]}
            onChange={handleInputChange}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Postal Code"
            name={ORDER_KEYS_MAP.postalCode} // Use the mapped key for postalCode
            value={formData[ORDER_KEYS_MAP.postalCode]}
            onChange={handleInputChange}
          />

          {errorText && (
            <Typography variant="body1" color="error" align="center">
              {errorText}
            </Typography>
          )}
          <Box mt={2} display="flex" justifyContent="center">
            <Button
              variant="contained"
              onClick={onSubmit}
              sx={{
                color: "white",
                backgroundColor: "green",
              }}
            >
              Update order
            </Button>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            p: 4,
            boxShadow: 24,
            width: "80%",
            maxWidth: "600px",
            borderRadius: "8px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CircularProgress />
          <Typography variant="h6" gutterBottom>
            Updating...
          </Typography>
        </Box>
      )}
    </Modal>
  );
};

export default EditOrderModal;
