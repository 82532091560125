import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { withAuthorization } from '../Session';

const useHeaderStyles = makeStyles((theme) => ({
      search: {
        width: '470px',
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: 'rgba(255, 255, 255, 0.04)',
        '&:hover': {
          backgroundColor: 'rgba(255, 255, 255, 0.08)',
        },    
        marginLeft: 0,
      },
      headerAction: {
        width: '680px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
      },
      headerButton: {
        width: '160px',
        marginLeft: '15px'
      },
      headerText: {
        fontSize: '14px',
        fontWeight: '500',
        letterSpacing: '1.5px',
        color: '#000000',
        opacity: '0.4'
      },
      addButton: {
        marginLeft: '15px',
        background: '#1f7cf2',
        borderRadius: '6px',
        width: '160px',
        height: '40px',
        fontSize: '16px',
        fontWeight: '500',
        color: '#FFFFFF',
        textTransform: 'initial',
        '&:hover': {
          background: 'rgba(31, 124, 242, 0.8)',
        },
      },
  }));

function PageHeader(props) {
  const {handleAdd, users} = props;  
  const classes = useHeaderStyles();

  return (
      <div className="pageHeader">
        <div className={classes.pageHeading}>
          <Typography variant="h1">User Management</Typography> 
          <Typography variant='subtitle1'><strong>{users}</strong> Total Users</Typography>   
        </div>

        <div className={classes.headerAction}>
          {props.editOnly && (
          <Button variant="blue" component="span" sx={{width:'160px'}} onClick={handleAdd?handleAdd:null} >
            Add User     
          </Button>
          )}
        </div>              
      </div>
  );
}


const condition = authUser => !!authUser;
export default withAuthorization(condition)(PageHeader);
