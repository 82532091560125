import { Box, CircularProgress, Typography, Backdrop} from "@mui/material";
import React from "react";

export default function Loading() {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
      <Backdrop open={open} style={{ zIndex: 9999, color: '#fff' }}>
          <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
}