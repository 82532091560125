import * as React from "react";
const BadgeSVG = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={76}
    height={77}
    fill="none"
    {...props}
  >
    <path
      fill="#000"
      fillRule="evenodd"
      d="M10.695 16.594C9.5 18.297 9.5 23.36 9.5 33.486v4.986c0 17.854 13.423 26.518 21.845 30.197 2.285.998 3.427 1.497 6.655 1.497s4.37-.499 6.655-1.497C53.077 64.99 66.5 56.326 66.5 38.472v-4.986c0-10.126 0-15.189-1.195-16.892-1.196-1.703-5.956-3.333-15.477-6.592l-1.814-.62C43.05 7.681 40.569 6.832 38 6.832c-2.57 0-5.05.85-10.014 2.548l-1.814.621c-9.52 3.26-14.281 4.889-15.477 6.592Zm36.993 17.154a2.375 2.375 0 1 0-3.543-3.163l-9.538 10.682-2.752-3.082a2.375 2.375 0 1 0-3.543 3.163l4.524 5.067a2.375 2.375 0 0 0 3.543 0l11.31-12.667Z"
      clipRule="evenodd"
    />
  </svg>
);
export default BadgeSVG;
