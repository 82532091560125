import { collections, SIZES } from '../constants/defines';
import { getMatchedCropSettings, getMatchedCropSettingByFront, getMatchedCropSettingByBack } from './cropSettings';
import { getMatchedTemplate } from './templates';
import { getBoxsetsBySku } from './boxsets'
import _ from "lodash";
import {
  collection,
  query,
  where,
  limit,
  getDocs,
  orderBy,
  doc,
  updateDoc,
  addDoc,
  setDoc,
  getDoc,
  deleteDoc,
  onSnapshot,
  writeBatch
} from "firebase/firestore";
import { ITEM_TYPES, validateImagePathFromDB, whatProductIsItBasedOffArts } from '../utils/updateItemIfSaysMissingArt';

const PRODUCT_DEFAULT = {
  frontArt: {},
  backArt: {},
  pdfs: [],
  variants: [],
  templates: [],
  vendors: [],
  type: "",
  style: "",
  format: "",
  tags: "",
  name: "",
  size: "",
  sku: "",
  id: "",
  color: "",
  isCustomProduct: false,
  isBundle: false,
  isMask: false,
  identicalHyperOptic: {},
  pathFront: "",
  pathBack: "",
  dimension: false,
  identical: false,
  leftArt: {},
  rightArt: {},
  pathLeft: "",
  pathRight: "",
  identicalHyperOpticPath: "",
  isBoxedImageArt: {},
  pathHyperOpticIdentical: "",
  hyperOpticIdenticalArt: {},
  isBoxedImagePath: "",
};

const getProductBySku = async (db, sku, callback) => {
  try {
    const q = query(collection(db, collections.PRODUCTS), where("sku", "==", sku));
    const querySnapshot = await getDocs(q);

    let _item = { ...PRODUCT_DEFAULT };
    let foundProduct = false;

    querySnapshot.forEach((doc) => {
      if (doc.exists) {
        const data = doc.data();
        foundProduct = true;
        _item = { ...PRODUCT_DEFAULT, ...data };
        _item.id = doc.id;
        _item.type = data.type;
      }
    });

    if (!foundProduct) {
      console.log('No such document! Trying variantSingleSku: ', sku);
      const itemsCollRef = collection(db, 'Items');
      const singlesQuery = query(itemsCollRef, where('variantSingleSku', '==', sku));
      const singlesSnapshot = await getDocs(singlesQuery);

      singlesSnapshot.forEach((doc) => {
        if (doc.exists) {
          const data = doc.data();
          foundProduct = true;
          _item = { ...PRODUCT_DEFAULT, ...data };
          _item.id = doc.id;
          _item.type = data.type;
        }
      });
    }

    callback(_item);
  } catch (error) {
    console.log('getProductBySKU: ', error);
  }
};


// promise version... 
const getBySku = (db, sku, typeAndStyle = false, isMixMatch = false) => {
  return new Promise((resolve, reject) => {

    const whereClause = !isMixMatch ? "sku" : "variantSingleSku";

    const q = query(collection(db, collections.PRODUCTS), where(whereClause, "==", sku));
    getDocs(q)
      .then((querySnapshot) => {
        let _item = { ...PRODUCT_DEFAULT };
        querySnapshot.forEach((doc) => {
          if (doc.exists) {
            const data = doc.data();
            _item = { ...PRODUCT_DEFAULT, ...data };
            _item.id = doc.id;

            if (typeAndStyle) {
              resolve({ type: _item.type, style: _item.style, sku: _item.sku, size: _item.size });
            }

          }
        });
        resolve(_item);
      })
      .catch(error => {
        console.log('getProductBySKU: ', error);
        reject();
      });
  });

}

const getBundleBySku = (db, sku) => {
  return new Promise((resolve, reject) => {
    const q = query(collection(db, collections.BOXSETS), where("sku", "==", sku));
    getDocs(q)
      .then((querySnapshot) => {
        let _item = { ...PRODUCT_DEFAULT };
        querySnapshot.forEach((doc) => {
          if (doc.exists) {
            const data = doc.data();
            _item = { ...PRODUCT_DEFAULT, ...data };
            _item.id = doc.id;
          }
        });
        resolve(_item);
      })
      .catch(error => {
        console.log('getProductBySKU: ', error);
        reject();
      });
  });
}

const browseObject = async (db, options, sizes, formats, templates, callback) => {
  const rows = [
    ["sku",
      "name",
      "variants",
      "type",
      "style",
      "format",
      "size",
      "pathFront",
      "frontSize",
      "frontWidth",
      "frontHeight",
      "pathBack",
      "backSize",
      "backWidth",
      "backHeight",
      "templates"]
  ];
  let csvContent = "";
  rows.forEach(function (rowArray) {
    let row = rowArray.join(",");
    csvContent += row + "\r\n";
  });

  let last = null, totalCount = 0, matchedCount = 0;
  do {
    // code block to be executed
    let query = db
      .collection(collections.PRODUCTS)
      .orderBy('sku')
      .startAfter(last)
      .limit(1000);
    /*
        if (options.type !== "" && options.type != "All")
          query = query.where('type', '==', options.type);
        else 
          query = query.where('type', '!=', options.type);
    
        if (options.style !== "" && options.style != "All")
          query = query.where('style', '==', options.style);
        else 
          query = query.where('style', '!=', options.style);
    
        if (options.format !== "" && options.format != "All")
          query = query.where('format', '==', options.format);
        else 
          query = query.where('format', '!=', options.format);
          
        if (options.size !== "" && options.size != "All")
          query = query.where('size', '==', options.size);
        else 
          query = query.where('size', '!=', options.size);
    */

    const snapshot = await query.get();
    if (snapshot.docs.length > 0) {
      totalCount += snapshot.docs.length;
      snapshot.docs.forEach(doc => {
        if (doc.exists) {
          const data = doc.data();
          console.log('SKU: ', data.sku);
          let filterTemplate = true;
          if (options.template !== "" && options.template !== "All") {
            filterTemplate = _.filter(data.templates, { template: options.template }).length > 0;
          }
          let filterType = true;
          if (options.type !== "" && options.type != "All") {
            filterType = options.type === data.type;
          }

          let filterStyle = true;
          if (options.style !== "" && options.style != "All") {
            filterStyle = options.style === data.style;
          }

          let filterSize = true;
          if (options.size !== "" && options.size != "All") {
            filterSize = options.size === data.size;
          }

          let filterFormat = true;
          if (options.format !== "" && options.format != "All") {
            filterFormat = options.format === data.format;
          }

          if (!!data.sku && filterTemplate && filterType && filterStyle && filterSize && filterFormat) {

            let _templates = "", _variants = "";

            if (!!data.variants) {
              const variants = _.map(data.variants, item => item.sku);
              _variants = variants.join(',');
            }

            _.each(data.templates, item => {
              _templates += _.find(templates, { id: item.template }).name;
            });

            let _size = "", _format = "";
            if (data.size !== "") {
              _size = _.find(sizes, { id: data.size }).name;
            }

            if (data.format !== "") {
              _format = _.find(formats, { id: data.format }) ? _.find(formats, { id: data.format }).name : '';
            }

            const frontArt = { width: '', height: '', size: '', ...data.frontArt };
            const backArt = { width: '', height: '', size: '', ...data.backArt };

            const frontSize = !!frontArt.size ? parseFloat(Math.round((frontArt.size / 1000000 + Number.EPSILON) * 10) / 10) + ' MB' : '';
            const backSize = !!backArt.size ? parseFloat(Math.round((backArt.size / 1000000 + Number.EPSILON) * 10) / 10) + ' MB' : '';

            csvContent += `${data.sku},${data.name},${_variants},${data.type},${data.style},${_format},${_size},${data.pathFront},${frontSize},${frontArt.width},${frontArt.height},${data.pathBack},${backSize},${backArt.width},${backArt.height},${_templates}\r\n`;
            matchedCount++;
          }
        }
      });
    }
    // Get the last document
    last = snapshot.docs[snapshot.docs.length - 1];
    console.log('Length: ', snapshot.docs.length);
  }
  while (last);

  console.log('Total Count: ', totalCount);
  console.log('Matched Count: ', matchedCount);
  var blob = new Blob(["\ufeff", csvContent]);
  var url = URL.createObjectURL(blob);
  var downloadLink = document.createElement("a");
  downloadLink.href = url;

  const baseBatchId = Date.now();
  downloadLink.download = `Exports_${baseBatchId}.csv`;

  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);

  callback(matchedCount);

}

const exportProducts = (db, options, sizes, formats, templates, callback) => {
  console.log('getProducts Filter Option: ', options.filters);
  browseObject(db, options, sizes, formats, templates, callback);
}

const getProductsBySku = (db, items) => {
  const skus = items.map(item => item.sku);
  const requests = _.map(skus, (sku) => {
    const q = query(collection(db, collections.PRODUCTS), where("sku", "==", sku), limit(1));
    return getDocs(q);
  });

  // Make requests
  return Promise.all(requests)
    .then((snaps) => {
      /**
       * Filter existing/missing docs
       * Since limit was 1, and there should be only one item with this sku,
       * it's safe to just grab the first doc in each snap.
       */
      const docs = _.map(snaps, (snap) => snap.docs[0]);

      const _items = _.map(docs, (doc, index) => {
        if (!!doc) {
          const data = doc.data();
          return {
            exists: doc.exists(),
            sku: data.sku,
            name: data.name,
            isMask: data.isMask,
            qty: items[index].quantity,
            format: data.format,
            size: data.size,
            type: data.type,
            style: data.style,
            frontArt: !!data.frontArt ? data.frontArt : null,
            backArt: !!data.backArt ? data.backArt : null,
            pathHyperOpticIdentical: data.pathHyperOpticIdentical ?? "",
            isBoxedImagePath: data.isBoxedImagePath ?? "",
            pathRight: data.pathRight ?? "",
            pathLeft: data.pathLeft ?? "",
            pathBack: data.pathBack ?? "",
            pathFront: data.pathFront ?? "",
          };

        } else {
          return {
            exists: false,
            sku: items[index].sku,
            name: '',
            qty: items[index].quantity,
            isMask: false,
            format: '',
            size: '',
            type: '',
            style: '',
            frontArt: null,
            backArt: null
          };
        }
      });

      return _items;
    })
    .catch((error) => {
      console.log('getProductsBySku: ', error);
    });
}

const getProductVariants = (db, sku) => {
  return new Promise((resolve, reject) => {
    const q = query(collection(db, collections.PRODUCTS), where("variants", "array-contains", sku));
    getDocs(q)
      .then((querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          items.push(data.sku);
        });
        resolve({ variant: sku, skus: items });
      })
      .catch(error => {
        console.log('getProductBySKU: ', error);
        reject();
      });
  });
}

const getProductByVariantSku = (db, sku) => {
  return new Promise((resolve, reject) => {
    const q = query(collection(db, collections.PRODUCTS), where("variants", "array-contains", sku));
    getDocs(q)
      .then(async (querySnapshot) => {
        const promises = querySnapshot.docs.map(doc => {
          const data = doc.data();
          // Call getBySku for each document and return the promise
          return getBySku(db, data.sku);
        });

        // Wait for all getBySku promises to resolve
        Promise.all(promises)
          .then(items => {
            // items will be an array of all resolved product data from getBySku
            resolve({ variant: sku, skus: items }); // Adjust if getBySku returns an object structure different than expected
          })
          .catch(error => {
            console.log('Error processing product details:', error);
            reject(error);
          });
      })
      .catch(error => {
        console.log('Error querying products by variant SKU:', error);
        reject(error);
      });
  });
}

const getProductsByVariant = (db, items) => {
  const skus = items.map(item => item.sku);
  const requests = _.map(skus, (sku) => {
    const q = query(collection(db, collections.PRODUCTS), where("variants", "array-contains", sku), limit(1));
    return getDocs(q);
  });

  // Make requests
  return Promise.all(requests)
    .then((snaps) => {
      /**
       * Filter existing/missing docs
       * Since limit was 1, and there should be only one item with this sku,
       * it's safe to just grab the first doc in each snap.
       */
      const docs = _.map(snaps, (snap) => snap.docs[0]);
      const _items = _.map(docs, (doc, index) => {
        if (!!doc) {
          const data = doc.data();
          return {
            exists: doc.exists(),
            sku: data.sku,
            name: data.name,
            qty: items[index].qty,
            format: data.format,
            size: data.size,
            type: data.type,
            style: data.style,
            isMask: data.isMask,
            frontArt: !!data.frontArt ? data.frontArt : null,
            backArt: !!data.backArt ? data.backArt : null,
            pathHyperOpticIdentical: data.pathHyperOpticIdentical ?? "",
            isBoxedImagePath: data.isBoxedImagePath ?? "",
            pathRight: data.pathRight ?? "",
            pathLeft: data.pathLeft ?? "",
          };

        } else {
          return {
            exists: false,
            sku: items[index].sku,
            name: '',
            isMask: false,
            qty: items[index].qty,
            format: '',
            size: '',
            type: '',
            style: '',
            frontArt: null,
            backArt: null
          };
        }
      });
      return _items;
    })
    .catch((error) => {
      console.log('getProductsBySku: ', error);
    });
}

const createProduct = (db, item) => {
  const newItem = { ...item };
  const currentTime = Date();
  newItem.sku = item.sku.toUpperCase();
  newItem.dimension = false;
  newItem.created = currentTime;
  newItem.createdBy = localStorage.getItem("currentuser");

  newItem.isMask = item.type === 'Masks';
  newItem.isCustomProduct = item.templates.length > 0;

  return new Promise((resolve, reject) => {
    const docRef = doc(db, collections.PRODUCTS, item.sku);

    setDoc(docRef, newItem)
      .then(() => {
        console.log("New Product created");
        resolve();
      })
      .catch((error) => {
        console.error("Error adding Product: ", error);
        reject();
      });
  });
}

const updateProduct = (db, item, sku) => {
  let product = {
    name: item.name,
    sku: item.sku.toUpperCase(),
    variants: item.variants,
    tags: item.tags,
    type: item.type,
    style: item.style,
    format: item.format,
    size: item.size,
    isCustomProduct: item.isCustomProduct,
    color: item.color,
    isMask: item.type === 'Masks',
    isBundle: item.isBundle,
    frontArt: item.frontArt,
    backArt: item.backArt,
    templates: item.templates,
    vendors: item.vendors,
    pdfs: item.pdfs,
    pathFront: item.pathFront,
    pathBack: item.pathBack,
    dimension: false,
    identical: item.identical,
    identicalHyperOptic: item.identicalHyperOptic,
    hyperOpticIdenticalArt: item.hyperOpticIdenticalArt,
    pathLeft: item.pathLeft,
    pathRight: item.pathRight,
    rightArt: item.rightArt,
    leftArt: item.leftArt,
    pathHyperOpticIdentical: item.pathHyperOpticIdentical,
    isBoxedImageArt: item.isBoxedImageArt,
    isBoxedImagePath: item.isBoxedImagePath,
    errors: item.errors,
  };

  const isHyperIdentical = item.style === 'HyperOptic' && item.pathLeft === '' && item.pathRight === '';

  console.log("🚀 [Save Product] ~ updateProduct ~ isHyperIdentical:", isHyperIdentical)

  if (isHyperIdentical) {
    product.variantSingleSku = item.variantSingleSku
  }

  const productWithNulls = Object.fromEntries(
    Object.entries(product).map(([key, value]) => [key, value === undefined ? null : value])
  );

  return new Promise((resolve, reject) => {
    const docRef = doc(db, collections.PRODUCTS, item.id);
    updateDoc(docRef, productWithNulls)
      .then(() => {
        console.log("Product successfully updated!");
        resolve();
      })
      .catch((error) => {
        // The document probably doesn't exist.
        console.error("Error updating Product: ", error);
        reject();
      });
  });
}

const deleteProduct = (db, item) => {
  return new Promise((resolve, reject) => {
    deleteDoc(doc(db, collections.PRODUCTS, item.id))
      .then(() => {
        console.log("Product successfully deleted!");
        resolve();
      })
      .catch((error) => {
        // The document probably doesn't exist.
        console.error("Error deleting Product: ", error);
        reject();
      });
  });
}

const checkArtDimension = (product, cropsettingsList, width, height, isFront) => {
  let cropped = false, errorDimension = false;
  const isSolo = !!product.identical;
  const isMask = !!product.isMask;
  const side = isFront ? "Front" : "Back";

  let ret = { cropped: false, error: false };
  const cropSetting = isFront ? getMatchedCropSettingByFront(cropsettingsList, width, height) : getMatchedCropSettingByBack(cropsettingsList, width, height);
  if (cropsettingsList.length == 0) {
    cropped = true;
    errorDimension = false;
  } else if (!!cropSetting) {
    cropped = false;
    if (isSolo && cropSetting.artType !== "solo") {
      errorDimension = true;
    } else {
      errorDimension = product.size !== cropSetting.size ||
        product.format !== cropSetting.format ||
        product.type !== cropSetting.type ||
        product.style !== cropSetting.style;
    }

  } else {
    cropped = true;
    errorDimension = true;
  }
  return errorDimension;
}

const checkCustomArtDimension = (product, templates, width, height, isFront) => {
  let cropped = false, errorDimension = false;

  if (templates.length == 0 || product.templates.length == 0) {
    console.log('No registered Templates');
    return { cropped: true, error: false };
  }

  const template = getMatchedTemplate(templates, product.templates[0].template);
  if (!!template) {
    cropped = false;
    errorDimension = product.size !== template.size || template.canvas.width !== width || template.canvas.height !== height;
  } else {
    console.log('No Template');
    cropped = true;
    errorDimension = true;
  }
  return { cropped, error: errorDimension, template };
}

const validateCustomProduct = (product, templates) => {
  let ret = { valid: false, msg: '' };
  const isMask = !!product.isMask;

  const frontArt = !!product.frontArt && !!product.frontArt.path ? product.frontArt : null;
  const backArt = !!product.backArt && !!product.backArt.path ? product.backArt : null;

  if (!product.style || !product.type || !product.format || !product.size) {
    console.log('Incorrect Spec');
    ret.msg = 'Incorrect Spec';
    return ret;
  }

  if ((isMask && !backArt) || (!isMask && (!frontArt || !backArt))) {
    console.log('Missing Art');
    ret.msg = 'Missing Art';
    return ret;
  }

  if (!product.templates || product.templates.length == 0) {
    console.log('No Template');
    ret.msg = 'No Template';
    return ret;
  }

  //front Art Check
  if (frontArt) {
    const frontArtWidth = frontArt.width;
    const frontArtHeight = frontArt.height;

    const result = checkCustomArtDimension(product, templates, frontArtWidth, frontArtHeight, true);
    if (result.error) {
      console.log('Front Art Incorrect Dimension');
      ret.msg = 'Incorrect Dimension';
      return ret;
    }
  }

  //back Art Check
  if (backArt) {
    const backArtWidth = backArt.width;
    const backArtHeight = backArt.height;

    const result = checkCustomArtDimension(product, templates, backArtWidth, backArtHeight, false);
    if (result.error) {
      console.log('Back Art Incorrect Dimension');
      ret.msg = 'Incorrect Dimension';
      return ret;
    }
  }

  console.log('Valid Custom Product: ', product.sku);
  ret.valid = true;
  return ret;
}

const validateNonCustomProduct = (product, cropSettings, skipValidation) => {
  // if product.style is not 'Graphic', return validation as all good. 
  if (product.style !== 'Graphic') {
    return { valid: true, msg: '' };
  }

  let ret = { valid: false, msg: '' };

  const isMask = !!product.isMask;

  const frontArt = !!product.frontArt && !!product.frontArt.path ? product.frontArt : null;
  const backArt = !!product.backArt && !!product.backArt.path ? product.backArt : null;


  if (!product.style || !product.type || !product.format || !product.size) {
    console.log('Incorrect Spec');
    ret.msg = 'Incorrect Spec';
    return ret;
  }


  if ((isMask && !backArt) || (!isMask && (!frontArt || !backArt))) {
    ret.msg = 'Missing Art';
    return ret;
  }

  const cropsettingsList = getMatchedCropSettings(cropSettings, product.type, product.style, product.size, product.format);
  //front Art Check
  if (frontArt) {
    const frontArtWidth = frontArt.width;
    const frontArtHeight = frontArt.height;

    const result = checkArtDimension(product, cropsettingsList, frontArtWidth, frontArtHeight, true);
    if (result.error) {
      console.log('Front Art Incorrect Dimension');
      ret.msg = 'Incorrect Dimension';
      return ret;
    }
  }

  //back Art Check
  if (backArt) {
    const backArtWidth = backArt.width;
    const backArtHeight = backArt.height;

    const result = checkArtDimension(product, cropsettingsList, backArtWidth, backArtHeight, false);
    if (result.error) {
      console.log('Back Art Incorrect Dimension');
      ret.msg = 'Incorrect Dimension';
      return ret;
    }
  }

  console.log('Valid Non-Custom Product: ', product.sku);
  ret.valid = true;
  return ret;
}

const validateProduct = async (product, cropSettings, storage, skipValidation) => {
  let ret = { valid: false, msg: '' };
  const isMask = !!product.isMask;

  const frontArt = !!product.frontArt && !!product.frontArt.path ? product.frontArt : null;
  const backArt = !!product.backArt && !!product.backArt.path ? product.backArt : null;

  if (!product.style || !product.type || !product.format || !product.size) {
    console.log('Incorrect Spec');
    ret.msg = 'Incorrect Spec';
    return ret;
  }

  if (!skipValidation) {
    let productType = whatProductIsItBasedOffArts(product);

    const validateImages = await validateImagePathFromDB(product, storage, productType);

    if (!validateImages) {
      ret.msg = 'Missing Art';
      return ret;
    }

    if (productType === ITEM_TYPES.HYPER_OPTIC.FRATERNAL || productType === ITEM_TYPES.HYPER_OPTIC.IDENTICAL || productType === ITEM_TYPES.BOXED) {
      return { valid: true, msg: '' };
    }
  }

  if ((isMask && !backArt) || (!isMask && (!frontArt || !backArt))) {
    console.log('Missing Art, OUT VAL');
    ret.msg = 'Missing Art';
    return ret;
  }

  const cropsettingsList = getMatchedCropSettings(cropSettings, product.type, product.style, product.size, product.format);
  //front Art Check
  if (frontArt) {
    const frontArtWidth = frontArt.width;
    const frontArtHeight = frontArt.height;

    const error = checkArtDimension(product, cropsettingsList, frontArtWidth, frontArtHeight, true);
    if (error) {
      console.log('Front Art Incorrect Dimension');
      ret.msg = 'Incorrect Dimension';
      return ret;
    }
  }

  //back Art Check
  if (backArt) {
    const backArtWidth = backArt.width;
    const backArtHeight = backArt.height;

    const error = checkArtDimension(product, cropsettingsList, backArtWidth, backArtHeight, false);
    if (error) {
      console.log('Back Art Incorrect Dimension');
      ret.msg = 'Incorrect Dimension';
      return ret;
    }
  }

  ret.valid = true;
  return ret;
}


const checkBarcodeLength = (barcodes, vendors) => {

  let isError = false, msg = '';
  for (let i = 0; i < vendors.length; i++) {
    const vendor = vendors[i];
    const barcodeNumber = vendor.barcode;

    const template = _.find(barcodes, { id: vendor.template })

    if (!template) {
      isError = true;
      msg = `Barcode Template is not defined.`;
      break;
    }

    const type = template.type;

    let expectedLen = 0;
    if (type === "pharmacode")
      expectedLen = 4;
    else if (type === "codabar")
      expectedLen = 10;
    else if (type === "CODE128")
      expectedLen = 11;
    else if (type === "CODE39")
      expectedLen = 14;
    else if (type === "EAN13")
      expectedLen = 13;
    else if (type === "UPC")
      expectedLen = 12;
    else if (type === "EAN8")
      expectedLen = 8;
    else if (type === "EAN5")
      expectedLen = 5;
    else if (type === "EAN2")
      expectedLen = 2;
    else if (type === "ITF14")
      expectedLen = 14;
    else if (type === "MSI")
      expectedLen = 4;

    if (barcodeNumber.length !== expectedLen) {
      isError = true;
      msg = `Barcode # should be ${expectedLen} letters.`;
      break;
    }
  }
  return { error: isError, msg };
}

const checkProduct = (sku, exists, isCustomProduct, product, cropSettings, templates) => {
  let error = '';

  if (!exists) {
    error = 'Product Not Found';

  } else if (isCustomProduct) {
    const ret = validateCustomProduct(product, templates);
    if (!ret.valid)
      error = ret.msg;

  } else {
    if (product?.style.toLowerCase() === 'graphic') {
      const ret = validateNonCustomProduct(product, cropSettings);
      if (!ret.valid)
        error = ret.msg;
    } else {
      console.log('Product Style is not Graphic... skip validation');
      error = '';
    }

  }

  console.log(`Product:${sku} =>  error? : ${error}`);

  return error;

}

const lookupItems = (db, _items, cropSettings, templates) => {
  let products = _.map(_items, item => {
    return { ...item };
  })

  // Look up products by sku
  return getProductsBySku(db, _items)
    .then((response) => {
      // Record whether or not the skus were found
      _.each(products, (item, index) => {

        // console.log(first)

        const rspItem = response[index];
        const exists = rspItem.exists;
        const isCustomProduct = exists && rspItem.isCustomProduct ? rspItem.isCustomProduct : false;
        const isMask = exists && rspItem.isMask ? rspItem.isMask : false;
        const isBundle = exists && !!rspItem.isBundle ? rspItem.isBundle : false;

        item.exists = exists;
        item.isCustomProduct = isCustomProduct;
        item.isMask = isMask;
        item.isBundle = isBundle;
        item.error = checkProduct(item.sku, exists, isCustomProduct, rspItem, cropSettings, templates);
        item.available = item.error === "";
        item.style = exists ? rspItem.style : "";;
        item.size = exists ? rspItem.size : "";
        item.name = exists ? rspItem.name : "";
        item.format = exists ? rspItem.format : "";
        item.pathFront = exists ? rspItem.pathFront : "";
        item.pathBack = exists ? rspItem.pathBack : "";
        item.pathHyperOpticIdentical = exists ? rspItem.pathHyperOpticIdentical : "";
        item.isBoxedImagePath = exists ? rspItem.isBoxedImagePath : "";
        item.pathRight = exists ? rspItem.pathRight : "";
        item.pathLeft = exists ? rspItem.pathLeft : "";
        item.type = rspItem.type ?? "";
      });

      // Check if any of skus belong to box sets
      return getBoxsetsBySku(db, _items);
    })
    .then((response) => {
      /**
       * Record whether or not box set skus were found. Do not
       * overwrite exists = true items.
       */
      _.each(products, (item, index) => {
        if (!item.exists) {
          item.exists = response[index].exists;

        }
        item.isBoxSet = response[index].exists;
        if (item.isBoxSet && item.exists) {
          console.log('Boxset SKU: ', item.sku);
          item.error = '';
          item.available = true;
          item.name = response[index].name;
          item.style = response[index].style;
          item.size = response[index].size;
          item.format = response[index].format;
          item.items = response[index].items;
          item.type = response[index].type;
        }
      });

      return getProductsByVariant(db, _items);
    })
    .then((response) => {
      _.each(products, (item, index) => {
        if (!item.exists && response[index].exists) {
          console.log(`Update Variant SKU Index:${index}, ${item.sku} => ${response[index].sku}`);
          const rspItem = response[index];

          const isCustomProduct = !!rspItem.isCustomProduct ? rspItem.isCustomProduct : false;
          const isMask = !!rspItem.isMask ? rspItem.isMask : false;
          const isBundle = !!rspItem.isBundle ? rspItem.isBundle : false;
          item.isBoxSet = false;
          item.exists = true;
          item.isCustomProduct = isCustomProduct;
          item.isMask = isMask;
          item.isBundle = isBundle;
          item.error = checkProduct(item.sku, true, isCustomProduct, rspItem, cropSettings, templates);
          item.available = item.error === "";
          item.style = rspItem.style;
          item.size = rspItem.size;
          item.name = rspItem?.name ?? "";
          item.format = rspItem?.format ?? "";
          item.pathFront = rspItem.pathFront ?? "";
          item.pathBack = rspItem.pathBack ?? "";
          item.pathHyperOpticIdentical = rspItem.pathHyperOpticIdentical ?? "";
          item.isBoxedImagePath = rspItem.isBoxedImagePath ?? "";
          item.pathRight = rspItem.pathRight ?? "";
          item.pathLeft = rspItem?.pathLeft ?? "";
          item.type = rspItem.type ?? "";
        }

      });

      return products;
    })
    .catch((error) => {
      console.error(
        "[lookUpItems] Error getting products by sku: ",
        error
      );
    });
};


export {
  PRODUCT_DEFAULT,
  validateProduct,
  checkBarcodeLength,
  validateCustomProduct,
  getProductBySku,
  getProductsBySku,
  getProductsByVariant,
  getProductVariants,
  createProduct,
  updateProduct,
  deleteProduct,
  exportProducts,
  lookupItems,
  getBySku,
  getBundleBySku,
  getProductByVariantSku
};