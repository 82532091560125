import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import _ from "lodash";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { ConfirmDialog } from '../Page/generalModal';
import { createBoxset, updateBoxset, getBoxsetProducts, getProduct, deleteBoxset, updateABoxSetsVariants, getBoxsetsBySku, getBoxset, updateProductsSize } from '../../db/boxsets';
import { MockupImageBundles } from '../Products/components';
import MockupItem from './mockupItem';
import ModalHeader from './modalHeader';
import Products from './products';
import { palette } from '../../theme/colors';
import DeleteOutlined from '@mui/icons-material/DeleteOutlined';
import { TextField } from '@mui/material';
import VariantsModal from './variantsModal';
import MenuIcon from '@mui/icons-material/Menu';
import { BootstrapInput } from '../Page/input';
import { useSelector } from 'react-redux';
import { addBundleToState, deleteBundleInState, updateBundle } from '../../redux/bundles/actions';
import { listenToMockJobChange } from '../../db/listenToMockJobChange';
import { handleCopyToBoard } from '../../utils/copyToClip';
import { Toast } from '../Toast';
import { ToastCopyCLip } from '../Toast/copyToClip';
import { API, collections } from '../../constants/defines';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import useFirestoreDocument from '../../hooks/useFirestorDoc';
import { useDropzone } from 'react-dropzone';
import FileUploadBundles from './fileUpload';

const MainTabs = {
  Flat: 0,
  Mannequin: 1
};

const FlatTabs = {
  Cascade: 0,
  SingleLine: 1,
  Stacked: 2
};

const useHeaderStyles = makeStyles((theme) => ({
  normalAccordion: {
    background: 'rgba(242, 244, 246, 0.4)'
  },
  expandedAccordion: {
    background: '#F1F7FE'
  },
  tagList: {
    display: 'flex',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    // marginTop: '1.25rem',
    '& li': {
      marginBottom: '.625rem'
    }
  },
  chipWrap: {
    display: 'flex',
    flexWrap: 'nowrap',  // Ensures no wrapping occurs
    overflowX: 'auto',   // Enables horizontal scrolling
    gap: '1rem',         // Adds space between chips, replacing marginRight on chip
    justifyContent: 'flex-start', // Align items to the start
    padding: '0 .625rem',   // Padding inside the chip container for better spacing
  },
  chip: {
    borderRadius: '1.25rem',
    background: palette.profileProductsColors.mediumBlue,
    height: '2.5rem',
    padding: '0 .9375rem 0 1.5625rem',  // Adjusted for consistency
    display: 'flex',
    alignItems: 'center',
    minWidth: '6.25rem',  // Ensures all chips have at least a minimum width
  },
  chipButton: {
    color: '#FFFFFF',
    marginLeft: '.3125rem',
    padding: '0',
  },
  chipLabel: {
    fontWeight: '500',
    color: '#FFFFFF'
  },
  clearButton: {
    fontSize: '.875rem!important',
    fontWeight: '500!important',
    letterSpacing: '.0938rem!important',
    color: '#000000!important',
    marginTop: '1.25rem!important',
    padding: '.3125rem 0!important'
  },
  artsBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    paddingRight: '.5%',
  },
  mockupBox: {
    width: '40%',
    display: 'flex',
    justifyContent: 'center',
    // alignItems: 'center',
  },
  mockupLoadingBox: {
    width: '21.875rem',
    height: '21.875rem',
    border: '.0625rem solid #EEEEEE',
    background: 'rgba(0, 0, 0, 0.04)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  productsBox: {
    width: '100vw',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'auto',
    paddingTop: '1.25rem',
  },
  hImage: {
    height: 'auto',
    width: '100%'
  },
  artToolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '1.875rem'
  },
  artCanvasBox: {
    width: '21.875rem',
  },
  canvasPreview: {
    border: '.0625rem solid #EEEEEE',
    width: '21.875rem',
    height: '21.875rem',
    overflow: 'hidden',
    position: 'relative'
  },
  tabContent: {
    padding: '.625rem 0'
  },
  tabFlatContent: {
    padding: '.3125rem 0',
  },
  productMockup: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  productMockupItem: {
    width: '15%',
    padding: '0 .3125rem'
  },
  tabsFlat: {
    // minHeight: '1.25rem',
  },
  tabFlat: {
    fontSize: '1.125rem!important',
    fontWeight: '300',
    color: '#333',
    padding: '0 0',
    '&.Mui-selected': {
      color: '#000',
      fontWeight: 'bold',
    }
  }
}));

const DEFAULT_STATE = {
  id: "",
  name: "",
  sku: "",
  type: "",
  style: "",
  format: "",
  size: "",
  variants: [],
  items: [],
  toggles: [],
  mockups: [],
  originalSku: "",
  stringSize: "",
  stringFormat: "",
};

const DEFAULT_ITEM = {
  type: "",
  path: "",
  preview: false,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`mockup-tabpanel-${index}`}
      aria-labelledby={`mockup-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `mockup-tab-${index}`,
    'aria-controls': `mockup-tabpanel-${index}`,
  };
};

const changeArrayOrder = function (list, targetIdx, moveValue) {
  if (list.length < 0) return;

  const newPosition = targetIdx + moveValue;

  if (newPosition < 0 || newPosition >= list.length) return;

  const tempList = JSON.parse(JSON.stringify(list));

  const target = tempList.splice(targetIdx, 1)[0];

  tempList.splice(newPosition, 0, target);
  return tempList;
};

const getType = (mockupTab, flatTab) => {
  let type = "";
  if (mockupTab === MainTabs.Mannequin) {
    type = "Mannequin";
  } else if (mockupTab === MainTabs.Flat && flatTab === FlatTabs.SingleLine) {
    type = "Singleline";
  } else if (mockupTab === MainTabs.Flat && flatTab === FlatTabs.Stacked) {
    type = "Stacked";
  } else if (mockupTab === MainTabs.Flat && flatTab === FlatTabs.Cascade) {
    type = "Cascade";
  }
  return type;
}

let unsubscribe = null;

export default function BundleModal(props) {
  const { firebase } = props
  const { bundleInView, originalDropDownData, originalSubDocs } = useSelector(state => state.productBundles);
  const { handleClose, open, sizes, formats, cropSettings, isCreate, dispatch } = props;
  const [boxset, setBoxset] = useState(null);
  const [products, setProducts] = useState([]);
  const [oldSku, setOldSku] = useState("");
  const [mockupTab, setMockupTab] = useState(1);
  const [flatTab, setFlatTab] = useState(0);
  const [deleteModal, setDeleteModal] = useState(false);
  const [clickedSave, setClickedSave] = useState(false);
  const [openVariantsModal, setOpenVariantsModal] = useState(false);
  const [isUpdatingOneMock, setIsUpdatingOneMock] = useState(false);
  const [toastMessage, setToastMessage] = useState('')
  const [showToast, setShowToast] = useState(false);
  const [loadingAfterMockRequest, setLoadingAfterMockRequest] = useState(false);
  const [nonGraphicDetected, setNonGraphicDetected] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const db = firebase.getdb();
  const storage = firebase.getstorage();

  // Use the custom hook to listen to the document changes
  const documentData = useFirestoreDocument(
    db,
    collections.BOXSETS,
    bundleInView?.id,
  );

  useEffect(() => {
    if (documentData && !isCreate) {
      // Update the boxset state when document data changes
      setBoxset(documentData);
    }
  }, [documentData, isCreate]);

  useEffect(() => {
    if (open) {
      setProducts([]);
      setClickedSave(false);
      setMockupTab(1);
      setFlatTab(0);

      if (isCreate) {
        setOldSku("");
        const mockups = [
          { ...DEFAULT_ITEM, type: "Cascade" },
          { ...DEFAULT_ITEM, type: "Singleline" },
          { ...DEFAULT_ITEM, type: "Stacked" },
          { ...DEFAULT_ITEM, type: "Mannequin" }
        ];
        setBoxset({ ...DEFAULT_STATE, mockups });
      } else {
        setOldSku(bundleInView.id);
        console.log('Fetching bundle data for SKU: ', bundleInView.originalSku);
        getBundleData(bundleInView.originalSku);
      }
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
        unsubscribe = null; // Ensure unsubscribe is reset after being called
      }
    };
  }, [open, bundleInView]);


  const getBundleData = (sku) => {
    console.log('Getting bundle data for SKU: ', sku);
    try {
      const q = query(collection(db, collections.BOXSETS), where('sku', '==', sku));

      unsubscribe = onSnapshot(q, (querySnapshot) => {
        let _boxset = boxset;
        querySnapshot.forEach((doc) => {
          if (doc.exists) {
            const data = doc.data();
            console.log('data: ', data);
            // strictly only update the mockups
            _boxset = {
              ...bundleInView,
              items: data.items,
              mockups: data.mockups,
              name: data.name,
              variants: data?.variants || [],
              toggles: data?.toggles || [],
            };
          } else {
            console.log('No such document!');
          }
        }), (error) => {
          console.log('Error getting documents: ', error);
        }
        fetchData(_boxset);
      });
    } catch (error) {
      console.log('Error getting bundle data: ', error);
    }

  }

  const fetchData = async (boxset) => {

    getBoxsetProducts(db, boxset.items)
      .then(items => {
        if (!items || items.length === 0) {
          console.log('No products found for the boxset.');
          setProducts([]);
        } else {
          // Check if the Boxset type is Mixed
          if (boxset.type === "Mixed") {
            // Find the first item that is of type 'Underwear'
            const underwearItemIndex = items.findIndex(item => item.data.type === "Underwear");

            // Check if the underwear item exists and is not already the last item
            if (underwearItemIndex >= 0 && underwearItemIndex !== items.length - 1) {
              // Move the underwear item to the end of the array
              const [underwearItem] = items.splice(underwearItemIndex, 1); // Remove the item
              items.push(underwearItem); // Insert it at the end of the array
            }
          }

          // Update the products state with the possibly reordered items
          setProducts([...items]);

          // Update the boxset.items to match the new order of the products
          const reorderedSkus = items.map(item => item.sku);

          setBoxset({ ...boxset, items: reorderedSkus });
        }
      })
      .catch(error => {
        console.log('Error fetching products:', error);
        setProducts([]);
      });
  };

  const getBundleType = (products) => {

    const isErrorItem = products.some(product => !product.exists);

    if (isErrorItem) {
      // alert what product is not found
      const notFoundProduct = products.find(product => !product.exists);
      alert(`Product ${notFoundProduct.sku} not found. Please verify the SKU and try again.`);
      setBoxset({ ...boxset, items: [], toggles: [] });
      return;
    }

    let type = products[0]?.data?.type || ''

    if (type === '') {
      alert(`Data not found for product ${products[0].sku}`)
      setBoxset({ ...boxset, items: [], toggles: [] });
      return
    }

    for (let i = 1; i < products.length; i++) {
      const product = products[i];
      if (product.data.type !== type) {
        type = 'Mixed';
        break;
      }
    }
    return type;
  }

  const getBundleFormat = (products) => {
    let format = products[0].data.format;
    for (let i = 1; i < products.length; i++) {
      const product = products[i];
      if (product.data.format !== format) {
        format = '';
        break;
      }
    }
    return format;
  }

  const getBundleStyle = (products) => {
    let style = products[0].data.style;
    for (let i = 1; i < products.length; i++) {
      const product = products[i];
      if (product.data.style !== style) {
        style = 'Mixed';
        break;
      }
    }
    return style;
  }

  const getBundleSize = (products) => {
    let size = products[0].data.size;
    for (let i = 1; i < products.length; i++) {
      const product = products[i];
      if (product.data.size !== size) {
        size = '';
        break;
      }
    }
    return size;
  }

  const handleSelectProduct = (newValue) => {
    const items = [...boxset.items];
    const toggles = [...boxset.toggles];
    const productsInfo = [...(boxset?.productsInfo || [])]; // Initialize or copy existing array

    let item = _.find(items, item => item === newValue.sku);
    if (!item) {
      items.push(newValue.sku);
      toggles.push(true);

      getProduct(db, newValue.sku)
        .then(product => {
          const _products = [...products];
          console.log('product: ', product);
          console.log('productsInfo: ', productsInfo);
          _products.push(product);

          // Add product.data to productsInfo
          if (product?.data) {
            productsInfo.push(product.data);
          }

          // Update non-graphic detection logic
          if (product?.data?.style !== 'Graphic') {
            console.log('non graphic detected');
            setNonGraphicDetected(true);
          }

          // Derive bundle properties
          const type = getBundleType(_products);
          const size = getBundleSize(_products);
          const format = getBundleFormat(_products);
          const style = getBundleStyle(_products);

          // Update boxset with new productsInfo
          setBoxset({
            ...boxset,
            items,
            toggles,
            productsInfo, // Add productsInfo to boxset
            format,
            type,
            size,
            style
          });
        });
    }
  };


  const handleDeleteProduct = (sku) => () => {
    console.log('handleDeleteProduct: ', sku);

    const _items = [...boxset.items];
    const _toggles = [...boxset.toggles];

    let items = [], toggles = [];

    for (let i = 0; i < _items.length; i++) {
      if (_items[i] !== sku) {
        items.push(_items[i]);
        toggles.push(_toggles[i]);
      }
    }
    setBoxset({ ...boxset, items, toggles });
    const _products = _.filter(products, item => item.sku !== sku);
    setProducts([..._products]);

  };

  const checkAvailability = () => {

    const _boxset = { ...boxset };

    if (!_boxset.originalSku || _boxset.originalSku === '') {
      _boxset.originalSku = _boxset.id
    }

    let hasError = false;

    // Check if the original SKU is empty
    if (_boxset.originalSku === "") {
      msgs.push('Error: No SKU provided. Please enter a SKU.');
      hasError = true;
    }

    // Check if the name is empty
    if (_boxset.name === "") {
      msgs.push('Error: No Name provided. Please enter a name for the bundle.')
      hasError = true;
    }

    // Check for an invalid number of products (must be 3 or 5)
    if (products.length !== 3 && products.length !== 5 && products.length !== 2 && !nonGraphicDetected) {
      msgs.push(`Error: Invalid number of sub-products. There should be either 2, 3 or 5 products, but there are ${products.length}.`);
      hasError = true;
    }

    // Check if the size is valid
    if (_boxset.size) {
      const skuPrefixes = _boxset?.originalSku?.split("-");

      if (isCreate) {
        if (!_boxset.stringSize || _boxset.stringSize === '') {
          let newSizeByDelete = _boxset.originalSku.split('-').pop();

          const strippedString = newSizeByDelete.replace(/[^a-zA-Z0-9]/g, '');

          _boxset.size = sizes.find(size => size.sku === strippedString)?.id;

        }

        const replacedToName = sizes.find(size => size.id === _boxset.size)?.name;

        _boxset.stringSize = replacedToName

        if (!_boxset.stringSize) {
          return;
        }
      }

      // remove speacial characters from size. Dont know why we were comparing .size to a string of size when .size is an id.
      if (!_boxset?.stringSize) {
        return;
      }
      let newSize = _boxset?.stringSize.replace(/[^a-zA-Z0-9]/g, '') ?? '';

      if (newSize === 'YouthM') {
        newSize = 'YTHM';
      } else if (newSize === 'Toddler24Y') {
        newSize = 'TDLR24';
      } else if (newSize === 'Toddler12Y') {
        newSize = 'TDLR12';
      } else if (newSize === 'YouthL') {
        newSize = 'YTHL';
      }

      const isSizeValid = skuPrefixes.includes(newSize);

      if (!isSizeValid) {
        msgs.push(`Error: Invalid SKU. The sizes do not match between the chosen size (${newSize}) and the SKU (${_boxset.originalSku}).`);
        hasError = true;
      }
    } else {
      // add to errors no size found
      if (!_boxset.size || _boxset.size === '') {
        msgs.push('Error: No size found for the bundle. Please select a size.');
        hasError = true;
      }
    }

    // Check if all products match the boxset size and exist
    const sizeMismatch = products.some(product => {
      return !product.exists || product.data.size !== _boxset.size;
    });

    if (sizeMismatch && boxset.size) {
      const validSizes = ['LXL', 'L', 'XL', 'M', 'SM', 'YTHL', 'YTHM', 'TDLR24', 'TDLR12'];

      // Filter to find invalid products based only on size criteria for existing products
      const missingSizesProducts = [];
      const invalidSizeProducts = [];

      products.forEach(product => {
        if (!product.exists) {
          return; // Skip non-existing products
        }

        if (product.data && product.data.size) {
          const productSize = sizes.find(size => size.id === product.data.size)?.sku;

          if (!productSize) {
            // Size is invalid or missing in the sizes database
            missingSizesProducts.push(product);
          } else if (!validSizes.includes(productSize)) {
            // Size is not in the list of valid sizes
            invalidSizeProducts.push(product);
          }
        }
      });

      // Handle missing size errors
      if (missingSizesProducts.length > 0) {
        console.log('Error: Some sub-products dont have a valid size!: ', missingSizesProducts.map(product => product.sku));

        // for each product that is missing a size, we need to update the product's size
        // using this functions: updateProductsSize

        missingSizesProducts.forEach(product => {
          // Call update function to apply the new Size
          // update using the parents size as sub childs are not allowed to have different sizes
          const updateResult = updateProductsSize(db, product.sku, _boxset.size, _boxset.sku);

          if (updateResult) {
            console.log(`Size updated for product SKU: ${product.sku}`);
          }

        });

      }
      // // Handle invalid size errors
      // if (invalidSizeProducts.length > 0) {
      //   msgs.push('Error: Mismatch in sub-product sizes. Not all products match the chosen bundle size: ' + invalidSizeProducts.map(product => ` ${product.sku}`).join(''));
      //   hasError = true;
      // }
    }

    // Check if the SKU format is correct
    if (_boxset.originalSku) {
      const prefixes = _boxset.originalSku.split('-');
      if (prefixes.length < 2) {
        msgs.push('Error: Incorrect SKU Format. The SKU is not long enough or is missing required segments.');
        hasError = true;
      }

    }

    const isErrorItem = hasError ? msgs.join('\n ') : null;
    return isErrorItem;
  }


  const handleSave = async () => {

    if (nonGraphicDetected && !uploadedFile) {
      alert('Error: No image uploaded for non-graphic bundle. Please upload an image.');
      return;
    }

    setClickedSave(true);

    if (isErrorItem) {
      console.log('Error Item: ', isErrorItem);
      return;
    }

    const _boxset = { ...boxset };

    delete _boxset.subDocs;

    if (isCreate) {

      await createBoxset(db, _boxset, uploadedFile, nonGraphicDetected, storage);
      if (uploadedFile) {
        boxset.manualImage = `${uploadedFile.name}`;
      }
      // dispatch action to update the state upon creation
      dispatch(addBundleToState(boxset, sizes, formats));

    } else {

      let mockups = _.map(_boxset.mockups, item => {
        return { ...item, path: "", preview: false }
      })

      updateBoxset(db, _boxset, mockups, oldSku);
    }

    handleClose();
  }

  const handleOverride = async () => {

    const _boxset = { ...boxset };

    delete _boxset.subDocs;

    if (isCreate) {
      await createBoxset(db, _boxset);
      dispatch(addBundleToState(boxset, sizes, formats));

    } else {
      dispatch(updateBundle(boxset))
      updateBoxset(db, _boxset, boxset.mockups, oldSku);
    }

    handleClose();
  }


  const handlePreview = () => {
    // allow only 3 or 5 products
    if (products.length !== 3 && products.length !== 5) {
      return;
    }

    // off the boxset remove the subDocs to not save in db
    const _boxset = { ...boxset };

    delete _boxset.subDocs;

    if (isCreate) {

      createBoxset(db, _boxset);

    } else {

      // check what type is in view
      const type = getType(mockupTab, flatTab);

      let mockups = _.map(_boxset.mockups, item => {

        if (item.type === type) {
          // set state to handle only working on one mock
          setIsUpdatingOneMock(true);
          return { ...item, path: "", preview: false }
        }

        return { ...item };

      })

      updateBoxset(db, _boxset, mockups, oldSku);
      // delete the
      setBoxset({ ...boxset, mockups });

    }

  }

  const handleCloseDialog = (event) => {
    setBoxset(null);
    handleClose();
  }

  const handleChange = (event) => {
    let { name, value } = event.target;

    if (name === 'originalSku' || name === 'sku') {
      value = value.toUpperCase();
    }

    let _boxset = { ...boxset };

    _boxset[name] = value;

    if (name === 'originalSku' && value !== '') {
      _boxset['sku'] = value;
    }

    setBoxset(_boxset);
  }

  const handleClear = (event) => {
    let _boxset = { ...boxset };
    _boxset.items = [];
    setBoxset(_boxset);
  }

  const handleOnDragEnd = (result) => {
    const src = result.source.index;
    const dst = result.destination.index;

    const items = [...boxset.items];
    if (src !== dst) {
      const sku = items[dst];
      items[dst] = items[src];
      items[src] = sku;
    }

    const toggles = [...boxset.toggles];
    if (src !== dst) {
      const toggle = toggles[dst];
      toggles[dst] = toggles[src];
      toggles[src] = toggle;
    }

    setBoxset({ ...boxset, toggles, items });
  }

  const renderItems = () => {
    const _items = boxset.items;

    return (
      <Box sx={styles.skusList}>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="droppable" direction="horizontal">
            {(provided, snapshot) => (
              <Box className={classes.chipWrap}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {
                  _items.map((sku, index) => {
                    return (
                      <Draggable
                        key={`layer_${index}`}
                        draggableId={`layer_${index}`}
                        index={index}
                        isDragDisabled={adjustForYouthL || isMixedBoxSet}
                      >
                        {(provided, snapshot) => (
                          <Box ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                            <Box className={classes.chip}>
                              <Typography
                                sx={{ fontSize: { sm: '.5rem', md: '.625rem', xl: '.75rem' }, cursor: 'pointer' }}
                                className={classes.chipLabel}
                                onClick={(e) => {
                                  e.stopPropagation(); // Prevents the drag event from firing when clicking
                                  handleCopyToBoard(`Copied SKU: ${sku}`, sku, setToastMessage, setShowToast);
                                }}
                              >
                                {sku}
                              </Typography>
                              <IconButton sx={{
                                color: '#FFFFFF',
                                marginLeft: '.3125rem',
                                padding: '0',
                              }}
                                onClick={handleDeleteProduct(sku)}
                              >
                                <CloseIcon />
                              </IconButton>
                            </Box>
                          </Box>
                        )}
                      </Draggable>
                    );
                  })
                }
                {provided.placeholder}
              </Box>
            )}
          </Droppable>
        </DragDropContext>
      </Box>
    );
  }

  const isMixedBoxSet = boxset?.type === "Mixed" || false;

  const handleChangeTab = (event, newValue) => {
    setMockupTab(newValue);
  }

  const handleChangeTabFlat = (event, newValue) => {
    setFlatTab(newValue);
  }

  const renderMockupFlat = () => {
    return (
      <Box className={classes.tabFlatContent}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={flatTab}
            onChange={handleChangeTabFlat}
            aria-label="Flat Tabs"
            textColor="#000000"
            indicatorColor="transparent"
            centered
            className={classes.tabsFlat}
          >
            {
              !is3PackToddlerSizeCrew && (
                <Tab label="Cascade" {...a11yProps(FlatTabs.Cascade)} className={classes.tabFlat} />
              )
            }
            <Tab label="Single line" {...a11yProps(FlatTabs.SingleLine)} className={classes.tabFlat} />
            {
              !is3PackToddlerSizeCrew && (
                <Tab label="Stacked" {...a11yProps(FlatTabs.Stacked)} className={classes.tabFlat} />
              )
            }
          </Tabs>
        </Box>
      </Box>
    )
  }

  const handleMove = (index, isForward) => {
    const cond = (index == 0 && !isForward) || (index == products.length - 1 && isForward);
    if (!cond) {
      const _items = [...boxset.items];
      const items = changeArrayOrder(_items, index, isForward ? 1 : -1);

      const _toggles = [...boxset.toggles];
      const toggles = changeArrayOrder(_toggles, index, isForward ? 1 : -1);

      setBoxset({ ...boxset, items, toggles });
    }
  }

  const handleToggle = (index, toggle) => {
    let toggles = [...boxset.toggles];
    toggles[index] = toggle;
    setBoxset({ ...boxset, toggles });
  }

  const handleDragDropImages = (result) => {
    const { source, destination } = result;
    if (!destination) return; // Dropped outside the list

    if (source.index !== destination.index) {
      const _items = [...boxset.items];
      const _toggles = [...boxset.toggles];

      // Reorder the items and toggles based on the source and destination indices
      const [movedItem] = _items.splice(source.index, 1);
      _items.splice(destination.index, 0, movedItem);

      const [movedToggle] = _toggles.splice(source.index, 1);
      _toggles.splice(destination.index, 0, movedToggle);

      // Update the state with the new order
      setBoxset({ ...boxset, items: _items, toggles: _toggles });
    }
  };

  let strSize = "", strFormat = ""

  const type = getType(mockupTab, flatTab);

  const renderProducts = () => {

    const enableToggle = type !== "Stacked";

    // check if some have an identical in them by looking into the dataobject iside product
    const identicalProducts = _.filter(products, product => product?.data?.identical);

    return (
      <Box
      >
        <DragDropContext onDragEnd={handleDragDropImages}>
          <Droppable droppableId="droppable" direction="horizontal">

            {(provided, snapshot) => (
              <Box
                sx={{
                  ...styles.productBox,
                  gap: isUnderWearOnly || isMixedBoxSet ? '40%' :
                    identicalProducts.length === 0 && packCnt === 3 ? '22%'
                      : identicalProducts.length === 0 && packCnt === 5 && boxset.stringSize !== 'Youth L' ? '7%'
                        : identicalProducts.length === 0 && packCnt === 5 && boxset.stringSize === 'Youth L' ? '5%'
                          : boxset.stringSize === 'Youth L' && !isCrew5pack && !is2packCrewYth && !isLowAnd5Pck ? '20%'
                            : isLowAnd5Pck ? '5%'
                              : boxset.stringSize === 'Youth L' && isCrew5pack ? '5%'
                                : isCrew5pack ? '5%'
                                  : is2packCrewLxl ? '40%'
                                    : is2packCrewYth ? '40%'
                                      : boxset.manualImage ? '25%'
                                        : '20%',

                  paddingRight:
                    boxset.stringSize === 'Youth L' && isCrew5pack ? '5%'
                      : isLowAnd5Pck ? '5%'
                        : isCrew5pack ? '5%'
                          : is2packCrewLxl || is2packCrewYth ? '10%'
                            : '0%',
                  paddingTop: is2packCrewLxl || is2packCrewYth ? '3%' : '0%'
                }}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {
                  _.map(boxset.items, (item, index, array) => {
                    const product = _.find(products, { sku: item });
                    return (
                      <Draggable
                        key={`layer_${index}`}
                        draggableId={`layer_${index}`}
                        index={index}
                        isDragDisabled={adjustForYouthL || isMixedBoxSet}
                      >
                        {(provided, snapshot) => (
                          <Box className={classes.productMockupItem}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            {product && (
                              <MockupItem
                                isLowAnd5Pck={isLowAnd5Pck}
                                isAdjustedForOtherTypes={adjustedForLow3Pack}
                                isMixedBoxSet={isMixedBoxSet}
                                key={`mockItem_${product.sku}`}
                                firebase={firebase}
                                product={product.data}
                                cropSettings={cropSettings}
                                index={index}
                                adjustForYouthL={adjustForYouthL}
                                enableToggle={enableToggle}
                                toggle={boxset.toggles[index]}
                                onMove={handleMove}
                                onToggle={handleToggle}
                                isUnderWearOnly={isUnderWearOnly}
                              />
                            )}
                          </Box>
                        )}
                      </Draggable>
                    )
                  })
                }
                {provided.placeholder}
              </Box>

            )}

          </Droppable>
        </DragDropContext>
      </Box>
    )
  }


  const renderArts = () => {
    const visibleArts =
      (strFormat === "Crew" && strSize === "L/XL") ||
      (strFormat === "Crew" && strSize === "YTHL") ||
      (strFormat === "Low" && strSize === "L/XL" && packCnt === 5) ||
      isMixedBoxSet || adjustedForLow3Pack && !hideItems || adjustForYouthL || isUnderWearOnly || is3PackToddlerSizeCrew || boxset?.manualImage

    if (!visibleArts) return <Box />;

    const type = getType(mockupTab, flatTab);

    let mockPath = type === "" ? boxset.comboMockup : _.find(boxset.mockups, { type: type }).path;

    if (mockPath === "") {
      mockPath = boxset.comboMockup;
    }

    if (boxset.manualImage) {
      mockPath = boxset.manualImage;
    }

    return (
      <Box
        sx={styles.artsBox}
      >

        <Box
          sx={{
            paddingLeft: '5%',
          }}
        >
          {
            !isUpdatingMock ? (
              <MockupImageBundles
                name="boxset"
                key={`mockup_${boxset.originalSku}`}
                reverse={false}
                firebase={firebase.getFirebase()}
                classes={classes}
                path={mockPath}
                isUnderWearOnly={isUnderWearOnly}
              />
            ) : (
              <Box
                sx={styles.updatingMockLoadingBox}
              >
                <CircularProgress
                />
              </Box>
            )
          }

        </Box>
        <Box
          sx={styles.mainProductBox}
        >
          {renderProducts()}
        </Box>
      </Box>
    )
  }

  const handleOpenDeleteDialog = (event) => {
    setDeleteModal(true);
  }

  const handleCloseDeleteDialog = (event) => {
    setDeleteModal(false);
  }

  const handleDeleteBundle = () => {
    // update sku to - original SKU
    setDeleteModal(false);
    setBoxset(null);
    dispatch(deleteBundleInState(boxset.sku));
    handleClose();
    deleteBoxset(db, boxset);
  }

  const handleChangeVariant = (event, index) => {
    let { name, value } = event.target;

    let _product = { ...boxset };
    let _variants = [...boxset.variants];
    _variants[index] = value.toUpperCase();
    _product.variants = _variants;

    setBoxset(_product);
  }

  const handleDeleteVariant = (event, index) => {
    const _product = { ...boxset };
    const _variants = [..._product.variants];

    _variants.splice(index, 1);

    _product.variants = _variants;
    setBoxset(_product);
  }

  const handleAddVariant = (event) => {
    const newItem = '';

    const _product = { ...boxset };
    const _variants = [..._product.variants];
    _variants.push(newItem);
    _product.variants = _variants;
    setBoxset(_product);
  }

  const handleChangeFirstVariant = (event) => {
    const { value } = event.target;

    let _product = { ...boxset };
    let _variants = [..._product.variants];

    _variants[0] = value.toUpperCase();; // This could become an empty string if the user clears the input
    _product.variants = _variants;
    setBoxset(_product);
  };

  const handleSaveVariant = async () => {
    let isEmpty = false;
    // Map the variants to trim whitespace and then filter out any empty strings
    const validVariants = boxset.variants
      .map(variant => variant.trim())
      .filter(variant => variant !== "");  // Remove empty strings

    // Set the updated variants back to state
    setBoxset(prev => ({
      ...prev,
      variants: validVariants
    }));

    if (isCreate) {
      setOpenVariantsModal(false);
      return;
    }

    // Check if there are variants to update
    if (validVariants.length === 0) {
      console.log('No variants to save, saving as empty array.');
      isEmpty = true;
    }

    // Update the product with the new or empty variants array
    const updateHappened = await updateABoxSetsVariants(db, validVariants, isEmpty, bundleInView, dispatch, originalSubDocs);

    if (!updateHappened) {
      alert('Error updating variants. Please try again later.');
      return;
    }

    // Close the modal or clear the input fields after update
    setOpenVariantsModal(false);
  };


  const renderVariants = () => {
    const renderSingleStrings = _.filter(boxset.variants, (variant, index) => index !== 0);

    return (
      <Box sx={{ width: '100%' }}>
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <TextField
            sx={{ width: '100%', borderRadius: '.25rem', marginBottom: '.625rem' }}
            placeholder={boxset.variants.length === 0 ? 'Type Here' : ''}
            value={boxset.variants.length > 0 ? boxset.variants[0] : ""}
            onChange={(e) => handleChangeFirstVariant(e)}
          />
        </Box>

        {renderSingleStrings.map((variant, index) => (
          <Box key={index + 1} sx={{ width: '100%', marginTop: '.625rem', display: 'flex', alignItems: 'center' }}>
            {/* Hamburger icon on the left */}
            <IconButton onClick={() => {/* Handle action for hamburger icon */ }}>
              <MenuIcon />
            </IconButton>

            <StyledBootstrapInput
              name="variant"
              value={variant}
              onChange={(event) => handleChangeVariant(event, index + 1)}
              sx={{ flexGrow: 1, marginLeft: '.625rem', marginRight: '.625rem' }}
            />


            {/* X icon for deletion */}
            <IconButton onClick={() => handleDeleteVariant(event, index + 1)}>
              <CloseIcon />
            </IconButton>
          </Box>
        ))}

        <Button
          onClick={() => handleAddVariant()}
          sx={{
            marginTop: '1.25rem',
            color: '#2870EA',
            fontSize: '1rem',
            borderRadius: '.625rem',
            border: '.125rem solid #2870EA',
            padding: '.625rem 1.25rem',
          }}
        >
          + Add Variant
        </Button>

        {/* cancel and save buttons */}
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1.25rem' }}>
          <Button
            variant="contained"
            onClick={handleCloseModal}
            sx={{
              backgroundColor: 'lightgrey',
              color: 'white',
              width: '9.375rem',
              padding: '.625rem .9375rem',
              borderRadius: '3.125rem',
              marginRight: '1.25rem',
              color: 'black',
            }}
          >
            <Typography variant="h6">Cancel</Typography>
          </Button>

          <Button
            variant="contained"
            onClick={() => {
              handleSaveVariant();
            }}
            sx={{
              backgroundColor: '#2870EA',
              color: 'white',
              width: '9.375rem',
              padding: '.625rem .9375rem',
              borderRadius: '3.125rem',
            }}
          >
            <Typography variant="h6">Save</Typography>
          </Button>
        </Box>

      </Box>
    );
  };

  const classes = useHeaderStyles();

  const filter = 'isBoxset:=false && isCustomProduct:=false';

  if (!boxset) {
    return (
      <Box></Box>
    )
  }

  if (sizes.length > 0 && !!boxset.size) {
    strSize = boxset.stringSize
  }

  if (formats.length > 0 && !!boxset.format) {
    // strFormat = _.find(formats, { id: boxset.format })?.name || '';
    strFormat = boxset.stringFormat;
  }

  const packCnt = products.length;

  const adjustedForLow3Pack = strFormat === "Low" && packCnt === 3 || false;

  const disableSave = strSize === "L/XL" ? (!!boxset ? _.filter(boxset.mockups, { preview: false }).length > 0 : false) : false;

  let isErrorSKU = false, isErrorName = false, isErrorProducts = false, msgs = [];

  const isErrorItem = checkAvailability();

  const isSubTabsVisible = mockupTab === MainTabs.Flat; // Example condition

  let hideItems = false

  let adjustForYouthL = false;

  const filtered = _.filter(sizes, size => size.id === boxset.size);

  const nameOfSize = filtered.length > 0 ? filtered[0].name : '';

  const isUnderWearOnly = boxset?.type === "Underwear" || false;

  const setHieghtBasedOffSize = () => {

    if (boxset.type === 'Mixed' || isUnderWearOnly) {
      return '97vh';
    }

    if (!sizes.length || !boxset.size) return '';


    if (nameOfSize === 'S/M' || nameOfSize === 'Toddler 1-2Y') {
      hideItems = true;
    }

    if (nameOfSize === 'Youth L') {
      adjustForYouthL = true;
      hideItems = true;
    }

    if (nameOfSize === 'Youth M') {
      // same UI as SM
      hideItems = true;
    }



    return !isCreate ? '97vh' : '50vh'
  }

  const handleCloseModal = () => {
    setOpenVariantsModal(false);
  }

  const is2packCrewLxl = packCnt === 2 && boxset.stringFormat === 'Crew' && boxset.stringSize === 'L/XL';

  const is2packCrewYth = packCnt === 2 && boxset.stringFormat === 'Crew' && boxset.stringSize === 'Youth L';

  const isCrewAndLxl = strFormat === "Crew" && strSize === "L/XL";

  const isLowAnd5Pck = strFormat === "Low" && packCnt === 5;

  const showIf3pckCrew = packCnt === 3 && boxset.stringFormat === 'Crew';

  const isCrew5pack = strFormat === "Crew" && packCnt === 5;

  const is3PackToddlerSizeCrew = packCnt === 3 && boxset.stringFormat === 'Crew' && boxset.stringSize === 'Toddler 2-4Y'

  function isEligibleForDisplayMockMannequin() {
    if (boxset.items.length === 2) {
      return false
    }
    return isCrewAndLxl || isMixedBoxSet || adjustedForLow3Pack || isUnderWearOnly || isLowAnd5Pck
  }

  function isEligibleForDisplayMockFlat() {
    const allowIfCrewAndLXL = strFormat === "Crew" && strSize === "L/XL";

    // Define your existing conditions
    const isNotAllowed = !is2packCrewLxl && !is2packCrewYth;

    // Return true if it's either a Crew & L/XL or it's a 3-pack toddler Crew, and none of the not allowed conditions are met
    return (allowIfCrewAndLXL || is3PackToddlerSizeCrew) && isNotAllowed;
  }

  function renderArtsIfEligible() {
    if (boxset.manualImage) {
      return true;
    }

    return !isCreate && (adjustedForLow3Pack || isMixedBoxSet || packCnt == 3 || packCnt == 5 || isUnderWearOnly || packCnt === 2)

  }

  const preview = type === "" ? false : _.find(boxset.mockups, { type: type }).preview;

  // flag for loading to show the process has started for creating one new mock...
  const isUpdatingMock = isUpdatingOneMock && !preview;

  const isElegibleForPreviewButtonToShow = () => {

    const isErrorItem = products.some(product => !product.exists);

    return !adjustForYouthL && !isMixedBoxSet && !adjustedForLow3Pack && !hideItems && !isCreate && !isErrorItem && (packCnt === 3 || packCnt === 5)
  }

  const createMock = async () => {
    try {
      setLoadingAfterMockRequest(true);
      const response = await fetch(API.sendClientSideMock, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          boxset,
        }),
      })

      const data = await response.json();

      if (data.success) {
        console.log('Mock created successfully: ', data);
      }

      setTimeout(() => {
        setLoadingAfterMockRequest(false);
      }
        , 5000);


    } catch (error) {
      console.log('Error creating mock: ', error);
      setLoadingAfterMockRequest(false);
    }
  }

  const handleFileUpload = (file) => {
    console.log('handleFileUpload: ', file);
    setUploadedFile(file);
  }

  return (
    <Dialog
      onClose={handleCloseDialog}
      aria-labelledby="revision-dialog-title"
      open={open}
      PaperProps={{
        style: {
          borderRadius: '1.25rem',
          height: 'auto',
          maxHeight: !isCreate ? setHieghtBasedOffSize() : 'auto',
          width: '98vw',
          maxWidth: '98vw',
          overflow: 'hidden',

        },
      }}
    >
      {
        ((isCreate && clickedSave && isErrorItem) || (!isCreate && isErrorItem)) && (
          <Box sx={styles.errorContainer}>
            {
              _.map(msgs, (msg, index) => (
                <Typography
                  key={index}
                  variant="body2"
                  sx={{ color: 'error.main' }}
                >
                  {msg}
                </Typography>
              ))
            }

          </Box>
        )
      }


      {!!boxset && (
        <Box sx={{
          height: '100%',
          width: '100%',
        }}
        >
          <ModalHeader
            boxset={boxset}
            originalSubDocs={originalSubDocs}
            originalDropDownData={originalDropDownData}
            isMixedBoxSet={isMixedBoxSet}
            handleCloseDialog={handleCloseDialog}
            variants={boxset.variants}
            sizes={sizes}
            hideItems={hideItems}
            handleChange={handleChange}
            setOpenVariantsModal={setOpenVariantsModal}
            dispatch={dispatch}
            isCreate={isCreate}
          />

          <Products
            db={db}
            onSelect={handleSelectProduct}
            filter={filter}
            handleClear={handleClear}
            products={products}
            renderItems={renderItems}
            packCnt={packCnt}
            classes={classes}
            adjustForYouthL={adjustForYouthL}
            hideItems={hideItems}
          />

          <Box >

            {
              !adjustForYouthL && !hideItems && !isCreate && (
                <Box sx={styles.mainTabsBox}>


                  {/* Tabs Wrapper */}

                  <Box sx={styles.mainTabs}>
                    <Box sx={styles.tabsDivider}>
                      <Tabs
                        value={mockupTab}
                        aria-label="Mockup Tabs"
                        textColor="black"
                        onChange={handleChangeTab}
                      >
                        {isEligibleForDisplayMockFlat() && (
                          <Tab
                            {...a11yProps(MainTabs.Flat)}
                            label="Flat"
                            sx={styles.tabLabel}
                          />
                        )}

                        {isEligibleForDisplayMockMannequin() && (
                          <Tab
                            {...a11yProps(MainTabs.Mannequin)}
                            label="Mannequin"
                            sx={styles.tabLabel}
                          />
                        )}

                      </Tabs>
                    </Box>
                  </Box>


                  {
                    !isMixedBoxSet && !is2packCrewYth && !is2packCrewLxl && (showIf3pckCrew || adjustedForLow3Pack || !isUnderWearOnly) &&
                    (
                      <Box sx={{
                        ...styles.subTabs,
                        ...(!isSubTabsVisible ? styles.subTabsVisible : {}),
                      }}>
                        <TabPanel value={mockupTab} index={MainTabs.Flat}>
                          {renderMockupFlat()}
                        </TabPanel>
                      </Box>
                    )
                  }

                </Box>
              )
            }


            <Grid >
              {
                renderArtsIfEligible() && renderArts()
              }
            </Grid>

          </Box>

          <DialogActions sx={styles.buttonContainer}>
            <Button
              variant="blue"
              color="primary"
              sx={{
                ...styles.actionButton,
                backgroundColor: palette.profileProductsColors.mediumBlue,
              }}
              disabled={(!isCreate && disableSave) || !props.editProduct}
              onClick={handleSave}
            >
              Save
            </Button>

            {((isCreate && clickedSave && isErrorItem) || (!isCreate && isErrorItem)) && !hideItems && (
              <Button
                disabled={!props.editProduct}
                variant="blue"
                sx={{
                  ...styles.actionButton,
                  backgroundColor: palette.profileProductsColors.green,
                  '&:hover': {
                    backgroundColor: palette.profileProductsColors.green,
                  }
                }}
                onClick={handleOverride}>
                Override
              </Button>
            )}

            {
              !isCreate && boxset.items.length === 2 || boxset.items.length === 3 && !boxset.manualImage && (
                <>
                  {loadingAfterMockRequest ? (
                    <Button
                      variant="blue"
                      color="primary"
                      sx={styles.actionButton}
                      disabled={true}
                    >                Sending Request {" "}
                      <CircularProgress size={20}
                        sx={{
                          marginLeft: '.625rem',
                        }}
                      />
                    </Button>
                  ) : (
                    <Button
                      variant="blue"
                      color="primary"
                      sx={{
                        ...styles.actionButton,
                        backgroundColor: palette.profileProductsColors.mediumBlue,
                      }}
                      onClick={createMock}
                    >
                      Create Mock
                    </Button>
                  )
                  }
                </>

              )
            }

            {
              !isCreate && (
                <>
                  <Button
                    sx={{
                      ...styles.actionButton,
                      backgroundColor: palette.profileProductsColors.red,
                      '&:hover': {
                        backgroundColor: '#FF0000',
                      }
                    }}
                    onClick={handleOpenDeleteDialog}
                    disabled={!props.editProduct}
                    endIcon={<DeleteOutlined />}
                  >Delete
                  </Button>
                </>
              )
            }
            {isElegibleForPreviewButtonToShow() && (
              <>
                <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                  <Button variant="blue" color="primary"
                    sx={{
                      ...styles.actionButton,
                      backgroundColor: palette.profileProductsColors.mediumBlue,
                    }}
                    // disable if 3pck toddler as mock is not in place yet
                    disabled={isUpdatingMock || is3PackToddlerSizeCrew}
                    onClick={handlePreview}
                  >
                    Preview
                  </Button>
                </Box>
              </>

            )}

            <Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%", paddingRight: "20px" }}>

              {nonGraphicDetected && (
                <FileUploadBundles onFileUpload={handleFileUpload} />
              )}

            </Box>

          </DialogActions>
        </Box>
      )}
      {
        openVariantsModal && (
          <>
            <VariantsModal
              openVariantsModal={openVariantsModal}
              handleCloseModal={handleCloseModal}
              renderVariants={renderVariants}
              product={boxset}
              nameOfSize={nameOfSize}
            />
          </>
        )
      }
      <ConfirmDialog
        label="delete-dialog-title"
        title="Delete Bundle"
        open={deleteModal}
        onConfirm={handleDeleteBundle}
        onClose={handleCloseDeleteDialog}
      >
        <Typography variant='body2' gutterBottom>
          Do you want to remove current bundle?
        </Typography>
      </ConfirmDialog>
      <ToastCopyCLip show={showToast} message={toastMessage} />
    </Dialog>
  );
}

const StyledBootstrapInput = (props) => (
  <BootstrapInput
    {...props}
    sx={{
      height: '3.75rem', // Increase the height
      width: '100%', // Adjust as needed
      '&& .MuiInputBase-input': {
        height: '100%',
        width: '100%',
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'center',

      }
    }}
  />
);

const styles = {
  updatingMockTextWarning: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '2.5rem',
    backgroundColor: palette.profileProductsColors.red,
    color: 'white',
    fontSize: '1rem',
    fontWeight: 'bold',
  },
  loadingNewMock: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '8px 50px',
    borderRadius: '25px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
    cursor: 'pointer',
    fontSize: '20px',
    color: 'white',
    fontSize: '1rem',
  },
  updatingMockLoadingBox: {
    width: '19.75vw',
    height: '40vh',
    border: '.0625rem solid #EEEEEE',
    borderColor: '#6BC7F6',
    borderRadius: '.625rem',
    display: 'flex',
    border: '.5rem solid',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tabLabel: { fontWeight: 'bold', paddingBottom: '.3125rem', fontSize: '1.5rem' },
  isMixedStyles: { display: 'flex', flexDirection: 'row', gap: '12.5rem' },
  isUnderWearOnlyStyles: { display: 'flex', flexDirection: 'row', gap: '15.625rem' },
  subTabs: {
    width: '65%',
    alignSelf: 'flex-end',
    visibility: 'visible', // By default, it is hidden
  },
  artsBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '100%',
  },
  subTabsVisible: {
    visibility: 'visible', // Make it visible,
    height: '1.875rem',
  },
  mainTabsBox: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end', // This ensures the tabs are pushed to the right
    flexDirection: 'column', // Vertical stacking for overall structure
  },
  mainTabs: {
    width: '71.5%', // Adjust the width as needed
    alignSelf: 'flex-end', // Aligns the tabs box to the right
  },
  tabsDivider: {
    borderBottom: 1,
    borderColor: 'divider',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  skusList: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '1.25rem',
    marginTop: '1.25rem',
  },
  actionButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '8px 50px',
    borderRadius: '25px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
    cursor: 'pointer',
    fontSize: '20px',
    color: 'white',
    fontSize: '1rem',
  },
  productBox: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    // gap: '20%',
  },
  mainProductBox: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  errorContainer: {
    padding: '.3125rem 3.4375rem', // Increased padding
    margin: '.625rem 0',     // Added margin for better spacing
    backgroundColor: '#fff1f1', // Optional: Adds a light background color for error messages
    border: '.0625rem solid #ff0000', // Optional: Adds a border to highlight the error box
    borderRadius: '1.25rem',
    width: '80%',
    margin: '0 auto',
    textAlign: 'center',
    marginTop: '.625rem',
  },
}
