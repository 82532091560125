import { getDocs, query, where, collection } from 'firebase/firestore';
import { collections } from "../constants/defines";

export const lookForAnyOrderByTrackDb = async (track, db) => {
  try {
    // Array of collections to search
    const collectionsArray = [
      collections.INLINE_PRODUCTS_QUEUE,
      collections.CUSTOM_PRODUCTS_QUEUE,
      collections.DS_INLINE_QUEUE,
      collections.DS_CUSTOM_QUEUE,
    ];

    const results = [];

    // Query each collection in the array
    for (const collectionName of collectionsArray) {
      const collectionRef = collection(db, collectionName);

      // Use Firestore's `where` clause to filter results
      const q = query(collectionRef, where('trackingNumber', '==', track));

      // Fetch documents
      const querySnapshot = await getDocs(q);

      // Process and store results
      querySnapshot.forEach((doc) => {
        results.push({
          collection: collectionName,
          id: doc.id,
          ...doc.data(),
        });
      });
    }
    if (results.length > 0) {
      return results;
    }
    return null;
  } catch (error) {
    console.error('Error searching Firestore collections:', error);
    throw error;
  }
}
