export const INLINE_ORDERS_FETCHED = 'INLINE_ORDERS_FETCHED';
export const FETCH_INLINE_ORDERS_START = 'FETCH_INLINE_ORDERS_START';
export const FETCH_INLINE_ORDERS_FAILURE = 'FETCH_INLINE_ORDERS_FAILURE';
export const UPDATE_INLINE_ORDERS = 'UPDATE_INLINE_ORDERS';
export const FETCH_ALL_INLINE_ORDERS = 'FETCH_ALL_INLINE_ORDERS';
export const SHOW_FILTER_DATA = 'SHOW_FILTER_DATA';
export const FILTERED_ORDERS_ARRAY = 'FILTERED_ORDERS_ARRAY';
export const UPDATE_PAGE_NUMBER = 'UPDATE_PAGE_NUMBER';
export const UPDATE_ROWS_PER_PAGE = 'UPDATE_ROWS_PER_PAGE';
export const UPDATE_NEXT_PAGE_DATA = 'UPDATE_NEXT_PAGE_DATA';
export const DID_USE_FILTER = 'DID_USE_FILTER';
export const USE_SEARCH = 'USE_SEARCH';
export const GET_NEXT_SET_OF_ORDERS = 'GET_NEXT_SET_OF_ORDERS';
export const RESET_FILTERS = 'RESET_FILTERS';
export const REMOVE_INLINE_ORDERS = 'REMOVE_INLINE_ORDERS';

export const FILTER_TYPES = {
  PRODUCTS: 'products',
  SHOWS: 'shows',
  PRODUCTION_TYPES: 'production',
  TYPES: 'types',
  SHIPPING: 'shippings',
}