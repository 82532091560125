import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FilterDropdown from '../Page/filterDropdown';
import FilterContext from './filterContext';
import Button from '@mui/material/Button';

const useToolbarStyles = makeStyles((theme) => ({
    root: {
      padding: '50px 0 50px 16px',
      display: 'flex',
      justifyContent: 'space-between',
      borderBottom: '1px solid',
      borderColor: 'rgba(217, 223, 233, 0.4)'
    },
    toolBox: {
      display: 'flex',
      width: 'calc(100% - 200px)',
      justifyContent: 'flex-end'
    },
    filterBox: {
      display: 'flex',
      alignItems: 'center'
    },
    button: {
      padding: '9px 32px',
      fontSize: '16px',
      fontWeight: '500',
      color: '#ffffff',
      textTransform: 'initial',
      marginRight: '16px',
      "&.Mui-disabled": {
        background: "rgba(0, 0, 0, 0.12)!important",
        color: "rgba(0, 0, 0, 0.26)"
      }
    },
    formLabel: {
      color: '#000000',
      fontSize: '14px',
      fontWeight: '500',
      letterSpacing: '1.5px'
    },
  }));
  
const PageToolbar = (props) => {
  const data = useContext(FilterContext);
  const classes = useToolbarStyles();
  const {handleCreate} = props;

  return (
    <div className='pageToolbar'>
      <div className={classes.buttonBox}>
        <Button
          variant="contained"
          className={classes.button}
          onClick={handleCreate}
          disabled={!props.editOnly}
          sx={{background: '#1f7cf2'}}          
        >
          Add Tag
        </Button>
      </div>

      <div className={classes.toolBox}>
        <div className={classes.sortBox}>
          <FilterDropdown title="Show" items={data.shows} active={data.filterShow} onSelect={data.setFilterShow} />            
        </div>          
      </div>
    </div>
  );
};

PageToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default PageToolbar;