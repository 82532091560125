import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import FilterDropdown from '../Page/filterDropdown';
import FilterContext from './filterContext';
import Button from '@mui/material/Button';
import useGetBundleCsv from '../../hooks/useGetBundleCsv';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import { handleCsvDownloadBundles } from '../../utils/handleBundleCsvData';


const DEFAULT_OPTIONS = [
  { id: 1, name: 'All', val: '' },
  { id: 2, name: 'None', val: '' },
];

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    padding: '50px 0 50px 16px',
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid',
    borderColor: 'rgba(217, 223, 233, 0.4)'
  },
  toolBox: {
    display: 'flex',
    width: 'calc(100% - 200px)',
    justifyContent: 'space-between'
  },
  filterBox: {
    display: 'flex',
    alignItems: 'center'
  },
  button: {
    padding: '9px 32px',
    fontSize: '16px',
    fontWeight: '500',
    color: '#ffffff',
    textTransform: 'initial',
    marginRight: '16px',
    "&.Mui-disabled": {
      background: "rgba(0, 0, 0, 0.12)!important",
      color: "rgba(0, 0, 0, 0.26)"
    }
  },
  formLabel: {
    color: '#000000',
    fontSize: '14px',
    fontWeight: '500',
    letterSpacing: '1.5px'
  },
  iconBox: {
    textAlign: 'right',
    paddingRight: '30px',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  viewIconBox: {
    padding: '0 5px',
    cursor: 'pointer',
  },
  viewIcon: {
    fontSize: '30px',
    color: '#e5e5e5'
  },
  viewActiveIcon: {
    fontSize: '30px',
    color: '#bebebe'
  },
  csvButton: {
    padding: '9px 32px',
  },
}));

const PageToolbar = (props) => {
  const { rowsPerPage, handleReset,handleNew } = props;
  const data = useContext(FilterContext);
  const classes = useToolbarStyles();
  const editOnly = props.editOnly;
  const [csvData, setCsvData] = useState([]);
  const [currentShow, setCurrentShow] = useState(5);

  // hook to reset and set the current show
  useEffect(() => {
    // search the data.shows and find the name key by converting rowsPerpage to a string
    const show = data.shows.find((item) => item.name === rowsPerPage.toString());

    if (!show) {
      //  five is based RowsPerPage
      setCurrentShow(5);
      return;
    }

    setCurrentShow(show.id);

  }
    , [rowsPerPage]);

  useGetBundleCsv(setCsvData);

  return (
    <div className='pageToolbar'>

      <div className={classes.buttonBox}>
        <Button
          variant="blue"
          onClick={handleNew}
          disabled={!editOnly}
          sx={{ width: '150px' }}
        // icon end
        >
          Add Bundle
        </Button>
      </div>

      <div className={classes.csvButton}>
        <Button
          variant="blue"
          onClick={() => { handleCsvDownloadBundles(csvData) }}
          sx={{ width: '250px' }}
          endIcon={<Inventory2Icon />}
        >
          Bundles CSV Info
        </Button>
      </div>

      <div className={classes.toolBox}>
        <div className={classes.filterBox}>
        </div>
        <div className={classes.sortBox}>

          <FilterDropdown
            title="Show" items={data.shows}
            active={currentShow} onSelect={data.setFilterShow}
          />
        </div>
      </div>

      <div className='pageToolbarRight'>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleReset}
          sx={{ marginRight: '20px', marginLeft: '20px' }}
        >Reset</Button>

      </div>
    </div>
  );
};

PageToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default PageToolbar;
