import React from "react";
import { Box, Tooltip, Typography } from "@mui/material";
import {
  GRAPH_COLORS,
  COMMON_COLORS,
  predefinedGreenColors,
  predefinedBlueColors,
} from "../../theme/colors";

const getTotalUnitsProduced = (styles) => {
  return styles.reduce((total, item) => {
    return total + item.totalUnitProduced;
  }, 0);
};

const getTitle = (type) => {
  switch (type) {
    case "boxer":
      return "Men's Underwear";
    case "socks":
      return "Socks";
    default:
      return type;
  }
};

const generateColors = (steps, isBoxer) => {
  let definedColors = !isBoxer ? predefinedGreenColors : predefinedBlueColors;
  const colors = [];
  for (let i = 0; i < steps; i++) {
    colors.push(definedColors[i % definedColors.length]);
  }
  return colors;
};

const sendBackCorrectName = (style) => {
  if (style === "Crew") {
    return "Crew Cut";
  } else if (style === "Low") {
    return "Low Cut";
  } else if (style === "Boxer") {
    return "Men's Boxer Briefs";
  } else {
    return style;
  }
};

// Define the desired order of sizes
const BOXER_SIZE_ORDER = ["S", "M", "L", "XL", "2XL"].reverse();

const SOCKS_SIZE_ORDER = [
  "LXL",
  "SM",
  "YTHL",
  "YTHM",
  "TDLR24",
  "TDLR12",
  "BABY",
];

const adjustSizeNaming = (size) => {
  switch (size) {
    case "LXL":
      return "L/XL";
    case "SM":
      return "S/M";
    case "YTHL":
      return "Youth L";
    case "YTHM":
      return "Youth M";
    case "TDLR24":
      return "Toddler 2-4Y";
    case "TDLR12":
      return "Toddler 1-2Y";
    case "BABY":
      return "Baby 0-12M";
    default:
      return size;
  }
};

const CardsBarGraphs = ({ chosenProductType, product, index, isMobile }) => {
  const productType =
    chosenProductType === "DTC" ? "shopify" : chosenProductType;

  const consumerType = product.types ? product.types[productType] : null;

  if (!consumerType) {
    return null;
  }

  const totalUnitsProduced = getTotalUnitsProduced(consumerType.styles);

  const title = getTitle(product.type);

  const isBoxer = product.type === "boxer";

  const calculatePercentage = (count, total) =>
    ((count / total) * 100).toFixed(2);

  // conditionally render based off of the total units produced
  const renderBasedOffEmpty = (totalUnitsProduced, title) => {
    if (totalUnitsProduced === 0 && title === "Women’s Underwear") {
      return (
        <Box sx={styles.emptybox}>
          <Typography sx={styles.emptyBoxText}>Coming Soon.</Typography>
        </Box>
      );
    } else if (
      totalUnitsProduced === 0 &&
      (title === "Men's Underwear" || title === "Socks")
    ) {
      return (
        <Box sx={styles.emptybox}>
          <Typography sx={styles.emptyBoxText}>
            No {title}'s needed to be produced.
          </Typography>
        </Box>
      );
    }

    return (
      <>
        <Box sx={styles.graphicalDataAndIdentifiers}>
          <Box sx={styles.graphicalData}>
            {consumerType.styles.map((style, idx) => {
              return (
                <Box
                  key={idx}
                  sx={{
                    backgroundColor:
                      idx % 2 === 0
                        ? !isBoxer
                          ? GRAPH_COLORS.green
                          : GRAPH_COLORS.blue
                        : !isBoxer
                        ? GRAPH_COLORS.lightGreen
                        : GRAPH_COLORS.lightBlue,
                    width: "100%",
                    height: `${
                      (style.totalUnitProduced / totalUnitsProduced) * 100
                    }%`,
                  }}
                ></Box>
              );
            })}
          </Box>

          <Box sx={styles.identifiers}>
            {consumerType.styles.map((style, idx) => {
              let title = sendBackCorrectName(style.style);

              return (
                <Box key={idx} sx={styles.identifierRow}>
                  <Box
                    sx={{
                      backgroundColor:
                        idx % 2 === 0
                          ? !isBoxer
                            ? GRAPH_COLORS.green
                            : GRAPH_COLORS.blue
                          : !isBoxer
                          ? GRAPH_COLORS.lightGreen
                          : GRAPH_COLORS.lightBlue,
                      width: "20px",
                      height: "20px",
                      marginRight: "8px",
                      borderRadius: "25%",
                    }}
                  ></Box>
                  <Typography sx={styles.styleText}>{title}</Typography>
                  <Typography sx={styles.totalText}>
                    {style.totalUnitProduced.toLocaleString()}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        </Box>
        <Box sx={styles.bottomHalf}>
          {consumerType.styles.map((style, idx) => {
            const colors = generateColors(
              Object.keys(style.sizes).length,
              isBoxer
            );

            let title = sendBackCorrectName(style.style);

            const sortedSizes = Object.entries(style.sizes).sort(
              ([sizeA], [sizeB]) => {
                const sizeOrder = isBoxer ? BOXER_SIZE_ORDER : SOCKS_SIZE_ORDER;
                const indexA = sizeOrder.indexOf(sizeA);
                const indexB = sizeOrder.indexOf(sizeB);
                return indexA - indexB;
              }
            );

            return (
              <>
                <Box key={idx} sx={styles.sizeBreakdown}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Typography sx={styles.bottomHalfText}>{title}</Typography>
                    <Typography sx={styles.bottomHalfText}>
                      {style.totalUnitProduced.toLocaleString()}
                    </Typography>
                  </Box>
                </Box>
                <Box sx={styles.horizontalGraph}>
                  {sortedSizes.map(([size, count], sizeIdx) => (
                    <Tooltip
                      key={sizeIdx}
                      title={`${calculatePercentage(
                        count,
                        style.totalUnitProduced
                      )}%`}
                      placement="top"
                      arrow
                    >
                      <Box
                        sx={{
                          backgroundColor: colors[sizeIdx],
                          height: "100%",
                          width: `${calculatePercentage(
                            count,
                            style.totalUnitProduced
                          )}%`,
                        }}
                      ></Box>
                    </Tooltip>
                  ))}
                </Box>
                <Box sx={styles.sizesLabeslContainer}>
                  {sortedSizes.map(([size, count], sizeIdx) => {
                    size = adjustSizeNaming(size);

                    // Determine the number of items per row based on isBoxer
                    const totalPerRow = isBoxer ? 5 : 4;
                    const mainBoxWidth = `calc(100% / ${totalPerRow} - 5px)`; // Adjust width to include gap

                    // Check if the item is in the last row
                    const isLastRow =
                      sizeIdx >=
                      Math.floor(sortedSizes.length / totalPerRow) *
                        totalPerRow;

                    // Check if the item is in the top row
                    const isTopRow = sizeIdx < totalPerRow;

                    return (
                      <Box
                        key={sizeIdx}
                        sx={{
                          ...styles.labelsSizeBoxes,
                          width: isLastRow ? "auto" : mainBoxWidth,
                          flexGrow: isLastRow ? 1 : 0, // Only grow if in the last row
                          flexBasis: mainBoxWidth,
                          marginLeft: isTopRow ? 0.11 : 0, // Add padding to the top row
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <Box
                            sx={{
                              backgroundColor: colors[sizeIdx],
                              width: "25px",
                              height: "8px",
                              marginRight: "8px",
                              borderRadius: "10%",
                            }}
                          ></Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "flex-start",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: 14,
                                fontWeight: 500,
                              }}
                            >
                              {size}
                            </Typography>
                          </Box>
                        </Box>
                        <Typography
                          sx={{
                            fontSize: 16,
                            fontWeight: 600,
                          }}
                        >
                          {count.toLocaleString()}
                        </Typography>
                      </Box>
                    );
                  })}
                </Box>
              </>
            );
          })}
        </Box>
      </>
    );
  };

  return (
    <Box
      key={index}
      sx={{
        ...styles.mainCards,
        width: isMobile ? "95%" : "33%",
        padding: isMobile ? 1 : 2,
        height: isMobile ? "700px" : "auto",
      }}
    >
      <Box sx={styles.headerTitleAndTotalUnitsProduced}>
        <Typography
          sx={{
            fontSize: 20,
          }}
        >
          {title}
        </Typography>
        <Typography sx={styles.totalCount}>
          {totalUnitsProduced.toLocaleString()}
        </Typography>
      </Box>

      <Box sx={styles.hr}></Box>
      {renderBasedOffEmpty(totalUnitsProduced, title)}
    </Box>
  );
};

const styles = {
  emptyBoxText: {
    fontSize: 16,
    fontWeight: 500,
    color: COMMON_COLORS.grey,
  },
  emptybox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  sizesLabeslContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "5px",
    width: "100%",
    paddingTop: "8px",
  },
  labelsSizeBoxes: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid #e0e0e0",
    borderRadius: "10px",
    padding: 1,
    boxSizing: "border-box",
  },
  mainCards: {
    display: "flex",
    flexDirection: "column",
    width: "33%",
    backgroundColor: "#ffffff",
    borderRadius: 2,
    padding: 2,
    marginBottom: 2,
  },
  horizontalGraph: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: "20px",
    marginTop: "8px",
  },
  bottomHalfText: {
    fontSize: 16,
    fontWeight: 500,
  },
  headerTitleAndTotalUnitsProduced: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",
    paddingTop: 1,
  },
  totalCount: {
    fontSize: 20,
  },
  hr: {
    width: "100%",
    borderTop: "1px solid #EAEAEA",
    marginTop: 1,
    marginBottom: 1,
  },
  graphicalDataAndIdentifiers: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    width: "100%",
    height: "200px",
    gap: "16px",
  },
  graphicalData: {
    display: "flex",
    flexDirection: "column",
    width: "50%",
    height: "100%",
  },
  identifiers: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "50%",
  },
  identifierRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    marginBottom: "8px",
  },
  styleText: {
    flex: 1,
  },
  totalText: {
    marginLeft: "auto",
  },
  bottomHalf: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
  },
  sizeBreakdown: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "100%",
    marginTop: 2,
    backgroundColor: "#F0F8FF",
    padding: 1,
    boxSizing: "border-box",
  },
  styleHeader: {
    fontWeight: "bold",
  },
  sizes: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "8px",
    width: "100%",
  },
  sizeBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#ffffff",
    padding: 8,
    borderRadius: 4,
    border: "1px solid #e0e0e0",
    width: "100px",
  },
  totalUnits: {
    marginTop: 8,
    fontWeight: "bold",
  },
};

export default CardsBarGraphs;
