import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import PermissionPage from '../Permission';
import AuthUserContext from './context';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import _ from "lodash";

const withAuthorization = condition => Component => {

  class WithAuthorization extends React.Component {
    componentDidMount() {
      this.listener = this.props.firebase.auth.onAuthStateChanged(
        authUser => {
          if (!condition(authUser)) {
            this.props.history.push(ROUTES.SIGN_IN);
          }
        },
      );
    }

    componentWillUnmount() {
      this.listener();
    }

    render() {
      const location = this.props.location;
      return (
        <AuthUserContext.Consumer>
          {data => {

            // Check if 'data.formats' exists and is not null before filtering
            if (data.formats && Array.isArray(data.formats)) {
              data.formats = data.formats.filter(format => format.name !== "H1Crew");
            }


            if (!condition(data.user))
              return null;
            if (!data.formats || !data.cropSettings || !data.sizes)
              return null;

            const isView = _.find(data.user.view, page => {
              if (page === 'users' && location.pathname.includes('/users'))
                return true;
              return location.pathname === '/' || location.pathname === `/${page}`
            });

            const isEdit = _.find(data.user.edit, page => {
              if (page === 'users' && location.pathname.includes('/users'))
                return true;
              return location.pathname === '/' || location.pathname === `/${page}`
            });

            const routeProducts = `${ROUTES.PRODUCTS}`;
            const routeBundles = `${ROUTES.BUNDLES}`;
            if (location.pathname === routeProducts && (isView || isEdit)) {
              const isViewProduct = data.user.view.includes('product');
              const isEditProduct = data.user.edit.includes('product');
              return <Component
                {...this.props}
                page={location.pathname.substring(1)}
                viewOnly={!!isView}
                editOnly={!!isEdit}
                viewProduct={!!isViewProduct}
                editProduct={!!isEditProduct}
                user={data.user}
                formats={data.formats}
                sizes={data.sizes}
                cropSettings={data.cropSettings}
              />

            } else if (location.pathname === routeBundles && (isView || isEdit)) {
              const isViewProduct = data.user.view.includes('bundle');
              const isEditProduct = data.user.edit.includes('bundle');
              return <Component
                {...this.props}
                page={location.pathname.substring(1)}
                viewOnly={!!isView}
                editOnly={!!isEdit}
                viewProduct={!!isViewProduct}
                editProduct={!!isEditProduct}
                user={data.user}
                formats={data.formats}
                sizes={data.sizes}
                cropSettings={data.cropSettings}
              />

            } else if (isView || isEdit) {
              return <Component
                {...this.props}
                page={location.pathname.substring(1)}
                viewOnly={!!isView}
                editOnly={!!isEdit}
                user={data.user}
                formats={data.formats}
                sizes={data.sizes}
                cropSettings={data.cropSettings}
              />
            }

            return <PermissionPage {...this.props} user={data.user} page={location.pathname.substring(1)} />
          }
          }
        </AuthUserContext.Consumer>
      );
    }
  }

  return compose(
    withRouter,
    withFirebase,
  )(WithAuthorization);
};

withAuthorization.contextType = AuthUserContext;
export default withAuthorization;
