import {
  INJECT_ANALYTICS,
  SORT_DATA_BASED_ON_FILTER,
  UPDATE_SELECTED_DATE,
  UPDATE_SELECTED_PRODUCT,
  UPDATE_SELECTED_TYPE_OF_PRODUCT,
  INJECT_SHIPMENT_ANALYTICS,
  UPDATE_SELECTED_DATASET_FOR_SHIPPING,
  SORT_SHIPMENT_DATA,
  SET_LOADING,
  SET_OF_ITEMS_WITH_ERRORS,
  SET_PRODUCED_ANALYTICS,
  SET_SHIPPED_YESTERDAY_ANALYTICS,
  SET_FINISHED_FULL_FETCH,
  INJECT_UNSHIPPED_GRAPH_DATA,
} from "./types";


export const setShippedYesterdayAnalytics = (value) => {
  console.log("[setShippedYesterdayAnalytics]: ", value);
  return {
    type: SET_SHIPPED_YESTERDAY_ANALYTICS,
    payload: value,
  };
}

export const setNewProducedAnalytics = (value) => {
  return {
    type: SET_PRODUCED_ANALYTICS,
    payload: value,
  };
}

export const setItemsWithErrors = (value) => {
  return {
    type: SET_OF_ITEMS_WITH_ERRORS,
    payload: value,
  };
}

export const setRemoveLoading = (value) => {
  return {
    type: SET_LOADING,
    payload: value,
  };
}

export const injectAnalyticsForShipment = (data, typeOfAnalytic) => {
  return {
    type: INJECT_SHIPMENT_ANALYTICS,
    payload: {
      typeOfAnalytic,
      data,
    }
  };
}

export const injectUnshippedGraphAnalytic = (data) => {
  return {
    type: INJECT_UNSHIPPED_GRAPH_DATA,
    payload: {
      data,
    }
  };
}

export const updateSelectedShipmentDataset = (option, value) => {
  return {
    type: UPDATE_SELECTED_DATASET_FOR_SHIPPING,
    payload: { option, value },
  };
}

export const sortDataSetForShippingData = (value) => {
  return {
    type: SORT_SHIPMENT_DATA,
    payload: value,
  };
}

export const injectAnalytics = (data, typeOfAnalytic) => {
  return {
    type: INJECT_ANALYTICS,
    payload: {
      typeOfAnalytic,
      data,
    }
  };
}

export const sortDataBasedOnFilter = (data) => {
  return {
    type: SORT_DATA_BASED_ON_FILTER,
    payload: data,
  };
}

export const updateSelectedDate = (option, value) => {
  return {
    type: UPDATE_SELECTED_DATE,
    payload: { option, value },
  };
}

export const updateSelectedProduct = (option, value) => {
  return {
    type: UPDATE_SELECTED_PRODUCT,
    payload: { option, value },
  };
}

export const updateSelectedTypeOfProduct = (option, value) => {
  return {
    type: UPDATE_SELECTED_TYPE_OF_PRODUCT,
    payload: { option, value },
  };
}
