import { useState, useEffect } from 'react';
import { doc, onSnapshot } from 'firebase/firestore';

/**
 * Custom hook to listen to changes in a Firestore document.
 *
 * @param {object} db - The Firestore database instance.
 * @param {string} collectionName - The name of the collection containing the document.
 * @param {string} documentId - The ID of the document to listen to.
 * @returns {object|null} - The current document data or null if it hasn't loaded yet.
 */
const useFirestoreDocument = (db, collectionName, documentId) => {
  const [documentData, setDocumentData] = useState(null);

  useEffect(() => {
    if (!db || !collectionName || !documentId) return;

    const docRef = doc(db, collectionName, documentId);

    // Set up the listener
    const unsubscribe = onSnapshot(docRef, (doc) => {
      if (doc.exists()) {
        setDocumentData(doc.data());
      } else {
        console.log(`No such document: ${documentId}`);
        setDocumentData(null);
      }
    });

    // Clean up the listener on unmount
    return () => unsubscribe();
  }, [db, collectionName, documentId]);

  return documentData;
};

export default useFirestoreDocument;
