import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  table: {
    minWidth: 750,
  },
  itemTable: {
    width: '1100px'
  },
  tableCell: {
    fontSize: '14px',
    fontWeight: '500',
    letterSpacing: '1.5px',
    color: '#000000'
  },
  tableHeadLabel: {
    fontSize: '14px',
    fontWeight: '500',
    color: '#000000',
    letterSpacing: '1.5px'
  },
  itemTableCell: {
    fontSize: '14px',
    fontWeight: '500',
    letterSpacing: '1.5px',
    color: '#000000',
    borderBottom: 'none'
  },
  itemTableCellError: {
    fontSize: '14px',
    fontWeight: '500',
    letterSpacing: '1.5px',
    color: 'red',
    borderBottom: 'none'
  },
  tableRow: {
    height: '70px',
    backgroundColor: 'rgba(242, 244, 246, 0.4)',
    '&:hover': {
      backgroundColor: '#F1F7FE!important',
    }
  },
  tableRow2: {
    height: '70px',
    backgroundColor: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#F1F7FE!important',
    }
  },
  selectedTableRow: {
    backgroundColor: '#F1F7FE!important',
  },
  progressPrimaryError: {
    height: '6px!important',
    borderRadius: '3px',
    backgroundColor: '#14B779!important'
  },
  progressReady: {
    height: '6px!important',
    borderRadius: '3px',
    backgroundColor: 'rgba(20, 183, 121, 0.16 )!important'
  },
  progressBarReady: {
    height: '6px!important',
    borderRadius: '3px',
    backgroundColor: 'rgba(20, 183, 121)!important'
  },
  progressPrimaryNormal: {
    height: '6px!important',
    borderRadius: '3px',
    backgroundColor: 'rgba(31, 124, 242, 0.16)!important'
  },
  progressBarError: {
    height: '6px!important',
    borderRadius: '3px',
    backgroundColor: '#14B779!important'
  },
  progressBarNormal: {
    height: '6px!important',
    borderRadius: '3px',
    backgroundColor: '#1F7CF2!important'
  },
  jobActionButton: {
    width: '100%',
    color: '#FFFFFF',
    textTransform: 'initial',
    fontSize: '16px',
    fontWeight: '500',
    borderRadius: '6px'
  },
  jobCancelButton: {
    color: '#FFFFFF',
    textTransform: 'initial',
    fontSize: '16px',
    fontWeight: '500',
    borderRadius: '6px',
    "&.Mui-disabled": {
      background: "rgba(0, 0, 0, 0.12)!important",
      color: "rgba(0, 0, 0, 0.26)"
    }
  },
  buttonCSV: {
    display: 'flex',
    background: '#FFFFFF',
    color: '#1F7CF2',
    fontSize: '16px',
    fontWeight: '600',
    width: '100%',
    borderRadius: '6px',
    padding: '12px 0',
    alignItems: 'center',
    justifyContent: 'center',
    textDecoration: 'none'
  },

  buttonBack: {
    display: 'flex',
    background: 'rgb(20, 183, 121)',
    color: '#FFFFFF',
    fontSize: '16px',
    fontWeight: '600',
    width: '100%',
    borderRadius: '6px',
    padding: '12px 0',
    alignItems: 'center',
    justifyContent: 'center',
    textDecoration: 'none',
    marginTop: '20px'
  },
  itemNavButton: {
    fontSize: '14px',
    fontWeight: '600',
    letterSpacing: '1.5px',
    color: '#000000',
    borderRadius: '0',
    width: '100%'
  }
}));

export default useStyles;