import React, { useState, useEffect, createRef, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import CheckIcon from '@mui/icons-material/Check';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { ref, getDownloadURL } from "firebase/storage";
import { BootstrapInput } from '../Page/input';
import { UploadMockImage } from '../Products/components';
import { API } from '../../constants/defines';
import { downloadMockImage } from '../../module/functions';
import _ from 'lodash';


const useHeaderStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: '15px',
    top: '0px',
    color: '#000000',
  },
  headerBox: {
    padding: '20px 40px'
  },
  headerItem1: {
    borderRight: '2px solid #ccc'
  },
  headerItem2: {

  },
  headerItem3: {
    width: '50px'
  },
  headerSubTitle: {
    fontSize: '18px',
    fontWeight: 'bold'
  },
  skuLabel: {
    marginTop: '10px',
    fontSize: '16px',
    fontWeight: '500',
    color: '#000000'
  },
  inputLabel: {
    fontSize: '16px',
    fontWeight: '500',
    color: '#000000'
  },
  buttonDownload: {
    width: '40px',
    background: '#1F7CF2',
    color: '#FFFFFF',
    fontSize: '16px',
    fontWeight: '600',
    borderRadius: '6px',
    marginTop: '30px',
    marginLeft: '20px'
  },
  artCanvasBox: {
    width: '350px',
  },
  artCanvas: {
    textAlign: 'center'
  },
  artToolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '30px'
  },
  artButton: {
    cursor: 'pointer'
  },
  canvasPreview: {
    border: '1px solid #EEEEEE',
    width: '350px',
    height: '350px',
    overflow: 'hidden',
    position: 'relative'
  },
  coverImage: {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    textAlign: 'center'
  },
  hImage: {
    height: 'auto',
    width: '100%'
  },
  vImage: {
    height: '100%',
    width: 'auto',
    margin: '0 auto'
  },
  mockImageBox: {
    border: '2px solid #CCC',
    width: '350px',
    height: '350px',

    position: 'relative'
  },
  mockImage: {
    width: '100%',
    height: '100%',
    objectFit: 'contain'
  },
  box1: {
    width: '40px',
    left: '20px',
    top: '40%',
    position: 'absolute',
  },
  box2: {
    paddingLeft: '70px',
  },
  atomicBox: {
    padding: '20px 0',
    borderTop: '2px solid #cccccc'
  },
  blueButton: {
    background: '#1F7CF2',
    padding: '8px 56px',
    borderRadius: '6px',
    fontSize: '16px',
    fontWeight: '500',
    color: '#FFFFFF',
    textTransform: 'initial'
  },
}));

const getMockPath = (options, original_path) => {
  const { style, background, orientation } = options;
  let path = original_path;
  if (style === "separated") {
    if (orientation === "standard")
      path = `${path}`;
    else
      path = `r_${path}`;
  } else if (style === "split") {
    path = `s_${path}`;
  }
  return path;
}

const getMockPathUnderwear = (options, original_path) => {
  const { style, background, orientation } = options;
  let path = original_path;
  if (style === "separated") {
    if (orientation === "standard")
      path = `FS_${path}`;
    else
      path = `BS_${path}`;
  } else if (style === "split") {
    path = `FS_${path}`;
  }
  return path;
}

function AtomicImage(props) {
  const { classes, selected, onSelect, onChangeOption, data } = props;
  const [options, setOptions] = useState({ ...props.options });

  useEffect(() => {
    setOptions({ ...props.options });
  }, [props.options]);

  const handleChange = (event) => {
    let { name, value } = event.target;
    const newOptions = { ...options, [name]: value };
    //setOptions(newOptions);
    if (onChangeOption) {
      onChangeOption(data.sku, newOptions);
    }
  }

  const handleSelect = () => {
    if (onSelect) {
      onSelect(data.sku);
    }
  }

  const path = data.type === 'Underwear' ? getMockPathUnderwear(options, data.pathFront) : getMockPath(options, data.pathFront);

  return (
    <Box className={classes.atomicBox}>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <Box sx={{ position: 'relative' }}>
            <Box className={classes.box1}>
              <Checkbox
                color="primary"
                checked={selected}
                onChange={handleSelect}
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            </Box>
            <Box className={classes.box2}>
              <Box sx={{ width: '100%' }}>
                <UploadMockImage
                  name="mockup"
                  key="mockup"
                  transparent={options.background === "transparent"}
                  firebase={props.firebase}
                  classes={classes}
                  path={path}
                />
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={7}>
          <Typography variant='h3'>{data.name}</Typography>
          <Typography variant='h4'>{data.sku}</Typography>

          <Box sx={{ marginTop: '40px', maxWidth: '600px' }}>
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <InputLabel>Style</InputLabel>
                <Select
                  name="style"
                  value={options.style}
                  input={<BootstrapInput />}
                  onChange={handleChange}
                >
                  <MenuItem value="separated">Separated</MenuItem>
                </Select>
              </Grid>

              <Grid item xs={4}>
                <InputLabel>Background</InputLabel>
                <Select
                  name="background"
                  value={options.background}
                  input={<BootstrapInput />}
                  onChange={handleChange}
                >
                  <MenuItem value="white">White</MenuItem>
                  <MenuItem value="transparent">Transparent</MenuItem>
                </Select>
              </Grid>

              <Grid item xs={4}>
                <InputLabel>Orientation</InputLabel>
                <Select
                  name="orientation"
                  value={options.orientation}
                  input={<BootstrapInput />}
                  onChange={handleChange}
                >
                  <MenuItem value="standard">Standard</MenuItem>
                  <MenuItem value="reverse">Reverse</MenuItem>
                </Select>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

const INIT_OPTIONS = {
  style: "separated",
  background: "white",
  orientation: "standard"
}

function HistoryModal(props) {
  const { onClose, open, sizes, title, items } = props;

  const [options, setOptions] = useState({ ...INIT_OPTIONS });
  const [productOptions, setProductOptions] = useState([]);
  const [selected, setSelected] = useState([]);
  const [downloading, setDownloading] = useState(false);

  const classes = useHeaderStyles();
  const storage = props.firebase.getstorage();

  useEffect(() => {
    if (props.open) {
      setOptions({ ...INIT_OPTIONS });
      setDownloading(false);
      setSelected([]);

      const _products = _.filter(props.items, item => {
        const sizeItem = _.find(sizes, { id: item.size });
        if (!!sizeItem && (sizeItem.sku === "LXL" || sizeItem.sku === "YTHL" || sizeItem.sku === "L")) {
          return true;
        }
        return false;
      });

      const pOptions = _.map(_products, item => {
        return { sku: item.sku, options: { ...INIT_OPTIONS } };
      });
      setProductOptions([...pOptions]);
    }

  }, [props.open]);


  const handleCloseDialog = (event) => {
    onClose();
  }

  const handleChange = (event) => {
    let { name, value } = event.target;
    const _option = { ...options, [name]: value };
    setOptions(_option);
  }

  const handleDownload = () => {
    console.log('Handle Download');
    setDownloading(true);

    const _profiles = _.filter(items, item => {
      const sizeItem = _.find(sizes, { id: item.size });
      return !!sizeItem && (sizeItem.sku === "LXL" || sizeItem.sku === "YTHL" || sizeItem.sku === "L") && isSelected(item.sku);
    })

    const profiles = _.map(_profiles, product => {
      const sizeItem = _.find(sizes, { id: product.size });
      const _options = _.find(productOptions, { sku: product.sku }) ? _.find(productOptions, { sku: product.sku }).options : null;
      return { name: product.name, type: product.type, path: product.pathFront, options: _options, size: sizeItem.sku };
    });

    let params = { title, products: profiles };
    fetch(API.downloadMockup, {
      method: 'post',
      body: JSON.stringify(params)
    }).then(function (response) {
      return response.json();
    }).then(function (data) {
      console.log('Response:', data);
      if (data.success) {
        getDownloadURL(ref(storage, data.url))
          .then(url => {
            var downloadLink = document.createElement("a");
            downloadLink.download = `${data.name}`;
            downloadLink.href = url;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);

            setDownloading(false);
          })
          .catch(error => {
            console.log(error);
            setDownloading(false);
          })
      } else {
        console.log("Error");
      }
    });

  }

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handleSelect = (sku) => {

    let _selected = [...selected];

    if (isSelected(sku)) {
      _selected = _.filter(_selected, item => item !== sku);

    } else {
      _selected.push(sku);
    }

    setSelected(_selected);
  };

  const handlePushOption = () => {
    const _productOptions = _.map(productOptions, option => {
      return { ...option, options: { ...options } };
    });
    setProductOptions([..._productOptions]);
  }

  const handleChangeOption = (sku, values) => {
    const _productOptions = _.map(productOptions, item => {
      if (item.sku === sku) {
        return { ...item, options: { ...values } };
      }
      return { ...item };
    });
    setProductOptions([..._productOptions]);
  }

  const renderOptions = () => {
    return (
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <InputLabel>Style</InputLabel>
          <Select
            name="style"
            value={options.style}
            input={<BootstrapInput />}
            onChange={handleChange}
          >
            <MenuItem value="separated">Separated</MenuItem>
          </Select>
        </Grid>

        <Grid item xs={4}>
          <InputLabel>Background</InputLabel>
          <Select
            name="background"
            value={options.background}
            input={<BootstrapInput />}
            onChange={handleChange}
          >
            <MenuItem value="white">White</MenuItem>
            <MenuItem value="transparent">Transparent</MenuItem>
          </Select>
        </Grid>

        <Grid item xs={4}>
          <InputLabel>Orientation</InputLabel>
          <Select
            name="orientation"
            value={options.orientation}
            input={<BootstrapInput />}
            onChange={handleChange}
          >
            <MenuItem value="standard">Standard</MenuItem>
            <MenuItem value="reverse">Reverse</MenuItem>
          </Select>
        </Grid>
      </Grid>
    );
  }

  const renderHeaderBox = () => {
    return (
      <Box className={classes.headerBox}>

        <Grid container spacing={1}>
          <Grid item xs={5}>
            <Box className={classes.headerItem1}>
              <Typography variant="h1">Download Product Images</Typography>
              <Typography className={classes.headerSubTitle}>Batch: {title} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{products.length} Eligible Items</Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            {renderOptions()}
          </Grid>
          <Grid item xs={1}>
            <Button variant="blue" color="primary" disabled={downloading} sx={{ marginTop: '30px' }} onClick={handlePushOption}>
              <CheckIcon />
            </Button>
          </Grid>
        </Grid>
      </Box>

    );
  }

  const renderContent = () => {
    return (
      <Box >
        {products.map((product, index) => {
          const odd = index % 2 === 0;
          const selected = isSelected(product.sku);
          const _options = _.find(productOptions, { sku: product.sku }) ? _.find(productOptions, { sku: product.sku }).options : null;
          return (
            <AtomicImage
              {...props}
              key={`Atomic_${product.sku}`}
              classes={classes}
              selected={selected}
              data={product}
              onSelect={handleSelect}
              onChangeOption={handleChangeOption}
              options={_options}
            />
          )
        })}
      </Box>
    )
  }

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const _selected = _.map(products, item => item.sku);
      setSelected(_selected);
    } else {
      setSelected([]);
    }
  }

  const scroll = 'paper';
  const products = _.filter(items, item => {
    const sizeItem = _.find(sizes, { id: item.size });
    return !!sizeItem && (sizeItem.sku === "LXL" || sizeItem.sku === "YTHL" || sizeItem.sku === "L");
  })

  const numSelected = selected.length;
  const isSelectedAll = numSelected === products.length;

  return (
    <Dialog onClose={handleCloseDialog}
      aria-labelledby="uploads-dialog-title"
      open={open}
      fullWidth={true}
      maxWidth={"lg"}
      scroll={scroll}
    >
      <DialogContent sx={{ padding: '0 0', overflowX: 'hidden' }} dividers>
        <IconButton aria-label="close" variant='close' onClick={handleCloseDialog}>
          <CloseIcon />
        </IconButton>
        {renderHeaderBox()}
        {renderContent()}
        <Divider />
      </DialogContent>
      <DialogActions sx={{ padding: '20px 20px', overflowX: 'hidden' }}>
        <Box display="flex" justifyContent="space-between" sx={{ width: '100%' }}>
          <FormControl component="fieldset" sx={{ marginRight: '40px', height: '46px', width: '165px' }}>
            <FormGroup aria-label="position" row>
              <FormControlLabel
                className={classes.formLabel}
                control={
                  <Checkbox color="primary"
                    indeterminate={numSelected > 0 && !isSelectedAll}
                    checked={numSelected > 0 && isSelectedAll}
                    onChange={handleSelectAll}
                  />}
                label={numSelected > 0 && isSelectedAll ? "SELECT NONE" : "SELECT ALL"}
                labelPlacement="end"
              />
            </FormGroup>
          </FormControl>

          <Button
            variant="blue"
            sx={{ width: '180px' }}
            color="primary"
            disabled={downloading}
            onClick={handleDownload}>Download</Button>
        </Box>
      </DialogActions>

    </Dialog>
  );
}
export default HistoryModal;
