import { collections } from "../constants/defines";
import { addDoc, collection, deleteDoc, doc, getDoc, getDocs } from "firebase/firestore";
const FIVE_DAYS_IN_SECONDS = 7 * 24 * 60 * 60; // 10 days in seconds
import { Filter } from 'bad-words';
import leoProfanity from "leo-profanity";

const filter = new Filter();

const customProfanityList = [
  "BLM",
  "MAKE UP",
  "MAKEUP",
  "MAKE-UP",
  "MAKE - UP",
  "HAND JOB",
  "HAND JOBS",
  "HANDJOB",
  "HANDJOBS",
  "HAND JOB",
  "HAND JOBS",
  "HANDJOB",
  "HANDJOBS",
  "JACK OFF",
  "JACKOFF",
  "JACKOFFS",
  "JACK-OFF",
  "JACK-OFFS",
  "JACK-OFF",
  "JACK-OFFS",
  "KAMIKAZE",
  "KAMIKAZE",
  "KAMIKAZE",
  "KAMIKAZE",
  "KAMIKAZE",
  "KAMIKAZE",
  "KAMIKAZE",
  "KAMIKAZE",
  "KAMIKAZE",
  "JEW",
  "JEWS",
  "JEWISH",
  "JEWRY",
  "NUDITY",
  "NUDE",
  "NUDES",
]
// Add custom words to the profanity filter
filter.addWords(...customProfanityList.map(word => word.toLowerCase()));

leoProfanity.loadDictionary(); // Loads the default dictionary

const isProfaneFind = (word) => {
  //  log in what lib  the bad word was found  return badWordsFilter.isProfane(word) || leoProfanity.check(word);
  const isProfane = filter.isProfane(word);
  const isProfaneLeo = leoProfanity.check(word);
  return isProfane || isProfaneLeo;
}


const getRandomWord = async (db) => {
  const { names, currentLetter } = await getUsedNames(db);
  const word = await fetchUniqueWord(currentLetter, names);
  return word;
};

const getUsedNames = async (db) => {
  // Define the alphabet without 'X', 'Y', 'Z'
  const alphabetSequence = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

  // Get the current letter from Firestore
  const currentLetterDoc = await getDoc(doc(db, collections.SETTINGS, 'batchNaming'));
  let currentLetter = currentLetterDoc.data().currentLetter;

  // Determine the next letter in the sequence
  const currentIndex = alphabetSequence.indexOf(currentLetter);
  let nextLetter = alphabetSequence[(currentIndex + 1) % alphabetSequence.length];

  console.log(`Current letter is ${currentLetter}, setting to ${nextLetter}`);
  currentLetter = nextLetter;

  // Fetch used names and delete expired ones
  const querySnapshot = await getDocs(collection(db, collections.USED_BATCH_NAMES));
  const names = [];
  const currentDate = new Date();

  querySnapshot.forEach(async (documentData) => {
    const data = documentData.data();
    const createdAt = new Date(data.createdAt.seconds * 1000 + data.createdAt.nanoseconds / 1000000);
    const differenceInSeconds = (currentDate.getTime() - createdAt.getTime()) / 1000;

    if (differenceInSeconds > FIVE_DAYS_IN_SECONDS) {
      console.log("deleting doc", documentData.id);
      await deleteDoc(doc(db, collections.USED_BATCH_NAMES, documentData.id));
    } else {
      names.push(data.batchName);
    }
  });

  return { names, currentLetter };
}

const saveBatchName = async (db, batchName) => {
  const docRef = await addDoc(collection(db, collections.USED_BATCH_NAMES), {
    batchName,
    createdAt: new Date(),
  });
  return docRef;
}

const DATAMUSE_API = "https://api.datamuse.com/words";

/**
 * Fetches a unique word from Datamuse API based on specified criteria.
 * @param {string} startLetter - The starting letter for the words.
 * @param {Array<string>} usedNames - List of already used names.
 * @param {number} minLength - Minimum length of the word.
 * @param {number} maxLength - Maximum length of the word.
 * @returns {Promise<string>} A unique word.
 */

const fetchUniqueWord = async (startLetter, usedNames, minLength = 2, maxLength = 8) => {
  try {
    const url = `${DATAMUSE_API}?sp=${startLetter}*&max=1000&md=p,d`;
    const response = await fetch(url);
    const words = await response.json();
    const badWords = [];

    // Filter words by part of speech and profanity
    const filteredWords = words
      .filter(wordObj => {
        const word = wordObj.word.toUpperCase();
        const isProfane = isProfaneFind(word);
        const isValidPOS = wordObj.tags?.includes('n') || wordObj.tags?.includes('adj'); // Only nouns or adjectives
        if (isProfane) badWords.push(word);

        return (
          word.length >= minLength &&
          word.length <= maxLength &&
          isValidPOS &&
          !usedNames.includes(word) &&
          !isProfane
        );
      })
      .map(wordObj => wordObj.word.toUpperCase());

    console.log("Bad words found:", badWords.length);

    if (filteredWords.length > 0) {
      return filteredWords[Math.floor(Math.random() * filteredWords.length)];
    } else {
      console.error("No unique words found matching criteria.");
      return "UNABLE_TO_GENERATE_NAME";
    }
  } catch (error) {
    console.error("Error fetching words from Datamuse:", error);
    return "ERROR_FETCHING_WORD";
  }
};



export { getRandomWord, saveBatchName, fetchUniqueWord };
