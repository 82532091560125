import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import 'react-lazy-load-image-component/src/effects/blur.css';
import _ from "lodash";
import { PRODUCT_ART_PREFIX, PRODUCT_THUMB_PREFIX } from '../../constants/defines';
import { getProductBySku, validateProduct, validateCustomProduct } from '../../db/products';
import { withAuthorization } from '../Session';
import { formatBytes, cropImg } from '../../module/functions';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import blankImg from '../../images/placeholder.png';
import { ref, getDownloadURL } from "firebase/storage";

const useStyles = makeStyles((theme) => ({
    itemBox: {
        border: '1px solid #CDD4DB',
        background: '#F4F4F4',
        borderRadius: '3px'
    },
    textSKU: {
        textTransform: 'uppercase',
        textAlign: 'center'
    },
    textName: {
        fontWeight: 'bold',
        textAlign: 'center',
        fontSize: '16px',
        lineHeight: 1,
        marginBottom: '10px'
    },
    textBold: {
        fontWeight: 'bold',
        color: '#FFFFFF',
        textAlign: 'center',
        fontSize: '16px',
        lineHeight: 1
    },
    textSmall: {
        fontWeight: 400,
        fontSize: '14px'
    },
    specBox: {
        padding: '8px 20px'
    },
    titleBox: {
        padding: '5px 20px 5px',
        minHeight: '90px'
    },
    customBox: {
        minHeight: '22px',
        padding: '7px 20px'
    },
    fullImg: {
        width: '100%',
        height: 'auto'
    },
    soloImg: {
        width: '50%',
        height: 'auto'
    },
    sizeText: {
        fontSize: '18px',
        lineHeight: '24px',
        color: '#000000',
        textAlign: 'center'
    },
    artBox: {

    },
    coverImage: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0
    },
    overlayBox: {
        position: 'relative',
        paddingTop: '178.8%',
        overflow: 'hidden'
    },
    overlayMaskBox: {
        position: 'relative',
        paddingTop: '86.7%'
    },
    soloOverlayBox: {
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center'
    }
}));

function ArtImage(props) {
    const { firebase, path, classes, isMask, isSolo, isFront, size } = props;
    const [imagePath, setImagePath] = useState("");
    const [load, setLoad] = useState(true);

    useEffect(() => {
        if (path === "") {
            setLoad(false);
        } else {
            const storage = firebase.getstorage();
            getDownloadURL(ref(storage, `${PRODUCT_THUMB_PREFIX}${path}`))
                .then(url => {
                    setLoad(true);
                    var img = new Image();
                    img.onload = function () {
                        setImagePath(url);
                        setLoad(false);
                    };
                    img.src = url;
                })
                .catch(error => {
                    console.log(error);
                    setLoad(false);
                })
        }

    }, [props.path]);

    const sizeMB = formatBytes(size, 1);
    let frontSizeColor;
    let frontSize = sizeMB.value;
    let frontUnit = sizeMB.unit;

    if (frontSize >= 15.00 && frontSize < 25.00 && frontUnit === 'MB') {
        frontSizeColor = 'red';
    } else if (frontSize >= 25.00 && frontUnit === 'MB') {
        frontSizeColor = 'red';
    } else {
        frontSizeColor = 'rgb(20, 183, 121)';
    }

    return (
        <Box className={classes.artBox}>
            <Typography className={classes.sizeText} sx={{ color: frontSizeColor }}>{frontSize > 0 ? `${frontSize} ${frontUnit}` : `-`}</Typography>
            <Box className={isMask ? classes.overlayMaskBox : classes.overlayBox}>
                <Box className={classes.coverImage} display="flex" alignItems={load ? "center" : "flex-start"} justifyContent="center">
                    {load ? <CircularProgress /> : <LazyLoadImage alt={path} effect="blur" src={imagePath ? imagePath : blankImg} className={classes.fullImg} />}
                </Box>
            </Box>
        </Box>
    );
}

// primary row component
function InventoryGridItem(props) {
    const { data, db, templates, settings, handleClick } = props;
    const classes = useStyles();

    const [product, setProduct] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!!data.sku) {
            getProductBySku(db, data.sku, fetchData);
        }
    }, [data.sku]);

    const fetchData = (_product) => {

        const ret = _product.isCustomProduct ? validateCustomProduct(_product, templates) : validateProduct(_product, settings);
        setProduct({ ..._product, valid: ret.valid });
        setLoading(false);
    };

    const renderArts = () => {
        return (
            <Box display="flex" sx={{ padding: '10px 10px' }}>
                {product.pathFront && !product.isMask && (
                    <Box sx={{ padding: '0 5px', width: '50%' }}>
                        <ArtImage
                            key={`art_${product.sku}_front`}
                            isFront={true}
                            firebase={props.firebase.getFirebase()}
                            classes={classes}
                            cropSettings={settings}
                            isMask={product.isMask}
                            isSolo={product.identical}
                            size={product.frontArt.size}
                            path={product.pathFront}
                        />
                    </Box>
                )}

                {product.pathBack && (
                    <Box sx={{ padding: '0 5px', width: product.isMask ? '100%' : '50%' }}>
                        <ArtImage
                            key={`art_${product.sku}_back`}
                            isFront={false}
                            firebase={props.firebase.getFirebase()}
                            cropSettings={settings}
                            classes={classes}
                            isMask={product.isMask}
                            isSolo={product.identical}
                            size={product.backArt.size}
                            path={product.pathBack}
                        />
                    </Box>
                )}
            </Box>
        );
    }

    const handleClickItem = (event) => {
        if (handleClick)
            handleClick(event, data.sku);
        console.log('handle Click');
    }

    const getSpecColor = () => {
        if (product.isBundle) {
            return '#AEDBC7';
        } else if (!product.valid) {
            return '#FF8F92';
        }
        return '#AEDBC7';
    }

    let bgColor = '#AEDBC7', strTemplate = '', strCustom = '';
    if (!loading) {
        bgColor = getSpecColor();
        if (product.templates.length > 0)
            strTemplate = _.find(templates, { id: product.templates[0].template }) ? _.find(templates, { id: product.templates[0].template }).name : "";
        if (product.isCustomProduct)
            strCustom = "Custom";
    }

    return (
        <Box className={classes.itemBox} onClick={handleClickItem}>
            {!loading && renderArts()}
            <Box className={classes.titleBox}>
                <Typography className={classes.textName}>{data.name}</Typography>
                <Typography className={classes.textSKU}>{data.sku}</Typography>
            </Box>
        </Box>
    )
}

const condition = authUser => !!authUser;
export default withAuthorization(condition)(InventoryGridItem);
