import {collections, SIZES} from '../constants/defines';
import _  from "lodash";
import { 
  collection, 
  query, 
  where, 
  getDocs, 
  orderBy,
  doc,
  updateDoc,
  addDoc,
  setDoc,
  getDoc,
  deleteDoc,
  onSnapshot,
  writeBatch
} from "firebase/firestore";

const getBarcode = (db, name, callback) => {
  const q = query(collection(db, collections.BARCODES), 
    where("name", "==", name)
  );

  getDocs(q)
    .then((querySnapshot) => {
      let _item = {exists: false, qty: 1, sku: name, name: '', format: '', size: ''};
      querySnapshot.forEach((doc) => {
        if (doc.exists) {
          const data = doc.data();              
          _item.name = data.name;
          _item.size = _.find(SIZES, item=>doc.id===data.size).name;
          _item.data = data;
        }  
      });
      callback(_item);
    })
    .catch( error => {
      console.log('getBarcode: ', error);
      callback(null);
    });
}

const getBarcodes = (db, limit, callback) => {
  const q = query(collection(db, collections.BARCODES), orderBy('name'));

  onSnapshot(q, (querySnapshot) => {
    let items = [];
    querySnapshot.forEach((doc) => {
      let data = doc.data();
      items.push({
        ...data, 
        id: doc.id 
      });
    });
    callback(items);
  });
}

const createBarcode = (db, item) => {
  const newItem = { ...item };
  const currentTime = Date();
  newItem.createdAt = currentTime; 

  return new Promise((resolve, reject) => {
    addDoc(collection(db, collections.BARCODES), newItem)  
    .then((docRef) => {
        console.log("New Barcode created with ID: ", docRef.id);
        resolve();
    })
    .catch((error) => {
        console.error("Error adding Barcode: ", error);
        reject();
    });
  });
}

const updateBarcode = (db, item) => {
  return new Promise((resolve, reject) => {
    const docRef = doc(db, collections.BARCODES, item.id);
    let _item = {...item};
    delete _item.id;
    updateDoc(docRef, _item)
      .then(() => {
        console.log("Barcode successfully updated!");
        resolve();
      })
      .catch((error) => {
        // The document probably doesn't exist.
        console.error("Error updating Barcode: ", error);
        reject();
      });
  });
}

const deleteBarcode = (db, item) => {
  return new Promise((resolve, reject) => {
    deleteDoc(doc(db, collections.BARCODES, item.id))
      .then(() => {
        console.log("Barcode successfully deleted!");
        resolve();
      })
      .catch((error) => {
        // The document probably doesn't exist.
        console.error("Error deleting Barcode: ", error);
        reject();
    });
  });  
}

export { getBarcodes, getBarcode, createBarcode, updateBarcode, deleteBarcode };