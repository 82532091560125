import { Box, DialogTitle, Grid, IconButton, MenuItem, Select } from '@mui/material';
import React, { useState, useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { BootstrapInput } from '../Page/input';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import _ from 'lodash';
import { hanldeChangeBundle } from '../../redux/bundles/actions';

const ModalHeader = ({
  boxset,
  handleCloseDialog,
  hideItems,
  sizes,
  handleChange,
  setOpenVariantsModal,
  isMixedBoxSet,
  dispatch,
  originalDropDownData,
  originalSubDocs,
  isCreate
}) => {
  let sizeUpdate = sizes;
  if (boxset.type === 'Mixed') {
    //filter(size => size.type === 'Socks') and Underwear
    sizeUpdate = sizes.filter(size => size.type === 'Socks' || size.type === 'Underwear');
  } else if (boxset.type === 'Socks') {
    sizeUpdate = sizes.filter(size => size.type === 'Socks');
  } else if (boxset.type === 'Underwear') {
    sizeUpdate = sizes.filter(size => size.type === 'Underwear');
  }

  const handleSizeChange = (event) => {

    if (isCreate) {
      const newSizeID = event.target.value;
      console.log('Sizes', sizes);
      const newSize = _.find(sizes, { id: newSizeID });
      handleChange({ target: { name: 'size', value: newSize.id } });
      return
    }

    const newSizeID = event.target.value;

    const newBundleSelected = _.filter(originalSubDocs, (bundle) => bundle.size === newSizeID);

    dispatch(hanldeChangeBundle(newBundleSelected[0]));

  };

  return (
    <>
      <DialogTitle>
        <IconButton aria-label="close" variant='close' size="large" onClick={handleCloseDialog}
          sx={styles.variantsIcon}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Grid container spacing={2} sx={styles.header}>
        <Grid item xs={12} sm={5}>
          <TopStyledBootstrapInput
            disabled={hideItems}
            placeholder="Product Name"
            value={boxset.name}
            name="name"
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12} sm={3}>
          <TopStyledBootstrapInput
            disabled={hideItems}
            placeholder="Product Sku"
            name="originalSku"
            value={boxset.originalSku}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12} sm={1}>

          <Box sx={styles.variantsIcon} onClick={() => {
            setOpenVariantsModal(true)
          }}>
            <AutoAwesomeMotionIcon sx={{
              fontSize: '30px',
              color: hideItems ? 'grey' : 'black'
            }} />
          </Box>

        </Grid>

        <Grid item xs={12} sm={2}>
          <Select
            onChange={handleSizeChange}
            value={boxset.size}
            input={<StyledBootstrapInput />}
          >
            {
              !isCreate
                ? originalDropDownData.map((sizeId) => {
                  const sizeName = _.find(sizes, { id: sizeId })?.name || '';
                  return (
                    <MenuItem key={sizeId} value={sizeId}>
                      {sizeName}
                    </MenuItem>
                  );
                })
                : sizeUpdate.map((size) => {
                  const sizeName = _.find(sizes, { id: size.id })?.name || '';

                  return (
                    <MenuItem key={size.id} value={size.id}>
                      {sizeName}
                    </MenuItem>
                  );
                })
            }


          </Select>
        </Grid>

      </Grid>
    </>
  );

};

const StyledBootstrapInput = ({ disabled, ...props }) => (
  <BootstrapInput
    {...props}
    disabled={disabled}
    sx={{
      width: '100%',
      height: '60px',
      opacity: disabled ? 0.5 : 1,  // Reduce opacity when disabled
      '&& .MuiInputBase-input': {
        width: '100%',
        height: '100%',
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'center',
      },
      backgroundColor: disabled ? '#f0f0f0' : 'transparent', // Grey background when disabled
    }}
  />
);

const TopStyledBootstrapInput = ({ disabled, ...props }) => (
  <BootstrapInput
    {...props}
    disabled={disabled}
    sx={{
      height: '60px',
      opacity: disabled ? 0.5 : 1,
      '&& .MuiInputBase-input': {
        height: '100%',
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'center',
      },
      backgroundColor: disabled ? '#f0f0f0' : 'transparent',
    }}
  />
);

const colors = {
  lightGrey: 'rgba(0,0,0,0.04)',
}

const styles = {
  variantsIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '3.4375rem',
    height: '3.4375rem',
    backgroundColor: colors.lightGrey,
    border: '.0625rem solid lightgrey',
    borderRadius: '50%',
    cursor: 'pointer',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    width: '99%',
    marginLeft: '.625rem',
    gap: '1rem',
    marginBottom: '10px',
  },
}

export default ModalHeader;
