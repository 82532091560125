



const isIdentical = (dimensions, cropSettings) => {
  // Find the crop setting for 'Socks' that matches the dimensions
  const matchingCropSetting = cropSettings.find(setting => {
    return setting.type === 'Socks' &&
      setting.hyperOpticCanvas &&
      setting.hyperOpticCanvas.width === dimensions.width &&
      setting.hyperOpticCanvas.height === dimensions.height;
  });

  if (!matchingCropSetting) {
    return {
      noSizeFound: true,
      passAlong: true
    }
  }

  return matchingCropSetting;

};

export const checkImageDimensions = (file, cropSettings) => {
  return new Promise((resolve, reject) => {
    const tempUrl = URL.createObjectURL(file);
    const img = new Image();

    img.onerror = () => {
      URL.revokeObjectURL(tempUrl);
      reject(new Error("Error loading image"));
    };

    img.onload = () => {
      const dimensions = {
        width: img.naturalWidth,
        height: img.naturalHeight,
      };

      const identicalSetting = isIdentical(dimensions, cropSettings);

      if( !identicalSetting.noSizeFound && identicalSetting.passAlong) {
        resolve(identicalSetting);
      }

      resolve(identicalSetting);
      
    };

    img.src = tempUrl;
  });
};



const getImageBlobSize = async (img) => {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement('canvas');
    canvas.width = img.naturalWidth;
    canvas.height = img.naturalHeight;

    const ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0);

    canvas.toBlob((blob) => {
      resolve(blob.size);
    });
  });
};


export const getDimenssionsAndSize = async (file) => {
  return new Promise(async (resolve, reject) => {
    const tempUrl = URL.createObjectURL(file);
    const img = new Image();

    img.onerror = () => {
      URL.revokeObjectURL(tempUrl);
      reject(new Error("Error loading image"));
    };

    img.onload = async () => {
      // Wait for the image blob size calculation to complete
      const blobSize = await getImageBlobSize(img);  // Use await to resolve the Promise
      const dimensions = {
        width: img.naturalWidth,
        height: img.naturalHeight,
        size: blobSize,  
        path: file.name  
      };
      resolve(dimensions);
    };

    img.src = tempUrl;
  });
}
