import React, { useState, useEffect, createRef, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Divider from '@mui/material/Divider';
import InputBase from '@mui/material/InputBase';
import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormControlLabel from '@mui/material/FormControlLabel';
import Link from '@mui/material/Link';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Dropzone from 'react-dropzone';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { EnhancedTableHead } from '../Page/table';
import { ConfirmDialog } from '../Page/generalModal';
import { PRODUCT_ART_PREFIX, TYPE_OF_PRODUCT } from '../../constants/defines';
import { existProductArt } from '../../module/storage';
import {
  getMatchedCropSettings,
  getMatchedCropSettingByFront,
  getMatchedCropSettingByBack
} from '../../db/cropSettings';
import { getBarcodes } from '../../db/barcodes';
import {
  PRODUCT_DEFAULT,
  getProductBySku,
  createProduct,
  updateProduct,
  deleteProduct,
  getProductVariants,
  checkBarcodeLength
} from '../../db/products';
import { BootstrapInput } from '../Page/input';
import { SoloArt, FileArt, ProductArt, MockupImage } from './components';
import {
  filterTypes,
  filterStyles
} from './Products.constants'
import UnderwearMockups from './underwearMockups';
import { uploadToStorage } from '../../module/storage';
import {
  checkImageDimensions,
  getDimenssionsAndSize
} from '../../utils/findIfIdentical';
import { checkIfItsFrontOrBack } from '../../utils/extractProductDetails';
import { RevampedProductProfile } from './revampProductProfile';
import { TextField } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

const useHeaderStyles = makeStyles((theme) => ({
  tagList: {
    display: 'flex',
    listStyle: 'none',
    padding: theme.spacing(0.5),
  },
  revampTagList: {
    display: 'flex',
    listStyle: 'none',
    padding: theme.spacing(0.5),
  },
  chip: {
    marginRight: '16px',
    borderRadius: '6px',
    background: '#3d3d3d',
    height: '40px',
    padding: '0 15px 0 25px'
  },
  revampChip: {
    marginRight: '16px',
    background: '#2870EA',
    height: '40px',
    justifyContent: 'space-between',
    padding: '0 0 0 25px',
    borderRadius: '20px',

  },
  chipLabel: {
    fontSize: '16px',
    fontWeight: '500',
    color: '#FFFFFF'
  },
  chipButton: {
    color: '#ffffff',
    marginLeft: '15px',
    marginRight: '15px',
    padding: '0',
    '&:hover': {
      opacity: '1',
    }
  },
  image: {
    width: '100%',
    height: 'auto'
  },
  dropBox: {
    textAlign: 'center',
    padding: '71px 0px',
    borderRadius: '6px',
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    marginBottom: '25px',
    '& svg': {
      opacity: '0.56'
    }
  },
  dropLabel: {
    fontSize: '16px',
    fontWeight: '500',
    color: '#000000',
    opacity: '0.56',
    margin: '0'
  },
  newDropLabel: {
    fontSize: '20px',
    fontWeight: '500',
    color: '#000000',
    margin: '0',
  },
  dropButton: {
    fontSize: '16px',
    fontWeight: '500',
    color: '#FFFFFF',
    width: '100%',
    background: '#3D3D3D',
    borderRadius: '6px',
    textTransform: 'Capitalize',
    height: '40px',
    padding: '0'
  },
  imageBox: {
    width: '100%',
    height: '100%',
  },
  hImage: {
    width: '100%',
    height: '100%',
  },
  vImage: {
    width: '100%',
    height: '100%',
  },
  artBox: {
    position: 'relative',
    textAlign: 'center',
  },
  coverImage: {
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  imageAndToolbarContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between', // Ensures the toolbar is at the bottom,
  },
  artCanvasBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between', // This will push the toolbar to the bottom
    height: '100%',
    position: 'relative',
    '&:hover $artToolbar': {
      opacity: 1,
    },
  },
  artCanvas: {
    textAlign: 'center'
  },
  artToolbar: {
    display: 'flex',
    justifyContent: 'center', // Center toolbar items
    gap: '10px', // Space between buttons
    opacity: 0, // Initially hidden, shown on hover
    transition: 'opacity 0.3s ease-in-out',
    bottom: '5%',
    paddingRight: '15%',
    alignItems: 'center',
    position: 'absolute',
  },
  artButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
    borderRadius: '25%',
    width: '35px',
    height: '35px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f0f0f0', // Slightly darker on hover
    },
  },
  saveButton: {
    // Adapt your existing button styles to match the circular design
    borderRadius: '50%',
    minWidth: '40px',
    height: '40px',
    '& .MuiButton-startIcon': {
      margin: 0,
    },
  },
  canvasPreview: {
    width: '100px',
    height: '300px',
    overflow: 'hidden',
    position: 'relative',
  },
  productProfileDialogue: {
    height: '85vh',
    width: '90vw',
    maxWidth: '90vw',
    borderRadius: '20px'
  }
}));

const dropzoneRef1 = createRef();
const dropzoneRef2 = createRef();
const dropZoneHyperOptic = createRef();

const isMask = (type) => type === "Masks";

const headCells = [
  {
    id: 'template',
    numeric: false,
    align: 'left',
    disablePadding: false,
    label: 'Vendor Template',
    width: '300px'
  },
  {
    id: 'barcode',
    numeric: true,
    align: 'left',
    disablePadding: false,
    label: 'Barcode #',
    width: '250px'
  },
  {
    id: 'price',
    numeric: true,
    align: 'left',
    disablePadding: false,
    label: 'Price',
    width: '150px'
  },
  {
    id: 'sku',
    numeric: true,
    align: 'left',
    disablePadding: false,
    label: 'Vendor SKU',
    width: 'auto'
  },
];

function ProductModal(props) {
  const {
    handleClose,
    open,
    sku,
    isCreate,
    templates,
    tags,
    cropSettings,
    addingProduct
  } = props;
  const [product, setProduct] = useState({ ...PRODUCT_DEFAULT });
  const [barcodes, setBarcodes] = useState([]);
  const [load, setLoad] = useState(false);
  const [expandAttr, setExpandAttr] = useState(true);
  const [expandArt, setExpandArt] = useState(true);
  const [expandVendor, setExpandVendor] = useState(false);
  const [sizes, setSizes] = useState([]);
  const [formats, setFormats] = useState([]);
  const [styles, setStyles] = useState([]);
  const [triggerSave, setTriggerSave] = useState(false);

  const [dontCloseFullModal, setDontCloseFullModal] = useState(true);

  const [expandImage, setExpandImage] = useState(true);
  const [frontProgress, setFrontProgress] = useState(100);
  const [backProgress, setBackProgress] = useState(100);
  const [hyperOpticIdenticalProgress, setHyperOpticIdenticalProgress] = useState(100);
  const [deleteModal, SetDeleteModal] = useState(false);
  const [duplicateModal, SetDuplicateModal] = useState(false);
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [frontDimension, setFrontDimension] = useState({});
  const [backDimension, setBackDimension] = useState({});
  const [frontImg, setFrontImg] = useState(null);
  const [backImg, setBackImg] = useState(null);
  const [frontPath, setFrontPath] = useState("");
  const [backPath, setBackPath] = useState("");


  const [didSelectHyperOptic, setDidSelectHyperOptic] = useState(false);
  const [dontShowFullModal, setDontShowFullModal] = useState(true);

  const [hyperOpticIdenticalImage, setHyperOpticIdenticalImage] = useState(null);
  const [hyperOpticLeftImage, setHyperOpticLeftImage] = useState(null);
  const [hyperOpticRightImage, setHyperOpticRightImage] = useState(null);
  const [isHyperOpticFraternal, setIsHyperOpticFraternal] = useState(false);

  const [hyperOpticLeftPath, setHyperOpticLeftPath] = useState("");
  const [hyperOpticRightPath, setHyperOpticRightPath] = useState("");
  const [hyperOpticIdenticalPath, setHyperOpticIdenticalPath] = useState("");

  const [showVendorAttributes, setShowVendorAttributes] = useState(false);

  const [newVendor, setNewVendor] = useState({
    template: "",
    barcode: "",
    sku: "",
    price: ""
  });

  const [isBoxedImagePath, setIsBoxedImagePath] = useState("");

  const [isBoxedImage, setIsBoxedImage] = useState(null);

  const [didSelectBoxedImage, setDidSelectBoxedImage] = useState(false);
  const [strSize, setStrSize] = useState('')


  const resetStateForHyperOptic = () => {
    setHyperOpticIdenticalImage(null);
    setHyperOpticRightImage(null);
    setHyperOpticLeftImage(null);
    setHyperOpticIdenticalPath("");
    setHyperOpticRightPath("");
    setHyperOpticLeftPath("");
    setIsHyperOpticFraternal(false);
  }

  const classes = useHeaderStyles();
  const db = props.firebase.getdb();
  const storage = props.firebase.getstorage();
  const viewOnly = props.viewOnly;

  const isUnderwear = product.type.toLowerCase() === TYPE_OF_PRODUCT.UNDERWEAR;
  useEffect(() => {
    if (props.open) {

      setLoad(false);
      setFormats([...props.formats]);
      setSizes([...props.sizes]);

      const _styles = _.filter(filterStyles, item => {
        return item.id > 2
      });
      setStyles([..._styles]);

      if (isCreate) {
        setProduct({ ...PRODUCT_DEFAULT });
        setLoad(true);

      } else {
        // start of selection when the modal is opened
        getProductBySku(db, props.sku, fetchData);
      }

      getBarcodes(db, 10, fetchBarcodes)

      setError(false);
      setErrorMsg("");

      setFrontImg(null);
      setFrontDimension({});
      setBackImg(null);
      setBackDimension({});

      setHyperOpticIdenticalImage(null);
      setHyperOpticRightImage(null);
      setHyperOpticLeftImage(null);
      setHyperOpticIdenticalPath("");
      setHyperOpticRightPath("");
      setHyperOpticLeftPath("");
      setIsHyperOpticFraternal(false);
      setDidSelectHyperOptic(false);

      setShowVendorAttributes(false);
      setNewVendor({ template: "", barcode: "", sku: "", price: "" });


      setFrontPath("");
      setBackPath("");
    }

    if (!props.open) {
      setProduct({ ...PRODUCT_DEFAULT });
      setFrontImg(null);
      setFrontPath("");
      setBackImg(null);
      setBackPath("");
      setIsBoxedImagePath("");
    }

  }, [props.sku, props.open]);

  const loadSpecs = (value) => {
    if (value === '') {
      setFormats([...props.formats]);
      setSizes([...props.sizes]);
      const _styles = _.filter(filterStyles, item => {
        return item.id > 2
      });
      setStyles([..._styles]);

    } else {
      const _formats = _.filter(props.formats, { type: value })
      setFormats([..._formats]);
      const _sizes = _.filter(props.sizes, { type: value })
      setSizes([..._sizes]);

      const _styles = _.filter(filterStyles, item => {
        return item.id > 2 && item.type.includes(value);
      })
      setStyles([..._styles]);
    }
  }

  useEffect(() => {
    loadSpecs(product.type);
  }, [product]);

  const fetchData = (_product) => {
    // support legacy tag values (will be deprecated in future versions)
    if (typeof _product.tags === 'string') {

      let _tags = _product.tags.split(',');
      let newTags = [];
      _.map(_tags, tag => {
        let _item = _.find(tags, { id: tag });
        if (!!_item)
          newTags.push(_item.name);
      });

      _product.tags = [...newTags];
    }

    setProduct({ ..._product });
    setLoad(true);
  };

  const fetchBarcodes = (codes) => {
    setBarcodes([...codes]);
  }

  const checkTemplateMatchSize = (item) => {
    const size = item.size;
    let ret = true;
    _.map(item.templates, template => {
      var _template = _.find(templates, { id: template.template });
      if (_template.size !== size) {
        ret = false;
      }
    });
    return ret;
  }

  const checkTemplateMatchDimension = (item, dimension) => {
    const size = item.size;
    let ret = false;
    _.map(item.templates, template => {
      var _template = _.find(templates, { id: template.template });
      if (_template.canvas.width === dimension.width && _template.canvas.height === dimension.height) {
        ret = true;
      } else if (_template.canvasModern.width === dimension.width && _template.canvasModern.height === dimension.height) {
        ret = true;
      }
    });
    return ret;
  }

  const checkBundleInfo = () => {
    let msg = '';

    if (product.sku === '') {
      msg = 'No SKU Field';
    } else if (product.name === '') {
      msg = 'No Name field';
    } else if (product.type === '') {
      msg = 'No Type Field';
    } else if (product.style === '' || product.style === '-') {
      msg = 'No Style Field';
    } else if (product.format === '') {
      msg = 'No Format Field';
    } else if (product.size === '') {
      msg = 'No Size Field';
    }

    if (msg != '') {
      setError(true);
      setErrorMsg(msg);
      return false;
    }
    return true;
  }
  const checkProductInfo = () => {
    let errors = []; // Array to collect error messages

    const sizeItem = _.find(sizes, { id: product.size });
    const strSize1 = sizeItem ? sizeItem.sku : '';
    setStrSize(strSize1)
    const isCustomProduct = product.templates.length > 0;
    const settings = getMatchedCropSettings(cropSettings, product.type, product.style, product.size, product.format);
    let template = null;
    if (product.templates.length > 0) {
      template = _.find(templates, { id: product.templates[0].template });
    }
    const skuArray = product.sku.split('-');

    let vendors = _.filter(product.vendors, item => item.template === "" || item.barcode === "" || item.price === "");
    let vendorError = checkBarcodeLength(barcodes, product.vendors);

    if (product.sku === '') {
      errors.push("Product SKU is missing.");
    }
    if (product.name === '') {
      errors.push("Product name is missing.");
    }
    if (product.type === '') {
      errors.push("Product type is missing.");
    }
    if (product.style === '' || product.style === '-') {
      errors.push("Product style is not selected.");
    }
    if (product.format === '') {
      errors.push("Product format is missing.");
    }
    if (product.size === '') {
      errors.push("Product size is missing.");
    }
    if (product.sku.includes('CUSTOM') && !isCustomProduct) {
      errors.push("The SKU indicates a custom product, but the custom product option is not selected.");
    }
    if (product.sku.includes('CUSTOM') && product.templates.length === 0) {
      errors.push("Template required for custom products is missing.");
    }
    if ((isCustomProduct === true && !product.sku.includes('CUSTOM')) ||
      (isCustomProduct === true && product.templates.length > 0 && !product.sku.includes('CUSTOM'))) {
      errors.push("SKU name does not align with custom product specifications.");
    }
    if (product.templates.length > 0 && !checkTemplateMatchSize(product)) {
      errors.push("Selected template does not match product specifications or size.");
    }
    if (template && template.isCustomText && product.color === "") {
      errors.push("Color selection is required for products with custom text templates.");
    }
    if (!!frontImg && settings.length > 0 && !isCustomProduct && !getMatchedCropSettingByFront(settings, frontDimension.width, frontDimension.height)) {
      errors.push("The dimensions of the uploaded front artwork do not match the required settings.");
    }
    if (!!backImg && settings.length > 0 && !isCustomProduct && !getMatchedCropSettingByBack(settings, backDimension.width, backDimension.height)) {
      errors.push("The dimensions of the uploaded back artwork do not match the required settings.");
    }
    if (!!frontImg && isCustomProduct && !checkTemplateMatchDimension(product, frontDimension)) {
      errors.push("The dimensions of the uploaded front artwork do not match any template dimensions.");
    }
    if (!!backImg && isCustomProduct && !checkTemplateMatchDimension(product, backDimension)) {
      errors.push("The dimensions of the uploaded back artwork do not match any template dimensions.");
    }
    if (vendors.length > 0) {
      errors.push("One or more vendor attributes are incomplete or missing (template, barcode, or price).");
    }
    if (vendorError.error) {
      errors.push(`Barcode number validation failed: ${vendorError.msg}`);
    }

    const artError = validateArtRequirements({
      isCreate,
      isDuplicate,
      product,
      frontImg,
      backImg
    });
    if (artError) {
      console.log('Art Error:', artError);
      errors.push(artError);
    }

    if (errors.length > 0) {
      console.log('Errors:', errors.join(" | "));
      setError(true);
      setErrorMsg(errors.join(" | "));
      return false;
    }

    setError(false);
    setErrorMsg('');
    return true;
  }


  // use switch cases, or a less inline nested if-else statement.
  function validateArtRequirements({
    isCreate,
    isDuplicate,
    product,
    frontImg,
    backImg
  }) {

    if (didSelectHyperOptic) {
      if (isCreate || isDuplicate) {
        if (!hyperOpticIdenticalImage && !hyperOpticLeftImage && !hyperOpticRightImage) {
          return 'Missing image for Hyper Optic product.';
        }
        if (isHyperOpticFraternal && (!hyperOpticLeftImage || !hyperOpticRightImage)) {
          return 'Missing fraternal images for Hyper Optic product.';
        }
      }

      return null;
    }

    if (didSelectBoxedImage) {
      if (isCreate || isDuplicate) {
        if (!isBoxedImage) {
          return 'Missing image for boxed product.';
        }
      }

      return null;
    }

    // Condition 1: Creating or duplicating a mask product without a back image
    if ((isCreate || isDuplicate) && isMask(product.type) && !backImg) {
      return 'Missing back image for mask product.';
    }

    // Condition 2: Creating or duplicating a non-mask product without front or back images
    if ((isCreate || isDuplicate) && !isMask(product.type) && (!frontImg || !backImg)) {
      return 'Missing front or back image for non-mask product.';
    }

    // Condition 3: Not creating or duplicating a mask product without a back image or path
    if (!isCreate && !isDuplicate && isMask(product.type) && !backImg && !product.pathBack) {
      return 'Missing back image or path for existing mask product.';
    }

    // If none of the conditions above are met, return null to indicate no error
    return null;
  }

  const checkVariantSKU = () => {
    const variants = _.map(product.variants, variant => {
      return getProductVariants(db, variant)
    });
    return Promise.all(variants)
      .then(function (responses) {
        let matched = false;
        for (let i = 0; i < responses.length; i++) {
          let rsp = responses[i];
          if (rsp.skus.length > 0) {
            const matchSKU = _.filter(rsp.skus, item => item !== sku);
            if (matchSKU.length > 0) {
              matched = true;
              break;
            }
          }
        }

        if (matched) {
          setError(true);
          setErrorMsg("Duplicated Variant");
        } else {
          uploadImages();
        }
      });
  }

  const checkProductSKU = (_product) => {

    console.log('Check SKU: ', _product);
    if (_product.sku !== '') {
      let msg = 'Duplicate SKU';
      setError(true);
      setErrorMsg(msg);
    } else {
      checkProduct();
    }
  }

  const checkProduct = () => {
    let valid = product.isBundle ? checkBundleInfo() : checkProductInfo();
    if (valid === false)
      return;

    if (!product.variants || product.variants.length == 0) {
      return uploadImages();
    }

    checkVariantSKU();
  }

  const uploadImages = () => {
    const promises = [];

    if (!!frontImg) {
      promises.push(handleUploadArt(frontImg, 'front'));
    }

    if (!!backImg) {
      promises.push(handleUploadArt(backImg, 'back'));
    }

    if (!!hyperOpticIdenticalImage) {
      promises.push(handleUploadArt(hyperOpticIdenticalImage, 'hyperOpticIdentical'));
      console.log('Hyper Optic Identical Image:', hyperOpticIdenticalImage);
    }

    if (!!hyperOpticLeftImage) {
      promises.push(handleUploadArt(hyperOpticLeftImage, 'hyperOpticLeft'));
    }

    if (!!hyperOpticRightImage) {
      promises.push(handleUploadArt(hyperOpticRightImage, 'hyperOpticRight'));
    }

    if (!!isBoxedImage) {
      promises.push(handleUploadArt(isBoxedImage, 'boxedImage'));
    }


    if (promises.length > 0) {
      Promise.all(promises)
        .then(() => {
          console.log('All Arts Uploaded!');
          processSave();
        })
        .catch(err => console.log(err));
    } else {
      processSave();
    }
  }

  const processSave = async () => {
    let _product = { ...product };
    if (!!frontImg && frontPath) {
      _product.frontArt = {};
      _product.pathFront = frontPath;
    }

    if (!!backImg && backPath) {
      _product.backArt = {};
      _product.pathBack = backPath;
    }

    if (!!hyperOpticIdenticalImage && hyperOpticIdenticalPath) {
      _product.hyperOpticIdenticalArt = {};
      _product.pathHyperOpticIdentical = hyperOpticIdenticalPath;
    }

    if (!!hyperOpticLeftImage && hyperOpticLeftPath) {
      _product.leftArt = {};
      _product.pathLeft = hyperOpticLeftPath;
    }

    if (!!hyperOpticRightImage && hyperOpticRightPath) {
      _product.rightArt = {};
      _product.pathRight = hyperOpticRightPath;
    }

    if (!!isBoxedImage && isBoxedImagePath) {
      _product.boxedImage = {};
      _product.isBoxedImagePath = isBoxedImagePath;
    }

    if (isCreate || isDuplicate) {

      // get hieght width of image here: hyperOpticIdenticalImage
      if (hyperOpticIdenticalImage) {
        const imageData = await getDimenssionsAndSize(hyperOpticIdenticalImage, cropSettings)
        _product.hyperOpticIdenticalArt = imageData
        _product.identicalHyperOptic = imageData;
      }

      if (hyperOpticLeftImage) {
        const imageData = await getDimenssionsAndSize(hyperOpticLeftImage, cropSettings)
        _product.leftArt = imageData
      }

      if (hyperOpticRightImage) {
        const imageData = await getDimenssionsAndSize(hyperOpticRightImage, cropSettings)
        _product.rightArt = imageData
      }

      if (isBoxedImage) {
        const imageData = await getDimenssionsAndSize(isBoxedImage, cropSettings)
        _product.isBoxedImageArt = imageData
      }

      createProduct(db, _product);
      //setProduct({...DEFAULT_STATE});
    } else {
      await updateProduct(db, _product, sku);
    }

    if (dontCloseFullModal) {
      handleClose();
    }

    console.log('Product Saved!');
  }

  const handleSave = async () => {


    console.log("SAVING THIS PRODUCT: ", product);

    let needCheck = false;

    if (isCreate || isDuplicate) {
      needCheck = true;
    } else if (sku !== product.sku) {
      console.log('Update Action');
      console.log('Original SKU: ', sku);
      console.log('New SKU: ', product.sku);
      needCheck = true;
    }

    if (!needCheck) {
      console.log('No need to check SKU');
      return checkProduct();
    }

    getProductVariants(db, product.sku)
      .then(rsp => {
        if (rsp.skus.length > 0) {
          let msg = 'Duplicate SKU';
          setError(true);
          setErrorMsg(msg);
        } else {
          console.log("PROD . SKU: ", product.sku);
          getProductBySku(db, product.sku, checkProductSKU);
        }
        console.log('Response:', rsp);
      });
  }

  const handleCloseDialog = (event) => {
    setDontShowFullModal(true);
    handleClose();
  }

  const handleAddTemplateForAdding = (event) => {
    const newItem = { template: templates[0].id };

    const _product = { ...product };
    const _templates = [..._product.templates];
    _templates.push(newItem);
    _product.templates = _templates;
    _product.isCustomProduct = _templates.length > 0;
    setProduct(_product);
  }

  const handleChangeTemplate = (event, index) => {
    let { name, value } = event.target;
    let _product = { ...product };
    let _templates = [..._product.templates];

    if (value === "") {
      // Remove the template at the specified index if the value is an empty string
      _templates.splice(index, 1);
    } else {
      // Check if the template exists at the index; if not, create a new object
      if (!_templates[index]) {
        _templates[index] = {}; // Initialize a new object if it doesn't exist
      }
      // Set the 'template' property of the object at the specified index
      _templates[index].template = value;
    }

    _product.templates = _templates;

    // Update 'isCustomProduct' based on whether there are any templates
    _product.isCustomProduct = _templates.length > 0;

    // Update the product state
    setProduct(_product);
  }

  // !

  const handleChangeVariant = (event, adjustedIndex) => {
    let value = event.target.value;
    let _product = { ...product };
    let _variants = [..._product.variants];

    // Use the adjusted index to update the correct variant
    _variants[adjustedIndex] = value;

    _product.variants = _variants;
    setProduct(_product);
  };


  const handleChangeVariantSingle = (event, originalIndex) => {
    let value = event.target.value;
    let _product = { ...product };

    // Counter to track the index of string variants
    let stringVariantIndex = -1;

    // Update the variant in the original array based on the string variant index
    _product.variants = _product.variants.map(variant => {
      if (typeof variant === 'string') {
        stringVariantIndex++; // Increment for each string variant
        if (stringVariantIndex === originalIndex) {
          return value; // Update the variant at the correct index
        }
      }
      return variant; // Return the variant unchanged if not a string or not the target index
    });

    setProduct(_product);
  };


  const handleDeleteVariant = (event, index) => {
    const _product = { ...product };
    const _variants = [..._product.variants];
    _variants.splice(index, 1);
    _product.variants = _variants;
    setProduct(_product);
  }

  const handleDeleteSingleVariant = (index) => {
    let _product = { ...product };
    // Filter to get only string variants
    let stringVariants = _product.variants.filter(variant => typeof variant === 'string');

    // Ensure the index is within the bounds of the string variants
    if (index >= 0 && index < stringVariants.length) {
      // Find the actual index of the string variant in the original array
      let actualIndex = _product.variants.findIndex(variant => variant === stringVariants[index]);

      // Validate the actual index before deletion
      if (actualIndex !== -1) {
        _product.variants.splice(actualIndex, 1);
        setProduct(_product);
      }
    }
  }


  const handleDeleteChildVariant = (index) => {
    let _product = { ...product };
    let childVariants = _product.variants.filter(variant => typeof variant !== 'string');

    // Check if the index is within bounds for child variants
    if (index >= 0 && index < childVariants.length) {
      // Find the actual index in the original array
      let actualIndex = _product.variants.findIndex(variant => variant === childVariants[index]);

      // Ensure the actual index is valid before deletion
      if (actualIndex !== -1) {
        _product.variants.splice(actualIndex, 1);
        setProduct(_product);
      }
    }
  }


  const handleDeleteVendorAttribute = (index) => {
    const _product = { ...product };
    const _vendors = [..._product.vendors];
    _vendors.splice(index, 1);
    _product.vendors = _vendors;
    setProduct(_product);
  }


  const handleChange = (event, justUpdating) => {

    let { name, value, checked, type } = event.target;

    value = type === "checkbox" ? checked : value;

    if (name === 'type') {
      setProduct({ ...product, [name]: value, style: '', format: '', size: '' }); // Reset Style, Format, Size on Type change
    } else if (name === 'style') {

      setProduct({ ...product, [name]: value, format: '', size: '' }); // Reset Format, Size on Style change

      setDidSelectHyperOptic(value === 'HyperOptic');

      const isKnitted = value === 'Knitted';

      const isCutAndSew = value.toLowerCase().trim() === 'cut & sew'; // Ensure your input matches this exactly

      setDidSelectBoxedImage((value.toLowerCase().trim() == 'packaging') || isKnitted || isCutAndSew);

      resetStateForHyperOptic(); // Reset Hyper Optic Images and state for them

    } else {
      setProduct({ ...product, [name]: value });
    }
  };

  useEffect(() => {
    // Initialize updatedSizes without the default options
    let updatedSizes = props.sizes.map((item, index) => ({
      id: item.id,
      name: item.name,
      val: item.id,
      sku: item.sku,

    }));

    // initialize Formats
    let updatedFormats = props.formats.map((item, index) => ({
      id: item.id,
      name: item.name,
      val: item.id,
      sku: item.sku
    }));

    const selectedStyle = product.style;
    const selectedType = product.type;
    // when socks, allow all sizes minus 2XL
    if (selectedType === 'Socks' && selectedStyle === 'Graphic') {
      // Apply specific filtering logic here
      updatedSizes = updatedSizes.filter(size => ['L/XL', 'S/M', 'YTHL', 'Youth L', 'Youth M', "Toddler 2-4Y", "Toddler 1-2Y", "Baby 0-12M "].includes(size.name));
      // Filter out only the formats you need
      let filteredFormats = updatedFormats.filter(format =>
        ['Crew', 'Quarter', 'Low'].includes(format.name)
      );

      const customOrderSize = ['L/XL', 'S/M', 'YTHL', 'Youth L', 'Youth M', "Toddler 2-4Y", "Toddler 1-2Y", "Baby 0-12M "];

      // Sort the array based on the custom order
      updatedSizes.sort((a, b) => customOrderSize.indexOf(a.name) - customOrderSize.indexOf(b.name));

      updatedFormats = filteredFormats;

      // Define a custom order
      const customOrder = ['Crew', 'Quarter', 'Low'];

      // Sort the array based on the custom order
      filteredFormats.sort((a, b) => customOrder.indexOf(a.name) - customOrder.indexOf(b.name));

      updatedFormats = filteredFormats;

    }

    if (selectedType === 'Socks' && selectedStyle === 'HyperOptic') {
      updatedFormats = updatedFormats.filter(format => ['Crew'].includes(format.name));
      updatedSizes = updatedSizes.filter(size => ['L/XL', 'S/M', 'Youth L'].includes(size.name));
    }

    if (selectedStyle === 'Knitted' && selectedType === 'Socks') {
      // include Kee high, when ready
      updatedFormats = updatedFormats.filter(format => ['Crew', 'Quarter', 'Low'].includes(format.name));
      updatedSizes = updatedSizes.filter(size => ['2XL', 'L/XL', 'S/M', 'Youth L'].includes(size.name));

      // Filter out only the formats you need
      let filteredFormats = updatedFormats.filter(format =>
        ['Crew', 'Quarter', 'Low'].includes(format.name)
      );

      // Define a custom order
      const customOrder = ['Crew', 'Quarter', 'Low'];

      // Sort the array based on the custom order
      filteredFormats.sort((a, b) => customOrder.indexOf(a.name) - customOrder.indexOf(b.name));

      updatedFormats = filteredFormats;
    }

    if (selectedType === 'Underwear') {
      // First, update the format names if necessary
      updatedFormats = updatedFormats.map(format => {
        if (format.name === 'Boxer') {
          return { ...format, name: "Men's Boxer Briefs" }; // Change name to "Men's Boxer Briefs"
        }
        return format;
      });

      // Then, filter formats as before (now with the updated name)
      updatedFormats = updatedFormats.filter(format => [`Men's Boxer Briefs`].includes(format.name));

      // Filter sizes as before
      updatedSizes = updatedSizes.filter(size => ['2XL', 'XL', 'L', 'M', 'S'].includes(size.name));
    }


    if (selectedType === "Add-On") {
      updatedFormats = updatedFormats.filter(format => [`Greeting Card`, `Gift Box`, 'NFL 8-Bit Box', 'VHS Box', 'Sticker', 'Coffin Box', 'Suitcase'].includes(format.name));
      updatedSizes = updatedSizes.filter(size => ['O/S'].includes(size.name));
    }

    setSizes(updatedSizes);
    setFormats(updatedFormats);

  }, [product]); // Depend on props.sizes and context values




  const handleChangeVendorAttributes = (e, index) => {
    let { name, value } = e.target;
    console.log(`Index:${index}, Name:'${name}', Value:'${value}'`);

    const vendors = [...product.vendors];
    if (name === "template" && value === "") {
      vendors.splice(index, 1);
    } else {
      vendors[index][name] = value;
    }

    setProduct({ ...product, vendors });
  }

  const handleDropFront = (file) => {

    if (Array.isArray(file)) {
      file = file[0];
    }

    const isFrontOrBack = checkIfItsFrontOrBack(file.name, 'front');

    if (!isFrontOrBack) {
      alert("Invalid file! Please upload a front image.");
      return;
    }

    existProductArt(storage, file.name)
      .then(exist => {
        console.log(`Front Art '${file.name}' exist:${exist}`);
        let filename = file.name;
        if (exist && filename !== product.pathBack) {
          const _filename = filename.split('.').slice(0, -1).join('.');
          const ext = filename.substr(filename.lastIndexOf('.') + 1);
          filename = `${_filename}-${Date.now()}.${ext}`;
        }
        setFrontImg(file);
        setFrontPath(filename);
      });
  }


  const checkIfIdenticalOrFraternal = async (file) => {
    if (Array.isArray(file)) {
      file = file[0];
    }
    const fileName = file.name.toLowerCase();
    const isLeft = fileName.includes('left');
    const isRight = fileName.includes('right');

    console.log(
      `isLeft: ${isLeft}, isRight: ${isRight}`
    )

    if (isLeft || isRight) {
      setIsHyperOpticFraternal(true);
      return {
        type: 'fraternal',
        side: isLeft ? 'left' : 'right'
      };
    }

    try {
      const identicalSetting = await checkImageDimensions(file, cropSettings);
      if (!identicalSetting) {
        console.log('No matching crop setting found');
        return {
          type: 'notHyperOptic',
        };
      }
      return {
        type: 'identical',
      };
    } catch (err) {
      console.log('Error:', err);
      return {
        type: 'notHyperOptic',
      };
    }
  };

  const alertIncorrectSide = (side) => {
    alert(`Please upload the ${side} image to the ${side} side.`);
  };


  const handleDropHyperOptic = async (file, droppedSide = 'any') => {
    // Await the resolution of the promise from checkIfIdenticalOrFraternal
    const hyperOpticFormat = await checkIfIdenticalOrFraternal(file);

    const exist = await existProductArt(storage, file.name);

    let filename = file.name;

    if (exist && filename !== product.pathBack) {
      const _filename = filename.split('.').slice(0, -1).join('.');
      const ext = filename.substr(filename.lastIndexOf('.') + 1);
      filename = `${_filename}-${Date.now()}.${ext}`;
    }

    switch (hyperOpticFormat.type) {
      case 'identical':
        if (Array.isArray(file)) {
          file = file[0];
        }
        existProductArt(storage, file.name)
          .then(exist => {
            console.log(`IDENTICAL Art '${file.name}' exist:${exist}`);
            let filename = file.name;
            if (exist && filename !== product.pathBack) {
              const _filename = filename.split('.').slice(0, -1).join('.');
              const ext = filename.substr(filename.lastIndexOf('.') + 1);
              filename = `${_filename}-${Date.now()}.${ext}`;
            }
            setHyperOpticIdenticalImage(file);
            setHyperOpticIdenticalPath(filename);
          });
        setHyperOpticLeftImage(null);
        setHyperOpticRightImage(null);
        setHyperOpticLeftPath("");
        setHyperOpticRightPath("");
        setIsHyperOpticFraternal(false);
        break;
      case 'fraternal':
        const side = hyperOpticFormat.side === 'left' ? 'left' : 'right';
        console.log(`Setting Hyper Optic ${side} Image`);
        if (side === 'left') {
          if (droppedSide !== 'left' && droppedSide !== 'any') {
            alertIncorrectSide(droppedSide);
            return;
          }
          existProductArt(storage, file.name)
            .then(exist => {
              console.log(`IDENTICAL Art '${file.name}' exist:${exist}`);
              let filename = file.name;
              if (exist && filename !== product.pathBack) {
                const _filename = filename.split('.').slice(0, -1).join('.');
                const ext = filename.substr(filename.lastIndexOf('.') + 1);
                filename = `${_filename}-${Date.now()}.${ext}`;
              }
              setHyperOpticLeftImage(file);
              setHyperOpticLeftPath(filename);
            });
          setHyperOpticIdenticalImage(null);
          setHyperOpticIdenticalPath("");
        } else if (side === 'right') {
          if (droppedSide !== 'right' && droppedSide !== 'any') {
            alertIncorrectSide(droppedSide);
            return;
          }
          existProductArt(storage, file.name)
            .then(exist => {
              console.log(`IDENTICAL Art '${file.name}' exist:${exist}`);
              let filename = file.name;
              if (exist && filename !== product.pathBack) {
                const _filename = filename.split('.').slice(0, -1).join('.');
                const ext = filename.substr(filename.lastIndexOf('.') + 1);
                filename = `${_filename}-${Date.now()}.${ext}`;
              }
              setHyperOpticRightImage(file);
              setHyperOpticRightPath(filename);
            });
        }
        break;
      case 'notHyperOptic':
        alert('This file is not a Hyper Optic file. Please upload a Hyper Optic file.');
        break;
      default:
        // Handle unexpected cases
        alert('Unexpected case. Could not determine Hyper Optic format. Please contact support.');
    }
  };


  const handleDropBack = (file) => {

    if (Array.isArray(file)) {
      file = file[0];
    }

    const isFrontOrBack = checkIfItsFrontOrBack(file.name, 'back');

    if (!isFrontOrBack) {
      alert("Invalid file! Please upload a front image.");
      return;
    }

    existProductArt(storage, file.name)
      .then(exist => {
        console.log(`Back Art '${file.name}' exist:${exist}`);
        let filename = file.name;
        if (exist && filename !== product.pathBack) {
          const _filename = filename.split('.').slice(0, -1).join('.');
          const ext = filename.substr(filename.lastIndexOf('.') + 1);
          filename = `${_filename}-${Date.now()}.${ext}`;
        }
        setBackImg(file);
        setBackPath(filename);
      });
  }


  const handleDropBoxedImage = (file) => {
    if (Array.isArray(file)) {
      file = file[0];
    }

    existProductArt(storage, file.name)
      .then(exist => {
        let filename = file.name;
        if (exist && filename !== product.isBoxedImagePath) {
          const _filename = filename.split('.').slice(0, -1).join('.');
          const ext = filename.substr(filename.lastIndexOf('.') + 1);
          filename = `${_filename}-${Date.now()}.${ext}`;
        }
        setIsBoxedImage(file);
        setIsBoxedImagePath(filename);
      });
  }


  const handleUploadArt = (img, side) => {

    let path, setProgress;
    switch (side) {
      case 'front':
        path = `${PRODUCT_ART_PREFIX}${frontPath}`;
        setProgress = setFrontProgress;
        break;
      case 'back':
        path = `${PRODUCT_ART_PREFIX}${backPath}`;
        setProgress = setBackProgress;
        break;
      case 'hyperOpticIdentical':
        path = `${PRODUCT_ART_PREFIX}${hyperOpticIdenticalPath}`;
        setProgress = setFrontProgress;
        break;
      case 'hyperOpticLeft':
        path = `${PRODUCT_ART_PREFIX}${hyperOpticLeftPath}`;
        setProgress = setFrontProgress;
        break;
      case 'hyperOpticRight':
        path = `${PRODUCT_ART_PREFIX}${hyperOpticRightPath}`;
        setProgress = setBackProgress;
        break;
      case 'boxedImage':
        path = `${PRODUCT_ART_PREFIX}${isBoxedImagePath}`;
        setProgress = setBackProgress;
        break;
      default:
        return Promise.reject(new Error("Unknown side"));
    }

    return new Promise((resolve, reject) => {
      uploadToStorage(storage, path, img, setProgress)
        .then(() => {
          resolve();
        }).catch(reject);
    });
  };


  const handleDownload = (event, filename) => {

    storage.ref().child(`${PRODUCT_ART_PREFIX}${filename}`).getDownloadURL()
      .then((url) => {
        // `url` is the download URL for 'images/stars.jpg'

        // This can be downloaded directly:
        var xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = (event) => {
          var blob = xhr.response;
        };
        xhr.open('GET', url);
        xhr.send();
      })
      .catch((error) => {
        // Handle any errors
      });
  }

  const handleClickUploadFront = () => {
    console.log('handleClickUploadFront');
    dropzoneRef1.current.open();
  }

  const handleClickUpHyperOptic = () => {
    console.log('handleClickUpHyperOptic');
    dropZoneHyperOptic.current.open();
  }

  const handleClickUploadBack = () => {
    console.log('handleClickUploadBack');
    dropzoneRef2.current.open();
  }

  const renderMockup = (isCrew) => {
    // don't show mockup for knitted socks
    if (product.style === 'Knitted') {
      return
    }

    if (product.style.toLowerCase().trim() == 'packaging') {
      return
    }

    if (product.toddlerSplitFaceMockup || product.toddlerInlineMockup) { /// 'TDLR24
      return (
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <InputLabel
              sx={{ fontWeight: 'bold', color: '#000000' }}>Front</InputLabel>
            <Box sx={{ marginTop: '10px', width: '100%' }}>
              <MockupImage
                name="product"
                key="mockup"
                reverse={false}
                firebase={props.firebase.getFirebase()}
                classes={classes}
                path={`${product.toddlerSplitFaceMockup}`}
              />
            </Box>
          </Grid>
          {isCrew && (
            <Grid item xs={12} sm={6}>
              <InputLabel
                sx={{ fontWeight: 'bold', color: '#000000' }}>Front/Back</InputLabel>
              <Box sx={{ marginTop: '10px', width: '100%' }}>
                <MockupImage
                  name="product"
                  key="mockup"
                  reverse={false}
                  firebase={props.firebase.getFirebase()}
                  classes={classes}
                  path={product.toddlerInlineMockup}
                />
              </Box>
            </Grid>
          )}
        </Grid>
      );
    }


    if (product.style === 'HyperOptic') {
      return (
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <InputLabel sx={{
              fontWeight: 'bold',
              color: '#000000'
            }}>Side</InputLabel>
            <Box sx={{ marginTop: '10px', width: '100%' }}>
              <MockupImage
                name="product"
                key="mockup"
                reverse
                firebase={props.firebase.getFirebase()}
                classes={classes}
                path={`${product.hyperopticPath}`}
                reversePath={`r_${product.hyperopticPath}`}
              />
            </Box>
          </Grid>
          {isCrew && (
            <Grid item xs={12} sm={6}>
              <InputLabel sx={{ fontWeight: 'bold', color: '#000000' }}>Separated</InputLabel>
              <Box sx={{ marginTop: '10px', width: '100%' }}>
                <MockupImage
                  name="product"
                  key="mockup"
                  reverse
                  firebase={props.firebase.getFirebase()}
                  classes={classes}
                  reversePath={`r_${product.hyperopticStandardPath}`}
                  path={product.hyperopticStandardPath}
                />
              </Box>
            </Grid>
          )}
        </Grid>
      );
    }

    return (
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          {product.type !== "T-Shirt" && (
            <>
              <InputLabel
                sx={{ fontWeight: 'bold', color: '#000000' }}>Separated</InputLabel>
              <Box sx={{ marginTop: '10px', width: '100%' }}>
                <MockupImage
                  name="product"
                  key="mockup"
                  reverse={true}
                  firebase={props.firebase.getFirebase()}
                  classes={classes}
                  path={`${product.pathFront}`}
                  reversePath={`r_${product.pathFront}`}
                />
              </Box>
            </>
          )
          }
        </Grid>
        {isCrew && (
          <Grid item xs={12} sm={6}>
            <InputLabel
              sx={{ fontWeight: 'bold', color: '#000000' }}>Split</InputLabel>
            <Box sx={{ marginTop: '10px', width: '100%' }}>
              <MockupImage
                name="product"
                key="mockup"
                reverse={product.style === 'HyperOptic'}
                firebase={props.firebase.getFirebase()}
                classes={classes}
                path={`s_${product.pathFront}`}
              />
            </Box>
          </Grid>
        )}
      </Grid>
    );
  };


  const renderMockupBoxer = () => {
    return (
      <Box sx={{ width: '100%' }}>
        <UnderwearMockups firebase={props.firebase.getFirebase()}
          product={product} />
      </Box>
    );
  }

  // For this function, add a new condition for Hyper Optic socks.
  const renderArts = () => {

    if (didSelectHyperOptic) return renderHyperOpticArt();

    if (didSelectBoxedImage) return renderBoxedImage();

    if (product.identical) return renderSoloArts();

    return renderNormalArts();

  }

  const renderBoxedImage = () => {

    return (
      <>
        <Grid sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '20%',
          height: '50%'
        }}>
          {
            isBoxedImage ? (
              <FileArt
                name="isBoxedImage"
                key="isBoxedImage"
                classes={classes}
                file={isBoxedImage}
                progress={frontProgress}
              />
            ) : (
              <ProductArt
                name="soloIdenticalImage"
                key="soloIdenticalImage"
                firebase={props.firebase.getFirebase()}
                classes={classes}
                progress={frontProgress}
                path={product.isBoxedImagePath}
              />
            )
          }
        </Grid>

        <Grid sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '20%',
          height: '50%'
        }}>
          <Dropzone
            ref={dropZoneHyperOptic}
            onDrop={(files) => handleDropBoxedImage(files[0])}
            multiple={false}>
            {({ getRootProps, getInputProps }) => (
              <Box {...getRootProps()} className={classes.dropBox}
                sx={{
                  padding: '20px',
                }}
              >
                <input {...getInputProps()} accept="image/png" />
                <CloudUploadIcon />
                <p className={classes.dropLabel}>Drag & Drop Artwork File</p>
              </Box>
            )}
          </Dropzone>

          <Button
            variant="black"
            onClick={handleClickUpHyperOptic}
          >Upload</Button>
        </Grid>
      </>
    )
  }


  const renderIndenticalHypOpt = () => {
    return (

      <>
        <Grid item xs={4}>
          {
            hyperOpticIdenticalImage ? (
              <FileArt
                name="soloIdenticalImage"
                key="soloIdenticalImage"
                classes={classes}
                file={hyperOpticIdenticalImage}
                progress={frontProgress}
                setDimension={setFrontDimension}
              />
            ) : (
              <ProductArt
                name="soloIdenticalImage"
                key="soloIdenticalImage"
                firebase={props.firebase.getFirebase()}
                classes={classes}
                progress={frontProgress}
                path={product.pathFront}
              />
            )
          }
        </Grid>

        <Grid item xs={4}>
          <Dropzone
            ref={dropZoneHyperOptic}
            onDrop={(files) => handleDropHyperOptic(files[0])}
            multiple={false}>
            {({ getRootProps, getInputProps }) => (
              <Box {...getRootProps()} className={classes.dropBox}>
                <input {...getInputProps()} accept="image/png" />
                <CloudUploadIcon />
                <p className={classes.dropLabel}>Drag & Drop Artwork File</p>
              </Box>
            )}
          </Dropzone>

          <Button
            variant="black"
            onClick={handleClickUpHyperOptic}
          >Upload</Button>
        </Grid>
      </>

    )
  }

  const renderFraternal = () => {
    return (
      <>
        <Grid item xs={5}>
          <InputLabel>Right Art</InputLabel>
          <Box sx={{ marginTop: '16px', width: '100%' }}>
            <Grid container spacing={3}>
              <Grid item xs={8}>
                {
                  hyperOpticRightImage ? (
                    <FileArt
                      name="soloRightImage"
                      key="soloRightImage"
                      classes={classes}
                      file={hyperOpticRightImage}
                      progress={frontProgress}
                      setDimension={setFrontDimension}
                    />
                  ) : (
                    <ProductArt
                      name="soloRightImage"
                      key="soloRightImage"
                      firebase={props.firebase.getFirebase()}
                      classes={classes}
                      progress={frontProgress}
                      path={product.pathFront}
                    />
                  )
                }
              </Grid>

              <Grid item xs={4}>
                <Dropzone
                  ref={dropZoneHyperOptic}
                  onDrop={(files) => handleDropHyperOptic(files[0], 'right')}
                  multiple={false}>
                  {({ getRootProps, getInputProps }) => (
                    <Box {...getRootProps()} className={classes.dropBox}>
                      <input {...getInputProps()} accept="image/png" />
                      <CloudUploadIcon />
                      <p className={classes.dropLabel}>Drag & Drop Artwork
                        File</p>
                    </Box>
                  )}
                </Dropzone>

                <Button
                  variant="black"
                  onClick={handleClickUpHyperOptic}
                >Upload</Button>
              </Grid>

            </Grid>
          </Box>

        </Grid>

        <Grid item xs={1}></Grid>

        <Grid item xs={5}>
          <InputLabel>Left Art</InputLabel>
          <Box sx={{ marginTop: '16px', width: '100%' }}>
            <Grid container spacing={3}>
              <Grid item xs={8}>
                {
                  hyperOpticLeftImage ? (
                    <FileArt
                      name="soloLeftImage"
                      key="soloLeftImage"
                      classes={classes}
                      file={hyperOpticLeftImage}
                      progress={frontProgress}
                      setDimension={setFrontDimension}
                    />
                  ) : (
                    <ProductArt
                      name="soloLeftImage"
                      key="soloLeftImage"
                      firebase={props.firebase.getFirebase()}
                      classes={classes}
                      progress={frontProgress}
                      path={product.pathFront}
                    />
                  )
                }
              </Grid>

              <Grid item xs={4}>
                <Dropzone
                  ref={dropzoneRef2}
                  onDrop={(files) => handleDropHyperOptic(files[0], 'left')}
                  multiple={false}>
                  {({ getRootProps, getInputProps }) => (
                    <Box {...getRootProps()} className={classes.dropBox}>
                      <input {...getInputProps()} accept="image/png" />
                      <CloudUploadIcon />
                      <p className={classes.dropLabel}>Drag & Drop Artwork
                        File</p>
                    </Box>
                  )}
                </Dropzone>

                <Button
                  variant="black"
                  onClick={handleClickUploadBack}
                >Upload</Button>
              </Grid>

            </Grid>
          </Box>

        </Grid>
      </>
    )
  }

  const renderHyperOpticArt = () => {
    return (
      <Box sx={{ width: '100%' }}>
        <Grid container spacing={3}>
          {
            isHyperOpticFraternal ? (
              renderFraternal()
            ) : (
              renderIndenticalHypOpt()
            )
          }
        </Grid>
      </Box>
    )
  }


  const renderSoloArts = () => {
    return (
      <Box sx={{ width: '100%' }}>
        <Grid container spacing={3}>
          <Grid item xs={5}>
            <InputLabel>Front Art</InputLabel>
            <Box sx={{ marginTop: '16px', width: '100%' }}>
              <Grid container spacing={3}>
                <Grid item xs={8}>
                  {
                    frontImg ? (
                      <FileArt
                        name="solofrontImage"
                        key="solofrontImage"
                        classes={classes}
                        file={frontImg}
                        progress={frontProgress}
                        setDimension={setFrontDimension}
                      />
                    ) : (
                      <ProductArt
                        name="solofrontImage"
                        key="solofrontImage"
                        firebase={props.firebase.getFirebase()}
                        classes={classes}
                        progress={frontProgress}
                        path={product.pathFront}
                      />
                    )
                  }
                </Grid>

                {strPtSize === "LXL" && (
                  <Grid item xs={4}>
                    <Dropzone
                      ref={dropzoneRef1}
                      onDrop={(files) => handleDropFront(files[0])}
                      multiple={false}>
                      {({ getRootProps, getInputProps }) => (
                        <Box {...getRootProps()} className={classes.dropBox}>
                          <input {...getInputProps()} accept="image/png" />
                          <CloudUploadIcon />
                          <p className={classes.dropLabel}>Drag & Drop Artwork
                            File</p>
                        </Box>
                      )}
                    </Dropzone>

                    <Button
                      variant="black"
                      onClick={handleClickUploadFront}
                    >Upload</Button>
                  </Grid>
                )}

              </Grid>
            </Box>

            {/*product.pathFront && (
              <Box sx={{marginTop: '15px'}}>
                <Link href="#" onClick={event => handleDownload(event, product.pathFront)} className={classes.link}>Download</Link>
              </Box>
            )*/}

          </Grid>
          <Grid item xs={1}></Grid>

          <Grid item xs={5}>
            <InputLabel>Back Art</InputLabel>
            <Box sx={{ marginTop: '16px', width: '100%' }}>
              <Grid container spacing={3}>
                <Grid item xs={8}>
                  {
                    backImg ? (
                      <FileArt
                        name="solobackImage"
                        key="solobackImage"
                        classes={classes}
                        file={backImg}
                        progress={backProgress}
                        setDimension={setBackDimension}
                      />
                    ) : (
                      <ProductArt
                        name="solobackImage"
                        key="solobackImage"
                        firebase={props.firebase.getFirebase()}
                        classes={classes}
                        progress={backProgress}
                        path={product.pathBack}
                      />
                    )
                  }
                </Grid>

                {strPtSize === "LXL" && (
                  <Grid item xs={4}>
                    <Dropzone
                      ref={dropzoneRef2}
                      onDrop={(files) => handleDropBack(files[0])}
                      multiple={false}>
                      {({ getRootProps, getInputProps }) => (
                        <Box {...getRootProps()} className={classes.dropBox}>
                          <input {...getInputProps()} accept="image/png" />
                          <CloudUploadIcon />
                          <p className={classes.dropLabel}>Drag & Drop Artwork
                            File</p>
                        </Box>
                      )}
                    </Dropzone>

                    <Button
                      variant="black"
                      onClick={handleClickUploadBack}
                    >Upload</Button>
                  </Grid>
                )}

              </Grid>
            </Box>

            {/*product.pathBack && (
              <Box sx={{marginTop: '15px'}}>
                <Link href="#" onClick={event => handleDownload(event, product.pathBack)} className={classes.link}>Download</Link>
              </Box>
            )*/}

          </Grid>
        </Grid>

        <Grid container spacing={3} sx={{ marginTop: '20px' }}>
          <Grid item xs={5}>
            <InputLabel>Full Front Art</InputLabel>
            <Box sx={{ marginTop: '16px', width: '100%' }}>
              <Grid container spacing={3}>
                <Grid item xs={8}>
                  <SoloArt
                    name="soloPathFront"
                    cropSettings={cropSettings}
                    isFront={true}
                    firebase={props.firebase.getFirebase()}
                    classes={classes}
                    file={frontImg}
                    path={product.pathFront}
                  />
                </Grid>
                <Grid item xs={4}>

                </Grid>
              </Grid>
            </Box>

            {/*product.pathFront && (
              <Box sx={{marginTop: '15px'}}>
                <Link href="#" onClick={event => handleDownload(event, product.pathFront)} className={classes.link}>Download</Link>
              </Box>
            )*/}

          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={5}>
            <InputLabel>Full Back Art</InputLabel>
            <Box sx={{ marginTop: '16px', width: '100%' }}>
              <Grid container spacing={3}>
                <Grid item xs={8}>
                  <SoloArt
                    name="soloPathBack"
                    cropSettings={cropSettings}
                    isFront={false}
                    firebase={props.firebase.getFirebase()}
                    classes={classes}
                    file={backImg}
                    path={product.pathBack}
                  />
                </Grid>
                <Grid item xs={4}>

                </Grid>
              </Grid>
            </Box>

            {/*product.pathBack && (
              <Box sx={{marginTop: '15px'}}>
                <Link href="#" onClick={event => handleDownload(event, product.pathBack)} className={classes.link}>Download</Link>
              </Box>
            )*/}

          </Grid>
        </Grid>
      </Box>
    );
  }

  const renderNormalArts = () => {
    if (isMask(product.type))
      return (
        <Box sx={{ width: '100%' }}>
          <Grid container spacing={3}>
            <Grid item xs={5}>
              <InputLabel>Art</InputLabel>
              <Box sx={{ marginTop: '16px', width: '100%' }}>
                <Grid container spacing={3}>
                  <Grid item xs={8}>
                    {
                      backImg ? (
                        <FileArt
                          name="maskImage"
                          key="maskImage"
                          classes={classes}
                          file={backImg}
                          progress={backProgress}
                          setDimension={setBackDimension}
                        />
                      ) : (
                        <ProductArt
                          key="maskImage"
                          name="maskImage"
                          firebase={props.firebase.getFirebase()}
                          classes={classes}
                          progress={backProgress}
                          path={product.pathBack}
                        />
                      )
                    }
                  </Grid>
                  <Grid item xs={4}>
                    <Dropzone
                      ref={dropzoneRef2}
                      onDrop={(files) => handleDropBack(files[0])}
                      multiple={false}>
                      {({ getRootProps, getInputProps }) => (
                        <Box {...getRootProps()} className={classes.dropBox}>
                          <input {...getInputProps()} accept="image/png" />
                          <CloudUploadIcon />
                          <p className={classes.dropLabel}>Drag & Drop Artwork
                            File</p>
                        </Box>
                      )}
                    </Dropzone>

                    <Button
                      variant="black"
                      onClick={handleClickUploadBack}>Upload</Button>
                  </Grid>
                </Grid>
              </Box>

              {/*product.pathBack && (
              <Box sx={{marginTop: '15px'}}>
                <Link href="#" onClick={event => handleDownload(event, product.pathBack)} className={classes.link}>Download</Link>
              </Box>
            )*/}

            </Grid>
          </Grid>
        </Box>
      );
    return (
      <Box sx={{ width: '100%' }}>
        <Grid container spacing={3}>
          <Grid item xs={5}>
            <InputLabel>Front Art</InputLabel>
            <Box sx={{ marginTop: '16px', width: '100%' }}>
              <Grid container spacing={3}>
                <Grid item xs={8}>
                  {
                    frontImg ? (
                      <FileArt
                        name="frontImage"
                        key="frontImage"
                        classes={classes}
                        file={frontImg}
                        progress={frontProgress}
                        setDimension={setFrontDimension}
                      />
                    ) : (
                      <ProductArt
                        name="frontImage"
                        key="frontImage"
                        firebase={props.firebase.getFirebase()}
                        classes={classes}
                        progress={frontProgress}
                        path={product.pathFront}
                      />
                    )
                  }
                </Grid>
                <Grid item xs={4}>
                  <Dropzone
                    ref={dropzoneRef1}
                    onDrop={(files) => handleDropFront(files[0])}
                    multiple={false}>
                    {({ getRootProps, getInputProps }) => (
                      <Box {...getRootProps()} className={classes.dropBox}>
                        <input {...getInputProps()} accept="image/png" />
                        <CloudUploadIcon />
                        <p className={classes.dropLabel}>Drag & Drop Artwork
                          File</p>
                      </Box>
                    )}
                  </Dropzone>

                  <Button
                    variant="black"
                    onClick={handleClickUploadFront}
                  >Upload</Button>
                </Grid>
              </Grid>
            </Box>

            {/*product.pathFront && (
              <Box sx={{marginTop: '15px'}}>
                <Link href="#" onClick={event => handleDownload(event, product.pathFront)} className={classes.link}>Download</Link>
              </Box>
            )*/}

          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={5}>
            <InputLabel>Back Art</InputLabel>
            <Box sx={{ marginTop: '16px', width: '100%' }}>
              <Grid container spacing={3}>
                <Grid item xs={8}>
                  {
                    backImg ? (
                      <FileArt
                        name="backImage"
                        key="backImage"
                        classes={classes}
                        file={backImg}
                        progress={backProgress}
                        setDimension={setBackDimension}
                      />
                    ) : (
                      <ProductArt
                        name="backImage"
                        key="backImage"
                        firebase={props.firebase.getFirebase()}
                        classes={classes}
                        progress={backProgress}
                        path={product.pathBack}
                      />
                    )
                  }
                </Grid>
                <Grid item xs={4}>
                  <Dropzone
                    ref={dropzoneRef2}
                    onDrop={(files) => handleDropBack(files[0])}
                    multiple={false}>
                    {({ getRootProps, getInputProps }) => (
                      <Box {...getRootProps()} className={classes.dropBox}>
                        <input {...getInputProps()} accept="image/png" />
                        <CloudUploadIcon />
                        <p className={classes.dropLabel}>Drag & Drop Artwork
                          File</p>
                      </Box>
                    )}
                  </Dropzone>

                  <Button
                    variant="black"
                    onClick={handleClickUploadBack}
                  >Upload</Button>
                </Grid>
              </Grid>
            </Box>

            {/*product.pathBack && (
              <Box sx={{marginTop: '15px'}}>
                <Link href="#" onClick={event => handleDownload(event, product.pathBack)} className={classes.link}>Download</Link>
              </Box>
            )*/}

          </Grid>
        </Grid>
      </Box>
    );
  }

  const handleTagsChange = (event) => {
    let { value } = event.target;

    if (value !== 'select') {

      const _tags = [...product.tags];

      if (!_.find(_tags, value)) {
        _tags.push(value);
      }

      let __tags = _.uniq(_tags);
      let _product = { ...product };
      _product.tags = __tags;
      setProduct(_product);
    }
  }

  const handleDeleteTag = (chipToDelete) => () => {

    const _tags = [...product.tags];
    const __tags = _.filter(_tags, tag => tag !== chipToDelete);
    let _product = { ...product };
    _product.tags = __tags;
    setProduct(_product);
  };

  const renderTags = () => {
    const _tags = product.tags;
    return (
      <Box component="ul" className={classes.revampTagList}>
        {_.map(_tags, (tag, key) => {
          return (
            <li key={'tag-' + key}>
              <Box className={classes.revampChip} display="flex"
                alignItems="center">
                <Typography className={classes.chipLabel}>{tag}</Typography>
                <IconButton className={classes.chipButton}
                  onClick={handleDeleteTag(tag)}>
                  <CloseIcon
                    sx={{
                      color: 'white',
                    }}
                  />
                </IconButton>
              </Box>
            </li>
          );
        })}
      </Box>
    );
  }

  const renderTagsForAdding = () => {
    const _tags = product.tags;
    return (
      <Box component="ul" className={classes.tagList}>
        {_.map(_tags, (tag, key) => {
          return (
            <li key={'tag-' + key}>
              <Box className={classes.chip} display="flex" alignItems="center">
                <Typography className={classes.chipLabel}>{tag}</Typography>
                <IconButton className={classes.chipButton}
                  onClick={handleDeleteTag(tag)}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </li>
          );
        })}
      </Box>
    );
  }

  const renderTemplates = () => {
    // Check if there are templates available to choose from and the product has no templates yet
    const showDefaultSelect = templates.length > 0 && product.templates.length === 0;

    return (
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '20%',
        justifyContent: 'center',
      }}>
        {showDefaultSelect ? (
          // Render a default select box when there are no templates in the product
          <Box sx={{ width: '100%' }}>
            <Select
              name="type"
              sx={{ width: '13vw' }}
              value={""} // Default value
              input={<BootstrapInput />}
              onChange={(event) => handleChangeTemplate(event, 0)}
              displayEmpty
            >
              <MenuItem value="">Template</MenuItem>
              {templates.map((item, itemIndex) => (
                <MenuItem key={itemIndex} value={item.id}>{item.name}</MenuItem>
              ))}
            </Select>
          </Box>
        ) : (
          // Render selects for each template in the product
          product.templates.map((template, index) => (
            <Box key={index}
              sx={{ width: '100%', marginTop: index > 0 ? '15px' : '0' }}>
              <Select
                name="type"
                sx={{ width: '100%' }}
                value={template.template || ""}
                input={<BootstrapInput />}
                onChange={(event) => handleChangeTemplate(event, index)}
              >
                <MenuItem value="">Template</MenuItem>
                {templates.map((item, itemIndex) => (
                  <MenuItem key={itemIndex}
                    value={item.id}>{item.name}</MenuItem>
                ))}
              </Select>
            </Box>
          ))
        )}
      </Box>
    );
  };

  const renderTemplatesForAdding = () => {
    return (
      <Box>
        {
          _.map(product.templates, (template, index) => {
            return (
              <Box sx={{ width: '100%', marginTop: '15px' }}>
                <Select
                  name="type"
                  sx={{ width: '100%' }}
                  value={template.template}
                  input={<BootstrapInput />}
                  onChange={event => handleChangeTemplate(event, index)}
                >
                  <MenuItem value="">None</MenuItem>
                  {
                    _.map(templates, item => (
                      <MenuItem value={item.id}>{item.name}</MenuItem>
                    ))
                  }
                </Select>
              </Box>
            );
          })
        }

        <Box sx={{ marginTop: '12px' }}>
          <Button onClick={handleAddTemplateForAdding}
            variant='link'>+Template</Button>
        </Box>
      </Box>

    );
  }


  const [openVariantsModal, setOpenVariantsModal] = useState(false);

  const handleOpenModal = () => {
    setOpenVariantsModal(true);
    setDontCloseFullModal(false)
  };

  const handleCloseModal = () => {
    setDontCloseFullModal(true)
    setOpenVariantsModal(false);
  };

  const [newVariant, setNewVariant] = React.useState('');
  const [newLeftVariant, setNewLeftVariant] = useState('');
  const [newRightVariant, setNewRightVariant] = useState('');

  React.useEffect(() => {
    // if closed modal, reset the newVariant
    if (!openVariantsModal) {
      setNewVariant('');
      setNewLeftVariant('');
      setNewRightVariant('');
    }
  }, [openVariantsModal]);

  const handleAddVariant = () => {
    let updatedVariants = [...product.variants];
    updatedVariants.push('');

    setProduct({ ...product, variants: updatedVariants });
  };


  const handleSaveVariant = async () => {
    // Temporarily hold the current product's variants
    let updatedVariants = product.variants.filter(variant => {
      if (typeof variant === 'object') {
        return variant.left.trim() !== '' || variant.right.trim() !== '';
      } else {
        return variant.trim() !== '';
      }
    });

    // Prepend the newVariant to the variants list if it's not empty and not already included
    if (newVariant.trim() && !updatedVariants.includes(newVariant.trim())) {
      updatedVariants = [newVariant.trim(), ...updatedVariants]; // Prepend newVariant
      setNewVariant(''); // Reset the input field after adding
    }

    // Check for unsaved left and right variants
    if (newLeftVariant.trim() && newRightVariant.trim()) {
      updatedVariants.push({ left: newLeftVariant.trim(), right: newRightVariant.trim() });
      setNewLeftVariant(''); // Reset the input fields after adding
      setNewRightVariant('');
    }

    // Update the product with the potentially new variants
    const updatedProduct = { ...product, variants: updatedVariants };
    setProduct(updatedProduct);

    function trimSku(variant) {
      if (typeof variant === 'string') {
        return variant.trim();
      } else if (typeof variant === 'object' && variant.sku) {
        variant.sku = variant.sku.trim();
        return variant;
      }
      return variant;
    }

    // Check if updatedProduct has variants and remove empty spaces
    if (updatedProduct.variants.length > 0) {
      updatedProduct.variants = updatedProduct.variants.map(trimSku);
      console.log('updatedProduct AFTER UP" ', updatedProduct);
    }

    // Set the flag to true to indicate that a save should be triggered
    setTriggerSave(true);
  };

  useEffect(() => {
    if (triggerSave) {
      // Call the handleSave function
      handleSave().then(() => {
        // Reset the trigger flag and any other cleanup after save
        setOpenVariantsModal(false);
        setDontCloseFullModal(true)
        setTriggerSave(false);
      });
    }
  }, [triggerSave]); // Depend on the triggerSave flag

  const handleChangeFirstVariant = (value) => {
    let _product = { ...product };
    let _variants = [..._product.variants];

    _variants[0] = value; // This could become an empty string if the user clears the input
    _product.variants = _variants;
    setProduct(_product);
  };

  //!!

  const renderVariants = () => {
    // Exclude the first variant from renderSingleStrings since it will be handled separately
    const renderSingleStrings = _.filter(product.variants, (variant, index) => typeof variant === 'string' && index !== 0);
    return (
      <Box sx={{ width: '100%' }}>
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <TextField
            sx={{ width: '100%', borderRadius: '4px', marginBottom: '10px' }}
            placeholder={product.variants.length === 0 ? 'Type Here' : ''}
            value={product.variants.length > 0 && typeof product.variants[0] === 'string' ? product.variants[0] : newVariant}
            onChange={(e) => handleChangeFirstVariant(e.target.value)}
          />
        </Box>

        {renderSingleStrings.map((variant, index) => (
          <Box key={index + 1} sx={{
            width: '100%',
            marginTop: '10px',
            display: 'flex',
            alignItems: 'center'
          }}>
            {/* Hamburger icon on the left */}
            <IconButton onClick={() => {/* Handle action for hamburger icon */
            }}>
              <MenuIcon />
            </IconButton>

            <StyledBootstrapInput
              name="variant"
              value={variant}
              onChange={(event) => handleChangeVariantSingle(event, index + 1)}
              sx={{ flexGrow: 1, marginLeft: '10px', marginRight: '10px' }}
            />


            {/* X icon for deletion */}
            <IconButton onClick={() => handleDeleteSingleVariant(index + 1)}>
              <CloseIcon />
            </IconButton>
          </Box>
        ))}

        <Button
          onClick={() => handleAddVariant()}
          sx={{
            marginTop: '20px',
            color: '#2870EA',
            fontSize: '16px',
            borderRadius: '10px',
            border: '2px solid #2870EA',
            padding: '10px 20px',
          }}
        >
          + Add Variant
        </Button>

        {/* cancel and save buttons */}
        <Box
          sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
          <Button
            variant="contained"
            onClick={handleCloseModal}
            sx={{
              backgroundColor: 'lightgrey',
              color: 'white',
              width: '150px',
              padding: '10px 15px',
              borderRadius: '50px',
              marginRight: '20px',
              color: 'black',
            }}
          >
            <Typography variant="h6">Cancel</Typography>
          </Button>

          <Button
            variant="contained"
            onClick={handleSaveVariant}
            sx={{
              backgroundColor: '#2870EA',
              color: 'white',
              width: '150px',
              padding: '10px 15px',
              borderRadius: '50px',
            }}
          >
            <Typography variant="h6">Save</Typography>
          </Button>
        </Box>

      </Box>
    );
  };

  const [singleSku, setSingleSku] = useState(product.variantSingleSku || '');

  useEffect(() => {
    setSingleSku(product.variantSingleSku || '');
  }, [product.variantSingleSku]);


  const handleChangeSingleSku = (event) => {
    setSingleSku(event.target.value.toUpperCase());
  }

  const handleSaveSingleSku = async () => {
    // append a new field called: variantSingleSku to the product object
    let _product = { ...product };
    _product.variantSingleSku = singleSku.trim();
    setProduct(_product);
    setTriggerSave(true);
  }

  const renderSingleSkuModal = () => {
    // Exclude the first variant from renderSingleStrings since it will be handled separately
    return (
      <Box sx={{ width: '100%' }}>
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <TextField
            sx={{ width: '100%', borderRadius: '4px', marginBottom: '10px' }}
            onChange={handleChangeSingleSku}
            value={singleSku}
            placeholder="Type Here"
          />
        </Box>

        {/* cancel and save buttons */}
        <Box
          sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
          <Button
            variant="contained"
            onClick={handleCloseModal}
            sx={{
              backgroundColor: 'lightgrey',
              color: 'white',
              width: '150px',
              padding: '10px 15px',
              borderRadius: '50px',
              marginRight: '20px',
              color: 'black',
            }}
          >
            <Typography variant="h6">Cancel</Typography>
          </Button>

          <Button
            variant="contained"
            onClick={handleSaveSingleSku}
            sx={{
              backgroundColor: '#2870EA',
              color: 'white',
              width: '150px',
              padding: '10px 15px',
              borderRadius: '50px',
            }}
          >
            <Typography variant="h6">Save</Typography>
          </Button>
        </Box>

      </Box>
    );
  };

  const handleChangeFirstChildVarVariant = (_value, side) => {
    let _product = { ...product };
    // Get the variants but rule out the single string variants
    let _variants = preprocessVariants(_product.variants);

    // Check if there is at least one child variant
    if (_variants.length > 0) {
      // Update the specified side of the first child variant
      _variants[0][side] = _value;
    } else {
      // Create a new child variant object with right and left properties
      const newChildVariant = { left: '', right: '' };
      newChildVariant[side] = _value;
      _variants.push(newChildVariant);
    }

    // Reintegrate the updated child variants with the string variants
    const stringVariants = _product.variants.filter(variant => typeof variant === 'string');
    _product.variants = [...stringVariants, ..._variants];
    setProduct(_product);
  };


  const preprocessVariants = (variants) => {
    // return variant tht are not strings
    return _.filter(variants, (variant) => typeof variant !== 'string');
  };

  const handleAddChildVariant = () => {
    let _product = { ...product };
    // Directly add a new child variant object to the original variants array
    _product.variants.push({ left: '', right: '' });

    setProduct(_product);
  };


  const handleChangeChildVariant = (event, index, side) => {
    let _product = { ...product };

    // Counter for child (object) variants
    let childVariantCounter = -1;

    // Find the actual index by counting object variants
    const actualIndex = _product.variants.findIndex(variant => {
      if (typeof variant !== 'string') {
        childVariantCounter++; // Increment only for object variants
      }
      // Check if the current count matches the provided index
      return childVariantCounter === index;
    });

    // Update the child variant if it's within bounds
    if (actualIndex !== -1 && typeof _product.variants[actualIndex] === 'object') {
      _product.variants[actualIndex][side] = event.target.value;
      setProduct(_product);
    }
  };


  const renderVariantsChild = () => {

    const preprocessedVariants = preprocessVariants(product.variants)
    return (
      <Box sx={{ width: '100%' }}>
        <Box sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between'
        }}>
          <Box sx={{
            width: '40%',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column'
          }}>
            <Typography variant="h6">Left</Typography>
            <TextField
              sx={{
                width: '100%',
                borderRadius: '4px',
                marginBottom: '10px',
              }}
              placeholder={preprocessedVariants.length === 0 ? 'Type Here' : ''}
              value={preprocessedVariants.length > 0 && typeof preprocessedVariants[0] === 'object' ? preprocessedVariants[0].left : newLeftVariant}
              onChange={(e) => handleChangeFirstChildVarVariant(e.target.value, 'left')}
            />
          </Box>

          <Box sx={{
            width: '40%',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column'
          }}>
            <Typography variant="h6">Right</Typography>
            <TextField
              sx={{
                width: '100%',
                borderRadius: '4px',
                marginBottom: '10px',
              }}
              placeholder={preprocessedVariants.length === 0 ? 'Type Here' : ''}
              value={preprocessedVariants.length > 0 && typeof preprocessedVariants[0] === 'object' ? preprocessedVariants[0].right : newRightVariant}
              onChange={(e) => handleChangeFirstChildVarVariant(e.target.value, 'right')}
            />
          </Box>
        </Box>


        {preprocessedVariants.slice(1).map((variant, index) => {
          return (
            <Box key={index + 1}
              sx={{
                width: '100%',
                marginTop: '10px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                flexDirection: 'row',
                flexWrap: 'nowrap', // Ensure items do not wrap
              }}
            >
              <Box sx={{ width: '45%', display: 'flex', alignItems: 'center' }}>
                <IconButton onClick={() => {/* Handle action for hamburger icon */
                }}>
                  <MenuIcon />
                </IconButton>

                <StyledBootstrapInput
                  name={`leftVariant-${index + 1}`}
                  value={variant.left}
                  onChange={(event) => handleChangeChildVariant(event, index + 1, 'left')}
                  sx={{ flexGrow: 1, marginLeft: '10px', marginRight: '10px' }}
                />
              </Box>

              <Box sx={{ width: '45%', display: 'flex', alignItems: 'center', }}>

                <StyledBootstrapInput
                  name={`rightVariant-${index + 1}`}
                  value={variant.right}
                  onChange={(event) => handleChangeChildVariant(event, index + 1, 'right')} // Ensure this function correctly handles the adjusted index
                  sx={{ flexGrow: 1, marginLeft: '10px', marginRight: '10px' }}
                />

                <IconButton onClick={() => handleDeleteChildVariant(index + 1)}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
          )
        })}


        <Button
          onClick={() => handleAddChildVariant()}
          sx={{
            marginTop: '20px',
            color: '#2870EA',
            fontSize: '16px',
            borderRadius: '10px',
            border: '2px solid #2870EA',
            padding: '10px 20px',
          }}
        >
          + Add Variant
        </Button>


        {/* cancel and save buttons */}
        <Box
          sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
          <Button
            variant="contained"
            onClick={handleCloseModal}
            sx={{
              backgroundColor: 'lightgrey',
              color: 'white',
              width: '150px',
              padding: '10px 15px',
              borderRadius: '50px',
              marginRight: '20px',
              color: 'black',
            }}
          >
            <Typography variant="h6">Cancel</Typography>
          </Button>

          <Button
            variant="contained"
            onClick={handleSaveVariant}
            sx={{
              backgroundColor: '#2870EA',
              color: 'white',
              width: '150px',
              padding: '10px 15px',
              borderRadius: '50px',
            }}
          >
            <Typography variant="h6">Save</Typography>
          </Button>
        </Box>

      </Box>
    );
  };

  const handleAddVariantForNewProd = (event) => {
    const newItem = '';

    const _product = { ...product };
    const _variants = [..._product.variants];
    _variants.push(newItem);
    _product.variants = _variants;
    setProduct(_product);
  }

  const renderVariantsForAdding = () => {
    return (
      <Box>
        {
          _.map(product.variants, (variant, index) => {
            return (
              <Box sx={{ width: '100%', marginTop: '10px', display: 'flex' }}>
                <BootstrapInput name="variant" value={variant}
                  onChange={event => handleChangeVariant(event, index)} />

                <Button onClick={() => handleDeleteVariant(event, index)}>
                  <DeleteOutlineIcon />
                </Button>

              </Box>
            );
          })
        }
        <Box sx={{ marginTop: '12px' }}>
          <Button onClick={handleAddVariantForNewProd}
            variant='link'>+Variant</Button>
        </Box>
      </Box>

    );
  }

  const handleNewVendorChange = (e, field) => {
    setNewVendor({ ...newVendor, [field]: e.target.value });
  };

  const handleAddVendor = () => {
    const updatedVendors = [...product.vendors, newVendor]; // Add the newVendor to the vendors array
    setProduct({ ...product, vendors: updatedVendors }); // Update the product state with the new vendors array
    setNewVendor({ template: "", barcode: "", sku: "", price: "" }); // Reset the newVendor state to clear the inputs
  };

  const renderVendorAttributes = () => {
    return (
      <Box>
        {/* Add vendor button on the top right */}
        <Box sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginBottom: '20px'
        }}>
          <Button
            variant="contained"
            onClick={handleAddVendor}
            sx={{
              backgroundColor: '#2870EA', color: 'white',
              padding: '10px 15px', borderRadius: '50px',
              marginRight: '20px', marginTop: '20px',
              fontSize: '16px',
            }}
          >
            + Add Vendor
          </Button>
        </Box>


        <Grid container spacing={3} alignItems="center"
          justifyContent={'center'} paddingLeft={2}>
          {/* New Vendor Inputs */}
          <Grid item xs={3}>
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '80%',
              paddingBottom: '6px',
              width: '95%'
            }}>
              <InputLabel
                sx={{
                  color: 'black',
                  fontWeight: 'bold'
                }}
              >New Vendor Template</InputLabel>
              <Select
                name="new-template"
                value={newVendor.template}
                displayEmpty
                onChange={(e) => handleNewVendorChange(e, 'template')}
                fullWidth
                sx={{
                  height: '55px',
                }}
                renderValue={
                  newVendor.template !== ""
                    ? undefined
                    : () => <span
                      style={{ color: 'gray' }}>Select a template</span> // Placeholder text and style
                }
              >
                <MenuItem value="" disabled>Select a template</MenuItem>
                {barcodes.map(item => (
                  <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                ))}
              </Select>

            </Box>
          </Grid>

          <Grid item xs={3}>
            <InputLabel
              sx={{
                color: 'black',
                fontWeight: 'bold'
              }}
            >New Barcode</InputLabel>
            <TextField
              sx={{
                width: '95%',
                height: '60px',
                borderRadius: '4px',
              }}
              placeholder="Type here..."
              value={newVendor.barcode}
              onChange={(e) => handleNewVendorChange(e, 'barcode')}
            />
          </Grid>

          <Grid item xs={3}>
            <InputLabel
              sx={{
                color: 'black',
                fontWeight: 'bold'
              }}
            >New Price</InputLabel>
            <TextField
              sx={{
                width: '95%',
                height: '60px',
                borderRadius: '4px',
              }}
              type="number"
              placeholder="Type here..."
              value={newVendor.price}
              onChange={(e) => handleNewVendorChange(e, 'price')}
              InputProps={{
                inputProps: {
                  min: 0, // Optionally set minimum value
                  step: 0.01 // Optionally set the step for increment/decrement
                }
              }}
            />
          </Grid>

          <Grid item xs={3}>
            <InputLabel
              sx={{
                color: 'black',
                fontWeight: 'bold'
              }}
            >New SKU</InputLabel>
            <TextField
              sx={{
                width: '95%',
                height: '60px',
                borderRadius: '4px',
              }}
              placeholder="Type here..."
              value={newVendor.sku}
              onChange={(e) => handleNewVendorChange(e, 'sku')}
            />
          </Grid>
        </Grid>


        {/* Divider Line */}
        <Divider sx={{ my: 2 }} />

        {product.vendors.map((vendor, index) => (
          <Grid container key={index} spacing={3} alignItems="center"
            justifyContent="center" paddingLeft={2}>
            <Grid item xs={12}>
              <Box sx={{
                display: 'flex',
                alignItems: 'center',
                paddingTop: '20px'
              }}>
                <IconButton size="small">
                  <MenuIcon />
                </IconButton>
                <Typography variant="subtitle1" sx={{ marginLeft: '10px' }}>
                  #{index + 1}
                </Typography>
              </Box>
            </Grid>

            {/* Adjust the Grid items to accommodate the delete icon */}
            <Grid item xs={2.5}>
              <Box sx={{ paddingBottom: '6px' }}>
                <InputLabel>Vendor Template</InputLabel>
                <Select
                  name={`template-${index}`}
                  value={vendor.template}
                  displayEmpty
                  onChange={(e) => handleChangeVendorAttributes(e, index, 'template')}
                  fullWidth
                  sx={{ height: '55px', paddingBotton: '10px' }}
                  renderValue={selected => {
                    // If no template is selected, show "Select a template"
                    if (!selected) {
                      return <span
                        style={{ color: 'gray' }}>Select a template</span>;
                    }
                    // Find the template name that matches the selected value and display it
                    return vendor.template;
                  }}
                >
                  <MenuItem value="" disabled>Select a template</MenuItem>
                  {barcodes.map(item => (
                    <MenuItem key={item.id}
                      value={item.id}>{item.name}</MenuItem>
                  ))}
                </Select>
              </Box>
            </Grid>

            <Grid item xs={2.5}>
              <InputLabel>Barcode #</InputLabel>
              <TextField
                sx={{ width: '100%', height: '60px', borderRadius: '4px' }}
                placeholder="Type here..."
                name={`barcode-${index}`}
                value={vendor.barcode}
                onChange={(e) => handleChangeVendorAttributes(e, index, 'barcode')}
              />
            </Grid>

            <Grid item xs={2.5}>
              <InputLabel>Price</InputLabel>
              <TextField
                sx={{ width: '100%', height: '60px', borderRadius: '4px' }}
                type="number"
                placeholder="Type here..."
                name={`price-${index}`}
                value={vendor.price}
                onChange={(e) => handleChangeVendorAttributes(e, index, 'price')}
                InputProps={{
                  inputProps: { min: 0, step: 0.01 }
                }}
              />
            </Grid>

            <Grid item xs={2.5}>
              <InputLabel>SKU</InputLabel>
              <TextField
                sx={{ width: '100%', height: '60px', borderRadius: '4px' }}
                placeholder="Type here..."
                name={`sku-${index}`}
                value={vendor.sku}
                onChange={(e) => handleChangeVendorAttributes(e, index, 'sku')}
              />
            </Grid>

            {/* Delete Icon next to the input fields */}
            <Grid item xs={1}>
              <IconButton
                onClick={() => handleDeleteVendorAttribute(index)} // Make sure to implement this function
                aria-label="delete"
                sx={{ marginTop: '10px' }} // Adjust the position as needed
              >
                <Box sx={{
                  width: '40px',
                  height: '40px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: 'rgba(0,0,0,0.04)',
                  borderRadius: '50%'
                }}>
                  <DeleteOutlineIcon
                    color="error"
                    fontSize="medium"
                  />
                </Box>
              </IconButton>
            </Grid>

            <Divider sx={{ width: '100%', my: 2 }} />
          </Grid>
        ))}


      </Box>
    );
  };

  const renderVendorAttributesForAdding = () => {
    return (
      <Box sx={{ width: "100%" }}>
        <TableContainer sx={{ marginTop: '0px', marginBottom: '20px' }}>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead classes={classes} useCheckbox={false}
              cells={headCells} />

            <TableBody>
              {product.vendors
                .map((row, index) => {
                  const keyId = `vendor-${index}`;

                  return (
                    <TableRow
                      tabIndex={-1}
                      key={keyId}
                    >
                      <TableCell align="left" className={classes.tableCell}>
                        <Select
                          name="template"
                          value={row.template}
                          input={<BootstrapInput />}
                          onChange={e => handleChangeVendorAttributes(e, index)}
                        >
                          <MenuItem value="">None</MenuItem>
                          {
                            _.map(barcodes, item => (
                              <MenuItem value={item.id}>{item.name}</MenuItem>
                            ))
                          }
                        </Select>
                      </TableCell>
                      <TableCell align="left" className={classes.tableCell}>
                        <BootstrapInput type="text" sx={{ width: "100%" }}
                          name="barcode" value={row.barcode}
                          onChange={e => handleChangeVendorAttributes(e, index)} />
                      </TableCell>
                      <TableCell align="left" className={classes.tableCell}>
                        <BootstrapInput type="text" sx={{ width: "100%" }}
                          name="price" value={row.price}
                          onChange={e => handleChangeVendorAttributes(e, index)} />
                      </TableCell>
                      <TableCell align="left" className={classes.tableCell}>
                        <BootstrapInput type="text" sx={{ width: "100%" }}
                          name="sku" value={row.sku}
                          onChange={e => handleChangeVendorAttributes(e, index)} />
                      </TableCell>
                    </TableRow>
                  );
                })}

            </TableBody>
          </Table>
        </TableContainer>

        <Button
          variant={'link'}
          onClick={handleAddVendor}>+Vendor</Button>
      </Box>
    );
  }

  const renderAttributes = () => {
    let template = null;
    if (product.templates.length > 0) {
      template = _.find(templates, { id: product.templates[0].template });
    }

    return (
      <Box sx={{ width: '100%' }}>
        <Grid container spacing={3}>
          <Grid item xs={5}>
            <InputLabel>Primary SKU</InputLabel>
            <BootstrapInput name="sku" value={product.sku}
              onChange={handleChange} />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={5}>
            <InputLabel>Product Name</InputLabel>
            <BootstrapInput name="name" value={product.name}
              onChange={handleChange} />
          </Grid>
        </Grid>

        <Grid container spacing={3} sx={{ marginTop: '25px' }}>
          <Grid item xs={6}>
            <InputLabel>Variants</InputLabel>
            <Typography variant="caption">*Primary variant is the first one</Typography>
            {renderVariants()}

          </Grid>
        </Grid>
        {
          !addingProduct && (
            <Grid container spacing={3} sx={{ marginTop: '25px' }}>
              <Grid item xs={5}>

                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <InputLabel>Type</InputLabel>
                    <Select
                      name="type"
                      value={product.type}
                      input={<BootstrapInput />}
                      onChange={handleChange}
                    >
                      <MenuItem value="">-</MenuItem>
                      {
                        _.map(types, item => (
                          <MenuItem value={item.name}>{item.name}</MenuItem>
                        ))
                      }
                    </Select>

                  </Grid>


                  <Grid item xs={6}>
                    <InputLabel>Style</InputLabel>
                    <Select
                      name="style"
                      value={product.style}
                      input={<BootstrapInput />}
                      onChange={handleChange}
                    >
                      <MenuItem value="">-</MenuItem>
                      {
                        _.map(styles, style => (
                          <MenuItem value={style.name}>{style.name}</MenuItem>
                        ))
                      }
                    </Select>
                  </Grid>


                </Grid>

                <Grid container spacing={3} sx={{ marginTop: '25px' }}>

                  <Grid item xs={6}>
                    <InputLabel>Format</InputLabel>
                    <Select
                      name="format"
                      value={product.format}
                      input={<BootstrapInput />}
                      onChange={handleChange}
                    >
                      <MenuItem value="">-</MenuItem>
                      {
                        _.map(formats, format => (
                          <MenuItem value={format.id}>{format.name}</MenuItem>
                        ))
                      }
                    </Select>
                  </Grid>

                  <Grid item xs={6}>
                    <InputLabel>Size</InputLabel>
                    <Select
                      name="size"
                      value={product.size}
                      input={<BootstrapInput />}
                      onChange={handleChange}
                    >
                      <MenuItem value="">-</MenuItem>
                      {
                        _.map(sizes, size => {
                          // Skip rendering the MenuItem for "YTH"
                          if (size.name === "YTHL") {
                            return 'Youth L';
                          }
                          return (
                            <MenuItem key={size.id}
                              value={size.id}>{size.name}</MenuItem>
                          );
                        })
                      }
                    </Select>
                  </Grid>


                </Grid>
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={5}>
                <InputLabel>Tags</InputLabel>
                <Select
                  sx={{ width: '100%' }}
                  input={<BootstrapInput />}
                  onChange={handleTagsChange}
                  value={'select'}
                >
                  <MenuItem value={'select'}>Select Tag(s)</MenuItem>
                  {
                    _.map(tags, (item, key) => (
                      <MenuItem key={'tagselect-' + key}
                        value={item.name}>{item.name}</MenuItem>
                    ))
                  }
                </Select>

                {tags.length > 0 && renderTagsForAdding()}
              </Grid>
            </Grid>
          )
        }

        {/* Template & Checkboxs */}
        <Grid container spacing={3} sx={{ marginTop: '25px' }}>
          <Grid item xs={3}>
            <InputLabel>Template</InputLabel>
            {renderTemplates()}
          </Grid>

          {template && template.isCustomText && (
            <Grid item xs={3}>
              <InputLabel>Color</InputLabel>
              <BootstrapInput name="color" value={product.color}
                onChange={handleChange} />
            </Grid>
          )}

          <Grid item xs={3}>
            <div>
              <InputLabel>&nbsp;</InputLabel>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    name="isBundle"
                    checked={product.isBundle}
                    onChange={handleChange}
                  />
                }
                label="Bundle Product"
              />
            </div>

          </Grid>
          <Grid item xs={3}>
            <div>
              <InputLabel>&nbsp;</InputLabel>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    name="identical"
                    checked={product.identical}
                    onChange={handleChange}
                  />
                }
                label="Identical Product"
              />
            </div>
          </Grid>
        </Grid>
      </Box>
    );
  }


  const renderAttributesForAdding = () => {
    let template = null;
    if (product.templates.length > 0) {
      template = _.find(templates, { id: product.templates[0].template });
    }

    return (
      <Box sx={{ width: '100%' }}>
        <Grid container spacing={3}>
          <Grid item xs={5}>
            <InputLabel>Primary SKU</InputLabel>
            <BootstrapInput name="sku" value={product.sku}
              onChange={handleChange} />
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid item xs={5}>
            <InputLabel>Product Name</InputLabel>
            <BootstrapInput name="name" value={product.name}
              onChange={handleChange} />
          </Grid>
        </Grid>

        <Grid container spacing={3} sx={{ marginTop: '25px' }}>
          <Grid item xs={6}>
            <InputLabel>Variants</InputLabel>
            {renderVariantsForAdding()}

          </Grid>
        </Grid>


        {/* Template & Checkboxs */}
        <Grid container spacing={3} sx={{ marginTop: '25px' }}>
          <Grid item xs={3}>
            <InputLabel>Template</InputLabel>
            {renderTemplatesForAdding()}
          </Grid>

          {template && template.isCustomText && (
            <Grid item xs={3}>
              <InputLabel>Color</InputLabel>
              <BootstrapInput name="color" value={product.color}
                onChange={handleChange} />
            </Grid>
          )}

          <Grid item xs={3}>
            <div>
              <InputLabel>&nbsp;</InputLabel>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    name="isBundle"
                    checked={product.isBundle}
                    onChange={handleChange}
                  />
                }
                label="Bundle Product"
              />
            </div>

          </Grid>
          <Grid item xs={3}>
            <div>
              <InputLabel>&nbsp;</InputLabel>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    name="identical"
                    checked={product.identical}
                    onChange={handleChange}
                  />
                }
                label="Identical Product"
              />
            </div>
          </Grid>
        </Grid>
      </Box>
    );
  }

  const handleChangeArt = (event, isExpanded) => {
    setExpandArt(isExpanded);
  };

  const handleChangeImage = (event, isExpanded) => {
    setExpandImage(isExpanded);
  };

  const handleChangeAttributes = (event, isExpanded) => {
    setExpandAttr(isExpanded);
  };

  const handleChangeVendor = (event, isExpanded) => {
    setExpandVendor(isExpanded);
  };

  const handleOpenDeleteDialog = (event) => {
    SetDeleteModal(true);
  }

  const handleDeleteProduct = () => {
    console.log('handleDeleteProduct');
    deleteProduct(db, product);
    SetDeleteModal(false);
    handleClose();
  }

  const handleCloseDeleteDialog = (event) => {
    SetDeleteModal(false);
  }

  const handleOpenDuplicateDialog = (event) => {
    SetDuplicateModal(true);
  }

  const handleDuplicateProduct = () => {
    console.log('handleDuplicateProduct');
    let _product = { ...product };
    _product.sku = product.sku + '-Duplicate';
    setProduct(_product);
    setIsDuplicate(true);
    SetDuplicateModal(false);
    //handleClose();
  }

  const handleCloseDuplicateDialog = (event) => {
    SetDuplicateModal(false);
  }
  const scroll = 'paper';

  const strPtSize = product.id !== "" && product.size !== "" ? _.find(sizes, { id: product.size })?.sku : "";


  const strPtFormat = product.format !== "" ? _.find(formats, { id: product.format })?.name : "";
  const types = _.filter(filterTypes, item => {
    return item.name !== 'All' && item.name !== 'None'
  });


  const handleNextButtonOnAllFilled = () => {
    setDontShowFullModal(false);
  }

  const renderAddProductModal = () => {
    return (

      <>
        <DialogTitle>
          <Typography variant="h2">Add Product</Typography>
          <IconButton aria-label="close" variant='close'
            onClick={handleCloseDialog}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <Divider sx={{ width: '100%' }} />


        <Box sx={{ padding: '40px', position: 'relative', paddingBottom: '8%' }}>
          {error && (<Typography variant='errorText'>{errorMsg}</Typography>)}
          <Grid container spacing={3} justifyContent="flex-start">
            {/* Type Field - Always visible */}
            <Grid item xs={3}>
              <InputLabel>Type</InputLabel>
              <Select
                name="type"
                value={product.type}
                input={<BootstrapInput />}
                onChange={handleChange}
                fullWidth
                sx={{
                  border: product.type ? '1px solid transparent' : '1px solid blue',
                  borderRadius: '4px'
                }}
              >
                <MenuItem value="">-</MenuItem>
                {_.map(types, item => (
                  <MenuItem key={item.name}
                    value={item.name}>{item.name}</MenuItem>
                ))}
              </Select>
            </Grid>

            {/* Style Field - Enabled after Type is selected */}
            <Grid item xs={3}>
              <InputLabel>Style</InputLabel>
              <Select
                name="style"
                value={product.style}
                input={<BootstrapInput />}
                onChange={handleChange}
                disabled={!product.type} // Enable after Type is selected
              >
                <MenuItem value="">-</MenuItem>
                {_.map(styles, style => (<MenuItem key={style.name}
                  value={style.name}>{style.name}</MenuItem>))}
              </Select>
            </Grid>

            {/* Format Field - Enabled after Style is selected */}
            <Grid item xs={3}>
              <InputLabel>Format</InputLabel>
              <Select
                name="format"
                value={product.format}
                input={<BootstrapInput />}
                onChange={handleChange}
                disabled={!product.style} // Enable after Style is selected
              >
                <MenuItem value="">-</MenuItem>
                {_.map(formats, format => (<MenuItem key={format.id}
                  value={format.id}>{format.name}</MenuItem>))}
              </Select>
            </Grid>

            {/* Size Field - Enabled after Format is selected */}
            <Grid item xs={3}>
              <InputLabel>Size</InputLabel>
              <Select
                name="size"
                value={product.size}
                input={<BootstrapInput />}
                onChange={handleChange}
                disabled={!product.format} // Enable after Format is selected
              >
                <MenuItem value="">-</MenuItem>
                {
                  _.map(sizes, size => {
                    // Check if the size is "YTH" and replace the display name with "Youth L"
                    let displayName = size.name === "YTHL" ? "Youth L" : size.name;
                    return (
                      <MenuItem key={size.id}
                        value={size.id}>{displayName}</MenuItem>
                    );
                  })
                }

              </Select>
            </Grid>

            {/* Conditionally rendered Divider and "Next" Button */}
            {product.type && product.format && product.style && product.size && dontShowFullModal && (
              <>
                <Divider sx={{
                  position: 'absolute',
                  bottom: '30%',
                  left: 0,
                  width: '100%'
                }} />
                <Box sx={{ position: 'absolute', bottom: '5%', right: '2%' }}>
                  <Button variant="contained"
                    onClick={handleNextButtonOnAllFilled}>
                    <Typography color={"white"} variant='text'>Next</Typography>
                  </Button>
                </Box>
              </>
            )}
          </Grid>
        </Box>

        {
          !dontShowFullModal && (
            <>
              <DialogContent sx={{ padding: '0 40px' }} dividers>
                <Box sx={{ padding: '20px 25px' }}>
                  {error && (
                    <Typography variant='errorText'>{errorMsg}</Typography>)}
                  <Accordion expanded={expandAttr}
                    onChange={handleChangeAttributes}
                    sx={{ boxShadow: "none" }}>
                    <AccordionSummary
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <IconButton aria-label="close" variant={'arrow'}>
                        {expandAttr ? (<ExpandLessIcon />) : (<ExpandMoreIcon />)}
                      </IconButton>

                      <Typography variant="h3">Product Attributes</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {load && renderAttributesForAdding()}
                    </AccordionDetails>
                  </Accordion>

                  {/* Vendor Attribute*/}
                  <Accordion expanded={expandVendor} onChange={handleChangeVendor}
                    sx={{ boxShadow: "none" }}>
                    <AccordionSummary
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <IconButton aria-label="close" variant={'arrow'}>
                        {expandVendor ? (<ExpandLessIcon />) : (<ExpandMoreIcon />)}
                      </IconButton>

                      <Typography variant="h3">Vendor Attributes</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {load && renderVendorAttributesForAdding()}
                    </AccordionDetails>
                  </Accordion>

                  {/* Product Art */}
                  <Accordion expanded={expandArt} onChange={handleChangeArt}
                    sx={{ boxShadow: "none" }}>
                    <AccordionSummary
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <IconButton aria-label="close" variant={'arrow'}>
                        {expandArt ? (<ExpandLessIcon />) : (<ExpandMoreIcon />)}
                      </IconButton>
                      <Typography variant="h3">Product Art</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {load && renderArts()}
                    </AccordionDetails>
                  </Accordion>

                  {
                    ((strPtSize === "LXL" && strPtFormat === "Low") ||
                      ((strPtSize === "LXL" || strPtSize === "YTHL") && strPtFormat === "Crew") ||
                      strPtSize === "L"
                    ) && (
                      <Accordion expanded={expandImage} onChange={handleChangeImage}
                        sx={{ boxShadow: "none" }}>
                        <AccordionSummary
                          aria-controls="panel3a-content"
                          id="panel3a-header"
                        >
                          <IconButton aria-label="close" variant={'arrow'}>
                            {expandImage ? (<ExpandLessIcon />) : (
                              <ExpandMoreIcon />)}
                          </IconButton>
                          <Typography variant="h3">Product Image</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          {load && strPtSize !== "L" && renderMockup(strPtFormat === "Crew")}
                          {load && strPtSize === "L" && renderMockupBoxer()}
                        </AccordionDetails>
                      </Accordion>
                    )
                  }

                </Box>

                <ConfirmDialog
                  label="delete-dialog-title"
                  title="Delete Product"
                  open={deleteModal}
                  onConfirm={handleDeleteProduct}
                  onClose={handleCloseDeleteDialog}
                >
                  <Typography variant='body2' gutterBottom>
                    Do you want to remove current product?
                  </Typography>
                </ConfirmDialog>

                <ConfirmDialog
                  label="duplicate-dialog-title"
                  title="Duplicate Product"
                  open={duplicateModal}
                  onConfirm={handleDuplicateProduct}
                  onClose={handleCloseDuplicateDialog}
                >
                  <Typography variant='body2' gutterBottom>
                    Do you want to duplicate current product?
                  </Typography>
                </ConfirmDialog>

              </DialogContent>

              <DialogActions
                sx={{ padding: '15px 40px', justifyContent: 'space-between' }}>
                <Button variant="blue" color="primary" sx={{ width: '120px' }}
                  onClick={handleSave}
                  disabled={frontProgress < 100 || backProgress < 100 || !props.editOnly}>Save</Button>

                {!isCreate && (
                  <Box>
                    <Button
                      onClick={handleOpenDuplicateDialog}
                      color="primary"
                      variant="blue"
                      disabled={!props.editOnly}
                      sx={{ marginRight: '15px', width: '120px' }}
                    >Duplicate</Button>

                    <Button
                      color="secondary"
                      variant="black"
                      onClick={handleOpenDeleteDialog}
                      disabled={!props.editOnly}
                      sx={{ width: '120px' }}
                    >Delete</Button>
                  </Box>
                )}

              </DialogActions>
            </>
          )
        }

      </>

    )
  }


  return (
    <>
      {
        addingProduct ? (
          <Dialog
            onClose={handleCloseDialog}
            aria-labelledby="revision-dialog-title"
            open={open}
            fullWidth={true}
            maxWidth={"lg"}
            scroll={scroll}
          >
            {renderAddProductModal()}
          </Dialog>
        ) : (
          <Dialog
            onClose={handleCloseDialog}
            aria-labelledby="revision-dialog-title"
            open={open}
            PaperProps={{
              style: {
                height: '95vh',
                width: '90vw',
                maxWidth: '90vw',
                borderRadius: '20px',
                maxHeight: '95vh'
              },
            }}
          >
            <RevampedProductProfile
              storage={storage}
              error={error}
              errorMsg={errorMsg}
              handleCloseDialog={handleCloseDialog}
              product={product}
              expandAttr={expandAttr}
              handleChangeAttributes={handleChangeAttributes}
              load={load}
              handleChange={handleChange}
              renderAttributes={renderAttributes}
              expandVendor={expandVendor}
              handleChangeArt={handleChangeArt}
              handleChangeVendor={handleChangeVendor}
              expandArt={expandArt}
              renderVendorAttributes={renderVendorAttributes}
              renderArts={renderArts}
              expandImage={expandImage}
              strPtSize={strPtSize}
              strPtFormat={strPtFormat}
              handleChangeImage={handleChangeImage}
              handleCloseDeleteDialog={handleCloseDeleteDialog}
              handleCloseDuplicateDialog={handleCloseDuplicateDialog}
              handleOpenDeleteDialog={handleOpenDeleteDialog}
              deleteModal={deleteModal}
              handleDeleteProduct={handleDeleteProduct}
              handleOpenDuplicateDialog={handleOpenDuplicateDialog}
              duplicateModal={duplicateModal}
              handleDuplicateProduct={handleDuplicateProduct}
              handleSave={handleSave}
              renderMockup={renderMockup}
              renderMockupBoxer={renderMockupBoxer}
              frontProgress={frontProgress}
              backProgress={backProgress}
              hyperOpticIdenticalProgress={hyperOpticIdenticalProgress}
              handleNextButtonOnAllFilled={handleNextButtonOnAllFilled}
              types={types}
              formats={formats}
              sizes={sizes}
              rockemStyles={styles}
              frontImg={frontImg}
              backImg={backImg}
              firebase={props.firebase.getFirebase()}
              classes={classes}
              dropzoneRef1={dropzoneRef1}
              dropzoneRef2={dropzoneRef2}
              handleDropFront={handleDropFront}
              handleDropBack={handleDropBack}
              handleClickUploadFront={handleClickUploadFront}
              handleClickUploadBack={handleClickUploadBack}
              setFrontDimension={setFrontDimension}
              cropSettings={cropSettings}
              handleTagsChange={handleTagsChange}
              tags={tags}
              renderTags={renderTags}
              StyledBootstrapInput={StyledBootstrapInput}
              TopStyledBootstrapInput={TopStyledBootstrapInput}
              renderVariants={renderVariants}
              openVariantsModal={openVariantsModal}
              handleCloseModal={handleCloseModal}
              handleOpenModal={handleOpenModal}
              setShowVendorAttributes={setShowVendorAttributes}
              showVendorAttributes={showVendorAttributes}
              renderTemplates={renderTemplates}
              disabledButtons={props.editOnly}
              db={db}
              frontPath={frontPath}
              backPath={backPath}
              setBackDimension={setBackDimension}
              isUnderwear={isUnderwear}
              isHyperOpticFraternal={isHyperOpticFraternal}
              hyperOpticIdenticalImage={hyperOpticIdenticalImage}
              hyperOpticLeftImage={hyperOpticLeftImage}
              hyperOpticRightImage={hyperOpticRightImage}
              handleDropHyperOptic={handleDropHyperOptic}
              renderVariantsChild={renderVariantsChild}
              hyperOpticLeftPath={hyperOpticLeftPath}
              hyperOpticRightPath={hyperOpticRightPath}
              hyperOpticIdenticalPath={hyperOpticIdenticalPath}
              isBoxedImagePath={isBoxedImagePath}
              handleDropBoxedImage={handleDropBoxedImage}
              isBoxedImage={isBoxedImage}
              renderSingleSkuModal={renderSingleSkuModal}
            />
            <ConfirmDialog
              label="delete-dialog-title"
              title="Delete Product"
              open={deleteModal}
              onConfirm={handleDeleteProduct}
              onClose={handleCloseDeleteDialog}
            >
              <Typography variant='body2' gutterBottom>
                Do you want to remove current product?
              </Typography>
            </ConfirmDialog><ConfirmDialog
              label="duplicate-dialog-title"
              title="Duplicate Product"
              open={duplicateModal}
              onConfirm={handleDuplicateProduct}
              onClose={handleCloseDuplicateDialog}
            >
              <Typography variant='body2' gutterBottom>
                Do you want to duplicate current product?
              </Typography>
            </ConfirmDialog>
          </Dialog>
        )
      }
    </>
  );
}

const StyledBootstrapInput = (props) => (
  <BootstrapInput
    {...props}
    sx={{
      height: '60px', // Increase the height
      width: '100%', // Adjust as needed
      '&& .MuiInputBase-input': {
        height: '100%',
        width: '100%',
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'center',

      }
    }}
  />
);

const TopStyledBootstrapInput = (props) => (
  <BootstrapInput
    {...props}
    sx={{
      height: '60px', // Increase the height
      '&& .MuiInputBase-input': {
        height: '100%',
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'center',

      }
    }}
  />
);


export default ProductModal;
