import { useFuzzySearch } from "../../utils/useFuzzySearchForBundles";



function formatFirestoreTimestamp(seconds, nanoseconds) {
  // Convert seconds to milliseconds and add nanoseconds in milliseconds
  const date = new Date(seconds * 1000 + nanoseconds / 1000000);

  // Get the date components
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  const day = String(date.getDate()).padStart(2, '0');
  const year = String(date.getFullYear()).slice(-2); // Get the last two digits of the year

  // Return formatted date in mm/dd/yy
  return `${month}/${day}/${year}`;
}


export const convertToRows = (_orders, isCustoms = false) => {
  const rows = [];
  _orders.forEach((order, index) => {
    const odd = index % 2 === 1;
    if (order && order.customer && order.customer.firstName && order.customer.lastName) {
      const formattedCustomerName = `${order.customer.firstName.charAt(0)}. ${order.customer.lastName}`;
      order.customer = formattedCustomerName;
    }

    let isManyItems = order.items.length > 1;
    let itemsPushed = []

    order.items.forEach((item, itemIndex) => {
      let type
      if (item.type && item.type !== "") {
        type = item.type ?? "Unknown";

        if (item?.style && item.style !== "Graphic") {
          type = item.style;
        }

      } else {
        if (item.isBoxSet) {
          type = item.data?.type ?? "Unknown";
        }
      }

      itemsPushed.push(type);

      item.type = type;

      const rowData = {
        containsCustomProduct: order.containsCustomProduct,
        createDate: formatFirestoreTimestamp(order.created_at.seconds, order.created_at.nanoseconds),
        odd,
        orderNumber: order.orderNumber.toString(),
        exists: item.exists,
        available: item.available,
        type: type,
        shipping: order.shipping,
        sku: item.sku,
        quantity: item.quantity,
        tags: item.tags,
        source: order.source,
        reprint: !!order.reprint,
        error: item.error,
        style: item.style,
        index: itemIndex,
        customText: isCustoms && (item.customText || item.customText !== "") ? item.customText : "",
        orderData: order,
        isMixAndMatch: item.isMixAndMatch,
        properties: item.properties,
        restoreDate: order.restoreDate,
      };
      rows.push(rowData);
    });

    if (isManyItems) {
      const allItemsSame = itemsPushed.every((val, i, arr) => val === arr[0]);

      if (!allItemsSame) {
        order.type = "Mixed" + itemsPushed.join(" & ");
      }
    }
  });
  return rows;
};

const handleHowManyItemsInOrder = (rows, typeOfOrder) => {
  switch (typeOfOrder) {
    case "All":
      return rows;
    case "Singles":
      const singleItems = rows.filter(row =>
        row.items.length === 1 &&            // Only one item in the array
        row.items[0].quantity === 1 &&       // Quantity of that item is 1
        !row.items[0].isBoxSet               // `isBoxSet` is false
      );
      return singleItems;
    case "Multiples":
      // has 2 or more items
      return rows.filter(row => row.items.length > 1);
    case "customAndInline":
      return rows.filter(order => {
        const items = order.items;
        const hasCustomProduct = items.some(item => item.isCustomProduct === true);
        const hasInlineProduct = items.some(item => item.isCustomProduct === false);

        return hasCustomProduct && hasInlineProduct;
      });
    default:
      return rows;
  }
}

const handleTypeOfWebOrder = (rows, typeOfOrder) => {
  switch (typeOfOrder) {
    case "Customs":
      return rows.filter(row => row.containsCustomProduct);
    case "Inlines":
      return rows.filter(row => !row.containsCustomProduct);
    case "BoxSets":
      return rows.filter(row => row.items.some(item => item.isBoxSet));
    default:
      return rows;
  }
}

const handleProductType = (rows, productType) => {
  console.log('productType', productType);
  switch (productType) {
    case "Socks":
      return rows.filter(row => row.items.some(item => item.type === "Socks"));
    case "SockOnly":
      return rows.filter(row => row.items.every(item => item.type === "Socks"));
    case "Underwear":
      return rows.filter(row => row.items.some(item => item.type === "Underwear"));
    case "UnderwearOnly":
      return rows.filter(row => row.items.every(item => item.type === "Underwear"));
    default:
      return rows;
  }
}

const handleProduction = (rows, production) => {
  switch (production) {
    case "Finished Goods":
      return rows.filter(row => row.items.some(item => {
        const style = item.style.toLowerCase();
        const isMixAndMatch = item.isMixAndMatch === true;
        const isDesiredStyle = ["knitted", "hyperoptic", "cut & sew", "graphic tee", "hat", "hoodie"].includes(style);

        return isDesiredStyle || isMixAndMatch;
      }));
    case "Only Finished Goods":
      return rows.filter(row => row.items.every(item => {
        const style = item.style.toLowerCase();
        const didFind = ["knitted", "hyperoptic", "cut & sew", "graphic tee", "hat", "hoodie"].includes(style);
        const isMixAndMatch = item.isMixAndMatch === true;
        return didFind || isMixAndMatch;
      }));
    case "Graphic":
      return rows.filter(row => row.items.every(item => item?.style?.toLowerCase()?.includes("graphic")));
    default:
      return rows;
  }
}

const handleShipping = (rows, shipping) => {
  if (shipping === "All") {
    return rows;
  }
  // th function will look in the rows shipping 
  return rows.filter(row => row.shipping === shipping);
}

// apply filters to rows
export const applyFilters = (rows, typeOfOrder, productType, production, shipping, showProductsNotFound, keyWords) => {
  let filteredRows = rows;

  if (showProductsNotFound) {
    filteredRows = filteredRows.filter((order) =>
      order.items.some((item) => item.error !== "" || item.exists === false)
    );
  }

  // handle when theres no keyWords or === ""
  if (keyWords === "" || !keyWords) {
    filteredRows = filteredRows;
  } else {
    filteredRows = useFuzzySearch(keyWords, filteredRows, "inline");
  }


  if (typeOfOrder !== "All") {
    filteredRows = handleHowManyItemsInOrder(filteredRows, typeOfOrder);
  }


  if (production !== "All") {
    filteredRows = handleProduction(filteredRows, production);
  }

  if (productType !== "All") {
    filteredRows = handleProductType(filteredRows, productType);
  }

  if (shipping !== "All") {
    filteredRows = handleShipping(filteredRows, shipping);
  }

  return filteredRows; // filtered results returned here
};

export const countProductsAndBundlesWeb = (selectedOrders, isSelectedData = false) => {
  let products = 0;
  let bundles = 0;
  let socks = 0;
  let underwear = 0;
  let finished = 0;
  let notFound = 0;

  // Updated finished quantity function
  function updateFinishedQuantity(itemStyle, quantity) {
    const stylesToAdd = ["knitted", "hyperoptic", "cut & sew", "graphic tee", "hat", "hoodie"];

    if (stylesToAdd.includes(itemStyle)) {
      finished += quantity;  // Update the finished count directly
      return true;  // Indicate that this item is finished
    }

    return false;  // This item is not considered finished
  }
  // Create unique set of order ids
  const orderIds = new Set();

  selectedOrders.forEach((order) => {
    // if an order has no items, remove it from the list
    if (order.items.length === 0) {
      return;
    }
    orderIds.add(order.orderNumber.toString());
  });

  const orders = orderIds.size;

  selectedOrders.forEach((order) => {
    order.items.forEach((item) => {
      const quantity = item.quantity || 1;

      if (item?.error?.toLowerCase() === "product not found") {
        notFound += 1;
      }

      if (item.isBoxSet) {
        bundles += quantity;

        // Handle box sets with specific types
        if (item?.data?.type === "Socks") {
          // It's an all-socks box set
          const itemsInBox = item.data?.items?.length || 0;
          socks += itemsInBox * quantity; // Add socks from the box set
          products += itemsInBox * quantity;

        } else if (item?.data?.type?.toLowerCase()?.includes("socks & underwear")) {
          // It's a combo of socks and underwear
          socks += 1 * quantity;
          underwear += 1 * quantity;
          products += 2 * quantity; // 1 sock + 1 underwear in each combo
        } else if (item?.data?.type?.toLowerCase()?.includes("underwear")) {
          underwear += 1 * quantity;
          products += 1 * quantity;
        }

        // Check for PCK keyword when no items are defined in the box set
        if (item.data?.items?.length === 0) {
          const pckMatch = item.sku.match(/(\d+)PCK/);
          if (pckMatch) {
            const itemsInPCK = parseInt(pckMatch[1], 10);
            socks += itemsInPCK * quantity; // Assume these are socks
            products += itemsInPCK * quantity; // Add to total products count
          }
        }
      } else {

        const itemStyle = item.style ? item.style.toLowerCase() : "";

        // Check if the item is a finished product
        const isFinished = updateFinishedQuantity(itemStyle, quantity);

        // If a finished item was found, increment the finished count and skip socks/underwear count
        if (!isFinished) {
          // For non-box set individual items
          if (item.type === "Socks") {
            socks += quantity;  // Add to socks
            products += quantity;  // Add to total products count
          } else if (item.type === "Underwear") {
            underwear += quantity;  // Add to underwear
            products += quantity;  // Add to total products count
          }
        }
      }

    });
  });
  return { products, bundles, socks, underwear, orders, finished, notFound };
};
