import Papa from 'papaparse';

export const createCsvFile = async (batch, formattedDate) => {
  // Extract trackingInfo from batch
  const trackingInfo = batch.trackingInfo;

  // Define CSV headers
  const headers = ['Order Number', 'Customer Name', 'Tracking Number', 'Date'];
  const formattedDateSlashed = formattedDate.replace(/\./g, '/');

  // Parse trackingInfo to CSV format using PapaParse
  const csv = Papa.unparse({
    fields: headers,
    data: trackingInfo.map(info => [info.orderNumber, info.customerName, info.trackingNumber, formattedDateSlashed])
  });

  // Create a Blob from the CSV string
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

  // Create a download link and trigger a download in the browser
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);
  link.setAttribute('href', url);
  link.setAttribute('download', `Tracking_${formattedDate}.csv`);
  document.body.appendChild(link);
  link.click();

  // Clean up and remove the link
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
};

export const convertToRows = (_orders, isCustoms = false) => {
  const rows = [];
  _orders.forEach((order, index) => {
    const odd = index % 2 === 1;
    if (order && order.customer && order.customer.firstName && order.customer.lastName) {
      const formattedCustomerName = `${order.customer.firstName.charAt(0)}. ${order.customer.lastName}`;
      order.customer = formattedCustomerName;
    }

    let isManyItems = order.items.length > 1;
    let itemsPushed = []

    order.items.forEach((item, itemIndex) => {
      let type
      if (item.type && item.type !== "") {
        type = item.type ?? "Unknown";

        if (item?.style && item.style !== "Graphic") {
          type = item.style;
        }

      } else {
        type = getItemType(item);
      }

      itemsPushed.push(type);

      item.type = type;

      const rowData = {
        containsCustomProduct: order.containsCustomProduct,
        createDate: order.dsDate,
        odd,
        orderNumber: order.orderNumber.toString(),
        exists: item.exists,
        available: item.available,
        type: type,
        shipping: order.shipping,
        sku: item.sku,
        quantity: item.quantity,
        tags: item.tags,
        source: order.source,
        reprint: !!order.reprint,
        error: item.error,
        style: item.style,
        index: itemIndex,
        customText: isCustoms && (item.customText || item.customText !== "") ? item.customText : "",
        orderData: order,
      };
      rows.push(rowData);
    });

    if (isManyItems) {
      const allItemsSame = itemsPushed.every((val, i, arr) => val === arr[0]);

      if (!allItemsSame) {
        order.type = "Mixed" + itemsPushed.join(" & ");
      }
    }
  });
  return rows;
};

const typeMapping = {
  "SOCKS": "Socks",
  "SOCK": "Socks",
  "BRIEFS": "Underwear",
  "BOXERS": "Underwear",
  "BOXER": "Underwear",
  "UNDERWEAR": "Underwear",
  "T-SHIRT": "T-Shirt",
  "TSHIRT": "T-Shirt",
};

const getItemType = (item) => {
  const itemName = item.name ?? "";
  const upperItemName = itemName.toUpperCase();

  // Check if "COMBO" is in the name
  if (upperItemName.includes("COMBO")) {
    // Initialize an empty array to collect the types in the combo
    let comboTypes = [];

    // Loop through the typeMapping to find both types in the combo
    for (const [keyword, unifiedType] of Object.entries(typeMapping)) {
      if (upperItemName.includes(keyword)) {
        comboTypes.push(unifiedType);
      }
    }

    // If we found two or more types, return them as a Combo
    if (comboTypes.length >= 2) {
      return `Combo: ${comboTypes.join(" & ")}`;
    }
  }

  // Loop through the typeMapping for non-combo items
  for (const [keyword, unifiedType] of Object.entries(typeMapping)) {
    if (upperItemName.includes(keyword)) {
      return unifiedType; // Return the unified type
    }
  }

  // Return a default value if no keyword is matched
  return "Unknown";
};


const knownHeaders = [
  "Transaction #",
  "PO #",
  "PO Date",
  "Buyer Currency",
  "WEB Order #",
  "Department #",
  "Account #",
  "Member ID #",
  "Internal Vendor #",
  "Vendor Name",
  "Ship to Name",
  "Qty Ordered",
  "Unit Price",
  "Product Description",
  "Ship to City",
  "Ship to State",
];

// Helper function to check if a row contains some known headers
export const isLikelyHeader = (row, threshold = 0.5) => {
  const matches = row.filter((header) =>
    knownHeaders.includes(header)
  ).length;
  const matchPercentage = matches / knownHeaders.length;
  return matchPercentage >= threshold;
};