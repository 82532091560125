import * as React from "react";
const HiddenPassword = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#000"
      fillRule="evenodd"
      d="M1.606 6.081a1 1 0 0 1 1.313.525L2 7l.92-.394c0-.002 0-.001 0 0l.003.008.021.046c.02.042.051.108.094.194.086.172.219.424.4.729.364.611.917 1.425 1.67 2.237a12.009 12.009 0 0 0 .59.591c1.484 1.39 3.555 2.59 6.303 2.59 1.209 0 2.278-.232 3.22-.603 1.227-.482 2.254-1.209 3.096-1.998a13.053 13.053 0 0 0 2.733-3.724l.027-.058.005-.012a1 1 0 0 1 1.838.788L22 7l.92.394-.003.005-.004.008-.011.026-.04.088a14.045 14.045 0 0 1-.741 1.348 15.363 15.363 0 0 1-1.711 2.255l.797.798a1 1 0 0 1-1.414 1.414l-.84-.84a11.81 11.81 0 0 1-1.897 1.256l.782 1.203a1 1 0 1 1-1.676 1.09l-.986-1.514c-.679.208-1.404.356-2.176.425V16.5a1 1 0 0 1-2 0v-1.544c-.775-.07-1.5-.217-2.177-.425l-.985 1.515a1 1 0 1 1-1.676-1.091l.782-1.203c-.7-.37-1.332-.8-1.897-1.256l-.84.84a1 1 0 0 1-1.414-1.414l.797-.798A15.402 15.402 0 0 1 1.72 8.605a13.457 13.457 0 0 1-.591-1.107 5.418 5.418 0 0 1-.033-.071l-.01-.022-.002-.006-.001-.003v-.001L2 7l-.919.395a1 1 0 0 1 .525-1.314Z"
      clipRule="evenodd"
    />
  </svg>
);
export default HiddenPassword;
