import React from "react";
import { Box, Typography } from "@mui/material";

const calculateTotal = (categoryData) => {
  return Object.values(categoryData).reduce((sum, value) => sum + value, 0);
};

// Component to display totals for dynamic categories
export const DisplayTotals = ({ totalsForPrinting }) => {
  // Sorting the categories to put "Crew" first and "Boxer" last
  const sortedCategories = Object.keys(totalsForPrinting).sort((a, b) => {
    if (a === "Crew") return -1;
    if (b === "Crew") return 1;
    if (a === "Boxer") return 1;
    if (b === "Boxer") return -1;
    return 0;
  });

  return (
    <Box
      display="flex"
      flexDirection="row"
      gap={4}
      width="80%" // Ensure it takes the full width of the parent
    >
      {sortedCategories.map((category) => {
        const total = calculateTotal(totalsForPrinting[category]);

        return (
          <Box
            key={category}
            display="flex"
            flexDirection="column"
            gap={1}
            flex={1} // Ensure each category box takes equal space
          >
            {/* Display the category and total */}
            <Typography variant="h6" sx={{ textAlign: "center" }}>
              {" "}
              {/* Center-align text */}
              <span style={{ fontWeight: "bold" }}>
                {category === "Crew"
                  ? "CREW SOCKS"
                  : category === "Boxer"
                  ? "Men's Boxer Briefs"
                  : category}
              </span>{" "}
              - {total}
            </Typography>

            {/* Display the sizes and their respective totals */}
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center" // Center the sizes row
              gap={2}
            >
              {Object.keys(totalsForPrinting[category]).map((size) => (
                <Typography key={size} variant="body1">
                  <span style={{ fontWeight: "bold" }}>
                    {totalsForPrinting[category][size]}
                  </span>{" "}
                  {size}
                </Typography>
              ))}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};
