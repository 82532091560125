import { useCallback, useState, useEffect } from 'react';
import { selectingIdInOrder } from '../redux/wholeSaleOrders/actions';

export const useShiftSelectedWQ = (rows, setSelectedRows, callBack, selectedRowIds, dispatch, order, isProduction) => {
  if(!isProduction) {
    return () => {};
  }
  const [lastSelectedIndex, setLastSelectedIndex] = useState(selectedRowIds[order]);

  // useEffect(() => {
  //   setLastSelectedIndex(selectedRowIds[order]);
  // }, [lastSelectedIndex]);

  const handleRowClick = useCallback(
    (event, clickedRow) => {
      const orderProp = 'id';
      let currentIndex = rows.findIndex(row => row[orderProp] === clickedRow);

      if (event.shiftKey && lastSelectedIndex !== null) {
        console.log('lastSelectedIndex: ', lastSelectedIndex);
        const startIndex = Math.min(lastSelectedIndex, currentIndex);
        const endIndex = Math.max(lastSelectedIndex, currentIndex);
        const range = rows.slice(startIndex, endIndex + 1);
        const rangeOrderNumbers = range.map(row => row[orderProp]);
        

        const lastFromArr = rangeOrderNumbers[rangeOrderNumbers.length - 1];


        dispatch(selectingIdInOrder(order, lastFromArr))

        const idSelected = rangeOrderNumbers.join(',');
        
        callBack(event, idSelected, true);
      } else {
        const idSelected = (currentIndex + 1).toString()
        dispatch(selectingIdInOrder(order, idSelected))
        callBack(event, idSelected);
      }
    },
    [rows, callBack, lastSelectedIndex]  // Notice the inclusion here
  );

  return handleRowClick;
};
