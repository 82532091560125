import {
  FETCHED_BUNDLES,
  CHANGE_BUNDLE,
  ORIGINAL_DROPDOWN_DATA,
  ORIGINAL_SUB_DOCS,
  META_DATA_RECIEVED,
  RESET_STATE,
  UPDATE_VARIANTS,
  UPDATE_EMPTY_VARIANTS,
  UPDATE_MAIN_BUNDLES_VARIANTS,
  SET_PATH_OF_MOCKS,
  UPDATE_MOCK_JOB_STATUS,
  UPDATE_BUNDLE,
  DELETE_BUNDLE,
  ADD_BUNDLE,
  HANDLE_DATA_CHANGE_REQUEST,
} from "./types"

export const injectBundles = (bundlesData, allBundles) => {
  return {
    type: FETCHED_BUNDLES,
    payload: {
      bundles: bundlesData,
      allBundles: allBundles
    }
  }
}

export const injectMetadata = (metaData) => {
  return {
    type: META_DATA_RECIEVED,
    payload: {
      totalBundles: metaData.totalItems,
      totalPages: metaData.totalPages,
    }
  }
}

export const hanldeChangeBundle = (bundleData) => {
  return {
    type: CHANGE_BUNDLE,
    payload: bundleData
  }
}

export const handleOriginalDropdownData = (dropDownData) => {
  return {
    type: ORIGINAL_DROPDOWN_DATA,
    payload: dropDownData
  }
}

export const handleOriginalSubDocs = (subDocs) => {
  return {
    type: ORIGINAL_SUB_DOCS,
    payload: subDocs
  }
}

export const resetState = () => {
  return {
    type: RESET_STATE
  }
}

export const updateVariantsLocally = (item, updatedVariants,
  updateMainOnlyLocally, newSkuMain, variantsWithoutLs) => {
  return {
    type: UPDATE_VARIANTS,
    payload: {
      item,
      updatedVariants,
      updateMainOnlyLocally,
      newSkuMain, 
      variantsWithoutLs
    }
  }
}

export const updateMainBundleOnly = (boxset, updatedVariants, newSkuMain) => {
  return {
    type: UPDATE_MAIN_BUNDLES_VARIANTS,
    payload: {
      boxset,
      updatedVariants,
      newSkuMain
    }
  }
}

export const updateEmptyVariants = (boxset, variants, originalSubDocs, newSkuMain) => {
  return {
    type: UPDATE_EMPTY_VARIANTS,
    payload: { boxset, variants, originalSubDocs, newSkuMain }
  }
}

export const setPathOfMocks = (oldMocks) => {
  return {
    type: SET_PATH_OF_MOCKS,
    payload: oldMocks
  }
}

export const updatingJobStatus = (status) => {
  return {
    type: UPDATE_MOCK_JOB_STATUS,
    payload: status
  }
}

export const updateBundle = (bundle) => {
  return {
    type: UPDATE_BUNDLE,
    payload: {
      newBundle: bundle
    }
  }
}

export const deleteBundleInState = (sku) => {
  return {
    type: DELETE_BUNDLE,
    payload: sku
  }
}

export const addBundleToState = (bundle, sizes, formats) => {
  return {
    type: ADD_BUNDLE,
    payload: { bundle, sizes, formats }
  }
}

/**
 * @description This function is used to handle filtering of data via dropdowns and text search
 * @param {string} requestType 
 * @param {any} data 
 */
export const handleDataChangeRequest = (requestType, data) => {
  return {
    type: HANDLE_DATA_CHANGE_REQUEST,
    payload: {
      requestType,
      data
    }
  }
}