import Papa from "papaparse";
import { PRODUCT_MOCK_PREFIX, PRODUCT_ART_PREFIX } from '../constants/defines';
import axios from 'axios';
import moment from "moment";

const MAX_BATCH_CHARS = 41;

const formatDate = (_time) => {
  if (!_time) {
    return "";

  }
  var date = _time.toDate();
  let year = date.getFullYear() - 2000;
  var str = ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + year;
  return str;
}

const formatTime = (_time) => {
  var date = _time.toDate();
  var hour = '' + date.getHours();
  var min = '' + date.getMinutes();
  if (hour.length < 2) hour = '0' + hour;
  if (min.length < 2) min = '0' + min;

  return `${hour}:${min}`;
}

const formatDateTime = (date) => {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear().toString().slice(-2),
    hour = '' + d.getHours(),
    min = '' + d.getMinutes();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;
  if (hour.length < 2) hour = '0' + hour;
  if (min.length < 2) min = '0' + min;

  var _date = [month, day, year].join('/');
  return _date + ' ' + hour + ':' + min;
}

const formatDate1 = (date) => {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear().toString().slice(-2),
    hour = '' + d.getHours(),
    min = '' + d.getMinutes();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;
  if (hour.length < 2) hour = '0' + hour;
  if (min.length < 2) min = '0' + min;

  var _date = [month, day, year].join('/');
  return _date;
}

const validateBatchName = (batchName) => {
  var regex = /^[A-Za-z0-9., _-]+$/
  if (batchName === "") {
    console.log("Blank Batch Name");
    return "Blank Name";
  } else if (batchName.length > MAX_BATCH_CHARS) {
    return "40 Characters Max";
  }
  //Validate TextBox value against the Regex.
  var isValid = regex.test(batchName);
  if (isValid === false) {
    return "Contains Special Character";
  }
  return "Success";
}

const createCSV = (csvName, items) => {
  let csv = Papa.unparse(items);
  csv = `data:text/csv;charset=utf-8,${csv}`;

  const encodedUri = encodeURI(csv);
  const link = global.document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", `${csvName}.csv`);
  global.document.body.appendChild(link); // Required for FF
  link.click();
  global.document.body.removeChild(link);
}

const formatBytes = (bytes, decimals = 2) => {
  if (!bytes || bytes === 0) return { value: 0, unit: 'B' };

  const k = 1000;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return { value: parseFloat((bytes / Math.pow(k, i)).toFixed(dm)), unit: sizes[i] };
}

const updateImageName = (filePath, srcSize, dstSize) => {
  //const fileExt = path.extname(filePath);
  //const baseFileName = path.basename(filePath, fileExt);

  const fileExt = filePath.substr(filePath.lastIndexOf('.') + 1);
  const baseFileName = filePath.split('.').slice(0, -1).join('.');

  let newFileName = '';
  var str_array = baseFileName.split(srcSize);
  if (str_array.length == 1) {
    newFileName = `${baseFileName}-${dstSize}.${fileExt}`;
  } else if (str_array.length == 2 && str_array[1] === "") {
    newFileName = `${str_array[0]}${dstSize}.${fileExt}`;
  } else {
    newFileName = `${str_array[0]}${dstSize}${str_array[1]}.${fileExt}`;
  }

  return newFileName;
}

const updateSKU = (sku, srcSize, dstSize) => {
  let str_array = sku.split('-'), new_array = [], found = false;
  for (let i = 0; i < str_array.length; i++) {
    let str = str_array[i];
    if (str === srcSize) {
      found = true;
      new_array.push(dstSize);
    } else {
      new_array.push(str);
    }
  }
  if (!found) {
    new_array.push(dstSize);
  }
  return new_array.join('-');
}

const cropSoloImg = (img, area) => {
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  /* setting canvas width & height allows us to 
  resize from the original image resolution */
  canvas.width = area.width * 2;
  canvas.height = area.height;

  ctx.drawImage(
    img,
    area.x,
    area.y,
    area.width,
    area.height,
    0,
    0,
    area.width,
    area.height
  );

  ctx.drawImage(
    img,
    area.x,
    area.y,
    area.width,
    area.height,
    area.width,
    0,
    area.width,
    area.height
  );

  return canvas;
}



const resizeImg = (img, width) => {
  const scale = width / img.width
  const height = scale * img.height

  var canvas = document.createElement("canvas")
  canvas.width = width
  canvas.height = height
  const ctx = canvas.getContext("2d")

  ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, width, height)
  return canvas;
}

const resizeSoloImg = (img, width, height, area) => {
  var canvas = document.createElement("canvas"), ctx = canvas.getContext("2d");

  var oc = document.createElement('canvas'), octx = oc.getContext('2d');

  oc.width = width;
  oc.height = height;

  octx.drawImage(
    img,
    0,
    0,
    oc.width,
    oc.height
  );

  canvas.width = area.width * 2;
  canvas.height = area.height;

  ctx.drawImage(
    oc,
    area.x,
    area.y,
    area.width,
    area.height,
    0,
    0,
    area.width,
    area.height
  );

  ctx.drawImage(
    oc,
    area.x,
    area.y,
    area.width,
    area.height,
    area.width,
    0,
    area.width,
    area.height
  );

  return new Promise((resolve) => {
    canvas.toBlob((blob) => {
      resolve(blob)
    }, 'image/png');
  });

}

const downloadImageBase64 = (storage, path) => {
  return new Promise((resolve, reject) => {
    const ref = storage.ref().child(path);

    ref.getDownloadURL().then(url => {
      axios
        .get(url, { responseType: 'arraybuffer' })
        .then(rsp => {
          let base64 = btoa(
            new Uint8Array(rsp.data)
              .reduce((data, byte) => data + String.fromCharCode(byte), '')
          );
          resolve("data:image/png;base64," + base64);

        });
    })
      .catch(error => {
        console.log(error);
        reject();
      });

  })
}


const convertBase64Image = (data, transparent) => {
  return new Promise((resolve, reject) => {
    var img = new Image();
    img.onload = function () {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext("2d");

      canvas.width = img.width;
      canvas.height = img.height;

      if (transparent)
        ctx.fillStyle = "#ffffff00";
      else
        ctx.fillStyle = "#ffffff";

      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(img, 0, 0);

      resolve(canvas.toDataURL("image/png"));
    }
    img.src = data;
  })
}

const downloadMockImage = (storage, name, path, transparent) => {
  console.log('handleDownload');
  const _path = `${PRODUCT_MOCK_PREFIX}${path}`;
  return new Promise((resolve, reject) => {
    downloadImageBase64(storage, _path)
      .then((data) => {
        convertBase64Image(data, transparent)
          .then(imageURL => {

            const link = document.createElement('a');
            link.href = imageURL;
            link.download = `${name}.png`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            resolve();
          });
      });
  })
}

const convertBatchTime = (strTime) => {
  const clientTime = moment(strTime, 'MM/DD/YY H:mm').format('MM/DD/YY hh:mm A');
  return clientTime;
}

export {
  createCSV,
  formatDate,
  formatDate1,
  formatTime,
  formatDateTime,
  validateBatchName,
  formatBytes,
  updateSKU,
  updateImageName,
  cropSoloImg,
  resizeImg,
  resizeSoloImg,
  downloadMockImage,
  downloadImageBase64,
  convertBase64Image,
  convertBatchTime
};