export const FETCHED_BUNDLES = 'FETCHED_BUNDLES';
export const CHANGE_BUNDLE = 'CHANGE_BUNDLE';
export const ORIGINAL_DROPDOWN_DATA = 'ORIGINAL_DROPDOWN_DATA';
export const ORIGINAL_SUB_DOCS = 'ORIGINAL_SUB_DOCS';
export const META_DATA_RECIEVED = 'META_DATA_RECIEVED';
export const RESET_STATE = 'RESET_STATE';
export const UPDATE_VARIANTS = 'UPDATE_VARIANTS';
export const UPDATE_EMPTY_VARIANTS = 'UPDATE_EMPTY_VARIANTS';
export const UPDATE_MAIN_BUNDLES_VARIANTS = 'UPDATE_MAIN_BUNDLES_VARIANTS';
export const SET_PATH_OF_MOCKS = 'SET_PATH_OF_MOCKS';
export const UPDATE_MOCK_JOB_STATUS = 'UPDATE_MOCK_JOB_STATUS';
export const UPDATE_BUNDLE = 'UPDATE_BUNDLE';
export const DELETE_BUNDLE = 'DELETE_BUNDLE';
export const ADD_BUNDLE = 'ADD_BUNDLE';
export const HANDLE_DATA_CHANGE_REQUEST = 'HANDLE_DATA_CHANGE_REQUEST';

export const FILTERING_TYPES = {
  SEARCH_KEY: 'SEARCH_KEY',
  ROWS_PER_PAGE: 'ROWS_PER_PAGE',
  PAGE_NUMBER: 'PAGE_NUMBER',
}