import {
  Box,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { EnhancedTableHead } from "../Page/table";
import { useState } from "react";
import { handleCopyToBoard } from "../../utils/copyToClip";
import {
  formatSource,
  isDateExceedingFiveDays,
  dsRowStyle,
} from "../../utils/fiveDaysExceederHelper";
import { ToastCopyCLip } from "../Toast/copyToClip";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import FlipCameraAndroidIcon from "@mui/icons-material/FlipCameraAndroid";

const headCells = [
  {
    id: "date",
    numeric: false,
    align: "left",
    enableSort: true,
    disablePadding: true,
    label: "DATE",
    width: "140px",
  },
  {
    id: "order",
    numeric: true,
    align: "left",
    enableSort: true,
    disablePadding: false,
    label: "ORDER",
    width: "140px",
  },
  {
    id: "type",
    numeric: false,
    align: "center",
    enableSort: false,
    disablePadding: false,
    label: "TYPE",
    width: "110px",
  },
  {
    id: "sku",
    numeric: false,
    align: "left",
    enableSort: false,
    disablePadding: false,
    label: "SKU",
    width: "auto",
  },
  {
    id: "shipping",
    numeric: false,
    align: "left",
    enableSort: false,
    disablePadding: false,
    label: "Shipping",
    width: "auto",
  },
  {
    id: "qty",
    numeric: true,
    align: "center",
    enableSort: false,
    disablePadding: false,
    label: "QTY",
    width: "60px",
  },
  {
    id: "source",
    numeric: false,
    align: "center",
    enableSort: false,
    disablePadding: false,
    label: "SOURCE",
    width: "100px",
  },
];

export const DsOrderTable = ({
  orders,
  classes,
  onSelectOrder,
  selectedOrders,
  tableOrder,
  orderBy,
  handleRequestSort,
}) => {
  const [toastMessage, setToastMessage] = useState("");
  const [showToast, setShowToast] = useState(false);
  return (
    <>
      <Table
        className={classes.table}
        aria-labelledby="tableTitle"
        size={"medium"}
        aria-label="enhanced table"
      >
        <EnhancedTableHead
          useCheckbox={true}
          cells={headCells}
          order={tableOrder}
          orderBy={orderBy}
          rowCount={orders.length}
          onRequestSort={handleRequestSort}
        />

        {orders.length > 0 ? (
          <TableBody>
            {orders.map((row, index) => {
              const hasBeenRestored = row.orderData.hasBeenRestored;
              const labelId = `enhanced-table-checkbox-${index}`;
              const keyId = `key-${row.orderNumber}-${index}`;
              const type = row.containsCustomProduct ? "customs" : "inlines";
              const isItemSelected = selectedOrders.some(
                (order) => order.orderNumber === row.orderNumber
              );

              const handleCheckboxClick = (event) => {
                event.stopPropagation();
                onSelectOrder(row.orderData);
              };

              return (
                <TableRow
                  hover
                  tabIndex={-1}
                  key={keyId}
                  selected={isItemSelected}
                  classes={{
                    root: row.odd ? classes.tableRow : classes.tableRow2,
                    selected: classes.selectedTableRow,
                  }}
                  className="no-select"
                  role="checkbox"
                  aria-checked={isItemSelected}
                  onClick={() => onSelectOrder(row.orderData)}
                >
                  <TableCell
                    padding="checkbox"
                    className={classes.tableCheckbox}
                  >
                    {row.index === 0 && (
                      <Checkbox
                        sx={{ paddingRight: "40px" }}
                        color="primary"
                        checked={isItemSelected}
                        onClick={handleCheckboxClick}
                        inputProps={{ "aria-labelledby": labelId }}
                      />
                    )}
                  </TableCell>

                  <TableCell
                    padding="none"
                    onClick={() =>
                      handleCopyToBoard(
                        `Copied Date: ${row.createDate}`,
                        row.createDate,
                        setToastMessage,
                        setShowToast
                      )
                    }
                    sx={{
                      cursor: "pointer",
                    }}
                  >
                    <Typography
                      variant="tableText"
                      sx={{
                        fontWeight: isDateExceedingFiveDays(row.createDate)
                          ? "bold"
                          : "normal",
                        color: isDateExceedingFiveDays(row.createDate)
                          ? "#FF0000"
                          : "inherit",
                      }}
                    >
                      {row.index === 0 ? row.createDate : ""}
                    </Typography>
                  </TableCell>

                  <TableCell
                    align="left"
                    onClick={() =>
                      handleCopyToBoard(
                        `Copied Order Number: ${row.orderNumber}`,
                        row.orderNumber,
                        setToastMessage,
                        setShowToast
                      )
                    }
                    sx={{
                      cursor: "pointer",
                    }}
                  >
                    <Typography
                      variant="tableText"
                      sx={dsRowStyle(row.createDate, row)}
                    >
                      {row.index === 0 ? row.orderNumber : ""}
                    </Typography>
                    {row.index === 0 && hasBeenRestored && (
                      <Box className={classes.restoreIcon}>
                        <FlipCameraAndroidIcon sx={{ color: "blue" }} />
                      </Box>
                    )}
                  </TableCell>
                  <TableCell
                    align="center"
                    onClick={() =>
                      handleCopyToBoard(
                        `Copied type: ${row.type}`,
                        row.type,
                        setToastMessage,
                        setShowToast
                      )
                    }
                    sx={{
                      cursor: "pointer",
                    }}
                  >
                    <Typography
                      variant="tableText"
                      sx={dsRowStyle(row.createDate, row, true)}
                    >
                      {row.type}
                    </Typography>
                  </TableCell>
                  <TableCell
                    align="left"
                    onClick={() =>
                      handleCopyToBoard(
                        `Copied SKU: ${row.sku}`,
                        row.sku,
                        setToastMessage,
                        setShowToast
                      )
                    }
                    sx={{
                      cursor: "pointer",
                    }}
                  >
                    <Typography
                      variant={
                        row.exists && row.available
                          ? "tableText"
                          : "tableErrorText"
                      }
                      sx={dsRowStyle(row.createDate, row)}
                    >
                      {row.sku}
                    </Typography>
                    {type === "customs" && row.customText && (
                      <Typography
                        variant="tableText"
                        sx={{ marginLeft: "30px", color: "#FFCC00" }}
                      >
                        <AnnouncementIcon />
                        <span style={{ marginLeft: "5px", color: "#1F7CF9" }}>
                          {row.customText}
                        </span>
                      </Typography>
                    )}
                    <Typography
                      variant="tableErrorText"
                      sx={{ marginLeft: "10px" }}
                    >
                      {row.error}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography
                      variant="tableText"
                      sx={dsRowStyle(row.createDate, row)}
                    >
                      {row.shipping}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography
                      variant="tableText"
                      sx={dsRowStyle(row.createDate, row)}
                    >
                      {row.quantity}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography
                      variant="tableText"
                      sx={dsRowStyle(row.createDate, row)}
                    >
                      {formatSource(row.source)}
                    </Typography>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        ) : (
          <TableBody>
            <TableRow>
              <TableCell colSpan={7} align="center">
                <Typography variant="tableText">No Orders!</Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        )}
      </Table>
      <ToastCopyCLip show={showToast} message={toastMessage} />
    </>
  );
};
