import { collections, SIZES } from '../constants/defines';
import _ from "lodash";
import {
  collection,
  query,
  where,
  getDocs,
  orderBy,
  doc,
  updateDoc,
  addDoc,
  setDoc,
  getDoc,
  deleteDoc,
  onSnapshot
} from "firebase/firestore";

const getTemplates = (db, limit, callback) => {
  const q = query(collection(db, collections.TEMPLATES), orderBy('name'));

  onSnapshot(q, (querySnapshot) => {
    let items = [];
    querySnapshot.forEach((doc) => {
      let data = doc.data();
      const item = {
        id: doc.id,
        name: data.name,
        size: data.size,
        canvas: data.canvas,
        faceSlots: data.faceSlots,
        faceSlotsBack: data.faceSlotsBack,
        printArea: data.printArea,
        printBackArea: data.printBackArea,
        printAreaModern: data.printAreaModern,
        printBackAreaModern: data.printBackAreaModern,
        propertySlots: data.propertySlots,
        propertySlotsBack: data.propertySlotsBack,
        canvasModern: data.canvasModern,
        faceSlotsModern: data.faceSlotsModern,
        faceSlotsModernBack: data.faceSlotsModernBack,
        propertySlotsModern: data.propertySlotsModern,
        propertySlotsModernBack: data.propertySlotsModernBack,
        property: data.property,
        isCustomText: data.isCustomText,
      };
      items.push(item);
    });
    callback(items);
  });
}

const getTemplate = (db, name, callback) => {
  const q = query(collection(db, collections.TEMPLATES), where("name", "==", name));

  getDocs(q)
    .then((querySnapshot) => {
      let _item = { exists: false, qty: 1, sku: name, name: '', format: '', size: '' };

      querySnapshot.forEach((doc) => {
        const data = doc.data();
        _item.name = data.name;
        _item.size = _.find(SIZES, item => doc.id === data.size).name;
        _item.data = data;
      });
      callback(_item);
    })
    .catch(error => {
      console.log('getUserByUid: ', error);
    });
}

const createTemplate = (db, item) => {
  return new Promise((resolve, reject) => {
    const newItem = { ...item };
    const currentTime = Date();
    newItem.created = currentTime;
    newItem.isCustomText = (item.propertySlots.length > 0 || item.propertySlotsBack.length > 0 || item.propertySlotsModern.length > 0 || item.propertySlotsModernBack.length > 0);

    addDoc(collection(db, collections.TEMPLATES), newItem)
      .then((docRef) => {
        console.log("New Template created with ID: ", docRef.id);
        resolve();
      })
      .catch((error) => {
        console.error("Error adding Template: ", error);
        reject();
      });
  });
}

const updateTemplate = (db, item) => {
  return new Promise((resolve, reject) => {
    const isCustomText = (item.propertySlots.length > 0 || item.propertySlotsBack.length > 0 || item.propertySlotsModern.length > 0 || item.propertySlotsModernBack.length > 0);

    const docRef = doc(db, collections.TEMPLATES, item.id);
    updateDoc(docRef, {
      name: item.name,
      size: item.size,
      property: item.property,
      faceSlots: item.faceSlots,
      faceSlotsBack: item.faceSlotsBack,
      propertySlots: item.propertySlots,
      propertySlotsBack: item.propertySlotsBack,
      printArea: {
        ...item.printArea,
      },
      printBackArea: {
        ...item.printBackArea
      },
      printAreaModern: {
        ...item.printAreaModern,
      },
      printBackAreaModern: {
        ...item.printBackAreaModern
      },
      canvas: {
        ...item.canvas,
      },
      faceSlotsModern: item.faceSlotsModern,
      faceSlotsModernBack: item.faceSlotsModernBack,
      propertySlotsModern: item.propertySlotsModern,
      propertySlotsModernBack: item.propertySlotsModernBack,
      canvasModern: {
        ...item.canvasModern,
      },
      isCustomText
    })
      .then(() => {
        console.log("Template successfully updated!");
        resolve();
      })
      .catch((error) => {
        // The document probably doesn't exist.
        console.error("Error updating Template: ", error);
        reject();
      });
  });
}

const deleteTemplate = (db, item) => {
  return new Promise((resolve, reject) => {
    deleteDoc(doc(db, collections.TEMPLATES, item.id))
      .then(() => {
        console.log("Template successfully deleted!");
        resolve();
      })
      .catch((error) => {
        // The document probably doesn't exist.
        console.error("Error deleting Template: ", error);
        reject();
      });
  });
}

const getMatchedTemplate = (templates, templateId) => {
  let template = _.find(templates, { id: templateId });
  return template;
}

export { getTemplate, getTemplates, createTemplate, updateTemplate, deleteTemplate, getMatchedTemplate };