import { useEffect } from 'react';
import { injectAnalytics, injectAnalyticsForShipment, injectUnshippedGraphAnalytic, setItemsWithErrors, setRemoveLoading } from '../redux/analytics/actions';
import { TYPE_OF_ANALYTICS } from '../redux/analytics/types';
import { API } from '../constants/defines';
import { doc, getDoc } from 'firebase/firestore';


const useFetchAnalytics = (dispatch, db) => {
  useEffect(() => {
    const fetchAllData = async () => {
      try {
        const fetchGraphAnalyticsUrl = `${API.getShippedBydate}?startDate=${null}&endDate=${null}&lookingSpecificaly=last30days`

        // Fetch all data concurrently
        const [analyticsResponse, shippedYesterdayResponse, awaitingShipmentResponse, producedDataResponse] = await Promise.all([
          fetch(API.getAnalytics, { method: 'GET', headers: { 'Content-Type': 'application/json' } }),
          fetch(fetchGraphAnalyticsUrl, { method: 'GET', headers: { 'Content-Type': 'application/json' } }),
          fetch(API.getAwaitingShipment, { method: 'GET', headers: { 'Content-Type': 'application/json' } }),
          fetch(API.getProducedData, { method: 'GET', headers: { 'Content-Type': 'application/json' } }),
        ]);

        // Parse all responses as JSON
        const [analyticsData, shippedYesterdayData, awaitingShipmentData, producedData] = await Promise.all([
          analyticsResponse.json(),
          shippedYesterdayResponse.json(),
          awaitingShipmentResponse.json(),
          producedDataResponse.json(),
        ]);

        // Structure the fetched analytics data to match the expected format
        const structuredAnalyticsData = [
          analyticsData.data.avgPrintTime,
          analyticsData.data.averagePrintedToShipped,
          analyticsData.data.averageCreatedToPrinted,
          analyticsData.data.oldestOrder,
          analyticsData.data.makeUpOrders,
        ];

        // Dispatch actions to update the Redux store
        dispatch(injectAnalytics(structuredAnalyticsData, TYPE_OF_ANALYTICS.TIMED_ANALYTICS));

        const arrayOfShipmentData = [
          shippedYesterdayData?.shipped || defaultNotFound,
          awaitingShipmentData?.data || defaultAwaitingShipment,
        ];

        dispatch(injectUnshippedGraphAnalytic(shippedYesterdayData?.unshipped || defaultNotFound,));

        // Dispatch the shipped orders data
        dispatch(injectAnalyticsForShipment(arrayOfShipmentData, TYPE_OF_ANALYTICS.ORDERS_SHIPPED_AND_NOT_SHIPPED));

        // Dispatch produced data
        dispatch(setItemsWithErrors(producedData.data.errorData));

        const docRef = doc(db, 'DailyOverview', 'latest');
        const document = await getDoc(docRef);
        const data = document.data();

        dispatch(injectAnalytics(data.arrayOfData, TYPE_OF_ANALYTICS.UNITS))

        // Set loading state to false
        dispatch(setRemoveLoading(false));

      } catch (error) {
        console.error('Error fetching data', error);
        dispatch(setRemoveLoading(false));
      }
    };

    fetchAllData();
  }, []);

  return null;
};


const defaultNotFound = {
  id: 2,
  title: 'Orders Shipped Yesterday',
  data: {
    shopify: {},
    dropship: {}
  },
  value: 'shipped',
}

const defaultAwaitingShipment = {
  id: 3,
  title: 'Orders Awaiting Shipment',
  data: {
    shopify: {},
    dropship: {}
  },
  value: 'awaiting',
}

const data2 = [
  {
    "id": 1,
    "title": "Units Produced",
    "value": "produced",
    "data": []
  },
  {
    "id": 2,
    "title": "Units To Be Produced",
    "value": "toBeProduced",
    "data": [
      {
        "products": [
          {
            "type": "socks",
            "types": {
              "shopify": {
                "styles": [
                  {
                    "style": "Crew",
                    "sizes": {
                      "LXL": 425,
                      "SM": 66,
                      "YTH": 33,
                      "TDLR24": 3
                    },
                    "totalUnitProduced": 527
                  },
                  {
                    "style": "Low",
                    "sizes": {
                      "LXL": 19,
                      "SM": 6
                    },
                    "totalUnitProduced": 25
                  }
                ]
              }
            }
          },
          {
            "type": "boxer",
            "types": {
              "shopify": {
                "styles": [
                  {
                    "style": "Boxer",
                    "sizes": {
                      "M": 20,
                      "L": 10,
                      "S": 3,
                      "2XL": 1,
                      "XL": 6
                    },
                    "totalUnitProduced": 40
                  }
                ]
              }
            }
          }
        ]
      }
    ]
  }
]

const data = {
  avgPrintTime: {
    id: 1,
    title: "Average Order to Print",
    data: {
      time: {
        days: 3,
        hours: 19,
        minutes: 30,
        seconds: 55
      }
    },
    icon: "printer"
  },
  averagePrintedToShipped: {
    id: 2,
    title: "Average Print to Ship",
    data: {
      time: {
        days: 0,
        hours: 8,
        minutes: 11,
        seconds: 40
      }
    },
    icon: "truck"
  },
  averageCreatedToPrinted: {
    id: 3,
    title: "Average Order to Ship",
    data: {
      time: {
        days: 7,
        hours: 17,
        minutes: 30,
        seconds: 56
      }
    },
    icon: "mailbox"
  },
  oldestOrder: {
    id: 4,
    title: "Oldest Order",
    data: {
      orderNumber: "1053253",
      date: "Jun 5, 2024",
      thisManyDaysAgo: 7,
      extraData: {
        email: "erica_renee85@hotmail.com"
      }
    },
    icon: "box"
  },
  makeUpOrders: {
    id: 5,
    title: "Makeup Orders",
    data: {
      count: 0,
      date: "N/A"
    },
    icon: "makeup"
  }
}


export default useFetchAnalytics;